<template>
    <span v-if="task.priority == true">
        <div class="container" v-badge.danger="'!'">
            <div class="row mt-2 justify-content-between">
                <div v-if="!task.internal" class="col" style="padding-bottom: 0px;" >
                    <Tag severity="info"><h6>External</h6></Tag>
                </div>
                <div v-else class="col" style="padding-bottom: 0px;">
                    <Tag severity="primary"><h6>Internal</h6></Tag>
                </div>
                <div v-if="task.id_user_assigned" class="col text-end" style="padding-bottom: 0px;">
                    <div class="circle-image">
                        <img :src="task.id_user_assigned.picture" class="profile-pic" @click="toggle"/>
                    </div>    
                </div>
                <div v-else class="col text-end" style="padding-bottom: 0px;">
                    <div class="circle-image">
                        <img :src="task.id_user_creator.picture" class="profile-pic" @click="toggle"/>
                    </div>    
                </div>
            </div>
            <div class="row" >
                <div class="col" style="padding-top: 0px; padding-bottom: 0px;">
                    <h5><span v-tooltip.top="task.title">{{format_title(task.title)}}</span></h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12 md:col-7" style="color: gray;">
                    <i class="fa fa-clock"></i> <small> {{format_time(task.creation_datetime)}} </small>
                </div>
                <div v-if="!task.internal" class="col text-end">
                    <span v-if="get_budgets_tasks.length > 0">
                        <span v-if="get_budgets_tasks[0].status == 'On hold'">
                            <Tag style="background-color: white;"><i style="color: gray;" class="fa fa-coins fa-xl me-2"></i> <h6 style="color:black;"> On Hold </h6></Tag>
                        </span>
                        <span v-else-if="task.budget_mail_sent">
                            <Tag style="background-color: white;"><i style="color: gray;" class="fa fa-coins fa-xl me-2"></i> <h6 style="color:black;"> Pending </h6></Tag>
                        </span>
                        <span v-else>
                            <Tag style="background-color: white;"><i style="color: gray;" class="fa fa-coins fa-xl me-2"></i> <h6 :class="{'budget_success': get_budgets_tasks[0].status == 'Confirmed', 'budget_rejected': get_budgets_tasks[0].status == 'Rejected'}"> {{get_budgets_tasks[0].status}} </h6></Tag>
                        </span>
                    </span>
                    <span v-else-if="task.budget_mail_sent == true">
                        <Tag style="background-color: white;"><i style="color: gray;" class="fa fa-coins fa-xl me-2"></i> <h6 style="color:black;"> Pending </h6></Tag>
                    </span>
                    <span v-else>
                        <Tag style="background-color: white;"><i style="color: gray;" class="fa fa-coins fa-xl me-2"></i> <h6 style="color:black;"> Not sent </h6></Tag>
                    </span>
                </div>
                <div v-else class="col text-end">
                    <Tag style="background-color: white;"><i style="color: gray;" class="fa fa-coins fa-xl me-2"></i> <h6 style="color: gray;"> - </h6> </Tag>
                </div>
            </div>
        </div>
    </span>
    <span v-else>
        <div  class="container" >            
            <div class="row mt-2 justify-content-between">
                <div v-if="!task.internal" class="col" style="padding-bottom: 0px;" >
                    <Tag severity="info"><h6>External</h6></Tag>
                </div>
                <div v-else class="col" style="padding-bottom: 0px;">
                    <Tag severity="primary"><h6>Internal</h6></Tag>
                </div>
                <div v-if="task.id_user_assigned" class="col text-end" style="padding-bottom: 0px;">
                    <div class="circle-image">
                        <img :src="task.id_user_assigned.picture" class="profile-pic" @click="toggle"/>
                    </div>    
                </div>  
                <div v-else class="col text-end" style="padding-bottom: 0px;">
                    <div class="circle-image">
                        <img :src="task.id_user_creator.picture" class="profile-pic" @click="toggle"/>
                    </div>    
                </div>
            </div>
            <div class="row" >
                <div class="col" style="padding-top: 0px; padding-bottom: 0px;">
                    <h5><span v-tooltip.top="task.title">{{format_title(task.title)}}</span></h5>
                </div>
            </div>
            <div class="row">
                <div class="col md:col-7" style="color: gray;">
                    <i class="fa fa-clock" ></i> <small> {{format_time(task.creation_datetime)}} </small>
                </div>
                <div v-if="!task.internal" class="col text-end">
                    <span v-if="budget_list.length > 0">
                        <span v-if="budget_list[0].id_task.id == task.id">
                            <span v-if="budget_list[0].status == 'On hold'"> 
                                <Tag severity="warning"><i class="fa fa-coins fa-xl me-2"></i> <h6> On Hold </h6></Tag>
                            </span>
                            <span v-else-if="task.budget_mail_sent">
                                <Tag severity="info"><i class="fa fa-coins fa-xl me-2"></i> <h6> Pending </h6></Tag>
                            </span>
                            <span v-else>
                                <Tag severity="danger"><i class="fa fa-coins fa-xl me-2"></i> <h6> {{budget_list[0].status}} </h6></Tag>
                            </span>
                        </span>
                        <span v-else>
                            <Tag style="background-color: white;"><i style="color: gray;" class="fa fa-coins fa-xl me-2"></i> <h6 style="color:gray;"> Not sent </h6></Tag>
                        </span>
                    </span>
                    <span v-else-if="task.budget_mail_sent == true">
                        <Tag severity="info"><i class="fa fa-coins fa-xl me-2"></i> <h6> Pending </h6></Tag>
                    </span>
                    <span v-else>
                        <Tag style="background-color: white;"><i class="fa fa-coins fa-xl me-2" style="color: gray;"></i> <h6 style="color:gray;"> Not sent </h6></Tag>
                    </span>
                </div>
                <div v-else class="col text-end">
                    <Tag style="background-color: white;"><i style="color: gray;" class="fa fa-coins fa-xl me-2"></i> <h6 style="color: gray;"> - </h6> </Tag>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import Tag from 'primevue/tag';

import moment from 'moment'

export default {
    name: "Pending Task",
    props: {
        task: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            budget_list: []
        }
    },
    components: {
        Tag
    },
    computed: {
        get_budgets_tasks: function() {
            return this.$store.getters.get_budgets_tasks
        }
    },
    async mounted() {
        await this.$store.dispatch('load_budgets_from_task', this.task.id)
        this.budget_list = this.get_budgets_tasks 
    },
    methods: {
        format_hour(value) {
            if(value){
                return value.split('T')[1].split(':')[0] + ':' + value.split('T')[1].split(':')[1]
            } else {
                return 'Pending'
            }
        },
        format_title(value){
            if(value.length > 20){
                return value.substring(0, 20) + '...'
            } else {
                return value
            }
        },
        format_time(value){
            return moment(value).fromNow()
        }
    }
}
</script>

<style scoped>
.circle-image{
  cursor:pointer;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 35px;
  height: 35px;
  padding: 0;
}

.circle-image img{
  width:100%;
  height:100%;
  object-fit: cover;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

h6{
    margin-bottom: 0px !important;
}

</style>