<template>
    <ModalShips />
    <TableShips />
</template>

<script>
import TableShips from '@/components/AdminDashboard/Ships/TableShips.vue'
import ModalShips from '@/components/AdminDashboard/Ships/ModalShips.vue'
export default {
  name: 'Ships',
  components: {
    TableShips,
    ModalShips
  }
}
</script>

<style scoped>
</style>