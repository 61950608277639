<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div v-if="$can('view','passenger') " class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Passengers </h1>
            <div v-if="$can('add','passenger')" class="card p-1">
                <Button  class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Passenger'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog header="New Passenger" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <form id="from_new_passenger" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">     
                                <InputText id="first_name" v-model="v$.first_name.$model" :class="{'p-invalid':v$.first_name.$invalid && submitted || passenger_errors.first_name}" />
                                <label for="first_name" :class="{'p-error':v$.first_name.$invalid && submitted}"> First name* </label>
                                <small v-if="Object.keys(passenger_errors).length !== 0 && passenger_errors.first_name" class="p-error"><span v-for="error in passenger_errors.first_name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.first_name.$invalid && submitted) || v$.first_name.$pending.$response" class="p-error">{{v$.first_name.required.$message.replace('Value', 'First name')}}</small>
                        </div>
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">     
                                <InputText id="last_name" v-model="v$.last_name.$model" :class="{'p-invalid':v$.last_name.$invalid && submitted || passenger_errors.last_name }" />
                                <label for="last_name" :class="{'p-error':v$.last_name.$invalid && submitted}"> Last name* </label>
                                <small v-if="Object.keys(passenger_errors).length !== 0 && passenger_errors.last_name" class="p-error"><span v-for="error in passenger_errors.last_name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.last_name.$invalid && submitted) || v$.last_name.$pending.$response" class="p-error">{{v$.last_name.required.$message.replace('Value', 'Last name')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="dni_pass" v-model="v$.dni_pass.$model" :class="{'p-invalid':v$.dni_pass.$invalid && submitted || passenger_errors.dni_pass}" />
                                <label for="dni_pass" :class="{'p-error':v$.dni_pass.$invalid && submitted}"> DNI / Passaport* </label>
                                <small v-if="Object.keys(passenger_errors).length !== 0 && passenger_errors.dni_pass" class="p-error"><span v-for="error in passenger_errors.dni_pass" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.dni_pass.$invalid && submitted) || v$.dni_pass.$pending.$response" class="p-error">{{v$.dni_pass.required.$message.replace('Value', 'DNI / PASS')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <Dropdown id="service_sheet" v-model="id_call" :options="all_calls" optionLabel="estimated_arrival_datetime" :filter="true" placeholder="Select a Call*" :class="{'p-invalid':v$.id_call.$invalid && submitted || passenger_errors.id_call}">
                                    <template #value="slotProps">
                                        <div v-if="!id_call">
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                        <div v-else-if="slotProps.value">
                                            <span>{{format_date(slotProps.value.arrival_datetime)}} - {{slotProps.value.id_ship.name}}</span>
                                        </div>
                                    </template>
                                    <template #option="slotProps">
                                        <div> {{format_date(slotProps.option.arrival_datetime)}} - {{slotProps.option.id_ship.name}} </div>
                                    </template>
                                </Dropdown>
                                
                            </div>
                            <small v-if="Object.keys(passenger_errors).length !== 0 && passenger_errors.id_call" class="p-error"><span v-for="error in passenger_errors.id_call" :key="error">{{error}}<br></span></small>
                        </div>
                        <small v-if="(v$.id_call.$invalid && submitted) || v$.id_call.$pending.$response" class="p-error">{{v$.id_call.required.$message.replace('Value', 'Call')}}</small>
                    </div> 
                </div>
            </form>
            <template #footer>
                <Button form="from_new_passenger" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Dropdown from 'primevue/dropdown'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

import ability from '../../../services/ability'

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        Toast,
        Dropdown
    },
    data() {
        return {
            displayModal: false,
            first_name: '',
            last_name: '',
            dni_pass: '',
            id_call: '',
            passenger_errors: {},
            submitted: false,
            showMessage: false,
        }
    },
    created() {
        if(ability.can('view','call')) {
            this.$store.dispatch('load_all_calls')
        }
    },
    computed: {
        all_calls: function() {
            return this.$store.getters.all_calls
        },
        get_passenger_errors: function() {
            return this.$store.getters.get_passenger_errors
        }
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        format_date(value) {
            
            if(value instanceof Date){
                return value.toISOString().split('T')[0]
               
            } else {
                return value.split('T')[0]
            }

        },
        async add_passenger() {
            let passenger = { 
                first_name: this.first_name,
                last_name: this.last_name,
                dni_pass: this.dni_pass,
                id_call: this.id_call
            }
            await this.$store.dispatch('create_passenger', passenger)
            this.passenger_errors = this.get_passenger_errors
            if(Object.keys(this.passenger_errors).length === 0){
                this.toggleDialog();
                this.resetForm();
                return true
            }
            return false

        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

            let res = await this.add_passenger()

            if (res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Passenger added', life: 3000});
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.first_name = '';
            this.last_name = '';
            this.dni_pass = '';
            this.id_call = null;
            this.submitted = false;
        }
    },
    validations() {
        return {
            first_name: { required },
            last_name: { required },
            dni_pass: { required },
            id_call: { required },

        }
    },
};
</script>

<style scoped>
</style>