<template>
    <Toast/>
    <div v-if="loading_calendar" style="position: absolute; top:0;left:0;">
        <ProgressBar mode="indeterminate" style="height: 6px; width: 100vw;"></ProgressBar>
    </div>
    <div class="container-fluid">
        <div class="row" style="height: 100%">
            <div class="side_bar_custom">
                <Menu :model="terminals_menu" />
            </div>  
            <div class="col main_table">
                <div class="container-fluid mt-2">
                    <!--
                    <span v-if="$ability.can('add','daily_log')" class="col-12 lg:col-4 card_note">
                        <div v-if="loading==true" class="">
                            <Skeleton height="50vh" />
                        </div>
                        <div v-else style="display: flex; flex-direction: column;">
                            <div class="p-fluid card p-3 ">
                                <form id="form_new_record" @submit.prevent="handleSubmit(!v$.$invalid)">
                                    <div class="container mt-2">
                                        <div class="col">
                                            <h2> New Record </h2>
                                        </div>
                                    </div>
                                    <div class="mt-3 container">
                                        <div class="col">
                                            <span class="p-float-label">
                                                <Dropdown :options="all_terminals" v-model="terminal_record" optionLabel="name" optionValue="id" :class="{'p-invalid':v$.terminal_record.$invalid && submitted}" />
                                                <label> Terminal <span style="color:red;">*</span></label>
                                            </span>
                                            <small v-if="(v$.terminal_record.$invalid && submitted) || v$.terminal_record.$pending.$response" class="p-error">{{v$.terminal_record.required.$message.replace('Value', 'Terminal')}}</small>
                                        </div>
                                    </div>
                                    <div class="mt-2 container">
                                        <div class="col">
                                            <span class="p-float-label">
                                                <Calendar v-model="date" dateFormat="yy-mm-dd" :class="{'p-invalid':v$.date.$invalid && submitted}" />
                                                <label :class="{'p-error':v$.date.$invalid && submitted}"> Date <span style="color:red;">*</span> </label>
                                            </span>
                                            <small v-if="(v$.date.$invalid && submitted) || v$.date.$pending.$response" class="p-error">{{v$.date.required.$message.replace('Value', 'Date')}}</small>
                                        </div>
                                    </div>
                                    <div class="mt-2 container">
                                        <div class="col">
                                            <span class="p-float-label">
                                                <Calendar v-model="hour" :timeOnly="true" :class="{'p-invalid':v$.hour.$invalid && submitted}" @change="hour = num2time($event)"/>
                                                <label :class="{'p-error':v$.hour.$invalid && submitted}"> Hour <span style="color:red;">*</span> </label>
                                            </span>
                                            <small v-if="(v$.hour.$invalid && submitted) || v$.hour.$pending.$response" class="p-error">{{v$.hour.required.$message.replace('Value', 'Hour')}}</small>
                                        </div>
                                    </div>
                                </form>
                                <div class="mt-2 container">
                                    <div class="col">
                                        <span class="p-float-label">
                                            <TextArea v-model="description" :autoResize="true"  rows="5" :class="{'p-invalid':v$.description.$invalid && submitted}"/>
                                            <label :class="{'p-error':v$.description.$invalid && submitted}"> Description <span style="color:red;">*</span> </label>
                                        </span>
                                        <small v-if="(v$.description.$invalid && submitted) || v$.description.$pending.$response" class="p-error">{{v$.description.required.$message.replace('Value', 'Description')}}</small>
                                        <span v-if="get_error_daily_logs">
                                            <span v-for="item in get_error_daily_logs.text" :key="item">
                                                <small class="p-error"> {{ item }} </small>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class=" mb-2 container" style="margin-top: auto;">
                                    <div class="col" >
                                        <Button label="Add" icon="fa fa-plus" form="form_new_record" type="submit" class="p-button-success p-button-raised"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>    
                    -->
                    <div v-if="loading==true" class="mt-3">
                        <Skeleton height="80vh" class="mb-4"></Skeleton>
                    </div>
                    <span v-else>
                        <ScrollPanel>
                            <div class="flex justify-content-between align-items-center">
                                <h3 class="my-auto"> Terminal {{ selected_terminal }}</h3>
                                <span class="card" style="display: block;">
                                    <Button class="p-button-text"  @click="this.$router.push('/record_book_summary')" v-tooltip.bottom="'Search'">
                                        <i class="pi pi-search" style="font-size: 1rem"></i>
                                    </Button>
                                    <Button data-cy="new_record_button" class="p-button-success p-button-text" @click="open_modal_new_record" v-tooltip.bottom="'New Record'">
                                        <i class="pi pi-plus" style="font-size: 1rem"></i>
                                    </Button>
                                </span>
                            </div>
                            <FullCalendar class="me-1" ref="fullCalendar" :options="optionsCalendar"/>
                        </ScrollPanel>
                    </span>
                </div>
            </div>
            
        </div>
        <div class="menu-mobile justify-content-end">
            <div class="col">
                <Button icon="fa fa-bars" class="sidebar-button p-button-raised p-button-rounded color-button-menu" style="color: white;" @click="visibleRight = true"/>
            </div>
        </div>
    </div>

    <Sidebar v-model:visible="visibleRight" position="full">
        <Menu :model="terminals_menu" />  
    </Sidebar>


    <Dialog header="New Record" v-model:visible="display_modal_new_record" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}" :modal="true" @hide="submitted = false">
        <div class="container p-fluid">
            <form id="form_new_record" @submit.prevent="handleSubmit(!v$.$invalid)">
                <div class="mt-4 container">
                    <div class="col">
                        <span class="p-float-label">
                            <Dropdown data-cy="dropdown_terminals" :options="all_terminals" v-model="terminal_record" optionLabel="name" optionValue="id" :class="{'p-invalid':v$.terminal_record.$invalid && submitted}"/>
                            <label> Terminal <span style="color:red;">*</span> </label>
                        </span>
                        <small v-if="(v$.terminal_record.$invalid && submitted) || v$.terminal_record.$pending.$response" class="p-error">{{v$.terminal_record.required.$message.replace('Value', 'Terminal')}}</small>
                    </div>
                </div>
                <div class="mt-2 container">
                    <div class="col">
                        <span class="p-float-label">
                            <Calendar v-model="date" dateFormat="yy-mm-dd" :class="{'p-invalid':v$.date.$invalid && submitted}" />
                            <label :class="{'p-error':v$.date.$invalid && submitted}"> Date <span style="color:red;">*</span>  </label>
                        </span>
                        <small v-if="(v$.date.$invalid && submitted) || v$.date.$pending.$response" class="p-error">{{v$.date.required.$message.replace('Value', 'Date')}}</small>
                    </div>
                </div>
                <div class="mt-2 container">
                    <div class="col">
                        <span class="p-float-label">
                            <Calendar  v-model="hour" :timeOnly="true" :class="{'p-invalid':v$.hour.$invalid && submitted}" @change="hour = num2time($event)"/>
                            <label :class="{'p-error':v$.hour.$invalid && submitted}"> Hour <span style="color:red;">*</span> </label>
                        </span>
                        <small v-if="(v$.hour.$invalid && submitted) || v$.hour.$pending.$response" class="p-error">{{v$.hour.required.$message.replace('Value', 'Hour')}}</small>
                    </div>
                </div>
            </form>
            <div class="mt-3 container">
                <div class="col">
                    <span class="p-float-label">
                        <TextArea id="description" v-model="description" rows="5" :class="{'p-invalid':v$.description.$invalid && submitted}"/>
                        <label :class="{'p-error':v$.description.$invalid && submitted}"> Description <span style="color:red;">*</span> </label>
                    </span>
                    <small v-if="(v$.description.$invalid && submitted) || v$.description.$pending.$response" class="p-error">{{v$.description.required.$message.replace('Value', 'Description')}}</small>
                    <span v-if="get_error_daily_logs">
                        <span v-for="item in get_error_daily_logs.text" :key="item">
                            <small class="p-error"> {{ item }} </small>
                        </span>
                    </span>
                </div>
            </div>
            <div class=" mb-2 container" style="margin-top: auto;">
                <div class="col" >
                    
                    <Button data-cy="create_record_button" label="Add" icon="fa fa-plus" form="form_new_record" type="submit" class="p-button-success p-button-raised" :disabled="loading_submitted"/>
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog header="Edit Record" v-model:visible="display_modal_edit" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '70vw'}" :modal="true">
        <div class="container p-fluid">
            <div class="row">
                <div class="col-12 lg:col-6 my-auto border_right_divider">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 md:col">
                                <h6> Terminal </h6>
                                <Dropdown v-model="edit_terminal" :options="all_terminals" optionLabel="name" optionValue="id"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 md:col">
                                <h6> Date </h6>
                                <Calendar v-model="edit_date" dateFormat="yy-mm-dd"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 md:col">
                                <h6> Hour </h6>
                                <Calendar v-model="edit_hour" :timeOnly="true" hourFormat="24" @change="edit_hour = num2time($event)"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 md:col">
                                <h6> Description </h6>
                                <TextArea id="edit_description" v-model="edit_text" rows="5"/>
                            </div>
                            <span v-if="get_error_daily_logs">
                                <span v-for="item in get_error_daily_logs.text" :key="item">
                                    <small class="p-error"> {{ item }} </small>
                                </span>
                            </span>
                        </div>
                        <div class="row justify-content-end">
                            <div class="col-12 md:col-3">
                                <Button data-cy="save_changes_record_button" label="Save" @click="save_event" class="p-button-raised"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-6 my-auto" >
                    <div class="container">
                        <div class="row justify-content-between">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Create Task </h5>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div v-if="edit_id_task==null " class="col">
                                        <div class="card">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col my-auto">
                                                        <h5> Internal Task </h5>
                                                    </div>
                                                    <div class="col-12 md:col-3 xl:col-4 text-end">
                                                        <Button icon="fa fa-plus" class="p-button-text" @click="create_task(internal = true)"/>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col mb-2">
                                                        <InputText v-model="edit_title" placeholder="Insert title for the internal task"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="col my-auto">
                                        <div class="card">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col">
                                                        A task was already created and assigned to this record.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="$ability.can('external','task')" class="row">
                                    <div class="col">
                                         <div class="card">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col my-auto">
                                                        <h5> External Task </h5>
                                                    </div>
                                                    <div class="col-12 md:col-3 lg:col-4 mb-2 text-end">
                                                        <Button icon="fa fa-plus" class="p-button-text" @click="$router.push({name: 'Tasks_board', params: {new_task_modal: true, task_type: 'External', text: edit_text, terminal: JSON.stringify(full_terminal)}})"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Create Incidence </h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="card">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col my-auto">
                                                        <h5> Incidence </h5>
                                                    </div>
                                                    <div class="col-12 md:col-3 lg:col-4 mb-2 text-end">
                                                        <Button icon="fa fa-plus" class="p-button-text"  @click="create_incidence_with_record()"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Button label="Cancel" class="p-button-text" @click="display_modal_edit = false"/>
                        <Button data-cy="delete_record_button" v-if="$ability.can('delete','daily_log')" label="Delete" class="p-button-danger p-button-raised" @click="delete_event"/>
                    </div>
                </div>
            </div>
        </template>
    </Dialog>

</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';

import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import TextArea from 'primevue/textarea'
import Calendar from 'primevue/calendar'
import InputText from 'primevue/inputtext'
import ScrollPanel from 'primevue/scrollpanel'
import Skeleton from 'primevue/skeleton'
import Menu from 'primevue/menu'
import Sidebar from 'primevue/sidebar'
import ProgressBar from 'primevue/progressbar'
import Toast from 'primevue/toast'

import ability from '../services/ability'
import moment from 'moment'
import axios from 'axios'

import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"
import { num2time, num2timeedit } from '@/modules/utilities.js'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'Daily Log',
    components: {
        FullCalendar,
        Button,
        Dialog,
        TextArea,
        Calendar,
        InputText,
        Dropdown,
        ScrollPanel,
        Skeleton,
        Menu,
        Sidebar,
        ProgressBar,
        Toast
    },
    data(){
        return {
            new_record_menu: [
                {
                    label: 'NEW RECORD',
                    items:{}
                }
            ],
            terminals_menu: [
                {
                    label: 'TERMINALS',
                    items: []
                }
            ],
            visibleRight: false,
            loading_calendar: false,
            loading: true,
            submitted: false,
            loading_submitted: false,
            full_terminal: '',
            terminal_record: '',
            selected_terminal: '',
            terminal_calendar: '',
            color: localStorage.getItem('primary_color'),
            date: moment().format('YYYY-MM-DD'),
            hour: moment().format('HH:mm'),
            display_modal_new_record: false,
            display_modal_edit: false,
            description: '',
            edit_terminal: '',
            edit_date: '',
            edit_hour: '',
            edit_text: '',
            edit_id: '',
            edit_title: '',
            edit_user: '',
            edit_category: '',
            edit_id_task: '',
            edit_id_incidence_report: '',
            optionsCalendar: {
                plugins: [dayGridPlugin, interactionPlugin, listPlugin ],
                initialView: 'listWeek',
                events: [],
                firstDay: 1,
                height: "calc(100vh - 15rem)",
                displayEventTime: false,
                eventOrder: '-hour',
                titleFormat: {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                },   
                headerToolbar: {
                    left: 'title',
                    center: '',
                    right: 'today prev next'
                },
                //aspectRatio: 1.8,
                eventClick: async function(event) {
                    if("status" in event.event._def.extendedProps) {
                        this.$router.push({name: 'Service_sheet_template', params: { id: event.event._def.extendedProps.id_call, sid: event.event._def.extendedProps.id_service_sheet}})
                        return true
                    }

                    if(ability.can('change','daily_log') && event.event._def.extendedProps.id_user == this.get_current_user.id) {
                        this.edit_id = event.event._def.publicId
                        this.edit_terminal = event.event._def.extendedProps.id_terminal
                        this.edit_hour = event.event._def.title.split(' ')[2]
                        this.edit_date = moment(event.event._instance.range.start).format('YYYY-MM-DD')
                        this.edit_text = event.event._def.title.split(/-/).slice(1).join('-').trim()
                        this.edit_id_task = event.event._def.extendedProps.id_task
                        this.edit_user = event.event._def.title.split(' ')[0]
                        this.edit_id_incidence_report = event.event._def.extendedProps.id_incidence_report
                        if(!event.event._def.extendedProps.status) {
                            await this.$store.dispatch('empty_errors_daily_log')
                            this.display_modal_edit = true

                        }
                    } else {
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000});    
                    }   
                    
        
                }.bind(this),
                customButtons: {
                    today: {
                        text: 'today',
                        click: async () => {
                            this.loading_calendar = true
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.today()

                            let data = {
                                date: moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'),
                                terminal: this.terminal_calendar
                            }   
                            await this.$store.dispatch('load_daily_logs', moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'))
                            await this.$store.dispatch('load_weekly_ships', data)
                            this.optionsCalendar.events = []

                            for(var item in this.get_weekly_ships) {
                                for(var ship in this.get_weekly_ships[item]) {
                                    if(this.get_weekly_ships[item][ship] != null) {
                                        var event = {
                                            title: this.get_weekly_ships[item][ship].name,
                                            start: moment(item).format(),
                                            color: 'white',
                                            extendedProps: {
                                                status: 'call',
                                                id_call: this.get_weekly_ships[item][ship].id_call,
                                                id_service_sheet: this.get_weekly_ships[item][ship].id_service_sheet
                                            },
                                        }
                                        this.optionsCalendar.events.push(event)
                                    }
                                }
                            }

                            this.get_daily_logs.forEach((element) => {
                                var event = {
                                    id: element.id,
                                    title: element.id_user.username + ' at '  + element.hour.split(':')[0] + ':' + element.hour.split(':')[1] + ' - ' + element.text,
                                    start: element.date,
                                    color: '#002D72',
                                    extendedProps: {
                                        id_terminal: element.id_terminal.id,
                                        id_task: element.id_task,
                                        id_incidence_report: element.id_incidence_report,
                                        id_user: element.id_user.id,
                                        hour: element.hour
                                    }
                                }
                            
                                if(element.id_terminal.name == this.selected_terminal) {
                                    this.optionsCalendar.events.push(event)
                                }
                            })
                            this.loading_calendar = false
                        }
                    },
                    next: {
                        click: async () => {
                            this.loading_calendar = true
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();

                            let data = {
                                date: moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'),
                                terminal: this.terminal_calendar
                            }   
                            await this.$store.dispatch('load_daily_logs', moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'))
                            await this.$store.dispatch('load_weekly_ships', data)
                            this.optionsCalendar.events = []

                            for(var item in this.get_weekly_ships) {
                                for(var ship in this.get_weekly_ships[item]) {
                                    if(this.get_weekly_ships[item][ship] != null) {
                                        var event = {
                                            title: this.get_weekly_ships[item][ship].name,
                                            start: moment(item).format(),
                                            color: 'white',
                                            extendedProps: {
                                                status: 'call',
                                                id_call: this.get_weekly_ships[item][ship].id_call,
                                                id_service_sheet: this.get_weekly_ships[item][ship].id_service_sheet
                                            },
                                        }
                                        this.optionsCalendar.events.push(event)
                                    }
                                }
                            }

                            this.get_daily_logs.forEach((element) => {
                                var event = {
                                    id: element.id,
                                    title: element.id_user.username + ' at '  + element.hour.split(':')[0] + ':' + element.hour.split(':')[1] + ' - ' + element.text,
                                    start: element.date,
                                    color: '#002D72',
                                    extendedProps: {
                                        id_terminal: element.id_terminal.id,
                                        id_task: element.id_task,
                                        id_incidence_report: element.id_incidence_report,
                                        id_user: element.id_user.id,
                                        hour: element.hour
                                    }
                                }
                            
                                if(element.id_terminal.name == this.selected_terminal) {
                                    this.optionsCalendar.events.push(event)
                                }
                            })
                            this.loading_calendar = false
                        } 
                    },
                    prev: {
                        click: async() => {
                            this.loading_calendar = true
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();

                            let data = {
                                date: moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'),
                                terminal: this.terminal_calendar
                            }   
                            await this.$store.dispatch('load_daily_logs', moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'))
                            await this.$store.dispatch('load_weekly_ships', data)
                            this.optionsCalendar.events = []

                            for(var item in this.get_weekly_ships) {
                                for(var ship in this.get_weekly_ships[item]) {
                                    if(this.get_weekly_ships[item][ship] != null) {
                                        var event = {
                                            title: this.get_weekly_ships[item][ship].name,
                                            start: moment(item).format(),
                                            color: 'white',
                                            extendedProps: {
                                                status: 'call',
                                                id_call: this.get_weekly_ships[item][ship].id_call,
                                                id_service_sheet: this.get_weekly_ships[item][ship].id_service_sheet
                                            },
                                        }
                                        this.optionsCalendar.events.push(event)
                                    }
                                }
                            }

                            this.get_daily_logs.forEach((element) => {
                                var event = {
                                    id: element.id,
                                    title: element.id_user.username + ' at '  + element.hour.split(':')[0] + ':' + element.hour.split(':')[1] + ' - ' + element.text,
                                    start: element.date,
                                    color: '#002D72',
                                    extendedProps: {
                                        id_terminal: element.id_terminal.id,
                                        id_task: element.id_task,
                                        id_incidence_report: element.id_incidence_report,
                                        id_user: element.id_user.id,
                                        hour: element.hour
                                    }
                                }
                            
                                if(element.id_terminal.name == this.selected_terminal) {
                                    this.optionsCalendar.events.push(event)
                                }


                            })
                            this.loading_calendar = false
                        }
                    }
                },
            },
        }
    },
    computed: {
        get_daily_logs: function(){
            return this.$store.getters.daily_logs
        },
        get_weekly_ships: function() {
            return this.$store.getters.weekly_ships
        },
        all_terminals: function() {
            var terminals_list = this.$store.getters.all_terminals
            return terminals_list 
        },
        get_task_categories: function() {
            return this.$store.getters.get_task_categories
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        get_error_daily_logs: function() {
            return this.$store.getters.get_error_daily_logs
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        await this.$store.dispatch('load_task_categories')
        await this.$store.dispatch('load_all_terminals')
        await this.$store.dispatch('load_daily_logs', moment().startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'))
        
        for(var item_menu in this.all_terminals) {

            let new_item = {
                label: 'Terminal ' + this.all_terminals[item_menu].name,
                props: this.all_terminals[item_menu],
                command: (event) => { 
                    this.visibleRight = false
                    this.change_view_calendar(event.item.props) 
                },
            }

            this.terminals_menu[0].items.push(new_item)
        }

        this.full_terminal = this.all_terminals[0]
        this.selected_terminal = this.all_terminals[0].name
        this.terminal_calendar = this.all_terminals[0].id
        let data = {
            date: moment().startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'),
            terminal: this.all_terminals[0].id
        }

        await this.$store.dispatch('load_weekly_ships', data)
 
        for(var item in this.get_weekly_ships) {
            for(var ship in this.get_weekly_ships[item]) {
                if(this.get_weekly_ships[item][ship] != null) {
                    var event = {
                        title: this.get_weekly_ships[item][ship].name,
                        start: moment(item).format(),
                        color: 'white',
                        extendedProps: {
                            status: 'call',
                            id_call: this.get_weekly_ships[item][ship].id_call,
                            id_service_sheet: this.get_weekly_ships[item][ship].id_service_sheet
                        },
                    }
                    this.optionsCalendar.events.push(event)
                }
            }
        }

        this.get_daily_logs.forEach((element) => {
            var event = {
                id: element.id,
                title: element.id_user.username + ' at ' + element.hour.split(':')[0] + ':' + element.hour.split(':')[1] + ' - ' + element.text,
                start: element.date,
                color: '#002D72',
                extendedProps: {
                    id_terminal: element.id_terminal.id,
                    id_task: element.id_task,
                    id_incidence_report: element.id_incidence_report,
                    id_user: element.id_user.id,
                    hour: element.hour
                }
            }
        
            if(element.id_terminal.name == this.selected_terminal) {
                this.optionsCalendar.events.push(event)
            }

        })
        
        this.loading = false
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {

        async handleResize(){
            if (window.innerWidth <= 465){
                this.optionsCalendar.headerToolbar.right = 'prev next'
                delete this.optionsCalendar.titleFormat.year
            } else {
                this.optionsCalendar.headerToolbar.right = 'today prev next'
                this.optionsCalendar.titleFormat.year = 'numeric'
            }
        },
        
        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data){
            return num2timeedit(data)
        },
        async modal_create_new_record() {
            await this.$store.dispatch('empty_errors_daily_log')
            this.display_modal_new_record = true
        },
        async create_new_record() {
            this.loading_submitted = true

            if(typeof(this.date) != 'string') {
                this.date = moment(this.date).format('YYYY-MM-DD')
            }

            if(typeof(this.hour) != 'string') {
                this.hour = moment(this.hour).format('HH:mm')
            }

            let record = {
               date: this.date,
               hour: this.hour,
               text: this.description,
               id_terminal: this.terminal_record 
            }

            await this.$store.dispatch('create_daily_log', record)

            if(this.get_error_daily_logs.length == 0) {

                var new_daily_log = this.get_daily_logs.slice(-1)
                var event = {
                    id: new_daily_log[0].id,
                    title: this.get_current_user.username + ' at ' + new_daily_log[0].hour.split(':')[0] + ':' + new_daily_log[0].hour.split(':')[1] + ' - ' + new_daily_log[0].text,
                    start: new_daily_log[0].date,
                    color: '#002D72',
                    extendedProps: {
                        id_terminal: new_daily_log[0].id_terminal.id,
                        id_task: new_daily_log[0].id_task,
                        id_incidence_report: new_daily_log[0].id_incidence_report,
                        id_user: this.get_current_user.id,
                        hour: new_daily_log[0].hour.split(':')[0] + ':' + new_daily_log[0].hour.split(':')[1]
                    }
                }

                if(new_daily_log[0].id_terminal.name == this.selected_terminal) {
                    this.optionsCalendar.events.push(event)
                }

                this.display_modal_new_record = false
                this.description = ''
                this.date = moment().format('YYYY-MM-DD'),
                this.hour = moment().format('HH:mm'),
                this.terminal_record = ''
                this.loading_submitted = false
                return true
            }
            this.loading_submitted = false
            return false
            
        },
        async change_view_calendar(data) {  
            this.loading_calendar = true
            this.full_terminal = data
            this.selected_terminal = data.name
            this.terminal_calendar = data.id
            
            let calendarApi = this.$refs.fullCalendar.getApi();
            let info = {
                date: moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'),
                terminal: this.terminal_calendar
            }

            await this.$store.dispatch('load_weekly_ships', info)
            this.optionsCalendar.events = []
            for(var item in this.get_weekly_ships) {
                for(var ship in this.get_weekly_ships[item]) {
                    if(this.get_weekly_ships[item][ship] != null) {
                        var event = {
                            title: this.get_weekly_ships[item][ship].name,
                            start: moment(item).format(),
                            color: 'white',
                            extendedProps: {
                                status: 'call',
                                id_call: this.get_weekly_ships[item][ship].id_call,
                                id_service_sheet: this.get_weekly_ships[item][ship].id_service_sheet
                            },
                        }
                        this.optionsCalendar.events.push(event)
                    }
                }
            }

            this.get_daily_logs.forEach((element) => {

                var event = {
                    id: element.id,
                    title: element.id_user.username + ' at ' + element.hour.split(':')[0] + ':' + element.hour.split(':')[1] + ' - ' + element.text,
                    start: element.date,
                    color: '#002D72',
                    extendedProps: {
                        id_terminal: element.id_terminal.id,
                        id_task: element.id_task,
                        id_incidence_report: element.id_incidence_report,
                        id_user: element.id_user.id,
                        hour: element.hour
                    }
                    
                }
            
                if(element.id_terminal.name == this.selected_terminal) {
                    this.optionsCalendar.events.push(event)
                }


            })
            this.loading_calendar = false
            // This concat serves the purpose of merging the weekly calls and tasks for the calendar view
            // this.calendarOptions.events = this.calendarOptions.events.concat(this.all_port_calls_calendar_filtered_by_terminal(data))
        },
        async save_event() {
            
            if(typeof(this.edit_hour) != 'string') {
                this.edit_hour = moment(this.edit_hour).format('H:mm')
            }
            
            if(typeof(this.edit_date) != 'string') {
                this.edit_date = moment(this.edit_date).format('YYYY-MM-DD')
            }
            
            for(var i in this.optionsCalendar.events) {
                if(this.optionsCalendar.events[i].id == this.edit_id) {
                this.optionsCalendar.events[i].start = this.edit_date
                this.optionsCalendar.events[i].title = this.edit_user + ' at ' + this.edit_hour + ' - ' + this.edit_text
                }
            }
            
            let daily_log = {
                id: this.edit_id,
                id_terminal: this.edit_terminal,
                date: this.edit_date,
                hour: this.edit_hour,
                text: this.edit_text
            }

            await this.$store.dispatch('edit_daily_log', daily_log)


            if(this.terminal_calendar != this.edit_terminal) {
                let index = this.optionsCalendar.events.findIndex(optionsCalendar => optionsCalendar.id == this.edit_id)
                this.optionsCalendar.events.splice(index, 1)
            }

            if(this.get_error_daily_logs.length == 0) {
                this.$toast.add({severity:'success', summary: 'Success', detail:'Daily log modified.', life: 3000});
                this.display_modal_edit = false
            }
        },
        async delete_event() {
            let index = this.optionsCalendar.events.findIndex(optionsCalendar => optionsCalendar.id == this.edit_id)
            this.optionsCalendar.events.splice(index, 1)
            await this.$store.dispatch('delete_daily_log', this.edit_id)
            this.$toast.add({severity:'success', summary: 'Success', detail:'Daily log deleted.', life: 3000});
            this.display_modal_edit = false
        },
        async handleSubmit(isFormValid) {
           
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

            let res = await this.create_new_record()

            if (res){
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Record Added', life: 3000});
            }

            this.submitted = false
        },
        async create_task(internal) {
            let daily_log = {
                id: this.edit_id,
                date: this.edit_date,
                hour: this.edit_hour,
                text: this.edit_text,
                title: this.edit_title
            } 

            for(var i in this.get_task_categories){
                if(this.get_task_categories[i].name.toLowerCase() == 'general') {
                    this.edit_category = this.get_task_categories[i].id
                }
            }

            if(this.edit_category == ''){
                this.$toast.add({severity:'error', summary: 'Error', detail:'Cannot create task due to general category missing.', life: 3000});
            } else if(this.edit_title==''){ 
                this.$toast.add({severity:'error', summary: 'Error', detail:'Cannot create task due to the title missing.', life: 3000});
            } else {
                await axios.post(process.env.VUE_APP_API_URL + "tasks/", {
                    title: daily_log.title,
                    description: daily_log.text,
                    id_terminal: this.terminal_calendar,
                    id_task_category: this.edit_category,
                    internal: internal,
                    status: 'Pending'
                })
                    .then(async(response) => {
                        var item = {
                            id: this.edit_id,
                            task: response
                        }
                        await this.$store.dispatch('update_daily_log_task', item)
                        this.edit_id_task = response.data.id

                    })
                this.$toast.add({severity:'success', summary: 'Success', detail:'Task created.', life: 3000});    
            }
        },
        async open_modal_new_record() {
            await this.$store.dispatch('empty_errors_daily_log')
            this.display_modal_new_record = true
        },
        async create_incidence_with_record() {
            this.$router.push({ name: 'Incidences' })
            let params =  {
                terminal: this.full_terminal, 
                hour: this.edit_hour, 
                date: this.edit_date, 
                text: this.edit_text
            }
            this.$store.dispatch('provisional_record', params)
        }
    },
    validations() {
        return {
            terminal_record: { required },
            date: { required },
            hour: { required },
            description: { required },
        }
    },
}
</script>

<style scoped>

.side_bar_custom{
    position: fixed;
    width: 300px;
    height: calc(100vh - 11rem);
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: 9rem;
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.main_table {
    margin-left: 300px;
    flex-direction: column;
    min-height: calc(100vh - 9rem);
    justify-content: space-between;
    padding-left: 2rem;
    transition: margin-left 0.2s;
}

@media (min-width: 1300px) {
    .menu-mobile {
        display: none;

    }

    :deep(.p-scrollpanel .p-scrollpanel-bar) {
        background-color: #a8a8a8;
        transition: #a8a8a8 .2s;
    }

    :deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
        background-color: #a8a8a8;
    }
}

@media (max-width: 1301px) {
    .side_bar_custom {
        display: none;
    }
    .main_table{
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
}

:deep(.p-scrollpanel .p-scrollpanel-bar) {
    background-color: #a8a8a8;
    transition: #a8a8a8 .2s;
}    

:deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
    background-color: #a8a8a8;
}
:deep(.p-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content){
    width: 100%;
}
.sidebar-button {
    border-radius: 10px;
    left: 82vw !important;
    top: 90% !important;
    position: fixed !important;
    z-index: 1000;
}

:deep(.p-menu) {
    border-radius: 0px;
    background: rgba(0,0,0,0); 
    padding: 0;
}

:deep(.p-menu .p-menuitem-link) {
    margin-left: 0px;
}

.scroll_menu{
    background-color: v-bind(opacity_color);
}

:deep(.p-scrollpanel-content){
    padding: 0;

}

:deep(.p-menu .p-submenu-header){
    background-color: rgba(0,0,0,0); 
    border-radius: 6px;
}

/*
:deep(.bm-item-list) {
    margin-left: 0px !important;
}

:deep(.bm-menu) {
    background-color: #eaeaea;
    box-shadow: 0 9px 10px -1px rgba(0,0,0,.2), 0 9px 10px 0 rgba(0,0,0,.14), 0 4px 5px 0 rgba(0,0,0,.12);
}
*/

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
}

.color-button-menu{
    color: white;
    background-color: v-bind(color);
    border-color: v-bind(color);
}

.color-button-menu:hover{
    background-color: v-bind(color) !important;
    border-color: v-bind(color) !important; 
    opacity: .8;
}

:deep(.router-link-active .p-menuitem-text){
    color: v-bind(color) !important;
    font-weight: 700;
}

:deep(.router-link-active .p-menuitem-icon){
    color: v-bind(color) !important;
}

:deep(.p-menu .p-menuitem-link:not(.p-disabled):hover){
    border-radius: 10px;
}

:deep(.p-scrollpanel-bar-x){
    height: 0px;
}

:deep(a) {
    color: black !important;
}

.fc:deep(.fc-view-harness .fc-list .fc-scroller .fc-list-table .fc-list-day .fc-list-day-cushion) {
    background: v-bind(color) !important;
}

.fc:deep(.fc-view-harness .fc-list .fc-scroller .fc-list-table .fc-list-day .fc-list-day-cushion .fc-list-day-text) {
    text-decoration: none !important;
    color: white !important;
}

.fc:deep(.fc-view-harness .fc-list .fc-scroller .fc-list-table .fc-list-day .fc-list-day-cushion .fc-list-day-side-text) {
    text-decoration: none !important;
    color: white !important;
}


.fc:deep(.fc-daygrid-day-number) {
    text-decoration: none !important;
}

.fc:deep(.fc-daygrid-event) {
    cursor: pointer;
}

.fc:deep(.fc-daygrid-day.fc-day-today) {
    background: #dce2f7;
}

.fc:deep(.fc-col-header-cell) {
    background: v-bind(color) !important;
    border: v-bind(color) !important;
}

:deep(.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl
        .fc-daygrid-event.fc-event-start) {
    margin-bottom: 2px !important;
}

:deep(.fc.fc-theme-standard .fc-toolbar .fc-button) {
    background: #002d72 !important;
    border: #002d72 !important;
}

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

a{
    text-decoration: none;
}

:deep(.fc-list-event) {
    cursor: pointer;
}

.border_right_divider{
    border-top: 1px solid lightgray;
    margin-top: 1em;
    border-right: 0px;
}

@media (min-width: 1200px) {
    .border_right_divider{
        border-right: 1px solid lightgray; 
        border-top: 0px;
        margin-top: 0px;
    }
}

@media (max-width: 1199px){
    .card_note{
        display: none !important;
    }
}

:deep(.fc-list-event-title){
    border: 0px;
}

:deep(.fc-list-event-graphic){
    border: 0px;
}

:deep(.p-scrollpanel-content){
    overflow-x: hidden;
}

.active_terminal {
    background-color: v-bind(color) !important;
    color: white;
}


</style>