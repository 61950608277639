<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div v-if="$can('view','ship_finger')" class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Ship Fingers </h1>
            <div v-if="$can('add','ship_finger')" class="card p-1">
                <Button data-cy="new_ship_finger_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Ship Finger'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_ship_finger_modal" header="New Ship Finger" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <div class="container">
                <form id="form" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                    <div class="row justify-content-center">
                        <div class="col-10 pt-5 md:col-6 pt-3">
                            <div class="field">
                                <div class="p-float-label">
                                    <Dropdown data-cy="dropdown_ships" id="id_ship" v-model="v$.id_ship.$model" :options="all_ships" optionLabel="name" filter class="{'p-invalid':v$.id_ship.$invalid && submitted || ship_finger_errors.id_ship}" />
                                    <label for="id_ship" :class="{'p-error':v$.id_ship.$invalid && submitted || ship_finger_errors.id_ship}">Ships* </label>
                                    <small v-if="Object.keys(ship_finger_errors).length !== 0 && ship_finger_errors.id_ship" class="p-error"><span v-for="error in ship_finger_errors.id_ship" :key="error">{{error}}<br></span></small>
                                </div>
                                <small v-if="(v$.id_ship.$invalid && submitted) || v$.id_ship.$pending.$response" class="p-error">{{v$.id_ship.required.$message.replace('Value', 'Ship')}}</small>
                            </div>
                        </div>
                        <div class="col-10 pt-5 md:col-6 pt-3">
                            <div class="fiel">
                                <div class="p-float-label">
                                    <Dropdown data-cy="dropdown_fingers" id="id_finger" v-model="v$.id_finger.$model"  :options="all_fingers" optionLabel="name" :filter="true" :class="{'p-invalid':v$.id_finger.$invalid && submitted || ship_finger_errors.id_finger}" />
                                    <label for="id_finger" :class="{'p-error':v$.id_finger.$invalid && submitted || ship_finger_errors.id_finger}">Fingers* </label>
                                    <small v-if="Object.keys(ship_finger_errors).length !== 0 && ship_finger_errors.id_finger" class="p-error"><span v-for="error in ship_finger_errors.id_finger" :key="error">{{error}}<br></span></small>
                                </div>
                                <small v-if="(v$.id_finger.$invalid && submitted) || v$.id_finger.$pending.$response" class="p-error">{{v$.id_finger.required.$message.replace('Value', 'Finger')}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 pt-3 md:col-6 text-center">
                            <div class="p-float-field">
                                <label for="pictureUploader" class="pb-1">Picture</label>
                                <FileUpload ref="fileUpload" id="pictureUploader" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="100000000" @uploader="onUploadPicture" />
                                <small v-if="Object.keys(ship_finger_errors).length !== 0 && ship_finger_errors.picture" class="p-error"><span v-for="error in ship_finger_errors.picture" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                        <div class="col-10 pt-3 md:col-6 ">
                            <div class="p-float-field">
                                <div v-for="position of positions" :key="position" class="field-checkbox mt-3">
                                    <RadioButton name="position" :value="position" v-model="finger_position"/>
                                    <label :for="position.key" class="ms-2"> {{position}} </label>
                                    <small v-if="Object.keys(ship_finger_errors).length !== 0 && ship_finger_errors.finger_position" class="p-error"><span v-for="error in ship_finger_errors.finger_position" :key="error">{{error}}<br></span></small>
                                </div>
                                <small v-if="(v$.finger_position.$invalid && submitted) || v$.finger_position.$pending.$response" class="p-error">{{v$.finger_position.required.$message.replace('Value', 'Position')}}</small>
                            </div>
                        </div>
                    </div>
                </form> 
            </div>
            <template #footer>
                <Button data-cy="create_ship_finger_button" form="form" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import Toast from 'primevue/toast'
import RadioButton from 'primevue/radiobutton'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"
import FileUpload from "primevue/fileupload"

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        Dropdown,
        Toast,
        FileUpload,
        RadioButton
    },
    data() {
        return {
            displayModal: false,
            name: '',
            id_ship: null,
            id_finger:null,
            finger_position: null,
            lowerbeds: 0,
            ship_fingerowner: '',
            picture:'',
            blueprint:'',
            ship_finger_errors:{},
            submitted: false,
            showMessage: false,
            positions : [
                'Port Side',
                'Starboard Side'
            ],
        }
    },
    created() {
        this.$store.dispatch('load_all_fingers')
        this.$store.dispatch('load_all_ships')
    },
    computed: {
        all_fingers: function() {
            return this.$store.getters.all_fingers
        },
        all_ships: function() {
            return this.$store.getters.all_ships
        },
        get_errors_ship_finger: function() {
            return this.$store.getters.get_errors_ship_finger
        }
    },
    methods: {
        onUploadPicture(e){
            this.picture = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_ship_finger() {
            let ship_fingers = { 
                picture:this.picture,
                id_ship: this.id_ship,
                id_finger: this.id_finger,
                position: this.finger_position
            }
            
            await this.$store.dispatch('create_ship_finger', ship_fingers)
            this.ship_finger_errors = this.get_errors_ship_finger
            if(Object.keys(this.ship_finger_errors).length === 0){
                this.toggleDialog();
                this.resetForm()
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }
            
            let res = await this.add_ship_finger()

            if(res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Ship finger added', life: 3000});
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.picture = '';
            this.id_ship = null;
            this.id_finger = null;
            this.submitted = false;
        }
    },
    validations() {
        return {
            picture: { required },
            id_ship: { required },
            id_finger: { required },
            finger_position: { required }
        }
    },
};
</script>

<style scoped>
</style>