import axios from 'axios'

const state = {
    ticket_stand: {},
}

const mutations = {
    SET_TICKET_STAND_SERVICE(state, ticket_stand) {
        state.ticket_stand = ticket_stand
    },
    CREATE_TICKET_STAND_SERVICE(state, ticket_stand) {
        state.ticket_stand = ticket_stand
    },
    DELETE_TICKET_STAND_SERVICE(state) {
        state.ticket_stand = {}
    }
}

const actions = {
	async load_ticket_stand_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'ticket_stand/get_service/' + id) 
			.then((response) => {
				commit('SET_TICKET_STAND_SERVICE', response.data)
			})
	},
    async create_ticket_stand_service({commit}, ticket_stand) {
        await axios.post(process.env.VUE_APP_API_URL + 'ticket_stand/', {
            id_service_sheet: ticket_stand.id_service_sheet,
			quantity: ticket_stand.quantity 
        })
            .then((response) => {
                commit('CREATE_TICKET_STAND_SERVICE', response.data)
            })
    },
	async update_ticket_stand_service({commit}, ticket_stand) {
        await axios.patch(process.env.VUE_APP_API_URL + 'ticket_stand/' + ticket_stand.id + '/', {
			quantity: ticket_stand.quantity 
        })
            .then((response) => {
                commit('SET_TICKET_STAND_SERVICE', response.data)
            })
    },
    async delete_ticket_stand_service({commit}, ticket_stand) {
        await axios.delete(process.env.VUE_APP_API_URL + 'ticket_stand/' + ticket_stand.id + '/')
            .then((response) => {
                commit('DELETE_TICKET_STAND_SERVICE', response.data)
            })
    }
}

const getters = {
    get_ticket_stand_service: (state) => state.ticket_stand,
}

export default {
    state,
    mutations,
    actions,
    getters
};