import axios from 'axios'

const state = {
    ship_responsibles: [],
    ship_responsibles_errors:{}
}

const mutations = {
    SET_SHIP_RESPONSIBLES(state, ship_responsibles) {
        state.ship_responsibles = ship_responsibles
    },
    NEW_SHIP_RESPONSIBLE(state, ship_responsibles) {  
        state.ship_responsibles.push(ship_responsibles)
        state.ship_responsibles_errors = {}
    },
    DELETE_SHIP_RESPONSIBLE(state, id) {
        let index = state.ship_responsibles.findIndex(ship_responsibles => ship_responsibles.id == id)
        state.ship_responsibles.splice(index, 1)
    },
    UPDATE_SHIP_RESPONSIBLE(state, ship_responsibles) {
        state.ship_responsibles = state.ship_responsibles.map(updated_ship_responsibles => {
            if(updated_ship_responsibles.id === ship_responsibles.id) {
                updated_ship_responsibles.name = ship_responsibles.name
                updated_ship_responsibles.phone = ship_responsibles.phone
                updated_ship_responsibles.id_ship_agent = ship_responsibles.id_ship_agent
            }
            return updated_ship_responsibles
        })
        state.ship_responsibles_errors = {}
    },
    ERRORS_SHIP_RESPONSIBLE(state, errors){
        state.ship_responsibles_errors = errors
    }
}

const actions = {
    async load_all_ship_responsibles({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "ship_responsible/")
            .then((response) => {
                commit('SET_SHIP_RESPONSIBLES', response.data)
            })
    },
    async create_ship_responsibles({commit}, ship_responsibles) {
        await axios.post(process.env.VUE_APP_API_URL  + 'ship_responsible/', { name: ship_responsibles.name, phone: ship_responsibles.phone, id_ship_agent: ship_responsibles.id_ship_agent.id })
            .then((response) => {
                commit('NEW_SHIP_RESPONSIBLE', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SHIP_RESPONSIBLE', error.response.data)
            })
    },
    delete_ship_responsibles({commit}, id) {
        axios.delete(process.env.VUE_APP_API_URL + 'ship_responsible/' + id)
            .then(() => {
                commit('DELETE_SHIP_RESPONSIBLE', id);
            })
            
    }, 
    async update_ship_responsibles({commit}, ship_responsibles){
        await axios.put(process.env.VUE_APP_API_URL  + 'ship_responsible/' + ship_responsibles.id + '/',  { name: ship_responsibles.name, phone: ship_responsibles.phone, id_ship_agent: ship_responsibles.id_ship_agent.id })
            .then(() => {
                
                commit('UPDATE_SHIP_RESPONSIBLE', ship_responsibles)
            })
            .catch((error) => {
                
                commit('ERRORS_SHIP_RESPONSIBLE', error.response.data)
            })
    },
}

const getters = {
    all_ship_responsibles: (state) => state.ship_responsibles,
    get_ship_responsibles_errors: (state) => state.ship_responsibles_errors
}

export default {
    state,
    mutations,
    actions,
    getters
};