<template>
    <div class="container">
        <div class="row pt-3">
            <div class="col text-center">
                <h4>Add Version</h4>
                <div class="row">
                    <div class="col"> 
                        <InputText v-model="new_version"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <Button label="Add" icon="fa fa-plus" class="p-button-text p-button-raised p-button-success" @click="add_new_version"/>
                    </div>
                </div>
            </div>
            <div class="col text-center">
                <h4>Add Record</h4>
                <div class="row">
                    <div class="col">
                        <span class="p-float-label">
                            <Dropdown v-model="new_change_type" :options="options"/>
                            <label> Change Type </label>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="p-float-label">
                            <TextArea v-model="new_record" :autoResize="true"/>
                            <label> Description </label>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="p-float-label">
                            <Dropdown v-model="new_id_change_log" :options="versions" optionLabel="version" optionValue="id"/>
                            <label> Version </label>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col"> 
                        <Button label="Add" icon="fa fa-plus" class="p-button-text p-button-raised p-button-success" @click="add_new_record"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col">
                <div v-for="log in get_change_log" :key="log">
                    <hr>
                    <div class="row">
                        <div class="col text-center">
                            <h4> Version {{log.version}} </h4>
                        </div>
                        <div class="col-2 text-center">
                            <Button icon="fa fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_version(log.id)"/>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div v-for="record in log.records" :key="record">
                            <div class="row">
                                <div class="col text-center">
                                    {{record.change_type}}
                                </div>
                                <div class="col text-center">
                                    {{record.record}}
                                </div>
                                <div class="col text-center">
                                    {{record.datetime_record.split('T')[0]}}
                                </div>
                                <div class="col-2 text-center">
                                    <Button icon="fa fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_record(record.id)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import TextArea from 'primevue/textarea'

export default {
    name: 'change_log_records',
    components: {
        Button,
        InputText,
        Dropdown,
        TextArea
    },
    data() {
        return {
            new_version: '',
            new_change_type: '',
            new_record: '',
            new_id_change_log: '',
            options: [
                'Added',
                'Changed',
                'Removed',
                'Fixed',
                'Security',
                'Deprecated'
            ],
            versions: []
        }
    },
    computed: {
        get_change_log: function() {
            return this.$store.getters.get_change_log
        },
    },
    async mounted() {
        await this.$store.dispatch('load_change_log')
        this.versions = this.get_change_log
    },
    methods: {
        async add_new_version(){
            await this.$store.dispatch('create_new_version', this.new_version)
        },
        async delete_version(data){
            await this.$store.dispatch('delete_version', data)
        },
        async add_new_record() {
            var item = {
                change_type: this.new_change_type,
                record: this.new_record,
                id_change_log: this.new_id_change_log
            }
            await this.$store.dispatch('create_new_record', item)
            await this.$store.dispatch('load_change_log')
        },
        async delete_record(data){
            await this.$store.dispatch('delete_record', data)
            await this.$store.dispatch('load_change_log')
        },
    }
}
</script>

<script>

</script>