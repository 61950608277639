<template>
    <ModalIncidence_subcategories />
    <TableIncidence_subcategories />
</template>

<script>
import TableIncidence_subcategories from '@/components/AdminDashboard/Incidence_subcategories/TableIncidence_subcategories.vue'
import ModalIncidence_subcategories from '@/components/AdminDashboard/Incidence_subcategories/ModalIncidence_subcategories.vue'
export default {
  name: 'Incidence_subcategories',
  components: {
    TableIncidence_subcategories,
    ModalIncidence_subcategories
  }
}
</script>

<style scoped>
</style>