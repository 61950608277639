<template>
    <div class="container">
        <Timeline :value="get_change_log" class="mb-5">
            <template #content="slotProps">
                <Card class="mt-2">
                    <template #title>
                        <div class="row">
                            <div class="col-12 ms-3">
                                <strong> Version </strong> {{slotProps.item.version}}
                            </div>
                        </div>
                    </template>
                    <template #subtitle>
                        <div class="row">
                            <div class="col-12 ms-3">
                               {{slotProps.item.date}}
                            </div>
                        </div>
                        <hr>
                    </template>
                    <template #content>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div v-for="item in slotProps.item.records" :key="item">
                                        <span v-if="item.change_type=='Added'">
                                            <div class="row">
                                                <div class="col-12 md:col-2 col_change_log" style="display:flex;align-items:center;">
                                                    <Chip label="Added" class="mt-2 mb-2 added-chip"/>
                                                </div>
                                                <div class="col-12 md:col-10 col_change_log" style="display:flex;align-items:center;">
                                                    {{item.record}} 
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div v-for="item in slotProps.item.records" :key="item">
                                        <span v-if="item.change_type=='Changed'">
                                            <div class="row">
                                                <div class="col-12 md:col-2 col_change_log" style="display:flex;align-items:center;">
                                                    <Chip label="Changed" class="mt-2 mb-2 changed-chip"/>
                                                </div>
                                                <div class="col-12 md:col-10 col_change_log" style="display:flex;align-items:center;">
                                                    {{item.record}}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div v-for="item in slotProps.item.records" :key="item">
                                        <span v-if="item.change_type=='Removed'">
                                            <div class="row">
                                                <div class="col-12 md:col-2 col_change_log" style="display:flex;align-items:center;">
                                                    <Chip label="Removed" class="mt-2 mb-2 removed-chip"/>
                                                </div>
                                                <div class="col-12 md:col-10 col_change_log" style="display:flex;align-items:center;">
                                                    {{item.record}}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div v-for="item in slotProps.item.records" :key="item">
                                        <span v-if="item.change_type=='Fixed'">
                                            <div class="row">
                                                <div class="col-12 md:col-2 col_change_log" style="display:flex;align-items:center;">
                                                    <Chip label="Fixed" class="mt-2 mb-2 fixed-chip"/>
                                                </div>
                                                <div class="col-12 md:col-10 col_change_log" style="display:flex;align-items:center;">
                                                    {{item.record}}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div v-for="item in slotProps.item.records" :key="item">
                                        <span v-if="item.change_type=='Security'">
                                            <div class="row">
                                                <div class="col-12 md:col-2" style="display:flex;align-items:center;">
                                                    <Chip label="Security" class="mt-2 mb-2 security-chip"/>
                                                </div>
                                                <div class="col-12 md:col-10" style="display:flex;align-items:center;">
                                                    {{item.record}}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div v-for="item in slotProps.item.records" :key="item">
                                        <span v-if="item.change_type=='Deprecated'">
                                            <div class="row">
                                                <div class="col-12 md:col-2" style="display:flex;align-items:center;">
                                                    <Chip label="Deprecated" class="mt-2 mb-2 deprecated-chip"/>
                                                </div>
                                                <div class="col-12 md:col-10" style="display:flex;align-items:center;">
                                                    {{item.record}}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Card>
            </template>
        </Timeline>
    </div>
</template>

<script>
import Timeline from 'primevue/timeline';
import Card from 'primevue/card'
import Chip from 'primevue/chip';

export default {
    name: 'Change_log',
    components: {
        Timeline,
        Card,
        Chip,
    },
    data() {
        return {
            home: {icon: 'fa fa-home', to: '/'},
            items: [
                {label: 'Change Log'}
            ],
        }
    },
    computed: {
        get_change_log: function() {
            return this.$store.getters.get_change_log
        },
    },
    async mounted() {
        await this.$store.dispatch('load_change_log')
    }

}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 5rem;
}

:deep(.p-timeline-event-opposite){
    display: none;
}

:deep(.p-timeline-event-separator){
    display: none;
}

.added-chip {
    background-color: #22C55E;
    color: white;
    font-weight: 1000;
}

.changed-chip{
    background-color: #3B82F6;
    color: white;
    font-weight: 1000;
}

.removed-chip{
    background-color: #EF4444;
    color: white;
    font-weight: 1000;    
}

.fixed-chip{
    background-color: #64748B;
    color: white;
    font-weight: 1000;
}

.security-chip{
    background-color: #A855F7;
    color: white;
    font-weight: 1000;
}

.deprecated-chip{
    background-color: #F59E0B;
    color: white;
    font-weight: 1000;
}

.col_change_log{
    padding: 0px !important;
    padding-left: 1rem !important;
}

</style>