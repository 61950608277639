<template>
    <div class="mt-4 p-0">
        <div class="row">
            <div class="col-6 text-left p-0">
                <Calendar v-model="accounted_params.dates" selectionMode="range" dateFormat="yy-mm-dd" class="input_small" @date-select="update_clockings()"></Calendar>
            </div>
            <div class="col-6 text-right p-0">
                <Dropdown id="ports" v-model="accounted_params.port" :options="get_all_ports" optionLabel="name" :filter="true" :placeholder="defaultPort" @change="update_port($event)">
                    <template #option="slotProps">
                        <div><img :alt="slotProps.option.name" :src="slotProps.option.logo" class="img img-fluid mr-2" style="width: 40px" /> {{slotProps.option.name}}</div>
                    </template>
                    <template #value="slotProps">
                        <div v-if="!accounted_params.port">
                            <span> {{slotProps.placeholder}} </span>
                        </div>
                        <div v-else-if="slotProps.value">
                            <div><img :alt="slotProps.value.name" :src="slotProps.value.logo" class="img img-fluid mr-2" style="width: 40px" /> {{slotProps.value.name}}</div>
                        </div>
                    </template>
                </Dropdown>
            </div>
        </div>
    </div>
    <div class="row mt-4 responsive_width">
        <div class="card p-0" >
            <BillingTable :get_all_bills="get_accounted_bills" :loading="loading" :accounting="true"/>
        </div>
    </div>
</template>

<script>
import BillingTable from '../../components/Billing/Billing_table.vue'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'

import moment from 'moment'

export default {
    name: 'Billing_dashboard',
    components: {
        BillingTable,
        Dropdown,
        Calendar,

    },
    data(){
        return {
            loading: false,
            defaultPort:localStorage.getItem('user_port'),
            accounted_params: {
                dates:[new Date(moment().startOf('month').subtract(3, 'months')), new Date(moment())],
                port:''
            },
        }
    },
    computed: {
        get_accounted_bills: function() {
            return this.$store.getters.get_accounted_bills
        },
        get_all_ports: function() {
            return this.$store.getters.all_ports
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        }
    },
    async mounted() {
        this.loading = true
        await this.$store.dispatch('load_all_ports')
        await this.$store.dispatch('load_accounted_bills', this.accounted_params)
        this.loading = false
    },
    methods: {
        async update_port(e) {
            this.accounted_params.port=e.value
            this.loading = true
            await this.$store.dispatch('load_accounted_bills_by_port', this.accounted_params)
            this.loading = false
        },
        async update_clockings () {
            if(this.accounted_params.dates[1] != null) {
                if (this.accounted_params.port != ''){
                    this.loading = true
                    await this.$store.dispatch('load_accounted_bills_by_port', this.accounted_params)
                    this.loading = false
                }else{
                    this.loading = true
                    await this.$store.dispatch('load_accounted_bills', this.accounted_params)
                    this.loading = false
                }
            }
                
        },
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

:deep(.p-tabview-nav){
    justify-content: flex-start;
    border-bottom: 2px solid #dee2e6 !important;
}

@media (max-width: 1301px) {
    .responsive_width{
        width: 95vw !important; 
    }
}

.responsive_width{
    width: calc(100vw - 23rem )
}

</style>