import { createStore } from 'vuex'

import cruise_lines from './AdminDashboard/cruise_lines'
import ships from './AdminDashboard/ships'
import contracts from './AdminDashboard/contracts'
import ship_fingers from './AdminDashboard/ship_fingers'
import ship_agents from './AdminDashboard/ship_agents'
import ship_responsibles from './AdminDashboard/ship_responsibles'
import terminals from './AdminDashboard/terminals'
import users_admin from './AdminDashboard/users'
import task_categories from './AdminDashboard/task_categories'
import fingers from './AdminDashboard/fingers'
import passengers from './AdminDashboard/passengers'
import suppliers from './AdminDashboard/suppliers'
import supplier_responsibles from './AdminDashboard/supplier_responsibles'
import incidence_reports from './AdminDashboard/incidence_reports'
import incidence_categories from './AdminDashboard/incidence_categories'
import incidence_subcategories from './AdminDashboard/incidence_subcategories'
import incidence_zones from './AdminDashboard/incidence_zones'
import crews from './AdminDashboard/crews'
import ports from './AdminDashboard/ports'
import calls from './AdminDashboard/calls'
import groups from './AdminDashboard/groups'
import billing_destination from './AdminDashboard/billing_destination'
import currency from './AdminDashboard/currency'
import card from './AdminDashboard/card'
import water_meter from './AdminDashboard/water_meter'

import service_sheets from './Calls/service_sheets'
import port_calls from './Calls/port_calls'
import finger_service from './Calls/finger_service'
import validations from './Calls/validations'

import auth from './Users/auth'
import users from './Users/users'

import cleaning from './SS_Services/cleaning'
import porters from './SS_Services/porters'
import custodies from './SS_Services/custodies'
import k9 from './SS_Services/k9'
import fences from './SS_Services/fences'
import terminals_service from './SS_Services/terminals_service'
import wifi from './SS_Services/wifi'
import medical_room from './SS_Services/medical_room'
import ticket_stand from './SS_Services/ticket_stand'
import shuttle from './SS_Services/shuttle'
import logs from './SS_Services/logs'
import water from './SS_Services/water'
import custom from './SS_Services/custom'

import home from './Home/home.js'
import notifications from './Home/notifications'

import change_log from './Change_log/change_log.js'
import messages from './Operation_diary/operation_diary.js'

import statistics from './Statistics/statistics.js'

import perms from './Perms/perms.js'

import board from './Maintenance/board.js'

import daily_logs from './Daily_log/daily_log.js'

import bills from './Billing/Bills.js'

const storeConfiguration = {
    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters: {
    },
    modules: {
        auth,
        users,
        cruise_lines,
        ships,
        terminals,
        users_admin,
        task_categories,
        fingers,
        passengers,
        suppliers,
        supplier_responsibles,
        ship_agents,
        ship_responsibles,
        ship_fingers,
        crews,
        ports,
        calls,
        contracts,
        incidence_reports,
        incidence_zones,
        incidence_categories,
        incidence_subcategories,
        port_calls,
        groups,
        billing_destination,
        service_sheets,
        finger_service,
        cleaning,
        porters,
        custodies,
        k9,
        fences,
        terminals_service,
        wifi,
        medical_room,
        ticket_stand,
        shuttle,
        logs,
        water,
        home,
        notifications,
        perms,
        change_log,
        board,
        daily_logs,
        bills,
        messages,
        validations,
        statistics,
        currency,
        card,
        custom,
        water_meter
    }
}

/*
const defaultOverrides = {
    state: () => {
        return {}
    }
}

function makeState(initialState, overrideState) {
    return {
        ...(typeof initialState === 'function' ? initialState() : initialState),
        ...overrideState()
    }
}

export function createStore(storeOverrides = defaultOverrides) {
    return vuexCreateStore({
        ...storeConfiguration,
        ...storeOverrides,
        ...{
            state: makeState(storeConfiguration.state, storeOverrides.state)
        }
    })
}
*/
export default createStore(storeConfiguration)