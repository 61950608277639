import axios from 'axios'

const state = {
    cards: [],
    card_errors: []
}

const mutations = {
    SET_CARDS(state, cards) {
        state.cards = cards
    },
    NEW_CARD(state, card) {  
        state.cards.push(card)
        state.card_errors={}
    },
    DELETE_CARD(state, id) {
        let index = state.cards.findIndex(card => card.id == id)
        state.cards.splice(index, 1)
    },
    UPDATE_CARD(state, card) {
        state.cards = state.cards.map(updated_card => {
            if(updated_card.id === card.id) {
                updated_card.name = card.name
                updated_card.number = card.number
                updated_card.balance = card.balance
                updated_card.bank = card.bank
                updated_card.id_user_assigned = card.id_user_assigned
            }
            return updated_card
        })
        state.card_errors={}
    },
    ERRORS_CARD(state, errors){
        state.card_errors = errors
    },
    DELETE_ERROR(state){
        state.card_errors = []
    }
}

const actions = {
    async load_all_cards({commit}) {        
        axios.get(process.env.VUE_APP_API_URL  + "cards/")
            .then((response) => {
                commit('SET_CARDS', response.data)
            })
    },
    async delete_card({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL  + 'cards/' + id)
            .then(() => {
                commit('DELETE_CARD', id)
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
    }, 
    async create_card({commit}, card ) {
        await axios.post(process.env.VUE_APP_API_URL  + 'cards/', { name: card.name, number: card.number, balance: card.balance, bank: card.bank, id_user_assigned: card.id_user_assigned })
            .then((response) => {
                commit('NEW_CARD', response.data)
            })
            .catch((error) => {
                commit('ERRORS_CARD', error.response.data)
            })
    },
    async update_card({commit}, card ) {
        await axios.put(process.env.VUE_APP_API_URL  + 'cards/' + card.id + '/',  { name: card.name, number: card.number, balance: card.balance, bank: card.bank, id_user_assigned: card.id_user_assigned.id })
            .then((response) => {
                commit('UPDATE_CARD', response.data)
            })
            .catch((error) => {
                commit('ERRORS_CARD', error.response.data)
            })
    },
}

const getters = {
    get_cards: (state) => state.cards,
    get_card_errors: (state) => state.card_errors
}

export default {
    state,
    mutations,
    actions,
    getters
};