import axios from 'axios'

const state = {
    medical_room: {},
}

const mutations = {
    SET_MEDICAL_ROOM_SERVICE(state, medical_room) {
        state.medical_room = medical_room
    },
    CREATE_MEDICAL_ROOM_SERVICE(state, medical_room) {
        state.medical_room = medical_room
    },
    DELETE_MEDICAL_ROOM_SERVICE(state) {
        state.medical_room = {}
    }
}

const actions = {
	async load_medical_room_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'medical_room/get_service/' + id) 
			.then((response) => {
				commit('SET_MEDICAL_ROOM_SERVICE', response.data)
			})
	},
    async create_medical_room_service({commit}, id) {
        await axios.post(process.env.VUE_APP_API_URL + 'medical_room/', {
            id_service_sheet: id
        })
            .then((response) => {
                commit('CREATE_MEDICAL_ROOM_SERVICE', response.data)
            })
    },
    async delete_medical_room_service({commit}, wifi_service) {
        await axios.delete(process.env.VUE_APP_API_URL + 'medical_room/' + wifi_service.id + '/')
            .then((response) => {
                commit('DELETE_MEDICAL_ROOM_SERVICE', response.data)
            })
    }
}

const getters = {
    get_medical_room_service: (state) => state.medical_room,
}

export default {
    state,
    mutations,
    actions,
    getters
};