import axios from 'axios'
import moment from 'moment'

const state = {
    water_meters: [],
    water_meters_errors: {},
    delete_status : false,
    last_reading: []
};

const mutations = {
    SET_WATER_METERS(state, payload) {
        state.water_meters = payload
    },
    SET_LAST_READING(state, payload) {
        state.last_reading = payload
    },
    NEW_WATER_METER(state, payload) {  
        state.water_meters.push(payload)
        state.water_meters_errors = {}
    },
    DELETE_WATER_METER(state, id) {
        let index = state.water_meters.findIndex(water_meter => water_meter.id == id)
        state.water_meters.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_WATER_METERS(state, payload) {
        state.water_meters = state.water_meters.map(updated_water_meter => {
            if(updated_water_meter.id === payload.id) {
                updated_water_meter.name = payload.name
                updated_water_meter.id_terminal = payload.id_terminal
            }
            return updated_water_meter
        })
        state.water_meters_errors = {}
    },
    ERRORS_WATER_METERS(state, payload){
        state.water_meters_errors = payload
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    },
    RESET_WATER_READING(state) {
        state.last_reading = []
    }
};

const actions = {
    async load_all_water_meters({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "water_meters/")
            .then((response) => {
                commit('SET_WATER_METERS', response.data)
            })
    },
    async load_last_reading({commit}, data) {
        var date = moment(data.date).format('DD-MM-YYYY') 

        await axios.get(process.env.VUE_APP_API_URL + "water_meters/get_last_reading/" + data.id_water_meter + '/' + date + '/')
            .then((response) => {
                commit('SET_LAST_READING', response.data)
            })
    },
    async create_water_meter({commit}, payload) {
        await axios.post(process.env.VUE_APP_API_URL  + 'water_meters/' , {
            name: payload.name,
            id_terminal: payload.id_terminal.id
        })
            .then((response) => {
                commit('NEW_WATER_METER', response.data)
            })
            .catch((error) => {
                commit('ERRORS_WATER_METERS', error.response.data)
            })
    },
    async delete_water_meter({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'water_meters/' + id)
            .then(() => {
                commit('DELETE_WATER_METER', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async update_water_meter({commit}, payload) {
        await axios.put(process.env.VUE_APP_API_URL  + 'water_meters/' + payload.id + '/',  { 
            name: payload.name,
            id_terminal: payload.id_terminal.id
         })
            .then((response) => { 
                commit('UPDATE_WATER_METERS', response.data)
            })
            .catch((error) => {
                commit('ERRORS_WATER_METERS', error.response.data)
            })
    },
    reset_water_reading({commit}) {
        commit('RESET_WATER_READING')
    }
};

const getters = {
    all_water_meters: (state) => state.water_meters,
    get_errors_water_meter: (state) => state.water_meters_errors,
    get_delete_water_meter_status:(state) => state.delete_status,
    get_last_reading: (state) => state.last_reading
};

export default {
    state,
    mutations,
    actions,
    getters
};