<template>
    <ModalPorts/>
    <TablePorts/>
</template>

<script>
import TablePorts from '@/components/AdminDashboard/Ports/TablePorts.vue'
import ModalPorts from '@/components/AdminDashboard/Ports/ModalPorts.vue'
export default {
  name: 'Cruise_lines',
  components: {
    TablePorts,
    ModalPorts
  }
}
</script>

<style scoped>
</style> 
