<template>
    <div class="container mt-4">
        <div class="content-section introduction mt-4">
            <div class="feature-intro">
                <h1> Service Reports </h1>
            </div>
        </div>
        <div class="row justify-content-between ">
            <div class="col-12 md:col-6 card mt-3">
                <div class="container row">
                    <div class="col">
                        <h3> Porters </h3>
                        <p style="margin-bottom: 0px;"> Generate report for the selected month </p>
                    </div>
                </div>
                <div class="container row p-fluid">
                    <div class="col">
                        <Calendar v-model="date" showIcon dateFormat="MM" view="month" @date-select="get_data()"/>
                    </div>
                </div>
                <div class="container row">
                    <div class="col">
                        <p style="margin-bottom: 0px;"> Select the supplier </p>
                    </div>
                </div>
                <div class="container row p-fluid mb-3">
                    <div class="col">
                        <Dropdown v-model="supplier" :options="all_suppliers" optionLabel="name" @change="get_data()"/>
                    </div>
                </div>
            </div>
            <div v-if="date !== null && Object.keys(supplier).length != 0" class="col-12 md:col-3 card align-self-start mt-3">
                <div class="row ">
                    <div class="col text-center">
                        <h3> Send Mail </h3>
                        <p style="margin-bottom: 0px;"> Generate mail for the selected service </p>
                    </div>
                </div>
                <div class="row ">
                    <div class="col text-center">
                        <Button label="Send" @click="open_modal_mail"/>
                    </div>
                </div>
            </div>
        </div>
 
        <div v-if="date !== null && Object.keys(supplier).length != 0" class="content-section introduction mt-4">
            <div class="feature-intro">
                <h1> Information </h1>
                <p> Monthly information about the porters service.</p>
            </div>
        </div>
        <div v-if="date !== null && Object.keys(supplier).length != 0" class="row mt-2">
            <div class="row">
                <div class="col-12 md:col-6 lg:col-3">
                    <div class="container card">
                        <div class="row">
                            <div class="col">
                                <h3> Embarking </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col my-auto">
                                <small> QUANTITY </small> 
                            </div>
                            <div class="col text-end ">
                                <h4>{{get_data_report_porters['embarking'] ? get_data_report_porters['embarking'] : 0}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-3">
                    <div class="container card">
                        <div class="row">
                            <div class="col">
                                <h3> Disembarking </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col my-auto">
                                <small> QUANTITY </small> 
                            </div>
                            <div class="col text-end">
                                <h4>{{get_data_report_porters['disembarking'] ? get_data_report_porters['disembarking'] : 0}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-section introduction mt-4">
                <div class="feature-intro">
                    <h1> Extra Services </h1>
                </div>
            </div>
            
            <div v-if="get_data_report_porters['extra_services']" class="row mt-2 mb-5">
                <span v-if="Object.keys(get_data_report_porters['extra_services']).length == 0">
                    <span> No extra services. </span>
                </span>
                <div v-else v-for="(item,pos) in get_data_report_porters['extra_services']" :key="item" class="col-12 md:col-4">
                    <div class="container card">
                        <div class="row">
                            <div class="col">
                                <h3> {{pos}} </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col my-auto">
                                <small> QUANTITY </small> 
                            </div>
                            <div class="col text-end">
                                <h4>{{item['quantity']}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col my-auto"> 
                                <small> HOURS </small>
                            </div>
                            <div class="col text-end">
                                <h4>{{item['total_hours']}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <Dialog header="Confirmation" v-model:visible="modal_mail_confirmation" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div class="row">
            <div class="col">
                <p> The financial report for {{format_date(date)}} will be sent to {{supplier.name}}. Would you like to authorize the report to be sent to them? </p>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" @click="modal_mail_confirmation = false" class="p-button-text"/>
            <Button label="Yes" @click="send_mail" autofocus />
        </template>
    </Dialog>

</template>

<script>
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'

import moment from 'moment'
import axios from 'axios'

export default {
    name: 'Services',
    components: {
        Calendar,
        Dropdown,
        Button,
        Dialog
    },
    data() {
        return {
            date: '',
            supplier: [],
            modal_mail_confirmation: false,
        }
    },
    computed: {
        all_suppliers: function() {
            return this.$store.getters.all_suppliers
        },
        get_data_report_porters: function() {
            return this.$store.getters.get_data_report_porters
        }
    },
    mounted() {
        this.$store.dispatch('load_all_suppliers')
    },
    methods: {
        get_data() {
            
            if(this.date != '' && Object.keys(this.supplier).length != 0 != '') {
                var data = {
                    month: moment(this.date).format('M'),
                    year: moment(this.date).format('YYYY'),
                    supplier: this.supplier.id
                }
                this.$store.dispatch('get_data_porters', data)
            }

        },
        open_modal_mail() {
            this.modal_mail_confirmation = true
        },
        async send_mail(){
            var data = {
                month: moment(this.date).format('M'),
                year: moment(this.date).format('YYYY'),
                supplier: this.supplier.id
            }

            await axios.post(process.env.VUE_APP_API_URL + 'porter/mail_monthly_porters/send_mail/' + data.month + '/' + data.year + '/' + data.supplier + '/')
            this.$toast.add({severity:'success', summary: 'Success', detail:'Email sent correctly.', life: 3000});

            this.modal_mail_confirmation = false
        },
        format_date(value){
            return moment(value).format('MMMM YYYY')
        }
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}


@media (max-width: 500px) {
    .row{
        --bs-gutter-x: 0;
    }
}

</style>