import axios from 'axios'

const state = {
    incidence_categories: [],
    incidence_categories_errors:{},
    delete_status:false,
    category_names: []
};

const mutations = {
    SET_INCIDENCE_CATEGORIES(state, incidence_categories) {
        state.incidence_categories = incidence_categories
    },
    SET_INCIDENCE_CATEGORIES_NAME(state, incidence_categories_names) {
        state.category_names = incidence_categories_names
    },
    NEW_INCIDENCE_CATEGORIES(state, incidence_categories) {  
        state.incidence_categories.push(incidence_categories)
        state.incidence_categories_errors={}
    },
    DELETE_INCIDENCE_CATEGORIES(state, id) {
        let index = state.incidence_categories.findIndex(incidence_categories => incidence_categories.id == id)
        state.incidence_categories.splice(index, 1)
        state.delete_status=false
    },
    UPDATE_INCIDENCE_CATEGORIES(state, incidence_category) {
        state.incidence_categories = state.incidence_categories.map(updated_incidence_categories => {
            if(updated_incidence_categories.id === incidence_category.id) {
                updated_incidence_categories.title = incidence_category.title
                updated_incidence_categories.description = incidence_category.description
                updated_incidence_categories.incidence_type = incidence_category.incidence_type
            }
            return updated_incidence_categories
        })
        state.incidence_categories_errors={}
    },
    ERRORS_INCIDENCE_CATEGORIES(state, errors){
        state.incidence_categories_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};
const actions = {
    async load_all_incidence_categories({commit}) {        
        let names = []
        await axios.get(process.env.VUE_APP_API_URL  + "incidence_categories/")
            .then((response) => {
                commit('SET_INCIDENCE_CATEGORIES', response.data)
                response.data.forEach(element => {
                    names.push(element.title)
                });
                commit('SET_INCIDENCE_CATEGORIES_NAME', names)
            })
    },
    async delete_incidence_category({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL  + 'incidence_categories/' + id)
            .then(() => {
                commit('DELETE_INCIDENCE_CATEGORIES', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async create_incidence_category({commit}, incidence_categories) {
        await axios.post(process.env.VUE_APP_API_URL  + 'incidence_categories/', incidence_categories)
            .then((response) => {
                commit('NEW_INCIDENCE_CATEGORIES', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_INCIDENCE_CATEGORIES', error.response.data)
            })
    },
    async update_incidence_category({commit}, incidence_category) {
        await axios.put(process.env.VUE_APP_API_URL  + 'incidence_categories/' + incidence_category.id + '/',  { title: incidence_category.title, description: incidence_category.description, incidence_type:incidence_category.incidence_type })
            .then(() => {
                commit('UPDATE_INCIDENCE_CATEGORIES', incidence_category)
            })
            .catch((error) => {
                
                commit('ERRORS_INCIDENCE_CATEGORIES', error.response.data)
            })
    },
};

const getters = {
    all_incidence_categories: (state) => state.incidence_categories,
    get_errors_incidence_category: (state) => state.incidence_categories_errors,
    get_delete_incidence_category_status:(state) => state.delete_status,
    general_incidences_category: (state) => {
        return state.incidence_categories.filter(incidence_category => incidence_category.incidence_type === 'General' || incidence_category.incidence_type === 'Both')
    },
    call_incidences_category: (state) => {
        return state.incidence_categories.filter(incidence_category => incidence_category.incidence_type === 'Call' || incidence_category.incidence_type === 'Both')
    },
    get_category_names:(state) => state.category_names,
    get_finger_category_names: (state) => {
        return state.incidence_categories.filter(incidence_category => incidence_category.title.toLowerCase().includes('finger'))
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};