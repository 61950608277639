import axios from 'axios'
import moment from 'moment'

const state = {
    custodian_service: [],
    send_custodian_mail: false,
    error_custodian_services: []
}

const mutations = {
    SET_CUSTODIES_SERVICE(state, custodian_service) {
        state.custodian_service = custodian_service
    },
    ADD_CUSTODIES_SERVICE(state, custodian_service){
        state.custodian_service.push(custodian_service)
    },
    SET_ERROR_CUSTODIAN_SERVICES(state, error_custodian_services) {
        state.error_custodian_services.push(error_custodian_services)
    },
    UPDATE_CUSTODIES_SERVICE(state, custodian_service) {
        state.custodian_service = state.custodian_service.map(updated_custodian_service => {
            if(updated_custodian_service.id === custodian_service.id) {
                updated_custodian_service.id_supplier = custodian_service.id_supplier
                updated_custodian_service.id_supplier_responsible = custodian_service.id_supplier_responsible
                updated_custodian_service.note_info = custodian_service.note_info
                updated_custodian_service.mail_text = custodian_service.mail_text
                updated_custodian_service.quantity = custodian_service.quantity,
                updated_custodian_service.start_datetime = custodian_service.start_datetime
            }
        })
    },
    UPDATE_MAIL_STATUS_CUSTODIES(state, cond) {
        if(cond == true) {
            state.send_custodian_mail = true    
        }
    },
    UPDATE_STATUS_MAIL_CUSTODIES(state) {
        state.send_custodian_mail = false
    },
    DELETE_CUSTODIAN(state, id){
        let index = state.custodian_service.findIndex(custodian_service => custodian_service.id == id)
        state.custodian_service.splice(index, 1)
    },
    CLEAN_ERROR_CUSTODIAN(state) {
        state.error_custodian_services = []
    },
}

const actions = {
	async load_custodian_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'custodian/get_service/' + id) 
			.then((response) => {
				commit('SET_CUSTODIES_SERVICE', response.data)
			})
	},

    async create_past_custodian({commit}, id_ss){
        await axios.post(process.env.VUE_APP_API_URL + 'custodian/create_past_custodian/' + id_ss + '/')
            .then((response) => {
                commit('SET_CUSTODIES_SERVICE', response.data)
            })
            .catch(() => {})
    },
	async create_custodian_services({commit}, custodian_services) {
        if(custodian_services.start_datetime_hour == '') {
            start_datetime = ''
        } else {
            if(typeof custodian_services.start_datetime_hour === 'string') {
                custodian_services.start_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + custodian_services.start_datetime_hour))
            }
            var start_datetime = new Date(new Date(custodian_services.start_datetime).getFullYear(), new Date(custodian_services.start_datetime).getMonth(), new Date(custodian_services.start_datetime).getDate(),
                new Date(custodian_services.start_datetime_hour).getHours(), new Date(custodian_services.start_datetime_hour).getMinutes(), new Date(custodian_services.start_datetime_hour).getSeconds()) 
            start_datetime = moment(start_datetime).format().split('+')[0]
        }
        
		await axios.post(process.env.VUE_APP_API_URL + 'custodian/', {
            id_service_sheet: custodian_services.id_service_sheet,
            id_supplier: custodian_services.id_supplier.id,
            id_supplier_responsible: custodian_services.id_supplier_responsible.id,
            quantity: custodian_services.quantity,
            start_datetime: start_datetime,
            note_info: custodian_services.note_info,
            mail_text: custodian_services.mail_text,
        })  
			.then((response) => {
                commit('ADD_CUSTODIES_SERVICE', response.data)
			})
			.catch((error) => {
                commit('SET_ERROR_CUSTODIAN_SERVICES', error.response.data)
			})
	},
    async update_custodian_services({commit}, custodian_services) {
        if(typeof(custodian_services.start_datetime_hour) === 'string') {
            var start_datetime = (new Date(moment(custodian_services.start_datetime).format().split('T')[0] + 'T' + custodian_services.start_datetime_hour))
        } else {
            start_datetime = new Date(new Date(custodian_services.start_datetime).getFullYear(), new Date(custodian_services.start_datetime).getMonth(), new Date(custodian_services.start_datetime).getDate(),
                                new Date(custodian_services.start_datetime_hour).getHours(), new Date(custodian_services.start_datetime_hour).getMinutes(), new Date(custodian_services.start_datetime_hour).getSeconds())
        }

        start_datetime = moment(start_datetime).format().split('+')[0]  

        await axios.patch(process.env.VUE_APP_API_URL + 'custodian/' + custodian_services.id + '/', {
            id_supplier_responsible: custodian_services.id_supplier_responsible.id,
            id_supplier: custodian_services.id_supplier.id,
            note_info: custodian_services.note_info,
            mail_text: custodian_services.mail_text,
            start_datetime: start_datetime,
            quantity: custodian_services.quantity
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_CUSTODIES', response.data.edited)
                commit('UPDATE_CUSTODIES_SERVICE', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_CUSTODIAN_SERVICES', error.response.data)
            })
    },
    async delete_custodian({commit}, id){
        await axios.delete(process.env.VUE_APP_API_URL + 'custodian/' + id + '/')
            .then((response) => {
                commit('DELETE_CUSTODIAN', response.data)
            }) 
            .catch((error) => {
                commit('SET_ERROR_CUSTODIAN', error.response.data)
            })
    },
    async send_custodian_service_mail({commit}, id){
        
        await axios.post(process.env.VUE_APP_API_URL + 'custodian/send_mail/' + id + '/')
            .then(() => {
                commit('UPDATE_STATUS_MAIL_CUSTODIES')
                commit('CLEAN_ERROR_CUSTODIAN')
            })
            .catch((error) => {
                commit('SET_ERROR_CUSTODIAN_SERVICES', error.response.data)
            })
    },
    async clean_errors_custodian({commit}) {
        commit('CLEAN_ERROR_CUSTODIAN')
    }
       
}

const getters = {
    get_custodian_services: (state) => state.custodian_service,
    get_send_custodian_mail: (state) => state.send_custodian_mail,
    get_error_custodian_services: (state) => state.error_custodian_services
}

export default {
    state,
    mutations,
    actions,
    getters
};