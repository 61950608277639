<template>
    <div class="mt-5">
        <div class="card">
            <div v-if="loading" class="flex justify-content-center p-4">
                <i class="pi pi-spin pi-spinner" style="font-size: 4rem;" :style="{color: color}"></i>
            </div>
            <div v-else class="row justify-content-around">
                <div class="col-12 md:col-6">
                    <div class="row justify-content-center">
                        <div class="col-10">
                            <div class="row">
                                <div class="col">
                                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Information </h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color:gray"> NUMBER </small> 
                                </div>
                                <div class="col text-end">
                                    <strong> {{ current_bill.number ? current_bill.number : '-' }} </strong> 
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color:gray"> CARD </small> 
                                </div>
                                <div class="col text-end">
                                    <strong> {{ current_bill.id_card ? current_bill.id_card.number : '-' }} </strong> 
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color: gray"> SUPPLIER </small> 
                                </div>
                                <div class="col text-end">
                                    <strong> {{ current_bill.id_supplier ? current_bill.id_supplier.name : '-' }} </strong> 
                                </div>
                            </div>
                            <div class="row">
                                <div class="offset-1 col">
                                    <small style="color: gray"> CCC </small> 
                                </div>
                                <div class="col text-end">
                                    <strong> {{ current_bill.supplier ? current_bill.supplier.ccc : '-' }} </strong> 
                                </div>
                            </div>
                            <div class="row">
                                <div class="offset-1 col">
                                    <small style="color: gray"> CIF </small> 
                                </div>
                                <div class="col text-end">
                                    <strong> {{ current_bill.supplier ? current_bill.supplier.cif : '-' }} </strong> 
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color: gray"> TERMINAL/S </small> 
                                </div>
                                <div v-if="current_bill.terminals" class="col text-end">
                                    <span v-if="current_bill.terminals.length == 0">
                                        <strong> - </strong>
                                    </span>
                                    <span v-else v-for="(item,index) in current_bill.terminals" :key="index">
                                        <strong> 
                                            {{item.name}}<span v-if="index !== current_bill.terminals.length - 1">, </span>
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <div class="row justify-content-center">
                        <div class="col-10">
                            <div class="row">
                                <div class="col">
                                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Dates </h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color: gray"> ISSUE DATE </small> 
                                </div>
                                <div class="col text-end">
                                    <strong> {{ current_bill.issue_date  ? current_bill.issue_date : '-' }} </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color: gray"> PAYMENT DATE </small> 
                                </div>
                                <div class="col text-end">
                                    <strong> {{ current_bill.payment_date  ? current_bill.payment_date : '-' }} </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color: gray"> DUE DATE </small> 
                                </div>
                                <div class="col text-end">
                                    <strong> {{ current_bill.due_date  ? current_bill.due_date : '-' }} </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Bill Justification </h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color: gray"> CONTRACT </small> 
                                </div>
                                <div class="col text-end">
                                    <strong> {{ current_bill.contract  ? current_bill.contract.name : '-' }} </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="offset-1 col">
                                    <small style="color: gray"> EXPIRATION DATE </small> 
                                </div>
                                <div class="col text-end">
                                    <strong> {{ current_bill.contract ? current_bill.contract.end_date : '-' }} </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color: gray"> BUDGET</small> 
                                </div>
                                <div class="col text-end">
                                    <strong v-if="current_bill.budget"> Yes </strong> 
                                    <strong v-else> No </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="card mt-2">
        <div class="flex justify-content-around">
            <div class="col-12">
                <Datatable :value="get_imports_from_bills">
                    <Column header="Description" field="description"></Column>
                    <Column header="Quantity" field="quantity"></Column>
                    <Column header="Base" field="base_import"></Column>
                    <Column header="IVA" field="iva"></Column>
                    <Column header="Total" field="total_import"></Column>
                    <Column header="Borrar" style="width: 8rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger"  @click="delete_import_bills(slotProps)"></Button>
                        </template>
                    </Column>
                </Datatable>
            </div>
        </div>
        <div class="grid p-fluid mt-1 mb-2 p-3">
            <div class="col-12 md:col pt-3">
                <div class="p-float-label">
                    <InputText v-model="bill_import.description"></InputText>
                    <label> Description </label>
                </div>
                <small v-if="validationErrors.description && submitted" class="p-error"> Description is required. </small>
                <span v-else>
                    <small v-for="item in error_imports.description" :key="item">
                        <span v-if="item">
                            <span class="p-error"> {{ item }} </span>
                        </span>
                    </small> 
                </span>         
            </div>
            <div class="col-12 md:col pt-3">
                <div class="p-float-label">
                    <InputNumber v-model="bill_import.quantity" @input="change_total_quantity($event)"></InputNumber>
                    <label> Quantity </label>
                </div>
                <small v-if="validationErrors.quantity && submitted" class="p-error"> Quantity is required. </small>
                <span v-else>
                    <small v-for="item in error_imports.quantity" :key="item">
                        <span v-if="item">
                            <span class="p-error"> {{ item }} </span>
                        </span>
                    </small> 
                </span> 
            </div>
            <div class="col-12 md:col pt-3">
                <div class="p-float-label">
                    <InputNumber v-model="bill_import.base" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" @input="change_total_base($event)"></InputNumber>
                    <label> Base </label>
                </div>
                <small v-if="validationErrors.base && submitted" class="p-error"> Base Import is required. </small>
                <span v-else>
                    <small v-for="item in error_imports.base_import" :key="item">
                        <span v-if="item">
                            <span class="p-error"> {{ item }} </span>
                        </span>
                    </small> 
                </span> 
            </div>
            <div class="col-12 md:col pt-3">
                <div class="p-float-label" >
                    <InputNumber v-model="bill_import.iva" suffix=" %" :min="0" :max="100" :minFractionDigits="2" @input="change_total_iva($event)"></InputNumber>
                    <label> Iva </label>
                </div>
            </div>
            <div class="col-12 md:col pt-3">
                <div class="p-float-label">
                    <InputNumber v-model="total_import" mode="decimal" :minFractionDigits="2" disabled></InputNumber>
                    <label> Total </label>
                </div>
            </div>
            <div class="col-12 md:col text-center">
                <Button icon="pi pi-plus" class="p-button-outlined p-button-rounded" @click="add_import_to_table"/>
            </div>
        </div>
    </div>
    <div class="grid grid-nogutter justify-content-between mt-3 mb-5">
        <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" disabled/>
        <Button @click="complete_another()" icon="fa fa-arrow-rotate-right" iconPos="right" class="p-button-success" />
        <Button label="Complete" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success" />
    </div>
</template>

<script>
import Button from 'primevue/button'
import Datatable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'

export default {
    emits: ['prev-page', 'complete', 'complete_another'],
    props: {
        formData: Object
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            bill_import: {
                description: '',
                quantity: 1,
                base: 0,
                iva: 21,
                id_bill: 0
            },
            total_import: 0,
            validationErrors: [],
            submitted: false,
            loading: true,
        }
    },
    components: {
        Button,
        Datatable,
        Column,
        InputText,
        InputNumber
    },
    computed: {
        get_imports_from_bills: function () {
            return this.$store.getters.get_imports_from_bills
        },
        id_bill: function () {
            return this.$store.getters.id_bill
        },
        error_imports: function() {
            return this.$store.getters.error_imports
        },
        current_bill: function() {
            return this.$store.getters.current_bill
        }
    },
    async activated() {

        if(Object.keys(this.formData).length == 0) {
            this.$router.push({path: '/billing/steps'})
        } else {
            this.bill_import.id_bill = this.id_bill
            if(this.id_bill == '') {
                await this.$store.dispatch('create_new_bill', this.formData)
                this.bill_import.id_bill = this.id_bill
            }
        }
        
        this.loading = false
    },
    methods: {
        change_total_quantity(event) {
            this.total_import = this.roundToTwo((event.value * this.bill_import.base) + ((event.value * this.bill_import.base) * (this.bill_import.iva / 100)))
        },
        change_total_base(event) {
            this.total_import = this.roundToTwo((event.value * this.bill_import.quantity) + ((event.value * this.bill_import.quantity) * (this.bill_import.iva / 100)))
        },
        change_total_iva(event) {
            this.total_import = this.roundToTwo((this.bill_import.base * this.bill_import.quantity) + ((this.bill_import.base * this.bill_import.quantity) * (event.value / 100)))
        },
        roundToTwo(num) {
            return + (Math.round(num + "e+2")  + "e-2");
        },
        async add_import_to_table() {
            this.submitted = true
            if (this.validateForm()) {
                await this.$store.dispatch('add_import', this.bill_import)

            }
        },
        validateForm() {
            if (!this.bill_import.description) { 
                this.validationErrors['description'] = true;
            } else {
                delete this.validationErrors['description'];
            } 
            if (!this.bill_import.quantity) { 
                this.validationErrors['quantity'] = true;
            } else {
                delete this.validationErrors['quantity'];
            }
            if (!this.bill_import.base) { 
                this.validationErrors['base'] = true;
            } else {
                delete this.validationErrors['base'];
            }
            return !Object.keys(this.validationErrors).length;
        },
        async delete_import_bills(data) {
            await this.$store.dispatch('delete_import_bill', data.data.id)
        },
        prevPage() {
            this.$emit('prev-page', { pageIndex: 3 });
        },
        complete() {
            this.$store.dispatch('empty_imports_bills')
            this.$emit('complete');
        },
        complete_another() {
            this.$emit('complete_another');
        }
    }
}
</script>

<style scoped>

</style>