import axios from 'axios'
import moment from 'moment'

const state = {
    all_daily_logs: [],
    daily_logs: [],
    weekly_ships: [],
    error_daily_logs: [],
    provisional_record: [],
    records_between: []
}

const mutations = {
    SET_ALL_DAILY_LOGS(state, all_daily_logs){
        state.all_daily_logs = all_daily_logs.map(updated_daily_log => {
            updated_daily_log.date = new Date(updated_daily_log.date)
            
            return updated_daily_log
        })
    },
    SET_DAILY_LOGS(state, daily_log) {
        state.daily_logs = daily_log
    },
    SET_WEEKLY_SHIPS(state, weekly_ships) {
        state.weekly_ships = weekly_ships
    },
    ADD_DAILY_LOGS(state, daily_log) {
        state.daily_logs.push(daily_log)
    },
    EDIT_DAILY_LOG(state, daily_log){
        state.daily_logs = state.daily_logs.map(updated_daily_log => {
            if(updated_daily_log.id === daily_log.id) {
                updated_daily_log.id_terminal = daily_log.id_terminal
                updated_daily_log.date = daily_log.date
                updated_daily_log.hour = daily_log.hour
                updated_daily_log.text = daily_log.text
                updated_daily_log.id_task = daily_log.id_task
            }
            return updated_daily_log
        })
    },
    DELETE_DAILY_LOG(state, id) {
        let index = state.daily_logs.findIndex(daily_logs => daily_logs.id == id)
        state.daily_logs.splice(index, 1)
    },
    SET_ERROR_DAILY_LOG(state, payload){
        state.error_daily_logs = payload
    },
    EMPTY_ERROR_DAILY_LOG(state) {
        state.error_daily_logs = []
    },
    PROVISIONAL_RECORD(state, payload) {
        state.provisional_record = payload
    },
    CLEAN_PROVISIONAL_RECORD(state) {
        state.provisional_record = []
    },
    SET_RECORDS_BETWEEN_DATES(state, payload) {
        state.records_between = payload
    }
}

const actions = {
    async load_all_daily_logs({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "daily_logs/") 
            .then((response) => {
                commit('SET_ALL_DAILY_LOGS', response.data)
            })
    },
    async load_daily_logs({commit},date) {
        await axios.get(process.env.VUE_APP_API_URL + "daily_logs/get_by_date/" + date + "/")
            .then((response) => {
                commit('SET_DAILY_LOGS', response.data)
            })
    },
    async load_weekly_ships({commit}, data){
        await axios.get(process.env.VUE_APP_API_URL + "ships/get_week_by_date_terminal/" + data.date + "/" + data.terminal + "/")
            .then((response) => {
                commit('SET_WEEKLY_SHIPS', response.data)
            })
    },
    async create_daily_log({commit}, data) {
        await axios.post(process.env.VUE_APP_API_URL + "daily_logs/", data)
            .then((response) => {
                commit('EMPTY_ERROR_DAILY_LOG')
                commit('ADD_DAILY_LOGS', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_DAILY_LOG', error.response.data)
            })
    },
    async edit_daily_log({commit}, data) {
        await axios.patch(process.env.VUE_APP_API_URL + "daily_logs/" + data.id + "/", data)
            .then((response) => {
                commit('EMPTY_ERROR_DAILY_LOG')
                commit('EDIT_DAILY_LOG', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_DAILY_LOG', error.response.data)
            })
    },
    async update_daily_log_task({commit}, data) {
        await axios.patch(process.env.VUE_APP_API_URL + "daily_logs/" + data.id + "/", {
            id_task: data.task.data.id
        })
            .then((response) => {
                commit('EDIT_DAILY_LOG', response.data)
            })
    },
    async delete_daily_log({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "daily_logs/" + id + "/")
            .then(() => {
                commit('DELETE_DAILY_LOG', id)
            })
    } ,
    async empty_errors_daily_log({commit}) {
        commit('EMPTY_ERROR_DAILY_LOG')
    },
    async provisional_record({commit}, params) {
        commit('PROVISIONAL_RECORD', params)
    },
    async clean_provisional_record({commit}){
        commit('CLEAN_PROVISIONAL_RECORD')
    },
    async load_records_between_dates({commit}, data) {
        await axios.get(process.env.VUE_APP_API_URL + 'daily_logs/get_by_date_range/' + moment(data[0]).format('YYYY-MM-DD') + '/' + moment(data[1]).format('YYYY-MM-DD') + '/')
            .then((response) => {
                commit('SET_RECORDS_BETWEEN_DATES', response.data)
            })
    }
}

const getters = {
    all_daily_logs: (state) => state.all_daily_logs,
    daily_logs: (state) => state.daily_logs,
    weekly_ships: (state) => state.weekly_ships,
    get_error_daily_logs: (state) => state.error_daily_logs,
    get_provisional_record: (state) => state.provisional_record,
    records_between: (state) => state.records_between
}

export default {
    state,
    actions,
    mutations,
    getters
}