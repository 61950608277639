<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Incidence Reports </h1>
            <div class="card p-1">
                <Button data-cy="new_incidence_report_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Incidence report'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_incidence_report_modal" header="New Incidence report" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <form id="form" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-6 pt-4">
                            <div class="p-float-label">     
                                <InputText id="title" v-model="v$.title.$model" :class="{'p-invalid':v$.title.$invalid && submitted || incidence_report_errors.title}" />
                                <label for="title" :class="{'p-error':v$.title.$invalid && submitted}"> Title*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.title" class="p-error"><span v-for="error in incidence_report_errors.title" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">{{v$.title.required.$message.replace('Value', 'Title')}}</small>
                        </div>
                        <div class="col-10 md:col-6 pt-4">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_incidence_type" id="incidence_type" v-model="incidence_type" :options="incidence_type_choices" :class="{'p-invalid':v$.incidence_type.$invalid && submitted || incidence_report_errors.incidence_type}" :filter="true"/>
                                <label for="incidence_type" :class="{'p-error':v$.incidence_type.$invalid && submitted || incidence_report_errors.incidence_type}">Incidence type*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.incidence_type" class="p-error"><span v-for="error in incidence_report_errors.incidence_type" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.incidence_type.$invalid && submitted) || v$.incidence_type.$pending.$response" class="p-error">{{v$.incidence_type.required.$message.replace('Value', 'Incidence type')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-4 pt-3">
                            <div class="p-float-label">
                                <Calendar data-cy="calendar_date" autocomplete="off" id="date" v-model="v$.date.$model" dateFormat="yy-mm-dd" :class="{'p-invalid':v$.date.$invalid && submitted || incidence_report_errors.date}" />
                                <label for="date" :class="{'p-error':v$.date.$invalid && submitted}"> Date*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.date" class="p-error"><span v-for="error in incidence_report_errors.date" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.date.$invalid && submitted) || v$.date.$pending.$response" class="p-error">{{v$.date.required.$message.replace('Value', 'Date')}}</small>
                        </div>
                        <div class="col-10 md:col-4 pt-3">
                            <div class="p-float-label">
                                <Calendar data-cy="calendar_hour" autocomplete="off" id="hour" :timeOnly="true" v-model="v$.hour.$model" :class="{'p-invalid':v$.hour.$invalid && submitted || incidence_report_errors.hour}" @change="hour = num2time($event)"/>
                                <label for="hour" :class="{'p-error':v$.hour.$invalid && submitted}"> Hour*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.hour" class="p-error"><span v-for="error in incidence_report_errors.hour" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.hour.$invalid && submitted) || v$.hour.$pending.$response" class="p-error">{{v$.hour.required.$message.replace('Value', 'Hour')}}</small>
                        </div>
                        <div class="col-10 md:col-4 pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_terminals" id="id_terminal" v-model="id_terminal" :options="all_terminals" optionLabel="name" :filter="true" :class="{'p-invalid':v$.id_terminal.$invalid && submitted || incidence_report_errors.id_terminal}"/>
                                <label for="id_terminal" :class="{'p-error':v$.id_terminal.$invalid && submitted || incidence_report_errors.id_terminal}">Terminal*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_terminal" class="p-error"><span v-for="error in incidence_report_errors.id_terminal" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.id_terminal.$invalid && submitted) || v$.id_terminal.$pending.$response" class="p-error">{{v$.id_terminal.required.$message.replace('Value', 'Terminal')}}</small>
                        </div>
                    </div> 
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-4 pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_incidence_zone" id="id_incidence_zone" v-model="id_incidence_zone" :options="all_incidence_zones" optionLabel="title" :filter="true" :class="{'p-invalid':v$.id_incidence_zone.$invalid && submitted || incidence_report_errors.id_incidence_zone}"/>
                                <label for="id_incidence_zone" :class="{'p-error':incidence_report_errors.id_incidence_zone}">Zone*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_zone" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_zone" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.id_incidence_zone.$invalid && submitted) || v$.id_incidence_zone.$pending.$response" class="p-error">{{v$.id_incidence_zone.required.$message.replace('Value', 'Zone')}}</small>
                        </div>
                        <div class="col-10 md:col-4 pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_incidence_category" id="id_incidence_category" v-model="id_incidence_category" :options="all_incidence_categories" optionLabel="title" :filter="true" :class="{'p-invalid':v$.id_incidence_category.$invalid && submitted || incidence_report_errors.id_incidence_category}"/>
                                <label for="id_incidence_category" :class="{'p-error':incidence_report_errors.id_incidence_category}">Category*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_category" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_category" :key="error">{{error}}<br></span></small>      
                            </div>
                            <small v-if="(v$.id_incidence_category.$invalid && submitted) || v$.id_incidence_category.$pending.$response" class="p-error">{{v$.id_incidence_category.required.$message.replace('Value', 'Category')}}</small> 
                        </div>
                        <div class="col-10 md:col-4 pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_incidence_subcategory" id="id_incidence_subcategory" v-model="id_incidence_subcategory" :options="all_incidence_subcategories" optionLabel="title" :filter="true" :class="{'p-invalid':v$.id_incidence_subcategory.$invalid && submitted || incidence_report_errors.id_incidence_subcategory}"/>
                                <label for="id_incidence_subcategory" :class="{'p-error':incidence_report_errors.id_incidence_subcategory}">Subcategory*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_subcategory" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_subcategory" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.id_incidence_subcategory.$invalid && submitted) || v$.id_incidence_subcategory.$pending.$response" class="p-error">{{v$.id_incidence_subcategory.required.$message.replace('Value', 'Subcategory')}}</small>  
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="text" v-model="v$.text.$model" :class="{'p-invalid':v$.text.$invalid && submitted || incidence_report_errors.text}" />
                                <label for="text" :class="{'p-error':v$.text.$invalid && submitted}"> Description*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.text" class="p-error"><span v-for="error in incidence_report_errors.text" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.text.$invalid && submitted) || v$.text.$pending.$response" class="p-error">{{v$.text.required.$message.replace('Value', 'Description')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_users" id="user" v-model="v$.id_user.$model" :options="get_users" optionLabel="username" :filter="true" :class="{'p-invalid':v$.id_user.$invalid && submitted || incidence_report_errors.id_user}"/>
                                <label for="user" :class="{'p-error':v$.id_user.$invalid && submitted}"> User*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_user" class="p-error"><span v-for="error in incidence_report_errors.id_user" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.id_user.$invalid && submitted) || v$.id_user.$pending.$response" class="p-error">{{v$.id_user.required.$message.replace('Value', 'User')}}</small>
                        </div>
                        <div v-if="incidence_type=='Service Sheet'" class="col-10 md:col pt-3">
                            <div class="p-float-label">
                                <Dropdown id="service_sheet" v-model="id_service_sheet" :options="all_service_sheets" optionLabel="estimated_arrival_datetime" :filter="true" :class="{'p-invalid':v$.id_service_sheet.$invalid && submitted || incidence_report_errors.text}">
                                    <template #value="slotProps">
                                        <div v-if="!id_service_sheet">
                                            <span> - </span>
                                        </div>
                                        <div v-else-if="slotProps.value">
                                            <span>{{format_date(slotProps.value.estimated_arrival_datetime)}} - {{slotProps.value.id_call.id_ship.name}}</span>
                                        </div>
                                    </template>
                                    <template #option="slotProps">
                                        <div> {{format_date(slotProps.option.estimated_arrival_datetime)}} - {{slotProps.option.id_call.id_ship.name}} </div>
                                    </template>
                                </Dropdown>
                                <label for="service_sheet" :class="{'p-error':incidence_report_errors.id_service_sheet}"> Service Sheet*</label>
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_service_sheet" class="p-error"><span v-for="error in incidence_report_errors.id_service_sheet" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.id_service_sheet.$invalid && submitted) || v$.id_service_sheet.$pending.$response" class="p-error">{{v$.id_service_sheet.required.$message.replace('Value', 'Service sheet')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col text-center">
                            <div class="p-float-label">
                                <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUpload" />
                                <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.photo" class="p-error"><span v-for="error in incidence_report_errors.photo" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                </div>    
            </form>
            <template #footer>
                <Button data-cy="create_incidence_report_button" form="form" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Toast from 'primevue/toast'
import FileUpload from  'primevue/fileupload'
import Calendar from 'primevue/calendar'
import { required, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"
import { num2time } from '@/modules/utilities.js'

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        Dropdown,
        FileUpload,
        Toast,
        Calendar
    },
    data() {
        return {
            displayModal: false,
            title: '',
            text:'',
            photo:'',
            date:'',
            hour:'',
            incidence_type:null,
            id_terminal:null,
            id_incidence_report: null,
            id_incidence_zone:null,
            id_incidence_category:null,
            id_incidence_subcategory:null,
            id_call:null,
            id_user: '',
            id_service_sheet: '',
            incidence_type_choices:[
                "General", "Service Sheet"
            ],
            incidence_report_errors:{},
            submitted: false,
            showMessage: false,
            color: localStorage.getItem('primary_color')
        }
    },
    created() {
        this.$store.dispatch('load_all_incidence_reports')
        this.$store.dispatch('load_users_by_port')
        this.$store.dispatch('load_user_port')
        this.$store.dispatch('load_all_service_sheets')
    },
    computed: {
        all_incidence_reports: function() {
            return this.$store.getters.all_incidence_reports
        },
        all_incidence_zones: function() {
            return this.$store.getters.all_incidence_zones
        },
        all_incidence_categories: function() {
            return this.$store.getters.all_incidence_categories
        },
        all_incidence_subcategories: function() {
            return this.$store.getters.all_incidence_subcategories
        },
        all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        all_calls: function() {
            return this.$store.getters.all_calls
        },
        get_errors_incidence_report: function() {
            return this.$store.getters.get_errors_incidence_report
        },
        get_users: function() {
            return this.$store.getters.users_by_port
        },
        all_service_sheets: function() {
            return this.$store.getters.all_service_sheets
        }
    },
    methods: {
        num2time(data) {
            return num2time(data)
        },
        format_date(value) {
            if(value instanceof Date){
                return value.toISOString().split('T')[0]
               
            } else {
                return value.split('T')[0]
            }
        },
        onUpload(e){
            this.photo = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_incidence_report() {
            let incidence_reports = {}

            if(this.incidence_type == 'General'){ 
                incidence_reports = { 
                    title: this.title,
                    text: this.text,
                    incidence_type: this.incidence_type,
                    photo: this.photo,
                    date: this.date,
                    hour: this.hour,
                    id_terminal: this.id_terminal,
                    id_incidence_zone: this.id_incidence_zone,
                    id_incidence_report: this.id_incidence_report,
                    id_incidence_category: this.id_incidence_category,
                    id_incidence_subcategory: this.id_incidence_subcategory,
                    id_call: this.id_call,
                    id_user: this.id_user.id,
                    id_service_sheet: ''
                } 
            } else if (this.incidence_type == 'Service Sheet') {
                incidence_reports = { 
                    title: this.title,
                    text: this.text,
                    incidence_type: this.incidence_type,
                    photo: this.photo,
                    date: this.date,
                    hour: this.hour,
                    id_terminal: this.id_terminal,
                    id_incidence_zone: this.id_incidence_zone,
                    id_incidence_report: this.id_incidence_report,
                    id_incidence_category: this.id_incidence_category,
                    id_incidence_subcategory: this.id_incidence_subcategory,
                    id_call: this.id_call,
                    id_user: this.id_user.id,
                    id_service_sheet: this.id_service_sheet.id
                } 
            }
        
            await this.$store.dispatch('create_incidence_report', incidence_reports)
            this.incidence_report_errors = this.get_errors_incidence_report
            if(Object.keys(this.incidence_report_errors).length === 0){
                this.toggleDialog();
                this.resetForm()
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {

            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }
            
            let res = await this.add_incidence_report()
            
            if(res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence report added', life: 3000});
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.title = '';
            this.text = '';
            this.photo = '';
            this.date = '';
            this.hour = '';
            this.incidence_type = null;
            this.id_incidence_report = null;
            this.id_incidence_zone = null;
            this.id_incidence_category = null;
            this.id_incidence_subcategory = null;
            this.id_call = null;
            this.id_terminal = null;
            this.id_service_sheet = null;
            this.id_user = null;
            this.submitted = false;
        }
    },
    validations() {
        return {
            title: { required },
            text: { required },
            incidence_type: { required },
            date: { required },
            hour: { required },
            id_incidence_zone: {required},
            id_incidence_category: { required },
            id_incidence_subcategory: { required },
            id_terminal: { required },
            id_user: { required },
            id_service_sheet: { 
                required: requiredIf(function() {
                    return this.incidence_type == 'Service Sheet'
                }) 
            }
        }
    },
};
</script>

<style scoped>



</style>