<template>
    <Toast />
    <div class="card">
        <DataTable :value="all_incidence_reports" :paginator="true" :rows="10"
        :paginatorTemplate="paginatorTemplate"
        :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" scrollable 
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        :globalFilterFields="['title','text', 'id_user.username', 'incidence_type','photo','date','hour','creation_date','id_terminal','id_incidence_zone','id_incidence_category','id_incidence_subcategory']"
        v-model:filters="filters" :loading="loading"
        stripedRows>   
            <template #header>
                <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"/>
                        <InputText id="filter_incidence_reports" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                    </span>
                </div>
            </template>
            <Column header="Title" field="title" :sortable="true" style="min-width: 15rem;"> 
            </Column>
            <Column header="Text" field="text" :sortable="true" style="min-width: 16rem;"> 
            </Column>
            <Column header="User" field="id_user_creator.username" :sortable="true" style="min-width: 8rem;"> 
            </Column>
            <Column header="Incidence_type" field="incidence_type" :sortable="true" style="min-width: 10rem;">
            </Column>
            <Column header="Service Sheet" field="id_service_sheet" :sortable="true" style="min-width: 13rem;">
                <template #body="slotProps">
                    <div v-if="slotProps.data.incidence_type == 'Service Sheet'">
                        <span>{{format_date(slotProps.data.id_service_sheet.estimated_arrival_datetime)}} -> {{slotProps.data.id_service_sheet.id_call.id_ship.name}}</span>
                    </div>
                    <div v-else-if="slotProps.data.incidence_type == 'General'">
                        <span> * </span>
                    </div>
                </template>
            </Column>
            <Column header="Date" field="date" :sortable="true" style="min-width: 10rem;">
                <template #body="slotProps">
                    {{format_date(slotProps.data.date)}}
                </template>
            </Column>
            <Column header="Hour" field="hour" :sortable="true" style="min-width: 10rem;">
            </Column>
            <Column header="Terminal" field="id_terminal.name" :sortable="true" style="min-width: 10rem;">
            </Column>
            <Column header="Incidence zone" field="id_incidence_zone.title" :sortable="true" style="min-width: 13rem;">
            </Column>
            <Column header="Incidence category" field="id_incidence_category.title" :sortable="true" style="min-width: 13rem;">
            </Column>
            <Column header="Incidence subcategory" field="id_incidence_subcategory.title" :sortable="true" style="min-width: 13rem;">
            </Column>
            <Column header="Picture" style="width: 7rem;">
                <template #body="slotProps">
                    <Button icon="pi pi-eye" class="p-button-rounded p-button-warning p-button-outlined" @click="open_image_incidence(slotProps.data)"/>
                </template>
            </Column>
            <Column header="Edit" style="width: 7rem;">
                <template #body="slotProps">
                    <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_incidence_report(slotProps.data)"/>
                </template>
            </Column>
            <Column header="Delete" style="width: 7rem;">
                <template #body="slotProps">
                    <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_incidence_report(slotProps.data)"/>
                </template>
            </Column>
        </DataTable>
    </div>

    <Dialog data-cy="edit_incidence_report_modal" v-model:visible="incidence_report_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '60vw'}" header="Incidence Report Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="title" class="ps-1 pb-1"> <strong>Title</strong> </label>
                        <InputText id="title" v-model="incidence_report.title" required="true" autofocus :class="{'p-invalid': submitted && !incidence_report.title || incidence_report_errors.title}"/>
                        <small class="p-error" v-if="submitted && !incidence_report.title">Title is required.</small>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.title" class="p-error"><span v-for="error in incidence_report_errors.title" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="incidence_type" :class="{'p-invalid': submitted && !incidence_report.incidence_type || incidence_report_errors.incidence_type}" class="ps-1 pb-1"> <strong>Incidence type</strong></label>
                        <Dropdown id="incidence_type" v-model="incidence_report.incidence_type" :options="incidence_type_choices" :filter="true">
                            <template #value="slotProps">
                                <div v-if="slotProps.value && slotProps.value.name">
                                    <span>{{slotProps.value.name}}</span>
                                </div>
                                <div v-else-if="slotProps.value && !slotProps.value.name">
                                    <span >{{slotProps.value}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.incidence_type" class="p-error"><span v-for="error in incidence_report_errors.incidence_type" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-4">
                    <div class="p-float-field">
                        <label for="date" class="ps-1 pb-1"> <strong>Date</strong> </label>
                        <Calendar autocomplete="off" id="date" v-model="incidence_report.date" required="true" dateFormat="yy-mm-dd" autofocus :class="{'p-invalid': submitted && !incidence_report.date || incidence_report_errors.date}"/>
                        <small class="p-error" v-if="submitted && !incidence_report.date">Date is required.</small>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.date" class="p-error"><span v-for="error in incidence_report_errors.date" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col-4">
                    <div class="p-float-field">
                        <label for="hour" class="ps-1 pb-1"> <strong>Hour</strong> </label>
                        <Calendar autocomplete="off" id="hour" :timeOnly="true" v-model="incidence_report.hour" required="true" autofocus :class="{'p-invalid': submitted && !incidence_report.hour || incidence_report_errors.hour}" @change="incidence_report.hour = num2timeedit($event)"/>
                        <small class="p-error" v-if="submitted && !incidence_report.hour">Date is required.</small>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.hour" class="p-error"><span v-for="error in incidence_report_errors.hour" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col-4">
                    <div class="p-float-field">
                        <label for="terminal" :class="{'p-invalid': submitted && !incidence_report.id_terminal || incidence_report_errors.id_terminal}" class="ps-1 pb-1"> <strong>Terminal</strong> </label>  
                        <Dropdown id="id_terminal" v-model="incidence_report.id_terminal" :options="all_terminals" optionLabel="name" :filter="true"> 
                        <template #value="slotProps">
                            <div v-if="slotProps.value && slotProps.value.value">
                                <span>{{slotProps.value.label}}</span>
                            </div>
                            <div v-else-if="slotProps.label && !slotProps.value.value">
                                <span >{{slotProps.name}}</span>
                            </div>
                        </template>
                        </Dropdown>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_terminal" class="p-error"><span v-for="error in incidence_report_errors.id_terminal" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-4">
                    <div class="p-float-field">
                        <label for="incidence_zone" :class="{'p-invalid': submitted && !incidence_report.id_incidence_zone || incidence_report_errors.id_incidence_zone}" class="ps-1 pb-1"> <strong>Incidence zone</strong> </label>  
                        <Dropdown id="id_incidence_zone" v-model="incidence_report.id_incidence_zone" :options="all_incidence_zones" optionLabel="title" :filter="true" placeholder="Select subcategory"> 
                            <template #value="slotProps">
                                <span> {{slotProps.value.title}} </span>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_zone" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_zone" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col-4">
                    <div class="p-float-field">
                        <label for="incidence_category" :class="{'p-invalid': submitted && !incidence_report.id_incidence_category || incidence_report_errors.id_incidence_category}" class="ps-1 pb-1"> <strong>Incidence category</strong> </label>  
                        <Dropdown id="id_incidence_category" v-model="incidence_report.id_incidence_category" :options="all_incidence_categories" optionLabel="title" :filter="true" placeholder="Select subcategory"> 
                            <template #value="slotProps">
                                <span> {{slotProps.value.title}} </span>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_category" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_category" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col-4">
                    <div class="p-float-field">
                        <label for="incidence_subcategory" :class="{'p-invalid': submitted && !incidence_report.id_incidence_subcategory || incidence_report_errors.id_incidence_subcategory}" class="ps-1 pb-1"> <strong>Incidence subcategory</strong> </label>  
                        <Dropdown id="id_incidence_subcategory" v-model="incidence_report.id_incidence_subcategory" :options="all_incidence_subcategories" optionLabel="title" :filter="true" placeholder="Select subcategory"> 
                            <template #value="slotProps">
                                <span> {{slotProps.value.title}} </span>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_subcategory" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_subcategory" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field" >
                        <label for="text" class="ps-1 pb-1"> <strong>Text</strong> </label>
                        <InputText id="text" v-model="incidence_report.text" required="true" autofocus :class="{'p-invalid': submitted && !incidence_report.text || incidence_report_errors.text}"/>
                        <small class="p-error" v-if="submitted && !incidence_report.text">Text is required.</small>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.text" class="p-error"><span v-for="error in incidence_report_errors.text" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col">
                    <div class="p-float-field">
                        <label for="id_user" :class="{'p-invalid': submitted && !incidence_report.id_incidence_subcategory || incidence_report_errors.id_incidence_subcategory}" class="ps-1 pb-1"> <strong>User</strong> </label>  
                        <Dropdown id="id_user" v-model="incidence_report.id_user_creator" :options="get_users" optionLabel="username" :filter="true" > 
                            <template #value="slotProps">
                                <span> {{slotProps.value.username}} </span>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_subcategory" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_subcategory" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div  v-if="incidence_report.incidence_type=='Service Sheet'" class="col-10 md:col">
                    <div class="p-float-field">
                        <label for="service_sheet" :class="{'p-invalid': submitted && !incidence_report.id_service_sheet || incidence_report_errors.id_service_sheet}" class="ps-1 pb-1"> <strong>Service Sheet</strong> </label>  
                        <Dropdown id="service_sheet" v-model="incidence_report.id_service_sheet" :options="all_service_sheets" optionLabel="id" :filter="true"> 
                            <template #value="slotProps">
                                <div v-if="!slotProps.value">
                                    <span> - </span>
                                </div>
                                <div v-if="slotProps.value">
                                    <span>{{format_date(slotProps.value.estimated_arrival_datetime)}} - {{slotProps.value.id_call.id_ship.name}}</span>
                                </div>
                            </template>
                            <template #option="slotProps">
                                <div> {{format_date(slotProps.option.estimated_arrival_datetime)}} - {{slotProps.option.id_call.id_ship.name}} </div>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_service_sheet" class="p-error"><span v-for="error in incidence_report_errors.id_service_sheet" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12 text-center">
                    <div class="p-float-field">
                        <div class="p-float-label">
                            <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUpload" />
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.photo" class="p-error"><span v-for="error in port_errors.photo" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_incidence_report_dialog"/>
            <Button data-cy="save_edit_incidence_report" label="Save" @click="save_incidence_report" />
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_incidence_report_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="incidence_report">Are you sure you want to delete <b>{{incidence_report.title}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_incidence_report_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deleteIncidence_reports" />
        </template>
    </Dialog>
    
    <Galleria v-model:visible="incidence_picture_galeria" :value="incidence_photo" :responsiveOptions="responsiveOptions" containerStyle="max-width: 50%" :fullScreen="true" :showThumbnails="false">
        <template #item="slotProps">
            <img :src="slotProps.item" :alt="slotProps.item.alt" style="width: 100%; display: block" />
        </template>
    </Galleria>

</template>

<script> 
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import Dropdown from 'primevue/dropdown'
import FileUpload from 'primevue/fileupload';
import Calendar from 'primevue/calendar'
import Galleria from 'primevue/galleria';

import { FilterMatchMode } from 'primevue/api';
import { useVuelidate } from "@vuelidate/core"
import { required, requiredIf } from '@vuelidate/validators'

import { num2timeedit } from '@/modules/utilities.js'

export default {
    setup: () => ({ v$: useVuelidate() }),
    title: 'TableIncidence_reports',
    components: {
        DataTable,
        Column,
        Button,
        Dialog,
        InputText,
        FileUpload,
        Toast,
        Dropdown,
        Calendar,
        Galleria
    },
    data() {
        return {
            incidence_report: {},
            incidence_report_errors:{},
            incidence_type_choices:[
                "General", "Service Sheet"
            ],
            photo:'',
            delete_incidence_report_dialog: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            incidence_report_dialog: false,
            incidence_picture_galeria: false,
            submitted: false,
            loading: false,
            incidence_photo: [],
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_incidence_reports.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_incidence_reports')
            await this.$store.dispatch('load_all_incidence_zones')
            await this.$store.dispatch('load_all_incidence_categories')
            await this.$store.dispatch('load_all_incidence_subcategories')
            await this.$store.dispatch('load_all_terminals')
            await this.$store.dispatch('load_users_by_port')
            this.loading = false
            await this.$store.dispatch('load_all_calls')
            await this.$store.dispatch('load_all_service_sheets')
        }
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_incidence_reports: function() {
            return this.$store.getters.all_incidence_reports
        },
        all_incidence_zones: function() {
            return this.$store.getters.all_incidence_zones
        },
        all_incidence_categories: function() {
            return this.$store.getters.all_incidence_categories
        },
        all_incidence_subcategories: function() {
            return this.$store.getters.all_incidence_subcategories
        },
        all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        all_calls: function() {
            return this.$store.getters.all_calls
        },
        get_errors_incidence_report: function() {
            return this.$store.getters.get_errors_incidence_report
        },
        get_delete_incidence_report_status:function() {
            return this.$store.getters.get_delete_incidence_report_status
        },
        get_users: function() {
            return this.$store.getters.users_by_port
        },
        all_service_sheets: function() {
            return this.$store.getters.all_service_sheets
        },
        single_incidence_report: function() {
            return this.$store.getters.single_incidence_report
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        num2timeedit(data) {
            return num2timeedit(data)
        },
        format_date(value) {
            if(value instanceof Date){
                return value.toISOString().split('T')[0]
               
            } else {
                return value.split('T')[0]
            }
        },
        onUpload(e){
            this.photo = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        confirm_delete_incidence_report(incidence_report) {
            this.incidence_report = incidence_report;
            this.delete_incidence_report_dialog = true;
        },
        async deleteIncidence_reports() {
            await this.$store.dispatch('delete_incidence_report', this.incidence_report.id)
            if (!this.get_delete_incidence_report_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence report deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Incidence report can't be deleted", life: 3000});
            }
            this.delete_incidence_report_dialog = false;
        },
        async open_image_incidence(data){
            this.incidence_photo = []
            await this.$store.dispatch('load_single_incidence', data.id)
            this.incidence_photo.push(this.single_incidence_report.photo)
            this.incidence_picture_galeria = true;
        },
        edit_incidence_report(incidence_report) {
            this.incidence_report = {...incidence_report};
            this.incidence_report_dialog = true;
        },
        async save_incidence_report() {
            this.submitted = true;
            if(this.photo != ''){
                this.incidence_report.photo = this.photo
            } 
            
            if(this.incidence_report.incidence_type == 'General') {
                this.incidence_report.id_service_sheet = null;
            }

            if(this.incidence_report.id_service_sheet == null) {
                if(this.incidence_report.incidence_type == 'Service Sheet') {
                    alert('error')
                    this.submitted = false;
                } else {
                    await this.$store.dispatch('update_incidence_report', this.incidence_report)
                    this.incidence_report_errors = this.get_errors_incidence_report
                    
                    if(Object.keys(this.incidence_report_errors).length === 0){
                        this.hide_incidence_report_dialog()
                        //this.$store.dispatch('load_all_incidence_reports')
                        this.incidence_report = {};
                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence report modified', life: 3000});
                    }
                }   
            } else if (this.incidence_report.id_service_sheet != null) {
                await this.$store.dispatch('update_incidence_report', this.incidence_report)
                this.incidence_report_errors = this.get_errors_incidence_report
                
                if(Object.keys(this.incidence_report_errors).length === 0){
                    this.hide_incidence_report_dialog()
                    //this.$store.dispatch('load_all_incidence_reports')
                    this.incidence_report = {};
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence report modified', life: 3000});
                }
            }
        },
        hide_incidence_report_dialog() {
            this.incidence_report_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            title: { required },
            text: { required },
            incidence_type: { required },
            date: { required },
            hour: { required },
            id_incidence_zone: {required},
            id_incidence_category: { required },
            id_incidence_subcategory: { required },
            id_terminal: { required },
            id_user: { required },
            id_service_sheet: { 
                required: requiredIf(function() {
                    return this.incidence_type == 'Service Sheet'
                }) 
            }
        }
    },

}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>