<template>
    <div class="card">
        <div v-if="$can('view','terminal')" class="row">
            <Toast />
            <DataTable :value="all_terminals" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading" removableSort
            :globalFilterFields="['name']"
            v-model:filters="filters"  >
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText id="filter_terminals" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                        </span>
                    </div>
                </template>
                <Column header="Name" field="name" :sortable="true"/>
                <Column v-if="$can('change','terminal')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_terminal(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','terminal')" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_terminal(slotProps)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_terminal_modal" v-model:visible="terminal_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Terminal Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="name" class="ps-1 pb-1"> <strong>Name</strong> </label>
                        <InputText id="name" v-model.trim="terminal.name" required="true" autofocus :class="{'p-invalid': submitted && !terminal.name || terminal_errors.name}" />
                        <small class="p-error" v-if="submitted && !terminal.name">Name is required.</small>
                        <small v-if="Object.keys(terminal_errors).length !== 0 && terminal_errors.name" class="p-error"><span v-for="error in terminal_errors.name" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_terminal_dialog"/>
            <Button data-cy="save_edit_terminal" label="Save" @click="save_terminal" />
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_terminal_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="terminal">Are you sure you want to delete <b>{{terminal.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_terminal_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deleteTerminal" />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'

import { FilterMatchMode } from 'primevue/api'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'TableTerminals',
    components: {
        DataTable,
        Column,
        Button,
        InputText,
        Toast,
        Dialog,
    },
    data() {
        return {
            terminal: {},
            terminal_errors:{},
            terminal_dialog: false,
            delete_terminal_dialog: false,
            submitted: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_terminals.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_terminals')
            this.loading = false
        }   
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        get_errors_terminal: function(){
            return this.$store.getters.get_errors_terminal
        },
        get_delete_terminal_status: function() {
            return this.$store.getters.get_delete_terminal_status
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        confirm_delete_terminal(terminal) {
            this.terminal = terminal.data;
            this.delete_terminal_dialog = true;
        },
        async deleteTerminal() {
            await this.$store.dispatch('delete_terminal', this.terminal.id)
            if (!this.get_delete_terminal_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Terminal deleted', life: 3000});    
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Terminal can't be deleted", life: 3000});
            }
            this.delete_terminal_dialog = false;
        },
        edit_terminal(terminal) {
            this.terminal = {...terminal};
            this.terminal_dialog = true;
        },
        async save_terminal() {
            this.submitted = true;
			if (this.terminal.name.trim()) {

                await this.$store.dispatch('update_terminal', this.terminal)
                this.terminal_errors = this.get_errors_terminal
                
                if(Object.keys(this.terminal_errors).length === 0){
                    this.hide_terminal_dialog();
                    this.terminal = {};
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Terminal modified', life: 3000});
                }
            }
        },
        hide_terminal_dialog() {
            this.terminal_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            name: { required }
        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>