import axios from 'axios'

const state = {
    supplier_responsibles: [],
    supplier_responsible_errors:{}
}

const mutations = {
    SET_SUPPLIER_RESPONSIBLES(state, supplier_responsibles) {
        state.supplier_responsibles = supplier_responsibles
    },
    NEW_SUPPLIER_RESPONSIBLE(state, supplier_responsible) {  
        state.supplier_responsibles.push(supplier_responsible)
        state.supplier_responsible_errors = {}
    },
    DELETE_SUPPLIER_RESPONSIBLE(state, id) {
        let index = state.supplier_responsibles.findIndex(supplier_responsibles => supplier_responsibles.id == id)
        state.supplier_responsibles.splice(index, 1)
    },
    UPDATE_SUPPLIER_RESPONSIBLE(state, supplier_responsible) {
        state.supplier_responsibles = state.supplier_responsibles.map(updated_supplier_responsible => {
            if(updated_supplier_responsible.id === supplier_responsible.id) {
                updated_supplier_responsible.name = supplier_responsible.name
                updated_supplier_responsible.phone = supplier_responsible.phone
                updated_supplier_responsible.id_supplier = supplier_responsible.id_supplier
            }
            return updated_supplier_responsible
        })
        state.supplier_responsible_errors = {}
    },
    ERRORS_SUPPLIER_RESPONSIBLE(state, errors){
        state.supplier_responsible_errors = errors
    }
}

const actions = {
    async load_all_supplier_responsibles({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "supplier_responsible/")
            .then((response) => {
                commit('SET_SUPPLIER_RESPONSIBLES', response.data)
            })
    },
    async create_supplier_responsible({commit}, supplier_responsible) {
        await axios.post(process.env.VUE_APP_API_URL  + 'supplier_responsible/', { name: supplier_responsible.name, phone: supplier_responsible.phone, id_supplier: supplier_responsible.id_supplier.id })
            .then((response) => {
                commit('NEW_SUPPLIER_RESPONSIBLE', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SUPPLIER_RESPONSIBLE', error.response.data)
            })
    },
    delete_supplier_responsible({commit}, id) {
        axios.delete(process.env.VUE_APP_API_URL + 'supplier_responsible/' + id)
            .then(() => {
                commit('DELETE_SUPPLIER_RESPONSIBLE', id);
            })
            
    }, 
    async update_supplier_responsible({commit}, supplier_responsible){
        await axios.put(process.env.VUE_APP_API_URL  + 'supplier_responsible/' + supplier_responsible.id + '/',  { name: supplier_responsible.name, phone: supplier_responsible.phone, id_supplier: supplier_responsible.id_supplier.id })
            .then(() => {
                
                commit('UPDATE_SUPPLIER_RESPONSIBLE', supplier_responsible)
            })
            .catch((error) => {
                
                commit('ERRORS_SUPPLIER_RESPONSIBLE', error.response.data)
            })
    },
}

const getters = {
    all_supplier_responsibles: (state) => state.supplier_responsibles,
    get_supplier_responsible_errors: (state) => state.supplier_responsible_errors
}

export default {
    state,
    mutations,
    actions,
    getters
};