import axios from 'axios'

const state = {
    crews: [],
    crew_errors:{}
}

const mutations = {
    SET_CREWS(state, crews) {
        state.crews = crews
    },
    NEW_CREW(state, crew) {  
        state.crews.push(crew)
        state.crew_errors = {}
    },
    DELETE_CREW(state, id) {
        let index = state.crews.findIndex(crews => crews.id == id)
        state.crews.splice(index, 1)
    },
    UPDATE_CREW(state, crew) {
        state.crews = state.crews.map(updated_crew => {
            if(updated_crew.id === crew.id) {
                updated_crew.first_name = crew.first_name
                updated_crew.last_name = crew.last_name
                updated_crew.dni_pass = crew.dni_pass
                updated_crew.id_call = crew.id_call
            }
            return updated_crew
        })
        state.crew_errors = {}
    },
    ERRORS_CREW(state, errors){
        state.crew_errors = errors
    }
}

const actions = {
    async load_all_crews({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "crews/")
            .then((response) => {
                
                commit('SET_CREWS', response.data)
            })
    },
    async create_crew({commit}, crew) {
        await axios.post(process.env.VUE_APP_API_URL  + 'crews/', { first_name: crew.first_name, last_name: crew.last_name, dni_pass: crew.dni_pass, id_call: crew.id_call.id })
            .then((response) => {
                commit('NEW_CREW', response.data)
            })
            .catch((error) => {
                commit('ERRORS_CREW', error.response.data)
            })
    },
    delete_crew({commit}, id) {
        axios.delete(process.env.VUE_APP_API_URL + 'crews/' + id)
            .then(() => {
                commit('DELETE_CREW', id);
            })
            
    }, 
    async update_crew({commit}, crew){
        await axios.put(process.env.VUE_APP_API_URL  + 'crews/' + crew.id + '/',  { first_name: crew.first_name, last_name: crew.last_name, dni_pass: crew.dni_pass, id_call: crew.id_call.id })
            .then(() => {
                
                commit('UPDATE_CREW', crew)
            })
            .catch((error) => {
                
                commit('ERRORS_CREW', error.response.data)
            })
    },
}

const getters = {
    all_crews: (state) => state.crews,
    get_crew_errors: (state) => state.crew_errors
}

export default {
    state,
    mutations,
    actions,
    getters
};