<template>
    <div v-if="loading_calendar" style="position: absolute; top:0rem; left: 0">
        <ProgressBar mode="indeterminate" style="height: 6px; width: 100vw;"></ProgressBar>
    </div>
    <div class="row pt-3" style="height:100%">
        <div class="col-12" style="height: calc(100vh - 11rem)">
            <FullCalendar :options="calendarOptions" ref="fullCalendar"/>
        </div> 
        <!--
        <div class="row mt-3">
            <div class="col">
                <Panel header="Filters">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 md:col text-center">
                                <Dropdown
                                    v-model="ship_name_filter"
                                    :filter="true"
                                    :options="all_ships"
                                    optionLabel="name"
                                    optionValue="name"
                                    placeholder="Select a Ship ..."
                                />
                            </div>
                            <div class="col-12 md:col text-center">
                                <i
                                    class="
                                        fa-solid fa-trash
                                        pt-2
                                        icon_filter
                                    "
                                    style="
                                        font-size: 1.7rem;
                                        cursor: pointer;
                                    "
                                    @click="reset_filter_ship"
                                ></i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 md:col text-center">
                                <Dropdown
                                    v-model="cruise_line_filter"
                                    :filter="true"
                                    :options="all_cruise_lines"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Ship Agent ..."
                                />
                            </div>
                            <div class="col-12 md:col text-center">
                                <i
                                    class="
                                        fa-solid fa-trash
                                        pt-2
                                        icon_filter
                                    "
                                    style="
                                        font-size: 1.7rem;
                                        cursor: pointer;
                                    "
                                    @click="
                                        reset_filter_cruise_line
                                    "
                                ></i>
                            </div> 
                        </div>
                        <div class="row pt-4 text-center">
                            <div class="col">
                                <Button
                                    class="p-button-sm"
                                    label="Apply Filters"
                                    @click="apply_filter"
                                />
                            </div>
                            <div class="col">
                                <Button
                                    class="p-button-sm"
                                    label="Reset Filters"
                                    @click="reset_all_filters"
                                />
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>
            <div class="col text-center">
                <Panel header="Information">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <i
                                    class="
                                        fa-solid fa-circle fa-xl
                                        estimated_key
                                    "
                                />
                                <b class="ms-3"> Estimated </b>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <i
                                    class="
                                        fa-solid fa-circle fa-xl
                                        confirmed_key
                                    "
                                />
                                <b class="ms-3"> Confirmed</b>
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
        -->
    </div>
    
    <Dialog data-cy="modal_call_info"
        :header="ship_name"
        v-model:visible="displayModal"
        :modal="true"
        :breakpoints="{'991px': '90vw', '1400px': '90vw'}" :style="{width: '50vw'}"
    >
        <div class="flex">
            <div class="md:col-12 col-12 d-flex flex-wrap align-items-center p-2">
                <img
                    :src="ship_picture"
                    :alt="ship_name"
                    preview
                    class="img-fluid w-100 h-100"
                    style="border-radius: 1%;"
                />
            </div>
            <!--
            <div class="md:col-12 col-12 align-items-center">
                <div class="grid justify-content-center">
                    <div class="col-5">
                        <div class="row">
                            <h5 class="text-center" style="color: #17C58B;"> Arrival</h5>
                        </div>
                        <div class="row">
                            <div class="card" style="border-top: 1rem solid #17C58B;">
                                <div class="row align-items-center pt-3">
                                    <span class="d-flex justify-content-center">
                                        <h1>{{ format_date(arrival_time).split(' ')[1] }}</h1>
                                    </span>
                                </div>
                                <div class="row align-items-center">
                                    <span class="d-flex justify-content-center">
                                        {{format_date(arrival_time).split(',')[1].split(' ')[2]}} {{format_date(arrival_time).split(',')[1].split(' ')[3]}}
                                    </span>
                                </div>
                                <div class="row align-items-center pb-3">
                                    <span class="d-flex justify-content-center">
                                        {{format_date(arrival_time).split(',')[1].split(' ')[4]}}
                                    </span>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="grid justify-content-center">
                    <div class="col-5">
                        <div class="row">
                            <h5 class="text-center" style="color: #F74050;"> Departure</h5>
                        </div>
                        <div class="row">
                            <div class="card" style="border-top: 1rem solid #F74050;">
                                <div class="row align-items-center pt-3">
                                    <span class="d-flex justify-content-center">
                                        <h1>{{ format_date(departure_time).split(' ')[1] }}</h1>
                                    </span>
                                </div>
                                <div class="row align-items-center">
                                    <span class="d-flex justify-content-center">
                                        {{format_date(departure_time).split(',')[1].split(' ')[2]}} {{format_date(departure_time).split(',')[1].split(' ')[3]}}
                                    </span>
                                </div>
                                <div class="row align-items-center pb-3">
                                    <span class="d-flex justify-content-center">
                                        {{format_date(departure_time).split(',')[1].split(' ')[4]}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            -->
        </div>
        <div class="flex justify-content-between">
            <div class="p-3 text-end my-auto ms-1">
                <span>
                    <i v-tooltip.left="'Start'" class="pi pi-calendar mr-2" style="border-radius: 10px; color: green;"> </i>
                    {{format_date(arrival_time)}} 
                </span>
            </div>
            <div class="p-3 text-end my-auto ms-1">
                <span>
                    <i v-tooltip.left="'End'" class="pi pi-calendar mr-2" style="border-radius: 10px; color: red;"> </i>
                    {{format_date(departure_time)}} 
                </span>
            </div>
        </div>
        <div class="flex justify-content-between p-2" style="border-radius: 10px;">
            
            <div data-cy="button_service_sheet" v-if="id_ss" class="card p-1 text-start my-auto">
                <Button
                    @click="
                        $router.push({
                            name: 'Service_sheet_template',
                            params: { id: id_call, sid: id_ss },
                        })
                    "
                    icon="pi pi-inbox"
                    class="p-button-text"
                    label="Service Sheet"
                />
            </div>
            <div data-cy="button_service_sheet" v-if="!id_ss" class="card p-1 text-start my-auto">
                <Button
                    @click="
                        $router.push({
                            name: 'Service_sheet_menu',
                            params: { id: id_call },
                        })
                    "
                    icon="pi pi-inbox"
                    label="Service Sheet"
                    class="p-button-text"
                />
            </div>
           
            <div class="card p-1 text-end ms-1 my-auto">
                <Button data-cy="delete_button_call" v-tooltip.left="'Delete'" icon="pi pi-trash" class="p-button-danger p-button-text " style="border-radius: 10px;" @click="confirm_delete_call"/>
            </div>
            
        </div>
    </Dialog>
    
    <Dialog
        v-model:visible="delete_call_dialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
    >
        <div class="confirmation-content">
            <span v-if="id_call">
                Are you sure you want to delete the call ?</span
            >
        </div>
        <template #footer>
            <Button 
                label="No"
                class="p-button-text"
                @click="delete_call_dialog = false"
            />
            <Button data-cy="confirm_delete_button_call"
                label="Yes"
                @click="deleteCall"
            />
        </template>
    </Dialog>
</template>

<script>
// import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid'

import ProgressBar from 'primevue/progressbar'
import Dialog from "primevue/dialog"
//import Panel from "primevue/panel"
import Button from "primevue/button"
//import Dropdown from "primevue/dropdown"
//import Image from "primevue/image"

import moment from 'moment'

export default {
    name: "Calendar_calls",
    components: {
        FullCalendar,
        Dialog,
        Button,
        ProgressBar
        //Dropdown,
        //Panel,
        //Image,
    },
    data() {
        return {
            loading_calendar: false,
            data_calendar: {},
            events_filtered: [],
            color: localStorage.getItem("primary_color"),
            displayModal: false,
            ship_name_filter: "",
            cruise_line_filter: "",
            ship_name: "",
            id_call: "",
            id_ss: "",
            items: [],
            departure_time: "",
            arrival_time: "",
            ship_picture: "",
            service_sheets: {},
            delete_call_dialog: false,
            calendarOptions: {
                titleFormat:{ year: 'numeric', month: 'long' },
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
                initialView: "dayGridMonth",
                headerToolbar: {
                    left: "prevYear,nextYear",
                    center: "title",
                    right: "printButton prev,next",
                },
                events: [],
                eventTimeFormat: {
                    // like '14:30:00'
                    hour: "2-digit",
                    minute: "2-digit",
                },
                displayEventEnd: true,
                displayEventTime: false,
                eventDisplay: 'block',
                firstDay: 1,
                height: '100%',
                eventOrder: "title",
                selectable: true,
                showNonCurrentDates: false,
                selectMirror: true,
                eventColor: "#002D72",
                customButtons: {
                    printButton:{
                        text:"Print service sheet template",
                        hint:"Open new tab with empty service sheet",
                        click:() => {
                            const pdfUrl = '../ss_template.pdf';
                            window.open(pdfUrl, '_blank');
                        }
                    },
                    next: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();
                            this.refill_calendar(calendarApi.currentData.currentDate.getMonth() + 1, calendarApi.currentData.currentDate.getFullYear());
                            this.data_calendar =  {
                                month: calendarApi.currentData.currentDate.getMonth() + 1,
                                year: calendarApi.currentData.currentDate.getFullYear()
                            }
                        }
                    },
                    nextYear: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.nextYear();
                            this.refill_calendar(calendarApi.currentData.currentDate.getMonth() + 1, calendarApi.currentData.currentDate.getFullYear());
                            this.data_calendar =  {
                                month: calendarApi.currentData.currentDate.getMonth() + 1,
                                year: calendarApi.currentData.currentDate.getFullYear()
                            }
                        } 
                    },
                    prev: {
                        click: async() => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            await calendarApi.prev();
                            this.refill_calendar(calendarApi.currentData.currentDate.getMonth() + 1, calendarApi.currentData.currentDate.getFullYear());
                            this.data_calendar =  {
                                month: calendarApi.currentData.currentDate.getMonth() + 1,
                                year: calendarApi.currentData.currentDate.getFullYear()
                            }
                        }
                    },
                    prevYear: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prevYear();
                            this.refill_calendar(calendarApi.currentData.currentDate.getMonth() + 1, calendarApi.currentData.currentDate.getFullYear());
                            this.data_calendar =  {
                                month: calendarApi.currentData.currentDate.getMonth() + 1,
                                year: calendarApi.currentData.currentDate.getFullYear()
                            }
                        }
                    }
                },
                eventClick: async function (info) {
                    this.ship_name = info.event._def.title;
                    this.arrival_time = moment(info.event._instance.range.start).utc(false);
                    this.departure_time = moment(info.event._instance.range.end).utc(false);
                    this.id_call = info.event._def.publicId;

                    await this.$store.dispatch("load_ship",info.event._def.extendedProps.ship_id);
                    
                    this.ship_picture = this.get_ship.picture;

                    await this.$store.dispatch("load_service_sheets_by_call",this.id_call);

                    this.service_sheets = this.get_service_sheets;

                    if(this.service_sheets.length > 0) {
                        this.id_ss = this.service_sheets[0].id
                    } 

                    this.displayModal = true;

                }.bind(this),
            },
        };
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
        this.loading_calendar = true
        let date = {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear()
        }
        this.data_calendar = date

        await this.$store.dispatch('load_filtered_calls', date)
        await this.fill_calendar();

        // await this.$store.dispatch("load_all_cruise_lines");
        this.loading_calendar = false

    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        all_port_calls_calendar: function () {
            return this.$store.getters.all_port_calls_calendar;
        },
        get_port_calls_by_cruise_line() {
            return (
                this.$store.getters.get_port_calls_by_cruise_line(
                    this.cruise_line_filter
                ) ?? {}
            );
        },
        get_port_calls_filtered() {
            return this.$store.getters.get_port_calls_filtered(
                this.ship_name_filter,
                this.cruise_line_filter
            );
        },
        get_port_calls_semi_filtered() {
            return this.$store.getters.get_port_calls_semi_filtered(
                this.ship_name_filter,
                this.cruise_line_filter
            );
        },
        all_cruise_lines: function () {
            return this.$store.getters.all_cruise_lines;
        },
        get_port_calls_foresight: function () {
            return this.$store.getters.get_port_calls_foresight;
        },
        get_port_calls_confirmed: function () {
            return this.$store.getters.get_port_calls_confirmed;
        },
        get_service_sheets: function () {
            return this.$store.getters.get_service_sheets;
        },
        get_ship: function () {
            return this.$store.getters.get_ship;
        },
        filtered_calls: function() {
            return this.$store.getters.filtered_calls
        },
        all_port_calls_calendar_filtered: function() {
            return this.$store.getters.all_port_calls_calendar_filtered
        }
    },
    methods: {
        async fill_calendar() {
            this.calendarOptions.events = this.all_port_calls_calendar_filtered;
        },
        async handleResize(){
            if (window.innerWidth <= 605){
                this.calendarOptions.headerToolbar.left = 'title'
                this.calendarOptions.headerToolbar.center = 'printButton'
                this.calendarOptions.headerToolbar.right = 'prev,next'
                this.calendarOptions.customButtons.printButton.text = 'Print SS'
                this.calendarOptions.titleFormat.month = 'short'
                
            }else{
                this.calendarOptions.titleFormat.month = 'long'
                this.calendarOptions.customButtons.printButton.text = 'Print service sheet template'
                this.calendarOptions.headerToolbar.left = "prevYear,nextYear"
                this.calendarOptions.headerToolbar.center = 'title'
                this.calendarOptions.headerToolbar.right = "printButton prev,next"
            }
        },
        async refill_calendar(month, year){
            this.loading_calendar = true

            let date = {
                month: month,
                year: year
            }

            await this.$store.dispatch('load_filtered_calls', date)

            this.calendarOptions.events = this.all_port_calls_calendar_filtered;
            this.loading_calendar = false
        },
        format_date(value) {
            return moment(value).format('DD, MMMM - HH:mm')
            //return value.toUTCString().split("GMT")[0].split(':')[0] + ':' + value.toUTCString().split("GMT")[0].split(':')[1];
        },
        format_time(value){
            return moment(value).format('HH:mm')
        },
        apply_filter() {
            if (this.ship_name_filter == "" && this.cruise_line_filter == "") {
                this.calendarOptions.events = this.all_port_calls_calendar;
            } else if (
                this.ship_name_filter == "" ||
                this.cruise_line_filter == ""
            ) {
                this.calendarOptions.events = this.get_port_calls_semi_filtered;
            } else if (
                this.ship_name_filter != "" ||
                this.cruise_line_filter != ""
            ) {
                this.calendarOptions.events = this.get_port_calls_filtered;
            }
        },
        reset_all_filters() {
            this.calendarOptions.events = this.all_port_calls_calendar;
            this.ship_name_filter = "";
            this.cruise_line_filter = "";
        },
        reset_filter_ship() {
            this.calendarOptions.events = this.all_port_calls_calendar;
            this.ship_name_filter = "";
        },
        reset_filter_cruise_line() {
            this.calendarOptions.events = this.all_port_calls_calendar;
            this.cruise_line_filter = "";
        },
        confirm_delete_call(call) {
            this.call = call.data;
            this.delete_call_dialog = true;
        },
        async deleteCall() {
            await this.$store.dispatch("delete_port_call", this.id_call);

            if (!this.get_delete_call_status) {
                this.$toast.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Call Deleted",
                    life: 3000,
                });
            } else {
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Call can't be deleted",
                    life: 3000,
                });
            }
            this.delete_call_dialog = false;
            this.service_sheets = [];
            this.displayModal = false;

            await this.$store.dispatch('load_filtered_calls', this.data_calendar)

            await this.refill_calendar(this.data_calendar.month, this.data_calendar.year);
        },
    },
};
</script>

<style scoped>

:deep(.p-dialog .p-dialog-content ){
    padding: 0 0 0 0;
}

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}
.confirmed_key {
    color: #002d72;
}

.estimated_key {
    color: gray;
}

.icon_filter {
    color: v-bind(color);
}

:deep(a) {
    color: black !important;
}

.fc:deep(.fc-col-header-cell-cushion) {
    text-decoration: none !important;
    color: white !important;
}

.fc:deep(.fc-daygrid-day-number) {
    text-decoration: none !important;
}

.fc:deep(.fc-daygrid-event) {
    cursor: pointer;
}

.fc:deep(.fc-daygrid-day.fc-day-today) {
    background: #dce2f7;
}

.fc:deep(.fc-col-header-cell) {
    background: v-bind(color) !important;
    border: v-bind(color) !important;
}

:deep(.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl
        .fc-daygrid-event.fc-event-start) {
    margin-bottom: 2px !important;
}

:deep(.fc.fc-theme-standard .fc-toolbar .fc-button) {
    background: #002d72 !important;
    border: #002d72 !important;
}

.p-panel:deep(.p-panel-header) {
    background: v-bind(color) !important;
    color: white !important;
    border-radius: none !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
}

.p-dropdown {
    width: 14rem;
}

@media (min-width: 991px) and (max-width: 1220px){
    .p-dropdown {
        width: 10rem;
    }
}

:deep(.p-image-preview-indicator){
    border-radius: 1%;
}

.img-fluid{
    margin:0 auto;
    max-height: calc(100vh - 40rem);
    min-height: 350px;
    object-fit: scale-down;
}


</style>