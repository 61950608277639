<template>
    <div class="card">
        <div class="row">
            <Toast />
            <DataTable :value="all_ships" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" scrollable
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            :globalFilterFields="['name','lowerbeds','shipowner','id_cruise_line.name']"
            v-model:filters="filters" removableSort
            stripedRows :loading="loading">   
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText id="filter_ships" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                        </span>
                    </div>
                </template>
                <template #empty>
                    <div class="row">
                        <div class="col text-center">
                            No records found.
                        </div>
                    </div>
                </template>
                <Column header="Name" field="name" :sortable="true" > 
                </Column>
                <Column header="LowerBeds" field="lowerbeds" :sortable="true" > 
                </Column>
                <Column header="Shipowner" field="shipowner" :sortable="true"> 
                </Column>
                <Column header="Cruise Line" field="id_cruise_line.name" :sortable="true" > 
                </Column>
                <Column header="Info" style="width: 7rem;" bodyStyle="overflow: visible">
                    <template #body="slotProps">
                        <Button icon="pi pi-eye" class="p-button-rounded p-button-warning p-button-outlined" @click="show_ship(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('change','ship')" header="Edit" style="width: 7rem;" bodyStyle="overflow: visible">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_ship(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','ship')" header="Delete" style="width: 7rem;" bodyStyle="overflow: visible">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_ship(slotProps.data)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_ship_modal" v-model:visible="ship_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Ship Details" :modal="true" class="p-fluid">
        <form id="form_edit_ship" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-10 md:col-6">
                        <div class="p-float-field">
                            <label for="name" class="ps-1 pb-1"> <strong>Name</strong> </label>
                            <InputText id="name" v-model="ship.name" autofocus :class="{'p-invalid': submitted && !ship.name || ship_errors.name}"/>
                            <small class="p-error" v-if="submitted && !ship.name">Name is required.</small>
                            <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.name" class="p-error"><span v-for="error in ship_errors.name" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                    <div class="col-10 md:col-6">
                        <div class="p-float-field">
                            <label for="cruise_line" :class="{'p-invalid': submitted && !ship.id_cruise_line || ship_errors.id_cruise_line}" class="ps-1 pb-1"> <strong> Cruise Line </strong>  </label>  
                            <Dropdown id="id_cruise_line" v-model="ship.id_cruise_line" :options="all_cruise_lines" optionLabel="name" :filter="true"> 
                            <template #value="slotProps">
                                <div v-if="slotProps.value && slotProps.value.name">
                                    <span>{{slotProps.value.name}}</span>
                                </div>
                                <div v-else-if="slotProps.label && !slotProps.value.value">
                                    <span >{{slotProps.name}}</span>
                                </div>
                            </template>
                            </Dropdown>
                            <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.id_cruise_line" class="p-error"><span v-for="error in ship_errors.id_cruise_line" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-10 md:col-6">
                        <div class="p-float-field">
                            <label for="shipowner" class="ps-1 pb-1"> <strong> Shipowner </strong> </label>  
                            <InputText id="shipowner" v-model="ship.shipowner" autofocus :class="{'p-invalid': submitted && ship_errors.shipowner}" />
                            <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.shipowner" class="p-error"><span v-for="error in ship_errors.shipowner" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                    <div class="col-10 md:col-6">
                        <div class="p-float-field">    
                            <label for="lowerbeds" class="ps-1 pb-1"> <strong> Lowerbeds </strong> </label>
                            <InputNumber id="lowerbeds" v-model="ship.lowerbeds" autofocus :class="{'p-invalid': submitted && ship_errors.lowerbeds}" />
                            <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.lowerbeds" class="p-error"><span v-for="error in ship_errors.lowerbeds" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-10 md:col-6 text-center">
                        <div class="p-float-field">
                            <label for="pictureUploader" class="ps-1 pb-1"> <strong> Picture </strong> </label>
                            <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUploadPicture" />
                            <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.picture" class="p-error"><span v-for="error in ship_errors.picture" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                    <div class="col-10 md:col-6 text-center">
                        <div class="p-float-field">
                            <label for="blueprintUploader" class="pb-1 ps-1"> <strong> Blueprint </strong> </label>
                            <FileUpload ref="fileUpload2" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUploadBlueprint" />
                            <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.blueprint" class="p-error"><span v-for="error in ship_errors.blueprint" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_ship_dialog"/>
            <Button data-cy="save_edit_ship" label="Save" form="form_edit_ship" type="submit"/>
        </template>
    </Dialog>

     <Dialog v-model:visible="ship_dialog_info" :style="{width: '50rem'}" header="Ship Information" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row pb-2 text-center">
                <div class="col">
                    <div class="row">
                      <b> Picture </b>
                    </div>
                    <div class="row">
                        <Image :src="ship_with_image.picture" height="200" preview />
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <b> Blueprint</b>
                    </div>
                    <div class="row">
                        <Image :src="ship_with_image.blueprint" height="200" preview />
                    </div>
                </div>
            </div>
            <div class="row pt-5 text-center">
                <div class="md:col">
                    <b> Name </b>
                    <p> {{ship.name}} </p>
                </div>
                <div class="md:col">
                    <b> Lowerbeds</b>
                    <span v-if="ship.lowerbeds">
                        <p> {{ship.lowerbeds}} </p>
                    </span>
                    <span v-else>
                        <p> - </p>
                    </span>
                </div>
            </div>
            <div class="row text-center">
                <div class="md:col">
                    <b> Shipowner </b>
                    <span v-if="ship.shipowner">
                        <p> {{ship.shipowner}} </p>
                    </span>
                    <span v-else>
                        <p> - </p>
                    </span>
                </div>
                <div class="md:col">
                    <b> Cruise Line </b>
                    <p> {{ship.id_cruise_line.name}} </p>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Close" icon="pi pi-times" class="p-button-text" @click="hide_ship_info_dialog"/>
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_ship_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="ship">Are you sure you want to delete <b>{{ship.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_ship_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deleteShips" />
        </template>
    </Dialog>

</template>

<script> 
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Toast from 'primevue/toast';
import FileUpload from 'primevue/fileupload'
import Image from 'primevue/image'
import { FilterMatchMode } from 'primevue/api';
import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'TableShips',
    components: {
        DataTable,
        Column,
        Button,
        Dialog,
        InputText,
        InputNumber,
        Dropdown,
        Toast,
        FileUpload,
        Image
    },
    data() {
        return {
            ship: {},
            ship_errors:{},
            picture:'',
            blueprint:'',
            delete_ship_dialog: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            ship_dialog: false,
            ship_dialog_info: false,
            submitted: false,
            active_index: 1,
            ship_with_image: {},
            ship_with_images: [
                {
                    "itemImageSrc": "",
                    "thumbnailImageSrc": "",
                    "alt": "Ship Picture",
                    "title": "Ship Picture" 
                },
                {
                    "itemImageSrc": "",
                    "thumbnailImageSrc": "",
                    "alt": "Ship Blueprint",
                    "title": "Ship Blueprint" 
                }
            ],
            responsiveOptions: [
				{
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_ships.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_ships')
            await this.$store.dispatch('load_all_cruise_lines')
            this.loading = false
        }
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_ships: function() {
            return this.$store.getters.all_ships
        },
        all_cruise_lines: function() {
            return this.$store.getters.all_cruise_lines
        },
        get_errors_ship: function() {
            return this.$store.getters.get_errors_ship
        },
        get_delete_ship_status: function() {
            return this.$store.getters.get_delete_ship_status
        },
        get_ship: function() {
            return this.$store.getters.get_ship
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },
        
        onUploadBlueprint(e){
            this.blueprint = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        onUploadPicture(e){
            this.picture = e.files[0];
            this.$refs.fileUpload2.clear();
            this.$refs.fileUpload2.uploadedFileCount = 0;
        },
        confirm_delete_ship(ship) {
            this.ship = ship;
            this.delete_ship_dialog = true;
        },
        async deleteShips() {
            await this.$store.dispatch('delete_ship', this.ship.id)
            if (!this.get_delete_incidence_zone_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Ship deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Ship can't be deleted", life: 3000});
            }
            this.delete_ship_dialog = false
        },
        edit_ship(ship) {
            this.ship = {...ship};
            this.ship_dialog = true;
        },
        async show_ship(ship) {
            await this.$store.dispatch('load_ship', ship.id)
            this.ship = {...ship};
            this.ship_with_image = this.get_ship
            this.ship_dialog_info = true;
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;

            if(!isFormValid) {
                return;
            }

            if(this.picture != ''){
                this.ship.picture = this.picture
            }
            if(this.blueprint != ''){
                this.ship.blueprint = this.blueprint
            }

            if(this.ship.lowerbeds == null) {
                this.ship.lowerbeds = 0
            }

            await this.$store.dispatch('update_ship', this.ship)
            this.ship_errors = this.get_errors_ship
            
            if(Object.keys(this.ship_errors).length === 0){
                this.picture = '';
                this.blueprint = '';
                this.hide_ship_dialog()
                this.ship = {};
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Ship modified', life: 3000});
            }
        },
        hide_ship_dialog() {
            this.ship_dialog = false;
            this.submitted = false;
        },
        hide_ship_info_dialog() {
            this.ship_dialog_info = false;
        }
    },
    validations() {
        return {
            ship: {
                name: { required },
                id_cruise_line: { required }
            }
        }
    },

}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>