<template>
    <Panel header="Extra Service" v-model:collapsed="custom_collapsed" :collapsed="true" :toggleable="true" @toggle="fill_custom_service()">
        <template #header>
            <div @click="custom_collapsed = !custom_collapsed; fill_custom_service()" class="col" style="padding: 0.7rem;">
                <div class="row">
                    <div class="col">
                        <span class="title_panels"> Extra Service </span>
                    </div>
                </div>
            </div>
        </template>
        <template #icons>
            <i v-if="get_custom_service.length != 0" class="p-panel-header-icon mr-2">
                <i v-tooltip.left="'Custom service required.'" class="fa-solid fa-circle fa-xl" style="color: #22C55E;"></i>
            </i>
        </template>
        <div class="container-fluid p-fluid">
            <div class="row">
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Supplier </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="all_suppliers" :disabled="!$ability.can('change','custom_service') || check_user_service_operator" :filter="true" optionLabel="name" v-model="custom_service.id_supplier" placeholder="Select a supplier" @change="load_custom_service_responsibles()">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span> {{slotProps.value.name}} </span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Responsible </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="custom_service_responsibles_list" :disabled="!$ability.can('change','custom_service') || check_user_service_operator" :filter="true" optionLabel="name" v-model="custom_service.id_supplier_responsible" placeholder="Select a responsible">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span>{{slotProps.value.name}}</span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$ability.can('add','supplier_responsible')" class="row justify-content-end">
                <div class="col-12 md:col-3 text-center">
                    <ModalSupplierResponsibles :service_sheet="true"/>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Title </b>
                        </div>
                    </Divider>
                    <InputText v-model="custom_service.title"></InputText>
                    <span v-if="get_error_custom_service">
                        <span v-for="item in get_error_custom_service" :key="item">
                            <small v-if="item.title" class="p-error">{{ item['title'][0] }}</small>
                        </span>
                    </span> 
                </div>
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Description </b>
                        </div>
                    </Divider>
                    <TextArea v-model="custom_service.description" :rows="5"></TextArea>
                    <span v-if="get_error_custom_service">
                        <span v-for="item in get_error_custom_service" :key="item">
                            <small v-if="item.description" class="p-error">{{ item['description'][0] }}</small>
                        </span>
                    </span> 
                </div>
            </div>
            <div class="row pt-3 justify-content-between">
                <div v-if="$ability.can('delete','custom_service') && custom_service && !check_user_service_operator" class="col-12 md:col-4 lg:col-4">
                    <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_custom_service()" :disabled="get_custom_service.length == 0"/>
                </div>
                <div v-if="$ability.can('change','custom_service') || $ability.can('add','custom_service') && !check_user_service_operator" class="col-12 md:col-3 lg:col-3">
                    <span v-if="get_custom_service.length != 0 && $ability.can('change','custom_service') && !check_user_service_operator">
                        <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_custom_service()"/>
                    </span>
                    <span v-else>
                        <Button label="Create" icon="fa-solid fa-plus" class="p-button-raised p-button-text p-button-success" @click="save_changes_custom_service()"/>
                    </span>
                </div>
            </div>
            <!--
            <div v-if="mail_custom_service_error" class="row pt-3 text-center">
                <div class="col" style="color: red;">
                    {{mail_custom_service_error}}
                </div>
            </div>-->
        </div>
    </Panel>

    <Dialog v-model:visible="delete_service" :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span> Are you sure you want to delete this service? </span>
        </div>
        <template #footer>
            <Button label="No"  class="p-button-text" @click="delete_service = false"/>
            <Button label="Yes" @click="handle_function_delete(service_selected)" />
        </template>
    </Dialog>

    <Dialog v-model:visible="modal_confirmation_mail"  :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="row">
            <div class="col">
                <span> Are you sure you want to send this mail? </span>
            </div>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="modal_confirmation_mail = false"/>
            <Button label="Yes" @click="handle_function_confirmation_mail(mail_selected)" />
        </template>
    </Dialog>
</template>

<script>
import Panel from 'primevue/panel'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import TextArea from 'primevue/textarea'

import ModalSupplierResponsibles from '@/components/AdminDashboard/Supplier_responsibles/ModalSupplier_responsibles.vue/'

import { num2time, num2timeedit } from '@/modules/utilities.js'


export default {
    name: 'Custodian',
    components: {
        ModalSupplierResponsibles,
        Panel,
        Button,
        Divider,
        Dropdown,
        Dialog,
        InputText,
        TextArea
    },
    props: {
        service_sheet: {
            type: Object
        },
        all_suppliers: {
            type: Object
        },
        min_date: {
            type: Object
        },
        max_date: {
            type: Object
        }
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            custom_service: {'id': '','id_supplier_responsible':null,'id_service_sheet': (Number(this.$route.params.sid)),'id_supplier': {"id": '1', "name": ''}, 'title':'', 'description': ''},
            custom_service_responsibles_list: [], 
            custom_collapsed: true,
            delete_service: false,
            modal_confirmation_mail: false
        }
    },
    computed: {
        get_custom_service: function() {
            return this.$store.getters.get_custom_service
        },
        get_error_custom_service: function() {
            return this.$store.getters.get_error_custom_service
        },
        get_supplier_service: function() {
            return this.$store.getters.get_supplier_service
        },
        get_supplier_service_responsible: function() {
            return this.$store.getters.get_supplier_service_responsible
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Ops') {
                    return true
                }
            }
            return false
        },
    },
    async mounted() {
        // Custodian Service check if exists 
        if(this.get_custom_service.length) {
            this.custom_service = this.get_custom_service[0]
        }

    },
    methods: {
        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
        handle_function_confirmation_mail(function_name){
            this[function_name]()
            this.modal_confirmation_mail = false
            this.mail_selected = ''
        },
        //  CUSTODIAN SERVICE
        async fill_custom_service() {
            await this.$store.dispatch('load_port_supplier_service')

            if(this.get_custom_service.length == 0) {
                this.custom_service.id_supplier = this.all_suppliers[0]
            }

            await this.$store.dispatch('load_supplier_service_responsible', this.custom_service.id_supplier.id)
            this.custom_service_responsibles_list = this.get_supplier_service_responsible

        },
        async save_changes_custom_service() {

            if(this.get_custom_service.length == 0) {
                await this.$store.dispatch('create_custom_service', this.custom_service)
                if(this.get_error_custom_service.length == 0) {
                    this.custom_service = this.get_custom_service[0]
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail:'Service created', life: 3000})
                }
            } else {
                await this.$store.dispatch('update_custom_service', this.custom_service)
                if(this.get_error_custom_service.length == 0) {
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail:'Service updated', life: 3000})
                }
                
            }
        },
        async load_custom_service_responsibles() {
            this.custom_service.id_supplier_responsible = ''
            await this.$store.dispatch('load_supplier_service_responsible', this.custom_service.id_supplier.id)
            this.custom_service_responsibles_list = this.get_supplier_service_responsible

            if(this.custom_service_responsibles_list.length != 0) {
                this.custom_service.id_supplier_responsible = this.custom_service_responsibles_list[0]
            } else {
                this.custom_service.id_supplier_responsible = ''
            }
           
        },
        async dialog_confirmation_custom_mail() {
            this.modal_confirmation_mail = true
            this.mail_selected = 'send_custom_service_mail'
        },
        async delete_custom_service() {
            this.service_selected = 'delete_custom_service_confirmation'
            this.delete_service = true
        },
        async delete_custom_service_confirmation() {           
            await this.$store.dispatch('delete_custom_service', this.get_custom_service[0].id)
            await this.$store.dispatch('load_custom_service', (Number(this.$route.params.sid)))
            
            this.custom_service = {'id_supplier_responsible': null, 'title': '', 'description':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))}
            
            
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Service deleted', life: 3000})
            

            this.fill_custom_service()
        },
        handle_function_delete(function_name){
            this[function_name]()
            this.delete_service = false
            this.service_selected = ''
        },
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
    border-radius: 0%;
}

.on_hold{
    border: 4px solid #002D72;
    border-radius: 10px;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}

.p-divider.p-divider-horizontal {
  color: v-bind(color);
}

.p-tooltip{
    background: v-bind(color);
}

ul {
    list-style-type: none;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}


:deep(.p-button-sm){
    padding: .5px .5px;
}

:deep(.p-scrollpanel-content) {
    overflow-y: hidden;
}
</style>