import axios from 'axios'

const state = {
    perms: [],
    perm_list: [],
    group_permissions: [],
    group_users: [],
    group_users_not: [],
    custom_permissions_group: [],
    errors_group_permission: [],
    menu_items: [],
    editable_group_permissions: []
}

const mutations = {
    SET_PERMS(state, perms) {
        var perms_json = []
        var perms_list = []
        for(var item in perms){
            var perm_splited = perms[item].split(/_(.*)/s)
            perms_json.push({action:perm_splited[0], subject:perm_splited[1]})
            if(perms_list.includes(perm_splited[1]) === false) {
                perms_list.push(perm_splited[1])
            }
        }
        state.perms = perms_json
        state.perm_list = perms_list
    },
    SET_GROUP_PERMISSIONS(state, group_permissions) {
        state.group_permissions = group_permissions
    },
    SET_EDIT_GROUP_PERMISSIONS(state, editable_group_permissions){
        state.editable_group_permissions =  editable_group_permissions
    },
    SET_GROUP_USERS(state, group_users) {
        state.group_users = group_users
    },
    SET_GROUP_USERS_NOT(state, group_users_not) {
        state.group_users_not = group_users_not
    },
    SET_CUSTOM_PERMISSIONS_GROUP(state, custom_permissions_group) {
        state.custom_permissions_group = custom_permissions_group
    },
    CREATE_USER_GROUP(state, group_permissions) {
        state.group_permissions.push(group_permissions)
    },
    SET_ERRORS_GROUP_PERMISSION(state, error) {
        state.errors_group_permission = error
    },
    SET_MENU_ITEMS(state, menu_items) {
        state.menu_items = menu_items
    },
    UPDATE_GROUP_PERMISSIONS(state, group_permissions){
        state.group_permissions = state.group_permissions.map(updated_group_permissions => {
            if(updated_group_permissions.id === group_permissions.id) {
                updated_group_permissions.permissions = group_permissions.permissions
            }
            return updated_group_permissions
        })
    },
    UPDATE_USER_GROUP(state, user){
        
        let index = state.group_users.findIndex(group_users => group_users.id == user.id)
        if(index != -1) {
            state.group_users.splice(index, 1)
            return state.group_users_not.push(user)
        } 
        
        
        let index_remove = state.group_users_not.findIndex(group_users_not => group_users_not.id == user.id)
        if(index_remove != -1) {
            state.group_users_not.splice(index_remove, 1)
            return state.group_users.push(user)
        }

    },
    DELETE_GROUP_USER(state, id) {
        let index = state.group_permissions.findIndex(group_permissions => group_permissions.id == id)
        state.group_permissions.splice(index, 1)
    }
}

const actions = {
    async load_perms({commit}){
        await axios.get(process.env.VUE_APP_API_URL  + "users/get_perms/")
            .then((response) => {
                commit('SET_PERMS', response.data)
            })
    },
    async load_group_permissions({commit}){
        await axios.get(process.env.VUE_APP_API_URL + "group_permissions/")
            .then((response) => {
                commit('SET_GROUP_PERMISSIONS', response.data)
            })
    },
    async load_group_users({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "users/get_by_group/" + id)
            .then((response) => {
                commit('SET_GROUP_USERS', response.data)
            })
    },
    async load_group_users_not({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "users/get_by_group_not/" + id)
            .then((response) => {
                commit('SET_GROUP_USERS_NOT', response.data)
            })
    },
    async load_custom_permissions_group({commit}, name) {
        await axios.get(process.env.VUE_APP_API_URL + "permission/get_by_name/" + name)
            .then((response) => {
                commit('SET_CUSTOM_PERMISSIONS_GROUP', response.data)
            })
    },
    async load_editable_permissions_group({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "permission/get_by_group_id/" + id)
            .then((response) => {
                commit('SET_EDIT_GROUP_PERMISSIONS', response.data)
            })
    },
    async load_menu_items({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "menu_bar_items/")
            .then((response) => {
                commit('SET_MENU_ITEMS', response.data)
            })
    },
    async create_user_group({commit}, group) {
        await axios.post(process.env.VUE_APP_API_URL + "group_permissions/", {
            name: group.group_name,
            permissions: group.format_perms,
            id_port: group.id_port,
            id_menu_bar_items: group.id_menu_bar_items
        })
            .then(async (response) => {
                commit('CREATE_USER_GROUP', response.data)
                await axios.post(process.env.VUE_APP_API_URL + "group_permissions/add_users/" + response.data.id + '/', {
                    users: group.format_users
                })
            })
            .catch((error) => {
                commit('SET_ERRORS_GROUP_PERMISSION',error.response.data)
            })       
    },
    async update_user_group({commit}, user) {
        await axios.patch(process.env.VUE_APP_API_URL + "users/" + user.id + "/", {
            groups: user.groups    
        }) 
            .then((response) => {
                commit('UPDATE_USER_GROUP', response.data)
            })
    },
    async update_group_permissions({commit}, group) {
        await axios.patch(process.env.VUE_APP_API_URL + "group_permissions/" + group.id + "/", {
            permissions: group.permissions,
            id_menu_bar_items: group.id_menu_bar_items    
        })
            .then((response) => {
                commit('UPDATE_GROUP_PERMISSIONS', response)
            })  
    },
    async delete_group_user({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "group_permissions/" + id + "/") 
            .then(() => {
                commit('DELETE_GROUP_USER', id)
            })
    }
}

const getters = {
    get_perms: (state) => state.perms,
    get_perm_list: (state) => state.perm_list,
    get_group_permissions: (state) => state.group_permissions,
    get_group_users: (state) => state.group_users,
    get_group_users_not: (state) => state.group_users_not,
    get_custom_permissions_group: (state) => state.custom_permissions_group,
    get_errors_group_permission: (state) => state.errors_group_permission,
    get_menu_items: (state) => state.menu_items,
    get_editable_group_permissions: (state) => state.editable_group_permissions
}

export default {
    state,
    mutations,
    actions,
    getters
};