<template>
    <div class="container p-fluid">
        <div class="row">
            <Divider align="center">
                <div class="inline-flex align-items-center">
                    <b> Schedule <i v-tooltip.right="'Dates cannot be left empty.'" class="fa fa-circle-info"></i> </b>
                </div>
            </Divider>
            <div class="col-12 xl:col-6">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <Calendar autocomplete="off" id="es_arrival_date" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled"  data-testid="e_arrival_date" v-model="new_service_sheet.estimated_arrival_datetime" dateFormat="yy-mm-dd" @date-select="change_estimation()" @change="change_estimation()"/>
                        <label for="es_arrival_date"> Arrival Date  </label>
                    </span>
                </div>
            </div>
            <div class="col-12 xl:col-6">
                <div class="col-12 pt-4">
                    <span class="p-float-label"> 
                        <Calendar autocomplete="off" id="es_departure_date" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled"  data-testid="e_arrival_hour" v-model="new_service_sheet.estimated_arrival_datetime_hour" hourFormat="24" :timeOnly="true" @date-select="change_estimation()" @change="change_estimation(); new_service_sheet.estimated_arrival_datetime_hour = num2time($event)"/>
                        <label for="es_departure_date"> Hour </label>
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 xl:col-6">
                <div class="col-12 pt-2">
                    <span class="p-float-label">
                        <Calendar autocomplete="off" id="es_arrival_date" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled"  data-testid="e_departure_date" v-model="new_service_sheet.estimated_departure_datetime" dateFormat="yy-mm-dd" @date-select="change_estimation()" @change="change_estimation()"/>
                        <label for="es_arrival_date"> Departure Date  </label>
                    </span>
                </div>
            </div>
            <div class="col-12 xl:col-6">
                <div class="col-12 pt-2">
                    <span class="p-float-label"> 
                        <Calendar autocomplete="off" id="es_departure_date" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled"  data-testid="e_departure_hour" v-model="new_service_sheet.estimated_departure_datetime_hour" hourFormat="24" :timeOnly="true" @date-select="change_estimation()" @change="change_estimation(); new_service_sheet.estimated_departure_datetime_hour = num2time($event)"/>
                        <label for="es_departure_date"> Hour </label>
                    </span>
                </div>
            </div>
        </div> 
        <div v-if="errors_service_sheet_estimation.length !== 0 && errors_service_sheet_estimation.estimated_arrival_datetime" class="row pt-3 text-center">
            <small v-if="Object.keys(errors_service_sheet_estimation).length !== 0 && errors_service_sheet_estimation.estimated_arrival_datetime" class="p-error"><span v-for="error in errors_service_sheet_estimation.estimated_arrival_datetime" :key="error">{{error}}<br></span></small> 
        </div>
        <div class="row">
            <Divider align="center">
                <div class="inline-flex align-items-center">
                    <b> Passengers </b>
                </div>
            </Divider>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.e_p_disembarking" data-testid="e_p_disembarking" id="e_p_disembarking" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled"  v-model="new_service_sheet.e_p_disembarking" @input="change_estimation()" /> 
                        <label for="e_p_disembarking"> Disembarking </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_estimation).length !== 0 && errors_service_sheet_estimation.e_p_disembarking" class="p-error"><span v-for="error in errors_service_sheet_estimation.e_p_disembarking" :key="error">{{error}}<br></span></small> 
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.e_p_embarking" data-testid="e_p_embarking" id="e_p_embarking" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled"  v-model="new_service_sheet.e_p_embarking" @input="change_estimation()" /> 
                        <label for="e_p_embarking"> Embarking </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_estimation).length !== 0 && errors_service_sheet_estimation.e_p_embarking" class="p-error"><span v-for="error in errors_service_sheet_estimation.e_p_embarking" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.e_p_transit" data-testid="e_p_transit" id="e_p_transit" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled"  v-model="new_service_sheet.e_p_transit" @input="change_estimation()" /> 
                        <label for="e_p_transit"> Transit </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_estimation).length !== 0 && errors_service_sheet_estimation.e_p_transit" class="p-error"><span v-for="error in errors_service_sheet_estimation.e_p_transit" :key="error">{{error}}<br></span></small>
                </div>
            </div>
        </div>
        <div v-if="user_port=='Port de Barcelona'" class="row">
            <Divider align="center">
                <div class="inline-flex align-items-center">
                    <b> Crew </b>
                </div>
            </Divider>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.e_c_disembarking" data-testid="e_c_disembarking" id="e_c_disembarking" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled" v-model="new_service_sheet.e_c_disembarking" @input="change_estimation()" /> 
                        <label for="e_c_disembarking"> Disembarking </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_estimation).length !== 0 && errors_service_sheet_estimation.e_c_disembarking" class="p-error"><span v-for="error in errors_service_sheet_estimation.e_c_disembarking" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.e_c_embarking" data-testid="e_c_embarking" id="e_c_embarking" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled"  v-model="new_service_sheet.e_c_embarking" @input="change_estimation()" />
                        <label for="e_c_embarking"> Embarking </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_estimation).length !== 0 && errors_service_sheet_estimation.e_c_embarking" class="p-error"><span v-for="error in errors_service_sheet_estimation.e_c_embarking" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">            
                        <InputNumber :placeholder="get_placeholders.e_c_transit" data-testid="e_c_transit" id="e_c_transit" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled"  v-model="new_service_sheet.e_c_transit" @input="change_estimation()" /> 
                        <label for="e_c_transit"> Transit </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_estimation).length !== 0 && errors_service_sheet_estimation.e_c_transit" class="p-error"><span v-for="error in errors_service_sheet_estimation.e_c_transit" :key="error">{{error}}<br></span></small>
                </div>
            </div>
        </div> 
    </div>
    <div v-if="button_confirm_estimation" class="col text-center pt-3">
        <Button data-testid="button_save" label="Save" icon="pi pi-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_estimation()"/>
    </div>
</template>

<script>
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Divider from 'primevue/divider'
import Button from 'primevue/button'
import { num2time, num2timeedit } from '@/modules/utilities.js'

export default {
    name: 'Estimation',
    components: {
        Calendar,
        InputNumber,
        Divider,
        Button
    },
    props: {
        service_sheet: {
            type: Object,
            required: true
        },
        user_port: {
            type: String,
            required: true
        },
        get_placeholders: {
            type: Object
        }
    },
    computed: {
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Ops') {
                    return true
                }
            }
            return false
        },
        get_errors_service_sheet_estimation: function() {
            return this.$store.getters.get_errors_service_sheet_estimation
        },
        check_user_admins: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Admins') {
                    return true
                }
            }
            return false
        },
        // Para editar una hoja de servicio despues de ser validada / confirmada, se tiene que ser Admin.
        is_disabled: function() {
            if(this.service_sheet.confirmed && !this.check_user_admins) {
                return true
            } else {
                return false
            }
        },
    },
    data(){
        return {
            color: localStorage.getItem('primary_color'),
            new_service_sheet: this.service_sheet,
            errors_service_sheet_estimation: '',
            is_on_hold_estimation: false,
            button_confirm_estimation: false,
            estimation_collapsed: true,
            ph_estimated_departure_datetime: this.service_sheet.estimated_departure_datetime +'T'+ this.service_sheet.estimated_departure_datetime_hour,
            ph_estimated_arrival_datetime: this.service_sheet.estimated_arrival_datetime+'T'+this.service_sheet.estimated_arrival_datetime_hour
        }
    },
    methods: {
        change_estimation() {
            this.is_on_hold_estimation = true
            this.button_confirm_estimation = true
        }, 
        // Function called when the button save from estimation section is clicked
        async save_changes_estimation() {
            
            if(this.new_service_sheet.estimated_departure_datetime == null || this.new_service_sheet.estimated_departure_datetime_hour == null) {
                this.new_service_sheet.estimated_departure_datetime = this.ph_estimated_departure_datetime.split('T')[0]
                this.new_service_sheet.estimated_departure_datetime_hour = this.ph_estimated_departure_datetime.split('T')[1].split(':')[0] + ':' + this.ph_estimated_departure_datetime.split('T')[1].split(':')[1]
                this.$toast.add({severity:'warn', summary: 'Warning', detail:'Estimated departure date not assigned correctly', life: 3000});
            }

            if(this.new_service_sheet.estimated_arrival_datetime_hour == null || this.new_service_sheet.estimated_arrival_datetime == null) {
                this.new_service_sheet.estimated_arrival_datetime = this.ph_estimated_arrival_datetime.split('T')[0]
                this.new_service_sheet.estimated_arrival_datetime_hour = this.ph_estimated_arrival_datetime.split('T')[1].split(':')[0] + ':' + this.ph_estimated_arrival_datetime.split('T')[1].split(':')[1]
                this.$toast.add({severity:'warn', summary: 'Warning', detail:'Estimated arrival date not assigned correctly', life: 3000});
            }

            await this.$store.dispatch('patch_service_sheet_estimation', this.new_service_sheet)
            this.errors_service_sheet_estimation = this.get_errors_service_sheet_estimation

            if(Object.keys(this.errors_service_sheet_estimation).length === 0){
                this.button_confirm_estimation = false
                this.is_on_hold_estimation = false
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Data Modified', life: 3000});
            }
        },
        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
    }
}
</script>

<style scoped>
.on_hold{
    border: 4px solid #002D72;
    border-radius: 10px;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}


:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

.p-divider.p-divider-horizontal {
  color: v-bind(color);
}


:deep(input:focus::placeholder) {
    opacity: 1;
}
</style>