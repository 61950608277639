<template>
    <div class="row content-section introduction mt-4">
        <div class="feature-intro">
            <h1> Settlement </h1>
        </div>
    </div>
    <div class="content-section introduction">
        <div class="feature-intro">
            <h2>Cards</h2>
        </div>
    </div>
    <div v-if="get_cards.length == 0">
        <div class="flex justify-content-center mb-5">
            <div class="col text-center">
                <InlineMessage> No cards available.</InlineMessage>
            </div>
        </div>
    </div>
    <Carousel v-else :value="get_cards" :numVisible="3" :numScroll="3" :responsiveOptions="responsiveOptions">
        <template #item="slotProps">
            <div class="div_card_main">
                <div class="card d-flex" style="cursor:pointer;"  @click="load_transactions_by_card(slotProps.data.id)" :class="{'active_card': slotProps.data.id === selected_card}">
                    <div class="card__front card__part">
                        <div class="card__front-square card__square card__info">Málaga</div>
                        <p class="card_numer">{{ format_number(slotProps.data.number) }}</p>
                        <div class="card__space-75">
                            <span class="card__label">Card holder</span>
                            <p class="card__info" v-if="slotProps.data.id_user_assigned.first_name && slotProps.data.id_user_assigned.last_name">{{ slotProps.data.id_user_assigned.first_name }} {{ slotProps.data.id_user_assigned.last_name }}</p>
                            <p class="card__info" v-else>{{ slotProps.data.id_user_assigned.username }}</p>
                        </div>
                        <div class="card__space-25">
                            <span class="card__label">Balance</span>
                            <p class="card__info"> {{ slotProps.data.balance ? slotProps.data.balance : '-' }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Carousel>
    <div class="content-section introduction">
        <div class="feature-intro grid">
            <div class="col-12 md:col p-fluid">
                <h2> Transactions </h2>
            </div>
            <div class="col-12 md:col p-fluid" style="color: gray">
                <h3>
                    Total <span style="color: black">{{ count_monthly_bills }}</span>
                </h3>
            </div>
            <div class="col-12 md:col p-fluid" style="color: gray">
                <h3>    
                    <i class="fa fa-arrow-trend-down me-2" style="color: red"></i> <span style="color: black">{{ total_import_bills.toFixed(2)}}</span>
                </h3>
            </div>
            <div class="col-12 md:col p-fluid">
                <Calendar v-model="date" view="month" dateFormat="MM, yy" placeholder="Date" @date-select="load_transactions_by_date($event)"></Calendar>
            </div>
        </div>
    </div>
    <div class="flex">
        <div class="col card p-0" style="height: 100%;">
            <DataTable :value="get_monthly_bills" paginator :rows="7" stripedRows>
                <Column field="total_import" header="Import" :sortable="true"></Column>
                <Column header="Concept" field="concept" :sortable="true"></Column>
                <Column header="Date" field="payment_date" :sortable="true"></Column>
                <template #empty>
                    <div class="row">
                        <div class="col text-center">
                            No records found.
                        </div>
                    </div>
                </template>
            </DataTable>
        </div>
        
    </div>
</template>

<script>
import Calendar from 'primevue/calendar' 
import Carousel from 'primevue/carousel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InlineMessage from 'primevue/inlinemessage'

import moment from 'moment'

export default {
    name: 'Billing settlement',
    components: {
        Calendar,
        Carousel,
        DataTable,
        Column,
        InlineMessage
    },
    data(){
        return {
            color: localStorage.getItem('primary_color'),
            index_cards: 0,
            load_data: {
                id_card: '',
                month: '',
                year: ''
            },
            date: moment().format('MMMM, yy'),
            first: 0,
            selected_card: '',
            responsiveOptions: [
                {
                    breakpoint: '1199px',
                    numVisible: 3,
                    numScroll: 3
                },
                {
                    breakpoint: '991px',
                    numVisible: 2,
                    numScroll: 2
                },
                {
                    breakpoint: '767px',
                    numVisible: 1,
                    numScroll: 1
                }
            ]
        }
    },
    computed: {
        get_cards: function() {
            return this.$store.getters.get_cards
        },
        get_monthly_bills: function() {
            return this.$store.getters.get_monthly_bills
        },
        total_import_bills: function (){
            return this.get_monthly_bills.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.total_import), 0)
        },
        count_monthly_bills: function() {
            return this.get_monthly_bills.length
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        }
    },
    async mounted() {
        await this.$store.dispatch('load_all_cards')
    },
    methods: {
        async load_transactions_by_card(data) {
            this.load_data.id_card = data
            this.selected_card = data

            if(this.date != '') {
                this.load_data.month = moment(this.date).format('MM')
                this.load_data.year = moment(this.date).format('YYYY')
                await this.$store.dispatch('load_monthly_bills_by_card', this.load_data)
            } 
        },
        async load_transactions_by_date(event) {
            this.load_data.month = moment(event).format('MM')
            this.load_data.year = moment(event).format('YYYY')
            
            if(this.load_data.id_card != '') {

                await this.$store.dispatch('load_monthly_bills_by_card', this.load_data)
            } 
        },
        format_date(value) {
            if(this.date != '') {
                return moment(value).format('MMMM YYYY')
            } else {
                return '-'
            }
           
        },
        format_number(value){
            return value.match(/.{1,4}/g).join(' ');
        }
    }
}
</script>

<style scoped>
.active_card{
    box-shadow: var(--primary-color) 10px 10px 15px -5px;
}



.back_odd{
    background-color: rgb(241, 240, 240);
}

@import url('https://fonts.googleapis.com/css?family=Space+Mono:400,400i,700,700i');

.card{
    width: 320px;
    height: 190px;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;
    perspective:600px;
    
  
}

.div_card_main{
    display: flex;
    justify-content: center;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
}
.card__part{
    top: 0;
    position: absolute;
    z-index: 1000;
    left: 0;
    display: inline-block;
    width: 320px;
    height: 190px;
    background-image: linear-gradient(to right bottom,  var(--indigo-900),  var(--indigo-800), var(--indigo-700), var(--indigo-600), var(--indigo-500)); /*linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    
    
    -webkit-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

}

.card__front{
    padding: 18px;

}

.card__back {
    padding: 18px 0;

}

.card__black-line {
    margin-top: 5px;
    height: 38px;
    background-color: #303030;
}

.card__logo {
    height: 45px;
}

.card__front-logo{
    position: absolute;
    top: 18px;
    right: 18px;
}
.card__square {
    border-radius: 5px;
    height: 30px;
}

.card_numer {
    display: block;
    width: 100%;
    word-spacing: 4px;
    font-size: 20px;
    letter-spacing: 2px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.card__space-75 {
    width: 75%;
    float: left;
}

.card__space-25 {
    width: 25%;
    float: left;
}

.card__label {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.8);
    letter-spacing: 1px;
}

.card__info {
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.card__back-content {
    padding: 15px 15px 0;
}
.card__secret--last {
    color: #303030;
    text-align: right;
    margin: 0;
    font-size: 14px;
}

.card__secret {
    padding: 5px 12px;
    background-color: #fff;
    position:relative;
}

.card__secret:before{
    content:'';
    position: absolute;
    top: -3px;
    left: -3px;
    height: calc(100% + 6px);
    width: calc(100% - 42px);
    border-radius: 4px;
    background: repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 10px);
}

.card__back-logo {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.card__back-square {
    position: absolute;
    bottom: 15px;
    left: 15px;
}



</style>