import axios from 'axios'


const state = {
    ship: {},
    ships: [],
    ship_errors:{},
    delete_status:false
};

const mutations = {
    SET_SHIPS(state, ships) {
        state.ships = ships
    },
    SET_SHIP(state, ship) {
        state.ship = ship
    },
    NEW_SHIP(state, ships) {
        state.ships.push(ships)
        state.ship_errors = {}
    },
    DELETE_SHIP(state, id) {
        let index = state.ships.findIndex(ships => ships.id == id)
        state.ships.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_SHIP(state, ship) {
        state.ships = state.ships.map(updated_ship => {
            if(updated_ship.id === ship.id) {
                updated_ship.name = ship.name
                updated_ship.lowerbeds = ship.lowerbeds
                updated_ship.shipowner = ship.shipowner
                updated_ship.id_cruise_line = ship.id_cruise_line
            }
            return updated_ship
        })
        state.ship_errors = {}
    },
    ERRORS_SHIP(state, errors){
        state.ship_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};

const actions = {
    async load_all_ships({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "ships/" )
            .then((response) => {
                commit('SET_SHIPS', response.data)
            })
    },
    async load_ship({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "ships/" + id)
            .then((response) => {
                commit('SET_SHIP', response.data)
            })
    },
    async delete_ship({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'ships/' + id)
            .then(() => {
                commit('DELETE_SHIP', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
    }, 
    async create_ship({commit}, ship) {
        if(typeof ship.picture.name != 'string'){
            delete ship['picture']
        }
        if(typeof ship.blueprint.name != 'string'){
            delete ship['blueprint']
        }
        if (ship.id_cruise_line !== null){
            ship['id_cruise_line'] = ship.id_cruise_line.id
        }
        var formData = new FormData();
        for(var item in ship){
            formData.append(item, ship[item])
        }
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
        await axios.post(process.env.VUE_APP_API_URL + 'ships/', formData, {headers})
            .then((response) => {  
                
                commit('NEW_SHIP', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_SHIP', error.response.data)
            })
    },
    async update_ship({commit}, ship) {
        if('picture' in ship){
            if(typeof ship.picture.name != 'string'){
                delete ship['picture']
            }
        }
        if('blueprint' in ship){
            if(typeof ship.blueprint.name != 'string'){
                delete ship['blueprint']
            }
        }
        var formData = new FormData();
        for(var item in ship){
            if(item == 'id_cruise_line'){
                formData.append(item, ship[item].id)
            }else{
                formData.append(item, ship[item])
            }
        }
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
       await axios.put(process.env.VUE_APP_API_URL + 'ships/' + ship.id + '/',  formData, {headers})
            .then((response) => {
                commit('UPDATE_SHIP', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SHIP', error.response.data)
            })
    },
};

const getters = {
    all_ships: (state) => state.ships,
    get_errors_ship: (state) => state.ship_errors,
    get_delete_ship_status:(state) => state.delete_status,
    get_ship_by_name:(state) => (name)=>{
        return state.ships.filter(ship => ship.name === name).slice(-1)
    },
    get_ship: (state) => state.ship
};

export default {
    state,
    mutations,
    actions,
    getters
};