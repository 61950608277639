<template>
    <ModalPassengers/>
    <TablePassengers/>
</template>

<script>
import TablePassengers from '@/components/AdminDashboard/Passengers/TablePassengers.vue/'
import ModalPassengers from '@/components/AdminDashboard/Passengers/ModalPassengers.vue/'
export default {
    name: 'Passengers',
    components: {
        TablePassengers,
        ModalPassengers
    }
}
</script>

<style scoped>

</style>