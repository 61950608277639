<template>
    <span v-if="task.priority == true">
        <div class="container" v-badge.danger="'!'">
            <div class="row mt-2 justify-content-between">
                <div v-if="!task.internal" class="col" style="padding-bottom: 0px;" >
                    <Tag severity="info"><h6>External</h6></Tag>
                    <span v-if="task.periodical" class="ms-2"><Tag value="Periodical" severity="success"/></span>
                </div>
                <div v-else class="col" style="padding-bottom: 0px;">
                    <Tag severity="primary"><h6>Internal</h6></Tag>
                    <span v-if="task.periodical" class="ms-2"><Tag value="Periodical" severity="success"/></span>
                </div>
                <div v-if="task.id_user_assigned" class="col text-end" style="padding-bottom: 0px;">
                    <div class="circle-image">
                        <img :src="task.id_user_assigned.picture" class="profile-pic" @click="toggle"/>
                    </div>    
                </div>    
                <div v-else class="col text-end" style="padding-bottom: 0px;">
                    <div class="circle-image">
                        <img :src="task.id_user_creator.picture" class="profile-pic" @click="toggle"/>
                    </div>    
                </div>
            </div>
            <div class="row" >
                <div class="col" style="padding-top: 0px; padding-bottom: 0px;">
                    <h5><span v-tooltip.top="task.title">{{format_title(task.title)}}</span></h5>
                </div>
            </div>
            <div class="row">
                <div class="col-6" style="color: gray;">
                    <i class="fa fa-clock" ></i> <small>{{format_time(task.creation_datetime)}}</small>
                </div>
                <div v-if="check_after(task.end_datetime)" class="col text-end">
                    <small style="color:green"> <i class="fa fa-check" style="color:green"></i> Finished </small>
                </div>
                <div v-if="task.work_report" class="col text-end">
                    <small><i class="fa fa-file" style="color: gray;"></i> Report </small>
                </div>
            </div>
        </div>
    </span>
    <span v-else>
        <div class="container" >
            <div class="row mt-2 justify-content-between">
                <div v-if="!task.internal" class="col" style="padding-bottom: 0px;" >
                    <Tag severity="info"><h6>External</h6></Tag>
                    <span v-if="task.periodical" class="ms-2"><Tag value="Periodical" severity="success"/></span>
                </div>
                <div v-else class="col" style="padding-bottom: 0px;">
                    <Tag severity="primary"><h6>Internal</h6></Tag>
                    <span v-if="task.periodical" class="ms-2"><Tag value="Periodical" severity="success"/></span>
                </div>
                <div v-if="task.id_user_assigned" class="col text-end" style="padding-bottom: 0px;">
                    <div class="circle-image">
                        <img :src="task.id_user_assigned.picture" class="profile-pic" @click="toggle"/>
                    </div>    
                </div>  
                <div v-else class="col text-end" style="padding-bottom: 0px;">
                    <div class="circle-image">
                        <img :src="task.id_user_creator.picture" class="profile-pic" @click="toggle"/>
                    </div>    
                </div> 
            </div>
            <div class="row" >
                <div class="col" style="padding-top: 0px; padding-bottom: 0px;">
                    <h5><span v-tooltip.top="task.title">{{format_title(task.title)}}</span></h5>
                </div>
            </div>
            <div class="row">
                <div class="col-6" style="color: gray;">
                    <i class="fa fa-clock" ></i> <small>{{format_time(task.creation_datetime)}}</small>
                </div>
                <div v-if="check_after(task.end_datetime)" class="col text-end">
                    <small style="color:green"> <i class="fa fa-check" style="color:green"></i> Finished </small>
                </div>
                <div v-if="task.work_report" class="col text-end">
                    <small><i class="fa fa-file" style="color: gray;"></i> Report </small>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import Tag from 'primevue/tag';

import moment from 'moment'

export default {
    name: "Scheduled Task",
    props: {
        task: {
            type: Object,
            required: true
        }
    },
    components: {
        Tag
    },
    methods: {
        format_hour(value) {
            if(value){
                return value.split('T')[1].split(':')[0] + ':' + value.split('T')[1].split(':')[1]
            } else {
                return 'Pending'
            }
        },
        format_title(value){
            if(value.length > 20){
                return value.substring(0, 20) + '...'
            } else {
                return value
            }
        },
        format_time(value){
            return moment(value).fromNow()
        },
        check_after(value){
            var now = moment()
            var date = moment(value)
            if(date.isBefore(now)){
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>

.circle-image{
  cursor:pointer;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 35px;
  height: 35px;
  padding: 0;
}

.circle-image img{
  width:100%;
  height:100%;
  object-fit: cover;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

h6{
    margin-bottom: 0px !important;
}

</style>