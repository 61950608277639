import axios from "axios"

const state = {
    terminal_statistics: [],
    call_statistics: [],
    cruise_line_statistics: [],
    water_meter_statistics:[]
}

const mutations = {
    LOAD_TERMINAL_STATISTICS(state, data) {
        state.terminal_statistics = data
    },
    LOAD_CALL_STATISTICS(state, data) {
        state.call_statistics = data
    },
    LOAD_CRUISE_LINE_STATISTICS(state, data) {
        state.cruise_line_statistics = data
    },
    LOAD_WATER_METER_STATISTICS(state, data) {
        state.water_meter_statistics = data
    },
}

const actions = {
    async load_terminals_statistics({commit}, year) {
        await axios.get(process.env.VUE_APP_API_URL  + 'terminals/get_terminals_statistics_by_year/' + year + '/')
            .then((response) => { 
                commit('LOAD_TERMINAL_STATISTICS', response.data)
            })
    },
    async load_calls_statistics({commit}, year) {
        await axios.get(process.env.VUE_APP_API_URL  + 'calls/get_calls_statistics_by_year/' + year + '/')
            .then((response) => { 
                commit('LOAD_CALL_STATISTICS', response.data)
            })
    },
    async load_cruise_line_statistics({commit}, year) {
        await axios.get(process.env.VUE_APP_API_URL  + 'cruise_lines/get_cruise_line_statistics_by_year/' + year + '/')
            .then((response) => { 
                commit('LOAD_CRUISE_LINE_STATISTICS', response.data)
            })
    },
    async load_water_meter_statistics({commit}, date) {
        await axios.get(process.env.VUE_APP_API_URL  + 'water_meters/get_by_month/' + date + '/')
            .then((response) => { 
                commit('LOAD_WATER_METER_STATISTICS', response.data)
            })
    }

}

const getters = {
    get_terminal_statistics: (state) => state.terminal_statistics,
    get_call_statistics: (state) => state.call_statistics,
    get_cruise_line_statistics: (state) => state.cruise_line_statistics,
    get_water_meter_statistics: (state) => state.water_meter_statistics
}

export default {
    state,
    actions,
    mutations,
    getters
}