<template>
    <div class="row mt-4 mb-2">
        <div class="col text-center">
            <Button label="New Proposal" class="p-button-raised p-button-success" @click="create_mode(); $router.push({name: 'Payment_proposal_creation', params: {bills: []}})"/>
        </div>
    </div>
    <div class="row">
        <DataView :value="get_bills_group" :paginator="true" :layout="layout" :rows="12">
            <template #grid="slotProps">
                <div class="col-12 md:col-4">
                    <div class="card" :class="{'border_green': slotProps.data.validated == true, 'border_red': slotProps.data.validated == false}">
                        <div class="container">
                            <div class="row mt-2" style=" border-bottom: 1px solid lightgray;">
                                <div class="col">
                                    <h5> {{ format_date(slotProps.data.date) }} </h5>
                                </div>
                                <div class="col-1 text-end">
                                    <i @click="confirm_dialog_delete(slotProps.data.id)" style="cursor: pointer;" class="fa fa-xmark"></i> 
                                </div>
                            </div>
                            <div class="row justify-content-around" >
                                <div class="col">
                                    <span> BILLS </span>
                                    <h2> {{ slotProps.data.bills.length }} </h2>
                                </div>
                                <div class="col text-end">
                                    <small> IMPORT </small><h2> {{ slotProps.data.total_import }} </h2>
                                </div>
                            </div>
                            <div class="row" style=" border-top: 1px solid lightgray;">
                                <div v-if="slotProps.data.validated != true" class="col my-auto text-start">
                                    <Button label="Validate" icon="fa fa-check" class="p-button-sm p-button-text p-button-rounded" @click="$router.push({name:'Payment_proposal_validation', params: { id: slotProps.data.id }})"/>
                                </div>
                                <div v-if="slotProps.data.validated != true" class="col my-auto text-end">
                                    <Button label="Edit" icon="fa fa-pen" class="p-button-sm p-button-text p-button-rounded" @click="edit_mode(slotProps.data.id); $router.push({name: 'Payment_proposal_creation'})"/>
                                </div>
                                <div v-if="slotProps.data.validated == true" class="col my-auto text-start">
                                    <Button label="View" icon="fa-solid fa-magnifying-glass" class="p-button-sm p-button-text p-button-rounded" @click="$router.push({name: 'Payment_proposal_validation', params: {id: slotProps.data.id, view_mode: 'view', date: slotProps.data.date}})"/>
                                </div>
                                <div v-if="slotProps.data.validated == true" class="col my-auto text-end">
                                    <Button label="Edit" icon="fa fa-pen" class="p-button-sm p-button-text p-button-rounded" @click="edit_mode(slotProps.data.id); $router.push({name: 'Payment_proposal_creation'})"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </DataView>
    </div>
    
    <Dialog header="Confirmation" v-model:visible="display_confirmation_modal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}" :modal="true">
        <div class="container">
            <div class="row">
                <div class="col">
                    Are you sure you want to delete this payment proposal?
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="No" @click="display_confirmation_modal = false" class="p-button-text"/>
            <Button label="Yes" @click="delete_payment_proposal" autofocus />
        </template>
    </Dialog>

</template>

<script>
import DataView from 'primevue/dataview'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'

import moment from 'moment'

export default {
    name: 'Payment_proposal',  
    components: {
        DataView,
        Button,
        Dialog
    } ,
    data() {
        return {  
            layout: 'grid',
            display_confirmation_modal: false,
            id_delete_payment_proposal: '',
        }
    },
    computed: {
        get_bills_group: function() {
            return this.$store.getters.get_bills_group
        }
    },
    async mounted() {
        await this.$store.dispatch('load_bills_groups')
    },
    methods: {
        format_date(value) {
            return moment(value).format('MMMM, yy')
        },
        async edit_mode(id) {
            await this.$store.dispatch('edit_mode', true)
            await this.$store.dispatch('set_id_bill_edit', id)
        },
        async create_mode() {
            await this.$store.dispatch('edit_mode', false)
            await this.$store.dispatch('set_id_bill_edit', '')
        },
        confirm_dialog_delete(id) {
            this.display_confirmation_modal = true
            this.id_delete_payment_proposal = id
        },
        delete_payment_proposal(){
            this.$store.dispatch('delete_payment_proposal', this.id_delete_payment_proposal)
            this.display_confirmation_modal = false
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Payment deleted.', life: 3000});
        }
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border: 2px solid rgba(0,0,255, 0.2);
    background-color: rgba(0,0,255, 0.1);
    margin: 1rem;
}

.border_green{
    border: 2px solid rgba(0, 128, 0, 0.4) !important;
    background-color: rgba(0, 128, 0, 0.1) ;
}

.border_red{
    border: 2px solid rgba(255, 0, 0, 0.4) !important;
    background-color: rgba(255, 0, 0, 0.1)
}

</style>