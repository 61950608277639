<template>
    <ModalCruise_lines/>
    <TableCruise_lines/>
</template>

<script>
import TableCruise_lines from '@/components/AdminDashboard/Cruise_lines/TableCruise_lines.vue'
import ModalCruise_lines from '@/components/AdminDashboard/Cruise_lines/ModalCruise_lines.vue'
export default {
  name: 'Cruise_lines',
  components: {
    TableCruise_lines,
    ModalCruise_lines
  }
}
</script>

<style scoped>
</style>