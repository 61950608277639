<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div v-if="$can('add','task_category') " class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Task Categories </h1>
            <div class="card p-1">
                <Button data-cy="new_task_category_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Task Category'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_task_category_modal" header="New Task category" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <div class="container">
                <form id="form_new_task_category" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted || task_category_errors.name}" />
                                <label for="name" :class="{'p-error':v$.name.$invalid && submitted}"> Name </label>
                                <small v-if="Object.keys(task_category_errors).length !== 0 && task_category_errors.name" class="p-error"><span v-for="error in task_category_errors.name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
                        </div>
                    </div> 
                </form>
            </div>
            <template #footer>
                <Button data-cy="create_task_category_button" form="form_new_task_category" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        Toast
    },
    data() {
        return {
            task_category_errors:{},
            displayModal: false,
            name: '',
            submitted: false,
            showMessage: false,
        }
    },
    computed: {
        get_errors_task_category: function() {
            return this.$store.getters.get_errors_task_category
        }
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_task_category() {
            let task_category = { 
                name: this.name,
            }
            
            await this.$store.dispatch('create_task_category', task_category)
            this.task_category_errors = this.get_errors_task_category
            if(Object.keys(this.task_category_errors).length === 0){
                this.toggleDialog();
                this.resetForm();
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

            let res = await this.add_task_category()
            if(res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Task category added', life: 3000});
            }
            
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }

        },
        resetForm() {
            this.name = '';
            this.submitted = false;
        }
    },
    validations() {
        return {
            name: { required }
        }
    },
};
</script>

<style scoped>
</style>