<template>
    <div class="card">
        <div class="row">
            <Toast />
            <DataTable :value="all_ship_fingers" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" scrollable
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            :globalFilterFields="['id_ship.name', 'id_finger.name']"
            v-model:filters="filters" 
            stripedRows :loading="loading" removableSort
            >   
                <template #header>
                    <div class="row">
                        <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                            <span class="p-input-icon-left ">
                                <i class="pi pi-search" />
                                <InputText id="filter_ship_fingers" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                            </span>
                        </div>
                    </div>
                </template>
                <template #empty>
                    <div class="row">
                        <div class="col text-center">
                            No records found.
                        </div>
                    </div>
                </template>
                <Column header="Ship" field="id_ship.name" :sortable="true">
                </Column>
                <Column header="Finger" field="id_finger.name" :sortable="true"> 
                </Column>
                <Column header="Position" field="position" :sortable="true"> 
                </Column>
                <Column header="Info" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button icon="pi pi-eye" class="p-button-rounded p-button-warning p-button-outlined" @click="show_ship_finger(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('change','ship_finger')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_ship_finger(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','ship_finger')" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_ship_finger(slotProps.data)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_ship_finger_modal" v-model:visible="ship_finger_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Ship finger Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10  md:col-6">
                    <div class="p-float-field">
                        <label for="ship" :class="{'p-invalid': submitted && !ship_finger.id_ship || ship_finger_errors.id_ship}" class="ps-1 pb-1"> <strong> Ship </strong>  </label>  
                        <Dropdown id="id_ship" v-model="ship_finger.id_ship" :options="all_ships" optionLabel="name" :filter="true"> 
                            <template #value="slotProps">
                                <div v-if="slotProps.value && slotProps.value.name">
                                    <span>{{slotProps.value.name}}</span>
                                </div>
                                <div v-else-if="slotProps.label && !slotProps.value.value">
                                    <span >{{slotProps.name}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(ship_finger_errors).length !== 0 && ship_finger_errors.id_ship" class="p-error"><span v-for="error in ship_finger_errors.id_ship" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="finger" :class="{'p-invalid': submitted && !ship_finger.id_finger || ship_finger_errors.id_finger}" class="ps-1 pb-1"> <strong> Finger </strong>  </label>  
                        <Dropdown id="id_finger" v-model="ship_finger.id_finger" :options="all_fingers" optionLabel="name" :filter="true"> 
                            <template #value="slotProps">
                                <div v-if="slotProps.value && slotProps.value.name">
                                    <span>{{slotProps.value.name}}</span>
                                </div>
                                <div v-else-if="slotProps.label && !slotProps.value.value">
                                    <span>{{slotProps.name}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(ship_finger_errors).length !== 0 && ship_finger_errors.id_finger" class="p-error"><span v-for="error in ship_finger_errors.id_finger" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-6 text-center">
                    <div class="p-float-field">
                        <label for="pictureUploader" class="ps-1 pb-1"> <strong> Picture </strong> </label>
                        <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUploadPicture" />
                        <small v-if="Object.keys(ship_finger_errors).length !== 0 && ship_finger_errors.picture" class="p-error"><span v-for="error in ship_finger_errors.picture" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col-6 text-center">
                    <div class="p-float-field">
                        <div v-for="position of positions" :key="position" class="field-checkbox mt-3">
                            <RadioButton name="position" :value="position" v-model="ship_finger.position"/>
                            <label :for="position.key" class="ms-2"> {{position}} </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_ship_finger_dialog"/>
            <Button data-cy="save_edit_ship_finger" label="Save" @click="save_ship_finger" />
        </template>
    </Dialog>

     <Dialog v-model:visible="ship_finger_dialog_info" :style="{width: '50vw'}" :breakpoints="{ '960px': '90vw', }" header="Ship finger Information" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row pb-2 text-center">
                <div class="col">
                    <div class="row">
                      <b> Picture </b>
                    </div>
                    <div class="row">
                        <div class="col">
                            <Image :src="ship_finger_with_image.picture" height="200" preview />
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row pt-5 text-center">
                        <div class="md:col">
                            <b> Finger </b>
                            <p> {{ship_finger.id_finger.name}} </p>
                        </div>
                    </div>
                    <div class="row pt-5 text-center">
                        <div class="md:col">
                            <b> Ship </b>
                            <p> {{ship_finger.id_ship.name}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Close"  class="p-button-text" @click="hide_ship_finger_info_dialog"/>
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_ship_finger_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="ship_finger">Are you sure you want to delete <b>{{ship_finger.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_ship_finger_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deleteShip_fingers" />
        </template>
    </Dialog>

</template>

<script> 
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast';
import FileUpload from 'primevue/fileupload'
import Image from 'primevue/image'
import RadioButton from 'primevue/radiobutton'
import { FilterMatchMode } from 'primevue/api';
import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'TableShip_fingers',
    components: {
        DataTable,
        Column,
        Button,
        Dialog,
        InputText,
        Dropdown,
        Toast,
        FileUpload,
        Image,
        RadioButton
    },
    data() {
        return {
            ship_finger: {},
            ship_finger_errors:{},
            picture:'',
            delete_ship_finger_dialog: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            ship_finger_dialog: false,
            ship_finger_dialog_info: false,
            submitted: false,
            active_index: 1,
            ship_finger_with_image: {},
            ship_finger_with_images: [
                {
                    "itemImageSrc": "",
                    "thumbnailImageSrc": "",
                    "alt": "Ship_finger Picture",
                    "title": "Ship_finger Picture" 
                },
            ],
            responsiveOptions: [
				{
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
            positions : [
                'Port Side',
                'Starboard Side'
            ],
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_ship_fingers.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_ships')
            await this.$store.dispatch('load_all_fingers')
            await this.$store.dispatch('load_all_ship_fingers')
            this.loading = false
        }
       
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_ship_fingers: function() {
            return this.$store.getters.all_ship_fingers
        },
        all_fingers: function() {
            return this.$store.getters.all_fingers
        },
        all_ships: function() {
            return this.$store.getters.all_ships
        },
        get_errors_ship_finger: function() {
            return this.$store.getters.get_errors_ship_finger
        },
        get_delete_ship_finger_status: function() {
            return this.$store.getters.get_delete_ship_finger_status
        },
        get_ship_finger: function() {
            return this.$store.getters.get_ship_finger
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        onUploadPicture(e){
            this.picture = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        confirm_delete_ship_finger(ship_finger) {
            this.ship_finger = ship_finger;
            this.delete_ship_finger_dialog = true;
        },
        async deleteShip_fingers() {
            await this.$store.dispatch('delete_ship_finger', this.ship_finger.id)
            if (!this.get_delete_incidence_zone_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Ship finger deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Ship finger can't be deleted", life: 3000});
            }
            this.delete_ship_finger_dialog = false
        },
        edit_ship_finger(ship_finger) {
            this.ship_finger = {...ship_finger};
            this.ship_finger_dialog = true;
        },
        async show_ship_finger(ship_finger) {
            await this.$store.dispatch('load_ship_finger', ship_finger.id)
            this.ship_finger = {...ship_finger};
            this.ship_finger_with_image = this.get_ship_finger
            this.ship_finger_dialog_info = true;
        },
        async save_ship_finger() {
            this.submitted = true;
            if(this.picture != ''){
                this.ship_finger.picture = this.picture
            }
            await this.$store.dispatch('update_ship_finger', this.ship_finger)
            this.ship_finger_errors = this.get_errors_ship_finger
            
            if(Object.keys(this.ship_finger_errors).length === 0){
                this.picture = '';
                this.hide_ship_finger_dialog()
                this.ship_finger = {};
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Ship finger modified', life: 3000});
            }
        },
        hide_ship_finger_dialog() {
            this.ship_finger_dialog = false;
            this.submitted = false;
        },
        hide_ship_finger_info_dialog() {
            this.ship_finger_dialog_info = false;
        }
    },
    validations() {
        return {
            picture: { required },
            id_ship: { required },
            id_finger: { required }
        }
    },

}
</script>

<style  scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>