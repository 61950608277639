<template>
    <div class="card">
        <div v-if="$can('view','user')" class="row">
            <Toast />
            <DataTable :value="all_users" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading" removableSort
            :globalFilterFields="['username', 'first_name','last_name', 'email']"
            v-model:filters="filters"  >
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText id="filter_users" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                        </span>
                    </div>
                </template>
                <Column header="Username" field="username" :sortable="true" style="min-width: 7rem"/>
                <Column header="First Name" field="first_name" :sortable="true" style="min-width: 7rem"/>
                <Column header="Last Name" field="last_name" :sortable="true" style="min-width: 7rem"/>
                <Column header="Email" field="email" :sortable="true" style="min-width: 7rem"/>
                <Column header="Active" v-if="check_user_admin"> 
                    <template #body="slotProps">
                        <InputSwitch v-model="slotProps.data.is_active" @change="change_user_active(slotProps.data)"></InputSwitch>
                    </template>
                </Column>
                <Column header="Info" style="width: 7rem;" bodyStyle="overflow: visible">
                    <template #body="slotProps">
                        <Button icon="pi pi-eye" class="p-button-rounded p-button-warning p-button-outlined" @click="show_profile_images(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('change','user')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_user(slotProps.data)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_users_modal" v-model:visible="user_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="User Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="username" class="ps-1 pb-1"> <strong>Username</strong> </label>
                        <InputText id="username" v-model.trim="user.username" required="true" autofocus :class="{'p-invalid': submitted && !user.username || user_errors.username}" />
                        <small class="p-error" v-if="submitted && !user.username">Username is required.</small>
                        <small v-if="Object.keys(user_errors).length !== 0 && user_errors.username" class="p-error"><span v-for="error in user_errors.username" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="first_name" class="ps-1 pb-1"> <strong> First name </strong> </label>  
                        <InputText id="first_name" v-model="user.first_name" required="true" autofocus :class="{'p-invalid': submitted && user_errors.first_name}" />
                        <small v-if="Object.keys(user_errors).length !== 0 && user_errors.first_name" class="p-error"><span v-for="error in user_errors.first_name" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="last_name" class="ps-1 pb-1"> <strong> Last name </strong> </label>  
                        <InputText id="last_name" v-model="user.last_name" required="true" autofocus :class="{'p-invalid': submitted && user_errors.last_name}" />
                        <small v-if="Object.keys(user_errors).length !== 0 && user_errors.last_name" class="p-error"><span v-for="error in user_errors.last_name" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="email" class="ps-1 pb-1"> <strong>Email</strong> </label>
                        <InputText id="email" v-model.trim="user.email" required="true" autofocus :class="{'p-invalid': submitted && !user.email || user_errors.email}" />
                        <small class="p-error" v-if="submitted && !user.email">Email is required.</small>
                        <small v-if="Object.keys(user_errors).length !== 0 && user_errors.email" class="p-error"><span v-for="error in user_errors.email" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <div class="row">
                        <div class="col text-center">
                            <div class="row ">
                                <span class="text-center"> Picture </span>
                            </div>
                            <div class="row">
                                <span v-if="typeof(user.profile.picture) == 'object'">
                                    <Image :src="user.profile.picture.objectURL" height="100" preview/>
                                </span>
                                <span v-else-if="user.profile.picture != null">
                                    <Image :src="user.profile.picture" height="100" preview/>
                                </span>
                            </div>
                        </div>
                        <div class="col text-center">
                            <div class="row">
                                <span class="text-center"> Signature </span>
                            </div>
                            <div class="row">
                                <span v-if="user.profile.signature == null">
                                    <Avatar label="?" shape="circle" class="mt-3" size="xlarge" />
                                </span>
                                <span v-else-if="typeof(user.profile.signature) === 'object'">
                                    <Image :src="user.profile.signature.objectURL" height="100" preview/>
                                </span>
                                <span v-else>
                                    <Image :src="user.profile.signature" height="100" preview/>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-around">
                        <div class="col text-center">
                            <div class="row">
                                <FileUpload ref="fileUpload_picture" class="p-button-rounded p-button-raised" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUpload_picture"/>
                            </div>
                            <small v-if="Object.keys(profile_errors).length !== 0 && profile_errors.picture" class="p-error"><span v-for="error in profile_errors.picture" :key="error">{{error}}<br></span></small>
                        </div>  
                        <div class="col text-center">
                            <div class="row">
                                <FileUpload ref="fileUpload_signature" class="p-button-rounded p-button-raised" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUpload_signature"/>
                            </div>
                            <small v-if="Object.keys(profile_errors).length !== 0 && profile_errors.signature" class="p-error"><span v-for="error in profile_errors.signature" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="row">
                <div class="col">
                    <Checkbox v-model="user.profile.mail_notifications" :binary="true"/>
                    <label class="ms-3" style="color: gray;"> Mail notifications </label>
                </div>
            </div>
            <div v-if="check_user_admin" class="row justify-content-center">
                <div class="col-12 md:col-3">
                    <Button label="Change Password" @click="dialog_change_password = true; this.error_password_admin = ''"/>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_user_dialog"/>
            <Button data-cy="save_edit_users" label="Save" @click="save_user" />
        </template>
    </Dialog>

    <Dialog v-model:visible="dialog_change_password" :breakpoints="{'760px': '30rem'}" :style="{width: '50rem'}" :modal="true" header="Change Password">
        <div class="container p-fluid">
            <div class="row mt-3">
                <div class="col">
                    <span class="p-float-label">
                        <Password v-model="password_admin" toggleMask>
                            <template #header>
                                <h6> Pick a password </h6>
                            </template>
                            <template #footer>
                                <Divider />
                                <p class="mt-2"> Requirements </p>
                                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                    <li> At least one lowercase </li>
                                    <li> At least one uppercase </li>
                                    <li> At least one numeric </li>
                                    <li> Minimum 8 characters </li>
                                </ul>
                            </template>
                        </Password>
                        <label> New Password </label>
                    </span>
                    <small class="p-error"> {{error_password_admin}} </small>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text"/>
            <Button label="Save" @click="change_password_admin"/>
        </template>
    </Dialog>

    <Dialog v-model:visible="user_dialog_images" :breakpoints="{'760px': '30rem'}" :style="{width: '60rem'}" header="User profile images" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row pb-2 text-center">
                <div class="col-12 md:col-6">
                    <div class="row">
                      <b> Picture </b>
                    </div>
                    <div class="row">
                        <Image :src="user_data_dialog.profile.picture" height="200" preview />
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <div class="row">
                        <b> Signature</b>
                    </div>
                    <div class="row ">
                        <span class="mt-5" v-if="user_data_dialog.profile.signature == null">
                            <Avatar label="?" shape="circle" class="mt-5" size="xlarge" />
                        </span>
                        <span v-else>
                            <img alt="ship picture" :src="user_data_dialog.profile.signature" class="card-img-top img-fluid w-100"/>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Close" class="p-button-text" @click="hide_user_profile_images_dialog"/>
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import Image from 'primevue/image'
import Checkbox from 'primevue/checkbox'
import Avatar from 'primevue/avatar'
import FileUpload from 'primevue/fileupload'
import Password from 'primevue/password'
import Divider from 'primevue/divider'
import InputSwitch from 'primevue/inputswitch'

import axios from 'axios'

import { FilterMatchMode } from 'primevue/api';
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";


export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'TableUsers',
    components: {
        DataTable,
        Column,
        Button,
        InputText,
        Toast,
        Dialog,
        Image,
        Avatar,
        FileUpload,
        Checkbox,
        Password,
        Divider,
        InputSwitch
    },
    data() {
        return {
            user: {},
            user_errors:{},
            profile_errors:{},
            user_dialog: false,
            user_data_dialog:'',
            submitted: false,
            user_dialog_images: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            loading: false,
            dialog_change_password: false,
            password_admin: '',
            error_password_admin: '',
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }   
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_users.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_users_management')
            this.loading = false
        } 
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        all_users: function() {
            return this.$store.getters.users_management
        },
        get_errors_user: function(){
            return this.$store.getters.get_errors_user
        },
        get_delete_user_status: function() {
            return this.$store.getters.get_delete_user_status
        },
        get_errors_profile: function() {
            return this.$store.getters.get_errors_profile
        },
        check_user_admin: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Admins' || this.get_current_user.groups[index].name == 'Super Admins') {
                    return true
                }
            }
            return false
        },
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        async onUpload_picture(e) {
            this.user.profile.picture = e.files[0]
            this.$refs.fileUpload_picture.clear();
            this.$refs.fileUpload_picture.uploadedFileCount = 0;
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Picture selected.', life: 3000});
        },
        async onUpload_signature(e) {
            this.user.profile.signature = e.files[0]
            this.$refs.fileUpload_signature.clear();
            this.$refs.fileUpload_signature.uploadedFileCount = 0;
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Picture selected.', life: 3000});
        },
        async show_profile_images(user) {
            this.user_data_dialog = user
            this.user_dialog_images = true;
        },
        edit_user(user) {
            this.user = {...user};
            this.user_dialog = true;
        },
        async save_user() {
            this.submitted = true;
			if (this.user.username.trim()) {

                await this.$store.dispatch('update_user', this.user)
                this.user_errors = this.get_errors_user
                this.profile_errors = this.get_errors_profile
                if(Object.keys(this.user_errors).length === 0 && Object.keys(this.profile_errors).length === 0){
                    this.hide_user_dialog();
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'User modified', life: 3000});
                }
            }
        },
        hide_user_dialog() {
            this.user_dialog = false;
            this.submitted = false;
        },
        hide_user_profile_images_dialog() {
            this.user_dialog_images = false;
        },
        async change_password_admin () {
            this.error_password_admin = ''
            await axios.put(process.env.VUE_APP_API_URL + 'users/change_admin_password/' + this.user.id + '/', {
                password: this.password_admin 
            }) 
                .then(() => {
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Success', detail:'Password changed successfully.', life: 3000});
                    this.dialog_change_password = false
                })
                .catch((error) => {
                    this.error_password_admin = error.response.data.password
                })
        },
        async change_user_active(value) {
            await this.$store.dispatch('activate_user', value)
            if(value.is_active) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Success', detail:'User activated.', life: 3000});
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Success', detail:'User desactivated.', life: 3000});
            }
           
        }
    },
    validations() {
        return {
            username: { required },
            email: { required }
        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

.card-img-top {
    max-height: 200px;
    min-height: 200px;
    object-fit: cover;
}

:deep(.p-input-icon-right>svg){
    right: 0.75rem
}

</style>