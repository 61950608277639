<template>
    <Toast/>
    <div v-if="$can('view','call')" class="pt-4 pb-2">
        <div class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Calls </h1>
            <div v-if="$can('add','call')" class="card p-1">
                <Button data-cy="new_call_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Call'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog header="New Call" data-cy="new_call_modal" v-model:visible="displayModal" :modal="true" :style="{ width: '60vw' }" :breakpoints="{ '960px': '80vw', '641px': '95vw' }">
            <div class="container">
                <form id="form_new_call" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                    <div class="row justify-content-center mt-2">
                        <div class="col-10 md:col-3 pt-3">
                            <div class="p-float-label">     
                                <Calendar data-cy="arrival_date_calendar" autocomplete="off" id="arrival_datetime" dateFormat="yy-mm-dd" v-model="v$.arrival_datetime.$model" :class="{'p-invalid':v$.arrival_datetime.$invalid && submitted || call_errors.arrival_datetime}"/>
                                <label for="arrival_datetime" :class="{'p-error':v$.arrival_datetime.$invalid && submitted } "> Arrival date* </label>
                            </div>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.arrival_datetime" class="p-error"><span v-for="error in call_errors.arrival_datetime" :key="error">{{error}}<br></span></small>                       
                            <small v-if="(v$.arrival_datetime.$invalid && submitted) || v$.arrival_datetime.$pending.$response" class="p-error">{{v$.arrival_datetime.required.$message.replace('Value', 'Date')}}</small>
                        </div>
                        <div class="col-10 md:col-3 pt-3">
                            <div class="p-float-label">
                                <Calendar data-cy="arrival_time_calendar" autocomplete="off" id="arrival_hour" v-model="arrival_datetime_hour" :timeOnly="true" hourFormat="24" @change="arrival_datetime_hour = num2time($event)" :class="{'p-invalid':v$.arrival_datetime_hour.$invalid && submitted || call_errors.arrival_datetime}"/>
                                <label for="arrival_hour"> Hour* </label>
                            </div>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.arrival_datetime_hour" class="p-error"><span v-for="error in call_errors.arrival_datetime_hour" :key="error">{{error}}<br></span></small>                       
                            <small v-if="(v$.arrival_datetime_hour.$invalid && submitted) || v$.arrival_datetime_hour.$pending.$response" class="p-error">{{v$.arrival_datetime_hour.required.$message.replace('Value', 'Hour')}}</small>
                        </div>
                        <div class="col-10 md:col-3 pt-3">
                            <div class="p-float-label">   
                                <Calendar data-cy="departure_date_calendar" autocomplete="off" id="departure_datetime" dateFormat="yy-mm-dd" v-model="v$.departure_datetime.$model" :class="{'p-invalid':v$.departure_datetime.$invalid && submitted || call_errors.departure_datetime}"/>
                                <label for="departure_datetime">Departure date*</label>
                            </div>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.departure_datetime" class="p-error"><span v-for="error in call_errors.departure_datetime" :key="error">{{error}}<br></span></small>
                            <small v-if="(v$.departure_datetime.$invalid && submitted) || v$.departure_datetime.$pending.$response" class="p-error">{{v$.departure_datetime.required.$message.replace('Value', 'Date')}}</small>
                        </div>
                        <div class="col-10 md:col-3 pt-3">
                            <div class="p-float-label">
                                <Calendar data-cy="departure_time_calendar" autocomplete="off" id="departure_hour" v-model="departure_datetime_hour" :timeOnly="true" hourFormat="24" @change="departure_datetime_hour = num2time($event)" :class="{'p-invalid':v$.departure_datetime_hour.$invalid && submitted || call_errors.departure_datetime}"/>
                                <label for="departure_hour"> Hour* </label>
                            </div>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.departure_datetime_hour" class="p-error"><span v-for="error in call_errors.departure_datetime_hour" :key="error">{{error}}<br></span></small>
                            <small v-if="(v$.departure_datetime_hour.$invalid && submitted) || v$.departure_datetime_hour.$pending.$response" class="p-error">{{v$.departure_datetime_hour.required.$message.replace('Value', 'Hour')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_ship" id="id_ship" v-model="id_ship" :options="all_ships" optionLabel="name" :filter="true" @change="load_ship_agent($event)" :class="{'p-invalid':v$.id_ship.$invalid && submitted || call_errors.id_ship}"/>
                                <label for="id_ship"> Ship* </label>
                            </div>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.id_ship" class="p-error"><span v-for="error in call_errors.id_ship" :key="error">{{error}}<br></span></small>
                            <small v-if="(v$.id_ship.$invalid && submitted) || v$.id_ship.$pending.$response" class="p-error">{{v$.id_ship.required.$message.replace('Value', 'Ship')}}</small>
                        </div>
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <Dropdown
                                id="ship_agent"
                                v-model="ship_agent"
                                :options="get_ship_agents"
                                optionLabel="name"
                                optionValue="id"
                                @change="load_ship_responsibles($event)"
                                :filter="true"
                                />
                                <label for="ship_agent"> Ship Agent </label>
                                <small v-if="Object.keys(call_errors).length !== 0 && call_errors.ship_agent" class="p-error"><span v-for="error in call_errors.ship_agent" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <Dropdown id="ship_responsible" v-model="ship_responsible" :options="get_ship_responsible" optionLabel="name" optionValue="id"/>
                                <label for="ship_responsible" > Ship Responsible </label>
                            </div>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.ship_responsible" class="p-error"><span v-for="error in call_errors.ship_responsible" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>   
                    <div class="row justify-content-start">
                        <div class="col-3 md:col-4 pt-3">
                            <div class="p-float-label">
                                <Checkbox id="unconvention_ship" v-tooltip.bottom="'Mark this option if the ship is the first that has arrived to this port.'" v-model="is_new" :binary="true"></Checkbox>
                                <label class="ms-3"> Ship First Call </label> 
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-3 md:col-4 pt-3">
                            <div class="p-float-label">
                                <Checkbox id="unconvention_ship" v-tooltip.bottom="'Mark this option if the ship will only require the water service'" v-model="is_unconventional" :binary="true"></Checkbox>
                                <label  class="ms-3"> Unconventional Ship </label> 
                            </div>
                        </div>
                    </div>
                </form> 
            </div>
            <template #footer>
                <div class="container">
                    <Button data-cy="create_call_button" form="form_new_call" type="submit" label="Submit" class="p-button-raised"/>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Toast from "primevue/toast";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { num2time } from '@/modules/utilities.js'
import Checkbox from 'primevue/checkbox';

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        Calendar,
        Toast,
        Dropdown,
        Checkbox
    },
    data() {
        return {
            displayModal: false,
            ship_agent: "",
            ship_responsible: "",
            phone: "",
            fax: "",
            arrival_datetime: "",
            arrival_datetime_hour: "",
            departure_datetime: "",
            departure_datetime_hour: "",
            is_unconventional: false,
            is_new: false,
            id_ship: null,
            id_port: null,
            call_errors: {},
            submitted: false,
            showMessage: false,
        };
    },
    computed: {
        all_ships: function () {
            return this.$store.getters.all_ships;
        },
        all_ports: function () {
            return this.$store.getters.all_ports;
        },
        get_errors_call: function () {
            return this.$store.getters.get_errors_call;
        },
        get_ship_agents: function() {
            return this.$store.getters.get_ship_agents ? this.$store.getters.get_ship_agents : []
        },
        get_ship_responsible: function() {
            return this.$store.getters.get_ship_responsible ?  this.$store.getters.get_ship_responsible  : []
        }
    },
    methods: {
        num2time(data) {
            return num2time(data)
        },
        async load_ship_agent(id) {
            await this.$store.dispatch('load_ship_agents', id.value.id_cruise_line.id)
        },
        async load_ship_responsibles(id) {
            await this.$store.dispatch('load_ship_responsibles', id.value)
        },
        async openModal() {
            await this.$store.dispatch('empty_ship_agents')
            await this.$store.dispatch('empty_ship_responsibles')
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_call() {

            let call = {
                ship_agent: this.ship_agent,
                arrival_datetime: this.arrival_datetime,
                arrival_datetime_hour: this.arrival_datetime_hour,
                departure_datetime: this.departure_datetime,
                departure_datetime_hour: this.departure_datetime_hour, 
                id_ship: this.id_ship,
                id_ship_responsible: this.ship_responsible,
                phone: this.phone,
                fax: this.fax,
                id_port: this.id_port,
                is_unconventional: this.is_unconventional,
                is_new: this.is_new
            };

            await this.$store.dispatch("create_call", call);
            this.call_errors = this.get_errors_call;
            if (Object.keys(this.call_errors).length === 0) {
                this.toggleDialog();
                this.resetForm();
                return true;
            }
            return false;
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }

            let res = await this.add_call();
            this.$toast.removeAllGroups();
            if (res) {
                this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Call added",
                life: 3000,
                });
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal();

            if (!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.ship_agent = "";
            this.arrival_datetime = "";
            this.arrival_datetime_hour = "";
            this.departure_datetime = "";
            this.departure_datetime_hour = "";
            this.id_ship = null;
            this.ship_responsible = "";
            this.phone = "";
            this.fax = "";
            this.id_port = null;
            this.submitted = false;
            this.is_unconventional = false
            this.is_new = false
        },
    },
    validations() {
        return {
            arrival_datetime: { required },
            arrival_datetime_hour: { required },
            departure_datetime: { required },
            departure_datetime_hour: { required },
            id_ship: { required },
        };
    },
};
</script>

<style scoped>

</style>