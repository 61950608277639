import axios from 'axios'

const state = {
    ship_agents: [],
    ship_agent_errors:{}
}

const mutations = {
    SET_SHIP_AGENTS(state, ship_agents) {
        state.ship_agents = ship_agents
    },
    NEW_SHIP_AGENT(state, ship_agent) {  
        state.ship_agents.push(ship_agent)
        state.ship_agent_errors = {}
    },
    DELETE_SHIP_AGENT(state, id) {
        let index = state.ship_agents.findIndex(ship_agents => ship_agents.id == id)
        state.ship_agents.splice(index, 1)
    },
    UPDATE_SHIP_AGENT(state, ship_agent) {
        state.ship_agents = state.ship_agents.map(updated_ship_agent => {
            if(updated_ship_agent.id === ship_agent.id) {
                updated_ship_agent.name = ship_agent.name
                updated_ship_agent.id_cruise_line = ship_agent.id_cruise_line
            }
            return updated_ship_agent
        })
        state.ship_agent_errors = {}
    },
    ERRORS_SHIP_AGENT(state, errors){
        state.ship_agent_errors = errors
    }
}

const actions = {
    async load_all_ship_agents({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "ship_agents/")
            .then((response) => {
                commit('SET_SHIP_AGENTS', response.data)
            })
    },
    async create_ship_agent({commit}, ship_agent) {
        var cruise_lines = []

        for (var i in ship_agent.id_cruise_line) {
            cruise_lines.push(ship_agent.id_cruise_line[i].id)
        }

        await axios.post(process.env.VUE_APP_API_URL  + 'ship_agents/', { name: ship_agent.name, id_cruise_line: cruise_lines })
            .then((response) => {
                commit('NEW_SHIP_AGENT', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SHIP_AGENT', error.response.data)
            })
    },
    delete_ship_agent({commit}, id) {
        axios.delete(process.env.VUE_APP_API_URL + 'ship_agents/' + id)
            .then(() => {
                commit('DELETE_SHIP_AGENT', id);
            })
            
    }, 
    async update_ship_agent({commit}, ship_agent){
        var cruise_lines = []

        for (var i in ship_agent.id_cruise_line) {
            cruise_lines.push(ship_agent.id_cruise_line[i].id)
        }

        await axios.put(process.env.VUE_APP_API_URL  + 'ship_agents/' + ship_agent.id + '/',  { name: ship_agent.name, id_cruise_line: cruise_lines })
            .then(() => {
                
                commit('UPDATE_SHIP_AGENT', ship_agent)
            })
            .catch((error) => {
                
                commit('ERRORS_SHIP_AGENT', error.response.data)
            })
    },
}

const getters = {
    all_ship_agents: (state) => state.ship_agents,
    get_ship_agent_errors: (state) => state.ship_agent_errors,
    /*get_ship_agent_by_name:(state) => (name)=>{
        return state.ship_agents.filter(ship_agent => ship_agent.name === name).slice(-1)
    },*/
}

export default {
    state,
    mutations,
    actions,
    getters
};