<template>
    <!--
    <div class="row">
        <div class="col text-end">
            <Button icon="fa fa-xmark" class="p-button-text p-button-raised" @click="change_view"/>
        </div>
    </div>
    -->
    <div class="grid mt-5">
        <div v-for="terminal in all_terminals" :key="terminal" class="col-12 lg:col-4 mt-2 "> 
            <div class="container">
                <div class="card flex justify-content-center mt-2 color_opacity">
                    <div class="col-12 text-center" >
                        <h5 style="margin-bottom: 0px; "> Terminal <strong> {{terminal.name}} </strong> </h5>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="container">
                        <div class="row mt-3">
                            <div class="col-2 text-center">
                                <i class="fa-solid fa-book"></i>
                            </div>
                            <div class="col">
                                <small style="color: gray;"> CALLS </small>
                            </div>
                            <div class="col text-end">
                                <strong v-if="statistics[terminal.name]">{{statistics[terminal.name].calls}}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2 text-center">
                                <i class="fa-solid fa-person-walking-arrow-right"></i>
                            </div>
                            <div class="col">
                                <small style="color: gray;"> EMBARKING </small>
                            </div>
                            <div class="col text-end">
                                <strong v-if="statistics[terminal.name]">{{statistics[terminal.name].emb}}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2 text-center">
                                <i class="fa-solid fa-person-walking-arrow-right fa-flip-horizontal"></i>
                            </div>
                            <div class="col">
                                <small style="color: gray;"> DISEMBARKING </small>
                            </div>
                            <div class="col text-end">
                                <strong v-if="statistics[terminal.name]">{{statistics[terminal.name].dis}}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2 text-center">
                                <i class="fa-solid fa-people-arrows"></i>
                            </div>
                            <div class="col">
                                <small style="color: gray;"> E + D </small>
                            </div>
                            <div class="col text-end">
                                <strong v-if="statistics[terminal.name]">{{statistics[terminal.name]['E+D']}}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2 text-center">
                                <i class="fa-solid fa-ship"></i>
                            </div>
                            <div class="col">
                                <small style="color: gray;"> TRANSIT </small>
                            </div>
                            <div class="col text-end">
                                <strong v-if="statistics[terminal.name]">{{statistics[terminal.name].transit}}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2 text-center">
                                <i class="fa-solid fa-people-group"></i>
                            </div>
                            <div class="col">
                                <small style="color: gray;"> PAX </small>
                            </div>
                            <div class="col text-end">
                                <strong v-if="statistics[terminal.name]">{{statistics[terminal.name].pax}}</strong>
                            </div>
                        </div>
                        <div v-if="current_port == 'Port de Barcelona'" class="row mb-3">
                            <div class="col-2 text-center">
                                <i class="fa-solid fa-droplet"></i>
                            </div>
                            <div class="col">
                                <small style="color: gray;"> WATER </small>
                            </div>
                            <div class="col text-end">
                                <strong v-if="statistics[terminal.name]">{{statistics[terminal.name].water}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--
    <div class="row justify-content-between">
        <div class="col-6">
            <Listbox v-model="option_selected" :options="title_options" optionLabel="title" optionValue="value" @change="push_data($event)"/>
        </div>
        <div class="col-4 text-center">
            <Chart type="pie" :data="chartData" :options="lightOptions" />
        </div>
    </div>
    -->
</template>

<script>
// import Chart from 'primevue/chart'
// import Listbox from 'primevue/listbox'
// import Button from 'primevue/button'

export default {
    name: 'Terminal Statistics',
    components: {
        // Chart,
        // Listbox,
        // Button
    },
    props: {
        statistics: {
            type: Object
        },
        terminal_names: {
            type: Object
        },
    },
    data() {
        return {
            current_port: localStorage.getItem('user_port'),
            color: localStorage.getItem('primary_color'),
            opacity_color: '',
            view: '',
            new_data: [],
            option_selected: 'calls',
            chartData: {
                labels: this.terminal_names,
                datasets: [
                    {
                        data: [300, 50, 100],
                        backgroundColor: ["#1F77B4","#2CA02C","#FF7F0E","#D62728","#9467BD","#8C564B","#E377C2",],
                    }
                ]
            },
            lightOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            },
            title_options: [
                {
                    title: 'Calls',
                    value: 'calls'
                },
                {
                    title: 'Embarking',
                    value: 'emb'
                },
                {
                    title: 'Disembarking',
                    value: 'dis'
                },
                {
                    title: 'E+D',
                    value: 'E+D'
                },
                {
                    title: 'Transit',
                    value: 'transit'
                },
                {
                    title: 'PAX',
                    value: 'pax'
                },
                {
                    title: 'Water',
                    value: 'water'
                },
            ],
        }
    },
    computed: {
        all_terminals: function() {
            return this.$store.getters.all_terminals
        } 
    },
    mounted() {
        if(this.color == '#6E3FA3') {
            this.opacity_color = 'rgba(108, 67, 154, .3)'
        } else if (this.color == '#0097A9') {
            this.opacity_color = 'rgba(0, 151, 169, .3)'
        }
        this.$store.dispatch('load_all_terminals')
    },
    methods: {
        change_view() {
            this.view = '' 
        },
        push_data(value) {
            this.new_data = []

            for(var item in this.statistics){
                this.new_data.push(this.statistics[item][value.value])   
            }

            this.chartData = {
                labels: this.terminal_names,
                datasets: [
                    {
                        data: this.new_data,
                        backgroundColor: ["#1F77B4","#2CA02C","#FF7F0E","#D62728","#9467BD","#8C564B","#E377C2",],
                    }
                ]
            }
        }
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

:deep(.p-tabview .p-tabview-panels) {
    background-color: white;
}

.color_opacity{
    background-color: v-bind(opacity_color)
}
</style>