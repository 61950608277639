import axios from 'axios'
import moment from 'moment'

const state = {
    all_tasks: [],
    tasks: [],
    weekly_tasks: [],
    weekly_calls: [],
    week_calendar: [],
    finished_tasks: [],
    task_categories: [],
    budgets: [],
    all_budgets: [],
    budget_table: [],
    worker_list: [],
    error_update_schedule_task_end_date: [],
    task_scheduler: [],
    error_task_scheduler: []
}

const mutations = {
    SET_ALL_TASKS(state, tasks) {
        state.all_tasks = tasks.map(updated_task => {
            if(updated_task.start_datetime != null) {
                updated_task.start_datetime = moment(updated_task.start_datetime).utc(false).toDate()
            }
            return updated_task
        })
    },
    SET_TASKS(state, tasks) {
        state.tasks = tasks
    },
    SET_FINISHED_TASKS(state, tasks) {
        state.finished_tasks = tasks
    },
    SET_TASK_CATEGORIES(state, task_categories) {
        state.task_categories = task_categories
    },
    SET_TASK_AS_SCHEDULING(state, task) {
        state.tasks = state.tasks.map(updated_task => {
            if(updated_task.id === task.id) {
                updated_task.status = task.status
                updated_task.id_user_assigned = task.id_user_assigned
            }
            return updated_task
        })
    },
    SET_TASK_AS_FINISHED(state, task) {
        state.tasks = state.tasks.map(updated_task => {
            if(updated_task.id === task.id) {
                updated_task.status = task.status
            }
            return updated_task
        })
    },
    SET_TASK_AS_SCHEDULED(state, task) {
        state.tasks = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === task.id) {
                updated_pending_task.status = 'Scheduled'
                updated_pending_task.start_datetime = task.start_datetime
                updated_pending_task.end_datetime = task.end_datetime
            }
            return updated_pending_task
        })

        state.week_calendar.push(task)
        state.weekly_tasks.push(task)
    },
    SET_WEEKLY_TASKS(state, task) {
        state.weekly_tasks = task
    },
    SET_WEEKLY_CALLS(state, call) {
        state.weekly_calls = call
    },
    SET_BUDGETS(state, budget) {
        state.budgets = budget
    },
    SET_ALL_BUDGETS(state, budget) {
        state.all_budgets = budget
    },
    SET_LOAD_ALL_BUDGETS(state, budget) {
        state.budget_table = budget
    },
    SET_WORKER_LIST(state, worker_list) {
        state.worker_list = worker_list
    },
    SET_TASK_SCHEDULER(state, task_scheduler) {
        state.task_scheduler = task_scheduler
    },
    CREATE_TASK(state, task) {
        state.tasks.push(task)
    },
    NEW_TASK_SCHEDULERS(state, task) {
        state.task_scheduler.push(task)
    },
    SET_TASK_SCHEDULERS_ERROR(state, error) {
        state.error_task_scheduler = error
    },
    UPDATE_TASK_SCHEDULER(state, task) {
        state.task_scheduler = state.task_scheduler.map(updated_task_scheduler => {
            if(updated_task_scheduler.id === task.id) {
                updated_task_scheduler.frequency = task.frequency
                updated_task_scheduler.hour = task.hour
                updated_task_scheduler.name = task.name
                updated_task_scheduler.description = task.description
                updated_task_scheduler.id_terminal = task.id_terminal
                updated_task_scheduler.expiration_day = task.expiration_day
                updated_task_scheduler.id_task_category = task.id_task_category
                updated_task_scheduler.days = task.days
                updated_task_scheduler.date = task.date
                updated_task_scheduler.type = task.type
            }
            return updated_task_scheduler
        })
    },
    DELETE_TASK_SCHEDULERS(state, id) {
        let index = state.task_scheduler.findIndex(task => task.id == id)
        state.task_scheduler.splice(index, 1)
    },
    SAVE_TASK(state, task) {
        state.tasks = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === task.id) {
                updated_pending_task.title = task.title
                updated_pending_task.id_task_category = task.id_task_category
                updated_pending_task.id_supplier = task.id_supplier
                updated_pending_task.id_terminal = task.id_terminal
                updated_pending_task.description = task.description
                updated_pending_task.picture = task.picture
                updated_pending_task.id_user_assigned = task.id_user_assigned
            }
            return updated_pending_task
        })
    },
    SEND_BUDGET_MAIL(state, item) {
        state.tasks = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === item.id) {
                updated_pending_task.budget_status = 'Pending'
                updated_pending_task.message_sent = item.message
                updated_pending_task.budget_mail_sent = true
            }
            return updated_pending_task
        })
    },
    CONFIRM_BUDGET(state, item) {
        state.tasks = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === item.id) {
                updated_pending_task.budget_status = item.status
                if(item.status == 'Confirmed') {
                    updated_pending_task.status = 'Scheduling'
                }
                updated_pending_task.budget_mail_sent = false
            }
            return updated_pending_task
        })
    },
    REJECT_BUDGET(state, id) {
        state.tasks = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === id) {
                updated_pending_task.budget_status = 'Rejected'
            }
            return updated_pending_task
        })
    },
    CHANGE_STATUS_BUDGET(state, item){
        state.budgets = state.budgets.map(updated_budget => {
            if(updated_budget.id === item.id) {
                updated_budget.status = item.status
            }
            return updated_budget
        })
    },
    SEND_DATE_PROPOSAL_MAIL(state, task){
        state.tasks = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === task.id) {
                updated_pending_task.proposed_start_datetime = task.start_datetime
                updated_pending_task.proposal_supplier = false
            }
            return updated_pending_task
        })
    },
    ACCEPT_SUPPLIER_DATE_PROPOSAL(state, task) {
        state.tasks = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === task.id) {
                updated_pending_task.status = 'Scheduled'
                updated_pending_task.start_datetime = task.start_datetime
                updated_pending_task.end_datetime = task.end_datetime
            }
            return updated_pending_task
        })
    },
    REQUEST_WORKER_LIST(state, id) {
        state.task = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === id) {
                updated_pending_task.worker_file_sent_mail = true
            }
        })
    },
    UPLOAD_TASK_IMAGE(state, item) {
        state.task = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === item.id) {
                updated_pending_task.picture = item.picture
            }
            return updated_pending_task
        })
    },
    UPLOAD_WORK_REPORT(state, task) {
        state.tasks = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === task.id) {
                updated_pending_task.work_report = task.work_report
            }
            return updated_pending_task
        })
    },
    SEND_WORD_REPORT_MAIL(state, id) {
        state.tasks = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === id) {
                updated_pending_task.work_report_mail_sent = true
            }
            return updated_pending_task
        }) 
    },
    UPDATE_END_DATA(state, item) {
        state.tasks = state.tasks.map(updated_pending_task => {
            if(updated_pending_task.id === item.id) {
                updated_pending_task.end_datetime = item.end_datetime
            }
            return updated_pending_task
        })
    },
    UPDATE_SCHEDULED_TASK_END_DATE(state, item){
        state.tasks = state.tasks.map(updated_task => {
            if(updated_task.id === item.id) {
                updated_task.end_datetime = item.end_datetime
            }
            return updated_task
        })
    },
    DELETE_TASK(state, id) {
        let index = state.tasks.findIndex(task => task.id == id)
        state.tasks.splice(index, 1)
    },
    /*
    MOVE_TASK(state, {fromTasks, toTasks, taskIndex}) {
        const taskToMove = fromTasks.splice(taskIndex, 1)[0]
        toTasks.push(taskToMove)
    }
    */
    DELETE_TASK_FROM_CALENDAR(state, id) {
        state.tasks = state.tasks.map(updated_task => {
            if(updated_task.id === id) {
                updated_task.status = 'Scheduling'
                updated_task.start_datetime = null
                updated_task.end_datetime = null
            }
            return updated_task
        })

        let index = state.week_calendar.findIndex(task => task.id == id)
        state.week_calendar.splice(index, 1)
        let sec_index = state.weekly_tasks.findIndex(task => task.id == id)
        state.weekly_tasks.splice(sec_index, 1)
    },
    ARCHIVE_TASK(state, id) {
        state.all_tasks = state.all_tasks.map(updated_task => {
            if(updated_task.id === id) {
                updated_task.is_archived = true
            }
            return updated_task
        })

        let index = state.tasks.findIndex(task => task.id == id)
        state.tasks.splice(index, 1)

    },
    UNARCHIVE_TASK(state, id) {
        state.all_tasks = state.all_tasks.map(updated_task => {
            if(updated_task.id === id) {
                updated_task.is_archived = false
            }
            return updated_task
        })
    },
    ERROR_UPDATE_SCHEDULE_TASK_END_DATE(state, error_update_schedule_task_end_date){
        state.error_update_schedule_task_end_date = error_update_schedule_task_end_date
    },
    CLEAN_ERROR_UPDATE_SCHEDULE_TASK_END_DATE(state) {
        state.error_update_schedule_task_end_date = []
    },
    EMPTY_ERRORS_TASK_SCHEDULER(state) {
        state.error_task_scheduler = []
    }
}

const actions = {
    // Loads every task for the budget historic 
    async load_tasks({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "tasks/")
            .then((response) => {
                commit('SET_ALL_TASKS', response.data)
            })
    },
    // Loads specifics task for the maintenance board
    async load_all_tasks({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "tasks/get_last_tasks/")
            .then((response) => {
                commit('SET_TASKS', response.data)
            })
    },
    async load_finished_tasks({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "tasks/get_by_status/Finished/")
            .then((response) => {
                commit('SET_FINISHED_TASKS', response.data)
            })
    },
    async load_task_categories({commit}){
        await axios.get(process.env.VUE_APP_API_URL + "task_categories/")
            .then((response) => {
                commit('SET_TASK_CATEGORIES', response.data)
            })
    },
    async load_weekly_tasks({commit}, date) {
        await axios.get(process.env.VUE_APP_API_URL + "tasks/get_week_task_day/" + date + "/",) 
            .then((response) => {
                commit('SET_WEEKLY_TASKS', response.data)
            })
    },
    async load_weekly_calls({commit}, date) {
        await axios.get(process.env.VUE_APP_API_URL + "calls/get_week_calls_day/" + date + "/",) 
            .then((response) => {
                commit('SET_WEEKLY_CALLS', response.data)
            })
    },
    async load_budgets_from_tasks({commit}, id) {
        await  axios.get(process.env.VUE_APP_API_URL + "budgets/get_by_task/" + id + "/")
            .then((response) => {
                commit('SET_ALL_BUDGETS', response.data)
            })

    },
    async load_budgets_from_task({commit}, id){
        await axios.get(process.env.VUE_APP_API_URL + "budgets/get_by_task/" + id + "/")
            .then((response) => {
                commit('SET_BUDGETS', response.data)
            })
    },
    async load_all_budgets({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "budgets")
            .then((response) => {
                commit('SET_LOAD_ALL_BUDGETS', response.data)
            })
    },
    async load_workers_list({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "workers_file/get_by_id_task/" + id + "/") 
            .then((response) => {
                commit('SET_WORKER_LIST', response.data)
            })
    },
    async load_task_schedulers({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "task_schedulers/") 
            .then((response) => {
                commit('SET_TASK_SCHEDULER', response.data)
            })
    },
    async create_task({commit}, task) {

        var formData = new FormData();

        for(var item in task){
            formData.append(item, task[item])
        }
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }

        await axios.post(process.env.VUE_APP_API_URL  + "tasks/", formData, {headers})
            .then((response) => {
                commit('CREATE_TASK', response.data)
            })     
        
    },
    async new_task_schedulers({commit}, task){
        if(typeof(task.date) != 'string') {
            task.date = moment(task.date).format('YYYY-MM-DD')
        }
        await axios.post(process.env.VUE_APP_API_URL + "task_schedulers/", task)
            .then((response) => {
                commit('EMPTY_ERRORS_TASK_SCHEDULER')
                commit('NEW_TASK_SCHEDULERS', response.data)
            }) 
            .catch((error) => {
                commit('SET_TASK_SCHEDULERS_ERROR', error.response.data)
            })
    },
    async delete_task_scheduler({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "task_schedulers/" + id + "/")
            .then(() => {
                commit('DELETE_TASK_SCHEDULERS', id)
            }) 
    },
    async update_task_scheduler({commit}, task) {

        if(task.type == 'Monthly') {
            var edit_date = moment(task.date).format('YYYY-MM-DD')
        }

        if(typeof(task.hour) != 'string') {
            var edit_hour = moment(task.hour).format('HH:mm')
        } else {
            edit_hour = task.hour
        }
        
        if(typeof(task.date) != 'string') {
            edit_date = moment(task.date).format('YYYY-MM-DD')
        } 
        
        if(typeof(task.expiration_day) != 'string') {
            var expiration_day = moment(task.expiration_day).format('YYYY-MM-DD')
        } else {
            expiration_day = task.expiration_day
        }

        await axios.patch(process.env.VUE_APP_API_URL + "task_schedulers/" + task.id + "/", {
            date: edit_date,
            days: task.days,
            description: task.description,
            expiration_day: expiration_day,
            frequency: task.frequency,
            hour: edit_hour,
            id_task_category: task.id_task_category.id,
            id_terminal: task.id_terminal.id,
            name: task.name,
            type: task.type,
        })
            .then((response) => {
                commit('EMPTY_ERRORS_TASK_SCHEDULER')
                commit('UPDATE_TASK_SCHEDULER', response.data)
            })
            .catch((error) => {
                commit('SET_TASK_SCHEDULERS_ERROR', error.response.data)
            })
    },
    async save_task({commit}, task){
        var new_task = task
        new_task.id_task_category = new_task.id_task_category.id
        new_task.id_terminal = new_task.id_terminal.id
        if(task.id_supplier.id == null) {
            delete new_task.id_supplier
        } else {
            new_task.id_supplier = new_task.id_supplier.id
        }

        var formData = new FormData();

        for(var item in new_task){
            formData.append(item, new_task[item])
        }
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
        
        await axios.patch(process.env.VUE_APP_API_URL  + "tasks/" + new_task.id + '/' , formData, {headers})
            .then((response) => {
                commit('SAVE_TASK', response.data)
            }) 
    },
    async send_budget_mail({commit}, item) {
        await axios.post(process.env.VUE_APP_API_URL  + "tasks/send_budget_mail/" + item.id + '/', {
            message: item.message,
            budget_mail_sent: true
        })
            .then(() => {
                commit('SEND_BUDGET_MAIL', item)
            })
    },
    async send_work_report_mail({commit}, id) {
        await axios.post(process.env.VUE_APP_API_URL + "tasks/send_work_report_mail/" + id + "/") 
            .then((response) => {
                commit('SEND_WORD_REPORT_MAIL', response.data)
            })
    },
    async confirm_budget({commit}, item) {
        await axios.post(process.env.VUE_APP_API_URL + "tasks/change_status_budget/" + item.id + '/', {
            status: item.status,
            budget_mail_sent: false
        })
            .then(() => {
                commit('CONFIRM_BUDGET', item)
            })    
    },
    async change_status_budget({commit}, item) {
        await axios.patch(process.env.VUE_APP_API_URL + "budgets/" + item.id + '/', {
            status: item.status
        })
            .then((response) => {
                commit('CHANGE_STATUS_BUDGET', response.data)
            })
    },
    async send_date_proposal_mail({commit}, item) {
        await axios.post(process.env.VUE_APP_API_URL + "tasks/send_date_proposal_mail/" + item.id + "/", {
            proposal_datetime: item.start_datetime
        })
            .then(() => {
                commit('SEND_DATE_PROPOSAL_MAIL', item)
            })
    },
    async accept_supplier_date_proposal({commit}, item) {
        await axios.post(process.env.VUE_APP_API_URL + "tasks/accept_supplier_date_proposal/" + item.id + "/" ,{
            status: 'Scheduled',
            start_datetime: item.start_datetime,
            end_datetime: item.end_datetime
        })
            .then(() => {
                commit('ACCEPT_SUPPLIER_DATE_PROPOSAL', item)
            })
    },
    async update_scheduled_task_end_date({commit}, item) {
        await axios.patch(process.env.VUE_APP_API_URL + "tasks/" + item.id + "/", {
            end_datetime: item.end_datetime
        }) 
            .then((response) => {
                commit('CLEAN_ERROR_UPDATE_SCHEDULE_TASK_END_DATE')
                commit('UPDATE_SCHEDULED_TASK_END_DATE', response.data)
            })
            .catch((error) => {
                commit('ERROR_UPDATE_SCHEDULE_TASK_END_DATE', error.response.data)
            })
    },
    async request_worker_list({commit}, id) {
        await axios.post(process.env.VUE_APP_API_URL + "tasks/send_worker_file_mail/" + id + "/")
            .then(() => {
                commit('REQUEST_WORKER_LIST', id)
            })
    },
    async upload_work_report_image({commit}, items) {

        var formData = new FormData();
        
        for(var item in items){
            formData.append(item, items[item]) 
        }
        
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }  
        
        await axios.patch(process.env.VUE_APP_API_URL + 'tasks/' + items.id + "/",  formData, headers)
            .then((response) => {
                commit('UPLOAD_WORK_REPORT', response.data)
            })
    },
    async upload_task_image({commit}, items) {
        var formData = new FormData();
        
        for(var item in items){
            formData.append(item, items[item]) 
        }
        
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        } 

        await axios.patch(process.env.VUE_APP_API_URL + 'tasks/' + items.id + "/",  formData, headers)
            .then((response) => {
                commit('UPLOAD_TASK_IMAGE', response.data)
            })
    },
    async assign_end_date({commit}, item) {
        let end_datetime = moment(item.end_date).format().split('T')[0] +'T'+moment(item.end_hour).format().split('T')[1].split(':')[0]+':'+moment(item.end_hour).format().split('T')[1].split(':')[1]
        await axios.patch(process.env.VUE_APP_API_URL + 'tasks/' + item.id + "/" , {
            end_datetime: end_datetime
        })
            .then((response) => {
                commit('UPDATE_END_DATA', response.data)                
            })
    },
    async update_pending_task({commit}, task) {
        await axios.patch(process.env.VUE_APP_API_URL  + "tasks/" + task.id + '/' , {
            status: 'Scheduling',
            id_user_assigned: task.id_user_assigned.id
        })
            .then((response) => {
                commit('SET_TASK_AS_SCHEDULING', response.data)
            })
    },
    async update_scheduled_task({commit}, task) {
        await axios.patch(process.env.VUE_APP_API_URL  + "tasks/" + task.id + '/' , {
            status: 'Finished'
        })
            .then((response) => {
                commit('SET_TASK_AS_FINISHED', response.data)
            })
    },
    async update_scheduling_task_from_calendar({commit}, task) {
        await axios.patch(process.env.VUE_APP_API_URL  + "tasks/" + task.id + '/' ,{
            status: 'Scheduled',
            start_datetime: task.start,
            end_datetime: task.end
        })
            .then((response) => {
                commit('SET_TASK_AS_SCHEDULED', response.data)
            })
    },
    async delete_task({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "tasks/" + id + '/')
            .then(() => {
                commit('DELETE_TASK', id)
            })
    },
    async delete_task_from_calendar({commit}, id) {
        await axios.patch(process.env.VUE_APP_API_URL + "tasks/" + id + '/', {
            status: "Scheduling",
            start_datetime: null,
            end_datetime: null
        })
            .then(() => {
                commit('DELETE_TASK_FROM_CALENDAR', id)
            })
    },
    async archive_task({commit}, id) {
        await axios.post(process.env.VUE_APP_API_URL + "tasks/archive/" + id + '/')
            .then(() => {
                commit('ARCHIVE_TASK', id)
            })
    },
    async unarchive_task({commit}, id) {
        await axios.post(process.env.VUE_APP_API_URL + "tasks/unarchive/" + id + '/')
            .then(() => {
                commit('UNARCHIVE_TASK', id)
            })
    },
    async empty_errors_task_scheduler({commit}) {
        commit('EMPTY_ERRORS_TASK_SCHEDULER')
    }
}

const getters = {
    get_all_tasks: (state) => state.all_tasks,
    get_pending_tasks: (state) => {
        return state.tasks.filter(task => task.status === 'Pending')
    },
    get_scheduling_tasks: (state) => {
        return state.tasks.filter(task => task.status === 'Scheduling')
    },
    get_internal_scheduling_tasks: (state) => {
        return state.tasks.filter(task => task.status === 'Scheduling' && task.internal == true)
    },
    get_scheduled_tasks: (state) => {
        return state.tasks.filter(task => task.status === 'Scheduled')
    },
    get_finished_tasks: (state) => {
        return state.tasks.filter(task => task.status === 'Finished')
    },
    get_all_finished_tasks: (state) => state.finished_tasks,
    get_task_categories: (state) => state.task_categories,
    get_end_datetime: (state) => (id) => {
        return state.tasks.filter(task => task.id === id)
    },
    get_scheduling_tasks_by_terminal: (state) => (name) => {
        if(name == 'All') {
            return state.tasks.filter(task => task.status === 'Scheduling' && task.internal == true)
        } else {
            return state.tasks.filter(task => task.id_terminal.name === name && task.status === 'Scheduling' && task.internal == true)
        }
    },
    get_scheduled_tasks_by_terminal: (state) => (name) => {
        if(name == 'All') {
            return state.tasks.filter(task => task.status === 'Scheduled' || task.status === 'Finished').concat(state.weekly_calls)
        } else {
            state.week_calendar = state.weekly_tasks.filter(task => task.id_terminal.name === name && (task.status === 'Scheduled' || task.status === 'Finished')).concat(state.weekly_calls.filter(call => call.terminal == name))
            return state.week_calendar
        }
    },
    get_budgets_tasks: (state) => state.budgets,
    get_all_budgets: (state) => state.all_budgets,
    get_budget_table: (state) => state.budget_table,
    get_budget_from_task: (state) => (id) => {
        return state.budget_table.filter(budget => budget.id_task.id === id)
    },
    get_worker_list: (state) => state.worker_list,
    get_error_update_schedule_task_end_date: (state) => state.error_update_schedule_task_end_date,
    get_work_report_from_task: (state) => (id) => {
        var filtered_task = state.tasks.find(task => task.id === id)
        return filtered_task.work_report
    },
    get_picture_from_task: (state) => (id) => {
        var filtered_task = state.tasks.find(task => task.id === id)
        return filtered_task.picture
    },
    get_task_scheduler: (state) => state.task_scheduler,
    get_filtered_task_scheduler: (state) => (status) => {
        if(status == 'Active') {
            return state.task_scheduler.filter(task => moment().isBetween(task.date, task.expiration_day) || (moment().isAfter(task.date) && !task.expiration_day ))
        } else if(status == 'Finished') {
            return state.task_scheduler.filter(task => moment().isAfter(task.expiration_day))
        } else if(status == 'Stand by') {
            return state.task_scheduler.filter(task => moment().isBefore(task.date))
        }
    },
    get_error_task_scheduler: (state) => state.error_task_scheduler
}

export default {
    state,
    mutations,
    actions,
    getters
};