<template>
    <Toast/>
    <div class="container">
        <div class="content-section introduction mt-4">
            <div class="feature-intro">
                <h1>Group</h1>
                <p> Complete the steps to create a new group.</p>
            </div>
        </div>

        <div class="mt-5">
            <Steps :model="items" :readonly="true" aria-label="Form Steps" />
        </div>
        
        <router-view v-slot="{Component}" :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete" @complete_another="complete_another">
            <keep-alive>
                <component :is="Component"/>
            </keep-alive>
        </router-view> 

    </div>
</template>

<script>
import Toast from 'primevue/toast'
import Steps from 'primevue/steps';

export default {
    name: 'Supplier_selection',
    components: {
        Steps,
        Toast
    },
    data() {
        return {
            items: [
                {
                    label: 'Information',
                    to: '/permissions/group/steps'
                },
                {
                    label: 'Users',
                    to: '/permissions/group/users'
                },
                {
                    label: 'Roles',
                    to: '/permissions/group/roles'
                },
                {
                    label: 'Confirmation',
                    to: '/permissions/group/confirmation'
                },
            ],
            formObject: {}
        }
    },
    computed: {
        get_errors_group_permission: function() {
            return this.$store.getters.get_errors_group_permission
        }
    },
    methods: {
        nextPage(event) {
            for (let field in event.formData) {
                this.formObject[field] = event.formData[field];
            }
            
            this.$router.push(this.items[event.pageIndex + 1].to);
        },
        prevPage(event) {
            this.$router.push(this.items[event.pageIndex - 1].to);
        },
        async complete(event) {

            for (let field in event.formData) {
                this.formObject[field] = event.formData[field];
            }
            
            await this.$store.dispatch('create_user_group', this.formObject)

            this.$router.push({name: 'Permissions'})
        },
    }
}
</script>

<style scoped>

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    background-color: #f8f9fa;
    padding: 1rem;
}

</style>