<template>
    <Toast/>
    <div v-if="loading==true">
        <Skeleton height="116px"/>
    </div>
    <div v-else>
		<Menubar :model="main_menu" :activeItem="activeItem" ref="menubar">
			<template #start>
                <router-link class="navbar-brand text-white borderMenuTop" :to="{ name: 'Home' }"><img alt="logo" :src="logo" height="100" ></router-link>
            </template>
            <template #item="{item}">
                <span v-if="item.label == 'Maintenance'">
                    <span :class="{active_item: $route.path.includes('maintenance')}" class="p-menuitem-link router-link-active router-link-active-exact" role="menuitem" aria-haspopup="true" aria-controls="overlay_amenu"><span class="p-menuitem-text" @click="setActiveItem(item.label)" >{{item.label}}</span></span>
                </span>
                <span v-else-if="item.label == 'Records'">
                    <span :class="{active_item: $route.path.includes('record_book') || $route.path.includes('operation_diary')}" class="p-menuitem-link router-link-active router-link-active-exact" role="menuitem" aria-haspopup="true" aria-controls="overlay_bmenu"><span class="p-menuitem-text" @click="setActiveItem(item.label)">{{item.label}}</span></span>
                </span>
                <span v-else>
                    <router-link :to="item.to">
                        <a :href="item.to" :class="{active_item: $route.path.split('/')[1].includes(item.to.split('/')[1])}" class="p-menuitem-link router-link-active router-link-active-exact" role="menuitem" @click="setActiveItem(item.label)"><span class="p-menuitem-text">{{item.label}}</span></a>
                    </router-link>
                </span>
            </template>
            <template #end>
                <ul class="menubar_end me-3" role="menubar">
                    <li role="none" class="p-menuitem">
                        <span>
                            <Avatar icon="fa fa-bell" size="large" shape="circle" @click="toggle_notifications" aria-haspopup="true" aria-controls="overlay_tmenu" style="color: #ffffff; background-color:rgba(0, 0, 0, 0);" v-badge="get_last_notifications.total_notifications"/>
                        </span>
                    </li>
                    <li class="p-menuitem ms-3 me-3 mt-2">
                        <span v-if="get_current_user.profile" > 
                            <div class="circle-image" >
                                <span >
                                    <img :src="get_current_user.profile.picture" class="profile-pic" @click="toggle"/>
                                </span>
                            </div>
                        </span>
                    </li>
                </ul>
            </template>
        </Menubar>
    </div>

    <TieredMenu id="overlay_tmenu" :model="profile" appendTo="body" :popup="true" ref="menu"/>

    <TieredMenu id="overlay_amenu" style="border-radius: 0 0 10px 10px;" :style="{'background-color': color}" ref="maintenance_menu" :model="maintenance" :popup="true" aria-haspopup="true" aria-controls="overlay_amenu" />
    <!--
    <TieredMenu id="overlay_bmenu" style="border-radius: 0 0 10px 10px;" :style="{'background-color': color}" ref="record_menu" :model="records" :popup="true" aria-haspopup="true" aria-controls="overlay_bmenu"/>
    -->
    <OverlayPanel id="overlay_tmenu" appendTo="body" ref="nots" style="max-width:30rem; min-width:20rem;" :dismissable="true">
        <div class="container" >
            <div class="row" style="border-bottom: 1px solid #d6d6d6;">
                <div class="col text-center">
                    <h6> <strong> Notifications </strong> </h6>
                </div>
            </div>
        </div>
        <div v-if="get_last_notifications.notifications.length != 0">
            <Timeline :value="get_last_notifications.notifications">
                <template #content="slotProps">
                    <div v-if="slotProps.item.is_viewed == false" style="background-color: rgba(23, 197, 139, .1); border-start: 5px solid #17C58B; border-bottom: 1px solid #d6d6d6; padding-start: 1rem; padding-end: 1.25rem; color; rgba(23, 197, 139, .5)">
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                    <small style="font-weight: bold;">{{slotProps.item.title}}</small> - <small>{{slotProps.item.text}}</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color: #a3a3a3;">{{format_timeframe(slotProps.item.datetime)}}</small>
                                </div>
                                <div class="col text-end">
                                    <i v-tooltip.right="'Mark as read'" class="fa fa-envelope fa-lg" @click="mark(slotProps.item.id)" style="color: #002D72; cursor: pointer;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else style="border-start: 5px solid #d6d6d6; border-bottom: 1px solid #d6d6d6; padding-start: 1rem; padding-end: 1.25rem;">
                        <div class="container" >
                            <div class="row">
                                <div class="col">
                                    <small>{{slotProps.item.title}}</small> - <small>{{slotProps.item.text}}</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color: #a3a3a3;">{{format_timeframe(slotProps.item.datetime)}}</small>
                                </div>
                                <div class="col text-end">
                                    <i v-tooltip.right="'Mark as unread'" class="fa fa-envelope-open fa-lg" @click="unmark(slotProps.item.id)" style="color: #002D72; cursor: pointer;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Timeline>
        </div>
        <div v-else>
            <div class="row" style="padding:1.25rem;">
                <div class="col text-center">
                    <span> <i class="fa fa-bell-slash fa-5x"></i> </span>
                </div>
            </div>
        </div>
        <div class="container" style="background-color: #f8f9fa">
            <div class="row" >
                <div class="col text-center">
                    <h6 @click="view_all_notifications()" style="cursor: pointer; margin-top: 6px;"> View All </h6>
                </div>
            </div>
        </div>
    </OverlayPanel>

    <Sidebar v-model:visible="visibleLeft" position="right" class="p-sidebar-lg" style="background-color: #f8f9fa;" >
        <DataTable :value="current_page_notifications" :paginator="true" :rows="15" :loading="load_notifications">
            <template #header>
                <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                    <div class="col my-auto">
                        <h5 class="mb-2 md:m-0 p-as-md-center"> Notifications </h5>
                    </div>
                    <div class="col text-end">
                        <Button label="Mark as read" icon="fa-solid fa-check" class="p-button-text color_port" @click="set_all_as_read"/>
                    </div>
                </div>
            </template>
            <Column style="min-width: 8rem;background-color: white;" header="Title">
                <template #body="slotProps">
                    <div v-if="slotProps.data.is_viewed == false" style="padding: .9rem;">
                        <span> <strong> {{slotProps.data.title}} </strong> </span>
                    </div>
                    <div v-else style="padding: .9rem;">
                        <span> {{slotProps.data.title}} </span>
                    </div>
                </template>
            </Column>
            <Column style="background-color: white;"  header="Description">
                <template #body="slotProps">
                    <div v-if="slotProps.data.is_viewed == false" style="padding: .9rem; ">
                        <span>  <strong>{{slotProps.data.text}} </strong>  </span>
                    </div>
                    <div v-else style="padding: .9rem;">
                        <span> {{slotProps.data.text}} </span>
                    </div>
                </template>
            </Column>
            <Column style="min-width: 8rem; background-color: white;" header="Date">
                <template #body="slotProps">
                    <div v-if="slotProps.data.is_viewed == false" style="padding: .9rem;">
                        <span> <strong> {{format_timeframe(slotProps.data.datetime)}} </strong> </span>
                    </div>
                    <div v-else style="padding: .9rem;">
                        <span> {{format_timeframe(slotProps.data.datetime)}} </span>
                    </div>
                </template>
            </Column>
            <Column style="background-color: white;" header="Status">
                <template #body="slotProps">
                    <div v-if="slotProps.data.is_viewed" class="text-center"> <Button icon="fa fa-envelope-open" class="p-button-text p-button-rounded" @click="unmark(slotProps.data.id)" style="color: #002D72; cursor: pointer;"/> </div>
                    <div v-else style="background-color: white;" class="text-center"> <Button icon="fa fa-envelope" class="p-button-text p-button-rounded" @click="mark(slotProps.data.id)" style="color: #002D72; cursor: pointer;"/> </div>
                </template>
            </Column>
        </DataTable>
    </Sidebar>

</template>

<script>
import Toast from 'primevue/toast'
import Menubar from 'primevue/menubar'
import TieredMenu from 'primevue/tieredmenu'
import OverlayPanel from 'primevue/overlaypanel'
import Avatar from 'primevue/avatar'
import Timeline from 'primevue/timeline'
import Sidebar from 'primevue/sidebar'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Skeleton from 'primevue/skeleton'

import ability from '../../services/ability'
import moment from 'moment'

export default {
    name: "TopMenuHeader",
    components: {
        Toast,
        Menubar,
        //Dropdown,
        TieredMenu,
        Avatar,
        OverlayPanel,
        Timeline,
        Sidebar,
        //Paginator,
        DataTable,
        Column,
        //Breadcrumb
        Button,
        Skeleton
    },
    data() {
        return {
            loading: true,
            load_notifications: true,
            last_notifications: [],
            current_page_notifications: [],
            visibleLeft: false,
            // home: {icon: 'fa fa-home', to: '/'},
            main_menu: [
                {
                    label: 'Management',
                    to: '/admin/',
                    visible: () =>  {
                        for(var item in this.get_current_user.menu_bar_items) {
                            if(['Management'].includes(this.get_current_user.menu_bar_items[item])) {
                                return true
                            }
                        }
                    },
                },
                {
                    label: 'Calls',
                    to: '/dashboard/',
                    visible: () =>  {
                        for(var item in this.get_current_user.menu_bar_items) {
                            if(['Calls'].includes(this.get_current_user.menu_bar_items[item]) ) {
                                return true
                            }
                        }
                    },
                },
                {
                    label: 'Records',
                    visible: () =>  {
                        for(var item in this.get_current_user.menu_bar_items) {
                            if(['Records'].includes(this.get_current_user.menu_bar_items[item]) ) {
                                return true
                            }
                        }
                    },
                    items: [
                        {
                            label: 'Record book',
                            to: '/record_book',
                            style: 'margin-left: 1.25rem; ',
                            visible: () =>  {
                                if(ability.can('view', 'daily_log')){
                                    return true
                                }else{
                                    return false
                                }
                                
                            },
                            command: () => {
                                this.setActiveItem('Records')
                            }
                        },
                        {
                            label: 'Operation book',
                            to: '/operation_diary',
                            style: 'margin-left: 1.25rem;',
                            visible: () =>  {
                                if(this.check_if_user_operation == true){
                                    return true
                                } else {
                                    return false
                                }
                            },
                            command: () => {
                                this.setActiveItem('Records')
                            }
                        }
                    ]
                },
                {
                    label: 'Incidences',
                    to: '/incidences/',
                    visible: () =>  {
                        for(var item in this.get_current_user.menu_bar_items) {
                            if(['Incidences'].includes(this.get_current_user.menu_bar_items[item]) ) {
                                return true
                            }
                        }
                        return false
                    },
                },
                {
                    label: 'Maintenance',
                    to: '/maintenance/tasks_board/',
                    visible: () =>  {
                        for(var item in this.get_current_user.menu_bar_items) {
                            if(['Maintenance'].includes(this.get_current_user.menu_bar_items[item]) ) {
                                return true
                            }
                        }
                        return false
                    },
                    items: [
                        {
                            label: 'Board',
                            to: '/maintenance/tasks_board',
                            command: () => {
                                this.setActiveItem('Maintenance')
                            }
                        },
                        {
                            label: 'Calendar',
                            to: '/maintenance/tasks_calendar',
                            command: () => {
                                this.setActiveItem('Maintenance')
                            },
                            visible: () =>  {
                                if(ability.can('view','call')) {
                                    return true
                                }else{
                                    return false
                                }
                                
                            }
                        },
                        {
                            label: 'Scheduler',
                            to: '/maintenance/tasks_periodicity',
                            visible: () =>  {
                                if(ability.can('view','task_scheduler')) {
                                    return true
                                }else{
                                    return false
                                }
                                
                            }
                        },
                        {
                            label: 'Overview',
                            to: '/maintenance/tasks_budgets',
                        }
                    ]
                },
                {
                    label: 'Billing',
                    to: '/billing/dashboard/',
                    visible: () =>  {
                        for(var item in this.get_current_user.menu_bar_items) {
                            if(['Billing'].includes(this.get_current_user.menu_bar_items[item]) ) {
                                return true
                            }
                        }
                        return false
                    },
                },
                {
                    label: 'Statistics',
                    to: '/statistics/overview/',
                    visible: () =>  {
                        for(var item in this.get_current_user.menu_bar_items) {
                            if(['Statistics'].includes(this.get_current_user.menu_bar_items[item]) ) {
                                return true
                            }
                        }
                        return false
                    },
                },
            ],
            profile: [
                {
                    label: 'Profile',
                    to: '/profile/'
                },
                {
                    label: 'Permissions',
                    to: '/permissions/',
                    visible: () =>  {
                        if(this.check_if_user_is_admin){
                            return true
                        }else{
                            return false
                        }
                    }
                },
                {
                    label: 'Change Log',
                    to: '/change_log/',
                },
                {
                    separator:true
                },
                {
                    label: 'Log Out',
                    icon: 'pi pi-power-off',
                    command:() =>  {
                        this.remove_auth()
                        this.$router.push("/")
                    }
                }
            ],
            maintenance: [
                {
                    label: 'Board',
                    to: '/maintenance/tasks_board',
                },
                {
                    label: 'Calendar',
                    to: '/maintenance/tasks_calendar',
                    visible: () =>  {
                        if(ability.can('view','call')) {
                            return true
                        }else{
                            return false
                        }
                    }
                },
                {
                    label: 'Scheduler',
                    to: '/maintenance/tasks_periodicity',
                    visible: () =>  {
                        if(ability.can('view','task_scheduler')) {
                            return true
                        }else{
                            return false
                        }
                    }
                },
                {
                    label: 'Overview',
                    to: '/maintenance/tasks_budgets',
                }
            ],
            records: [
                {
                    label: 'Book',
                    to: '/record_book',
                    visible: () =>  {
                        if(ability.can('view', 'daily_log')){
                            return true
                        }else{
                            return false
                        }
                    },
                    command: () => {
                        this.setActiveItem('Records')
                    }
                },
                {
                    label: 'Operation',
                    to: '/operation_diary',
                    visible: () =>  {
                        if(this.check_if_user_operation == true){
                            return true
                        } else {
                            return false
                        }
                    },
                    command: () => {
                        this.setActiveItem('Records')
                    }
                }
            ],
            username: '',
            avatar: '',
            port: localStorage.getItem('user_port'),
            color: localStorage.getItem('primary_color'),
            selected_port: null,
            change_user_port: {'id_user': '', 'id_port': ''},
            logo: '',
            activeItem: ''
        }
    },
    async mounted() {
        document.addEventListener("click", this.handleClickOutside)
        await this.$store.dispatch('get_current_user')
        await this.$store.dispatch('load_ports')
        await this.$store.dispatch('load_user_port')

        this.logo = this.get_current_user.profile.id_port.logo

        if(ability.can('view', 'notification')) {
            await this.$store.dispatch('load_last_notifications')
        }
        
        this.username =  this.get_current_user.username
        this.avatar = this.get_current_user.profile.picture
        this.last_notifications = this.get_notifications.slice(0,5)
        
        
        this.loading = false
    },

    unmounted() {
        document.removeEventListener("click", this.handleClickOutside)
    },

    computed: {
        get_ports: function() {
            return this.$store.getters.get_ports
        },
        load_user_port: function() {
            return this.$store.getters.load_user_port
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        get_perms: function() {
            return this.$store.getters.get_perms
        },
        get_notifications: function() {
            return this.$store.getters.notifications
        },
        get_last_notifications: function() {
            return this.$store.getters.last_notifications
        },
        get_unread_notifications: function() {
            return this.$store.getters.unread_notifications
        },
        check_if_user_is_admin: function() {
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Admins' || this.get_current_user.groups[index].name == 'Super Admins') {
                    return true
                }
            }
            return false
        },
        check_if_user_operation: function() {
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Operation diary') {
                    return true
                }
            }
            return false
        }

    },
    methods: {

        handleClickOutside(event) {
            // Verifica si el clic ocurrió fuera del menú
            if (window.innerWidth <= 960){
                const menubarElement = this.$refs.menubar.$el;
                if (menubarElement && !menubarElement.contains(event.target) && this.$refs.menubar.mobileActive) {
                    this.$refs.menubar.toggle(event)
                }
            }
            
        },

        format_timeframe(value) {
            return moment(value).fromNow()
        },
        setActiveItem(item) {
            this.activeItem = item;
        },
        async mark(id){
            await this.$store.dispatch('mark_as_viewed', id)
            await this.$store.dispatch('load_last_notifications')
        },
        async unmark(id){
            await this.$store.dispatch('mark_as_not_viewed', id)
            await this.$store.dispatch('load_last_notifications')
        },
        async set_all_as_read() {
            await this.$store.dispatch('mark_all_as_read', this.get_current_user.id)
            await this.$store.dispatch('load_last_notifications')
        },
        selectView(name) {
            this.$emit("selectView", name);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        async view_all_notifications() {
            this.visibleLeft = true; 

            this.load_notifications = true
            await this.$store.dispatch('load_notifications', this.get_current_user.id)
            this.current_page_notifications = this.get_notifications
            this.load_notifications = false
        },
        toggle_notifications(event) {
            this.$refs.nots.toggle(event);
        },
        toggle_maintenance(event) {
            this.$refs.maintenance_menu.toggle(event)
        },
        toggle_record(event) {
            this.$refs.record_menu.toggle(event)
        },
        async remove_auth() {
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            localStorage.removeItem('refresh')
            localStorage.removeItem('refresh_expire_date')
            localStorage.removeItem('user_port')
            this.$store.dispatch('empty_current_calls')
            this.$ability.update([])
        },
        async change_port(slotProps) {
            this.change_user_port.id_port = slotProps.id
            this.change_user_port.id_user = this.get_current_user.id
            
            await this.$store.dispatch('change_user_port', this.change_user_port)
            
            await this.$router.push('/home')
            window.location.reload()
        
        }
    },
};
</script>

<style scoped>
a {
    text-decoration: none;
}

.p-button {
    background: v-bind(color) !important;
    border: 0px !important;
}

.p-button:focus {
    box-shadow: 0 0 0 0 !important;
}

.p-menubar{
    border-radius: 0% !important;
    color: rgb(255, 255, 255)!important;
    background: v-bind(color) !important;
    border: 0px !important;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text{
    color: rgb(255, 255, 255)!important;
    margin-left: 1.25rem;
}

:deep(.p-menubar .p-menubar-root-list > .p-menuitem ){
    margin-left: 0px;
}

:deep(.p-menubar .p-menubar-root-list ul li a) {
    padding-left: 0rem;
    border-bottom: 0px !important;
}

:deep(.p-menubar-root-list > .p-menuitem-active > .p-submenu-list){
    background: v-bind(color) !important;
    box-shadow: 0 3px 2px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 2px 4px 0 rgba(0,0,0,.12);
    border-radius: 0px;
    z-index: 1000;
}

:deep(.p-menubar .p-menuitem-link:not(.p-disabled):hover) {
    border-radius: 0px;
}

:deep(.p-menubar-root-list > .p-menuitem-active > .p-submenu-list > .p-menuitem) {
    margin-left: 0px !important;
}

:deep(.p-menubar-root-list > .p-menuitem-active > .p-submenu-list > .p-menuitem  .p-menuitem-text) {
    margin-left: 1.25rem !important;
}

.p-menuitem:hover{
    background: v-bind(color) !important;
}

.p-menubar .p-menuitem-link:not(.p-disabled)   {
    background: v-bind(color) !important;
}

.country-item {
    width: 35px;
    margin-right: 0.5rem;
}

:deep(.p-dropdown){
    border: 0;
    background: none;
}

.p-dropdown-items-wrapper{
    background-color: black;
}

.p-dropdown:not(.p-disabled).p-focus{
    box-shadow: 0;
}

.p-dropdown:deep(.p-dropdown-label) {
    background-color: v-bind(color);
}

.p-dropdown:deep(.p-dropdown-trigger) {
    background-color: v-bind(color);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.p-dropdown:deep(.p-dropdown-trigger-icon){
    color: white;
}

:deep(.p-inputtext) {
    border-radius: 0px;
}

@media (max-width: 700px) {
    .text-dropdown{
        display: none;
    } 
}

@media (max-width: 1024px) {
    :deep(.p-dropdown-label){
        display: none;
    }
    :deep(.p-menubar .p-menuitem-link){
        padding: 10px;
    }
}

.p-menuitem-link{
    background: blue;
}

.p-avatar{
    cursor: pointer;
    /* border: 2px solid #0027D2; */
}

:deep(.p-timeline-event-opposite){
    display: none;
}

:deep(.p-timeline-event-separator){
    display: none;
}

.new-chip {
    background-color: #17C58B;
    color: white;
}
/*
:deep(.p-timeline-event-content:hover){
  background-color: rgba(0, 45, 114, 0.1);
}
*/
:deep(.p-timeline-event-content){
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}

:deep(td) {
    padding: 0rem !important;
}

:deep(.p-avatar-image) {
    object-fit: cover !important;
}

.circle-image{
    cursor:pointer;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: 48px;
    height: 48px;
    padding: 0;
}

.circle-image img{
    width:100%;
    height:100%;
    object-fit: cover;
}

.image {
    display: block;
    width: 100%;
    height: auto;
}

.p-skeleton{
    background-color: v-bind(color);
}

@media (min-width: 961px) {
    .active_item{
        border-bottom: 1px solid white;
    }
}

.color_port{
    color: v-bind(color)
}

:deep(.p-tieredmenu ul:focus-visible) {
    outline: none!important; /* Remove the focus outline */
    /* Add any other styles you want to apply */
}

</style>