import axios from 'axios'
import moment from 'moment'

const state = {
    contract: {},
    contracts: [],
    supplier_contract: [],
    active_supplier_contract: [],
    contract_images: [],
    contract_errors:{},
    delete_status:false
};

const mutations = {
    SET_CONTRACTS(state, contracts) {
        state.contracts = contracts
    },
    SET_CONTRACT(state, contract) {
        state.contract = contract
    },
    SET_SUPPLIER_CONTRACTS(state, supplier_contract) {
        state.supplier_contract = supplier_contract
    },
    SET_ACTIVE_SUPPLIER_CONTRACTS(state, supplier_contract) {
        state.active_supplier_contract = supplier_contract
    },
    NEW_CONTRACT(state, contracts) {
        state.contracts.push(contracts)
        state.contract_errors = {}
    },
    DELETE_CONTRACT(state, id) {
        let index = state.contracts.findIndex(contracts => contracts.id == id)
        state.contracts.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_CONTRACT(state, contract) {
        state.contracts = state.contracts.map(updated_contract => {
            if(updated_contract.id === contract.id) {
                updated_contract.name = contract.name
                updated_contract.start_date = contract.start_date
                updated_contract.end_date = contract.end_date
                updated_contract.rescission_date = contract.rescission_date
                updated_contract.id_supplier = contract.id_supplier
                updated_contract.contract_details = contract.contract_details
            }
            return updated_contract
        })
        state.contract_errors = {}
    },
    ERRORS_CONTRACT(state, errors){
        state.contract_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};

const actions = {
    async load_all_contracts({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "contracts/" )
            .then((response) => {
                commit('SET_CONTRACTS', response.data)
            })
    },
    async load_contract({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "contracts/" + id)
            .then((response) => {
                commit('SET_CONTRACT', response.data)
            })
    },
    async load_supplier_contracts({commit}, id){
        await axios.get(process.env.VUE_APP_API_URL + "contracts/get_by_supplier/" + id)
            .then((response) => {
                commit('SET_SUPPLIER_CONTRACTS', response.data)
            })
    },
    async load_active_supplier_contracts({commit}, id){
        await axios.get(process.env.VUE_APP_API_URL + "contracts/get_by_supplier_active/" + id)
            .then((response) => {
                commit('SET_ACTIVE_SUPPLIER_CONTRACTS', response.data)
            })
    },
    async delete_contract({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'contracts/' + id)
            .then(() => {
                
                commit('DELETE_CONTRACT', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
    }, 
    async create_contract({commit}, contract) {
        if(contract.rescission_date != null){
            contract.rescission_date = moment(contract.rescission_date).format().split('T')[0]
        }else{
            delete contract['rescission_date']
        }
        contract.start_date = moment(contract.start_date).format().split('T')[0]
        contract.end_date = moment(contract.end_date).format().split('T')[0]
        
        if(typeof contract.contract.name != 'string'){
            delete contract['contract']
        }
        if (contract.id_supplier !== null){
            contract['id_supplier'] = contract.id_supplier.id
        }
        var formData = new FormData();
        for(var item in contract){
            formData.append(item, contract[item])
        }
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
        await axios.post(process.env.VUE_APP_API_URL + 'contracts/', formData, {headers})
            .then((response) => {  
                
                commit('NEW_CONTRACT', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_CONTRACT', error.response.data)
            })
    },
    async update_contract({commit}, contract) {
        if(contract.rescission_date != null){
            contract.rescission_date = moment(contract.rescission_date).format().split('T')[0]
        }else{
            delete contract['rescission_date']
        }
        contract.start_date = moment(contract.start_date).format().split('T')[0]
        contract.end_date = moment(contract.end_date).format().split('T')[0]
        if('contract' in contract){
            if(typeof contract.contract.name != 'string'){
                delete contract['contract']
            }
        }
        var formData = new FormData();
        for(var item in contract){
            if(item == 'id_supplier'){
                formData.append(item, contract[item].id)
            }else{
                formData.append(item, contract[item])
            }
        }
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
       await axios.patch(process.env.VUE_APP_API_URL + 'contracts/' + contract.id + '/',  formData, {headers})
            .then((response) => {
                commit('UPDATE_CONTRACT', response.data)
            })
            .catch((error) => {
                commit('ERRORS_CONTRACT', error.response.data)
            })
    },
};

const getters = {
    all_contracts: (state) => state.contracts,
    get_errors_contract: (state) => state.contract_errors,
    get_delete_contract_status:(state) => state.delete_status,
    get_contract_by_name:(state) => (name)=>{
        return state.contracts.filter(contract => contract.name === name).slice(-1)
    },
    get_contract: (state) => state.contract,
    get_contract_images: (state) => state.contract_images,
    get_supplier_contracts: (state) => state.supplier_contract,
    get_active_supplier_contracts: (state) => state.active_supplier_contract
};

export default {
    state,
    mutations,
    actions,
    getters
};