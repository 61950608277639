<template>
    <div class="card">
        <div class="row">
            <Toast/>
            <DataTable :value="all_fingers" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" scrollable 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading" removableSort
            :globalFilterFields="['name']"
            v-model:filters="filters">
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText id="filter_fingers" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                        </span>
                    </div>
                </template>
                <Column header="Name" field="name" :sortable="true"/>
                <Column v-if="$can('change','finger')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_finger(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','finger')" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_finger(slotProps)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_finger_modal" v-model:visible="finger_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Finger Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="name" class="ps-1 pb-1"> <strong> Name  </strong> </label>
                        <InputText id="name" v-model.trim="finger.name" required="true" autofocus :class="{'p-invalid': submitted && !finger.name ||finger_errors.name}" />
                        <small class="p-error" v-if="submitted && !finger.name">Name is required.</small>
                        <small v-if="Object.keys(finger_errors).length !== 0 && finger_errors.name" class="p-error"><span v-for="error in finger_errors.name" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_finger_dialog"/>
            <Button data-cy="save_edit_finger" label="Save" @click="save_finger" />
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_finger_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="finger">Are you sure you want to delete <b>{{finger.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_finger_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deleteFinger" />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import { FilterMatchMode } from 'primevue/api';
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: "TableFingers",    
    components: {
        DataTable,
        Column,
        InputText,
        Button,
        Toast,
        Dialog
    },
    data() {
        return {
            finger: {},
            finger_errors:{},
            finger_dialog: false,
            delete_finger_dialog: false,
            submitted: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_fingers.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_fingers')
            this.loading = false
        }
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_fingers: function() {
            return this.$store.getters.all_fingers
        },
        get_finger_errors: function(){
            return this.$store.getters.get_finger_errors
        },
        get_delete_finger_status: function() {
            return this.$store.getters.get_delete_finger_status
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        confirm_delete_finger(finger) {
            this.finger = finger.data;
            this.delete_finger_dialog = true;
        },
        async deleteFinger() {
            await this.$store.dispatch('delete_finger', this.finger.id)
            
            if (!this.get_delete_finger_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Finger deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Finger can't be deleted", life: 3000});
            }
            this.delete_finger_dialog = false;
            
        },
        edit_finger(finger) {
            this.finger = {...finger};
            this.finger_dialog = true;
        },
        async save_finger() {
            this.submitted = true;
			if (this.finger.name.trim()) {
                await this.$store.dispatch('update_finger', this.finger)
                this.finger_errors = this.get_finger_errors
                
                if(Object.keys(this.finger_errors).length === 0){
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Finger modified', life: 3000});
                    this.hide_finger_dialog()
                    this.finger = {};
                }

            }
        },
        hide_finger_dialog() {
            this.finger_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            name: { required }
        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>