import axios from 'axios'

const state = {
    ports: [],
    port_errors:{},
    delete_status : false
};

const mutations = {
    SET_PORTS(state, ports) {
        state.ports = ports
    },
    NEW_PORT(state, port) {  
        state.ports.push(port)
        state.port_errors = {}
    },
    DELETE_PORT(state, id) {
        let index = state.ports.findIndex(ports => ports.id == id)
        state.ports.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_PORT(state, port) {
        state.ports = state.ports.map(updated_port => {
            if(updated_port.id === port.id) {
                updated_port.name = port.name
                updated_port.logo = port.logo
                updated_port.bic_entity = port.bic_entity
                updated_port.iban = port.iban
                updated_port.sender_email = port.sender_email
                updated_port.primary_color = port.primary_color
                updated_port.secondary_color = port.secondary_color
                updated_port.typography = port.typography
                updated_port.typography_media = port.typography_media
            }
            return updated_port
        })
        state.port_errors = {}
    },
    ERRORS_PORT(state, errors){
        state.port_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};

const actions = {
    async load_all_ports({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "ports/")
            .then((response) => {
                commit('SET_PORTS', response.data)
            })
    },
    async create_port({commit}, port) {
        if(typeof port.logo.name != 'string'){
            delete port['logo']
        }
        var formData = new FormData();
        for(var item in port){
            formData.append(item, port[item])
        }
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
        
        await axios.post(process.env.VUE_APP_API_URL  + 'ports/', formData, {headers})
            .then((response) => {
                commit('NEW_PORT', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_PORT', error.response.data)
            })
    },
    async delete_port({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'ports/' + id)
            .then(() => {
                
                commit('DELETE_PORT', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async update_port({commit}, port) {
        if(typeof port.logo.name != 'string'){
            delete port['logo']
        }
        
        var formData = new FormData();
        for(var item in port){
            formData.append(item, port[item])
            
        }
        
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
        await axios.put(process.env.VUE_APP_API_URL  + 'ports/' + port.id + '/',  formData, headers)
            .then((response) => {
                commit('UPDATE_PORT', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_PORT', error.response.data)
            })
    },
};

const getters = {
    all_ports: (state) => state.ports,
    get_port_errors: (state) => state.port_errors,
    get_delete_port_status:(state) => state.delete_status
};

export default {
    state,
    mutations,
    actions,
    getters
};