<template>
    <div class="content-section introduction mt-4">
        <div class="feature-intro">
            <h1>{{format_date(get_bill_group.date)}}</h1>
        </div>
    </div>
    <div class="row mt-4">
        <div class="card p-0 ml-2 responsive_width">
            <BillingTable :get_all_bills="get_bill_group.bills" :validation="true"/>
        </div>
    </div>
    <div v-if="!view_mode && get_bill_group.validated != true" class="flex p-fluid justify-content-end mt-3">
        <div class="col-2">
            <Button label="Validate" class="p-button-success" @click="validate_payment_proposal"/>
        </div>
        <div class="col-2">
            <Button label="Deny" class="p-button-warning" @click="deny_payment_proposal"/>
        </div> 
    </div>
    <div v-else-if="view_mode == 'view' || get_bill_group.validated == true" class="row p-fluid justify-content-end mt-3">
        <div class="col-2">
            <Button label="Web Notebook" @click="display_payday_modal = true"/>
        </div>
    </div>
    <Dialog header="Generate Web Notebook" v-model:visible="display_payday_modal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}" :modal="true">
        <div class="flex">
            <p> This field represents the date on which the payment will be made, and if the date expires, another one will have to be created.   </p>
        </div>
        <div class="flex mt-3">        
            <span class="p-float-label">
                <Calendar v-model="payday" dateFormat="yy-mm-dd"/>
                <label> Payday </label>
            </span>
            <small v-if="empty_payday" class="p-error"> {{ empty_payday }}</small>
            <small v-if="error_payday" class="p-error"> {{error_payday.payday}} </small>
        </div>
        <template #footer>
            <Button label="Cancel" @click="display_payday_modal = false" class="p-button-text"/>
            <Button label="Download" @click="generate_web_notebook" autofocus />
        </template>
    </Dialog>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Calendar from 'primevue/calendar'

import moment from 'moment'
import axios from 'axios'

import BillingTable from '../../components/Billing/Billing_table.vue'

export default {
    name: 'Payment_proposal_validation',
    components: {
        Button,
        BillingTable,
        Dialog,
        Calendar
    },
    props: {
        view_mode: {
            type: String
        }
    },
    data() {
        return {
            home: {icon: 'fa fa-home', to: '/billing/payment_proposal'},
            items: [
                {label: 'Validation'},
            ],
            payday: '',
            display_payday_modal: false,
            error_payday: '',
            empty_payday: '',
        }
    },
    computed: {
        get_bill_group: function() {
            return this.$store.getters.get_bill_group
        }
    },
    async mounted() {
        await this.$store.dispatch('load_bill_group', Number(this.$route.params.id))
    },
    methods: {
        format_date(value) {
            return moment(value).format('MMMM, yy')
        },
        format_date_file(value) {
            return moment(value).format('MM_yy')
        },
        validate_payment_proposal(){
            
            var data = {
                id: this.get_bill_group.id,
                message: 'validate'
            }

            this.$store.dispatch('change_validation_status_payment_proposal', data)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Payment validated.', life: 3000});
            this.$router.push('/billing/payment_proposal')
        },
        deny_payment_proposal() {
             var data = {
                id: this.get_bill_group.id,
                message: 'reject'
            }

            this.$store.dispatch('change_validation_status_payment_proposal', data)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Payment denied.', life: 3000});
            this.$router.push('/billing/payment_proposal')
        },
        async generate_web_notebook() {

            if(this.payday == '' || this.payday == null) {
                this.error_payday = ''
                this.empty_payday = 'The date is required'
                
            } else {
                this.empty_payday = ''
                await axios.post(process.env.VUE_APP_API_URL + "bill_groups/generate_xml/" + this.get_bill_group.id + "/", {
                    payday: moment(this.payday).format('YYYY-MM-DD')
                })
                    .then((response) => {
                        this.error_payday = ''
                        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/xml'}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download',  this.format_date_file(this.get_bill_group.date) + 'web_notebook.xml');
                        document.body.appendChild(link);
                        link.click();
                        this.display_payday_modal = false
                        this.payday = ''
                    })
                    .catch((error) => {
                        this.error_payday = error.response.data
                    })
            }
        }
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

@media (max-width: 1301px) {
    .responsive_width{
        width: 97vw !important; 
    }
}

.responsive_width{
    width: calc(100vw - 23rem )
}

</style>