<template>
    <div v-if="loading" style="position: absolute; top:0;left:0;">
        <ProgressBar mode="indeterminate" style="height: 6px; width: 100vw;"></ProgressBar>
    </div>
	<div class="container">
		<div class="row pt-3">
			<div class="col text-center">
				<SelectButton :options="menu" v-model="menu_selected" optionLabel="name" @change="change_option($event.value)" unselectable>
					<template #option="slotProps">
						{{slotProps.option.name}}
					</template>
				</SelectButton>
			</div>
		</div>
	</div>
	<div v-if="!loading" class="container-fluid pt-3" >
		<div class="row">
            <span v-if="menu_selected.name == 'Pending'">
                <DataView :value="all_port_calls_signatures" :layout="layout" :paginator="true" :rows="9" >
                    <template #grid="slotProps">
                        <div class="col-12 md:col-6 lg:col-4">
                            <div class="product-grid-item card ps-4 pe-4 pt-2 pb-2">
                                <div class="row pt-12">
                                    <div class="col-12 md:col">
                                        <div class="flex">
                                            <small class="text-600"> SHIP </small>
                                        </div>
                                        <i class="fa fa-ship me-2"></i>
                                        <span> {{slotProps.data.id_ship.name}} </span>
                                    </div> 
                                </div>
                                <div class="row">
                                    <div class="col-12 md:col-6 text-md-start">
                                        <div class="flex">
                                            <small class="text-600"> ARRIVAL </small>
                                        </div>
                                        <span v-if="slotProps.data.arrival_datetime"> 
                                            <i class="fa fa-calendar me-2"></i>
                                            {{format_date(slotProps.data.arrival_datetime)}} 
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </div>
                                    <div class="col-12 md:col-6  text-start">
                                        <div class="flex ">
                                            <small class="text-600"> DEPARTURE </small>
                                        </div>
                                        <span v-if="slotProps.data.departure_datetime"> 
                                            <i class="fa fa-calendar me-2"></i>
                                            {{format_date(slotProps.data.departure_datetime)}} 
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </div>
                                </div>
                                <div class="row pt-1 justify-content-between  text-md-start" >
                                    <div v-if="menu_selected.name === 'Pending'" class="col">
                                        <Button v-tooltip.bottom="'Sign'" icon="fa fa-pen" class="p-button-success p-button-sm p-button" @click="open_modal_service_sheet(slotProps.data)"/>
                                    </div>
                                    <div class="col text-md-end">
                                        <Button v-tooltip.bottom="'Info'" icon="fa fa-clipboard" class="p-button-sm " @click="$router.push({name:'Service_sheet_menu', params: { id:slotProps.data.id, }})"/>
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </template>
                    <template #empty>
                        <div class="row">
                            <div class="col text-center">
                                <p> No pending calls.</p>
                            </div>
                        </div>
                    </template>
                </DataView>
            </span>
			<span v-else>
                <DataTable :value="all_port_calls_signatures" stripedRows :paginator="true" :rows="10">
                    <Column header="Arrival" field="arrival_datetime">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.arrival_datetime"> 
                                {{format_date(slotProps.data.arrival_datetime)}} 
                            </span>
                            <span v-else>
                                -
                            </span>
                        </template>
                    </Column>
                    <Column header="Departure" field="departure_datetime">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.departure_datetime"> 
                                {{format_date(slotProps.data.departure_datetime)}} 
                            </span>
                            <span v-else>
                                -
                            </span>
                        </template>
                    </Column>
                    <Column header="Ship" field="id_ship.name"/>
                    <Column header="Versions">
                        <template #body="slotProps">
                            <Button icon="fa fa-code-branch" class="p-button-info p-button-rounded p-button-sm" @click="open_modal_call_verions_pdf(slotProps)"/>
                        </template>
                    </Column>
                    <Column header="Info">
                        <template #body="slotProps">
                            <Button icon="fa fa-clipboard" class="p-button-rounded p-button-sm" @click="$router.push({name:'Service_sheet_menu', params: { id:slotProps.data.id, }})"/>
                        </template>
                    </Column>
                    <template #empty>
                        <div class="row">
                            <div class="col text-center">
                                No signatures.
                            </div>
                        </div>
                    </template>
                </DataTable>
            </span>
		</div>
	</div>

	<Dialog header="Details Service Sheet" v-model:visible="display_modal" :breakpoints="{'960px': '90vw'}" :style="{width: '80vw'}" :modal="true" @hide="close_modal_signatures">
		<TabMenu :model="items" v-model:activeIndex="active"/>
		<div class="row">
			<router-view :key="$route.fullPath" @close_modal="close_modal"/>
		</div>
	</Dialog>

	<Dialog header="Pdf versions" v-model:visible="display_modal_pdfs" :style="{width: '70rem'}" :modal="true">
		<DataTable :value="call_pdfs" rowGroupMode="subheader" groupRowsBy="terminal"
                sortMode="single" sortField="terminal" :sortOrder="1" responsiveLayout="scroll"
                :expandableRowGroups="true" v-model:expandedRowGroups="expandedRowGroups"
                @rowgroupExpand="onRowGroupExpand" @rowgroupCollapse="onRowGroupCollapse">
			<Column field="terminal" header="Terminal"></Column>
			<Column field="version" header="Version">

			</Column>
			<Column field="pdf" header="Link">
				<template #body="slotProps">
					<span> <a :href="slotProps.data.pdf" target="_blank"> Open Pdf </a> </span>
				</template>
			</Column>
			<Column field="creation_datetime" header="Date">
				<template #body="slotProps">
					<span> {{format_date(slotProps.data.creation_datetime)}} </span>
				</template>
			</Column>
			<template #groupheader="slotProps">
				<span class="image-text">Terminal {{slotProps.data.terminal}}</span>
			</template>
            <template #empty>
                <div class="row">
                    <div class="col text-center">
                        No signatures.
                    </div>
                </div>
            </template>
		</DataTable>
	</Dialog>

</template>

<script>
import DataView from 'primevue/dataview'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import TabMenu from 'primevue/tabmenu'
import SelectButton from 'primevue/selectbutton'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ProgressBar from 'primevue/progressbar'

export default {
	name: 'Signatures',
	components: {
		DataView,
		Button,
		Dialog,
		TabMenu,
		SelectButton,
		DataTable,
		Column,
        ProgressBar
	},
	data() {
		return{
			loading: true,
			active: 0,
			actual_calls: [],
            items: [],
			items_pdfs: [],
			call_pdfs: [],
			menu_selected: { name:'Pending', icon:'fa fa-ellipsis fa-2x' },
			menu: [
				{ name:'Pending', icon:'fa fa-ellipsis fa-2x' },
				{ name:'Signed', icon:'fa fa-check fa-2x' }
				
			],
			color: localStorage.getItem('primary_color'),
			layout: 'grid',
			display_modal: false,
			display_modal_pdfs: false,
			service_sheet: {},
			service_sheet_pdf: {
				'id_service_sheet': '',
				'pdf': ''
			},
			expandedRowGroups: null
		}
	},
	async mounted() {
		await this.$store.dispatch('load_signature_calls', 'Pending')
		this.actual_calls = this.all_port_calls_confirmed
		this.loading = false

        if(this.$route.name == 'Signatures_template') {
            this.display_modal = true
        }
	},
	computed: {
		get_all_service_sheets: function() {
			return this.$store.getters.all_service_sheets
		},
		all_port_calls_signatures: function() {
            return this.$store.getters.all_port_calls_signatures
        },
		get_service_sheets: function() {
            return this.$store.getters.get_service_sheets
        },
		get_pdf_versions: function() {
			return this.$store.getters.get_pdf_versions
		}
	},
	methods: {
		close_modal() {
			this.display_modal = false
		},
		close_modal_signatures(){
			this.$router.push({'name': 'Signatures'})
		},
		format_date(date){
			return date.split('T')[0] + ' ' + date.split('T')[1].split(':')[0] + ':' + date.split('T')[1].split(':')[1]
		},
		async open_modal_service_sheet(service_sheet) {
			this.items = []
			await this.$store.dispatch('load_service_sheets_by_call', (Number(service_sheet.id)))
			await this.$router.push({name: 'Signatures_template', params: { sid: this.get_service_sheets[0].id }})
			this.get_service_sheets.forEach((elements) => {
				let item = {}
				if(elements.id_terminal!==null) {
					item['label'] = 'Terminal ' + elements.id_terminal.name
				} else {
					item['label'] = elements.number 
				}
				item['to'] = '/signatures/' + elements.id
				this.items.push(item)
			})
			this.service_sheet = {...service_sheet}
			this.display_modal = true
		},
		closeBasic() {
			this.display_modal = false
		},
		async change_option(event){
			this.loading = true
			if(event.name === 'Signed'){
				await this.$store.dispatch('load_signature_calls', 'Signed')
				this.loading = false

			} else if(event.name === 'Pending') {
				await this.$store.dispatch('load_signature_calls', 'Pending')
				this.loading = false
			}
			this.loading = false
		},
		async open_modal_call_verions_pdf(data) {
			this.display_modal_pdfs = true
			this.items_pdfs = []
			this.call_pdfs = []
			await this.$store.dispatch('load_service_sheets_by_call', data.data.id)
			for(var i in this.get_service_sheets) {
				let item = {
					ss: '',
					pdfs: ''
				}

				item.ss = this.get_service_sheets[i].id_terminal.name
				await this.$store.dispatch('get_pdf_versions', this.get_service_sheets[i].id )
				if(this.get_pdf_versions) {
					for(var j in this.get_pdf_versions) {
						
						this.get_pdf_versions[j].terminal = this.get_service_sheets[i].id_terminal.name

						this.call_pdfs.push(this.get_pdf_versions[j])

					}
					
					item.pdfs = this.get_pdf_versions
				} else {
					item.pdfs = ''
				}
				
				this.items_pdfs.push(item)

			}
		}	
	}
}
</script>

<style scoped>

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
}

:v-deep(.product-grid-item) {
	margin: .5rem;
}

.pt-6rem{
	padding-top: 6rem!important;
}
.pt-10rem{
	padding-top: 10rem!important;
}
.pb-8rem{
	padding-bottom: 8rem!important;
}
.b-1{
	border: 1px solid;
	border-color: v-bind(color)
}

@media (max-width: 576px) { 
	.service_resume{
		font-family: 'Gotham Narrow', sans-serif;
		font-size: 0.8rem;
	}
	h5{
		font-size: 1rem;
	}
}
@media (max-width: 768px) { 
	.service_resume{
		font-family: 'Gotham Narrow', sans-serif;
		font-size: 0.8rem;
	}
	h5{
		font-size: 1rem;
	}
	.bl-1{
		margin-top: 50px;
	}
}
@media (min-width: 769px) { 
	.service_resume{
		font-family: 'Gotham Narrow', sans-serif;
		font-size: 0.8rem;
	}
	h5{
		font-size: 1rem;
	}
	.bl-1{
		border-left: 1px solid black;
	}
	.br-1{
		border-right: 1px solid black;
	}
}

::v-deep(.product-grid-item) {
	margin: .5rem;
}

:deep(.p-tabmenu-nav){
    background-color: white !important;
}

</style>