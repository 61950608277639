<template>
    <div style="display:hidden;" class="row text-center">
        <div class="col">
            <i class="fa fa-trash"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Deleted Task',
    props: {
        task: {
            type: Object,
            required: true
        }
    },
}
</script>


<style scoped>

</style>