<template>
    <div v-if="blockedDocument" style="position: absolute; top:0;left:0;">
        <ProgressBar mode="indeterminate" style="height: 6px; width: 100vw;"></ProgressBar>
    </div>
    <div class="container-fluid mt-2">
        <div class="grid justify-content-between">
            <div class="col-12 md:col my-auto">
                <h1 style="margin-bottom: 0px;"> Overview</h1>
            </div>
            <div class="col-12 md:col-3">
                <div class="flex">
                    <div class="col text-center my-auto">
                        <Button icon="fa fa-chevron-left" class="p-button-sm p-button-text p-button-rounded" @click="previous_year"/>
                    </div>
                    <div class="col text-center my-auto">
                        <h4 style="margin-bottom: 0px;"> {{ year }} </h4>
                    </div>
                    <div class="col text-center my-auto">
                        <Button icon="fa fa-chevron-right" class="p-button-sm p-button-text p-button-rounded" @click="next_year"/>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <TabView class="mt-5 pb-5">
        <TabPanel header="Total">
            <Total :statistics="get_call_statistics" :year="year"/>
        </TabPanel>
        <TabPanel header="Terminal">
            <Terminal :statistics="get_terminal_statistics" :terminal_names="get_terminal_names"/>
        </TabPanel>
        <TabPanel header="Cruise Lines">
            <CruiseLine :statistics="get_cruise_line_statistics"/>
        </TabPanel>
    </TabView>
</template>

<script>
import Button from 'primevue/button'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import ProgressBar from 'primevue/progressbar'

import Total from '../../components/Statistics/Total_statistics.vue'
import Terminal from '../../components/Statistics/Terminal_statistics.vue'
import CruiseLine from '../../components/Statistics/Cruise_line_statistics.vue'

import moment from 'moment'

export default {
    name: 'Overview',
    components: {
        Button,
        TabView,
        TabPanel,
        Total,
        Terminal,
        CruiseLine,
        ProgressBar
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            blockedDocument: false,
            year: moment().format('YYYY'),
        }
    },
    computed: {
        get_terminal_statistics: function() {
            return this.$store.getters.get_terminal_statistics
        },
        get_call_statistics: function() {
            return this.$store.getters.get_call_statistics
        },
        get_cruise_line_statistics: function() {
            return this.$store.getters.get_cruise_line_statistics
        },
        get_terminal_names: function() {
            return this.$store.getters.get_terminal_names
        }
    },
    async mounted() {
        this.blockedDocument = true
        await this.$store.dispatch('load_all_terminals')
        await this.$store.dispatch('load_terminals_statistics', this.year) 
        await this.$store.dispatch('load_calls_statistics', this.year) 
        await this.$store.dispatch('load_cruise_line_statistics', this.year) 
        this.blockedDocument = false
    },
    methods: {
        async previous_year() {
            this.blockedDocument = true
            this.year = this.year - 1
            await this.$store.dispatch('load_terminals_statistics', this.year) 
            await this.$store.dispatch('load_calls_statistics', this.year)
            await this.$store.dispatch('load_cruise_line_statistics', this.year) 
            this.blockedDocument = false
        },
        async next_year() {
            this.blockedDocument = true
            this.year = this.year + 1
            await this.$store.dispatch('load_terminals_statistics', this.year)
            await this.$store.dispatch('load_calls_statistics', this.year)
            await this.$store.dispatch('load_cruise_line_statistics', this.year) 
            this.blockedDocument = false
        },
    }
    
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}


</style>