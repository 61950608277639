<template>
	<div class="card">
        <div class="row">
			<Toast />
			<Datatable :value="all_billing_destinations" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" scrollable 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading"
            :globalFilterFields="['billing_type']"
            v-model:filters="filters">
                <template #empty>
                    <div class="col text-center">
                        No records founds
                    </div>
                </template>
				<template #header>
					<div class="flex justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText id="filter_billing_destination" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                        </span>
                    </div>
				</template>
				
				<Column header="Billing Type" field="billing_type">
				</Column>
                <Column v-if="$can('change','billing_destination')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_billing_destination(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','billing_destination')" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_billing_destination(slotProps)"/>
                    </template>
                </Column>
			</Datatable>
		</div>
	</div>

	<Dialog data-cy="edit_billing_destination_modal" v-model:visible="billing_destination_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Billing Details" :modal="true" class="p-fluid">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-10 md:col-12">
					<div class="p-float-field">
						<label for="billing_type" class="ps-1 pb-1"> <strong>Billing Type</strong>  </label>
						<InputText id="billing_type" v-model.trim="billing_destination.billing_type" required="true" autofocus :class="{'p-invalid': submitted && !billing_destination.billing_type || billing_destination_errors.billing_type}" />
						<small class="p-error" v-if="submitted && !billing_destination.billing_type">Billing type is required.</small>
						<small v-if="Object.keys(billing_destination_errors).length !== 0 && billing_destination_errors.billing_type" class="p-error"><span v-for="error in billing_destination_errors.billing_type" :key="error">{{error}}<br></span></small>
					</div>
				</div>
			</div>
		</div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_billing_destination_dialog"/>
            <Button data-cy="save_edit_billing_destination" label="Save" @click="save_billing_destination" />
        </template>
    </Dialog>

	<Dialog v-model:visible="delete_billing_destination_dialog" :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="billing_destination">Are you sure you want to delete <b>{{billing_destination.data.billing_type}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_billing_destination_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="delete_billing_destination" />
        </template>
    </Dialog>

</template>

<script>
import Datatable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'

import { FilterMatchMode } from 'primevue/api';

export default {
	billing_type: 'TableBilling_destination',
	components: {
		Datatable,
		Column,
		Button,
		InputText,
		Toast,
		Dialog
	},
	data() {
		return {
			filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
			billing_destinations: {},
            billing_destination_errors:{},
            get_errors_billing_destination: {},
            billing_destination_dialog: false,
            delete_billing_destination_dialog: false,
            submitted: false,
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		}
	},
	async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_billing_destinations.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_billing_destinations')
            this.loading = false
        }	
	},

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

	computed: {
		all_billing_destinations: function() {
			return this.$store.getters.all_billing_destinations
		}
	},
	methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

		confirm_delete_billing_destination(billing_destination) {
            this.billing_destination = billing_destination;
            this.delete_billing_destination_dialog = true;
        },
        async delete_billing_destination() {
            await this.$store.dispatch('delete_billing_destination', this.billing_destination.data.id)
            if (!this.get_delete_billing_destination_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Billing destination deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Billing destination can't be deleted", life: 3000});
            }
            this.delete_billing_destination_dialog = false;
        },
		edit_billing_destination(billing_destination) {
            this.billing_destination = {...billing_destination};
            this.billing_destination_dialog = true;
        },
        async save_billing_destination() {
            this.submitted = true;
			if (this.billing_destination.billing_type.trim()) {
                await this.$store.dispatch('update_billing_destination', this.billing_destination)
                this.billing_destination_errors = this.get_errors_billing_destination

                if(Object.keys(this.billing_destination_errors).length === 0){
                    this.billing_destination_dialog = false;
                    this.billing_destination = {};
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Biilling destination modified', life: 3000});
                }
                
            }
        },
	}

}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>