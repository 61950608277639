import axios from 'axios'

const state = {
    logs: [],
    filtered_logs: [],
	lazy_logs: []
}

const mutations = {
    SET_LOGS(state, logs) {
        state.logs = logs
        state.filtered_logs = logs
    },
	SET_LAZY_LOGS(state, lazy_logs) {
		state.lazy_logs.push(lazy_logs)
	},
    SET_FILTERS_LOG(state, filter){
        state.filtered_logs = null
        let new_filter = {}
        for(var item in filter){
            if(filter[item] != null){
                new_filter[item] = filter[item]
            }
        }
        state.filtered_logs = state.logs.filter(filter_item => {
            for(var data in new_filter){
                if(data == 'user'){
                    if (!filter_item.id_user) {
                        return false
                    }
                    if (filter_item.id_user.username != new_filter[data]) {
                        return false
                    }
                }
                if(data == 'date'){
                    if(new_filter[data][1]) {
                        if(new Date(filter_item.modification_date).getTime() > new_filter[data][1].getTime() && new Date(filter_item.modification_date).getTime() < new_filter[data][0].getTime()) {
                            return false
                        }

                    } else {
                        var date_hour_modified = new Date(filter_item.modification_date)
                        if(new Date(new_filter[data]).getTime() != date_hour_modified.setHours(0,0,0,0)) {
                            return false
                        }
                    }
                }
                if(data == 'field'){
                    if(filter_item.field_changed) {
                        if(!filter_item.field_changed.includes(new_filter[data].name)){
                            return false
                        } 
                    } else {
                        return false
                    }
                }
            }
            return true
        })

    },
	EMPTY_FILTERS_LOGS(state) {
		state.lazy_logs = []
	}
}

const actions = {
	async load_logs({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'log_records/get_logs/' + id) 
			.then((response) => {
				commit('SET_LOGS', response.data)
			})
	},
    async filter_logs({commit}, filter) {
        commit('SET_FILTERS_LOG', filter)
    },
	async lazy_logs({commit}, payload) {
		for(var i in state.logs) {
			if(i >= payload[1] && i < payload[1] + payload[2]) {
                commit('SET_LAZY_LOGS', state.filtered_logs[i])
			}
		}
	},
	async empty_filter_logs({commit}) {
		commit('EMPTY_FILTERS_LOGS')
	}
}

const getters = {
    get_logs: (state) => state.logs,
	get_lazy_logs: (state) => state.lazy_logs,
    get_filtered_logs: (state) => state.filtered_logs
}

export default {
    state,
    mutations,
    actions,
    getters
};