<template>
    <div class="card pointer"  @click="open_service_sheet(call.id)">
        <img alt="ship picture" :src="call.id_ship.picture" class="card-img-top img-fluid w-100"/>
        <div class="card-body">
            <h5 class="card-title"> {{call.id_ship.name}} </h5>
            <h6 v-if="!validation_type" class="text-center" style="margin-bottom: 0px;"> Time Left </h6>
            <Countdown v-if="!validation_type" countdownSize="1.1rem" :showLabels="false" :flipAnimation="false"  mainColor="black" secondFlipColor="#FFFFFF" :deadlineDate="format_dateline(call.departure_datetime)" class="pt-1"/>
            <div v-if="validation_type" class="row">
                <div class="col text-center">
                    {{format_date_long(call.arrival_datetime)}}
                </div>
                <div class="col text-center">
                    {{format_date_long(call.departure_datetime)}} 
                </div>
            </div>
        </div>
        <div class="card-footer" v-if="!validation_type">
            <div class="row justify-content-between">
                <div class="col" style="color: green;">
                    {{format_date(call.arrival_datetime)}}
                </div>
                <div class="col text-end" style="color: orange;">
                    {{format_date(call.departure_datetime)}} 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { Countdown } from 'vue3-flip-countdown'

export default {
    props: {
        call: {
            type: Object,
            required: true
        },
        validation_type: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        get_user_port() {
            return this.$store.getters.get_user_port;
        },  
        get_service_sheets: function() {
            return this.$store.getters.get_service_sheets
        },
    },
    components: {
        Countdown,
    },
    methods: {
        format_date(value) {
            return moment(value).format('ddd') + ' ' + moment(value).format().split('T')[0].split('-')[2] +  ', ' + moment(value).format().split('T')[1].split(':')[0] + ':' + moment(value).format().split('T')[1].split(':')[1]
        },
        format_date_long(value) {
            return moment(value).format("MMMM D") + ' at ' + moment(value).format("HH:mm")
        },
        format_dateline(value) {
            return new Date(moment(value).format())
        },
        async open_service_sheet(id) {  
            await this.$store.dispatch('load_service_sheets_by_call', id)
            
            if(this.get_service_sheets.length > 0) {
                await this.$router.push({name: 'Service_sheet_template', params: { id: id, sid: this.get_service_sheets[0].id  }})
            } else {
                await this.$router.push({name: 'Service_sheet_menu', params: { id: id }})
            }
        }
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
    margin: 1rem;
}

.card-img-top {
    max-height: 200px;
    min-height: 200px;
    object-fit: cover;
}

.pointer{
  cursor: pointer !important;
}

</style>