<template>
    <Toast/>
    <div v-if="$can('view','finger')" class="pt-4 pb-2">
        <div class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Fingers </h1>
            <div v-if="$can('add','finger')" class="card p-1">
                <Button data-cy="new_finger_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Finger'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_finger_modal" header="New Finger" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <div class="container">
                <div class="row justify-content-center">
                    <form id="from_new_finger" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted || finger_errors.name}" />
                                <label for="name" :class="{'p-error':v$.name.$invalid && submitted}"> Name </label>
                                <small v-if="Object.keys(finger_errors).length !== 0 && finger_errors.name" class="p-error"><span v-for="error in finger_errors.name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
                        </div>
                    </form>
                </div> 
            </div>
            <template #footer>
                <Button data-cy="create_finger_button" form="from_new_finger" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        Toast
    },
    data() {
        return {
            finger_errors:{},
            displayModal: false,
            name: '',
            submitted: false,
            showMessage: false,
        }
    },
    computed:{
        get_finger_errors: function() {
            return this.$store.getters.get_finger_errors
        }
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_finger() {
            let finger = { 
                name: this.name,
            }
            await this.$store.dispatch('create_finger', finger)
            this.finger_errors = this.get_finger_errors
            
            if(Object.keys(this.finger_errors).length === 0){
                this.toggleDialog();
                this.resetForm()
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

            let res = await this.add_finger()

            if (res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Finger added', life: 3000});
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.name = '';
            this.submitted = false;
        }
    },
    validations() {
        return {
            name: { required }
        }
    },
};
</script>

<style scoped>
</style>