<template>
  <div class="container-fluid">
    <TableIncidences/>
  </div>
</template>


<script>
import TableIncidences from '@/components/Incidences/TableIncidences.vue'
export default {
    name: 'Incidences',
    components: {
        TableIncidences,
    },
    async mounted(){
        await this.$store.dispatch('load_users_by_port')
        
        await this.$store.dispatch('load_all_incidence_zones')
        await this.$store.dispatch('load_all_incidence_categories')
        await this.$store.dispatch('load_all_incidence_subcategories')
        await this.$store.dispatch('load_all_terminals')
    }
}
</script>

<style scoped>

.title_header{
  height: 10vh;
  color: white;
  display:flex;
  justify-content:center;
  align-items:center;
  margin: auto;
}

</style> 