import axios from 'axios'

const state = {
    unconfirmed_calls: []
}

const mutations = {
    SET_UNCONFIRMED_CALLS(state, unconfirmed_calls) {
        state.unconfirmed_calls = unconfirmed_calls
    }
}

const actions = {
    async load_unconfirmed_calls({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "calls/get_unconfirmed_calls/")
            .then((response) => {
                commit('SET_UNCONFIRMED_CALLS', response.data)
            })
    },
}

const getters = {
    get_unconfirmed_calls: (state) => state.unconfirmed_calls
}

export default {
    state,
    actions,
    mutations,
    getters
}