import axios from 'axios'
import moment from 'moment';

const state = {
	finger_service: {},
    finger_service_errors: {},
}

const mutations = {	
	SET_FINGER_SERVICE(state, finger_service) {
        state.finger_service = finger_service
    },
    CREATE_SERVICE_SHEET_FINGER_SERVICE(state, finger_service) {  
        state.finger_service.push(finger_service)
        state.finger_service_errors = {}
    },
    DELETE_SERVICE_SHEET_FINGER_SERVICE(state, id) {
        let index = state.finger_service.findIndex(finger_service => finger_service.id == id)
        state.finger_service.splice(index, 1)
    },
    PATCH_SERVICE_SHEET_FINGERS_SERVICE(state, finger_service) {
        state.finger_service = state.finger_service.map(updated_finger_service => {
            if(updated_finger_service.id === finger_service.id) {
                updated_finger_service.position = finger_service.position
                updated_finger_service.finger = finger_service.finger 
                updated_finger_service.start_datetime_hour = finger_service.start_datetime.split('T')[1].split(':')[0] + ':' + finger_service.start_datetime.split('T')[1].split(':')[1]
                updated_finger_service.start_datetime = finger_service.start_datetime.split('T')[0]
                if(finger_service.end_datetime != null) {
                    updated_finger_service.end_datetime_hour = finger_service.end_datetime.split('T')[1].split(':')[0] + ':' + finger_service.end_datetime.split('T')[1].split(':')[1]
                    updated_finger_service.end_datetime = finger_service.end_datetime.split('T')[0]
                } else {
                    updated_finger_service.end_datetime_hour = ''
                    updated_finger_service.end_datetime = ''
                }
                
            }
            return updated_finger_service
        })
        state.finger_service_errors = {}
    },
    ERRORS_SERVICE_SHEET_FINGERS_SERVICE(state, errors){
        state.finger_service_errors = errors
    },
}

const actions = {
    async load_finger_service({commit}, id){
		await axios.get(process.env.VUE_APP_API_URL + 'finger_service/service_sheet/' + id +'/')
			.then((response) => {
				commit('SET_FINGER_SERVICE', response.data)
			})
    },
    async patch_service_sheet_fingers_service({commit}, finger_service) {
        if(typeof(finger_service.start_datetime) === 'object' || typeof(finger_service.start_datetime_hour) === 'object') {
            if(typeof finger_service.start_datetime_hour === 'string') {
                finger_service.start_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + finger_service.start_datetime_hour))
            }
            var start_datetime = new Date(new Date(finger_service.start_datetime).getFullYear(), new Date(finger_service.start_datetime).getMonth(), new Date(finger_service.start_datetime).getDate(),
                new Date(finger_service.start_datetime_hour).getHours(), new Date(finger_service.start_datetime_hour).getMinutes(), new Date(finger_service.start_datetime_hour).getSeconds())
            start_datetime = moment(start_datetime).format().split('+')[0]
        } else {
            start_datetime = finger_service.start_datetime + 'T' + finger_service.start_datetime_hour
        }
        if(finger_service.end_datetime != '' && finger_service.end_datetime != null) {
            if(typeof(finger_service.end_datetime) === 'object' || typeof(finger_service.end_datetime_hour) === 'object') {
                if(typeof finger_service.end_datetime_hour === 'string') {
                    finger_service.end_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + finger_service.end_datetime_hour))
                }
                var end_datetime = new Date(new Date(finger_service.end_datetime).getFullYear(), new Date(finger_service.end_datetime).getMonth(), new Date(finger_service.end_datetime).getDate(),
                    new Date(finger_service.end_datetime_hour).getHours(), new Date(finger_service.end_datetime_hour).getMinutes(), new Date(finger_service.end_datetime_hour).getSeconds())
                end_datetime = moment(end_datetime).format().split('+')[0]
            } else {
                end_datetime = finger_service.end_datetime + 'T' + finger_service.end_datetime_hour
            }
            await axios.patch(process.env.VUE_APP_API_URL + 'finger_service/' + finger_service.id + '/', 
            { 
                finger: finger_service.finger.id,
                position: finger_service.position,
                start_datetime: start_datetime,
                end_datetime: end_datetime,
                service_sheet: finger_service.service_sheet.id
            }) 
                .then((response)=> {
                    commit('PATCH_SERVICE_SHEET_FINGERS_SERVICE', response.data)
                })
                .catch((error) => {
                    if(error.response){
                        commit('ERRORS_SERVICE_SHEET_FINGERS_SERVICE', error.response.data)
                    }
                })
        } else {
            await axios.patch(process.env.VUE_APP_API_URL + 'finger_service/' + finger_service.id + '/', 
            { 
                finger: finger_service.finger.id,
                position: finger_service.position,
                start_datetime: start_datetime,
                service_sheet: finger_service.service_sheet.id,
                end_datetime: null
            }) 
                .then((response)=> {
                    commit('PATCH_SERVICE_SHEET_FINGERS_SERVICE', response.data)
                })
                .catch((error) => {
                    if(error.response){
                        commit('ERRORS_SERVICE_SHEET_FINGERS_SERVICE', error.response.data)
                    }
                })
        }
       
    },
    async create_service_sheet_finger_service({commit}, finger_service) {
        if(typeof finger_service.start_datetime_hour === 'string') {
            finger_service.start_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + finger_service.start_datetime_hour))
        }
        var start_datetime = new Date(new Date(finger_service.start_datetime).getFullYear(), new Date(finger_service.start_datetime).getMonth(), new Date(finger_service.start_datetime).getDate(),
                            new Date(finger_service.start_datetime_hour).getHours(), new Date(finger_service.start_datetime_hour).getMinutes(), new Date(finger_service.start_datetime_hour).getSeconds())
        
        if(finger_service.end_datetime != '') {
            if(typeof finger_service.end_datetime_hour === 'string') {
                finger_service.end_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + finger_service.end_datetime_hour))
            }
            var end_datetime = new Date(new Date(finger_service.end_datetime).getFullYear(), new Date(finger_service.end_datetime).getMonth(), new Date(finger_service.end_datetime).getDate(),
                                new Date(finger_service.end_datetime_hour).getHours(), new Date(finger_service.end_datetime_hour).getMinutes(), new Date(finger_service.end_datetime_hour).getSeconds())
        
            
            await axios.post(process.env.VUE_APP_API_URL + 'finger_service/', {
                finger: finger_service.id_finger,
                position: finger_service.position,
                start_datetime: moment(start_datetime).format().split('+')[0],
                end_datetime:moment(end_datetime).format().split('+')[0],
                service_sheet: finger_service.service_sheet
            })
                .then((response) => {
                    commit('CREATE_SERVICE_SHEET_FINGER_SERVICE', response.data)
                })
                .catch((error) => {
                    commit('ERRORS_SERVICE_SHEET_FINGERS_SERVICE', error.response.data)
                })
        
        } else {
            await axios.post(process.env.VUE_APP_API_URL + 'finger_service/', {
                finger: finger_service.id_finger,
                position: finger_service.position,
                start_datetime: moment(start_datetime).format().split('+')[0],
                service_sheet: finger_service.service_sheet
            })
                .then((response) => {
                    commit('CREATE_SERVICE_SHEET_FINGER_SERVICE', response.data)
                })
                .catch((error) => {
                    commit('ERRORS_SERVICE_SHEET_FINGERS_SERVICE', error.response.data)
                }) 
        }
        
        
        
    },
    async delete_service_sheet_finger_services({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'finger_service/' + id + '/')
            .then(() => {
                commit('DELETE_SERVICE_SHEET_FINGER_SERVICE', id)
            })
    }
    
}

const getters = {
    get_finger_service: (state) => state.finger_service,
    get_finger_service_errors: (state) => state.finger_service_errors
}

export default {
    state,
    mutations,
    actions,
    getters
};