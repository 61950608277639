export const num2time = function(data) {

    var num = data.target.value
    var h,m="00"

    var reg = /^\d+[:]?\d*$/
    var reg2 = /^\d{1,2}[:]\d{2}$/
    var reg3 = /^\d{1,2}[:]$/

    if(!reg.test(num)) {
        return null
    }   
    if(reg3.test(num)) {
        return null
    }        
    if(num==''){
        return null
    }
    
    if(reg2.test(num)){
        var horas = num.split(':')[0]
        var min = num.split(':')[1]

        if(min > 59) {
            min = '00'
        }

        if(horas > 23) {
            horas = '00'
        }

        if(horas < 10 && horas.length == 1) {
            horas = '0' + horas
        }

        num = horas + ':' + min

        return num
    }

    if(num<100){
        h=num;
    } else {
        
        if(num.length > 4) {
            return null
        }

        h=Math.floor(num/100);
        m=("0"+(num%100)).slice(-2);  

    }
    
    if(m>59) {
        m = "00"
    }
    h=h%24;
    if(h>23) {
        h = "00"
    }
    return ("0"+h).slice(-2)+":"+m;
}

export const num2timeedit = function(data) {

    var num = data.target.value
    var h,m = "00"

    var reg = /^\d+[:]?\d*$/
    var reg2 = /^\d{1,2}[:]\d{2}$/
    var reg3 = /^\d{1,2}[:]$/

    if(!data.target.value){
        return '00:00'
    }

    if(!reg.test(num)) {
        return '00:00'
    }  
    if(reg3.test(num)) {
        return '00:00'
    }     

    if(reg2.test(num)){
        var horas = num.split(':')[0]
        var min = num.split(':')[1]

        if(min > 59) {
            min = '00'
        }

        if(horas > 23) {
            horas = '00'
        }

        num = horas + ':' + min

        return num
    }
    if(num<100)
        h=num;
    else {
        h=Math.floor(num/100);
        m=("0"+(num%100)).slice(-2);
    }
    if(m>59) {
        m = "00"
    }
    h=h%24;
    if(h>23) {
        h = "00"
    }
    return ("0"+h).slice(-2)+":"+m;
}

