<template>
    <div class="mt-5">
        <div class="row">
            <div class="col-12 lg:col-6">
                <div class="row">
                    <div class="col">
                        <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Bill Selection </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 md:col"  style="max-width: 100%;">
                        <div class="row container">
                            <div v-if="url" class="col p-0" style="max-height: 50vh;max-width: 100%;">
                                <div v-if="url_type == 'pdf'" class="ratio ratio-16x9">
                                    <iframe
                                        :src="url"
                                        frameBorder="0"
                                        scrolling="auto"
                                    ></iframe>
                                </div>
                                <div v-if="url_type == 'mail'">
                                    <Message class="border-primary w-full justify-content-start"  icon="pi pi-envelope" severity="info" :closable="false">
                                        <div class="flex align-items-center">
                                            <div class="ml-2"> Mail file selected.</div>
                                        </div>
                                    </Message>
                                </div>
                                <Image v-if="url_type == 'image'" :src="url" preview class="card-img" imageStyle="max-height: 50vh;max-width: 100%;"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUpload"/>
                    </div> 
                    <small v-show="validationErrors.bill && submitted" class="p-error text-center">Bill is required.</small>
                </div>
            </div>
            <div class="col-12 lg:col-6">
                <div class="row">
                      <div class="col">
                        <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Budget Selection (Optional)</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 md:col" style="max-width: 100%;">
                        <div class="row container">
                            <div v-if="budget_url" class="col p-0" style="max-height: 50vh:">
                                <div v-if="budget_url_type == 'pdf'" class="ratio ratio-16x9">
                                    <iframe
                                        :src="budget_url"
                                        frameBorder="0"
                                        scrolling="auto"
                                    ></iframe>
                                </div>
                                <div v-if="budget_url_type == 'mail'">
                                    <Message class="border-primary w-full justify-content-start"  icon="pi pi-envelope" severity="info" :closable="false">
                                        <div class="flex align-items-center">
                                            <div class="ml-2"> Mail file selected.</div>
                                        </div>
                                    </Message>
                                </div>
                                <Image v-if="budget_url_type == 'image'" :src="budget_url" preview class="card-img" imageStyle="max-height: 50vh;max-width: 100%"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <FileUpload ref="fileUpload_budget" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .eml" :auto="true" :maxFileSize="10000000" @uploader="onUpload_Budget"/>
                    </div> 
                </div>
            </div>
        </div>     
        <div class="grid grid-nogutter justify-content-between mt-5">
            <Button label="Back" class="p-button-raised" @click="prevPage()" icon="pi pi-angle-left" />
            <Button label="Next" class="p-button-raised" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
        </div>  
    </div>
     
</template>

<script>
import Button from 'primevue/button'
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image'
import Message from 'primevue/message'

export default {
    name: 'Bill_selection',
    data() {
        return{
            url: '',
            budget_url: '',
            url_type: '',
            budget_url_type: '',
            bill: '',
            budget: '',
            validationErrors: {},
        }
    },
    props: {
        formData: Object
    },
    components: {
        Button,
        FileUpload,
        Image,
        Message
    },
    activated() {
        if(Object.keys(this.formData).length == 0) {
            this.$router.push({path: '/billing/steps'})
        }     
    },
    methods: {
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', { formData: { bill: this.bill, budget: this.budget }, pageIndex: 1 });
            }
        },
        prevPage() {
            this.$emit('prev-page', { pageIndex: 1 });
        },
        validateForm() {
            if (!this.bill) { 
                this.validationErrors['bill'] = true;
            } else {
                delete this.validationErrors['bill'];
            } 
            
            return !Object.keys(this.validationErrors).length
        },
        onUpload(e){
            if(e.files[0].name.split('.').pop().toLowerCase() == 'pdf') {
                this.url_type = 'pdf'
            } else if(e.files[0].name.split('.').pop().toLowerCase() == 'eml') {
                this.url_type = 'mail'
            } else {
                this.url_type = 'image'
            }
            this.bill = e.files[0]
            const file = e.files[0];
            this.url = URL.createObjectURL(file);
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        onUpload_Budget(e){
            if(e.files[0].name.split('.').pop().toLowerCase() == 'pdf') {
                this.budget_url_type = 'pdf'
            } else if(e.files[0].name.split('.').pop().toLowerCase() == 'eml') {
                this.budget_url_type = 'mail'
            } else {
                this.budget_url_type = 'image'
            }
            this.budget = e.files[0]
            const file = e.files[0];
            this.budget_url = URL.createObjectURL(file);
            this.$refs.fileUpload_budget.clear();
            this.$refs.fileUpload_budget.uploadedFileCount = 0;
        },
    }
}
</script>

<style scoped>

.card-img {
    object-fit: contain;
}

</style>