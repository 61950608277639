<template>
    <ModalShipfingers />
    <TableShipfingers />
</template>

<script>
import TableShipfingers from '@/components/AdminDashboard/Ship_fingers/TableShip_fingers.vue'
import ModalShipfingers from '@/components/AdminDashboard/Ship_fingers/ModalShip_fingers.vue'
export default {
  name: 'Ship_fingers',
  components: {
    TableShipfingers,
    ModalShipfingers
  }
}
</script>

<style scoped>
</style>