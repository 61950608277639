<template>
    <ModalIncidence_categories />
    <TableIncidence_categories />
</template>

<script>
import TableIncidence_categories from '@/components/AdminDashboard/Incidence_categories/TableIncidence_categories.vue'
import ModalIncidence_categories from '@/components/AdminDashboard/Incidence_categories/ModalIncidence_categories.vue'
export default {
  name: 'Incidence_categories',
  components: {
    TableIncidence_categories,
    ModalIncidence_categories
  }
}
</script>

<style scoped>
</style>