import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import ability from '../services/ability'

import Login from '../Login/Login.vue'

import Home from '../views/Home.vue'
import Profile from '../views/Profile'

import Dashboard from '../views/Dashboard'
import Calendar from '../views/Dashboard/Calendar'
import Table_calendar from '../views/Dashboard/Table'
import Validation_panel from '../views/Dashboard/Validations'

import Incidences from '../views/Incidences'

import Daily_log from '../views/Daily_log'
import Daily_log_summary from '../views/Daily_log_summary'

import Operation_diary from '../views/Operation_diary'
import Operation_diary_summary from '../views/Operation_diary_summary'

import Maintenance from '../views/Maintenance' 
import Tasks_calendar from '../views/Tasks/Task_calendar'
import Tasks_board from '../views/Tasks/Task_board'
import Tasks_budgets from '../views/Tasks/Task_budgets'
import Tasks_periodicity from '../views/Tasks/Task_periodicity'

import Billing from '../views/Billing.vue'
import Billing_dashboard from '../views/Billing/Billing_dashboard.vue'
import Billing_steps from '../views/Billing/Billing_creation/Bill_steps.vue'
import Billing_supplier_selection from '../views/Billing/Billing_creation/Supplier_selection.vue'
import Billing_bill_selection from '../views/Billing/Billing_creation/Bill_selection.vue'
import Billing_information from '../views/Billing/Billing_creation/Bill_information.vue'
import Billing_confirmation from '../views/Billing/Billing_creation/Bill_confirmation.vue'
import Payment_proposal from '../views/Billing/Payment_proposal_dashboard.vue'
import Payment_proposal_creation from '../views/Billing/Payment_proposal_creation.vue'
import Payment_proposal_validation from '../views/Billing/Payment_proposal_validation.vue'
import Billing_settlement from '../views/Billing/Billing_settlement.vue'
import Billing_accounting_validated from '../views/Billing/Billing_accounting_validated.vue'
import Billing_accounting_accounted from '../views/Billing/Billing_accounting_accounted.vue'

import Admin from '../views/Admin'
import Cruise_lines from '../views/AdminDashboard/Cruise_lines.vue'
import Ships from '../views/AdminDashboard/Ships.vue'
import Ship_fingers from '../views/AdminDashboard/Ship_fingers.vue'
import Ship_agents from '../views/AdminDashboard/Ship_agents.vue'
import Ship_responsibles from '../views/AdminDashboard/Ship_responsibles.vue'
import Incidence_reports from '../views/AdminDashboard/Incidence_reports.vue'
import Incidence_zones from '../views/AdminDashboard/Incidence_zones.vue'
import incidence_categories from '../views/AdminDashboard/Incidence_categories.vue'
import incidence_subcategories from '../views/AdminDashboard/Incidence_subcategories.vue'
import Fingers from '../views/AdminDashboard/Fingers.vue'
import Terminals from '../views/AdminDashboard/Terminals.vue'
import Users from '../views/AdminDashboard/Users.vue'
import Task_categories from '../views/AdminDashboard/Task_categories.vue'
import Passengers from '../views/AdminDashboard/Passengers.vue'
import Suppliers from '../views/AdminDashboard/Suppliers.vue'
import Contracts from '../views/AdminDashboard/Contracts.vue'
import Supplier_responsibles from '../views/AdminDashboard/Supplier_responsibles.vue'
import Supplier_assignment from '../views/AdminDashboard/Supplier_assignment.vue'
import Crew from '../views/AdminDashboard/Crew.vue'
import Ports from '../views/AdminDashboard/Ports.vue'
import Calls from '../views/AdminDashboard/Calls.vue'
import Groups from '../views/AdminDashboard/Groups.vue'
import Billing_destination from '../views/AdminDashboard/Billing_destination.vue'
import Currency from '../views/AdminDashboard/Currency.vue'
import Card from '../views/AdminDashboard/Card.vue'
import WaterMeters from '../views/AdminDashboard/Water_meters.vue'

import Statistics from '../views/Statistics.vue'
import Overview from '../views/Statistics/Overview.vue'
import Graphics from '../views/Statistics/Graphics.vue'
import Services from '../views/Statistics/Services.vue'

import Service_sheet_menu from '../views/Service_sheet_menu.vue'
import Service_sheet_template from '../views/ServiceSheet/Service_sheet_template.vue'
import Service_sheet_log from '../views/ServiceSheet/Service_sheet_log.vue'

import Signatures from '../views/Signatures.vue'
import Signatures_template from '../views/Signatures/Signatures_template.vue'

import Change_log from '../views/Change_log.vue'
import Change_log_record from '../views/Change_log_record.vue'

import Permissions from '../views/Permissions.vue'
import Group_steps from '../views/Permissions_group/Group_steps.vue'
import Group_information from '../views/Permissions_group/Group_information.vue'
import Group_users from '../views/Permissions_group/Group_users.vue'
import Group_permissions from '../views/Permissions_group/Group_permissions.vue'
import Group_confirmation from '../views/Permissions_group/Group_confirmation.vue'

import Access_denied from '../components/Access_denied.vue'

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: Home,
    },
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: {
            requiresAuth: 'Management', 
        },
        children: [
            {
                path: '/admin/cruise_lines',
                name: 'Cruise_lines',
                component: Cruise_lines,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Ship Info') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/ships',
                name: 'Ships',
                component: Ships,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Ship Info') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/ship_agents',
                name: 'Ship_agents',
                component: Ship_agents,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Ship Info') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/ship_responsibles',
                name: 'Ship_responsibles',
                component: Ship_responsibles,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Ship Info') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/fingers',
                name: 'Fingers',
                component: Fingers,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Infrastructure') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/water_meters',
                name: 'Water_meters',
                component: WaterMeters,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Infrastructure') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/ship_fingers',
                name: 'Ship_fingers',
                component: Ship_fingers,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Infrastructure') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/terminals',
                name: 'Terminals',
                component: Terminals,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Infrastructure') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/users',
                name: 'Users',
                component: Users,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment People') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/passengers',
                name: 'Passengers',
                component: Passengers,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment People') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/crew',
                name: 'Crew',
                component: Crew,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment People') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/ports',
                name: 'Ports',
                component: Ports,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Infrastructure') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/currency',
                name: 'Currency',
                component: Currency,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Infrastructure') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/card',
                name: 'Card',
                component: Card,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Infrastructure') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/calls',
                name: 'Calls',
                component: Calls,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment General') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/suppliers',
                name: 'Suppliers',
                component: Suppliers,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Services') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/contracts',
                name: 'Contracts',
                component: Contracts,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Services') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/supplier_responsibles',
                name: 'Supplier Responsibles',
                component: Supplier_responsibles,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Services') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/supplier_assignment',
                name: 'Supplier Assignment',
                component: Supplier_assignment,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Services') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/incidence_reports',
                name: 'Incidence reports',
                component: Incidence_reports,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Incidences') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/incidence_zones',
                name: 'Incidence zones',
                component: Incidence_zones,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Incidences') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/incidence_categories',
                name: 'Incidence categories',
                component: incidence_categories,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Incidences') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/incidence_subcategories',
                name: 'Incidence subcategories',
                component: incidence_subcategories,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Incidences') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/groups',
                name: 'Group',
                component: Groups,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Ship Info') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/billing_destination',
                name: 'Billing destination',
                component: Billing_destination,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Services') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            {
                path: '/admin/task_categories',
                name: 'Task categories',
                component: Task_categories,
                beforeEnter: (to, from, next) => {
                    for(var index in store.state.users.current_user.groups) {
                        if(store.state.users.current_user.groups[index].name == 'Managment Incidences') {
                            return next()
                        }
                    }
                    next('/admin/access_denied')
                }
            },
            
            {
                path: '/admin/access_denied',
                name: 'Access denied',
                component: Access_denied,
            }
        ]
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: 'Calls', 
        },
        redirect: () => {
            return '/dashboard/calendar'
        },
        children: [
            {
                path: '/dashboard/calendar',
                name: 'Calendar',
                component: Calendar,
                meta: {
                    requiresAuth: 'Calls'
                },
                beforeEnter: (to, from, next) => {
                    
                    if(ability.can('view','call')) {
                        return next()
                    } else {
                        next('/dashboard/access_denied')
                    }
                }
            },
            {
                path: '/dashboard/table_calendar',
                name: 'Table Calendar',
                component: Table_calendar,
                meta: {
                    requiresAuth: 'Calls'
                },
                beforeEnter: (to, from, next) => {
                    if(ability.can('add','call') ) {
                        return next()
                    } else {
                        next('/dashboard/access_denied')
                    }
                }
            },
            {
                path: '/dashboard/validation_panel',
                name: 'Validation Panel',
                component: Validation_panel,
                meta: {
                    requiresAuth: 'Calls'
                },
                beforeEnter: (to, from, next) => {
                    if(ability.can('validate','service_sheet') ) {
                        return next()
                    } else {
                        next('/dashboard/access_denied')
                    }
                }
            },
            {
                path: '/dashboard/signatures',
                name: 'Signatures',
                component: Signatures,
                meta: {
                    requiresAuth: 'Calls'
                },
                children: [
                    {
                        path: '/signatures/:sid',
                        name: 'Signatures_template',
                        component: Signatures_template,
                    },
                ]
            },
            {
                path: '/dashboard/calls',
                name: 'Calls Table',
                component: Calls,
                beforeEnter: (to, from, next) => {
                    
                    if(ability.can('view','call')) {
                        return next()
                    } else {
                        next('/dashboard/access_denied')
                    }
                }
            },
            {
                path: '/dashboard/access_denied',
                name: 'Access Denied Dashboard',
                component: Access_denied
            },
        ]
    },
    {
        path: '/incidences',
        name: 'Incidences',
        component: Incidences,
        meta: {
            requiresAuth: 'Incidences', 
        },
    },
    {
        path: '/record_book',
        name: 'Daily Log',
        component: Daily_log,
        meta: {
            requiresAuth: 'Records', 
        },
    },
    {
        path: '/record_book_summary',
        name: 'Daily Log Summary',
        component: Daily_log_summary,
        meta: {
            requiresAuth: 'Records', 
        },
    },
    {
        path: '/operation_diary',
        name: 'Operation Diary',
        component: Operation_diary
    },
    {
        path: '/operation_diary_summary',
        name: 'Operation Diary Summary',
        component: Operation_diary_summary
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        props: true,
        children: [
            {
                path: '/maintenance/tasks_board',
                name: 'Tasks_board',
                component: Tasks_board,
                props: true
            },
            {
                path: '/maintenance/tasks_calendar',
                name: 'Tasks_calendar',
                component: Tasks_calendar,
                props: true
            },
            {
                path: '/maintenance/tasks_budgets',
                name: 'Tasks_budgets',
                component: Tasks_budgets,
                props: true
            },
            {
                path: '/maintenance/tasks_periodicity',
                name: 'Tasks_periodicity',
                component: Tasks_periodicity,
                props: true
            },
            
        ]
    },
    {
        path: '/billing',
        name: 'Billing',
        component: Billing,
        props: true,
        meta: {
            requiresAuth: 'Billing', 
        },
        children: [
            {
                path: '/billing/dashboard',
                name: 'Billing_dashboard',
                props: true,
                meta: {
                    requiresAuth: 'Billing', 
                },
                component: Billing_dashboard
            },
            {
                path: '/billing/settlement',
                name: 'Billing_settlement',
                props: true,
                meta: {
                    requiresAuth: 'Billing', 
                },
                component: Billing_settlement
            },
            {
                path: '/billing/accounting_validated',
                name: 'Billing_accounting_validated',
                props: true,
                meta: {
                    requiresAuth: 'Billing', 
                },
                component: Billing_accounting_validated
            },
            {
                path: '/billing/accounting_accounted',
                name: 'Billing_accounting_accounted',
                props: true,
                meta: {
                    requiresAuth: 'Billing', 
                },
                component: Billing_accounting_accounted
            },
            {
                path: '/billing/steps',
                name: 'Billing_steps',
                props: true,
                component: Billing_steps,
                meta: {
                    requiresAuth: 'Billing', 
                },
                beforeEnter: (to, from, next) => {
                    if(ability.can('view','bill_group') ) {
                        return next()
                    } else {
                        next('/billing/access_denied')
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'Billing_supplier_selection',
                        component: Billing_supplier_selection,
                        meta: {
                            requiresAuth: 'Billing', 
                        },
                        beforeEnter: (to, from, next) => {
                            if(ability.can('view','bill_group') ) {
                                return next()
                            } else {
                                next('/billing/access_denied')
                            }
                        }
                    },
                    {
                        path: '/billing/bill_selection',
                        name: 'Billing_bill_selection',
                        component: Billing_bill_selection,
                        meta: {
                            requiresAuth: 'Billing', 
                        },
                        beforeEnter: (to, from, next) => {
                            if(ability.can('view','bill_group') ) {
                                return next()
                            } else {
                                next('/billing/access_denied')
                            }
                        }
                    },
                    {
                        path: '/billing/bill_information',
                        name: 'Billing_information',
                        component: Billing_information,
                        meta: {
                            requiresAuth: 'Billing', 
                        },
                        beforeEnter: (to, from, next) => {
                            if(ability.can('view','bill_group') ) {
                                return next()
                            } else {
                                next('/billing/access_denied')
                            }
                        }
                    },
                    {
                        path: '/billing/bill_confirmation',
                        name: 'Billing_confirmation',
                        component: Billing_confirmation,
                        meta: {
                            requiresAuth: 'Billing', 
                        },
                        beforeEnter: (to, from, next) => {
                            if(ability.can('view','bill_group') ) {
                                return next()
                            } else {
                                next('/billing/access_denied')
                            }
                        }
                    },
                    {
                        path: '/billing/access_denied',
                        name: 'Access Denied Dashboard',
                        component: Access_denied
                    },
                ]
            },
            {
                path: '/billing/payment_proposal',
                name: 'Payment_proposal',
                props: true,
                component: Payment_proposal,
                meta: {
                    requiresAuth: 'Billing', 
                },
                beforeEnter: (to, from, next) => {
                    if(ability.can('view','bill_group') ) {
                        return next()
                    } else {
                        next('/billing/access_denied')
                    }
                }
            },
            {
                path: '/billing/payment_proposal_creation',
                name: 'Payment_proposal_creation',
                props: true,
                component: Payment_proposal_creation,
                meta: {
                    requiresAuth: 'Billing', 
                },
                beforeEnter: (to, from, next) => {
                    if(ability.can('view','bill_group') ) {
                        return next()
                    } else {
                        next('/billing/access_denied')
                    }
                }
            },
            {
                path: '/billing/payment_proposal_validation/:id',
                name: 'Payment_proposal_validation',
                props: true,
                component: Payment_proposal_validation,
                meta: {
                    requiresAuth: 'Billing', 
                },
                beforeEnter: (to, from, next) => {
                    if(ability.can('view','bill_group') ) {
                        return next()
                    } else {
                        next('/billing/access_denied')
                    }
                }
            },
            {
                path: '/billing/access_denied',
                name: 'Access Denied Dashboard',
                component: Access_denied
            },
        ]
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: Statistics,
        children: [
            {
                path: '/statistics/overview',
                name: 'Overview',
                component: Overview,
            },
            {
                path: '/statistics/graphics',
                name: 'Graphics',
                component: Graphics,
            },
            {
                path: '/statistics/services',
                name: 'Services',
                component: Services,
            }
        ]
    },
    {
        path: '/dashboard/call/:id',
        name: 'Service_sheet_menu',
        component: Service_sheet_menu,
        meta: {
            requiresAuth: 'Calls', 
        },
        children: [
            {
                path: 'servicesheet/:sid',
                name: 'Service_sheet_template',
                component: Service_sheet_template,
                meta: {
                    swap: false
                }
            },
            {
                path: 'log/:sid',
                name: 'Service_sheet_log',
                component: Service_sheet_log,
                meta: {
                    swap: true
                }
            }
        ]
    },
    {
        path: '/change_log',
        name: 'Change Log',
        component: Change_log
    },
    {
        path: '/add_change_log_record',
        name: 'Change_log_record',
        component: Change_log_record
    },
    {
        path: '/permissions',
        name: 'Permissions',
        component: Permissions,
        beforeResolve: async (to, from, next) => {
            if(ability.can('view','permission') ) {
                return next()
            } else {
                next('/access_denied')
            }
        }
    },
    {
        path: '/permissions/group/steps',
        name: 'Group_steps',
        component: Group_steps,
        children: [
            {
                path: '',
                name: 'Group_information',
                component: Group_information
            },
            {
                path: '/permissions/group/users',
                name: 'Group_users',
                component: Group_users
            },
            {
                path: '/permissions/group/roles',
                name: 'Group_permissions',
                component: Group_permissions
            },
            {
                path: '/permissions/group/confirmation',
                name: 'Group_confirmation',
                component: Group_confirmation
            },
        ]
    },
    {
        path: '/access_denied',
        name: 'Access Denied',
        component: Access_denied
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/home'
    }
]

const router = createRouter({
    scrollBehavior () {
        return { top: 0 }
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) =>  {

    const loggedIn = localStorage.getItem('token')

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!loggedIn) {
            next({ name: 'Login' })
        } else {
            await store.dispatch('get_current_user')
            //await store.dispatch('load_perms')
            ability.update(store.getters.get_perms)
            
            for (let record of to.matched) {
                if(record.meta.requiresAuth) {

                    for(var item in store.state.users.current_user) {
                        if(item == 'menu_bar_items') {
                            for(var index in store.state.users.current_user[item]) {
                                if(typeof(record.meta.requiresAuth) == 'string') {
                                    if(record.meta.requiresAuth == store.state.users.current_user[item][index]) {
                                        return next()
                                    }
                                }
                            }
                            next({ name: 'Access Denied' })
                        }
                    }
                }
            }
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

export default router
