import axios from 'axios'

const state = {
    cruise_lines: [],
    cruise_line_errors:{},
    delete_status:false,
    cruise_line: {}
};

const mutations = {
    SET_CRUISE_LINES(state, cruise_lines) {
        state.cruise_lines = cruise_lines
    },
    SET_CRUISE_LINE(state, cruise_line){
        state.cruise_line = cruise_line
    },
    NEW_CRUISE_LINE(state, cruise_lines) {  
        state.cruise_lines.push(cruise_lines)
        state.cruise_line_errors={}
    },
    DELETE_CRUISE_LINE(state, id) {
        let index = state.cruise_lines.findIndex(cruise_lines => cruise_lines.id == id)
        state.cruise_lines.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_CRUISE_LINE(state, cruise_line) {
        state.cruise_lines = state.cruise_lines.map(updated_cruise_line => {
            if(updated_cruise_line.id === cruise_line.id) {
                updated_cruise_line.name = cruise_line.name
                updated_cruise_line.id_cruise_group = cruise_line.id_cruise_group 
            }
            return updated_cruise_line
        })
        state.cruise_line_errors={}
    },
    ERRORS_CRUISE_LINE(state, errors){
        state.cruise_line_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};
const actions = {
    async load_all_cruise_lines({commit}) {        
        axios.get(process.env.VUE_APP_API_URL  + "cruise_lines/")
            .then((response) => {
                commit('SET_CRUISE_LINES', response.data)
            })
    },
    async load_cruise_line({commit}, id) {
        axios.get(process.env.VUE_APP_API_URL  + "cruise_lines/" + id)
            .then((response) => {
                commit('SET_CRUISE_LINE', response.data)
            })
    },
    async delete_cruise_line({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL  + 'cruise_lines/' + id)
            .then(() => {
                commit('DELETE_CRUISE_LINE', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
    }, 
    async create_cruise_line({commit}, cruise_line) {
        await axios.post(process.env.VUE_APP_API_URL  + 'cruise_lines/', { name: cruise_line.name, id_cruise_group: cruise_line.id_group.id })
            .then((response) => {
                commit('NEW_CRUISE_LINE', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_CRUISE_LINE', error.response.data)
            })
    },
    async update_cruise_line({commit}, cruise_line) {
        await axios.put(process.env.VUE_APP_API_URL  + 'cruise_lines/' + cruise_line.id + '/',  { name: cruise_line.name, id_cruise_group: cruise_line.id_cruise_group.id })

            .then(() => {
                commit('UPDATE_CRUISE_LINE', cruise_line)
            })
            .catch((error) => {
                
                commit('ERRORS_CRUISE_LINE', error.response.data)
            })
    },
};

const getters = {
    all_cruise_lines: (state) => state.cruise_lines,
    get_errors_cruise_line: (state) => state.cruise_line_errors,
    get_delete_cruise_line_status:(state) => state.delete_status,
    get_cruise_line: (state) => (id) => {
        return state.cruise_lines.find(cruise_line => cruise_line.id === id)
    },
    get_one_cruise_line: (state) => state.cruise_line
};

export default {
    state,
    mutations,
    actions,
    getters
};