import axios from 'axios'
import moment from 'moment'

const state = {
    single_incidence_report: [],
    incidence_reports: [],
    service_sheet_incidences: [],
    incidence_between_dates: [],
    incidence_report_errors:{},
    delete_status : false,
    service_sheets_filtered: [],
};

const mutations = {
    SET_SINLGE_INCIDENCE_REPORTS(state, incidence_report){
        state.single_incidence_report = incidence_report
    },
    SET_INCIDENCE_REPORTS(state, incidence_reports) {
        state.incidence_reports = incidence_reports
        
        // state.incidence_reports.forEach(incidence_report => incidence_report.date = incidence_report.date)
    },
    SET_INCIDENCE_BY_SS(state, incidence_reports) {
        state.service_sheet_incidences = incidence_reports
    },
    SET_INCIDENCE_BETWEEN_DATES(state, incidence_between_dates) {
        state.incidence_between_dates = incidence_between_dates
    },
    NEW_INCIDENCE_REPORT(state, incidence_reports) {  
        incidence_reports.date = new Date(incidence_reports.date)
        state.incidence_reports.unshift(incidence_reports)
        state.incidence_between_dates.unshift(incidence_reports)
        state.incidence_report_errors = {}
    },
    DELETE_INCIDENCE_REPORT(state, id) {
        let index = state.incidence_reports.findIndex(incidence_reports => incidence_reports.id == id)
        state.incidence_reports.splice(index, 1)
        let index_filter = state.incidence_between_dates.findIndex(incidence_reports => incidence_reports.id == id)
        state.incidence_between_dates.splice(index_filter, 1)
        state.delete_status = false
    },
    UPDATE_INCIDENCE_REPORT(state, incidence_report) {
        state.incidence_reports = state.incidence_reports.map(updated_incidence_report => {
            if(updated_incidence_report.id === incidence_report.id) {
                updated_incidence_report.title = incidence_report.title
                updated_incidence_report.text = incidence_report.text
                updated_incidence_report.photo = incidence_report.photo
                updated_incidence_report.incidence_type = incidence_report.incidence_type
                updated_incidence_report.hour = incidence_report.hour
                updated_incidence_report.date = new Date(incidence_report.date)
                updated_incidence_report.id_terminal = incidence_report.id_terminal
                updated_incidence_report.id_incidence_category = incidence_report.id_incidence_category
                updated_incidence_report.id_incidence_subcategory = incidence_report.id_incidence_subcategory
                updated_incidence_report.id_incidence_zone = incidence_report.id_incidence_zone
                updated_incidence_report.id_call = incidence_report.id_call
                updated_incidence_report.id_service_sheet = incidence_report.id_service_sheet
            }
            return updated_incidence_report
        })

        state.incidence_between_dates = state.incidence_between_dates.map(updated_incidence_report => {
            if(updated_incidence_report.id === incidence_report.id) {
                updated_incidence_report.title = incidence_report.title
                updated_incidence_report.text = incidence_report.text
                updated_incidence_report.photo = incidence_report.photo
                updated_incidence_report.incidence_type = incidence_report.incidence_type
                updated_incidence_report.hour = incidence_report.hour
                updated_incidence_report.date = new Date(incidence_report.date)
                updated_incidence_report.id_terminal = incidence_report.id_terminal
                updated_incidence_report.id_incidence_category = incidence_report.id_incidence_category
                updated_incidence_report.id_incidence_subcategory = incidence_report.id_incidence_subcategory
                updated_incidence_report.id_incidence_zone = incidence_report.id_incidence_zone
                updated_incidence_report.id_call = incidence_report.id_call
                updated_incidence_report.id_service_sheet = incidence_report.id_service_sheet
            }
            return updated_incidence_report
        })
        state.incidence_report_errors={}
    },
    ERRORS_INCIDENCE_REPORT(state, errors){
        state.incidence_report_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    },
    SET_SERVICE_SHEETS_FILTERED(state, payload){
        state.service_sheets_filtered = payload
    }
};

const actions = {
    async load_single_incidence({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL  + "incidence_reports/" + id + '/')
            .then((response) => {
                commit('SET_SINLGE_INCIDENCE_REPORTS', response.data)
            })
    },
    async load_all_incidence_reports({commit}) {        
        await axios.get(process.env.VUE_APP_API_URL  + "incidence_reports/")
            .then((response) => {
                commit('SET_INCIDENCE_REPORTS', response.data)
            })
    },
    async load_incidence_by_service_sheet({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'incidence_reports/get_by_ss/' + id)
            .then((response) => {
                commit('SET_INCIDENCE_BY_SS', response.data)
            })
    },
    async load_incidences_between_dates({commit}, data){
        await axios.get(process.env.VUE_APP_API_URL + 'incidence_reports/get_between_dates/?start_date=' + moment(data[0]).format('YYYY-MM-DD') + '&end_date=' + moment(data[1]).format('YYYY-MM-DD') )
            .then((response) => {
                commit('SET_INCIDENCE_BETWEEN_DATES', response.data)
            })
    },
    async delete_incidence_report({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'incidence_reports/' + id)
            .then(() => {
                commit('DELETE_INCIDENCE_REPORT', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
                state.delete_status = true
            })
            
    }, 
    async create_incidence_report({commit}, incidence_report) {
        incidence_report.date = moment(incidence_report.date).format('YYYY-MM-DD')
        
        if(typeof incidence_report.hour != 'string') {
            incidence_report.hour = ((incidence_report.hour.toISOString().split('T')[1]).split('+')[0]).split(':')[0] + ':' + ((incidence_report.hour.toISOString().split('T')[1]).split('+')[0]).split(':')[1]
        }

        if(typeof incidence_report.photo.name != 'string'){
            delete incidence_report['photo']
        }
        if (incidence_report.id_incidence_zone !== null){
            incidence_report.id_incidence_zone = incidence_report.id_incidence_zone.id
        }
        if (incidence_report.id_incidence_category !== null){
            incidence_report.id_incidence_category = incidence_report.id_incidence_category.id
        }
        if (incidence_report.id_incidence_subcategory !== null){
            incidence_report.id_incidence_subcategory = incidence_report.id_incidence_subcategory.id
        }
        if (incidence_report.id_terminal !== null){
            incidence_report.id_terminal = incidence_report.id_terminal.id
        }

        var formData = new FormData();
        for(var item in incidence_report){
            if(incidence_report[item] == null) {
                incidence_report[item] = ''
            }
            if(item == 'id_user_responsible') {
                if(incidence_report[item] == '') {
                    delete incidence_report[item] 
                }
            } else {
                formData.append(item, incidence_report[item])
            }
            
        }
        
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
        
        await axios.post(process.env.VUE_APP_API_URL  + 'incidence_reports/', formData, headers)
            .then((response) => {
                commit('NEW_INCIDENCE_REPORT', response.data)
            })
            .catch((error) => {
                commit('ERRORS_INCIDENCE_REPORT', error.response.data)
            })
    },
    async update_incidence_report({commit}, incidence_report) {
        let data = {}

        incidence_report.date = (moment(incidence_report.date).format().split('T')[0])
        // Recibir la hora como objeto Date y cambiarle el formate a 'hh:mm'
        if(incidence_report.hour instanceof Date){
            incidence_report.hour = (incidence_report.hour.toLocaleString().split(',')[1]).split(':')[0] + ':' + (incidence_report.hour.toLocaleString().split(',')[1]).split(':')[1]
        }
        
        if (incidence_report.id_service_sheet !== null) {
            if(incidence_report.id_user_responsible == null) {
                data = { title: incidence_report.title, photo:incidence_report.photo,text: incidence_report.text, incidence_type:incidence_report.incidence_type, date: incidence_report.date, hour: incidence_report.hour, id_incidence_zone: '', id_incidence_category: '', id_incidence_subcategory: '', id_terminal: incidence_report.id_terminal.id, id_service_sheet: incidence_report.id_service_sheet.id }
            } else {
                data = { title: incidence_report.title, photo:incidence_report.photo,text: incidence_report.text, incidence_type:incidence_report.incidence_type, date: incidence_report.date, hour: incidence_report.hour, id_incidence_zone: '', id_incidence_category: '', id_incidence_subcategory: '', id_terminal: incidence_report.id_terminal.id, id_service_sheet: incidence_report.id_service_sheet.id, id_user_responsible: incidence_report.id_user_responsible.id }
            }
        } else if (incidence_report.id_service_sheet === null) {
            if(incidence_report.id_user_responsible == null) {
                data = { title: incidence_report.title, photo:incidence_report.photo,text: incidence_report.text, incidence_type:incidence_report.incidence_type, date: incidence_report.date, hour: incidence_report.hour, id_incidence_zone: '', id_incidence_category: '', id_incidence_subcategory: '', id_terminal: incidence_report.id_terminal.id, id_service_sheet: '' }
            } else {
                data = { title: incidence_report.title, photo:incidence_report.photo,text: incidence_report.text, incidence_type:incidence_report.incidence_type, date: incidence_report.date, hour: incidence_report.hour, id_incidence_zone: '', id_incidence_category: '', id_incidence_subcategory: '', id_terminal: incidence_report.id_terminal.id, id_service_sheet: '', id_user_responsible: incidence_report.id_user_responsible.id }
            }
        }

        if(data.photo == null) {
            delete data['photo']
        } else if(typeof data.photo.name != 'string'){
            delete data['photo']
        }

        if (incidence_report.id_incidence_zone !== null){
            data['id_incidence_zone'] = incidence_report.id_incidence_zone.id
        }
        if (incidence_report.id_incidence_category !== null){
            data['id_incidence_category'] = incidence_report.id_incidence_category.id
        }
        if (incidence_report.id_incidence_subcategory !== null){
            data['id_incidence_subcategory'] = incidence_report.id_incidence_subcategory.id
        }
        /*
        if (incidence_report.id_call !== null){
            data['id_call'] = incidence_report.id_call.id
        }
        */
        var formData = new FormData();
        
        for(var item in data){
            formData.append(item, data[item]) 
        }
        
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }

        await axios.put(process.env.VUE_APP_API_URL  + 'incidence_reports/' + incidence_report.id + '/',  formData, headers)
            .then((response) => {
                commit('UPDATE_INCIDENCE_REPORT', response.data)
            })
            .catch((error) => {
                commit('ERRORS_INCIDENCE_REPORT', error)
            })
    },
    async get_service_sheets_by_date_terminal({commit}, data) {
        await axios.get(process.env.VUE_APP_API_URL  + 'service_sheets/get_by_date_terminal/' + moment(data.date).format('yyyy-MM-DD') + '/' + data.id_terminal + '/')
            .then((response) => {
                commit('SET_SERVICE_SHEETS_FILTERED', response.data)
            })
    }

};

const getters = {
    single_incidence_report: (state) => state.single_incidence_report,
    all_incidence_reports: (state) => state.incidence_reports,
    service_sheet_incidences: (state) => state.service_sheet_incidences,
    get_errors_incidence_report: (state) => state.incidence_report_errors,
    get_delete_incidence_report_status:(state) => state.delete_status,
    get_general_incidences(state) {
        return state.incidence_between_dates.filter(incidence_report => incidence_report.incidence_type == 'General')
    },
    get_call_incidences(state) {
        return state.incidence_between_dates.filter(incidence_report => incidence_report.incidence_type == 'Service Sheet')
    },
    get_service_sheets_filtered: (state) => state.service_sheets_filtered,
};

export default {
    state,
    mutations,
    actions,
    getters
};