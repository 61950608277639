<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div v-if="$can('view','currency')" class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Currency </h1>
            <div v-if="$can('add','currency')" class="card p-1">
                <Button data-cy="new_currency_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Currency'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_currency_modal" header="New Currency" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <form id="form_new_currency" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                <div class="container">
                    <div class="row justify-content-center pt-3">
                        <div class="col-10 md:col-12">
                            <div class="p-float-label">
                                <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted || currency_errors.name}" />
                                <label for="name" :class="{'p-error':v$.name.$invalid && submitted}"> Name* </label>
                                <small v-if="Object.keys(currency_errors).length !== 0 && currency_errors.name" class="p-error"><span v-for="error in currency_errors.name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
                        </div>        
                    </div>
                    <div class="row justify-content-center pt-3">
                        <div class="col-10 md:col-12">
                            <div class="p-float-label">
                                <InputText id="abbreviation" v-model="abbreviation"/>
                                <label for="abbreviation"> Abbreviation </label>
                                <small v-if="Object.keys(currency_errors).length !== 0 && currency_errors.abbreviation" class="p-error"><span v-for="error in currency_errors.abbreviation" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>        
                    </div>
                    <div class="row justify-content-center pt-3">
                        <div class="col-10 md:col-12">
                            <div class="p-float-label">
                                <InputText id="symbol" v-model="symbol" :class="{'p-invalid':v$.symbol.$invalid && submitted || currency_errors.symbol}"/>
                                <label for="symbol" :class="{'p-error':v$.symbol.$invalid && submitted}"> Symbol* </label>
                                <small v-if="Object.keys(currency_errors).length !== 0 && currency_errors.symbol" class="p-error"><span v-for="error in currency_errors.symbol" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.symbol.$invalid && submitted) || v$.symbol.$pending.$response" class="p-error">{{v$.symbol.required.$message.replace('Value', 'Symbol')}}</small>
                        </div>        
                    </div>
                </div>
            </form>
            <template #footer>
                <Button data-cy="create_currency_button" form="form_new_currency" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        Toast,
    },
    data() {
        return {
            displayModal: false,
            name: '',
            abbreviation: '',
            symbol: '',
            currency_errors:{},
            submitted: false,
            showMessage: false,
        }
    },
    computed: {
        get_currency_errors: function() {
            return this.$store.getters.get_currency_errors
        },
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_currency() {
            let currency = { 
                name: this.name,
                abbreviation: this.abbreviation,
                symbol: this.symbol,
            }
            await this.$store.dispatch('create_currency', currency)
            this.currency_errors = this.get_currency_errors
            if(Object.keys(this.currency_errors).length === 0){
                this.toggleDialog();
                this.name = ''
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            
            this.submitted = true;
       
            if (!isFormValid) {
                return;
            }

            let res = await this.add_currency()
            if (res) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Currency added', life: 3000});
            } 
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.name = '';
            this.abbreviation = '',
            this.symbol = '',
            this.submitted = false;
        }
    },
    validations() {
        return {
            name: { required },
            symbol: { required },
        }
    },
};
</script>

<style scoped>
</style>