<template>
    <div class="container-fluid">
        <div class="grid" style="height: 100%;">
            <div v-if="this.$route.path.includes('/calendar')" class="side_bar_custom">
                <Menu :model="items"/>
                <!--
                <Button v-if="$ability.can('add','call') && this.$route.path == '/dashboard/calendar'" style="font-size: 1.8rem;" class="p-button-text p-button-raised p-button-success" label="Create Call"  @click="$router.push('Table_calendar')">
                </Button>
                -->
            </div> 
            <div v-else class="side_bar_custom_full">
                <Menu :model="items"/>
            </div>
            <div v-if="this.$route.path == '/dashboard/calendar'" class="bottom_side_bar">
                <Menu :model="legend"></Menu>
                <h6 class="ms-3 mt-4"> <strong class="ms-2">**</strong> <span class="ms-2"> First Call </span> </h6>       
                <h6 class="ms-3 mt-4"> <i class="fa fa-square ms-2" style="color: #c7c7c7;"></i> <span class="ms-2"> Foresight </span> </h6>
                <h6 class="ms-3 mt-4"> <i class="fa fa-square ms-2" style="color: #002D72;"></i> <span class="ms-2"> Confirmed </span> </h6> 
                <h6 class="ms-3 mt-4"> <i class="fa fa-square ms-2" style="color: #0eb583;"></i> <span class="ms-2"> Validated </span> </h6> 
                <h6 class="ms-3 mt-4"> <i class="fa fa-square ms-2" style="color: #9dc4f5;"></i> <span class="ms-2"> Unconventional Ship </span> </h6> 
                <h6 class="ms-3 mt-4 mb-3"> <i class="fa fa-square ms-2" style="color: #2181b5;"></i> <span class="ms-2"> Unconventional Ship Validated</span> </h6> 
                
            </div>
            <div class="col main_table">
                <div class="container-fluid" >
                    <router-view />
                </div>
            </div>
        </div>
        <div class="menu-mobile justify-content-between">
            <div class="col">
                <Button v-if="this.$route.path.includes('/calendar')" icon="fa fa-info" class="sidebar-button-info p-button-raised p-button-rounded color-button-menu" style="color: white;" @click="toggle"/>
                <OverlayPanel ref="op">
                    <Menu :model="legend"></Menu>
                    <h6 class="ms-3 mt-4 me-5"> <strong class="ms-2">**</strong> <span class="ms-2"> First Call </span> </h6>       
                    <h6 class="ms-3 mt-4 me-5"> <i class="fa fa-square ms-2" style="color: #c7c7c7;"></i> <span class="ms-2"> Foresight </span> </h6>
                    <h6 class="ms-3 mt-4 me-5"> <i class="fa fa-square ms-2" style="color: #002D72;"></i> <span class="ms-2"> Confirmed </span> </h6> 
                    <h6 class="ms-3 mt-4 me-5"> <i class="fa fa-square ms-2" style="color: #0eb583;"></i> <span class="ms-2"> Validated </span> </h6> 
                    <h6 class="ms-3 mt-4 me-5"> <i class="fa fa-square ms-2" style="color: #9dc4f5;"></i> <span class="ms-2"> Unconventional Ship </span> </h6> 
                    <h6 class="ms-3 mt-4 mb-3 me-5"> <i class="fa fa-square ms-2" style="color: #2181b5;"></i> <span class="ms-2"> Unconventional Ship Validated</span> </h6> 
                </OverlayPanel>
            </div>
            <div class="col">
                <Button icon="fa fa-bars" class="sidebar-button p-button-raised p-button-rounded color-button-menu" style="color: white;" @click="visibleRight = true"/>
            </div>
        </div>
    </div>

    <Sidebar v-model:visible="visibleRight" position="left">
        <Menu :model="items"/>    
    </Sidebar>
</template>

<script>
import Menu from 'primevue/menu'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import OverlayPanel from 'primevue/overlaypanel';

import ability from '../services/ability'

export default {
    name: "Dashboard",
    components: {
        Menu,
        Button,
        Sidebar,
        OverlayPanel
    },
    data(){
        return {
            color: localStorage.getItem('primary_color'),
            opacity_color: '',
            visibleRight: false,
            legend: [
                {
                    label: 'LEGEND',
                    items: [
                    ]
                },

            ],
            items: [
                {
                    label: 'CALLS',
                    items: [
                        {
                            label: 'Calendar',
                            to: '/dashboard/calendar',
                            command: () => {
                                this.visibleRight = false
                            },
                            visible: () =>  {
                                if(ability.can('view', 'call')) {
                                    return true
                                }
                            },
                        },
                        {
                            label: 'Manage',
                            to: '/dashboard/table_calendar',
                            command: () => {
                                this.visibleRight = false
                            },
                            visible: () =>  {
                                if(ability.can('add', 'call')) {
                                    return true
                                }
                            },
                        },
                        {
                            label: 'Pending calls',
                            to: '/dashboard/validation_panel',
                            command: () => {
                                this.visibleRight = false
                            },
                            visible: () =>  {
                                if(ability.can('validate', 'service_sheet')) {
                                    return true
                                }
                            },
                        },
                        {
                            label: 'Signatures',
                            to: '/dashboard/signatures',
                            command: () => {
                                this.visibleRight = false
                            },
                            visible: () =>  {
                                if(ability.can('sign', 'service_sheet') || ability.can('view', 'service_sheet_pdfs')) {
                                    return true
                                }
                            },
                        },
                        {
                            label: 'Calls',
                            to: '/dashboard/calls',
                            command: () => {
                                this.visibleRight = false
                            },
                            visible: () =>  {
                                if(ability.can('view', 'call')) {
                                    return true
                                }
                            },
                        },
                    ],
                },
            ],
            
        }
    },
    computed: {
        get_current_user: function() {
            return this.$store.getters.get_current_user
        }
    },
    mounted() {
        if(this.color == '#6E3FA3') {
            this.opacity_color = 'rgba(108, 67, 154, .3)'
        } else if (this.color == '#0097A9') {
            this.opacity_color = 'rgba(0, 151, 169, .3)'
        }
    },
    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        }
    }

}

</script>

<style scoped>

.side_bar_custom{
    position: fixed;
    width: 300px;
    height: calc(100vh - 34rem);
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: 9rem;
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.side_bar_custom_full{
    position: fixed;
    width: 300px;
    height: calc(100vh - 11rem);
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: 9rem;
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.bottom_side_bar{
    position: fixed;
    width: 300px;
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: calc(100vh - 24rem);
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}


.main_table {
    margin-left: 300px;
    flex-direction: column;
    min-height: calc(100vh - 9rem);
    justify-content: space-between;
    padding-left: 3rem;
    transition: margin-left 0.2s;
    width: 50vw;
}


@media (min-width: 1300px) {
    .menu-mobile {
        display: none;

    }

    :deep(.p-scrollpanel .p-scrollpanel-bar) {
        background-color: #a8a8a8;
        transition: #a8a8a8 .2s;
    }

    :deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
        background-color: #a8a8a8;
    }
}

@media (max-width: 1301px) {
    .side_bar_custom {
        display: none;
    }

    .side_bar_custom_full {
        display: none;
    }

    .bottom_side_bar {
        display: none;
    }

    .button_new_call {
        display: none;
    }
    .main_table{
        margin-left: 0px !important;
        padding-left: .5rem !important;
    }
}

:deep(.p-scrollpanel .p-scrollpanel-bar) {
    background-color: #a8a8a8;
    transition: #a8a8a8 .2s;
}    

:deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
    background-color: #a8a8a8;
}

.sidebar-button {
    border-radius: 10px;
    left: 82vw !important;
    top: 90% !important;
    position: fixed !important;
    z-index: 1000;
}

.sidebar-button-info {
    border-radius: 10px;
    right: 82vw !important;
    top: 90% !important;
    position: fixed !important;
    z-index: 1000;
}

:deep(.p-menu) {
    border-radius: 0px;
    background: rgba(0,0,0,0); 
    padding: 0;
}

:deep(.p-menu .p-menuitem-link) {
    margin-left: 0px;
}


.scroll_menu{
    background-color: v-bind(opacity_color);
}

:deep(.p-scrollpanel-content){
    padding: 0;

}

:deep(.p-menu .p-submenu-header){
    background-color: rgba(0,0,0,0); 
    border-radius: 6px;
}

/*
:deep(.bm-item-list) {
    margin-left: 0px !important;
}

:deep(.bm-menu) {
    background-color: #eaeaea;
    box-shadow: 0 9px 10px -1px rgba(0,0,0,.2), 0 9px 10px 0 rgba(0,0,0,.14), 0 4px 5px 0 rgba(0,0,0,.12);
}
*/

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
}

.color-button-menu{
    color: white;
    background-color: v-bind(color);
    border-color: v-bind(color);
}

.color-button-menu:hover{
    background-color: v-bind(color) !important;
    border-color: v-bind(color) !important; 
    opacity: .8;
}

:deep(.router-link-active .p-menuitem-text){
    color: v-bind(color) !important;
    font-weight: 700;
}

:deep(.router-link-active .p-menuitem-icon){
    color: v-bind(color) !important;
}

:deep(.p-menu .p-menuitem-link:not(.p-disabled):hover){
    border-radius: 10px;
}

.sidebar_color{
    background-color: red !important;
}

:deep(.p-scrollpanel-bar-x){
    height: 0px;
}

.test_color{
    background-color:red !important;
}

</style>