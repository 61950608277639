<template>
    <ModalCurrency/>
    <TableCurrency/> 
</template>

<script>
import TableCurrency from '@/components/AdminDashboard/Currency/TableCurrency.vue'
import ModalCurrency from '@/components/AdminDashboard/Currency/ModalCurrency.vue'
export default {
  name: 'Cruise_lines',
  components: {
    TableCurrency,
    ModalCurrency
  }
}
</script>

<style scoped>
</style>