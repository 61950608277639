<template>
    <div class="grid mt-5">
        <div v-for="(stat, index) in statistics" :key="stat" class="col-12 md:col-4 mt-2">
            <div class="container">
                <div class="card flex justify-content-center mt-2 color_opacity">
                    <div class="col-12 text-center">
                        <h5 style="margin-bottom: 0px; "> Total <strong>{{index}}</strong></h5>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="container mt-3 mb-3">
                        <div v-for="(value, key, number) in stat" :key="key" class="row">
                            <div class="col-2 text-center">
                                <i :class="icon_items[number].icon"></i>
                            </div>
                            <div class="col">
                                <small style="color: gray;"> {{format_upperkey(key)}} </small>
                            </div>
                            <div class="col text-end">
                                <strong>{{value}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            opacity_color: '',
            icon_items: [
                {'icon': 'fa fa-book'},
                {'icon': 'fa-solid fa-person-walking-arrow-right'},
                {'icon': 'fa-solid fa-person-walking-arrow-right fa-flip-horizontal'},
                {'icon': 'fa-solid fa-people-arrows'},
                {'icon': 'fa-solid fa-ship'},
                {'icon': 'fa-solid fa-people-group'},
                {'icon': 'fa-solid fa-droplet'},
            ]
        }
    },
    props: {
        statistics: {
            type: Object
        }
    },
    mounted() {
        if(this.color == '#6E3FA3') {
            this.opacity_color = 'rgba(108, 67, 154, .3)'
        } else if (this.color == '#0097A9') {
            this.opacity_color = 'rgba(0, 151, 169, .3)'
        }
    },
    methods: {
        format_title(string) {
            return string.toUpperCase();
        },
        format_upperkey(string){
            return string.toUpperCase();
        }
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

.back_odd{
    background-color: white;
}

.color_opacity{
    background-color: v-bind(opacity_color);
}

</style>