<template>
    <div class="container-fluid">
        <div class="row" style="height: 100%">
            <div class="side_bar_custom_small">
                <Menu :model="items"/>
                <div class="side_bar_custom_bottom">
                    <Menu :model="filters_menu_terminal"/>
                    <div class="p-fluid ps-3 pe-3 pb-2">
                        <Dropdown :options="all_terminals" v-model="filter_terminal" optionLabel="name" showClear> </Dropdown>
                    </div>
                    <Menu :model="menu_status"/>
                    <div class="p-fluid ps-3 pe-3 pb-2">
                        <Dropdown :options="status" v-model="filter_status" showClear> </Dropdown>
                    </div>
                    <Menu :model="menu_calendar"/>
                    <div class="p-fluid ps-3 pe-3">
                        <Calendar v-model="filter_date" selectionMode="range" dateFormat="yy-mm-dd" class="input_small"></Calendar>
                    </div>
                    <Menu :model="download_button"/>
                    <div class="flex p-fluid ps-3 pe-3">
                        <Button label="Download Selected Calls" icon="fa fa-download" class="p-button-text text-center" @click="download_calls()"></Button>
                    </div>
                    <div class="flex p-fluid ps-3 pe-3">
                        <p class="p-error"> {{ error_filters }} </p>
                    </div>
                </div> 
            </div> 
            <div class="col main_table">
                <div class="container-fluid" >
                    <router-view />
                </div>
            </div>
        </div>
        <div class="menu-mobile justify-content-end">
            <div class="col">
                <Button icon="fa fa-bars" class="sidebar-button p-button-raised p-button-rounded color-button-menu" style="color: white;" @click="visibleRight = true"/>
            </div>
        </div>
    </div>

    <Sidebar v-model:visible="visibleRight" position="left">
        <Menu :model="items"/>
        <div class="side_bar_custom_small2">
            <div class="side_bar_custom_bottom2">
                <Menu :model="filters_menu_terminal"/>
                <div class="p-fluid ps-3 pe-3 pb-2">
                    <Dropdown :options="all_terminals" v-model="filter_terminal" optionLabel="name" showClear> </Dropdown>
                </div>
                <Menu :model="menu_status"/>
                <div class="p-fluid ps-3 pe-3 pb-2">
                    <Dropdown :options="status" v-model="filter_status" showClear> </Dropdown>
                </div>
                <Menu :model="menu_calendar"/>
                <div class="p-fluid ps-3 pe-3">
                    <Calendar v-model="filter_date" selectionMode="range" dateFormat="yy-mm-dd" class="input_small"></Calendar>
                </div>
                <Menu :model="download_button"/>
                <div class="flex p-fluid ps-3 pe-3">
                    <Button label="Download Selected Calls" icon="fa fa-download" class="p-button-text text-center" @click="download_calls()"></Button>
                </div>
                <div class="flex p-fluid ps-3 pe-3">
                    <p class="p-error"> {{ error_filters }} </p>
                </div>
            </div>
        </div>
    </Sidebar>
</template>

<script>
import Menu from 'primevue/menu'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import moment from 'moment'

export default {
    name: "Dashboard",
    components: {
        Menu,
        Button,
        Sidebar,
        Calendar,
        Dropdown
    },
    computed: {
        calls_by_range: function() {
            return this.$store.getters.calls_by_range
        },
        get_all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_port: function(){
            if(this.get_current_user.profile.id_port.name == 'Puerto de Málaga') {
                return true
            } else {
                return false
            }
        },
        check_user_porter_responsible: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Porters responsibles') {
                    return true
                }
            }
            return false
        },
    },
    methods: {
        async download_calls() {
            this.error_filters = '';
            if (this.filter_date[1] != null && this.filter_terminal != null && this.filter_status != null) {
                this.load_dataview = true;
                const requestData = {
                    filter_date: this.filter_date,
                    filter_terminal: this.filter_terminal,
                    filter_status: this.filter_status
                }
                await this.$store.dispatch('download_calls_between_dates', requestData)
                this.load_dataview = false
            } else {
                this.error_filters = 'Please fill up all the options.'
            }
        },
    },
    data(){
        return {
            all_terminals: [],
            status: ["Validated", "Signed", "All"],
            color: localStorage.getItem('primary_color'),
            opacity_color: '',
            visibleRight: false,
            filter_terminal: null,
            filter_status: null,
            filter_date: [new Date(moment().startOf('month').format('YYYY-MM-DD')), new Date(moment().endOf('month').format('YYYY-MM-DD'))],
            error_filters: '',
            items: [
                {
                    label: 'Statistics',
                    items: [
                        {
                            label: 'Overview',
                            to: '/statistics/overview',
                            command: () => {
                                this.visibleRight = false
                            },
                        },
                        {
                            label: 'Graphics',
                            to: '/statistics/graphics',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Services',
                            to: '/statistics/services',
                            visible: () => {
                                if(this.check_user_porter_responsible && this.check_user_port){
                                    return true
                                } else {
                                    return false
                                }
                            },
                            command: () => {
                                this.visibleRight = false
                            }
                        }
                    ]
                }
            ],
            filters_menu_terminal: [
                {
                    label: 'TERMINAL',
                    items: []
                }
            ],
            menu_status: [
                {
                    label: 'STATUS',
                    items: [],
                }
            ],
            menu_calendar: [
                {
                    label: 'DATE',
                    items: [],
                }
            ],
            download_button: [
                {
                    items: []
                }
            ]    
        }
    },
   async mounted() {
        await this.$store.dispatch('load_all_terminals')
        this.all_terminals = this.get_all_terminals
        this.all_terminals.unshift({ id: 'all', name: 'ALL TERMINALS' })
        if(this.color == '#6E3FA3') {
            this.opacity_color = 'rgba(108, 67, 154, .3)'
        } else if (this.color == '#0097A9') {
            this.opacity_color = 'rgba(0, 151, 169, .3)'
        }
    }
}

</script>

<style scoped>

.side_bar_custom_small {
    position: fixed;
    width: 300px;
    height: calc(100vh - 36rem);
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: 9rem;
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.side_bar_custom_bottom {
    position: fixed;
    width: 300px;
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: calc(100vh - 26rem);
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.side_bar_custom_bottom2 {
    position: fixed;
    width: 300px;
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: calc(100vh - 26rem);
    padding: 0.5rem 0.5rem;
    left: 0.5rem;
}

.side_bar_custom{
    position: fixed;
    width: 300px;
    height: calc(100vh - 11rem);
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: 9rem;
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.main_table {
    margin-left: 300px;
    flex-direction: column;
    min-height: calc(100vh - 9rem);
    justify-content: space-between;
    padding-left: 2rem;
    transition: margin-left 0.2s;
}

@media (min-width: 1300px) {
    .menu-mobile {
        display: none;

    }

    :deep(.p-scrollpanel .p-scrollpanel-bar) {
        background-color: #a8a8a8;
        transition: #a8a8a8 .2s;
    }

    :deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
        background-color: #a8a8a8;
    }
}

@media (max-width: 1301px) {
    .side_bar_custom_small {
        display: none;
    }
    .main_table{
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
}

:deep(.p-scrollpanel .p-scrollpanel-bar) {
    background-color: #a8a8a8;
    transition: #a8a8a8 .2s;
}    

:deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
    background-color: #a8a8a8;
}

.sidebar-button {
    border-radius: 10px;
    left: 82vw !important;
    top: 90% !important;
    position: fixed !important;
    z-index: 1000;
}

:deep(.p-menu) {
    border-radius: 0px;
    background: rgba(0,0,0,0); 
    padding: 0;
}

:deep(.p-menu .p-menuitem-link) {
    margin-left: 0px;
}


.scroll_menu{
    background-color: v-bind(opacity_color);
}

:deep(.p-scrollpanel-content){
    padding: 0;

}

:deep(.p-menu .p-submenu-header){
    background-color: rgba(0,0,0,0); 
    border-radius: 6px;
}

/*
:deep(.bm-item-list) {
    margin-left: 0px !important;
}

:deep(.bm-menu) {
    background-color: #eaeaea;
    box-shadow: 0 9px 10px -1px rgba(0,0,0,.2), 0 9px 10px 0 rgba(0,0,0,.14), 0 4px 5px 0 rgba(0,0,0,.12);
}
*/

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
}

.color-button-menu{
    color: white;
    background-color: v-bind(color);
    border-color: v-bind(color);
}

.color-button-menu:hover{
    background-color: v-bind(color) !important;
    border-color: v-bind(color) !important; 
    opacity: .8;
}

:deep(.router-link-active .p-menuitem-text){
    color: v-bind(color) !important;
    font-weight: 700;
}

:deep(.router-link-active .p-menuitem-icon){
    color: v-bind(color) !important;
}

:deep(.p-menu .p-menuitem-link:not(.p-disabled):hover){
    border-radius: 10px;
}

.sidebar_color{
    background-color: red !important;
}

:deep(.p-scrollpanel-bar-x){
    height: 0px;
}
</style>