import axios from 'axios'
import moment from 'moment';

const state = {
    k9 : {},
    k9_service: [],
    k9_service_type_options: [],
    send_k9_mail: false,
    error_k9_service: [],
    error_k9_service_type_options: []
}

const mutations = {
    SET_K9(state, k9) {
        state.k9 = k9
    },
    ADD_K9(state, k9) {
        state.k9.push(k9)
    },
    SET_K9_SERVICE(state, k9_service) {
        state.k9_service = k9_service
    },
    ADD_K9_SERVICE(state, k9_service) {
        k9_service.start_datetime_hour =  k9_service.start_datetime.split('T')[1].split(':')[0] + ':' +  k9_service.start_datetime.split('T')[1].split(':')[1]
        k9_service.start_datetime =  k9_service.start_datetime.split('T')[0]
        k9_service.end_datetime_hour =  k9_service.end_datetime.split('T')[1].split(':')[0] + ':' +  k9_service.end_datetime.split('T')[1].split(':')[1]
        k9_service.end_datetime =  k9_service.end_datetime.split('T')[0]
        state.k9_service.push(k9_service)
    },
    SET_K9_SERVICE_TYPE_OPTIONS(state, options){
        state.k9_service_type_options = options
    },
    ADD_K9_SERVICE_TYPE_OPTIONS(state, option) {
        state.k9_service_type_options.push(option)
    },
    SET_ERROR_K9_SERVICE_SHIFTS(state, error_k9_service_shifts) {
        state.error_k9_service.push(error_k9_service_shifts)
    },
    DELETE_K9(state, id) {
        let index = state.k9.findIndex(k9 => k9.id == id)
        state.k9.splice(index, 1)
    },
    DELETE_K9_SERVICE(state, id) {
        let index = state.k9_service.findIndex(k9_service => k9_service.id == id)
        state.k9_service.splice(index, 1)
    },
    DELETE_K9_SERVICE_TYPE_OPTIONS(state, id) {
        let index = state.k9_service_type_options.findIndex(k9_service_type_options => k9_service_type_options.id == id)
        state.k9_service_type_options.splice(index, 1)
    },
    UPDATE_K9(state, k9) {
        state.k9 = Object.keys(state.k9).map(updated_k9 => {
            if(updated_k9.id === k9.id) {
                updated_k9.id_supplier_responsible = k9.id_supplier_responsible
                updated_k9.note_info = k9.note_info
                updated_k9.mail_text = k9.mail_text
                updated_k9.id_supplier = k9.id_supplier
            }
        })
    },
    UPDATE_K9_SERVICES(state, k9_service) {
        state.k9_service = state.k9_service.map(updated_k9_service => {

            if(updated_k9_service.id === k9_service.id) {
                updated_k9_service.id_type_option = k9_service.id_type_option
                updated_k9_service.quantity = k9_service.quantity
                updated_k9_service.is_estimated = k9_service.is_estimated
                updated_k9_service.start_datetime_hour = k9_service.start_datetime.split('T')[1].split(':')[0]+ ':' +  k9_service.start_datetime.split('T')[1].split(':')[1]
                updated_k9_service.start_datetime = k9_service.start_datetime.split('T')[0]
                updated_k9_service.end_datetime_hour = k9_service.end_datetime.split('T')[1].split(':')[0]+ ':' + k9_service.end_datetime.split('T')[1].split(':')[1]
                updated_k9_service.end_datetime = k9_service.end_datetime.split('T')[0]
            }
            return updated_k9_service
        })
    },
    UPDATE_MAIL_STATUS_K9(state, cond) {
        if(cond == true) {
            state.send_k9_mail = true    
        }
    },
    UPDATE_STATUS_MAIL_K9(state) {
        state.send_k9_mail = false
    },
    SET_ERROR_K9(state, error) {
        state.error_k9_service = error
    },
    CLEAN_ERROR_K9(state) {
        state.error_k9_service = []
    },
    EMPTY_K9_SHIFTS(state) {
        state.k9_service = []
    },
    SET_ERROR_K9_SERVICE_TYPE_OPTIONS(state, payload) {
        state.error_k9_service_type_options = payload
    },
    EMPTY_ERROR_K9_SERVICE_TYPE_OPTIONS(state){
        state.error_k9_service_type_options = []
    }

}

const actions = {
    async load_k9({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'K9/get_service/' + id)
            .then((response) => {
                commit('SET_K9', response.data)
            })
    },
	async load_k9_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'K9_service/service_sheet/' + id) 
			.then((response) => {
				commit('SET_K9_SERVICE', response.data)
			})
            
	},
    async create_past_k9({commit}, id_ss){
        await axios.post(process.env.VUE_APP_API_URL + 'K9/create_past_k9/' + id_ss + '/')
            .then((response) => {
                commit('SET_K9', response.data)
            })
            .catch(() => {})
    },
    async load_k9_service_type_options({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + 'K9_service_type_option/') 
            .then((response) => {
                commit('SET_K9_SERVICE_TYPE_OPTIONS', response.data)
            })
    },
    async create_k9_service({commit}, k9) {
        await axios.post(process.env.VUE_APP_API_URL + 'K9/', {
            id_supplier_responsible: k9.id_supplier_responsible.id,
            id_service_sheet: k9.id_service_sheet,
            id_supplier: k9.id_supplier.id,
            note_info: k9.note_info,
            mail_text: k9.mail_text
        })
            .then((response) => {
                commit('ADD_K9', response.data)
            })
    },
    async create_k9_services({commit}, k9_service) {
        if(typeof k9_service.start_datetime_hour === 'string') {
            k9_service.start_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + k9_service.start_datetime_hour))
        }
        var start_datetime = new Date(new Date(k9_service.start_datetime).getFullYear(), new Date(k9_service.start_datetime).getMonth(), new Date(k9_service.start_datetime).getDate(),
            new Date(k9_service.start_datetime_hour).getHours(), new Date(k9_service.start_datetime_hour).getMinutes(), new Date(k9_service.start_datetime_hour).getSeconds()) 
        if(typeof k9_service.end_datetime_hour === 'string') {
            k9_service.end_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + k9_service.end_datetime_hour))
        }
        var end_datetime = new Date(new Date(k9_service.end_datetime).getFullYear(), new Date(k9_service.end_datetime).getMonth(), new Date(k9_service.end_datetime).getDate(),
            new Date(k9_service.end_datetime_hour).getHours(), new Date(k9_service.end_datetime_hour).getMinutes(), new Date(k9_service.end_datetime_hour).getSeconds())
        
        start_datetime = moment(start_datetime).format().split('+')[0]
        end_datetime = moment(end_datetime).format().split('+')[0]
        await axios.post(process.env.VUE_APP_API_URL + 'K9_service/', {
            end_datetime: end_datetime,
            id: k9_service.id,
            k9: k9_service.k9,
            quantity: k9_service.quantity,
            id_type_option: k9_service.service_type,
            start_datetime: start_datetime,
            is_estimated: k9_service.is_estimated
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_K9', true)
                commit('CLEAN_ERROR_K9')
                commit('ADD_K9_SERVICE', response.data)
            })
            .catch((error) => {
                commit('CLEAN_ERROR_K9')
                commit('SET_ERROR_K9_SERVICE_SHIFTS', error.response.data)
            })
    },
    async create_k9_service_type_option({commit}, name) {
        await axios.post(process.env.VUE_APP_API_URL + 'K9_service_type_option/', {
            name: name 
        })
            .then((response) => {
                commit('EMPTY_ERROR_K9_SERVICE_TYPE_OPTIONS')
                commit('ADD_K9_SERVICE_TYPE_OPTIONS', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_K9_SERVICE_TYPE_OPTIONS', error.response.data)
            })
    },
    async update_k9({commit}, k9_service) {
        await axios.patch(process.env.VUE_APP_API_URL + 'K9/' + k9_service.id + '/', {
            id_supplier_responsible: k9_service.id_supplier_responsible.id,
            note_info: k9_service.note_info,
            mail_text: k9_service.mail_text,
            id_supplier: k9_service.id_supplier.id
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_K9', response.data.edited)
                commit('UPDATE_K9', response.data)
            })
    },
    async update_k9_services({commit}, k9_service_shift){
        if(typeof(k9_service_shift.start_datetime_hour) === 'string') {
            var start_datetime = (new Date(moment(k9_service_shift.start_datetime).format().split('T')[0] + 'T' + k9_service_shift.start_datetime_hour))
        } else {
            start_datetime = new Date(new Date(k9_service_shift.start_datetime).getFullYear(), new Date(k9_service_shift.start_datetime).getMonth(), new Date(k9_service_shift.start_datetime).getDate(),
                                new Date(k9_service_shift.start_datetime_hour).getHours(), new Date(k9_service_shift.start_datetime_hour).getMinutes(), new Date(k9_service_shift.start_datetime_hour).getSeconds())
        }

        if(typeof(k9_service_shift.end_datetime_hour) === 'string') {
            var end_datetime = (new Date(moment(k9_service_shift.end_datetime).format().split('T')[0] + 'T' + k9_service_shift.end_datetime_hour))
        } else {
            end_datetime = new Date(new Date(k9_service_shift.end_datetime).getFullYear(), new Date(k9_service_shift.end_datetime).getMonth(), new Date(k9_service_shift.end_datetime).getDate(),
                                new Date(k9_service_shift.end_datetime_hour).getHours(), new Date(k9_service_shift.end_datetime_hour).getMinutes(), new Date(k9_service_shift.end_datetime_hour).getSeconds())    
        }
        start_datetime = moment(start_datetime).format().split('+')[0] 
        end_datetime = moment(end_datetime).format().split('+')[0]
        await axios.patch(process.env.VUE_APP_API_URL + 'K9_service/' + k9_service_shift.id + '/', {
            id: k9_service_shift.id,
            id_type_option: k9_service_shift.id_type_option.id,
            start_datetime: start_datetime,
            end_datetime: end_datetime,
            quantity: k9_service_shift.quantity
        })
            .then((response) => {
                commit('UPDATE_K9_SERVICES', response.data)
                commit('CLEAN_ERROR_K9')
                commit('UPDATE_MAIL_STATUS_K9', response.data.edited)
            })
            .catch((error) => {
                commit('SET_ERROR_K9_SERVICE_SHIFTS', error.response.data)
            })
    },
    async delete_k9({commit}, id){
        await axios.delete(process.env.VUE_APP_API_URL + 'K9/' + id + '/')
            .then(() => {
                commit('DELETE_K9', id)
            })
            .catch((error) => {
                commit('SET_ERROR_K9', error.response.data)
            })

    },
    async delete_k9_service({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'K9_service/' + id + '/')
            .then(() => {
                commit('DELETE_K9_SERVICE', id)
            })
            .catch((error) => {
                commit('SET_ERROR_K9', error.response.data)
            })
    },
    async delete_k9_service_type_option({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "K9_service_type_option/" + id + '/')
            .then(() => {
                commit('DELETE_K9_SERVICE_TYPE_OPTIONS', id)
            })
    },
    async send_k9_service_mail({commit}, id){
        await axios.post(process.env.VUE_APP_API_URL + 'K9/send_mail/' + id + '/')
            .then(() => {
                commit('UPDATE_STATUS_MAIL_K9')
                commit('CLEAN_ERROR_K9')
            })
            .catch((error) => {
                commit('SET_ERROR_K9', error.response.data)
            })
    },
    async clean_errors_k9_shifts({commit}) {
        commit('CLEAN_ERROR_K9')
    },
    async empty_k9_shifts({commit}) {
        commit('EMPTY_K9_SHIFTS')
    }
}

const getters = {
    get_k9_services: (state) => state.k9_service,
    get_k9: (state) => state.k9,
    get_k9_service_type_options: (state) => state.k9_service_type_options,
    get_send_k9_mail: (state) => state.send_k9_mail,
    get_error_k9_services: (state) => state.error_k9_service,
    get_k9_services_estimated: (state) =>  {
        return state.k9_service.filter(item => item.is_estimated === true)
    },
    get_k9_services_final: (state) =>  {
        return state.k9_service.filter(item => item.is_estimated === false)
    },
    error_k9_service_type_options: (state) => state.error_k9_service_type_options
}

export default {
    state,
    mutations,
    actions,
    getters
};