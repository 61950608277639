<template>
    <Toast/>
    <div v-if="$can('view','supplier')" class="pt-4 pb-2">
        <div class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Suppliers </h1>
            <div v-if="$can('add','supplier')" class="card p-1">
                <Button data-cy="new_supplier_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Supplier'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_supplier_modal" header="New Supplier" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '60vw'}">
            <div class="container mt-2">
                <form id="from_new_supplier" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted || supplier_errors.name}" />
                                <label for="name" :class="{'p-error':v$.name.$invalid && submitted}"> Name* </label>
                                <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.name" class="p-error"><span v-for="error in supplier_errors.name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <Chips id="email" separator="," v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted || supplier_errors.email }" />
                                <label for="email" :class="{'p-error':v$.email.$invalid && submitted}"> Email* </label>
                                <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.email" class="p-error"><span v-for="error in supplier_errors.email" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="phone" v-model="v$.phone.$model" :class="{'p-invalid':v$.phone.$invalid && submitted || supplier_errors.phone}" />
                                <label for="phone" :class="{'p-error':v$.phone.$invalid && submitted}"> Phone* </label>
                                <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.phone" class="p-error"><span v-for="error in supplier_errors.phone" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.phone.$invalid && submitted) || v$.phone.$pending.$response" class="p-error">{{v$.phone.required.$message.replace('Value', 'Phone')}}</small>
                        </div> 
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col pt-3">
                            <div class="p-float-label">
                                <InputText id="cif" v-model="cif" :class="{'p-invalid':submitted && supplier_errors.cif}" />
                                <label for="cif" :class="{'p-error':submitted && supplier_errors.cif}"> CIF </label>
                                <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.cif" class="p-error"><span v-for="error in supplier_errors.cif" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                        <div class="col-10 md:col pt-3">
                            <div class="p-float-label">
                                <InputText id="ccc" v-model="ccc" :class="{'p-invalid':submitted && supplier_errors.ccc}" />
                                <label for="ccc" :class="{'p-error':submitted && supplier_errors.ccc}"> CCC  </label>
                                <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.ccc" class="p-error"><span v-for="error in supplier_errors.ccc" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <InputText id="fiscal_address" v-model="fiscal_address" :class="{'p-invalid':submitted && supplier_errors.fiscal_address}" />
                                <label for="fiscal_address" :class="{'p-error':submitted && supplier_errors.fiscal_address}"> Fiscal Address </label>
                                <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.fiscal_address" class="p-error"><span v-for="error in supplier_errors.fiscal_address" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10 md:col pt-3">
                            <div class="p-float-label">
                                <InputText id="country_code" v-model="country_code" :class="{'p-invalid':submitted && supplier_errors.country_code}" />
                                <label for="country_code" :class="{'p-error':submitted && supplier_errors.country_code}"> Country Code </label>
                                <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.country_code" class="p-error"><span v-for="error in supplier_errors.country_code" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                        <div class="col-10 md:col pt-3">
                            <div class="p-float-label">
                                <InputText id="cp" v-model="cp" :class="{'p-invalid':submitted && supplier_errors.cp}" />
                                <label for="cp" :class="{'p-error':submitted && supplier_errors.cp}"> CP  </label>
                                <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.cp" class="p-error"><span v-for="error in supplier_errors.cp" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10 md:col pt-3">
                            <div class="p-float-label">
                                <InputText id="bic_entity" v-model="bic_entity" :class="{'p-invalid':submitted && supplier_errors.bic_entity}" />
                                <label for="bic_entity" :class="{'p-error':submitted && supplier_errors.bic_entity}"> BIC </label>
                                <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.bic_entity" class="p-error"><span v-for="error in supplier_errors.bic_entity" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                        <div class="col-10 md:col pt-3">
                            <div class="p-float-label">
                                <InputText id="iban" v-model="iban" :class="{'p-invalid':submitted && supplier_errors.iban}" />
                                <label for="iban" :class="{'p-error':submitted && supplier_errors.iban}"> IBAN  </label>
                                <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.iban" class="p-error"><span v-for="error in supplier_errors.iban" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <Button data-cy="create_supplier_button" form="from_new_supplier" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Chips from 'primevue/chips'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        Toast,
        Chips
    },
    data() {
        return {
            displayModal: false,
            name: '',
            email: [],
            phone: '',
            ccc: '',
            cif: '',
            fiscal_address: '',
            country_code: '',
            cp: '',
            bic_entity: '',
            iban: '',
            supplier_errors: {},
            submitted: false,
            showMessage: false,
        }
    },
    computed: {
        get_supplier_errors: function() {
            return this.$store.getters.get_supplier_errors
        }
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_supplier() {
            let supplier = { 
                name: this.name,
                email: this.email,
                phone: this.phone,
                ccc: this.ccc,
                cif: this.cif,
                fiscal_address: this.fiscal_address,
                country_code: this.country_code,
                cp: this.cp,
                bic_entity: this.bic_entity,
                iban: this.iban
            }
            await this.$store.dispatch('create_supplier', supplier)
            this.supplier_errors = this.get_supplier_errors
            if(Object.keys(this.supplier_errors).length === 0){
                this.toggleDialog();
                this.resetForm();
                return true
            }
            return false

        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

            let res = await this.add_supplier()

            if (res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier added', life: 3000});
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.name = '';
            this.email = '';
            this.phone = '';
            this.ccc='';
            this.cif='';
            this.fiscal_address='';
            this.submitted = false;
        }
    },
    validations() {
        return {
            name: { required },
            email: { required },
            phone: { required },
        }
    },
};
</script>

<style scoped>
</style>