import router from '@/router/index.js'
import { userService } from '@/services/user_services';

const user = localStorage.getItem('user')

const state = {
    user: user,
    status: { loggedIn: true },
    error_message_login: '',
    loading: false
}

const actions = {
    async login({ commit }, { username, password }) {
        commit('loading_true')
        await commit('loginRequest', { username });
        localStorage.setItem('user', username)
        userService.login({username, password})
        .then(
            user => {
                commit('loginSuccess', user);
                commit('loading_false')
                router.push('/home');
            },
        )
        .catch(
            error => {
                
                commit('loginFailure', error.response.data.detail);
                commit('loading_false')
                return (error.response.data.detail)
            }
        );
    },
}

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user.data.access;
        state.error_message_login = ''
    },
    loginFailure(state, error) {
        state.status = {};
        state.user = null;
        state.error_message_login = error
    },

    logout(state) {
        state.status = {};
        state.user = null;
    },
    loading_true(state) {
        state.loading = true
    },
    loading_false(state) {
        state.loading = false
    }
}

const getters = {
    get_error_message_login: (state) => state.error_message_login,
    get_loading: (state) => state.loading
}

export default {
    state,
    actions,
    mutations,
    getters
}