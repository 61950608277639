<template>
    <Toast/>
    <div v-if="loading_calendar" style="position: absolute; top:0;left:0;">
        <ProgressBar mode="indeterminate" style="height: 6px; width: 100vw;"></ProgressBar>
    </div>
    <div class="container-fluid">
        <div class="row" style="height: 100%">
            <div class="col main_table">
                <div class="container-fluid mt-2">
                    <ScrollPanel>
                        <div v-if="loading" class="col">
                            <Skeleton height="80vh"/>
                        </div>
                        <div v-else>
                            <div class="flex justify-content-between align-items-center">
                                <h2 class="my-auto"> Operation Diary </h2>
                                <span class="card" style="display: block;">
                                    <Button class="p-button-text"  @click="this.$router.push('/operation_diary_summary')" v-tooltip.bottom="'Search'" style="width: 10px;">
                                        <i class="pi pi-search" style="font-size: 1rem"></i>
                                    </Button>
                                    <Button v-if="$can('add', 'message')" class="p-button-success p-button-text" @click="open_modal_new_note" v-tooltip.bottom="'New Record'">
                                        <i class="pi pi-plus" style="font-size: 1rem"></i>
                                    </Button>
                                </span>
                            </div>
                            <FullCalendar class="me-1" ref="fullCalendar" :options="optionsCalendar"/>
                        </div>
                    </ScrollPanel>
                </div>
            </div>  
        </div>
    </div>

     <Dialog header="New Note" v-model:visible="display_modal_new_record" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '60vw'}" :modal="true">
        <form id="form_new_note" @submit.prevent="handleSubmit(!v$.$invalid)">
            <div class="mt-3 container p-fluid" style="margin-top: auto;">
                <div class="col">
                    <span class="p-float-label">
                        <Calendar v-model="note_day" dateFormat="yy-mm-dd" :class="{'p-invalid':v$.note_day.$invalid && submitted}"/>
                        <label> Day </label>
                    </span>
                    <small v-if="(v$.note_day.$invalid && submitted) || v$.note_day.$pending.$response" class="p-error">{{v$.note_day.required.$message.replace('Value', 'Day ')}}</small>
                </div>
            </div>
            <div class="mt-3 container p-fluid" style="margin-top: auto;">
                <div class="col">
                    <span class="p-float-label">
                        <Calendar v-model="note_time" :timeOnly="true" hourFormat="24" :class="{'p-invalid':v$.note_time.$invalid && submitted}" @change="note_time = num2time($event)"/>
                        <label> Time </label>
                    </span>
                    <small v-if="(v$.note_time.$invalid && submitted) || v$.note_time.$pending.$response" class="p-error">{{v$.note_time.required.$message.replace('Value', 'Time ')}}</small>
                </div>
            </div>
            <div class="mt-3 container p-fluid" style="margin-top: auto;">
                <div class="col">
                    <span class="p-float-label">
                        <TextArea id="note_text" v-model="note_text" rows="5" :class="{'p-invalid':v$.note_text.$invalid && submitted}"/>
                        <label for="note_text"> Text </label>
                    </span>
                    <small v-if="(v$.note_text.$invalid && submitted) || v$.note_text.$pending.$response" class="p-error">{{v$.note_text.required.$message.replace('Value', 'This field ')}}</small>
                </div>
            </div>
        </form>
        <div class="container mb-2" style="margin-top: auto;">
            <div class="col">
                <Button form="form_new_note" type="submit" label="Add" icon="fa fa-plus" class="p-button-success p-button-raised"/>
            </div>
        </div>
    </Dialog>

    <Dialog header="Edit Note" v-model:visible="display_modal_edit" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '60vw'}" :modal="true">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Details </h5>
                </div>
            </div>
            <div class="row mt-3 p-fluid">
                <div class="col">
                    <span class="p-float-label">
                        <Calendar v-model="edit_date" dateFormat="yy-mm-dd"/>
                        <label> Day </label>
                    </span>
                </div>
            </div>
            <div class="row mt-3 p-fluid">
                <div class="col">
                    <span class="p-float-label">
                        <Calendar v-model="edit_hour" :timeOnly="true" hourFormat="24" @change="edit_hour = num2timeedit($event)"/>
                        <label> Time </label>
                    </span>
                </div>
            </div>
            <div class="row mt-3 p-fluid">
                <div class="col">
                    <span class="p-float-label">
                        <TextArea v-model="edit_text" rows="3"/>
                        <label> Text </label>
                    </span>
                </div>
            </div>
            <div v-if="$ability.can('delete', 'message')" class="row justify-content-center">
                <div class="col-12">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Delete Note </h5>
                </div>
            </div>
            <div v-if="$ability.can('delete', 'message')" class="row">
                <div class="col text-center">
                    <span class="p-float-label">
                        <Button label="Delete" class="p-button-danger p-button-raised" @click="delete_note"/>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Button label="Cancel" class="p-button-text" @click="display_modal_edit = false"/>
                        <Button label="Save" class="p-button-raised" @click="save_note"/>
                    </div>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';

import Toast from 'primevue/toast'
import Button from 'primevue/button'
import TextArea from 'primevue/textarea'
import Calendar from 'primevue/calendar'
import Dialog from 'primevue/dialog'
import ScrollPanel from 'primevue/scrollpanel'
import Skeleton from 'primevue/skeleton'
import ProgressBar from 'primevue/progressbar'

import moment from 'moment'

import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";
import { num2time, num2timeedit } from '@/modules/utilities.js'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'Daily Log',
    components: {
        Toast,
        FullCalendar,
        Button,
        TextArea,
        Calendar,
        Dialog,
        ScrollPanel,
        Skeleton,
        ProgressBar,
    },
    data(){
        return {
            loading: true,
            loading_calendar: false,
            visibleRight: false,
            submitted: false,
            current_date:  moment().format('YYYY-MM-DD'),
            general_category: false,
            note_text: '',
            note_day: moment().format('YYYY-MM-DD'),
            note_time: moment().format('HH:mm'),
            color: localStorage.getItem('primary_color'),
            date: moment().format('YYYY-MM-DD'),
            hour: moment().format('HH:mm'),
            display_modal_edit: false,
            display_modal_new_record: false,
            description: '',
            edit_id: '',
            edit_text: '',
            edit_date: '',
            edit_hour: '',
            edit_username: '',
            optionsCalendar: {
                plugins: [dayGridPlugin, interactionPlugin, listPlugin ],
                initialView: 'listWeek',
                events: [],
                firstDay: 1,
                height: 800,
                displayEventTime: false,
                //aspectRatio: 1.6,
                eventDisplay: "block",
                titleFormat: {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                }, 
                headerToolbar: {
                    start: 'title', 
                    center: '',
                    end: 'today prev next' 
                },
                eventOrder: "creation_datetime",
                eventClick: async function(event) {
                    this.edit_id = event.event._def.publicId
                    this.edit_text = event.event._def.title.split(/-/).slice(1).join('-').trim()
                    this.edit_date = moment(event.event.start).format('YYYY-MM-DD')
                    this.edit_hour = moment(event.event.start).format('HH:mm')
                    this.edit_username = event.event._def.title.split(/-/)[0].trim()
                    if(event.event._def.extendedProps.user == this.get_current_user.id) {
                        this.display_modal_edit = true
                    } else {
                        if(event.event._def.extendedProps.read == true) {
                            if(event.el.style.fontWeight != 'bold'){
                                this.$store.dispatch('mark_manually_as_unread', event.event._def.publicId)
                                .then(() => {
                                    this.$toast.removeAllGroups();
                                    this.$toast.add({severity:'success', summary: 'Success', detail:'Set as unread.', life: 3000}); 
                                    event.el.style.backgroundColor = 'white'
                                    event.el.style.fontWeight = 'bold'
                                })
                                .catch(() => {
                                    this.$toast.removeAllGroups();
                                    this.$toast.add({severity:'error', summary: 'Error', detail: 'You do not have permissions to edit this note.', life: 3000}); 
                                })
                            }
                            
                        }
                    }
                }.bind(this),
                customButtons: {
                    next: {
                        click: async () => {
                            this.loading_calendar = true
                            await this.$store.dispatch('load_operation_notifications')

                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();

                            this.optionsCalendar.events = []
                            await this.$store.dispatch('load_daily_messages',moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'))

                            let data_read = {
                                date: moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'),
                            }

                            this.get_daily_messages.forEach((element) => {
                                var event = {
                                    id: element.id,
                                    title: element.id_user.username + ' - ' + element.text,
                                    start: element.text_datetime,
                                    extendedProps: {
                                        read: false,
                                        user: element.id_user.id
                                    },
                                }
                                
                                if(element.is_read == false) {
                                    event.color = 'green'
                                    if(element.id_user.id == this.get_current_user.id){
                                        event.className = 'current_user_message bold_title_event '
                                    } else {
                                        event.className = 'bold_title_event'
                                    }
                                    
                                } else {
                                    event.color = 'gray'
                                    event.extendedProps.read = true
                                    if(element.id_user.id == this.get_current_user.id){
                                        event.className = 'current_user_message normal_background'
                                    } else {
                                        event.className = 'normal_background'
                                    }
                                }

                                this.optionsCalendar.events.push(event)

                            })

                            if(this.optionsCalendar.events.length != 0) {
                                await this.$store.dispatch('mark_as_read', data_read)
                            }

                            this.loading_calendar = false
                        } 
                    },
                    prev: {
                        click: async() => {
                            this.loading_calendar = true
                            await this.$store.dispatch('load_operation_notifications')    

                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();

                            await this.$store.dispatch('load_daily_messages', moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'))
                            this.optionsCalendar.events = []
                            
                            let data_read = {
                                date: moment(calendarApi.currentData.currentDate).startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'),
                            }


                            this.get_daily_messages.forEach((element) => {

                                var event = {
                                    id: element.id,
                                    title: element.id_user.username + ' - ' + element.text,
                                    start: element.text_datetime,
                                    extendedProps: {
                                        read: false,
                                        user: element.id_user.id
                                    },
                                }

                                if(element.is_read == false) {
                                    if(element.id_user.id == this.get_current_user.id){
                                        event.className = 'current_user_message bold_title_event '
                                    } else {
                                        event.className = 'bold_title_event'
                                    }
                                    
                                } else {
                                    event.extendedProps.read = true
                                    if(element.id_user.id == this.get_current_user.id){
                                        event.className = 'current_user_message normal_background'
                                    } else {
                                        event.className = 'normal_background'
                                    }
                                }

            
                                this.optionsCalendar.events.push(event)
                                

                            })

                            if(this.optionsCalendar.events.length != 0) {
                                await this.$store.dispatch('mark_as_read', data_read)
                            }

                            this.loading_calendar = false
                        }
                    }
                },
            },
        }
    },
    computed: {
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        get_daily_messages: function() {
            return this.$store.getters.get_daily_messages
        },
        get_operation_notifications: function() {
            return this.$store.getters.get_operation_notifications
        }
    },
    async mounted() {
        
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        await this.$store.dispatch('get_current_user')
        await this.$store.dispatch('load_daily_messages', moment().startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD'))
        await this.$store.dispatch('load_operation_notifications')

        this.get_daily_messages.forEach((element) => {
            var event = {
                id: element.id,
                title: element.id_user.username + ' - ' + element.text,
                start: element.text_datetime,
                extendedProps: {
                    read: false,
                    user: element.id_user.id
                },
            }
            
            if(element.is_read == false) {
                if(element.id_user.id == this.get_current_user.id){
                    event.className = 'current_user_message bold_title_event '
                } else {
                    event.className = 'bold_title_event'
                }
                
            } else {
                event.extendedProps.read = true
                if(element.id_user.id == this.get_current_user.id){
                    event.className = 'current_user_message normal_background'
                } else {
                    event.className = 'normal_background'
                }
            }
           

            this.optionsCalendar.events.push(event)
            
        })

        let data_read = {
            date: moment().startOf('isoWeek').day(0).add(1, 'weeks').format('YYYY-MM-DD')
        }

        if(this.optionsCalendar.events.length != 0) {
            await this.$store.dispatch('mark_as_read', data_read)
        }

        this.loading = false
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {

        async handleResize(){
            if (window.innerWidth <= 465){
                this.optionsCalendar.headerToolbar.right = 'prev next'
                delete this.optionsCalendar.titleFormat.year
            } else {
                this.optionsCalendar.headerToolbar.right = 'today prev next'
                this.optionsCalendar.titleFormat.year = 'numeric'
            }
        },

        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data){
            return num2timeedit(data)
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }

            let res = await this.create_new_note()

            if (res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Note Added', life: 3000});
            }
        },
        async create_new_note() {

            let hour = ''

            if(typeof(this.note_time) == 'string') {
                hour = this.note_time
            } else {
                hour = moment(this.note_time).format('HH:mm')
            }

            var note = {
                text_datetime: moment(this.note_day).format('YYYY-MM-DD') + 'T' + hour,
                text: this.note_text
            }

            await this.$store.dispatch('create_message', note)
            this.submitted = false

            var new_daily_message = this.get_daily_messages.slice(-1)

            var event = {
                id: new_daily_message[0].id,
                title: new_daily_message[0].id_user.username + ' - ' + new_daily_message[0].text,
                start: new_daily_message[0].text_datetime,
                extendedProps: {
                    read: false,
                    user: new_daily_message[0].id_user.id
                },
                className: 'current_user_message bold_title_event'
            }

            this.optionsCalendar.events.push(event)

            this.note_text = ''
            this.display_modal_new_record = false
        },
        async save_note() {
            
            let hour = ''

            if(typeof(this.edit_hour) == 'string') {
                hour = this.edit_hour
            } else {
                hour = moment(this.edit_hour).format('HH:mm')
            }

            let note = {
                id: this.edit_id,
                text_datetime: moment(this.edit_date).format('YYYY-MM-DD') + 'T' + hour,
                text: this.edit_text,
            } 


            for(var i in this.optionsCalendar.events) {
                if(this.optionsCalendar.events[i].id == this.edit_id) {
                    this.optionsCalendar.events[i].start = this.edit_date + 'T' + hour
                    this.optionsCalendar.events[i].title = this.edit_username + ' - ' + this.edit_text
                }
            }
        
            this.$store.dispatch('edit_message', note)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Note modified.', life: 3000});
            this.display_modal_edit = false

        },
        async delete_note() {
            let index = this.optionsCalendar.events.findIndex(optionsCalendar => optionsCalendar.id == this.edit_id)
            this.optionsCalendar.events.splice(index, 1)
            this.display_modal_edit = false
            await this.$store.dispatch('delete_message', this.edit_id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Note deleted.', life: 3000});
        },
        async open_modal_new_note(){
            this.display_modal_new_record = true
        }
    },
    validations() {
        return {
            note_text: { required },
            note_day: { required },
            note_time: { required },
        }
    },
}
</script>

<style scoped>

.side_bar_custom{
    position: fixed;
    width: 300px;
    height: calc(100vh - 11rem);
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: 9rem;
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.main_table {
    flex-direction: column;
    min-height: calc(100vh - 9rem);
    justify-content: space-between;
    transition: margin-left 0.2s;
}

@media (min-width: 1300px) {
    .menu-mobile {
        display: none;

    }

    :deep(.p-scrollpanel .p-scrollpanel-bar) {
        background-color: #a8a8a8;
        transition: #a8a8a8 .2s;
    }

    :deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
        background-color: #a8a8a8;
    }
}

@media (max-width: 1301px) {
    .side_bar_custom {
        display: none;
    }
    .main_table{
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
}

:deep(.p-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content){
    width: 100%;
}

:deep(.p-scrollpanel .p-scrollpanel-bar) {
    background-color: #a8a8a8;
    transition: #a8a8a8 .2s;
}    

:deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
    background-color: #a8a8a8;
}

.sidebar-button {
    border-radius: 10px;
    left: 82vw !important;
    top: 90% !important;
    position: fixed !important;
    z-index: 1000;
}

:deep(.p-menu) {
    border-radius: 0px;
    background: rgba(0,0,0,0); 
    padding: 0;
}

:deep(.p-menu .p-menuitem-link) {
    margin-left: 0px;
}

.scroll_menu{
    background-color: v-bind(opacity_color);
}

:deep(.p-scrollpanel-content){
    padding: 0;

}

:deep(.p-menu .p-submenu-header){
    background-color: rgba(0,0,0,0); 
    border-radius: 6px;
}

/*
:deep(.bm-item-list) {
    margin-left: 0px !important;
}

:deep(.bm-menu) {
    background-color: #eaeaea;
    box-shadow: 0 9px 10px -1px rgba(0,0,0,.2), 0 9px 10px 0 rgba(0,0,0,.14), 0 4px 5px 0 rgba(0,0,0,.12);
}
*/

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
}

.color-button-menu{
    color: white;
    background-color: v-bind(color);
    border-color: v-bind(color);
}

.color-button-menu:hover{
    background-color: v-bind(color) !important;
    border-color: v-bind(color) !important; 
    opacity: .8;
}

:deep(.router-link-active .p-menuitem-text){
    color: v-bind(color) !important;
    font-weight: 700;
}

:deep(.router-link-active .p-menuitem-icon){
    color: v-bind(color) !important;
}

:deep(.p-menu .p-menuitem-link:not(.p-disabled):hover){
    border-radius: 10px;
}

:deep(.p-scrollpanel-bar-x){
    height: 0px;
}

:deep(a) {
    color: black !important;
}

.fc:deep(.fc-view-harness .fc-list .fc-scroller .fc-list-table .fc-list-day .fc-list-day-cushion) {
    background: v-bind(color) !important;
}

.fc:deep(.fc-view-harness .fc-list .fc-scroller .fc-list-table .fc-list-day .fc-list-day-cushion .fc-list-day-text) {
    text-decoration: none !important;
    color: white !important;
}

.fc:deep(.fc-view-harness .fc-list .fc-scroller .fc-list-table .fc-list-day .fc-list-day-cushion .fc-list-day-side-text) {
    text-decoration: none !important;
    color: white !important;
}


.fc:deep(.fc-daygrid-day-number) {
    text-decoration: none !important;
}

.fc:deep(.fc-daygrid-event) {
    cursor: pointer;
}

.fc:deep(.fc-daygrid-day.fc-day-today) {
    background: #dce2f7;
}

.fc:deep(.fc-col-header-cell) {
    background: v-bind(color) !important;
    border: v-bind(color) !important;
}

:deep(.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl
        .fc-daygrid-event.fc-event-start) {
    margin-bottom: 2px !important;
}

:deep(.fc.fc-theme-standard .fc-toolbar .fc-button) {
    background: #002d72 !important;
    border: #002d72 !important;
}

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

a{
    text-decoration: none;
}

:deep(.fc-list-event) {
    cursor: pointer;
}

.border_right_divider{
    border-top: 1px solid lightgray;
    margin-top: 1em;
    border-right: 0px;
}

@media (min-width: 1200px) {
    .border_right_divider{
        border-right: 1px solid lightgray; 
        border-top: 0px;
        margin-top: 0px;
    }

    .card_note_mobile{
        display: none !important;
    }
}

@media (max-width: 1199px){
    .card_note{
        display: none !important;
    }
}

:deep(.fc-list-event-title){
    border: 0px;
}

:deep(.fc-list-event-graphic){
    border: 0px;
    display: none;
}

:deep(.bold_title_event){
    font-weight: bold;
}

:deep(.normal_background) {
    background-color: #eaeaea;
}

:deep(.p-scrollpanel-content){
    overflow-x: hidden;
}


</style>