<template>
    <div class="mt-5">
        <div class="col">
            <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Supplier Selection </h5>
        </div>
        <div class="p-fluid col-12 md:col-6 lg:col-4">
            <div class="p-float-label mt-3">
                <Dropdown :options="all_suppliers" v-model="supplier_selected" optionLabel="name" @change="load_supplier_contract" filter/>
                <label> Supplier </label>
            </div>
            <small v-show="validationErrors.supplier_selected && submitted" class="p-error">Supplier is required.</small>
        </div>
        <div class="p-fluid col-12 md:col-6 lg:col-4">
            <div class="p-float-label mt-3">
                <Dropdown :options="get_active_supplier_contracts" v-model="contract_supplier_selected" optionLabel="name">
                    <template #option="slotProps">
                        <div class="row">
                            <div class="col-6"> 
                                {{slotProps.option.name}} 
                            </div>
                            <div class="col-6">
                                <span>Exp {{slotProps.option.end_date}} </span>
                            </div>
                        </div>
                    </template>
                </Dropdown>
                <label> Contract </label>    
            </div>
            <small v-show="validationErrors.contract_supplier_selected && submitted" class="p-error">Contract is required.</small>
        </div>
        <div class="col">
            <div class="grid grid-nogutter justify-content-between pt-5">
                <i></i>
                <Button class="p-button-raised" label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
            </div>  
        </div>
    </div>    
</template>

<script>
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'

export default {
    name: 'Supplier_selection',
    components: {
        Dropdown,
        Button
    },
    data() {
        return {
            supplier_selected: '',
            validationErrors: {},
            submitted: false,
            contract_supplier_selected: '',
        }
    },
    computed: {
        all_suppliers: function() {
            return this.$store.getters.all_suppliers
        },
        get_active_supplier_contracts: function() {
            return this.$store.getters.get_active_supplier_contracts
        }
    },
    async mounted() {
        await this.$store.dispatch('load_all_suppliers')
    },    
    methods: {
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', { formData: { supplier: this.supplier_selected, contract: this.contract_supplier_selected }, pageIndex: 0 });
            }
        },
        validateForm() {
            if (!this.supplier_selected) { 
                this.validationErrors['supplier_selected'] = true;
            } else {
                delete this.validationErrors['supplier_selected'];
            } 

            return !Object.keys(this.validationErrors).length
        },
        async load_supplier_contract() {
            await this.$store.dispatch('load_active_supplier_contracts', this.supplier_selected.id)
        }
    }
}
</script>

<style scoped>

</style>