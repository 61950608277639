<template>
    <TableCalendars/>
</template>

<script>
import TableCalendars from '@/components/Calendar/Table_calendar.vue' 
export default {
    name: 'Suppliers',
    components: {
        TableCalendars,
    }
}
</script>

<style scoped>

</style>