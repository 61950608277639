<template>
    <div class="card">
        <div class="row">
            <Toast />
            <DataTable :value="get_cards" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" scrollable 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading" removableSort
            :globalFilterFields="['name']"
            v-model:filters="filters">   
                <template #header>
                    <div class="row">
                        <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                            <span class="p-input-icon-left ">
                                <i class="pi pi-search" />
                                <InputText id="filter_cards" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                            </span>
                        </div>
                    </div>
                </template>
                <template #empty>
                    <div class="row">
                        <div class="col text-center">
                            No records.
                        </div>
                    </div>
                </template>
                <Column header="Name" field="name" :sortable="true"> 
                </Column>
                <Column header="Number" field="number" :sortable="true">
                </Column>
                <Column header="Balance" field="balance" :sortable="true">
                </Column>
                <Column header="Bank" field="bank" :sortable="true">
                </Column>
                <Column header="User" field="id_user_assigned.username" :sortable="true">
                </Column>
                <Column v-if="$can('change','card')" header="Edit" style="width: 7rem;" bodyStyle="overflow: visible">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_card(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','card')" header="Delete" style="width: 7rem;" bodyStyle="overflow: visible">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_card(slotProps)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_card_modal" v-model:visible="card_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Card Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="name" class="pb-1 ps-1"> <strong> Name </strong> </label>
                        <InputText id="name" v-model.trim="card.name" required="true" autofocus :class="{'p-invalid': submitted && !card.name || card_errors.name}" />
                        <small class="p-error" v-if="submitted && !card.name">Name is required.</small>
                        <small v-if="Object.keys(card_errors).length !== 0 && card_errors.name" class="p-error"><span v-for="error in card_errors.name" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="number" class="pb-1 ps-1"> <strong> Number </strong> </label>
                        <InputText id="number" v-model.trim="card.number" required="true" autofocus :class="{'p-invalid': submitted && !card.number || card_errors.number}" />
                        <small v-if="Object.keys(card_errors).length !== 0 && card_errors.number" class="p-error"><span v-for="error in card_errors.number" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="balance" class="pb-1 ps-1"> <strong> Balance </strong> </label>
                        <InputText id="balance" v-model.trim="card.balance"/>
                        <small v-if="Object.keys(card_errors).length !== 0 && card_errors.balance" class="p-error"><span v-for="error in card_errors.balance" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="bank" class="pb-1 ps-1"> <strong> Bank </strong> </label>
                        <InputText id="bank" v-model.trim="card.bank"/>
                        <small v-if="Object.keys(card_errors).length !== 0 && card_errors.bank" class="p-error"><span v-for="error in card_errors.bank" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="id_user_assigned" :class="{'p-invalid': submitted && !card.id_user_assigned || card_errors.id_user_assigned}" class="pb-1 ps-1"> <strong> User </strong> </label>  
                        <Dropdown id="id_user_assigned" v-model="card.id_user_assigned.id" :options="get_all_users" optionLabel="username" optionValue="id" :filter="true"> 
                            <template #value="slotProps">
                                <div v-if="slotProps.value && slotProps.value.name">
                                    <span>{{slotProps.value.name}}</span>
                                </div>
                                <div v-else-if="slotProps.label && !slotProps.value.value">
                                    <span >{{slotProps.name}}</span>
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                    <small v-if="Object.keys(card_errors).length !== 0 && card_errors.id_user_assigned" class="p-error"><span v-for="error in card_errors.id_user_assigned" :key="error">{{error}}<br></span></small>                      
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_card_dialog"/>
            <Button data-cy="save_edit_currency" label="Save" @click="save_card" />
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_card_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="card">Are you sure you want to delete <b>{{card.data.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_card_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="delete_card" />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import Dropdown from 'primevue/dropdown'
import { FilterMatchMode } from 'primevue/api';
import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'TableCruise_lines',
    components: {
        DataTable,
        Column,
        Button,
        Dialog,
        InputText,
        Toast,
        Dropdown
    },
    data() {
        return {
            id: '',
            card: {},
            card_errors:{},
            card_dialog: false,
            delete_card_dialog: false,
            submitted: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.get_cards.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_cards')
            await this.$store.dispatch('load_all_users')
            this.loading = false
        }
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        get_cards: function() {
            return this.$store.getters.get_cards
        },
        get_card_errors: function() {
            return this.$store.getters.get_card_errors
        },
        get_all_users: function() {
            return this.$store.getters.get_all_users
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        confirm_delete_card(card) {
            this.card = card;
            this.delete_card_dialog = true;
        },
        async delete_card() {
            await this.$store.dispatch('delete_card', this.card.data.id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Card deleted', life: 3000});
            this.delete_card_dialog = false;
        },
        edit_card(card) {
            this.card = {...card};
            this.card_dialog = true;
        },
        async save_card() {
            this.submitted = true;
			if (this.card.name.trim()) {
                await this.$store.dispatch('update_card', this.card)
                this.card_errors = this.get_card_errors
                
                if(Object.keys(this.card_errors).length === 0){
                    this.card_dialog = false;
                    this.card = {};
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Card modified', life: 3000});
                }
                
            }
        },
        hide_card_dialog() {
            this.card_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            name: { required },
            number: { required },
            id_user_assigned: { required }
        }
    },

}
</script>

<style>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>