import axios from "axios"
import moment from 'moment'

const state = {
    fence_security_service: [],
    fence_security_services_by_day: [],
    fence_security_service_shifts: [],
    fence_security_service_type_options: [],
    fence_security_service_gate_options: [],
    send_fence_security_mail: false,
    error_fence_security_service: [],
    error_fence_security_service_shifts: [],
    fence_security_service_sheets: [],
    error_fence_security_service_type_options: [],
    error_fence_security_service_gate_options: [],
    created_auto: false,
}

const mutations = {
    SET_FENCE_SECURITY_SERVICE(state, fence_security_service) {
        state.fence_security_service = fence_security_service
    },
    ADD_FENCE_SECURITY_SERVICE(state, fence_security_service) {
        state.error_fence_security_service = {}
        state.fence_security_service = fence_security_service
    },
    SET_FENCE_SECURITY_SERVICE_BY_DAY(state, fence_security_services_by_day) {
        state.fence_security_services_by_day = fence_security_services_by_day
    },
    SET_FENCE_SECURITY_SERVICE_SHIFTS(state, fence_security_service_shifts) {
        for(var item in fence_security_service_shifts) {
            fence_security_service_shifts[item].end_hour = fence_security_service_shifts[item].end_hour.split(':')[0] + ':' +  fence_security_service_shifts[item].end_hour.split(':')[1]
            fence_security_service_shifts[item].start_hour = fence_security_service_shifts[item].start_hour.split(':')[0] + ':' +  fence_security_service_shifts[item].start_hour.split(':')[1]
        }   
        state.fence_security_service_shifts = fence_security_service_shifts
    },
    SET_FENCE_SECURITY_SERVICE_TYPE_OPTIONS(state, options){
        state.fence_security_service_type_options = options
    },
    ADD_FENCE_SECURITY_SERVICE_TYPE_OPTIONS(state, option) {
        state.fence_security_service_type_options.push(option)
    },
    SET_FENCE_SECURITY_SERVICE_GATE_OPTIONS(state, options){
        state.fence_security_service_gate_options = options
    },
    ADD_FENCE_SECURITY_SERVICE_GATE_OPTIONS(state, option) {
        state.fence_security_service_gate_options.push(option)
    },
    ADD_FENCE_SECURITY_SERVICE_SHIFT(state, fence_security_service_shifts) {
        fence_security_service_shifts.start_hour =  fence_security_service_shifts.start_hour.split(':')[0] + ':' +  fence_security_service_shifts.start_hour.split(':')[1]
        fence_security_service_shifts.end_hour =  fence_security_service_shifts.end_hour.split(':')[0] + ':' +  fence_security_service_shifts.end_hour.split(':')[1]
        state.fence_security_service_shifts.push(fence_security_service_shifts)
    },
    EMPTY_FENCE_SECURITY_SERVICE_SHIFTS(state){
        state.fence_security_service_shifts = []
    },
    SET_ERROR_FENCE_SECURITY_SERVICE_SHIFTS(state, error_fence_security_service_shifts) {
        state.error_fence_security_service_shifts.push(error_fence_security_service_shifts)
    },
    UPDATE_FENCE_SECURITY_SERVICE(state, fence_security_service) {
        state.fence_security_service = Object.keys(state.fence_security_service).map(updated_fence_security_service => {
            if(updated_fence_security_service.id === fence_security_service.id) {
                updated_fence_security_service.id_supplier_responsible = fence_security_service.id_supplier_responsible
                updated_fence_security_service.note = fence_security_service.note
                updated_fence_security_service.mail_text = fence_security_service.mail_text
                updated_fence_security_service.note_info = fence_security_service.note_info
            }
            return updated_fence_security_service
        })
    },
    UPDATE_FENCE_SECURITY_SERVICE_SHIFT(state, fence_security_service_shift) {
        state.fence_security_service_shifts = state.fence_security_service_shifts.map(updated_fence_security_service_shift => {
            if(updated_fence_security_service_shift.id === fence_security_service_shift.id) {
                updated_fence_security_service_shift.quantity = fence_security_service_shift.quantity
                updated_fence_security_service_shift.id_gate_option = fence_security_service_shift.id_gate_option
                updated_fence_security_service_shift.id_type_option = fence_security_service_shift.id_type_option
                updated_fence_security_service_shift.start_hour = fence_security_service_shift.start_hour.split(':')[0] + ':' +  fence_security_service_shift.start_hour.split(':')[1] 
                updated_fence_security_service_shift.end_hour = fence_security_service_shift.end_hour.split(':')[0] + ':' + fence_security_service_shift.end_hour.split(':')[1] 
            }
            return updated_fence_security_service_shift
        })
    },
    DELETE_FENCE_SECURITY(state, id) {
        let index_sec = state.fence_security_services_by_day.findIndex(fence_security_services_by_day => fence_security_services_by_day.id == id)
        state.fence_security_services_by_day.splice(index_sec, 1)

        let index = state.fence_security_service.findIndex(fence_security_service => fence_security_service.id == id)
        state.fence_security_service.splice(index, 1)
    },
    DELETE_FENCE_SECURITY_SERVICE_SHIFT(state, id) {
        let index = state.fence_security_service_shifts.findIndex(fence_security_service_shifts => fence_security_service_shifts.id == id)
        state.fence_security_service_shifts.splice(index, 1)
    },
    DELETE_FENCE_SECURITY_SERVICE_TYPE_OPTIONS(state, id) {
        let index = state.fence_security_service_type_options.findIndex(fence_security_service_type_options => fence_security_service_type_options.id == id)
        state.fence_security_service_type_options.splice(index, 1)
    },
    DELETE_FENCE_SECURITY_SERVICE_GATE_OPTIONS(state, id) {
        let index = state.fence_security_service_gate_options.findIndex(fence_security_service_gate_options => fence_security_service_gate_options.id == id)
        state.fence_security_service_gate_options.splice(index, 1)
    },
    UPDATE_MAIL_STATUS_FENCE_SECURITY(state, cond) {
        if(cond == true) {
            state.send_fence_security_mail = true    
        }
    },
    UPDATE_STATUS_MAIL_FENCE_SECURITY(state) {
        state.send_fence_security_mail = false
    },
    SET_ERROR_FENCE_SECURITY(state, error) {
        state.error_fence_security_service_shifts = error
    },
    SET_EMPTY_ERROR_SHIFT(state) {
        state.error_fence_security_service_shifts = {'general': 'Cannot create shift with empty fields'}
    },
    ERROR_ADD_FENCE_SECURITY_SERVICE(state, error) {
        state.error_fence_security_service = error
    },
    CLEAN_ERROR_FENCE_SECURITY(state) {
        state.error_fence_security_service_shifts = []
    },
    ADD_SERVICE_SHEET_TO_FENCE_SERVICE(state, fence_security) {
        state.fence_security_service = fence_security
        state.fence_security_services_by_day = state.fence_security_services_by_day.map(updated_fence_security_services_by_day => {
            if(updated_fence_security_services_by_day.id === fence_security.id) {
                updated_fence_security_services_by_day.id_service_sheet = fence_security.id_service_sheet
            }
            return updated_fence_security_services_by_day
        })      
    },
    REMOVE_SERVICE_SHEET_TO_FENCE_SERVICE(state, fence_security) {
        state.fence_security_service = fence_security
        state.fence_security_services_by_day = state.fence_security_services_by_day.map(updated_fence_security_services_by_day => {
            if(updated_fence_security_services_by_day.id === fence_security.id) {
                updated_fence_security_services_by_day.id_service_sheet = fence_security.id_service_sheet
            }
            return updated_fence_security_services_by_day
        })      
    },
    SET_SERVICE_SHEETS_FROM_FENCE(state, service_sheets) {
        state.fence_security_service_sheets = service_sheets
    },
    SET_ERROR_FENCE_SECURITY_SERVICE_TYPE_OPTIONS(state, payload) {
        state.error_fence_security_service_type_options = payload
    },
    EMPTY_ERROR_FENCE_SECURITY_SERVICE_TYPE_OPTIONS(state){
        state.error_fence_security_service_type_options = []
    },
    SET_ERROR_FENCE_SECURITY_SERVICE_GATE_OPTIONS(state, payload) {
        state.error_fence_security_service_gate_options = payload
    },
    EMPTY_ERROR_FENCE_SECURITY_SERVICE_GATE_OPTIONS(state){
        state.error_fence_security_service_gate_options = []
    },
    CREATED_AUTO(state) {
        state.created_auto = true
    },
    CREATED_AUTO_DELETE(state) {
        state.created_auto = false
    }
}   

const actions = {
    async load_fence_security_service({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'fence_security/' + id + '/')
            .then((response) => {
                commit('SET_FENCE_SECURITY_SERVICE', response.data)
            })
    },
    async load_fence_security_service_by_day({commit}, date) {
        await axios.get(process.env.VUE_APP_API_URL + 'fence_security/get_services_by_day/' + date + '/')
            .then((response) => {
                commit('SET_FENCE_SECURITY_SERVICE_BY_DAY', response.data, )
            })
    },
    async create_past_fence_security({commit}, data){
        await axios.post(process.env.VUE_APP_API_URL + 'fence_security/create_past_fence_security/' + data.id_ss + '/'+ data.date + '/')
            .then((response) => {
                if(response.status == "200") {
                    commit('SET_FENCE_SECURITY_SERVICE_BY_DAY', response.data)
                    commit('CREATED_AUTO')
                }
                
            })
            .catch(() => {})
    },
    async load_fence_security_service_shifts({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'fence_security_service/fence/' + id)
            .then((response) => {
                commit('SET_FENCE_SECURITY_SERVICE_SHIFTS', response.data)
            })
    },
    async load_fence_security_service_type_options({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + 'fence_security_service_type_option/') 
            .then((response) => {
                commit('SET_FENCE_SECURITY_SERVICE_TYPE_OPTIONS', response.data)
            })
    },
    async load_fence_security_service_gate_options({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + 'fence_security_service_gate_option/') 
            .then((response) => {
                commit('SET_FENCE_SECURITY_SERVICE_GATE_OPTIONS', response.data)
            })
    },
    async empty_fence_security_service_shifts({commit}) {
        commit('EMPTY_FENCE_SECURITY_SERVICE_SHIFTS')
    },
    async create_fence_security_service({commit}, fence_security_service) {
        await axios.post(process.env.VUE_APP_API_URL + 'fence_security/', {
            id_service_sheet: fence_security_service.id_service_sheet,
            responsible: fence_security_service.responsible,
            id_supplier: fence_security_service.id_supplier,
            note: fence_security_service.note,
            date: fence_security_service.date
        })
            .then((response) => {
                commit('ADD_FENCE_SECURITY_SERVICE', response.data)
            })
            .catch((error) => {
                commit('ERROR_ADD_FENCE_SECURITY_SERVICE', error.response.data)
            })
    },
    async create_fence_security_service_shift({commit}, fence_security_service_shifts) {
        var start_datetime = ''
        var end_datetime = ''
        if(typeof fence_security_service_shifts.start_datetime_hour === 'string') {
            start_datetime = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + fence_security_service_shifts.start_datetime_hour))
            start_datetime = moment(start_datetime).format().split('+')[0]
            start_datetime = start_datetime.split('T')[1].split(':')[0] + ':' + start_datetime.split('T')[1].split(':')[1]
        }
        if(typeof fence_security_service_shifts.end_datetime_hour === 'string') {
            end_datetime = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + fence_security_service_shifts.end_datetime_hour))
            end_datetime = moment(end_datetime).format().split('+')[0]
            end_datetime = end_datetime.split('T')[1].split(':')[0] + ':' + end_datetime.split('T')[1].split(':')[1]
        }
        if(typeof fence_security_service_shifts.start_datetime_hour === 'object') {
            start_datetime = moment(fence_security_service_shifts.start_datetime_hour).format().split('+')[0]
            start_datetime = start_datetime.split('T')[1].split(':')[0] + ':' + start_datetime.split('T')[1].split(':')[1]
        }
        if(typeof fence_security_service_shifts.end_datetime_hour === 'object'){
            end_datetime = moment(fence_security_service_shifts.end_datetime_hour).format().split('+')[0]
            end_datetime = end_datetime.split('T')[1].split(':')[0] + ':' + end_datetime.split('T')[1].split(':')[1]
        }
        await axios.post(process.env.VUE_APP_API_URL + 'fence_security_service/', {
            id_type_option: fence_security_service_shifts.security_type,
            id_gate_option: fence_security_service_shifts.service_type,
            start_hour: start_datetime,
            end_hour: end_datetime,
            quantity: fence_security_service_shifts.quantity,
            fence_security: fence_security_service_shifts.fence_security,
            is_estimated: fence_security_service_shifts.is_estimated
        })
            .then((response) => {

                commit('CLEAN_ERROR_FENCE_SECURITY')
                commit('ADD_FENCE_SECURITY_SERVICE_SHIFT', response.data)
            })
            .catch((error) => {
                commit('CLEAN_ERROR_FENCE_SECURITY')
                commit('SET_ERROR_FENCE_SECURITY_SERVICE_SHIFTS', error.response.data)
            })
    },
    async create_fence_security_service_type_option({commit}, name) {
        await axios.post(process.env.VUE_APP_API_URL + 'fence_security_service_type_option/', {
            name: name 
        })
            .then((response) => {
                commit('EMPTY_ERROR_FENCE_SECURITY_SERVICE_TYPE_OPTIONS')
                commit('ADD_FENCE_SECURITY_SERVICE_TYPE_OPTIONS', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_FENCE_SECURITY_SERVICE_TYPE_OPTIONS', error.response.data)
            })
    },
    async create_fence_security_service_gate_option({commit}, name) {
        await axios.post(process.env.VUE_APP_API_URL + 'fence_security_service_gate_option/', {
            name: name 
        })
            .then((response) => {
                commit('EMPTY_ERROR_FENCE_SECURITY_SERVICE_GATE_OPTIONS')
                commit('ADD_FENCE_SECURITY_SERVICE_GATE_OPTIONS', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_FENCE_SECURITY_SERVICE_GATE_OPTIONS', error.response.data)
            })
    },
    async update_fence_security_service({commit}, fence_security_service) {
        var responsible = ''
        if(!fence_security_service.id_supplier_responsible) {
            responsible = null
        } else {
            responsible = fence_security_service.id_supplier_responsible.id
        }
        await axios.patch(process.env.VUE_APP_API_URL + 'fence_security/' + fence_security_service.id + '/', {
            note: fence_security_service.note, 
            id_supplier_responsible: responsible, 
            id_service_sheet: fence_security_service.id_service_sheet,
            mail_text: fence_security_service.mail_text,
            note_info: fence_security_service.note_info
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_FENCE_SECURITY', response.data.edited)
                commit('UPDATE_FENCE_SECURITY_SERVICE', response.data)
            })
    },
    async update_fence_security_services_shifts({commit}, fence_security_service_shifts){
        if(typeof fence_security_service_shifts.start_hour === 'object') {
            fence_security_service_shifts.start_hour = moment(fence_security_service_shifts.start_hour).format().split('T')[1].split(':')[0] + ':' + moment(fence_security_service_shifts.start_hour).format().split('T')[1].split(':')[1]
        }

        if(typeof fence_security_service_shifts.end_hour === 'object') {
            fence_security_service_shifts.end_hour = moment(fence_security_service_shifts.end_hour).format().split('T')[1].split(':')[0] + ':' + moment(fence_security_service_shifts.end_hour).format().split('T')[1].split(':')[1]
        }
        await axios.patch(process.env.VUE_APP_API_URL + 'fence_security_service/' + fence_security_service_shifts.id + '/', {
            end_hour: fence_security_service_shifts.end_hour,
            quantity: fence_security_service_shifts.quantity,
            id_gate_option: fence_security_service_shifts.id_gate_option.id,
            id_type_option: fence_security_service_shifts.id_type_option.id,
            start_hour: fence_security_service_shifts.start_hour
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_FENCE_SECURITY', response.data.edited)
                commit('UPDATE_FENCE_SECURITY_SERVICE_SHIFT', response.data)
            }).catch((error) => {
                commit('CLEAN_ERROR_FENCE_SECURITY')
                commit('SET_ERROR_FENCE_SECURITY_SERVICE_SHIFTS', error.response.data)
            })
    },
    async add_service_sheet_to_fence_service({commit}, fence_security_service) {
        await axios.patch(process.env.VUE_APP_API_URL + 'fence_security/' + fence_security_service.id + "/", {
            id_service_sheet: fence_security_service.id_service_sheet
        })
            .then((response) => {
                commit('ADD_SERVICE_SHEET_TO_FENCE_SERVICE', response.data)
            })
    },
    async remove_service_sheet_to_fence_service({commit}, fence_security_service) {
        await axios.patch(process.env.VUE_APP_API_URL + 'fence_security/' + fence_security_service.id + "/", {
            id_service_sheet: fence_security_service.id_service_sheet
        })
            .then((response) => {
                commit('REMOVE_SERVICE_SHEET_TO_FENCE_SERVICE', response.data)
                commit('CREATED_AUTO_DELETE')
            })
    },
    async delete_fence_security({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'fence_security/' + id + '/')
            .then(() => {
                commit('DELETE_FENCE_SECURITY', id)
            }) 
            .catch((error) => {
                commit('SET_ERROR_FENCE_SECURITY', error.response)
            })
    },
    async delete_fence_security_service({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'fence_security_service/' + id + '/')
            .then(() => {
                commit('DELETE_FENCE_SECURITY_SERVICE_SHIFT', id)
            })
    },
    async delete_fence_security_service_type_option({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "fence_security_service_type_option/" + id + '/')
            .then(() => {
                commit('DELETE_FENCE_SECURITY_SERVICE_TYPE_OPTIONS', id)
            })
    },
    async delete_fence_security_service_gate_option({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "fence_security_service_gate_option/" + id + '/')
            .then(() => {
                commit('DELETE_FENCE_SECURITY_SERVICE_GATE_OPTIONS', id)
            })
    },
    async send_fence_security_service_mail({commit}, data) {
        await axios.post(process.env.VUE_APP_API_URL + 'fence_security/send_mail/' + data.id_ss + '/' + data.id + '/')
            .then(() => {
                commit('UPDATE_STATUS_MAIL_FENCE_SECURITY')
                commit('CLEAN_ERROR_FENCE_SECURITY')
            })
            .catch((error) => {
                commit('SET_ERROR_FENCE_SECURITY', error.response.data)
            })
    },
    async set_error_empty_shift({commit}) {
        commit('SET_EMPTY_ERROR_SHIFT')
    },
    async clean_errors_fence_security_shifts({commit}) {
        commit('CLEAN_ERROR_FENCE_SECURITY')
    },
    async get_ss_from_fence_service({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'service_sheets/get_by_fence_security_service/' + id + '/')
            .then((response) => {
                commit('SET_SERVICE_SHEETS_FROM_FENCE', response.data)
            })
    },
}

const getters = {
    get_fence_security_service: (state) => state.fence_security_service,
    get_fence_security_service_by_day: (state) => state.fence_security_services_by_day,
    get_filtered_fence_by_day: (state) => (name) => state.fence_security_services_by_day.filter(fence_security_services_by_day => {
        return fence_security_services_by_day.id_supplier.name == name
    }),
    //get_filtered_fence_by_day: (state) => (name) => {
    //    console.log(name)
    //    console.log('---')
    //    return state.fence_security_services_by_day.filter(item => item.id_supplier.name === name)
    // },
    get_fence_security_service_shifts: (state) => state.fence_security_service_shifts,
    get_fence_security_service_shifts_estimated: (state) =>  {
        return state.fence_security_service_shifts.filter(item => item.is_estimated === true)
    },
    get_fence_security_service_shifts_final: (state) =>  {
        return state.fence_security_service_shifts.filter(item => item.is_estimated === false)
    },
    get_send_fence_security_mail: (state) => state.send_fence_security_mail,
    get_error_fence_security_service: (state) => state.error_fence_security_service,
    get_error_fence_security_service_shifts: (state) => state.error_fence_security_service_shifts,
    get_fence_security_service_sheets: (state) => state.fence_security_service_sheets,
    get_fence_security_service_type_options: (state) => state.fence_security_service_type_options,
    get_fence_security_service_gate_options: (state) => state.fence_security_service_gate_options,
    error_fence_security_service_type_options: (state) => state.error_fence_security_service_type_options,
    error_fence_security_service_gate_options: (state) => state.error_fence_security_service_gate_options,
    created_auto: (state) => state.created_auto,
}

export default {
    state,
    mutations,
    actions,
    getters
};