import axios from 'axios'

const state = {
    current_calls: []
}

const mutations = {
    SET_CURRENT_CALLS(state, current_calls) {
        state.current_calls = current_calls
    },
    EMPTY_CURRENT_CALLS(state) {
        state.current_calls = []
    }
}

const actions = {
    async load_current_calls({commit}){
        await axios.get(process.env.VUE_APP_API_URL  + "calls/get_active_calls/")
            .then((response) => {
                commit('SET_CURRENT_CALLS', response.data)
            })
    },
    async empty_current_calls({commit}){
        commit('EMPTY_CURRENT_CALLS')
    }
}

const getters = {
    current_calls: (state) => state.current_calls,
}

export default {
    state,
    mutations,
    actions,
    getters
};