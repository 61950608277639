<template>
    <div class="content-section introduction mt-4">
        <div class="feature-intro">
            <h1>New Bill</h1>
            <p> Complete the steps to create a new bill.</p>
        </div>
    </div>

    <div class="mt-5">
        <Steps :model="items" :readonly="true" aria-label="Form Steps" />
    </div>
    
    <router-view v-slot="{Component}" :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete" @complete_another="complete_another">
        <keep-alive>
            <component :is="Component"/>
        </keep-alive>
    </router-view> 
</template>

<script>
import Steps from 'primevue/steps';

export default {
    name: 'Supplier_selection',
    components: {
        Steps
    },
    data() {
        return {
            items: [
                {
                    label: 'Supplier',
                    to: '/billing/steps'
                },
                {
                    label: 'Bill',
                    to: '/billing/bill_selection'
                },
                {
                    label: 'Information',
                    to: '/billing/bill_information'
                },
                {
                    label: 'Imports',
                    to: '/billing/bill_confirmation'
                },
            ],
            formObject: {}
        }
    },
    methods: {
        nextPage(event) {
            for (let field in event.formData) {
                this.formObject[field] = event.formData[field];
            }

            this.$router.push(this.items[event.pageIndex + 1].to);
        },
        prevPage(event) {
            this.$router.push(this.items[event.pageIndex - 1].to);
        },
        async complete() {
            this.$router.push({name: 'Billing_dashboard'})
        },
        async complete_another() {
            await this.$store.dispatch('create_new_bill', this.formObject)
            this.$router.push(this.items[0].to);
        }
    }
}
</script>

<style scoped>

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    background-color: #f8f9fa;
    padding: 1rem;
}

</style>