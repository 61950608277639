import axios from 'axios'

const state = {
    passengers: [],
    passenger_errors:{}
}

const mutations = {
    SET_PASSENGERS(state, passengers) {
        state.passengers = passengers
    },
    NEW_PASSENGER(state, passenger) {  
        state.passengers.push(passenger)
        state.passenger_errors = {}
    },
    DELETE_PASSENGER(state, id) {
        let index = state.passengers.findIndex(passengers => passengers.id == id)
        state.passengers.splice(index, 1)
    },
    UPDATE_PASSENGER(state, passenger) {
        state.passengers = state.passengers.map(updated_passenger => {
            if(updated_passenger.id === passenger.id) {
                updated_passenger.first_name = passenger.first_name
                updated_passenger.last_name = passenger.last_name
                updated_passenger.dni_pass = passenger.dni_pass
                updated_passenger.id_call = passenger.id_call
            }
            return updated_passenger
        })
        state.passenger_errors = {}
    },
    ERRORS_PASSENGER(state, errors){
        state.passenger_errors = errors
    }
}

const actions = {
    async load_all_passengers({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "passengers/")
            .then((response) => {
                commit('SET_PASSENGERS', response.data)
            })
    },
    async create_passenger({commit}, passenger) {
        await axios.post(process.env.VUE_APP_API_URL  + 'passengers/', { first_name: passenger.first_name, last_name: passenger.last_name, dni_pass: passenger.dni_pass, id_call: passenger.id_call.id })
            .then((response) => {
                commit('NEW_PASSENGER', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_PASSENGER', error.response.data)
            })
    },
    delete_passenger({commit}, id) {
        axios.delete(process.env.VUE_APP_API_URL + 'passengers/' + id)
            .then(() => {
                commit('DELETE_PASSENGER', id);
            })
            
    }, 
    async update_passenger({commit}, passenger){
        await axios.put(process.env.VUE_APP_API_URL  + 'passengers/' + passenger.id + '/',  { first_name: passenger.first_name, last_name: passenger.last_name, dni_pass: passenger.dni_pass, id_call: passenger.id_call.id })
            .then(() => {
                
                commit('UPDATE_PASSENGER', passenger)
            })
            .catch((error) => {
                
                commit('ERRORS_PASSENGER', error.response.data)
            })
    },
}

const getters = {
    all_passengers: (state) => state.passengers,
    get_passenger_errors: (state) => state.passenger_errors
}

export default {
    state,
    mutations,
    actions,
    getters
};