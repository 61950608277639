<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div v-if="$can('add','task_category') " class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Incidence Categories </h1>
            <div class="card p-1">
                <Button data-cy="new_incidence_category_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Task Category'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_incidence_category_modal" header="New Incidence category" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <form id="form" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">    
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">     
                                <InputText id="title" v-model="v$.title.$model" :class="{'p-invalid':v$.title.$invalid && submitted || incidence_category_errors.title}" />
                                <label for="title" :class="{'p-error':v$.title.$invalid && submitted}"> Title* </label>
                                <small v-if="Object.keys(incidence_category_errors).length !== 0 && incidence_category_errors.title" class="p-error"><span v-for="error in incidence_category_errors.title" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">{{v$.title.required.$message.replace('Value', 'Title')}}</small>
                        </div>
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_incidence_type" id="incidence_type" v-model="incidence_type" :options="incidence_type_choices" :class="{'p-invalid':v$.incidence_type.$invalid && submitted || incidence_category_errors.incidence_type}" :filter="true"/>
                                <label for="incidence_type" :class="{'p-error':v$.incidence_type.$invalid && submitted || incidence_category_errors.incidence_type}">Incidence type*</label>
                                <small v-if="Object.keys(incidence_category_errors).length !== 0 && incidence_category_errors.incidence_type" class="p-error"><span v-for="error in incidence_category_errors.incidence_type" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.incidence_type.$invalid && submitted) || v$.incidence_type.$pending.$response" class="p-error">{{v$.incidence_type.required.$message.replace('Value', 'Incidence Type')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="description" v-model="v$.description.$model" :class="{'p-invalid':v$.description.$invalid && submitted || incidence_category_errors.description}" />
                                <label for="description" :class="{'p-error':v$.description.$invalid && submitted}"> Description* </label>
                                <small v-if="Object.keys(incidence_category_errors).length !== 0 && incidence_category_errors.description" class="p-error"><span v-for="error in incidence_category_errors.description" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.description.$invalid && submitted) || v$.description.$pending.$response" class="p-error">{{v$.description.required.$message.replace('Value', 'Description')}}</small>
                        </div>
                    </div> 
                </div>
            </form>
            <template #footer>
                <Button data-cy="create_incidence_category_button" form="form" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast'
import Dropdown from 'primevue/dropdown'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        Toast,
        InputText,
        Dropdown
    },
    data() {
        return {
            displayModal: false,
            title: '',
            description:'',
            incidence_type:null,
            incidence_type_choices:[
                "General", "Service Sheet", 'Both'
            ],
            incidence_category_errors:{},
            submitted: false,
            showMessage: false,
        }
    },
    created() {
        this.$store.dispatch('load_all_incidence_categories')
    },
    computed: {
        all_incidence_categories: function() {
            return this.$store.getters.all_incidence_categories
        },
        get_errors_incidence_category: function() {
            return this.$store.getters.get_errors_incidence_category
        }
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_incidence_category() {
            let incidence_categories = { 
                title: this.title,
                description: this.description,
                incidence_type: this.incidence_type,
            }
            await this.$store.dispatch('create_incidence_category', incidence_categories)
            this.incidence_category_errors = this.get_errors_incidence_category
            if(Object.keys(this.incidence_category_errors).length === 0){
                this.toggleDialog();
                this.resetForm()
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }
            
            let res = await this.add_incidence_category()

            if(res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence category added', life: 3000});
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.title = '';
            this.description = '';
            this.incidence_type = null,
            this.submitted = false;
        }
    },
    validations() {
        return {
            title: { required },
            description: { required },
            incidence_type: { required },
        }
    },
};
</script>

<style scoped>
</style>