<template>
    <ModalSupplierResponsibles/>
    <TableSupplierResponsibles/>
</template>

<script>
import TableSupplierResponsibles from '@/components/AdminDashboard/Supplier_responsibles/TableSupplier_responsibles.vue/'
import ModalSupplierResponsibles from '@/components/AdminDashboard/Supplier_responsibles/ModalSupplier_responsibles.vue/'
export default {
    name: 'Supplier_responsibles',
    components: {
        TableSupplierResponsibles,
        ModalSupplierResponsibles
    }
}
</script>

<style scoped>

</style>