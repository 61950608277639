<template>
    <div class="container-fluid" style="width: calc(100vw - 8rem);"> 
        <div class="grid justify-content-center mt-2">
            <div class="col">
                <p > <span @click="$router.push({name: 'Operation Diary'})" style="color: gray; margin-bottom: 0px; cursor: pointer;">Operation Book /</span></p>
                <h1 style="padding-top: 0px;"> Operation Diary Entries </h1>
            </div>
            <div class="col mt-auto text-end">
                <span class="p-float-label">
                    <span> Date Filter </span>
                    <Calendar v-model="filter_date" dateFormat="yy-mm-dd" selectionMode="range" :manualInput="false" @date-select="load_records()"/>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <DataTable :value="messages_between" paginator :rows="15" stripedRows removableSort responsiveLayout="scroll" scrollable scrollHeight="65vh"
                    :globalFilterFields="['date', 'hour', 'id_terminal.name', 'id_user.username', 'text']" filterDisplay="row"
                    v-model:filters="filters" :loading="loading">
                        <template #empty>
                            <span class="flex justify-content-center">
                                <span> No records found. </span>
                            </span>
                        </template>
                        <Column field="day" header="Date" :sortable="true" dataType="date" style="min-width: 10rem">
                            <template #body="slotProps">
                                {{ format_date(slotProps.data.day) }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <Calendar v-model="filterModel.value" dateFormat="yy-mm-dd" @date-select="filterCallback()" class="p-column-filter" placeholder="Any"/>
                            </template>
                        </Column>
                        <Column field="hour" header="Hour" :sortable="true" style="min-width: 10rem">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" placeholder="Any"/>
                            </template>
                            <template #body="slotProps">
                                {{ slotProps.data.hour }}
                            </template>
                        </Column>
                        <Column field="id_terminal" :sortable="true" header="Terminal" style="min-width: 6rem">
                            <template #filter="{ filterModel, filterCallback }">
                                <Dropdown :options="all_terminals"  v-model="filterModel.value" filter optionLabel="name" optionValue="name" @change="filterCallback()" class="p-column-filter" placeholder="Any"/>
                            </template>
                        </Column>
                        <Column field="id_user" :sortable="true" header="User" style="min-width: 15rem">
                            <template #filter="{ filterModel, filterCallback }">
                                <Dropdown :options="get_all_users" v-model="filterModel.value" filter optionLabel="username" optionValue="username" @change="filterCallback()" class="p-column-filter" placeholder="Any"/>
                            </template>
                        </Column>
                        <Column field="text" :sortable="true" header="Description" style="min-width: 35rem">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value"  @input="filterCallback()" class="p-column-filter" placeholder="Any"/>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Calendar from 'primevue/calendar'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'

import moment from 'moment'

import { FilterMatchMode } from 'primevue/api';

export default {
    name: 'Daily log summary',
    components: {
        DataTable,
        Column,
        Calendar,
        InputText,
        Dropdown,
    },
    data() {
        return{
            filters: {
                'day': {value: null, matchMode: FilterMatchMode.DATE_IS},
                'hour': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'id_terminal': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'id_user': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'text': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
            loading: false,
            filter_date: [new Date(moment().isoWeekday(1).format('YYYY-MM-DD')), new Date(moment().isoWeekday(7).format('YYYY-MM-DD'))],
        }
    },
    computed: {
        get_all_daily_logs: function(){
            return this.$store.getters.all_daily_logs
        },
        get_all_users: function() {
            return this.$store.getters.get_all_users
        },
        all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        messages_between: function() {
            return this.$store.getters.get_messages_between
        }
    },
    async mounted() {
        this.loading = true
        this.$store.dispatch('load_all_users')
        this.$store.dispatch('load_all_terminals')
        if(this.get_all_daily_logs.length == 0) {
            await this.$store.dispatch('load_messages_between_dates', this.filter_date)
            // await this.$store.dispatch('load_all_daily_logs')
           
        }
        
        this.loading = false
    },
    methods: {
        format_hour(value) {
            return moment(value).format('HH:mm')
        },
        format_date(value) {
            return moment(value).format('YYYY-MM-DD')
        },
        async load_records() {
            if(this.filter_date[1] != null) {
                this.loading = true
                await this.$store.dispatch('load_messages_between_dates', this.filter_date)
                this.loading = false
            }
        }
    }
}
</script>

<style>

</style>