import axios from 'axios'
import moment from 'moment'

const state = {
    water: {},
    water_readings: [],
    error_create_water_service: [],
    error_update_water_service: [],
}

const mutations = {
    SET_WATER_SERVICE(state, water) {
        state.water = water
    },
    SET_WATER_SERVICE_READINGS(state, water_readings) {
        state.water_readings = water_readings
    },
    CREATE_WATER_SERVICE(state, water) {
        state.water.push(water)
    },
    ADD_WATER_SERVICE(state, water_reading) {
        state.water_readings.push(water_reading)
    },
    UPDATE_WATER_SERVICE(state, water) {
        state.water_readings = state.water_readings.map(updated_water_readings => {
            if(updated_water_readings.id === water.id) {
                updated_water_readings.date = water.date
                updated_water_readings.initial_reading = water.initial_reading
                updated_water_readings.final_reading = water.final_reading
                updated_water_readings.difference = water.difference
                updated_water_readings.delivery_note = water.delivery_note
            }   
            return updated_water_readings
        })
    },
    DELETE_WATER_READING(state, id) {
        let index = state.water_readings.findIndex(water_readings => water_readings.id == id)
        state.water_readings.splice(index, 1)
    },
    DELETE_WATER_SERVICE(state, id) {
        let index = state.water.findIndex(water => water.id == id)
        state.water.splice(index, 1)
    },
    SET_ERROR_CREATE_WATER_SERVICE(state, error_create_water_service) {
        state.error_create_water_service = error_create_water_service
    },
    SET_ERROR_UPDATE_WATER_SERVICE(state, error_update_water_service) {
        state.error_update_water_service = error_update_water_service
    },
    CLEAN_ERROR_CREATE_WATER_SERVICE(state) {
        state.error_create_water_service = []
    },
    CLEAN_ERROR_UPDATE_WATER_SERVICE(state) {
        state.error_update_water_service = []
    },
    EMPTY_WATER_READINGS(state) {
        state.water_readings = []
    }
   
}

const actions = {
	async load_water_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'water/get_service/' + id) 
			.then((response) => {
				commit('SET_WATER_SERVICE', response.data)
			})
	},
    async load_water_service_readings({commit}, id){
        await axios.get(process.env.VUE_APP_API_URL + 'water_service/service_sheet/' + id) 
            .then((response) => {
                commit('SET_WATER_SERVICE_READINGS', response.data)
            })
    },
    async empty_water_readings({commit}) {
        commit('EMPTY_WATER_READINGS')
    },
    async create_water_service({commit}, id) {
        await axios.post(process.env.VUE_APP_API_URL + 'water/', {
            id_service_sheet: id
        })
            .then((response) => {
                commit('CREATE_WATER_SERVICE', response.data)
            })
    },
    async create_water_reading({commit}, water_reading) {
        if(water_reading.date){
            water_reading.date = moment(water_reading.date).format().split('T')[0]
        }

        var formData = new FormData();
        
        for(var item in water_reading){
            
            formData.append(item, water_reading[item]) 
        }
        
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }  

        await axios.post(process.env.VUE_APP_API_URL + 'water_service/',  formData, headers)
            .then((response) => {
                commit('CLEAN_ERROR_CREATE_WATER_SERVICE')
                commit('ADD_WATER_SERVICE', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_CREATE_WATER_SERVICE', error.response.data)
            })
    },
    async update_water_service({commit}, water) {
        delete water.id_service_sheet
        
        if(typeof water.delivery_note === 'undefined' || typeof water.delivery_note === 'string') {
            delete water.delivery_note
        }
        
        if(typeof water.date === 'object') {
            water.date = moment(water.date).format().split('T')[0]
        } else if (water.date.search('T') != -1) {
            water.date = moment(water.delivery_note_date).format().split('T')[0]
        }
        
        water.water = water.water.id

        var formData = new FormData();
        
        for(var item in water){
            
            formData.append(item, water[item]) 
        }
        
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }  


        await axios.patch(process.env.VUE_APP_API_URL + 'water_service/' + water.id + '/', formData, headers)
            .then((response) => {
                commit('CLEAN_ERROR_UPDATE_WATER_SERVICE')
                commit('UPDATE_WATER_SERVICE', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_UPDATE_WATER_SERVICE', error.response.data)
            })
            
    },
    async delete_water_service({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'water/' + id + '/')
            .then(() => {
                commit('DELETE_WATER_SERVICE', id)
            })
    },
    async delete_water_reading({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'water_service/' + id + '/')
            .then(() => {
                commit('DELETE_WATER_READING', id)
            })
    }
}

const getters = {
    get_water_service: (state) => state.water,
    get_water_readings: (state) => state.water_readings,
    get_error_create_water_service: (state) => state.error_create_water_service,
    get_error_update_water_service: (state) => state.error_update_water_service
}

export default {
    state,
    mutations,
    actions,
    getters
};