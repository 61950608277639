<template>
    <div class="container-fluid pt-5">
        <div class="row justify-content-between">
            <div class="col-12 md:col-3">
                <div class="content-section introduction">
                    <div class="feature-intro">
                        <h1> Task Scheduler </h1>
                    </div>
                </div> 
            </div>
            <div class="col-12 md:col lg:col-4">
                <div class="row justify-content-center">
                    <span v-for="item in status" :key="item" class="col-6 md:col">
                        <a href="#task_calendar">
                            <div class="card" style="cursor:pointer;" @click="change_table_status(item.name)" :class="{'active_status': item.name === status_table}">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <span> STATUS </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h4> {{item.name}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </span>
                </div>
            </div>
        </div>
        <div class="row mt-4 card">
            <DataTable :value="get_task_scheduler_filtered(status_table)" :paginator="true" :rows="10" stripedRows responsiveLayout="scroll" scrollHeight="50vh" scrollable
            v-model:filters="filters" filterDisplay="row" :globalFilterFields="['title', 'id_terminal.name', 'status', 'start_datetime']" :paginatorTemplate="paginatorTemplate"> 
                <template #empty>
                    <div class="col text-center">
                        No records found
                    </div>
                </template>
                <Column header="Name" field="name" style="min-width: 25rem">
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" placeholder="Search by name" />
                    </template>    
                </Column>
                <Column header="Frequency" field="type" style="min-width: 25rem">
                    <template #body="{data}">
                        {{format_frequency(data.frequency, data.type, data.days, data.date)}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <Dropdown :options="type" optionValue="option" optionLabel="option" type="text" v-model="filterModel.value" @change="filterCallback()" class="p-column-filter" placeholder="Search by frequency" />
                    </template>
                </Column>
                <Column header="Start" field="date" style="min-width: 7rem">
                    <template #body="{data}">
                        {{format_start_date(data.date)}}
                    </template>
                </Column>
                <Column header="End" field="expiration_day" style="min-width: 7rem">
                    <template #body="{data}">
                        {{format_end_date(data.expiration_day)}}
                    </template>
                </Column>
                <Column v-if="$ability.can('change','task_scheduler')" header="Edit" style="text-align: center; max-width: 7rem" bodyStyle="overflow: visible">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined" @click="edit_task_scheduler(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$ability.can('delete','task_scheduler')" header="Delete" style="text-align: center; max-width: 7rem" bodyStyle="overflow: visible">
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_task_scheduler(slotProps.data)"/>
                    </template>
                </Column> 
            </DataTable>
        </div>
        <div v-if="$ability.can('add','task_scheduler')" class="row mb-3">
            <div class="col">
                <Button icon="fa-regular fa-clock" label="New Scheduler" class="p-button-success p-button-raised" @click="open_modal_new_task"/>
            </div>
        </div>
    </div>

    <Dialog header="New Task" v-model:visible="new_task_modal" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '70vw'}" :modal="true">
        <div class="container p-fluid">
            <form id="form_new_task" @submit.prevent="handleSubmit(!v$.$invalid)">
                <div class="row">
                    <div class="col-12 lg:col-6">
                        <div class="row mt-3 container">
                            <div class="col">
                                <span class="p-float-label">
                                    <InputText id="task_title" v-model="task_title" :class="{'p-invalid':v$.task_title.$invalid && submitted}"/>
                                    <label for="task_title" :class="{'p-error':v$.task_title.$invalid && submitted } "> Title </label>
                                </span>                  
                                <small v-if="(v$.task_title.$invalid && submitted) || v$.task_title.$pending.$response" class="p-error">{{v$.task_title.required.$message.replace('Value', 'Title')}}</small>
                                <small v-if="Object.keys(get_error_task_scheduler).length > 0 && get_error_task_scheduler.name" class="p-error"> {{get_error_task_scheduler.name[0]}} </small>
                            </div>
                        </div>
                        <div class="row mt-2 container">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown v-model="task_terminal" :options="get_all_terminals" optionLabel="name" optionValue="id" :class="{'p-invalid':v$.task_terminal.$invalid && submitted}"/>
                                    <label :class="{'p-error':v$.task_terminal.$invalid && submitted } "> Terminal </label>
                                </span>
                                <small v-if="(v$.task_terminal.$invalid && submitted) || v$.task_terminal.$pending.$response" class="p-error">{{v$.task_terminal.required.$message.replace('Value', 'Terminal')}}</small>
                            </div>
                        </div>
                        <div class="row mt-2 container">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown v-model="task_category" :options="get_task_categories" optionLabel="name" optionValue="id" :class="{'p-invalid':v$.task_category.$invalid && submitted}"/>
                                    <label :class="{'p-error':v$.task_category.$invalid && submitted }"> Category </label>
                                </span>
                                <small v-if="(v$.task_category.$invalid && submitted) || v$.task_category.$pending.$response" class="p-error">{{v$.task_category.required.$message.replace('Value', 'Category')}}</small>
                            </div>
                        </div>
                        <div class="row mt-2 container">
                            <span class="col">
                                <span class="p-float-label">
                                    <Calendar v-model="task_hour" :timeOnly="true" :class="{'p-invalid':v$.task_hour.$invalid && submitted}" @change="task_hour = num2time($event)"/>
                                    <label :class="{'p-error':v$.task_hour.$invalid && submitted } "> Hour </label>
                                </span>
                                <small v-if="(v$.task_hour.$invalid && submitted) || v$.task_hour.$pending.$response" class="p-error">{{v$.task_hour.required.$message.replace('Value', 'Hour')}}</small>
                            </span>
                        </div>
                        <div class="row mt-2 container">
                            <div class="col">
                                <span class="p-float-label">
                                    <TextArea v-model="task_description" rows="4" :class="{'p-invalid':v$.task_description.$invalid && submitted}"/>
                                    <label :class="{'p-error':v$.task_description.$invalid && submitted } "> Description </label>
                                </span>
                                <small v-if="(v$.task_description.$invalid && submitted) || v$.task_description.$pending.$response" class="p-error">{{v$.task_description.required.$message.replace('Value', 'Description')}}</small>
                                <small v-if="Object.keys(get_error_task_scheduler).length > 0 && get_error_task_scheduler.description" class="p-error"> {{get_error_task_scheduler.description[0]}} </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6">
                        <div class="row container">
                            <div class="col">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Periodicity </h5>
                            </div>
                        </div>
                        <div class="row mt-3 container">
                            <div class="col-4 my-auto">
                                <span>Repeat every</span> 
                            </div>
                            <div class="col-3">
                                <InputNumber v-model="task_frequency" min="1" :class="{'p-invalid':v$.task_frequency.$invalid && submitted}"/> 
                            </div>
                            <div class="col-5">
                                <Dropdown v-model="task_type" :options="type" optionValue="option" optionLabel="title" @change="empty_task_days"/> 
                            </div>
                        </div>
                        <div class="row text-center">
                            <small v-if="(v$.task_frequency.$invalid && submitted) || v$.task_frequency.$pending.$response" class="p-error">{{v$.task_frequency.required.$message.replace('Value', 'Frequency')}}</small>
                        </div>
                        <div v-if="task_type == 'Weekly'" class="row container">
                            <div class="col"> 
                                <label> Which day/s</label>
                                <span class="p-float-label">
                                    <SelectButton v-model="task_days" :options="week_days" multiple :class="{'p-invalid':v$.task_days.$invalid && submitted}">
                                        <template #option="slotProps">
                                            {{slotProps.index}}
                                        </template>
                                    </SelectButton>
                                </span>
                            </div>
                            <small v-if="(v$.task_days.$invalid && submitted) || v$.task_days.$pending.$response" class="p-error">{{v$.task_days.required.$message.replace('The value is', 'Days are')}}</small>
                        </div>
                        <div v-if="task_type == 'Monthly'" class="row mt-3 container">
                            <div class="col">
                                <span class="p-float-label">
                                    <MultiSelect v-model="task_days" :options="month_days" :showToggleAll="false" :class="{'p-invalid':v$.task_days.$invalid && submitted}"/>
                                    <label> Days </label>
                                </span>
                                <small v-if="(v$.task_days.$invalid && submitted) || v$.task_days.$pending.$response" class="p-error">{{v$.task_days.required.$message.replace('The value is', 'Days are')}}</small>
                            </div>
                        </div>
                        <div v-if="task_type=='Monthly'" class="row mt-3 container">
                            <div class="col">
                                <span class="p-float-label">
                                    <Calendar v-model="task_date_start" view="month" dateFormat="MM yy" :class="{'p-invalid':v$.task_date_start.$invalid && submitted}"/>
                                    <label :class="{'p-error':v$.task_date_start.$invalid && submitted }"> Initial Date </label>
                                </span>
                                <small v-if="(v$.task_date_start.$invalid && submitted) || v$.task_date_start.$pending.$response" class="p-error">{{v$.task_date_start.required.$message.replace('Value', 'Initial Date')}}</small>
                                <small v-if="Object.keys(get_error_task_scheduler).length > 0 && get_error_task_scheduler.date" class="p-error"> {{get_error_task_scheduler.date[0]}} </small>
                            </div>
                        </div>
                        <div v-else class="row mt-3 container">
                            <div class="col">
                                <span class="p-float-label">
                                    <Calendar v-model="task_date_start" dateFormat="yy-mm-dd" :class="{'p-invalid':v$.task_date_start.$invalid && submitted}"/>
                                    <label :class="{'p-error':v$.task_date_start.$invalid && submitted }"> Initial Date </label>
                                </span>
                                <small v-if="(v$.task_date_start.$invalid && submitted) || v$.task_date_start.$pending.$response" class="p-error">{{v$.task_date_start.required.$message.replace('Value', 'Initial Date')}}</small>
                                <small v-if="Object.keys(get_error_task_scheduler).length > 0 && get_error_task_scheduler.date" class="p-error"> {{get_error_task_scheduler.date[0]}} </small>
                            </div>
                        </div>
                        <div v-if="task_type=='Monthly'" class="row mt-3 container">
                            <div class="col">
                                <span class="p-float-label">
                                    <Calendar v-model="task_date_end" view="month" dateFormat="MM yy"/>
                                    <label> Expiration Date </label>
                                </span>
                                <small v-if="Object.keys(get_error_task_scheduler).length > 0 && get_error_task_scheduler.expiration_day" class="p-error"> {{get_error_task_scheduler.expiration_day[0]}} </small>                            
                            </div>
                        </div>
                        <div v-else-if="task_type=='Annual'" class="row mt-3 container">
                            <div class="col">
                                <span class="p-float-label">
                                    <Calendar v-model="task_date_end" view="year" dateFormat="yy"/>
                                    <label > Expiration Date </label>
                                </span>
                                <small v-if="Object.keys(get_error_task_scheduler).length > 0 && get_error_task_scheduler.expiration_day" class="p-error"> {{get_error_task_scheduler.expiration_day[0]}} </small>      
                            </div>
                        </div>
                        <div v-else class="row mt-3 container">
                            <div class="col">
                                <span class="p-float-label">
                                    <Calendar v-model="task_date_end" dateFormat="yy-mm-dd"/>
                                    <label> Expiration Date </label>
                                </span>
                                <small v-if="Object.keys(get_error_task_scheduler).length > 0 && get_error_task_scheduler.expiration_day" class="p-error"> {{get_error_task_scheduler.expiration_day[0]}} </small>      
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <template #footer>
            <div class="row">
                <div class="col">
                    <Button label="Cancel" class="p-button-text" @click="new_task_modal = false"/>
                    <Button label="Create" type="submit" form="form_new_task"/>
                </div>
            </div>
        </template>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="delete_confirmation_task_scheduler" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div class="container">
            <div class="row">
                <div class="col">
                    <span> Are you sure you want to delete this task? </span>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="row">
                <div class="col">
                    <Button label="Cancel" class="p-button-text" @click="delete_confirmation_task_scheduler = false"/>
                    <Button label="Delete" @click="delete_task_scheduler"/> 
                </div>
            </div>
        </template>
    </Dialog>

    <Dialog header="Details" v-model:visible="display_edit_task_scheduler" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '70vw'}" :modal="true">
        <div class="container p-fluid">
            <div class="row">
                <div class="col ">
                    <div class="row mt-3 container">
                        <div class="col">
                            <span class="p-float-label">
                                <InputText v-model="editable_task_scheduler.name" />
                                <label> Title </label>
                            </span>
                        </div>
                    </div>
                    <div class="row mt-2 container">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown v-model="editable_task_scheduler.id_terminal.id" optionLabel="name" optionValue="id" :options="get_all_terminals"/>
                                <label> Terminal </label>
                            </span>
                        </div>
                    </div>
                    <div class="row mt-2 container">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown v-model="editable_task_scheduler.id_task_category.id" optionLabel="name" optionValue="id" :options="get_task_categories"/>
                                <label> Category </label>
                            </span>
                        </div>
                    </div> 
                    <div class="row mt-2 container">
                        <div class="col">
                            <span class="p-float-label">
                                <Calendar v-model="editable_task_scheduler.hour" :timeOnly="true"  @change="editable_task_scheduler.hour = num2time($event)"/>
                                <label> Hour </label>
                            </span>
                        </div>
                    </div> 
                    <div class="row mt-2 container">
                        <div class="col">
                            <span class="p-float-label">
                                <TextArea v-model="editable_task_scheduler.description" rows="4"/>
                                <label> Description </label>
                            </span>
                        </div>
                    </div>               
                </div>
                <div class="col container">
                    <div class="row">
                        <div class="col">
                            <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Periodicity </h5>
                        </div>
                    </div>
                    <div class="row mt-3 container">
                        <div class="col-4 my-auto">
                            <span>Repeat every</span> 
                        </div>
                        <div class="col-3">
                            <InputNumber v-model="editable_task_scheduler.frequency" min="1"/> 
                        </div>
                        <div class="col-5">
                            <h1>Hola</h1>
                            <Dropdown v-model="editable_task_scheduler.type" :options="type" optionValue="option" optionLabel="title" @change="edit_empty_task_days($event), change_date_format($event)"/> 
                        </div>
                    </div>
                    <div v-if="editable_task_scheduler.type == 'Weekly'" class="row container">
                        <div class="col"> 
                            <label class="mb-1"> Which day/s</label>
                            <span class="p-float-label">
                                <SelectButton v-model="editable_task_scheduler.days" :options="week_days" multiple>
                                    <template #option="slotProps">
                                        {{slotProps.index}}
                                    </template>
                                </SelectButton>
                            </span>
                            <small v-if="get_error_task_scheduler.days" class="p-error"> {{get_error_task_scheduler.days[0]}} </small>
                        </div>
                    </div>
                    
                    <div v-if="editable_task_scheduler.type == 'Monthly'" class="row container">
                        <div class="col">
                            <MultiSelect v-model="editable_task_scheduler.days" :options="month_days" :showToggleAll="false"/>
                        </div>
                        <small v-if="get_error_task_scheduler.days" class="p-error"> {{get_error_task_scheduler.days[0]}} </small>
                    </div>
                    <div v-if="editable_task_scheduler.type=='Monthly'" class="row mt-3 container">
                        <div class="col">
                            <span class="p-float-label">
                                <Calendar v-model="editable_task_scheduler.date" view="month" dateFormat="MM yy"/>
                                <label> Initial Date </label>
                            </span>
                            <small v-if="get_error_task_scheduler.date" class="p-error"> {{get_error_task_scheduler.date[0]}} </small>
                        </div>
                    </div>
                    <div v-else class="row mt-3 container">
                        <div class="col">
                            <span class="p-float-label">
                                <Calendar v-model="editable_task_scheduler.date" dateFormat="yy-mm-dd"/>
                                <label> Initial Date </label>
                            </span>
                            <small v-if="get_error_task_scheduler.date" class="p-error"> {{get_error_task_scheduler.date[0]}} </small>
                        </div>
                    </div>
                    <div v-if="editable_task_scheduler.type=='Monthly'" class="row mt-3 container">
                        <div class="col">
                            <span class="p-float-label">
                                <Calendar v-model="editable_task_scheduler.expiration_day" view="month" dateFormat="MM yy"/>
                                <label> Expiration Date </label>
                            </span>
                            <small v-if="get_error_task_scheduler.expiration_day" class="p-error"> {{get_error_task_scheduler.expiration_day[0]}} </small>
                        </div>
                    </div>
                    <div v-else-if="editable_task_scheduler.type == 'Annual'" class="row mt-3 container">
                        <div class="col">
                            <span class="p-float-label">
                                <Calendar v-model="editable_task_scheduler.expiration_day" view="year" dateFormat="yy"/>
                                <label> Expiration Date </label>
                            </span>
                            <small v-if="get_error_task_scheduler.expiration_day" class="p-error"> {{get_error_task_scheduler.expiration_day[0]}} </small>
                        </div>
                    </div>
                    <div v-else class="row mt-3 container">
                        <div class="col">
                            <span class="p-float-label">
                                <Calendar v-model="editable_task_scheduler.expiration_day" dateFormat="yy-mm-dd"/>
                                <label> Expiration Date </label>
                            </span>
                        </div>
                        <small v-if="get_error_task_scheduler.expiration_day" class="p-error"> {{get_error_task_scheduler.expiration_day[0]}} </small>
                    </div>
                </div>
            </div>  
        </div>
        <template #footer>
            <div class="row">
                <div class="col">
                    <Button label="Cancel" class="p-button-text" @click="display_edit_task_scheduler = false"/>
                    <Button label="Save" @click="save_task_scheduler"/> 
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import SelectButton from 'primevue/selectbutton'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Calendar from 'primevue/calendar'
import TextArea from 'primevue/textarea'
import MultiSelect from 'primevue/multiselect';

import { required, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";
import { num2time } from '@/modules/utilities.js'
import { FilterMatchMode } from 'primevue/api';

import moment from 'moment'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'Task periodicity',
    components: {
        Button,
        Dialog,
        InputText,
        InputNumber,
        Dropdown,
        SelectButton,
        DataTable,
        Column,
        Calendar,
        TextArea,
        MultiSelect,
    },
    data() {
        return {
            submitted: false,
            color: localStorage.getItem('primary_color'),
            new_task_modal: false,
            task_title: '',
            task_terminal: '',
            task_category: '',
            task_hour: '',
            task_description: '',
            task_frequency: 1,
            task_days: [],
            task_type: 'Daily',
            task_date_end: '',
            task_date_start: '',
            type: [     
                { title: 'Days', option: 'Daily'},
                { title: 'Weeks', option: 'Weekly'},
                { title: 'Months', option: 'Monthly'},
                { title: 'Years', option: 'Annual'}
            ],
            week_days: {'Mo': 0, 'Tu': 1, 'We': 2, 'Th': 3, 'Fr': 4, 'Sa': 5, 'Su': 6},
            month_days: [],
            delete_confirmation_task_scheduler: false,
            id_delete_task_scheduler: '',
            editable_task_scheduler: '',
            display_edit_task_scheduler: false,
            filters: {
                'name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'type': {value: null, matchMode: FilterMatchMode.EQUALS},
            },
            status: [
                {name: 'Active'},
                {name: 'Finished'},
                {name: 'Stand by'}
            ],
            status_table: 'Active',
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    computed: {
        get_task_scheduler: function() {
            return this.$store.getters.get_task_scheduler
        },
        get_all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        get_task_categories: function() {
            return this.$store.getters.get_task_categories
        },
        get_error_task_scheduler: function() {
            return this.$store.getters.get_error_task_scheduler
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        await this.$store.dispatch('load_task_schedulers')
        await this.$store.dispatch('load_all_terminals')
        await this.$store.dispatch('load_task_categories')
        for(let i = 1; i <= 31; i++) {
            this.month_days.push(i)
        }
        this.get_task_scheduler_filtered('Active')
        
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        num2time(data) {
            return num2time(data)
        },
        get_task_scheduler_filtered(status) {
            return this.$store.getters.get_filtered_task_scheduler(status)
        },
        change_table_status(item){
            this.status_table = item
        },
        empty_task_days() {
            this.task_days = ''
        },
        open_modal_new_task(){
            this.new_task_modal = true
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }
            
            await this.add_task();
            
        },
        async add_task() {

            let task = {}
            
            if(typeof(this.task_hour) != 'string') {
                this.task_hour = moment(this.task_hour).format('H:mm')
            }
            
            if(this.task_date_end == '' || this.task_date_end == null) {
                this.task_date_end = null
            } else {
                this.task_date_end = moment(this.task_date_end).format('YYYY-MM-DD')
            }

            task = {
                name: this.task_title,
                id_terminal: this.task_terminal,
                id_task_category: this.task_category,
                description: this.task_description,
                frequency: this.task_frequency,
                days: this.task_days,
                type: this.task_type,
                hour: this.task_hour,
                expiration_day: this.task_date_end,
                date: moment(this.task_date_start),
            }
            
            if(this.task_type == 'Monthly') {
                task.date = moment(task.date).set('day', moment().format('D')).format('YYYY-MM-DD')
            }
            
            if(this.task_type == 'Annual' || this.task_type == 'Daily') {
                delete task.days
            }   

            if(this.task_type == 'Annual') {
                task.date = moment(task.date).format('YYYY-MM-DD')
            }

            await this.$store.dispatch('new_task_schedulers', task)

            if(Object.keys(this.get_error_task_scheduler).length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Success', detail:'Task created successfully.', life: 3000});
                this.reset_form()
                this.submitted = false
                this.new_task_modal = false
            }
        },
        edit_task_scheduler(data) {
            this.$store.dispatch('empty_errors_task_scheduler')
            this.editable_task_scheduler = JSON.parse(JSON.stringify(data));
            if(data.type == 'Monthly'){
                this.editable_task_scheduler.date = moment(this.editable_task_scheduler.date).format('MMMM yy')
            }
            this.editable_task_scheduler.hour = this.editable_task_scheduler.hour.split(':')[0] + ':' + this.editable_task_scheduler.hour.split(':')[1]
            this.display_edit_task_scheduler = true
        },
        confirm_delete_task_scheduler(data) {
            this.id_delete_task_scheduler = data.id
            this.delete_confirmation_task_scheduler = true
        },
        delete_task_scheduler() {
            this.$store.dispatch('delete_task_scheduler', this.id_delete_task_scheduler)
            this.delete_confirmation_task_scheduler = false
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Task deleted.', life: 3000});
        },
        edit_empty_task_days() {
            this.editable_task_scheduler.days = []
            if(this.editable_task_scheduler.type == 'Annual') {
                this.editable_task_scheduler.date = moment(this.editable_task_scheduler.date).format('MMMM DD, YYYY')
                if(this.editable_task_scheduler.expiration_day != null) {
                    this.editable_task_scheduler.expiration_day = moment(this.editable_task_scheduler.expiration_day).format('MMMM DD, YYYY')
                }
            } else if(this.editable_task_scheduler.type == 'Daily') {
                this.editable_task_scheduler.date = moment(this.editable_task_scheduler.date).format('YYYY-MM-DD')
                if(this.editable_task_scheduler.expiration_day != null) {
                    this.editable_task_scheduler.expiration_day = moment(this.editable_task_scheduler.expiration_day).format('YYYY-MM-DD') 
                }
            } else if(this.editable_task_scheduler.type == 'Monthly') {
                this.editable_task_scheduler.date = moment(this.editable_task_scheduler.date).format('YYYY-MM-DD')
                if(this.editable_task_scheduler.expiration_day != null) {
                    this.editable_task_scheduler.expiration_day = moment(this.editable_task_scheduler.expiration_day).format('YYYY-MM-DD') 
                }
            } else if(this.editable_task_scheduler.type == 'Weekly') {
                this.editable_task_scheduler.date = moment(this.editable_task_scheduler.date).format('YYYY-MM-DD')
                if(this.editable_task_scheduler.expiration_day != null) {
                    this.editable_task_scheduler.expiration_day = moment(this.editable_task_scheduler.expiration_day).format('YYYY-MM-DD') 
                }
            }
        },
        async save_task_scheduler() {
            await this.$store.dispatch('update_task_scheduler', this.editable_task_scheduler)
            if(Object.keys(this.get_error_task_scheduler).length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Success', detail:'Task modified.', life: 3000});
                this.display_edit_task_scheduler = false
            }
        },
        format_end_date(data) {
            if(data == null) {
                return 'Not defined'
            }
            return new moment().to(moment(data));
        },
        format_start_date(data) {
            return moment(data).fromNow();
        },
        format_frequency(frequency, type, days, date) {
            if(type == "Daily") {
                if(frequency == 1) {
                    var text = 'day'
                    return 'Every ' + text
                } else {
                    text = 'days'
                    return 'Every ' + frequency + ' ' + text
                }
            } else if(type=="Weekly") {
                text = 'weeks'
                var week_days= {0 : 'Mondays', 1 : 'Tuesdays', 2 : 'Wednesdays', 3 : 'Thursdays', 4 : 'Fridays', 5 : 'Saturdays', 6 : 'Sundays'}
                var days_name = ''
                for(var i in days){
                    if(days.length > 1) {
                        if( i == days.length - 1) {
                            days_name = days_name + ' and ' + week_days[days[i]]
                        } else {
                            if(i == days.length - 2) {
                                days_name = days_name + ' ' + week_days[days[i]] 
                            } else {
                                days_name = days_name + ' ' + week_days[days[i]] + ','
                            }
                        }
                    } else {
                        days_name = days_name + ' ' + week_days[days[i]] 
                    } 
                }

                if(frequency == 1) {
                    text = 'week'
                    return 'The ' + days_name + ' of every ' +  text
                } else {
                    text = 'weeks'
                    return 'The ' +  days_name + ' of every ' + frequency + ' ' + text
                }
                
            } else if(type=="Monthly") {
                var days_numbers = ''
                var new_days = Object.values(days)
                for(var item in new_days) {
                    new_days[item] = this.ordinal_suffix_of(new_days[item])
                }
                for(var j in new_days){
                    if(new_days.length > 1) {
                        if( j == new_days.length - 1) {
                            days_numbers = days_numbers + ' and ' + new_days[j]
                        } else {
                            if(j == new_days.length - 2) {
                                days_numbers = days_numbers + ' ' + new_days[j]
                            } else {
                                days_numbers = days_numbers + ' ' + new_days[j] + ','
                            }   
                        }
                    } else {
                        days_numbers = days_numbers + ' ' + new_days[j]
                    } 
                }

                if(frequency == 1) {
                    text = 'month'
                    return 'The ' + days_numbers + ' of every ' + text 
                } else {
                    text = 'months'
                    return 'The ' + days_numbers + ' of every ' + frequency + ' ' + text 
                }
            } else if(type=="Annual") {
                if(frequency == 1) {
                    text = 'year'
                    return moment(date).format('MMMM Do') + ' of every ' + text 
                } else {
                    text = 'years'
                    return moment(date).format('MMMM Do') + ' every ' + frequency + ' ' +  text 
                }
            }
        },
        change_date_format(event) {
            if(event.value == 'Daily') {
                this.editable_task_scheduler.days = []
                this.editable_task_scheduler.date = moment(this.editable_task_scheduler.date).format('YYYY-MM-DD')
            } else if (event.value == 'Weekly') {
                this.editable_task_scheduler.days = []
                this.editable_task_scheduler.date = moment(this.editable_task_scheduler.date).format('YYYY-MM-DD')
            } else if (event.value == 'Monthly') {
                this.editable_task_scheduler.days = []
                this.editable_task_scheduler.date = moment(this.editable_task_scheduler.date).format('MMMM yy')
            } else if (event.value == 'Yearly') {
                this.editable_task_scheduler.days = []
                this.editable_task_scheduler.date = moment(this.editable_task_scheduler.date).format('YYYY-MM-DD')
            }
        },
        ordinal_suffix_of(i) {
            var j = i % 10,
                k = i % 100;
            if (j == 1 && k != 11) {
                return i + "st";
            }
            if (j == 2 && k != 12) {
                return i + "nd";
            }
            if (j == 3 && k != 13) {
                return i + "rd";
            }
            return i + "th";
        },
        reset_form(){
            this.task_title = ''
            this.task_terminal = ''
            this.task_category = ''
            this.task_description = ''
            this.task_frequency = 1
            this.task_days = ''
            this.task_type = 'Daily'
            this.task_hour = ''
            this.task_date_end = ''
            this.task_date_start = ''
        }
    },
    validations() {
        return {
            task_title: { required },
            task_terminal: { required },
            task_category: { required },
            task_hour: { required },
            task_description: { required },
            task_frequency: { required },
            task_date_start: { required },
            task_days: { 
                required: requiredIf(function() {
                    return this.task_type == 'Weekly' || this.task_type == 'Monthly'
                }) 
            }
        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

:deep(.p-datatable){
    padding: 0;
}

a{
    text-decoration: none;
}

:deep(a) {
    color: black !important;
}

.active_status {
    background-color: v-bind(color) !important;
    color: white;
}


</style>