<template>
    <ModalCrew/>
    <TableCrew/>
</template>

<script>
import TableCrew from '@/components/AdminDashboard/Crew/TableCrew.vue/'
import ModalCrew from '@/components/AdminDashboard/Crew/ModalCrew.vue/'
export default {
    name: 'Crew',
    components: {
        TableCrew,
        ModalCrew
    }
}
</script>

<style scoped>

</style>