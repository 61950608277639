<template>
    <ModalTask_categories/>
    <TableTask_categories/>
    
</template>

<script>
import TableTask_categories from '@/components/AdminDashboard/Task_categories/TableTask_categories.vue'
import ModalTask_categories from '@/components/AdminDashboard/Task_categories/ModalTask_categories.vue'
export default {
  name: 'Task_categories',
  components: {
    TableTask_categories,
    ModalTask_categories
  }
}
</script>

<style scoped>
</style> 
