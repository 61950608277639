import axios from 'axios'


const state = {
    ship_finger: {},
    ship_fingers: [],
    ship_finger_images: [],
    ship_finger_errors:{},
    delete_status:false
};

const mutations = {
    SET_SHIP_FINGERS(state, ship_fingers) {
        state.ship_fingers = ship_fingers
    },
    SET_SHIP_FINGER(state, ship_finger) {
        state.ship_finger = ship_finger
    },
    SET_SHIP_FINGERS_IMAGES(state, ship_finger_images){
        state.ship_finger_images = ship_finger_images
    },
    NEW_SHIP_FINGER(state, ship_fingers) {
        state.ship_fingers.push(ship_fingers)
        state.ship_finger_errors = {}
    },
    DELETE_SHIP_FINGER(state, id) {
        let index = state.ship_fingers.findIndex(ship_fingers => ship_fingers.id == id)
        state.ship_fingers.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_SHIP_FINGER(state, ship_finger) {
        state.ship_fingers = state.ship_fingers.map(updated_ship_finger => {
            if(updated_ship_finger.id === ship_finger.id) {
                updated_ship_finger.id_finger = ship_finger.id_finger
                updated_ship_finger.id_ship = ship_finger.id_ship
                updated_ship_finger.position = ship_finger.position
            }
            return updated_ship_finger
        })
        state.ship_finger_errors = {}
    },
    ERRORS_SHIP_FINGER(state, errors){
        state.ship_finger_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};

const actions = {
    async load_all_ship_fingers({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "ship_fingers/" )
            .then((response) => {
                commit('SET_SHIP_FINGERS', response.data)
            })
    },
    async load_ship_fingers_images({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "ship_fingers/get_by_service_sheet/" + id + '/')
            .then((response) => {
                commit('SET_SHIP_FINGERS_IMAGES', response.data)
            })
    },
    async load_ship_finger({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "ship_fingers/" + id)
            .then((response) => {
                commit('SET_SHIP_FINGER', response.data)
            })
    },
    async delete_ship_finger({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'ship_fingers/' + id)
            .then(() => {
                
                commit('DELETE_SHIP_FINGER', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
    }, 
    async create_ship_finger({commit}, ship_finger) {
        if(typeof ship_finger.picture.name != 'string'){
            delete ship_finger['picture']
        }
        if (ship_finger.id_finger !== null){
            ship_finger['id_finger'] = ship_finger.id_finger.id
        }
        if (ship_finger.id_ship !== null){
            ship_finger['id_ship'] = ship_finger.id_ship.id
        }
        var formData = new FormData();
        for(var item in ship_finger){
            formData.append(item, ship_finger[item])
        }
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
        await axios.post(process.env.VUE_APP_API_URL + 'ship_fingers/', formData, {headers})
            .then((response) => {  
                
                commit('NEW_SHIP_FINGER', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_SHIP_FINGER', error.response.data)
            })
    },
    async update_ship_finger({commit}, ship_finger) {
        if('picture' in ship_finger){
            if(typeof ship_finger.picture.name != 'string'){
                delete ship_finger['picture']
            }
        }

        var formData = new FormData();
        for(var item in ship_finger){
            if(item == 'id_ship' || item == 'id_finger'){
                formData.append(item, ship_finger[item].id)
            }else{
                formData.append(item, ship_finger[item])
            }
        }
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
        await axios.put(process.env.VUE_APP_API_URL + 'ship_fingers/' + ship_finger.id + '/',  formData, {headers})
            .then((response) => {
                commit('UPDATE_SHIP_FINGER', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SHIP_FINGER', error.response.data)
            })
    },
};

const getters = {
    all_ship_fingers: (state) => state.ship_fingers,
    get_errors_ship_finger: (state) => state.ship_finger_errors,
    get_delete_ship_finger_status:(state) => state.delete_status,
    get_ship_finger_by_name:(state) => (name)=>{
        return state.ship_fingers.filter(ship_finger => ship_finger.name === name).slice(-1)
    },
    get_ship_finger: (state) => state.ship_finger,
    get_ship_finger_images: (state) => state.ship_finger_images
};

export default {
    state,
    mutations,
    actions,
    getters
};