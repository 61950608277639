import axios from 'axios'

const state = {
   change_log: [],
   change_log_record: []
}

const mutations = {
    SET_CHANGE_LOG(state, change_log) {
        state.change_log = change_log
    },
    SET_CHANGE_LOG_RECORDS(state, change_log_record) {
        state.change_log_record = change_log_record
    },
    ADD_CHANGE_LOG(state, change_log) {
        state.change_log.unshift(change_log)
    },
    ADD_CHANGE_LOG_RECORD(state, change_log_record) {
        state.change_log_record.push(change_log_record)
    },
    DELETE_CHANGE_LOG(state, id) {
        let index = state.change_log.findIndex(change_log => change_log.id == id)
        state.change_log.splice(index, 1)
    },
    DELETE_CHANGE_LOG_RECORD(state, id) {
        let index = state.change_log_record.findIndex(change_log_record => change_log_record.id == id)
        state.change_log_record.splice(index, 1)
    }
}

const actions = {
    async load_change_log({commit}){
        await axios.get(process.env.VUE_APP_API_URL + 'change_log/')
            .then((response) => {
                commit('SET_CHANGE_LOG', response.data)
            })
    },
    async load_change_log_records({commit}){
        await axios.get(process.env.VUE_APP_API_URL + 'change_log_records/')
            .then((response) => {
                commit('SET_CHANGE_LOG_RECORDS', response.data)
            })
    },
    async create_new_version({commit}, id){
        await axios.post(process.env.VUE_APP_API_URL + 'change_log/', {
            version: id
        })
            .then((response) => {
                commit('ADD_CHANGE_LOG', response.data)
            })
    },
    async create_new_record({commit}, record) {
        await axios.post(process.env.VUE_APP_API_URL + 'change_log_records/', record)
            .then((response) => {
                commit('ADD_CHANGE_LOG_RECORD', response.data)
            })
    },
    async delete_version({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'change_log/' + id + '/')
            .then(() => {
                commit('DELETE_CHANGE_LOG', id)
            })
    },
    async delete_record({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'change_log_records/' + id + '/')
            .then(() => {
                commit('DELETE_CHANGE_LOG_RECORD', id)
            })
    }
}

const getters = {
    get_change_log: (state) => state.change_log,
    get_change_log_record: (state) => state.change_log_record
}

export default {
    state,
    mutations,
    actions,
    getters
};