<template>
    <div v-if="$can('view','call')">
        <div class="card ">
            <Toast/>
            <DataTable :value="calls_by_range" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading" removableSort 
            :globalFilterFields="['ship_agent','arrival_datetime','departure_datetime','id_ship.name','id_port.name']"
            v-model:filters="filters" >
                <template #header>
                    <div class="row">
                        <div class="table-header flex flex-column md:flex-row justify-content-between">
                            <Calendar v-model="dates" selectionMode="range" dateFormat="yy-mm-dd" class="input_small" @date-select="update_clockings()"></Calendar>
                            <span class="pt-2 md:pt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText id="filter_calls" v-model="filters['global'].value" placeholder="Search..." class="input_small"/>
                            </span>
                        </div>
                    </div>
                </template>
                <ColumnGroup type="header">
                    <Row>
                        <Column header="Arrival" :colspan="2" field="arrival_datetime" :sortable="true" />
                        <Column header="Departure" :colspan="2" field="departure_datetime" :sortable="true" />
                        <Column header="Terminal" :rowspan="2" field="terminals" :sortable="true" />
                        <Column header="Ship" :rowspan="2" field="id_ship.name" :sortable="true" />
                        <Column header="Ship Responsible" :rowspan="2"  field="id_ship_responsible.name" :sortable="true" />
                        <Column header="First Call" :rowspan="2"  field="is_new" :sortable="true"/>
                        <Column header="Unconventional" :rowspan="2"  field="is_unconventional" />
                        <Column header="View" :rowspan="2" />
                        <Column v-if="$can('change','call')" header="Edit" :rowspan="2" />
                        <Column v-if="$can('delete','call')" header="Delete" :rowspan="2"/>
                    </Row>
                    <Row>
                        <Column header="Date"/>
                        <Column header="Hour"/>
                        <Column header="Date"/>
                        <Column header="Hour"/>
                    </Row>
                </ColumnGroup>
                <Column field="arrival_datetime" style="min-width: 7rem;">
                    <template #body="slotProps">
                        {{format_date(slotProps.data.arrival_datetime)}}
                    </template>
                </Column>
                <Column field="arrival_datetime">
                    <template #body="slotProps">
                        {{format_hour(slotProps.data.arrival_datetime)}}
                    </template>
                </Column>
                <Column header="Departure Date" field="departure_datetime"  style="min-width: 7rem;">
                    <template #body="slotProps">
                        {{format_date(slotProps.data.departure_datetime)}}
                    </template>
                </Column>
                <Column header="Departure Hour" field="departure_datetime">
                    <template #body="slotProps">
                        {{format_hour(slotProps.data.departure_datetime)}}
                    </template>
                </Column>
                <Column header="Terminal" field="terminals"/>
                <Column header="Ship" field="id_ship.name"/>
                <Column header="Ship Responsible" field="id_ship_responsible.name" />
                <Column header="First Call" field="is_new">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.is_new == true">
                            <i class="pi pi-check-circle text-green-500 ml-4"></i>
                        </span>
                        <span v-else>
                            <i style="color: red;" class="pi pi-times-circle ml-4"></i>
                        </span>
                    </template>
                </Column>
                <Column header="Unconventional Ship" field="is_unconventional">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.is_unconventional == true">
                            <i class="pi pi-check-circle text-green-500 ml-4"></i>
                        </span>
                        <span v-else>
                            <i style="color: red;" class="pi pi-times-circle ml-4"></i>
                        </span>
                    </template>
                </Column>
                <Column style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="view_button" icon="pi pi-external-link" class="p-button-rounded p-button-outlined" severity="secondary" @click="$router.push({name: 'Service_sheet_menu', params: { id: slotProps.data.id}})"/>
                    </template>
                </Column>
                <Column v-if="$can('change','call')" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_call(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','call')" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_call(slotProps)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog v-model:visible="call_dialog" data-cy="edit_call_modal" :style="{ width: '60vw' }" :breakpoints="{ '960px': '80vw', '641px': '95vw' }" header="Call Details" :modal="true" class="p-fluid">
        <form id="form_edit_call" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-10 xl:col-3 md:col-6">
                        <div class="p-float-field">
                            <label for="arrival_datetime" class="pb-1 ps-1"> <strong> Arrival&nbsp;Date </strong> </label>
                            <Calendar autocomplete="off" id="arrival_datetime" v-model="call.arrival_datetime" dateFormat="yy-mm-dd"/>
                            <small class="p-error" v-if="submitted && !call.arrival_datetime">Arrival datetime is required.</small>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.arrival_datetime" class="p-error"><span v-for="error in call_errors.arrival_datetime" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                    <div class="col-10 xl:col-3 md:col-6">
                        <div class="p-float-field">
                            <label for="arrival_datetime_hour" class="pb-1 ps-1"> <strong>Hour</strong> </label>
                            <Calendar autocomplete="off" id="arrival_datetime_hour" v-model="call.arrival_hour" :timeOnly="true" hourFormat="24" @change="call.arrival_hour = num2timeedit($event)"/>
                            <small class="p-error" v-if="submitted && !call.arrival_hour">Arrival datetime is required.</small>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.arrival_hour" class="p-error"><span v-for="error in call_errors.arrival_hour" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                    <div class="col-10 xl:col-3 md:col-6">
                        <div class="p-float-field">
                            <label for="departure_datetime" class="pb-1 ps-1"> <strong> Departure&nbsp;Date </strong> </label>
                            <Calendar autocomplete="off" id="departure_datetime" v-model="call.departure_datetime" dateFormat="yy-mm-dd"/>
                            <small class="p-error" v-if="submitted && !call.departure_datetime">Departure datetime is required.</small>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.departure_datetime" class="p-error"><span v-for="error in call_errors.departure_datetime" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                    <div class="col-10 xl:col-3 md:col-6">
                        <div class="p-float-field">
                            <label for="departure_datetime_hour" class="pb-1 ps-1"> <strong>Hour</strong> </label>
                            <Calendar data-cy="edit_departure_time"  autocomplete="off" id="departure_datetime_hour" v-model="call.departure_hour" :timeOnly="true" hourFormat="24" @change="call.departure_hour = num2timeedit($event)"/>
                            <small class="p-error" v-if="submitted && !call.departure_hour">Departure datetime is required.</small>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.departure_hour" class="p-error"><span v-for="error in call_errors.departure_hour" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-10 md:col-12">
                        <div class="p-float-field">
                            <label for="ship" class="pb-1 ps-1"> <strong> Ship </strong></label>
                            <Dropdown data-cy="edit_dropdown_ship" id="id_ship" v-model="call.id_ship" :options="all_ships" optionLabel="name" :filter="true"  @change="load_ship_agent($event)"> 
                            <template #value="slotProps">
                                <div v-if="slotProps.value && !slotProps.value.value">
                                    <span>{{slotProps.value.name}}</span>
                                </div>
                                <div v-else-if="slotProps.label && slotProps.value.value">
                                    <span >{{slotProps.value.label}}</span>
                                </div>
                            </template>
                            </Dropdown>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.id_ship" class="p-error"><span v-for="error in call_errors.id_ship" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-10 md:col-12">
                        <div class="p-float-field">
                            <label for="ship_agent" class="pb-1 ps-1"> <strong> Ship Agent </strong> </label>
                            <Dropdown id="ship_agent" required="false" v-model="ship_agent.id" :options="get_ship_agents" optionValue="id" optionLabel="name" @change="load_ship_responsibles($event)"/>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.ship_agent" class="p-error"><span v-for="error in call_errors.ship_agent" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div v-if="call.id_ship_responsible" class="col-10 md:col-12">
                        <span v-if="typeof(call.id_ship_responsible)==='object'">
                            <div class="p-float-field">
                                <label for="ship_responsible" class="pb-1 ps-1"> <strong> Ship Responsible </strong> </label>
                                <Dropdown id="ship_responsible" v-model="call.id_ship_responsible.id" :options="get_ship_responsible" optionLabel="name" optionValue="id"/>
                                <small v-if="Object.keys(call_errors).length !== 0 && call_errors.id_ship_responsible" class="p-error"><span v-for="error in call_errors.id_ship_responsible" :key="error">{{error}}<br></span></small>
                            </div>
                        </span>
                        <span v-if="typeof(call.id_ship_responsible)==='number'">
                            <div class="p-float-field">
                                <label for="ship_responsible" class="pb-1 ps-1"> <strong> Ship Responsible </strong> </label>
                                <Dropdown id="ship_responsible" v-model="call.id_ship_responsible" :options="get_ship_responsible" optionLabel="name" optionValue="id"/>
                                <small v-if="Object.keys(call_errors).length !== 0 && call_errors.id_ship_responsible" class="p-error"><span v-for="error in call_errors.id_ship_responsible" :key="error">{{error}}<br></span></small>
                            </div>
                        </span>
                    </div>
                    <div v-else class="col-10 md:col-12">
                        <div class="p-float-field">
                            <label for="ship_responsible" class="pb-1 ps-1"> <strong> Ship Responsible </strong> </label>
                            <Dropdown id="ship_responsible" v-model="call.id_ship_responsible" :options="get_ship_responsible" optionLabel="name" optionValue="id"/>
                            <small v-if="Object.keys(call_errors).length !== 0 && call_errors.id_ship_responsible" class="p-error"><span v-for="error in call_errors.id_ship_responsible" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                    <div class="col-10 md:col-12">
                        <div class="p-float-label">
                            <Checkbox id="unconventional ship" v-model="call.is_new" :binary="true"></Checkbox>
                            <label class="ms-3"> <strong> First Call </strong> </label>
                        </div>
                    </div>
                    <div class="col-10 md:col-12">
                        <div class="p-float-label">
                            <Checkbox id="unconventional ship" v-model="call.is_unconventional" :binary="true"></Checkbox>
                            <label class="ms-3"> <strong> Unconventional Ship </strong> </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_call_dialog"/>
            <Button data-cy="save_edit_call" label="Save" class="p-button-raised" type="submit" form="form_edit_call"/>
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_call_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="call">Are you sure you want to delete the call on <b>{{format_time(call.arrival_datetime)}}</b> with the ship <b>{{call.id_ship.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_call_dialog = false"/>
            <Button label="Yes" data-cy="confirm_delete_button" @click="deleteCall" />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import Calendar from 'primevue/calendar'
import Checkbox from 'primevue/checkbox'

import moment from 'moment'

import { FilterMatchMode } from 'primevue/api';
import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'

import { num2timeedit } from '@/modules/utilities.js'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: "TableCalls",    
    components: {
        DataTable,
        Column,
        InputText,
        Button,
        Toast,
        Dialog,
        Dropdown,
        ColumnGroup,
        Row,
        Calendar,
        Checkbox
    },
    data() {
        return {
            call: {},
            call_errors: {},
            call_dialog: false,
            delete_call_dialog: false,
            submitted: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            ship_agent: '',
            loading: false,
            dates: [new Date(moment().startOf('month').subtract(3, 'months')), new Date(moment())],
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.calls_by_range.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_calls_by_range', this.dates)
            await this.$store.dispatch('load_all_ships')
            await this.$store.dispatch('load_all_ports')
            this.loading = false
        }
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_ships: function() {
            return this.$store.getters.all_ships
        },
        all_ports: function() {
            return this.$store.getters.all_ports
        },
        get_errors_call: function(){
            return this.$store.getters.get_errors_call
        },
        get_delete_call_status: function() {
            return this.$store.getters.get_delete_call_status
        },
        get_ship_agents: function()  {
            return this.$store.getters.get_ship_agents
        },
        get_ship_responsible: function()  {
            return this.$store.getters.get_ship_responsible 
        },
        calls_by_range: function() {
            return this.$store.getters.calls_by_range
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        num2timeedit(data) {
            return num2timeedit(data)
        },
        format_date(value) {
            if(value instanceof Date){
                return value.toISOString().split('T')[0]
            } else if (value !== null) {
                return value.split('T')[0]
            }else{
                return null
            }
        },
        format_hour(value) {
            if(value instanceof Date){
                return ((value.toISOString().split('T')[1]).split('+')[0]).split(':')[0] + ':' + ((value.toISOString().split('T')[1]).split('+')[0]).split(':')[1]
            } else if (value !== null){
                return ((value.split('T')[1]).split('+')[0]).split(':')[0] + ':' + ((value.split('T')[1]).split('+')[0]).split(':')[1]
            }else{
                return null
            }
        },
        format_time(value) {
            return moment(value).format('YYYY-MM-DD')
        },
        async update_clockings () {
            if(this.dates[1] != null) {
                this.loading = true
                await this.$store.dispatch('load_all_calls_by_range', this.dates)
                this.loading = false
            }
        },
        confirm_delete_call(call) {
            this.call = call.data;
            this.delete_call_dialog = true;
        },
        async deleteCall() {
            await this.$store.dispatch('delete_call', this.call.id)
            if (!this.get_delete_call_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Call deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Call can't be deleted", life: 3000});
            }
            this.delete_call_dialog = false;
            
        },
        async edit_call(call) {
            await this.$store.dispatch('empty_ship_agents')
            await this.$store.dispatch('empty_ship_responsibles')
            this.call_errors = {}
            this.call = {...call};
            this.call.arrival_hour = ((this.call.arrival_datetime.split('T')[1]).split('+')[0]).split(':')[0] + ':' + ((this.call.arrival_datetime.split('T')[1]).split('+')[0]).split(':')[1]
            this.call.departure_hour = ((this.call.departure_datetime.split('T')[1]).split('+')[0]).split(':')[0] + ':' + ((this.call.departure_datetime.split('T')[1]).split('+')[0]).split(':')[1]

            this.call.arrival_datetime = this.call.arrival_datetime.split('T')[0]
            this.call.departure_datetime = this.call.departure_datetime.split('T')[0]
            // ((this.call.departure_datetime.split('T')[1]).split('+')[0]).split(':')[0] + ':' + ((this.call.departure_datetime.split('T')[1]).split('+')[0]).split(':')[1]
            
            if(this.call.id_ship_responsible){
                await this.$store.dispatch('load_ship_agent_by_responsible', this.call.id_ship_responsible.id)
            }
            await this.$store.dispatch('load_ship_agents', this.call.id_ship.id_cruise_line)
            if(this.get_ship_agents.length > 0) {
                await this.$store.dispatch('load_ship_responsibles', this.get_ship_agents[0].id)
                this.ship_agent = this.get_ship_agents[0]
            }
            this.call_dialog = true;
        },
        async load_ship_agent(id) {
            await this.$store.dispatch('load_ship_agents', id.value.id_cruise_line.id)
            if(this.get_ship_agents.length > 0){
                this.ship_agent= this.get_ship_agents[0]
            } else {
                this.ship_agent = ''
            }
            
            this.call.id_ship_responsible = ''
            this.get_ship_responsible = ''
            
            if(this.ship_agent == ''){
                this.call.id_ship_responsible = ''
                this.get_ship_responsible = ''
            } else {
                this.load_ship_responsibles(this.ship_agent.id)
            }

        },
        async load_ship_responsibles(id) {
            if(typeof(id) === 'object') {
                await this.$store.dispatch('load_ship_responsibles', id.value)
            } else {
                await this.$store.dispatch('load_ship_responsibles', id)
            }
            
            if(this.get_ship_responsible.length > 0) {
                this.call.id_ship_responsible = this.get_ship_responsible[0]
            } else {
                 this.call.id_ship_responsible = null
            }
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            /*
            if(Object.prototype.toString.call(this.call.arrival_hour) === '[object Date]') {
                this.call.arrival_datetime_full = new Date(new Date(this.call.arrival_datetime).getFullYear(), new Date(this.call.arrival_datetime).getMonth(), new Date(this.call.arrival_datetime).getDate(),
                                new Date(this.call.arrival_hour).getHours(), new Date(this.call.arrival_hour).getMinutes(), new Date(this.call.arrival_hour).getSeconds())
            } else {
                this.call.arrival_datetime_full = moment(new Date(this.call.arrival_datetime)).add(this.call.arrival_hour.split(':')[0], 'h').add(this.call.arrival_hour.split(':')[1], 'm').add(-2, 'h').toDate()
            }

            if(Object.prototype.toString.call(this.call.departure_hour) === '[object Date]') {
                this.call.departure_datetime_full = new Date(new Date(this.call.departure_datetime).getFullYear(), new Date(this.call.departure_datetime).getMonth(), new Date(this.call.departure_datetime).getDate(),
                                new Date(this.call.departure_hour).getHours(), new Date(this.call.departure_hour).getMinutes(), new Date(this.call.departure_hour).getSeconds())
            } else {
                this.call.departure_datetime_full = moment(new Date(this.call.departure_datetime)).add(this.call.departure_hour.split(':')[0], 'h').add(this.call.departure_hour.split(':')[1], 'm').add(-2, 'h').toDate()
            }
            */
            if(!isFormValid) {
                return;
            }

            await this.$store.dispatch('update_call', this.call)
            this.call_errors = this.get_errors_call
            if(Object.keys(this.call_errors).length === 0){
                this.hide_call_dialog();
                this.call = {};
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Call modified', life: 3000});
            }

        },
        hide_call_dialog() {
            this.call_errors = {}
            this.call_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            call: {
                arrival_datetime: { required },
                departure_datetime: { required },
                id_ship: { required }
            }
            
        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>