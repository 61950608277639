<template>
    <DataTable v-model:selection="selected_bills" :value="get_all_bills" paginator :rows="10" stripedRows :loading="loading"
    responsiveLayout="scroll" scrollable scrollHeight="60vh"
    :globalFilterFields="['number', 'admin_comment', 'payment_type','creation_datetime', 'id_supplier.name', 'validated', 'total_import']"
    v-model:filters="filters" filterDisplay="row" :paginatorTemplate="paginatorTemplate">
        <Column v-if="accounting && validating_table" selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column header="Number" field="number" style="min-width: 14rem;">
            <template #filter="{filterModel,filterCallback}">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" placeholder="Any"/>
            </template>
        </Column>
        <Column v-if="!validation && !accounting" header="Validation" field="validated" style="min-width: 14rem;">
            <template #filter="{filterModel, filterCallback}">
                <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="status_options" placeholder="Any" class="p-column-filter" optionLabel="name" optionValue="validated"/>
            </template>
            <template #body="slotProps">
                <span v-if="slotProps.data.validated == null">
                    <Tag severity="warning"> Pending </Tag>
                </span>
                <span v-else-if="slotProps.data.validated == false">
                    <Tag severity="danger"> Rejected </Tag>
                </span>
                <span v-else>
                    <Tag severity="success"> Validated </Tag>
                </span>
            </template>
        </Column>
        <Column v-if="accounting" header="Validation Comment" field="admin_comment" style="min-width: 20rem;">
            <template #filter="{filterModel,filterCallback}">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" placeholder="Any"/>
            </template>
        </Column>
        <Column header="Paid" field="paid" style="min-width: 4rem;">
            <template #filter="{filterModel, filterCallback}">
                <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="paid_options" placeholder="Any" class="p-column-filter" optionLabel="name" optionValue="validated"/>
            </template>
            <template #body="slotProps">
                <span v-if="slotProps.data.paid"> <i class="pi pi-check-circle" style="color: green;"></i> </span><span v-else> <i class="pi pi-times-circle" style="color: red;"></i> </span>
            </template>
        </Column>
        <Column header="Imports" field="total_import" style="min-width: 12rem;">
            <template #filter="{filterModel,filterCallback}">
                <InputNumber v-model="filterModel.value" dateFormat="yy-mm-dd" @date-select="filterCallback()" class="p-column-filter" placeholder="Any"/>
            </template>
            <template #body="slotProps">
                <span style="cursor:pointer;" @click="toggle($event, slotProps.data)"> {{slotProps.data.total_import}} {{ slotProps.data.id_currency ? slotProps.data.id_currency.symbol : '-' }}</span>
            </template>
        </Column>
        <Column header="Issue Date" field="issue_date" filterField="creation_datetime" style="min-width: 12rem;">
            <template #filter="{filterModel,filterCallback}">
                <Calendar v-model="filterModel.value" dateFormat="yy-mm-dd" @date-select="filterCallback()" class="p-column-filter" placeholder="Any"/>
            </template>
            <template #body="slotProps">
                {{format_date(slotProps.data.issue_date)}}
            </template>
        </Column>
        <Column v-if="!accounting" header="Terminal" field="terminals_name" style="min-width: 14rem;">
            <template #filter="{filterModel, filterCallback}">
                <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="all_terminals_dropdown" placeholder="Any" class="p-column-filter" optionLabel="name" optionValue="name"/>
            </template>
            <template #body="slotProps">
                <span v-if="slotProps.data.terminals_name == 'Empty'">
                    <span> - </span>
                </span>
                <span v-else v-for="(item,index) in slotProps.data.terminals_name" :key="index">
                    {{item}}<span v-if="index < slotProps.data.terminals_name.length - 1">,&nbsp;</span>
                </span>
            </template>
        </Column>
        <Column v-if="!accounting" header="Supplier" field="id_supplier.name" style="min-width: 14rem;">
            <template #filter="{filterModel, filterCallback}">
                <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="all_suppliers" placeholder="Any" class="p-column-filter" optionValue="name" optionLabel="name"/>
            </template>
        </Column>
        <Column header="Info" style="max-width: 7rem;">
            <template #body="slotProps">
                <Button icon="pi pi-eye" class="p-button-sm ms-1 me-1 p-button-rounded p-button-outlined p-button-info" @click="info_bill(slotProps)"/>
            </template>
        </Column>
        <Column v-if="$ability.can('change','bill') && !validation && !accounting" header="Edit" style="max-width: 7rem;">
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-sm ms-1 me-1 p-button-rounded p-button-outlined" @click="edit_bill(slotProps)"/>
            </template>
        </Column>
        <Column v-if="$ability.can('delete','bill') && !validation && !accounting" header="Delete" style="max-width: 7rem;">
            <template #body="slotProps">
                <Button icon="pi pi-trash" class="p-button-sm ms-1 me-1 p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_bill(slotProps)"/>
            </template>
        </Column>
        <template #empty>
            <div class="col text-center">
                No records found.    
            </div>
        </template>
    </DataTable>
    <Button v-if="validating_table" label="Account bills" icon="pi-file-import" @click="confirm_account_bills(slotProps)" :disabled="!selected_bills || !selected_bills.length" />

    <OverlayPanel ref="op" :style="{width: '35vw'}">
        <DataTable :value="temporary_imports" paginator rows="5">
            <template #empty>
                <div class="flex justify-content-center">
                    <p class="mb-0"> No imports yet. </p>
                </div>
            </template>
            <Column field="description" header="Description"></Column>
            <Column header="Quantity" field="quantity"></Column>
            <Column field="base_import" header="Base Import">
                <template #body="slotProps">
                    {{ slotProps.data.base_import }} {{ temporary_currency }}
                </template>
            </Column>
            <Column field="iva" header="IVA">
                <template #body="slotProps">
                    {{ slotProps.data.iva }} %
                </template>
            </Column>
            <Column field="total_import" header="Total Import">
                <template #body="slotProps">
                    {{ slotProps.data.total_import }} {{ temporary_currency }}
                </template>
            </Column>
        </DataTable>
        
    </OverlayPanel>

    <Dialog header="Edit Bill" v-model:visible="display_edit_bill" :breakpoints="{'1400px': '60vw', '1080px': '75vw', '640px': '90vw'}" :style="{width: '60vw'}" :modal="true">
        <BlockUI :blocked="blocked_edit_bill" :fullScreen="true">
        <div v-if="blocked_edit_bill == true" style="position: absolute;margin-left: auto;margin-right: auto;left: 0;right: 0;bottom: 50%;text-align: center;z-index: 9099">
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
        </div>
        <div v-if="bill_editable.validated != true" class="container p-fluid" >
            <form id="edit_bill" @submit.prevent="handleSubmit(!v$.$invalid)">
                <div class="row justify-content-around mt-3">
                    <Accordion :activeIndex="0">
                        <AccordionTab header="General Information">
                            <div class="row mt-3 justify-content-around">
                                <div class="col">
                                    <span class="p-float-label">  
                                        <InputText v-model="bill_editable.number"/>
                                        <label> Number </label>
                                    </span>
                                    <small class="p-error" v-if="submitted && !bill_editable.number">Number is required.</small>
                                    <small v-if="get_error_bills.number" class="p-error"> <span>{{get_error_bills.number[0]}}</span> </small>
                                </div>
                            </div>
                            <div v-if="bill_editable.payment_type == 'Credit card'" class="row mt-3 justify-content-around">
                                <div class="col">
                                    <span class="p-float-label"> 
                                        <Dropdown v-model="bill_editable.id_card.id" :options="get_cards" optionLabel="number" optionValue="id"/>
                                        <label> Card </label>
                                    </span>
                                    <small class="p-error" v-if="submitted && !bill_editable.id_supplier">Supplier is required.</small>
                                </div>
                            </div>
                            <div class="row mt-3 justify-content-around">
                                <div class="col">
                                    <span class="p-float-label"> 
                                        <MultiSelect v-model="bill_editable.terminals" :options="all_terminals" optionLabel="name"/>
                                        <label> Terminal </label>
                                    </span>
                                </div>
                            </div>
                            <div class="row mt-3 justify-content-around">
                                <div class="col">
                                    <span class="p-float-label"> 
                                        <Dropdown v-model="bill_editable.id_supplier.id" :options="all_suppliers" optionLabel="name" optionValue="id"/>
                                        <label> Supplier </label>
                                    </span>
                                    <small class="p-error" v-if="submitted && !bill_editable.id_supplier">Supplier is required.</small>
                                </div>
                            </div>
                            <div class="row mt-3 justify-content-around">
                                <div class="col">
                                    <span class="p-float-label">
                                        <Calendar v-model="bill_editable.issue_date" dateFormat="yy-mm-dd"/>
                                        <label> Issue date </label>
                                    </span>
                                    <small v-if="get_error_bills.expiration_datetime" class="p-error"> <span>{{get_error_bills.expiration_datetime[0]}}</span> </small>
                                </div>
                                <div class="col">
                                    <span class="p-float-label">
                                        <Calendar v-model="bill_editable.due_date" dateFormat="yy-mm-dd"/>
                                        <label> Due date </label>
                                    </span>
                                    <small v-if="get_error_bills.expiration_datetime" class="p-error"> <span>{{get_error_bills.expiration_datetime[0]}}</span> </small>
                                </div>
                                <div class="col">
                                    <span class="p-float-label">
                                        <Calendar v-model="bill_editable.payment_date" dateFormat="yy-mm-dd"/>
                                        <label> Payment date </label>
                                    </span>
                                    <small v-if="get_error_bills.expiration_datetime" class="p-error"> <span>{{get_error_bills.expiration_datetime[0]}}</span> </small>
                                </div>
                            </div>
                            <div class="row mt-3 justify-content-around">
                                <div class="col">
                                    <span class="p-float-label">  
                                        <InputText v-model="bill_editable.concept"/>
                                        <label> Concept </label>    
                                    </span>
                                    <small class="p-error" v-if="submitted && !bill_editable.concept">Concept is required.</small>
                                    <small v-if="get_error_bills.concept" class="p-error"> <span>{{get_error_bills.concept[0]}}</span> </small>
                                </div>
                            </div>
                            <div class="row mt-3 justify-content-around">
                                <div class="col">
                                    <span class="p-float-label"> 
                                        <Dropdown v-model="bill_editable.id_currency.id" :options="get_currencies" optionLabel="symbol" optionValue="id"/>
                                        <label> Currency </label>
                                    </span>
                                    <small class="p-error" v-if="submitted && !bill_editable.id_supplier"> Currency is required. </small>
                                </div>
                            </div>
                        </AccordionTab> 
                        <AccordionTab header="Imports">
                            <DataTable :value="get_imports_from_bills" stripedRows>
                                <template #empty>
                                    <div class="flex justify-content-center">
                                        <p class="mb-0"> No imports added yet. </p>
                                    </div>
                                </template>
                                <Column header="Description" field="description"></Column>
                                <Column header="Quantity" field="quantity"></Column>
                                <Column header="Base" field="base_import">
                                    <template #body="slotProps">
                                        {{ slotProps.data.base_import }} {{ bill_editable.id_currency.symbol }}
                                    </template>
                                </Column>
                                <Column header="IVA" field="iva">
                                    <template #body="slotProps">
                                        {{ slotProps.data.iva }} %
                                    </template>
                                </Column>
                                <Column header="Total" field="total_import">
                                    <template #body="slotProps">
                                        {{ slotProps.data.total_import }} {{ bill_editable.id_currency.symbol }}
                                    </template>
                                </Column>
                                <Column header="Borrar" style="width: 8rem;">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger"  @click="delete_import_bills(slotProps)"></Button>
                                    </template>
                                </Column>
                            </DataTable>
                            <div class="grid p-fluid mt-1 mb-2 p-3">
                                <div class="col-12 md:col pt-3">
                                    <div class="p-float-label">
                                        <InputText v-model="bill_import.description"></InputText>
                                        <label> Description </label>
                                    </div>
                                    <small v-show="validationErrors.description && submitted" class="p-error"> Description is required. </small>
                                </div>
                                
                                <div class="col-12 md:col pt-3">
                                    <div class="p-float-label">
                                        <InputNumber v-model="bill_import.quantity" @input="change_total_quantity($event)"></InputNumber>
                                        <label> Quantity </label>
                                    </div>
                                    <small v-show="validationErrors.quantity && submitted" class="p-error"> Quantity is required. </small>
                                </div>
                                <div class="col-12 md:col pt-3">
                                    <div class="p-float-label">
                                        <InputNumber v-model="bill_import.base" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" @input="change_total_base($event)"></InputNumber>
                                        <label> Base </label>
                                    </div>
                                    <small v-show="validationErrors.base && submitted" class="p-error"> Base Import is required. </small>
                                </div>
                                <div class="col-12 md:col pt-3">
                                    <div class="p-float-label" >
                                        <InputNumber v-model="bill_import.iva" suffix=" %" :min="0" :max="100" :minFractionDigits="2" @input="change_total_iva($event)"></InputNumber>
                                        <label> Iva </label>
                                    </div>
                                </div>
                                <div class="col-12 md:col pt-3">
                                    <div class="p-float-label">
                                        <InputNumber v-model="total_import" mode="decimal" :minFractionDigits="2" disabled></InputNumber>
                                        <label> Total </label>
                                    </div>
                                </div>
                                <div class="col-12 md:col text-center">
                                    <Button icon="pi pi-plus" class="p-button-outlined p-button-rounded" @click="add_import_to_table"/>
                                </div>
                            </div>
                        </AccordionTab>   
                        <AccordionTab header="Budget & Bill">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <span class="text-center"> Budget </span>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col text-center">
                                            <div v-if="budget_url == ''" class="col text-center">
                                                <span v-if="bill_editable.budget == null || bill_editable.budget == 'no picture'">
                                                    <Avatar icon="pi pi-image" class="mt-3" size="xlarge" />
                                                </span>
                                                <span v-else-if="bill_editable.budget.split('?')[0].split('.').pop() == 'eml'">
                                                    <a :href="bill_editable.budget"><i class="fa-regular fa-envelope fa-4x" style="color:gray;"></i></a>
                                                </span>
                                                <span v-else-if="bill_editable.budget.split('?')[0].split('.').pop() == 'pdf'">
                                                    <iframe style="border:none;"
                                                        :src="bill_editable.budget"
                                                        frameBorder="0"
                                                        scrolling="auto"
                                                    ></iframe>
                                                </span>
                                                
                                                <span v-else>
                                                    <Image :src="bill_editable.budget" height="200" preview/>
                                                </span>
                                            </div>
                                            <div v-else class="col text-center">
                                                <span v-if="check_file_budget == 'eml'">
                                                    <a :href="budget_url"><i class="fa-regular fa-envelope fa-4x" style="color:gray;"></i></a>
                                                </span>
                                                <span v-else-if="check_file_budget == 'pdf'">
                                                    <iframe style="border:none;"
                                                        :src="budget_url"
                                                        frameBorder="0"
                                                        scrolling="auto"
                                                    ></iframe>
                                                </span>
                                                <span v-else-if="budget_url == null || budget_url == 'no picture'">
                                                    <Avatar icon="pi pi-image" class="mt-3" size="xlarge" />
                                                </span>
                                                <span v-else>
                                                    <Image :src="budget_url" height="200" preview/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col ">
                                    <div class="row">
                                        <span class="text-center"> Bill </span>
                                    </div>
                                    <div class="row">
                                        <div v-if="bill_url == ''" class="col text-center">
                                            <span v-if="bill_editable.bill.split('?')[0].split('.').pop() == 'eml'">
                                                <a :href="bill_editable.bill"><i class="fa-regular fa-envelope fa-4x" style="color:gray;"></i></a>
                                            </span>
                                            <span v-else-if="bill_editable.bill.split('?')[0].split('.').pop() == 'pdf'">
                                                <iframe style="border:none;"
                                                    :src="bill_editable.bill"
                                                    frameBorder="0"
                                                    scrolling="auto"
                                                ></iframe>
                                            </span>
                                            <span v-else>
                                                <Image :src="bill_editable.bill" height="200" preview/>
                                            </span>
                                        </div>
                                        <div v-else  class="col text-center">
                                            <span v-if="check_file_bill == 'eml'">
                                                <a :href="bill_url"><i class="fa-regular fa-envelope fa-4x" style="color:gray;"></i></a>
                                            </span>
                                            <span v-else-if="check_file_bill == 'pdf'">
                                                <iframe style="border:none;"
                                                    :src="bill_url"
                                                    frameBorder="0"
                                                    scrolling="auto"
                                                ></iframe>
                                            </span>
                                            <span v-else>
                                                <Image :src="bill_url" height="200" preview/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-around">
                                <div class="col text-center">
                                    <div class="row">
                                        <div class="col">
                                            <FileUpload ref="fileUpload_budget" class="p-button-rounded p-button-raised" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .eml" :auto="true" :maxFileSize="10000000" @uploader="onUpload_picture_budget"/>
                                        </div>
                                        <div v-if="bill_editable.budget && bill_editable.budget != 'no picture'" class="col">
                                            <Button icon="fa fa-xmark" class="p-button-danger p-button-rounded" @click="remove_budget_picture"/>
                                        </div>
                                    </div>
                                </div>  
                                <div class="col text-center">
                                    <div class="row">
                                        <div class="col">
                                            <FileUpload ref="fileUpload_bill" class="p-button-rounded p-button-raised" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .eml" :auto="true" :maxFileSize="10000000" @uploader="onUpload_picture_bill"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>    
                    </Accordion>
                </div>
            </form>
        </div>
        <div v-else>
            <div class="row mt-3">
                <p> Set the status back to pending, allowing the bill to be editable.</p>
            </div>
            <div class="row">
                <div class="col text-center">
                    <Button label="Remove Validation" @click="remove_validation_bill"/>
                </div>
            </div>
        </div>
        </BlockUI>
        <template v-if="bill_editable.validated != true" #footer>
            <div class="row">
                <div class="col align-self-center text-center">
                    <span v-if="bill_editable.validated == false">
                        <p style="margin-bottom: 0px;"> <i class="fa fa-exclamation-triangle" style="color: orange"></i> Updating this bill will automatically change its status to Pending. </p>
                    </span>
                </div>
                <div class="col">
                    <Button label="Cancel" class="p-button-text"/>
                    <Button label="Save" class="p-button-raised p-button-success" form="edit_bill" type="submit"/>
                </div>
            </div>
        </template>
    </Dialog>

    <Dialog header="Info Bill" v-model:visible="display_info_bill" :breakpoints="{'1400px': '60vw', '1080px': '75vw', '640px': '90vw'}" :style="{width: '60vw'}" :modal="true">
        <div class="container p-fluid" >
            <div class="row justify-content-around mt-3">
                <Accordion :activeIndex="0">
                    <AccordionTab header="General Information">
                        <div class="row">
                            <div class="col-6 md:col-12">
                                <span class="p-float-label">  
                                    <small> Number </small>
                                    <h4> {{ bill_editable.number }} </h4>
                                </span>
                            </div>
                            <div v-if="bill_editable.payment_type == 'Credit card'">
                                <div class="col-6 md:col-12">
                                    <span class="p-float-label"> 
                                        <small> Card </small>
                                        <h4> {{ bill_editable.id_card.name ? bill_editable.id_card.name: '-' }} </h4>
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 md:col-6">
                                <span class="p-float-label"> 
                                    <small> Terminal </small>
                                    <div v-if="bill_editable.terminals">
                                        <span v-if="bill_editable.terminals.length == 0">
                                            <strong> - </strong>
                                        </span>
                                        <span v-else v-for="(item,index) in bill_editable.terminals" :key="index">
                                            <h4>  {{item.name}}<span v-if="index !== bill_editable.terminals.length - 1">, </span> </h4>
                                        </span>
                                    </div>
                                </span>
                            </div>
                            <div class="col-12 md:col-6">
                                <span class="p-float-label"> 
                                    <small> Supplier </small>
                                    <h4> {{ bill_editable.id_supplier.name }}</h4>
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 md:col-4">
                                <span class="p-float-label">
                                    <small> Issue date </small>
                                    <h4> {{ bill_editable.issue_date }} </h4>
                                </span>
                            </div>
                            <div class="col-12 md:col-4">
                                <span class="p-float-label">
                                    <small> Due date </small>
                                    <h4> {{ bill_editable.due_date }} </h4>
                                </span>
                            </div>
                            <div class="col-12 md:col-4">
                                <span class="p-float-label">
                                    <small> Payment date </small>
                                    <h4> {{ bill_editable.payment_date }} </h4>
                                </span>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-12 md:col-6">
                                <span class="p-float-label">  
                                    <small> Concept </small>    
                                    <h4> {{ bill_editable.concept }} </h4>
                                </span>
                            </div>
                            <div class="col-12 md:col-6">
                                <span class="p-float-label"> 
                                    <small> Currency </small>
                                    <h4> {{ bill_editable.id_currency.symbol }}</h4>
                                </span>
                            </div>
                        </div>
                    </AccordionTab> 
                    <AccordionTab header="Imports">
                        <DataTable :value="get_imports_from_bills" stripedRows>
                            <template #empty>
                                <div class="flex justify-content-center">
                                    <p class="mb-0"> No imports added yet. </p>
                                </div>
                            </template>
                            <Column header="Description" field="description"></Column>
                            <Column header="Quantity" field="quantity"></Column>
                            <Column header="Base" field="base_import">
                                <template #body="slotProps">
                                    {{ slotProps.data.base_import }} {{ bill_editable.id_currency.symbol }}
                                </template>
                            </Column>
                            <Column header="IVA" field="iva">
                                <template #body="slotProps">
                                    {{ slotProps.data.iva }} %
                                </template>
                            </Column>
                            <Column header="Total" field="total_import">
                                <template #body="slotProps">
                                    {{ slotProps.data.total_import }} {{ bill_editable.id_currency.symbol }}
                                </template>
                            </Column>
                        </DataTable>
                    </AccordionTab>   
                    <AccordionTab header="Budget & Bill">
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <span class="text-center"> Budget </span>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col text-center">
                                        <div v-if="budget_url == ''" class="col text-center" style="heigth:100%; ">
                                            <span v-if="bill_editable.budget == null || bill_editable.budget == 'no picture'">
                                                <Avatar icon="pi pi-image" class="mt-3" size="xlarge" />
                                            </span>
                                            <span v-else-if="bill_editable.budget.split('?')[0].split('.').pop() == 'eml'">
                                                <a :href="bill_editable.budget"><i class="fa-regular fa-envelope fa-4x" style="color:gray;"></i></a>
                                            </span>
                                            <span v-else-if="bill_editable.budget.split('?')[0].split('.').pop().toLowerCase() == 'pdf'">
                                                <div class="ratio ratio-4x3">
                                                    <iframe style="border:none;"
                                                        :src="bill_editable.budget"
                                                        frameBorder="0"
                                                        scrolling="auto"
                                                    ></iframe>
                                                </div>
                                            </span>
                                            
                                            <span v-else>
                                                <Image :src="bill_editable.budget" height="100" preview/>
                                            </span>
                                        </div>
                                        <div v-else class="col text-center">
                                            <span v-if="check_file_budget == 'eml'">
                                                <a :href="budget_url"><i class="fa-regular fa-envelope fa-4x" style="color:gray;"></i></a>
                                            </span>
                                            <span v-else-if="check_file_budget == 'pdf'">
                                                <div class="ratio ratio-4x3">
                                                    <iframe style="border:none;"
                                                        :src="budget_url"
                                                        frameBorder="0"
                                                        scrolling="auto"
                                                    ></iframe>
                                                </div>
                                            </span>
                                            <span v-else-if="budget_url == null || budget_url == 'no picture'">
                                                <Avatar icon="pi pi-image" class="mt-3" size="xlarge" />
                                            </span>
                                            <span v-else>
                                                <Image :src="budget_url" height="100" preview/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <span class="text-center"> Bill </span>
                                </div>
                                <div class="row">
                                    <div v-if="bill_url == ''" class="col-12 text-center">
                                        <span v-if="bill_editable.bill.split('?')[0].split('.').pop() == 'eml'">
                                            <a :href="bill_editable.bill"><i class="fa-regular fa-envelope fa-4x" style="color:gray;"></i></a>
                                        </span>
                                        <span v-else-if="bill_editable.bill.split('?')[0].split('.').pop().toLowerCase() == 'pdf'">
                                            <div class="ratio ratio-4x3">
                                                <iframe style="border:none;"
                                                    :src="bill_editable.bill"
                                                    frameBorder="0"
                                                    scrolling="auto">
                                                </iframe>
                                            </div>
                                        </span>
                                        <span v-else>
                                            <Image :src="bill_editable.bill" height="200" preview/>
                                        </span>
                                    </div>
                                    <div v-else  class="col text-center">
                                        <span v-if="check_file_bill == 'eml'">
                                            <a :href="bill_url"><i class="fa-regular fa-envelope fa-4x" style="color:gray;"></i></a>
                                        </span>
                                        <span v-else-if="check_file_bill == 'pdf'">
                                            <div class="ratio ratio-4x3">
                                                <iframe style="border:none;"
                                                    :src="bill_url"
                                                    frameBorder="0"
                                                    scrolling="auto"
                                                ></iframe>
                                            </div>
                                        </span>
                                        <span v-else>
                                            <Image :src="bill_url" height="200" preview/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>    
                </Accordion>
            </div>
        </div>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="confirmation_delete_bill" :style="{width: '40vw'}" :modal="true"> 
        <div class="container">
            <div class="row">
                <div class="col">
                    <p>Do you really want to delete this bill?</p>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="col">
                <Button label="Cancel" class="p-button-text" @click="confirmation_delete_bill = false"/>
                <Button label="Confirm" class="p-button-raised p-button-success" @click="delete_bill"/>
            </div>
        </template>
    </Dialog>

    <Dialog header="Account bills" v-model:visible="confirmation_account_bills" :style="{width: '40vw'}" :modal="true"> 
        <div class="container">
            <div class="row">
                <div class="col">
                    <p>Are you sure you want to account for this/these bill(s)?</p>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="col">
                <Button label="Cancel" class="p-button-text" @click="confirmation_account_bills = false"/>
                <Button label="Confirm" class="p-button-raised p-button-success" @click="account_bills"/>
            </div>
        </template>
    </Dialog>


</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import OverlayPanel from 'primevue/overlaypanel'
import Tag from 'primevue/tag'
import Button from 'primevue/button'
import Image from 'primevue/image'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Dialog from 'primevue/dialog'
import FileUpload from 'primevue/fileupload'
import Avatar from 'primevue/avatar'
import BlockUI from 'primevue/blockui'
import Accordion  from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

import { FilterMatchMode } from 'primevue/api';

import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"

import moment from 'moment'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'Billing_table',
    components: {
        DataTable,
        Column,
        OverlayPanel,
        Tag,
        Button,
        Image,
        InputText,
        Dropdown,
        Calendar,
        InputNumber,
        Dialog,
        FileUpload,
        Avatar,
        BlockUI,
        MultiSelect,
        Accordion,
        AccordionTab,
    },
    props: {
        get_all_bills: {
            type: Object
        },
        validation: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
        accounting: {
            type: Boolean
        },
        validating_table: {
            type: Boolean
        }
    },
    data(){ 
        return {
            color: localStorage.getItem('primary_color'),
            delete_bill_id: '',
            confirmation_delete_bill: false,
            confirmation_account_bills: false,
            temporary_imports: '',
            temporary_currency: '',
            display_edit_bill: false,
            display_info_bill: false,
            bill_editable: [],
            status_options: [ 
                {
                    name:'Validated',
                    validated: true
                },
                {
                    name:'Rejected',
                    validated: false
                },
                {
                    name:'Pending',
                    validated: null
                }
            ],
            paid_options: [ 
                {
                    name:'Paid',
                    validated: true
                },
                {
                    name:'Not paid',
                    validated: false
                },
            ],
            payment_type_options: [
                {
                    name: 'Cash'
                },
                {
                    name: 'Credit card'
                },
                {
                    name: 'Bank transfer'
                },
                {
                    name: 'Direct debit'
                }
            ],
            submitted: false,
            blocked_edit_bill: false,
            filters: {
                'number': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'payment_type': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'admin_comment': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'creation_datetime': {value: null, matchMode: FilterMatchMode.DATE_IS},
                'terminals_name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'id_supplier.name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'validated': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'paid': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'total_import': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            all_terminals_dropdown: [],
            check_budget_eml: false,
            check_file_bill: false,
            bill_url: '',
            budget_url: '',
            bill_import: {
                description: '',
                quantity: 1,
                base: 0,
                iva: 21,
                id_bill: 0
            },
            total_import: 0,
            validationErrors: [],
            selected_bills: [],
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    computed: {
        get_error_bills: function() {
            return this.$store.getters.get_error_bills
        },
        all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        all_suppliers: function() {
            return this.$store.getters.all_suppliers
        },
        get_currencies: function() {
            return this.$store.getters.get_currencies
        },
        get_cards: function() {
            return this.$store.getters.get_cards
        },
        get_imports_from_bills: function() {
            return this.$store.getters.get_imports_from_bills
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        await this.$store.dispatch('load_all_terminals')
        await this.$store.dispatch('load_all_suppliers')
        await this.$store.dispatch('load_all_currencies')
        await this.$store.dispatch('load_all_cards')
        this.all_terminals_dropdown = this.all_terminals
        this.all_terminals_dropdown.push({name: 'Empty'})
        
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        change_total_quantity(event) {
            this.total_import = this.roundToTwo((event.value * this.bill_import.base) + ((event.value * this.bill_import.base) * (this.bill_import.iva / 100)))
            this.bill_import.total_import = this.roundToTwo((event.value * this.bill_import.base) + ((event.value * this.bill_import.base) * (this.bill_import.iva / 100)))
        },
        change_total_base(event) {
            this.total_import = this.roundToTwo((event.value * this.bill_import.quantity) + ((event.value * this.bill_import.quantity) * (this.bill_import.iva / 100)))
            this.bill_import.total_import = this.roundToTwo((event.value * this.bill_import.quantity) + ((event.value * this.bill_import.quantity) * (this.bill_import.iva / 100)))
        },
        change_total_iva(event) {
            this.total_import = this.roundToTwo((this.bill_import.base * this.bill_import.quantity) + ((this.bill_import.base * this.bill_import.quantity) * (event.value / 100)))
            this.bill_import.total_import = this.roundToTwo((this.bill_import.base * this.bill_import.quantity) + ((this.bill_import.base * this.bill_import.quantity) * (event.value / 100)))
        },
        roundToTwo(num) {
            return + (Math.round(num + "e+2")  + "e-2");
        },
        async add_import_to_table() {
            this.submitted = true
            this.bill_import.id_bill = this.bill_editable.id
            if (this.validateForm()) {
                await this.$store.dispatch('add_import', this.bill_import)
            }
        },
        validateForm() {
            if (!this.bill_import.description) { 
                this.validationErrors['description'] = true;
            } else {
                delete this.validationErrors['description'];
            } 
            if (!this.bill_import.quantity) { 
                this.validationErrors['quantity'] = true;
            } else {
                delete this.validationErrors['quantity'];
            }
            if (!this.bill_import.base) { 
                this.validationErrors['base'] = true;
            } else {
                delete this.validationErrors['base'];
            }
            return !Object.keys(this.validationErrors).length;
        },
        async delete_import_bills(data) {
            await this.$store.dispatch('delete_import_bill', data.data.id)
        },
        async onUpload_picture_budget(e) {
            if(e.files[0].name.split('.')[1] == 'eml') {
                this.check_file_budget = 'eml'
            } else if(e.files[0].name.split('.')[1] == 'pdf'){
                this.check_file_budget = 'pdf'
            } else {
                this.check_file_budget = 'image'
            }
            this.bill_editable.budget = e.files[0]
            const file = e.files[0];
            this.budget_url = URL.createObjectURL(file);
            this.$refs.fileUpload_budget.clear();
            this.$refs.fileUpload_budget.uploadedFileCount = 0;
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Picture selected.', life: 3000});
        },
        async onUpload_picture_bill(e) {
            if(e.files[0].name.split('.')[1] == 'eml') {
                this.check_file_bill = 'eml'
            } else if(e.files[0].name.split('.')[1] == 'pdf'){
                this.check_file_bill = 'pdf'
            } else {
                this.check_file_bill = 'image'
            }
            this.bill_editable.bill = e.files[0]
            const file = e.files[0];
            this.bill_url = URL.createObjectURL(file);
            this.$refs.fileUpload_bill.clear();
            this.$refs.fileUpload_bill.uploadedFileCount = 0;
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Picture selected.', life: 3000}); 
        },
        async toggle(event, data) {
            this.temporary_imports = data.imports
            this.temporary_currency = data.id_currency.symbol
            this.$refs.op.toggle(event)
        },
        format_date(data){
            if(data == null) {
                return '-'
            } else {
                return moment(data).format('YYYY-MM-DD')
            }
            
        },
        async delete_bill(){
            this.$store.dispatch('delete_bill', this.delete_bill_id)
            this.confirmation_delete_bill = false
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Bill deleted.', life: 3000});
            this.delete_bill_id = ''
        },
        async confirm_delete_bill(data) {
            this.delete_bill_id = data.data.id
            this.confirmation_delete_bill = true
        },
        async account_bills(){
            const id_list = this.selected_bills.map(item => item.id);
            this.$store.dispatch('account_list_bills', id_list)
            this.confirmation_account_bills = false
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Bill/s accounted.', life: 3000});
            this.selected_bills = []
        },
        async confirm_account_bills() {
            this.confirmation_account_bills = true
        },
        async edit_bill(data){
            this.bill_editable = JSON.parse(JSON.stringify(data.data))
            await this.$store.dispatch('get_import_by_bill_id', this.bill_editable.id)
            if(this.bill_editable.validation_datetime != null) {
                this.bill_editable.validation_datetime = moment(this.bill_editable.validation_datetime).format('YYYY-MM-DD')
            }
            this.display_edit_bill = true
        },
        async info_bill(data) {
            this.bill_editable = JSON.parse(JSON.stringify(data.data))
            await this.$store.dispatch('get_import_by_bill_id', this.bill_editable.id)
            if(this.bill_editable.validation_datetime != null) {
                this.bill_editable.validation_datetime = moment(this.bill_editable.validation_datetime).format('YYYY-MM-DD')
            }
            this.display_info_bill = true
        },
        async handleSubmit(isFormValid) {
            this.$store.dispatch('clear_errors_bill')
            this.submitted = true;

            if (!isFormValid) {
                return;
            }
            
            let res = await this.save_edited_bill()
            if (res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Bill Updated.', life: 3000});
                this.display_edit_bill = false
            } 
            
        },
        async save_edited_bill(){

            this.blocked_edit_bill = true
            
            let bill_clone = Object.assign({}, this.bill_editable) 
            
            await this.$store.dispatch('update_bill', bill_clone)
            this.blocked_edit_bill = false

            if(Object.keys(this.get_error_bills).length == 0) {
                return true
            }
        },
        async remove_budget_picture() {
            this.bill_editable.budget = 'no picture'
        },
        async remove_validation_bill() {
            let validation = {
                status: 'null',
                id: this.bill_editable.id
            }

            await this.$store.dispatch('change_status_bill', validation) 
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Validation removed.', life: 3000});
            this.display_edit_bill = false
        },
    },
    validations() {
        return {
            bill_editable: {
                number: { required },
                id_supplier: { required },
            }
        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}
</style>