<template>
    <Toast/>
    <div>
        <form id="form" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="row">
                <div class="col">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Information</h5>
                    
                </div>
            </div>
            <div class="row pt-2 justify-content-between">
                <div class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">     
                            <InputText id="title" v-model="v$.title.$model" :class="{'p-invalid':v$.title.$invalid && submitted || incidence_report_errors.title}" />
                            <label for="title" :class="{'p-error':v$.title.$invalid && submitted}"> Title <b class="required_field">*</b></label>
                            <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.title" class="p-error"><span v-for="error in incidence_report_errors.title" :key="error">{{error}}<br></span></small>
                        </div>
                        <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">{{v$.title.required.$message.replace('Value', 'Title')}}</small>
                    </div>
                </div>
                <div v-if="!service_sheet" class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">
                            <Dropdown data-cy="dropdown_incidence_type" :loading="load" id="incidence_type" v-model="incidence_type" :options="incidence_type_choices" :class="{'p-invalid':v$.incidence_type.$invalid && submitted || incidence_report_errors.incidence_type}" :filter="true"/>
                            <label for="incidence_type" :class="{'p-error':v$.incidence_type.$invalid && submitted || incidence_report_errors.incidence_type}"> Incidence type <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.incidence_type" class="p-error"><span v-for="error in incidence_report_errors.incidence_type" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.incidence_type.$invalid && submitted) || v$.incidence_type.$pending.$response" class="p-error">{{v$.incidence_type.required.$message.replace('Value', 'Incidence type')}}</small>
                    </div>
                </div>
                <div v-else class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">
                            <Dropdown :loading="load" id="incidence_type" v-model="incidence_type" :options="incidence_type_choices" :class="{'p-invalid':v$.incidence_type.$invalid && submitted || incidence_report_errors.incidence_type}" :filter="true" disabled/>
                            <label for="incidence_type" :class="{'p-error':v$.incidence_type.$invalid && submitted || incidence_report_errors.incidence_type}"> Incidence type <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.incidence_type" class="p-error"><span v-for="error in incidence_report_errors.incidence_type" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.incidence_type.$invalid && submitted) || v$.incidence_type.$pending.$response" class="p-error">{{v$.incidence_type.required.$message.replace('Value', 'Incidence type')}}</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="!service_sheet" class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">
                            <Dropdown data-cy="dropdown_terminals"  :loading="load" id="id_terminal" v-model="id_terminal" :options="all_terminals" optionLabel="name" :filter="true" :class="{'p-invalid':v$.id_terminal.$invalid && submitted || incidence_report_errors.id_terminal}" @change="change_service_sheet"/>
                            <label for="id_terminal" :class="{'p-error':v$.id_terminal.$invalid && submitted || incidence_report_errors.id_terminal}"> Terminal <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_terminal" class="p-error"><span v-for="error in incidence_report_errors.id_terminal" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.id_terminal.$invalid && submitted) || v$.id_terminal.$pending.$response" class="p-error">{{v$.id_terminal.required.$message.replace('Value', 'Terminal')}}</small>
                    </div>
                </div>
                <div v-else class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">
                            <Dropdown :loading="load" id="id_terminal" v-model="id_terminal" :options="all_terminals" optionLabel="name" :filter="true" :class="{'p-invalid':v$.id_terminal.$invalid && submitted || incidence_report_errors.id_terminal}" disabled/>
                            <label for="id_terminal" :class="{'p-error':v$.id_terminal.$invalid && submitted || incidence_report_errors.id_terminal}"> Terminal <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_terminal" class="p-error"><span v-for="error in incidence_report_errors.id_terminal" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.id_terminal.$invalid && submitted) || v$.id_terminal.$pending.$response" class="p-error">{{v$.id_terminal.required.$message.replace('Value', 'Terminal')}}</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">
                            <Dropdown :loading="load" id="user" v-model="id_user_responsible" :options="get_users" optionLabel="username" :filter="true"/>
                            <label for="user" :class="{'p-error':submitted}"> User Responsible</label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_user_responsible" class="p-error"><span v-for="error in incidence_report_errors.id_user_responsible" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Schedule </h5>
                </div>
            </div>
            <div class="row pt-2">
                <div  class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label"> 
                            <Calendar data-cy="calendar_date" autocomplete="off" id="date" v-model="date" dateFormat="yy-mm-dd" :class="{'p-invalid':v$.date.$invalid && submitted || incidence_report_errors.date}" @date-select="change_service_sheet"/>
                            <label for="date" :class="{'p-error':v$.date.$invalid && submitted}"> Date <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.date" class="p-error"><span v-for="error in incidence_report_errors.date" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.date.$invalid && submitted) || v$.date.$pending.$response" class="p-error">{{v$.date.required.$message.replace('Value', 'Date')}}</small>
                    </div>
                </div>
                <div class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">
                            <Calendar data-cy="calendar_hour" autocomplete="off" id="hour" :timeOnly="true" v-model="hour" :class="{'p-invalid':v$.hour.$invalid && submitted || incidence_report_errors.hour}" @change="hour = num2time($event)"/>
                            <label for="hour" :class="{'p-error':v$.hour.$invalid && submitted}"> Hour <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.hour" class="p-error"><span v-for="error in incidence_report_errors.hour" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.hour.$invalid && submitted) || v$.hour.$pending.$response" class="p-error">{{v$.hour.required.$message.replace('Value', 'Hour')}}</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Details </h5>
                </div>
            </div>
            <div class="row mt-2">
                <div v-if="!service_sheet && incidence_type=='Service Sheet'" class="col">
                    <div class="field">
                        <div class="p-float-label">   
                            <Dropdown :loading="load_ss" id="service_sheet" v-model="id_service_sheet" :options="get_service_sheets_filtered" optionLabel="estimated_arrival_datetime" :filter="true" :class="{'p-invalid':v$.id_service_sheet.$invalid && submitted || incidence_report_errors.text} ">
                                <template #value="slotProps">
                                    <div v-if="!id_service_sheet">
                                        <span> - </span>
                                    </div>
                                    <div v-else-if="slotProps.value">
                                        <span>{{format_date(slotProps.value.estimated_arrival_datetime)}} - {{slotProps.value.id_call.id_ship.name}}</span>
                                    </div>
                                </template>
                                <template #option="slotProps">
                                    <div> {{format_date(slotProps.option.estimated_arrival_datetime)}} - {{slotProps.option.id_call.id_ship.name}} </div>
                                </template>
                            </Dropdown>
                            <label for="service_sheet" :class="{'p-error':incidence_report_errors.id_service_sheet}"> Service Sheet <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_service_sheet" class="p-error"><span v-for="error in incidence_report_errors.id_service_sheet" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.id_service_sheet.$invalid && submitted) || v$.id_service_sheet.$pending.$response" class="p-error">{{v$.id_service_sheet.required.$message.replace('Value', 'Service sheet')}}</small>
                    </div>
                </div>
                <div v-if="!service_sheet && incidence_type=='Service Sheet'" class="col-1 text-center mt-2">
                    <i class="pi pi-question-circle" v-tooltip.left="'In order to select a service sheet, first you need to fill the date and terminal field.'" style="font-size: 1.5rem"></i>
                </div>
                <div v-if="service_sheet && incidence_type=='Service Sheet'" class="col my-auto">
                    <div class="field">
                        <div class="p-float-label">
                            <Dropdown :loading="load_ss" id="service_sheet" v-model="id_service_sheet" :options="filtered_service_sheets" optionLabel="estimated_arrival_datetime" :filter="true" :class="{'p-invalid':v$.id_service_sheet.$invalid && submitted || incidence_report_errors.text}" disabled>
                                <template #value="slotProps">
                                    <div v-if="!id_service_sheet">
                                        <span> - </span>
                                    </div>
                                    <div v-else-if="slotProps.value">
                                        <span>{{format_date(slotProps.value.estimated_arrival_datetime)}} - {{slotProps.value.id_call.id_ship.name}}</span>
                                    </div>
                                </template>
                                <template #option="slotProps">
                                    <div> {{format_date(slotProps.option.estimated_arrival_datetime)}} - {{slotProps.option.id_call.id_ship.name}} </div>
                                </template>
                            </Dropdown>
                            <label for="service_sheet" :class="{'p-error':incidence_report_errors.id_service_sheet}"> Service Sheet <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_service_sheet" class="p-error"><span v-for="error in incidence_report_errors.id_service_sheet" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.id_service_sheet.$invalid && submitted) || v$.id_service_sheet.$pending.$response" class="p-error">{{v$.id_service_sheet.required.$message.replace('Value', 'Service sheet')}}</small>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div v-if="!service_sheet" class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">
                            <Dropdown data_cy="dropdown_incidence_zone" :loading="load" id="id_incidence_zone" v-model="id_incidence_zone" :options="all_incidence_zones" optionLabel="title" :class="{'p-invalid':v$.id_incidence_zone.$invalid && submitted || incidence_report_errors.id_incidence_zone}" :filter="true"/>
                            <label for="id_incidence_zone" :class="{'p-error':incidence_report_errors.id_incidence_zone}"> Zone <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_zone" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_zone" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.id_incidence_zone.$invalid && submitted) || v$.id_incidence_zone.$pending.$response" class="p-error">{{v$.id_incidence_zone.required.$message.replace('Value', 'Incidence zone')}}</small>
                    </div>
                </div>
                <div v-else class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">
                            <Dropdown :loading="load" id="id_incidence_zone" v-model="id_incidence_zone" :options="all_incidence_zones" optionLabel="title" :class="{'p-invalid':v$.id_incidence_zone.$invalid && submitted || incidence_report_errors.id_incidence_zone}" :filter="true"/>
                            <label for="id_incidence_zone" :class="{'p-error':incidence_report_errors.id_incidence_zone}">Zone <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_zone" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_zone" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.id_incidence_zone.$invalid && submitted) || v$.id_incidence_zone.$pending.$response" class="p-error">{{v$.id_incidence_zone.required.$message.replace('Value', 'Incidence zone')}}</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="!service_sheet" class="col-12 md:col">
                    <div class="field ">
                        <div class="p-float-label">
                            <Dropdown data_cy="dropdown_incidence_category" :loading="load" id="id_incidence_category" v-model="id_incidence_category" :options="all_incidence_categories" optionLabel="title" :class="{'p-invalid':v$.id_incidence_category.$invalid && submitted || incidence_report_errors.id_incidence_category}" :filter="true"/>
                            <label for="id_incidence_category" :class="{'p-error':incidence_report_errors.id_incidence_category}">Category <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_category" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_category" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.id_incidence_category.$invalid && submitted) || v$.id_incidence_category.$pending.$response" class="p-error">{{v$.id_incidence_category.required.$message.replace('Value', 'Incidence category')}}</small>                            
                    </div>
                </div>
                <div v-else class="col-12 md:col">
                    <div class="field ">
                        <div class="p-float-label">
                            <Dropdown :loading="load" id="id_incidence_category" v-model="id_incidence_category" :options="get_finger_category_names" optionLabel="title" :class="{'p-invalid':v$.id_incidence_category.$invalid && submitted || incidence_report_errors.id_incidence_category}" :filter="true"/>
                            <label for="id_incidence_category" :class="{'p-error':incidence_report_errors.id_incidence_category}">Category <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_category" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_category" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.id_incidence_category.$invalid && submitted) || v$.id_incidence_category.$pending.$response" class="p-error">{{v$.id_incidence_category.required.$message.replace('Value', 'Incidence category')}}</small>                            
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">
                            <Dropdown data_cy="dropdown_incidence_subcategory" :loading="load" id="id_incidence_subcategory" v-model="id_incidence_subcategory" :options="all_incidence_subcategories" optionLabel="title" :class="{'p-invalid':v$.id_incidence_subcategory.$invalid && submitted || incidence_report_errors.id_incidence_subcategory}" :filter="true"/>
                            <label for="id_incidence_subcategory" :class="{'p-error':incidence_report_errors.id_incidence_subcategory}">Subcategory <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_subcategory" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_subcategory" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.id_incidence_subcategory.$invalid && submitted) || v$.id_incidence_subcategory.$pending.$response" class="p-error">{{v$.id_incidence_subcategory.required.$message.replace('Value', 'Incidence subcategory')}}</small>                                                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 md:col">
                    <div class="field">
                        <div class="p-float-label">     
                            <TextArea id="text" :autoResize="true" rows="5" v-model="text" :class="{'p-invalid':v$.text.$invalid && submitted || incidence_report_errors.text}" />
                            <label for="text" :class="{'p-error':v$.text.$invalid && submitted}"> Text <b class="required_field">*</b></label>
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.text" class="p-error"><span v-for="error in incidence_report_errors.text" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.text.$invalid && submitted) || v$.text.$pending.$response" class="p-error">{{v$.text.required.$message.replace('Value', 'Text')}}</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 md:col text-center">
                    <div class="field">
                        <div class="p-float-label">
                            <FileUpload :chooseLabel="photo_name" ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUpload" />
                        </div>
                        <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.photo" class="p-error"><span v-for="error in incidence_report_errors.photo" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
        </form>
    </div>   
    <div v-if="!load" class="container pt-3 mb-5">
        <div class="row text-center">
            <div class="col">
                <Button data-cy="create_incidence_button" form="form" type="submit" label="Create" class="p-button-success p-button-raised" :disabled="load_submitted == true"/>
            </div>
        </div>    
    </div>
</template>

<script>
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import Toast from 'primevue/toast'
import FileUpload from  'primevue/fileupload'
import TextArea from 'primevue/textarea'

import moment from 'moment'

import { required, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"
import { num2time } from '@/modules/utilities.js'

export default {
    setup: () => ({ v$: useVuelidate() }),
    props: { 
        service_sheet: Object,
        terminal_security: Boolean,
        service_incidence: Array,
        new_incidence: Object
    },
    emit: [
        'update:new_incidence'
    ],
    components: {
        Button,
        InputText,
        Calendar,
        Dropdown,
        FileUpload,
        Toast,
        TextArea,
    },
    data() {
        return {
            new_incidence_clone: this.new_incidence,
            displayModal: false,
            title: '',
            text:'',
            photo:'',
            photo_name: 'Choose',
            date: '',
            hour: moment().format('HH:mm'),
            incidence_type:'',
            id_terminal:'',
            id_incidence_zone:'',
            id_incidence_category:'',
            id_incidence_subcategory:'',
            id_call:null,
            id_user_responsible: '',
            id_service_sheet: '',
            incidence_type_choices:[
                "General", "Service Sheet"
            ],
            incidence_report_errors:{},
            submitted: false,
            load_submitted: false,
            showMessage: false,
            color: localStorage.getItem('primary_color'),
            load: true,
            load_ss: true,
        }
    },
    async mounted() {

        if(this.service_sheet) {
            this.incidence_type = 'Service Sheet'
            this.id_service_sheet = this.service_sheet
            
            this.all_incidence_zones.map(zone => {
                if(this.terminal_security === true) {
                    if(zone.title === 'General-All') {
                        this.id_incidence_zone = zone
                    }  
                } else {
                    if(zone.title.toLowerCase().includes('finger')) {
                        this.id_incidence_zone = zone
                    } 
                }
            })

            this.all_incidence_categories.map(category => {
                if(this.terminal_security === true) {
                    if(category.title === 'Terminal Security Service') {
                        this.id_incidence_category = category
                    } 
                } 
            })

            if(this.service_incidence){
                this.title = ''
            }

            this.all_terminals.map(terminal => {
                if(terminal.name === this.service_sheet.id_terminal.name) {
                    this.id_terminal = terminal
                }
            })
           
            this.date = moment().format().split('T')[0]
            this.hour = moment().format().split('T')[1].split(':')[0] + ':' + moment().format().split('T')[1].split(':')[1]

        }

        if(Object.keys(this.get_provisional_record).length > 0) {
            this.id_terminal = this.get_provisional_record.terminal
            this.date = this.get_provisional_record.date
            this.hour = this.get_provisional_record.hour
            this.text = this.get_provisional_record.text

            this.$store.dispatch('clean_provisional_record')
        }

        this.$emit('update:new_incidence', this.new_incidence)
        this.load = false
        this.change_service_sheet()
        // await this.$store.dispatch('load_all_service_sheets')
        this.load_ss = false

    },
    computed: {
        all_incidence_reports: function() {
            return this.$store.getters.all_incidence_reports
        },
        all_incidence_zones: function() {
            return this.$store.getters.all_incidence_zones
        },
        all_incidence_categories: function() {
            return this.$store.getters.all_incidence_categories
        },
        all_incidence_subcategories: function() {
            return this.$store.getters.all_incidence_subcategories
        },
        all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        get_errors_incidence_report: function() {
            return this.$store.getters.get_errors_incidence_report
        },
        general_incidences_zone: function() {
            return this.$store.getters.general_incidences_zone
        },
        call_incidences_zone: function() {
            return this.$store.getters.call_incidences_zone
        },
        general_incidences_category: function() {
            return this.$store.getters.general_incidences_category
        },
        call_incidences_category: function() {
            return this.$store.getters.call_incidences_category
        },
        general_incidences_subcategory: function() {
            return this.$store.getters.general_incidences_subcategories
        },
        call_incidences_subcategory: function() {
            return this.$store.getters.call_incidences_subcategory
        },
        get_users: function() {
            return this.$store.getters.users_by_port
        },
        all_service_sheets: function() {
            return this.$store.getters.all_service_sheets
        },
        get_finger_category_names: function () {
            return this.$store.getters.get_finger_category_names
        },
        get_provisional_record: function() {
            return this.$store.getters.get_provisional_record
        },
        filtered_service_sheets() {
            return this.$store.getters.filtered_service_sheets(
                this.id_terminal,
                this.date
            );
        },
        get_service_sheets_filtered: function() {
            return this.$store.getters.get_service_sheets_filtered
        }
    },
    methods: {
        num2time(data) {
            return num2time(data)
        },
        format_date(value) {
            if(value instanceof Date){
                return value.toISOString().split('T')[0]
               
            } else {
                return value.split('T')[0]
            }
        },
        change_service_sheet() {
            let data = {
                date: this.date,
                id_terminal: this.id_terminal.name
            }

            if(this.id_terminal != '' && this.date !=  '') {
                this.$store.dispatch('get_service_sheets_by_date_terminal', data)
            }
        },
        onUpload(e){
            this.photo_name = e.files[0].name
            this.photo = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_incidence_report() {
            let incidence_reports = {}

            if(this.incidence_type == 'General'){ 
                incidence_reports = { 
                    title: this.title,
                    text: this.text,
                    incidence_type: this.incidence_type,
                    photo: this.photo,
                    date: this.date,
                    hour: this.hour,
                    id_terminal: this.id_terminal,
                    id_incidence_zone: this.id_incidence_zone,
                    id_incidence_report: this.id_incidence_report,
                    id_incidence_category: this.id_incidence_category,
                    id_incidence_subcategory: this.id_incidence_subcategory,
                    id_call: this.id_call,
                    id_user_responsible: this.id_user_responsible.id,
                } 
            } else if (this.incidence_type == 'Service Sheet') {
                incidence_reports = { 
                    title: this.title,
                    text: this.text,
                    incidence_type: this.incidence_type,
                    photo: this.photo,
                    date: this.date,
                    hour: this.hour,
                    id_terminal: this.id_terminal,
                    id_incidence_zone: this.id_incidence_zone,
                    id_incidence_report: this.id_incidence_report,
                    id_incidence_category: this.id_incidence_category,
                    id_incidence_subcategory: this.id_incidence_subcategory,
                    id_call: this.id_call,
                    id_user_responsible: this.id_user_responsible.id,
                    id_service_sheet: this.id_service_sheet.id
                } 
            }
      
            await this.$store.dispatch('create_incidence_report', incidence_reports)
            this.incidence_report_errors = this.get_errors_incidence_report
            if(Object.keys(this.incidence_report_errors).length === 0){
                this.toggleDialog();
                this.resetForm()
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            
            this.submitted = true;
            this.load_submitted = true
            
            if (!isFormValid) {
                this.load_submitted = false
                return;
            }
            
            let res = await this.add_incidence_report()
            
            if(res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence report added', life: 3000});
                this.load_submitted = false
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.title = '';
            this.text = '';
            this.photo = '';
            this.photo_name = 'Choose',
            this.date = '';
            this.hour = moment().format('HH:mm');
            this.incidence_type = '';
            this.id_incidence_zone = '';
            this.id_incidence_category = '';
            this.id_incidence_subcategory = '';
            this.id_terminal = '';
            this.id_service_sheet = '';
            this.id_user_responsible = '';
            this.submitted = false;
        }
    },
    validations() {
        return {
            title: { required },
            text: { required },
            incidence_type: { required },
            date: { required },
            hour: { required },
            id_incidence_zone: { required },
            id_incidence_category: { required },
            id_incidence_subcategory: { required },
            id_terminal: { required },
            id_service_sheet: { 
                required: requiredIf(function() {
                    return this.incidence_type == 'Service Sheet'
                }) 
            }
        }
    },
    watch: {
        new_incidence: {
            handler(newValue) {
                this.new_incidence_clone = newValue
                this.date = this.new_incidence.date
                this.hour = this.new_incidence.hour
                this.text = this.new_incidence.text
                this.id_terminal = this.new_incidence.terminal
            }
        }, 
    }
};
</script>

<style scoped>

.required_field{
    color: v-bind(color)
}


</style>