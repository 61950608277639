<template>
    <div class="container-fluid pt-5">
        <div class="row">
            <div class="col">
                <div class="content-section introduction">
                    <div class="feature-intro">
                        <h1> Tasks </h1>
                    </div>
                </div>
            </div> 
        </div>
        <div class="row mt-4 justify-content-center card" style="background-color: #f8f9fa;">
            <div class="col-12" style="padding: 0px;">
                <DataTable :value="get_all_tasks" :paginator="true" :rows="10"  v-model:expandedRows="expandedRows" scrollable
                @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" removableSort stripedRows :loading="loading" responsiveLayout="scroll" scrollHeight="60vh"
                v-model:filters="filters" filterDisplay="row" :globalFilterFields="['title', 'id_terminal.name', 'status', 'start_datetime']" :paginatorTemplate="paginatorTemplate">
                    <Column :expander="true" style="width: 4rem;" />
                    <Column field="title" header="Title" style="min-width: 16rem;">
                        <template #body="{data}">
                            <span v-tooltip.top="data.title"> {{format_title(data.title)}} </span>
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" placeholder="Search by title" v-tooltip.top.focus="'Filter as you type'"/>
                        </template>
                    </Column>
                    <Column field="id_terminal.name" header="Terminal" :sortable="true" style="min-width: 15rem">
                        <template #body="slotProps">
                            {{slotProps.data.id_terminal.name}}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="get_terminal_names" placeholder="Any" class="p-column-filter">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value"><strong>{{slotProps.value}}</strong></span>
                                    <span v-else>{{slotProps.placeholder}}</span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{slotProps.option}}</span>
                                </template>
                            </Dropdown>
                        </template>
                    </Column>
                    <Column field="status" header="Status" :sortable="true" style="min-width: 15rem">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.status != null"> <Tag severity="info" :class="'status-' + slotProps.data.status.split(' ')[0]"><p>{{slotProps.data.status}}</p></Tag> </span>
                            <span v-else> - </span>
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="Any" class="p-column-filter">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value"><strong>{{slotProps.value}}</strong></span>
                                    <span v-else>{{slotProps.placeholder}}</span>
                                </template>
                                <template #option="slotProps">
                                    <span> {{slotProps.option}}</span>
                                </template>
                            </Dropdown>
                        </template>
                    </Column>
                    <Column field="is_archived" header="Archived" :sortable="true" style="min-width: 15rem">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.is_archived == true"  style="cursor: pointer;"> <Tag severity="success" value="Archived" @click="open_unarchive_validation(slotProps.data.id)" class="clickable"> </Tag> </span>
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <Dropdown :options="is_archived" v-model="filterModel.value" @change="filterCallback()" class="p-column-filter" placeholder="Any">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value">
                                        <span v-if="slotProps.value == true">
                                            <strong> Archived </strong> 
                                        </span>
                                    </span>
                                    <span v-else>
                                        <span v-if="slotProps.value == false">
                                            <strong> Not Archived </strong>
                                        </span>
                                        <span v-else>
                                            {{slotProps.placeholder}}
                                        </span>
                                    </span>
                                </template>
                                <template #option="slotProps">
                                    <span v-if="slotProps.option == false">
                                        Not Archived 
                                    </span>
                                    <span v-else>
                                        Archived 
                                    </span>
                                </template>
                            </Dropdown>
                        </template>
                    </Column>
                    <Column field="start_datetime" filterField="start_datetime" header="Day" :sortable="true" style="min-width: 16rem">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.start_datetime"> {{format_time(slotProps.data.start_datetime)}} </span>
                            <span v-else> Not defined </span>
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <Calendar v-model="filterModel.value" @date-select="filterCallback()" dateFormat="yy-mm-dd" class="p-column-filter" placeholder="Search by day"/>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <span v-if="get_budget_from_task(slotProps.data.id).length == 0">
                            <div class="row">
                                <div class="col text-center">
                                    <h6> This task has no budgets asssigned.</h6>
                                </div>
                            </div>
                        </span>
                        <span v-else >
                            <div class="row">
                                <div v-for="budget in get_budget_from_task(slotProps.data.id)" :key="budget" class="col-12 md:col-12 lg:col-4">
                                    <a :href="budget.budget" target="_blank">
                                        <div class="card" style="min-width: 340px;">
                                            <div class="container">
                                                <div class="row mt-2">
                                                    <div class="col">
                                                        <Avatar icon="fa-regular fa-file-image" class="mr-2" size="large" style="background-color:#2196F3; color: #ffffff" shape="circle" /> 
                                                    </div>
                                                    <div class="col text-end">
                                                        <span> <Tag :class="'status-' + budget.status.split(' ')[0]">{{budget.status}}</Tag> </span>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-4">
                                                        <small><strong> Budget </strong></small>
                                                    </div>
                                                    <div class="col text-end">
                                                        <i class="fa-regular fa-calendar me-2"></i> {{format_time(budget.upload_date)}}
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>   
                                    </a>
                                </div>
                            </div>
                        </span>
                    </template>
                    <template #empty>
                        <div class="col text-center">
                            No records found.
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </div> 

    <Dialog header="Confirmation" v-model:visible="display_unarchive_confirmation" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div class="container">
            <div class="row">
                <div class="col">
                    <span> Do you really want to unarchive this task?</span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" @click="close_unarchive_validation" class="p-button-text"/>
            <Button label="Yes" icon="pi pi-check" @click="unarchive_task" autofocus />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Tag from 'primevue/tag'
import Avatar from 'primevue/avatar'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'

import { FilterMatchMode } from 'primevue/api';
import moment from 'moment'

export default {
    name: 'Task Budgets',
    components: {
        DataTable,
        Column,
        Tag,
        Avatar,
        InputText,
        Dropdown,
        Calendar,
        Dialog,
        Button,
    },
    props: {
        filter_board: {
            type: String,
        }
    },
    data() {
        return {
            expandedRows: [],
            filters: {
                'title': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'id_terminal.name': {value: null, matchMode: FilterMatchMode.EQUALS},
                'status': {value: this.filter_board, matchMode: FilterMatchMode.EQUALS},
                'start_datetime': {value: null, matchMode: FilterMatchMode.DATE_IS},
                'is_archived': {value: null, matchMode: FilterMatchMode.EQUALS}
            },
            statuses: [
                'Pending', 'Scheduled', 'Scheduling', 'Finished'
            ],
            is_archived: [
                true, false
            ],
            loading: false,
            id_task: '',
            display_unarchive_confirmation: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    computed: {
        get_budget_table: function() {
            return this.$store.getters.get_budget_table
        },
        get_all_tasks: function() {
            return this.$store.getters.get_all_tasks
        },
        get_terminal_names: function() {
            return this.$store.getters.get_terminal_names
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        this.loading = true
        await this.$store.dispatch('load_all_budgets')
        await this.$store.dispatch('load_tasks')
        await this.$store.dispatch('load_all_terminals')
        this.loading = false
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        format_time(value) {
            return moment(value).format('yy-MM-DD')
        },
        get_budget_from_task(id) {
            return this.$store.getters.get_budget_from_task(id) ?? {}
        },
        format_title(value){
            if(value.length > 20){
                return value.substring(0, 20) + '...'
            } else {
                return value
            }
        },
        unarchive_task(){
            this.$store.dispatch('unarchive_task', this.id_task)
            this.display_unarchive_confirmation = false
            this.$toast.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Task unarchived.",
                    life: 3000,
                });
        },
        open_unarchive_validation(id){
            this.id_task = id
            this.display_unarchive_confirmation = true
        },
        close_unarchive_validation() {
            this.display_unarchive_confirmation = false
        }
    }
    
}
</script>

<style scoped>

.status-Confirmed{
    background-color: green;
}

.status-Rejected{
    background-color: red;
}

a{
    text-decoration: none;
    color: inherit;
}

:deep(.p-tag-icon){
    margin-right: 0px !important;
}

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.clickable{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

p{
    margin-bottom: 0px;
    font-weight: 100;
}

</style>