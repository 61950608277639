<template>
    <div class="card">
        <div class="row">
            <Toast />
            <DataTable :value="all_incidence_categories" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" scrollable 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            :globalFilterFields="['title','description', 'incidence_type']"
            v-model:filters="filters" :loading="loading"
            stripedRows>   
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText id="filter_incidence_categories" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                        </span>
                    </div>
                </template>
                <Column header="Title" field="title" :sortable="true" > 
                </Column>
                <Column header="Description" field="description" :sortable="true" > 
                </Column>
                <Column header="Incidence_type" field="incidence_type" :sortable="true" > 
                </Column>
                <Column v-if="$can('change','task_category')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_incidence_category(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','task_category')" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_incidence_category(slotProps.data)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_incidence_category_modal" v-model:visible="incidence_category_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Incidence Category Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="title" class="ps-1 pb-1"> <strong>Title</strong> </label>
                        <InputText id="title" v-model="incidence_category.title" required="true" autofocus :class="{'p-invalid': submitted && !incidence_category.title || incidence_category_errors.title}"/>
                        <small class="p-error" v-if="submitted && !incidence_category.title">Title is required.</small>
                        <small v-if="Object.keys(incidence_category_errors).length !== 0 && incidence_category_errors.title" class="p-error"><span v-for="error in incidence_category_errors.title" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="incidence_type" :class="{'p-invalid': submitted && !incidence_category.incidence_type || incidence_category.incidence_type}" class="ps-1 pb-1"> <strong>Incidence type</strong> </label>
                        <Dropdown data-cy="dropdown_incidence_type" id="incidence_type" v-model="incidence_category.incidence_type" :options="incidence_type_choices" :filter="true">
                            <template #value="slotProps">
                                <div v-if="slotProps.value && slotProps.value.name">
                                    <span>{{slotProps.value.name}}</span>
                                </div>
                                <div v-else-if="slotProps.value && !slotProps.value.name">
                                    <span >{{slotProps.value}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(incidence_category_errors).length !== 0 && incidence_category_errors.incidence_type" class="p-error"><span v-for="error in incidence_category_errors.incidence_type" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="description" class="ps-1 pb-1"><strong>Description</strong> </label>
                        <InputText id="description" v-model="incidence_category.description" required="true" autofocus :class="{'p-invalid': submitted && !incidence_category.description || incidence_category_errors.description}"/>
                        <small class="p-error" v-if="submitted && !incidence_category.description">Description is required.</small>
                        <small v-if="Object.keys(incidence_category_errors).length !== 0 && incidence_category_errors.description" class="p-error"><span v-for="error in incidence_category_errors.description" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_incidence_category_dialog"/>
            <Button data-cy="save_edit_incidence_category" label="Save" @click="save_incidence_category" /> 
        </template>
    </Dialog>
    <Dialog v-model:visible="delete_incidence_category_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="incidence_category">Are you sure you want to delete <b>{{incidence_category.title}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_incidence_category_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deleteIncidence_categories" />
        </template>
    </Dialog>
</template>

<script> 
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown'
import { FilterMatchMode } from 'primevue/api';
import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'

export default {
    setup: () => ({ v$: useVuelidate() }),
    title: 'TableIncidence_categories',
    components: {
        DataTable,
        Column,
        Button,
        Dialog,
        Toast,
        InputText,
        Dropdown
    },
    data() {
        return {
            incidence_category: {},
            incidence_category_errors:{},
            incidence_type_choices:[
                "General", "Call", "Both"
            ],
            delete_incidence_category_dialog: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            incidence_category_dialog: false,
            submitted: false,
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_incidence_categories.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_incidence_categories')
            this.loading = false
        }
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_incidence_categories: function() {
            return this.$store.getters.all_incidence_categories
        },
        get_errors_incidence_category: function() {
            return this.$store.getters.get_errors_incidence_category
        },
        get_delete_incidence_category_status: function() {
            return this.$store.getters.get_delete_incidence_category_status
        },
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        confirm_delete_incidence_category(incidence_category) {
            this.incidence_category = incidence_category;
            this.delete_incidence_category_dialog = true;
        },
        async deleteIncidence_categories() {
            await this.$store.dispatch('delete_incidence_category', this.incidence_category.id)
            if (!this.get_delete_incidence_category_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence category deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Incidence category can't be deleted", life: 3000});
            }
            this.delete_incidence_category_dialog = false;
        },
        edit_incidence_category(incidence_category) {
            
            this.incidence_category = {...incidence_category};
            this.incidence_category_dialog = true;
        },
        async save_incidence_category() {
            this.submitted = true;
            
            await this.$store.dispatch('update_incidence_category', this.incidence_category)
            this.incidence_category_errors = this.get_errors_incidence_category
            
            if(Object.keys(this.incidence_category_errors).length === 0){
                this.hide_incidence_category_dialog()
                this.incidence_category = {};
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence category modified', life: 3000});
            }
        },
        hide_incidence_category_dialog() {
            this.incidence_category_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            title: { required },
            description: { required },
            incidence_type: { required },
        }
    },

}
</script>

<style  scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>