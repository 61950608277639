<template>
    <ModalBillingDestination/>
	<TableBillingDestination/>
</template>

<script>
import TableBillingDestination from '@/components/AdminDashboard/Billing_destination/TableBilling_destination.vue'
import ModalBillingDestination from '@/components/AdminDashboard/Billing_destination/ModalBilling_destination.vue'

export default {
	name: 'Billing_destination',
	components : {
		TableBillingDestination,
		ModalBillingDestination
	}
}
</script>

<style scoped>

</style>