import axios from 'axios'

const state = {
    users: [],
    user_errors: {},
    profile_errors: {},
    delete_status : false,
    user_names : []
};

const mutations = {
    SET_USERS(state, users) {
        state.users = users
    },
    SET_USER_NAMES(state, user_names) {
        state.user_names = user_names
    },
    
    
};

const actions = {
    async load_all_users({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "users/")
            .then((response) => {
                commit('SET_USERS', response.data)
            })
    },
    async load_user_names({commit}) {
        let names = []
        await axios.get(process.env.VUE_APP_API_URL  + "users/")
            .then((response) => {
                response.data.forEach(element => {
                    names.push(element.name)
                });
                commit('SET_USER_NAMES', names)
            })
    },
    
};

const getters = {
    get_user_names:(state) => state.user_names,
    get_user_by_name:(state) => (name) => {
        return state.users.filter(user => user.name === name)
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};