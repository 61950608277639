import axios from 'axios'
import moment from 'moment'

const state = {
    bills: [],
    validated_bills: [],
    error_bills: [],
    pending_bills: [],
    accounted_bills: [],
    bills_group: [],
    bill_group: [],
    available_bills: [],
    empty_bills: [],
    monthly_bills: [],
    imports_from_bills: [],
    id_bill: [], // Pillar la id de la factura para añadirsela mas tarde a los imports que se añadan después
    error_imports: [],
    current_bill: [],
    admin_comment_errors:[],
    id_bill_edit: '',
    error_image: false,
    edit_mode: false,
}   

const mutations = {
    SET_ALL_BILLS(state, bills){
        state.bills = bills.map(updated_bill => {
            if(updated_bill.creation_datetime != null) {
                updated_bill.creation_datetime = moment(updated_bill.creation_datetime).utc(false).toDate()
            }
            return updated_bill
        })
    },
    SET_VALIDATED_BILLS(state, bills){
        state.validated_bills=bills
    },
    SET_ACCOUNTED_BILLS(state, bills){
        state.accounted_bills=bills
    },

    SET_PENDING_BILLS(state, bills) {
        state.pending_bills = bills
    },
    SET_ERRORS_BILL(state, error_bills) {
        state.error_bills = error_bills
    },
    SET_ERRORS_BILL_VALIDATION(state, admin_comment_errors) {
        state.admin_comment_errors = admin_comment_errors
    },
    CLEAR_ADMIN_COMMENT_ERRORS_BILL(state) {
        state.admin_comment_errors = []
    },
    SET_BILLS_GROUPS(state, bills_group) {
        state.bills_group = bills_group
    },
    SET_BILL_GROUP(state, bill_group) {
        state.bill_group = bill_group
    },
    SET_AVAILABLE_BILLS(state, bills) {
        state.available_bills = bills
    },
    SET_EMPTY_BILLS(state, bills) {
        for(var item in bills.bills) {
            state.empty_bills.push(bills.bills[item])
        }
    },
    SET_CURRENT_EMPTY_BILLS(state){
        state.empty_bills = []
    },
    SET_MONTHLY_BILLS(state, payload){
        state.monthly_bills = payload
    },
    NEW_BILL(state, bill) {
        state.id_bill = bill.id
        state.current_bill = bill
        state.bills.push(bill)
    },
    NEW_PROPOSAL_PAYMENT(state, bill_groups) {
        state.bills_group.unshift(bill_groups)
    },
    UPDATE_PROPOSAL_PAYMENT(state, bill_groups){
        state.bills_group = state.bills_group.map(updated_bill_groups => {
            if(updated_bill_groups.id === bill_groups.id) {
                updated_bill_groups.date = bill_groups.date
                updated_bill_groups.bills = bill_groups.bills
                updated_bill_groups.total_import = bill_groups.total_import
                updated_bill_groups.validated = bill_groups.validated
            }
            return updated_bill_groups
        })
    },
    UPDATE_BILL(state, bill) {

        state.bills = state.bills.map(updated_bills => {
            if(updated_bills.id === bill.id) {
                updated_bills.number = bill.number
                updated_bills.terminals = bill.terminals
                updated_bills.terminals_name = bill.terminals_name
                updated_bills.id_supplier = bill.id_supplier
                updated_bills.issue_date = bill.issue_date
                updated_bills.due_date = bill.due_date
                updated_bills.payment_date = bill.payment_date
                updated_bills.bill = bill.bill
                updated_bills.budget = bill.budget
                updated_bills.validated = bill.validated
                updated_bills.concept = bill.concept 
                updated_bills.total_import = bill.total_import 
                updated_bills.iva = bill.iva
                updated_bills.base_import = bill.base_import 
                updated_bills.id_card = bill.id_card
                updated_bills.id_currency = bill.id_currency
                if(bill.validated != true || bill.validated != false){
                    const index = state.pending_bills.findIndex(old_bill => old_bill.id === bill.id)
                    if (index !== -1) {
                        state.pending_bills.splice(index, 1)
                    }
                    state.pending_bills.push(updated_bills)
                }
            }
            return updated_bills
        })
    },
    CHANGE_BILL_VALIDATION(state, bill) {
        
        state.bills = state.bills.map(updated_bills => {
            
            if(updated_bills.id === bill.id) {
                updated_bills.validated = bill.validated
                if(bill.validated === null) {

                    let index = state.pending_bills.findIndex(bills => bills.id == updated_bills.id)
                    if(index >= 0) {
                        state.pending_bills.splice(index, 1)
                    }
                    state.pending_bills.push(updated_bills)
                }else{
                    let index = state.pending_bills.findIndex(bills => bills.id == updated_bills.id)
                    if(index >= 0) {
                        state.pending_bills.splice(index, 1)
                    }
                }
                
            }
            return updated_bills
        })
        

        if(bill.validated == true || bill.validated == false){
            let index = state.pending_bills.findIndex(bills => bills.id == bill.id)
            if (index >= 0){
                state.pending_bills.splice(index, 1)
            }
        }
        
    },
    CHANGE_PAYMENT_PROPOSAL_VALIDATION(state, bills_group){
        state.bills_group = state.bills_group.map(updated_bills_group => {
            if(updated_bills_group.id === bills_group.id) {
                updated_bills_group.validated = bills_group.validated
            }
            return updated_bills_group
        })
    },
    REMOVE_VALIDATED_BILLS(state,id_list){
        for(var item in id_list){
            let index = state.validated_bills.findIndex(validated_bills => validated_bills.id == id_list[item])
            state.validated_bills.splice(index, 1)
        }
    },
    DELETE_BILL(state, id){
        let index = state.bills.findIndex(bills => bills.id == id)
        state.bills.splice(index, 1)
    },
    DELETE_BILL_GROUP(state, id) {
        let index = state.bills_group.findIndex(bills_group => bills_group.id == id)
        state.bills_group.splice(index, 1)
    },
    CLEAR_ERRORS_BILL(state) {
        state.error_bills = []
    },
    ADD_BILL_TO_TARGET(state, bill){
        state.empty_bills.push(bill)
        for(var item in state.available_bills){
            if(state.available_bills[item].id == bill.id){
                state.available_bills.splice(item,1)
            }
        }
    },
    ADD_BILL_TO_SOURCE(state, bill){
        state.available_bills.push(bill)
        for(var item in state.empty_bills){
            if(state.empty_bills[item].id == bill.id){
                state.empty_bills.splice(item,1)
            }
        }
    },
    SET_IMPORTS(state, payload) {
        state.imports_from_bills = payload
    },
    ADD_IMPORT(state, payload) {
        
        state.imports_from_bills.push(payload)
        state.bills = state.bills.map(updated_bills => {
            if(updated_bills.id === payload.id_bill.id) {
                updated_bills.imports.push(payload)
            }
            return updated_bills
        })
        
    },

    EMPTY_PDF_ERROR_IMAGE(state){
        state.error_image = false
    },
    SET_ERROR_PDF_IMAGE(state){
        state.error_image = true
    },

    EMPTY_IMPORT_BILLS(state) {
        state.imports_from_bills = []
    },
    DELETE_IMPORT_CALL(state, id) {
        let index = state.imports_from_bills.findIndex(bills => bills.id == id)
        state.imports_from_bills.splice(index, 1)
    },
    SET_ERROR_IMPORTS(state, payload) {
        state.error_imports = payload
    },
    EMPTY_ERROR_IMPORTS(state) {
        state.error_imports = []
    },
    EMPTY_CURRENT_BILL(state) {
        state.current_bill = []
        state.id_bill = []
    },
    EDIT_MODE(state, payload) {
        state.edit_mode = payload
    },
    SET_ID(state, payload) {
        state.id_bill_edit = payload
    }
}

const actions = {
    async account_list_bills({commit}, data) {
        await axios.post(process.env.VUE_APP_API_URL + "bills/account_list/", {
            ids : data
        })
            .then(() => {
                commit('REMOVE_VALIDATED_BILLS', data)
                
            }).catch((error) => {
                console.log(error.response.data)
            })
    }, 
    async load_accounted_bills_by_port({commit}, data) {
        await axios.get(process.env.VUE_APP_API_URL + "bills/get_accounted_by_port_range/" + data.port.id+ '/' + moment(data.dates[0]).format('DD-MM-YYYY') + '/' + moment(data.dates[1]).format('DD-MM-YYYY') + '/')
            .then((response) => {
                commit('SET_ACCOUNTED_BILLS', response.data)
            })
    }, 
    async load_accounted_bills({commit}, data) {
        await axios.get(process.env.VUE_APP_API_URL + "bills/get_accounted/" + moment(data.dates[0]).format('DD-MM-YYYY') + '/' + moment(data.dates[1]).format('DD-MM-YYYY') + '/')
            .then((response) => {
                commit('SET_ACCOUNTED_BILLS', response.data)
            })
    },

    //Rango fechas en Bills
    async load_range_bills({commit}, data) {
        await axios.get(process.env.VUE_APP_API_URL + "bills/get_by_date_range/" + moment(data.dates[0]).format('YYYY-MM-DD') + '/' + moment(data.dates[1]).format('YYYY-MM-DD') + '/')
            .then((response) => {
                commit('SET_ALL_BILLS', response.data)
            })
    },

    async load_validated_bills_by_port({commit}, data) {
        await axios.get(process.env.VUE_APP_API_URL + "bills/get_bills_by_port/" + data+ '/')
            .then((response) => {
                commit('SET_VALIDATED_BILLS', response.data)
            })
    }, 
    async load_validated_bills({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "bills/get_validated/")
            .then((response) => {
                commit('SET_VALIDATED_BILLS', response.data)
            })
    }, 
    async load_pending_bills({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "bills/get_pending/")
            .then((response) => {
                commit('SET_PENDING_BILLS', response.data)
            }) 
    },
    async load_bills_groups({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "bill_groups/")
            .then((response) => {
                commit('SET_BILLS_GROUPS', response.data)
            }) 
    },
    async load_bill_group({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "bill_groups/" + id + "/")
            .then((response) => {
                commit('SET_BILL_GROUP', response.data)
            }) 
    },
    async load_available_bills({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "bills/get_available_bills/")
            .then((response) => {
                commit('SET_AVAILABLE_BILLS', response.data)
            })
    },
    async load_current_bills({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "bill_groups/" + id + "/")
            .then((response) => {
                commit('SET_EMPTY_BILLS', response.data)
            })
    },
    async load_monthly_bills_by_card({commit}, data) {
        await axios.get(process.env.VUE_APP_API_URL + "bills/get_monthly_bills_by_card/" + data.id_card + "/?month=" + data.month+ "&year=" + data.year)
            .then((response) => {
                
                commit('SET_MONTHLY_BILLS', response.data)
            })
    },
    set_current_bills_empty({commit}){
        commit('SET_CURRENT_EMPTY_BILLS')
    },
    async create_new_bill({commit}, data) {

        data['id_supplier'] = data.supplier.id
        
        data['id_currency'] = data.id_currency.id

        if(data.id_card == '') {
            delete data['id_card']
        } else {
            data['id_card'] = data.id_card.id
        }

        var id_list = []

        if(data.terminals.length > 0) {
            for(var obj in data.terminals) {
                id_list.push(data.terminals[obj].id)
            }
        }

       data['terminals'] = id_list


        if(data.budget == '') {
            delete data['budget']
        }

        var formData = new FormData();
        for(var item in data){
            if(item == 'terminals'){
                data['terminals'].forEach(element => {
                    formData.append('terminals', parseInt(element))    
                });
            }else{
                formData.append(item, data[item])
            }
        }


        
        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
        
        await axios.post(process.env.VUE_APP_API_URL + "bills/", formData, {headers})
            .then((response) => {
                commit('EMPTY_IMPORT_BILLS')
                commit('NEW_BILL', response.data)
            })
    },
    async create_proposal_payment({commit}, data) {

        let id_bills = []

        for(var item in data.bills) {
            id_bills.push(data.bills[item].id)
        }

        await axios.post(process.env.VUE_APP_API_URL + "bill_groups/", {
            date: moment(data.date).format('YYYY-MM-DD'),
            bills: id_bills
        })
            .then((response) => {
                commit('NEW_PROPOSAL_PAYMENT', response.data)
            })
    },
    async update_proposal_payment({commit}, data) {


        let id_bills = []

        for(var item in data.bills) {
            id_bills.push(data.bills[item].id)
        }

        await axios.patch(process.env.VUE_APP_API_URL + "bill_groups/" + data.id + "/", {
            bills: id_bills
        })
            .then((response) => {
                commit('UPDATE_PROPOSAL_PAYMENT', response.data)
            })
    },
    async update_bill({commit}, data) {

        delete data['changed_validation_datetime']

        var id_list = []

        if(data.terminals.length > 0) {
            for(var obj in data.terminals) {
                id_list.push(data.terminals[obj].id)
            }
        }

        data['terminals'] = id_list

        if(typeof(data.id_supplier) == 'object') {
            data.id_supplier = data.id_supplier.id
        }

        if(typeof(data.id_currency) == 'object') {
            data.id_currency = data.id_currency.id
        }

        if(data.id_card == null) {
            delete data['id_card']
        } else if(typeof(data.id_card) == 'object') {
            data.id_card = data.id_card.id
        }
        
        if(typeof(data.bill) == 'string') {
            delete data['bill']
        }
        
        if(data.budget == 'no picture') {
            data['budget'] = null
        } else if (typeof(data.budget) == 'string') {
            delete data['budget']
        }

        if(!data.issue_date) {
            delete data['issue_date']
        } else if(typeof(data.issue_date) == 'object') {
            data.issue_date = moment(data.issue_date).format('YYYY-MM-DD')
        } else {
            delete data['issue_date']
        }

        if(!data.due_date) {
            delete data['due_date']
        } else if(typeof(data.due_date) == 'object') {
            data.due_date = moment(data.due_date).format('YYYY-MM-DD')
        } else {
            delete data['due_date']
        }

        if(!data.payment_date) {
            delete data['payment_date']
        } else if(typeof(data.payment_date) == 'object') {
            data.payment_date = moment(data.payment_date).format('YYYY-MM-DD')
        } else {
            delete data['payment_date']
        }

        var formData = new FormData();

        for(var item in data){
            if(item == 'terminals'){
                data['terminals'].forEach(element => {
                    formData.append('terminals', parseInt(element))    
                });
            }else{
                formData.append(item, data[item])
            }
        }

        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }

        await axios.patch(process.env.VUE_APP_API_URL + "bills/" + data.id + "/", formData, {headers})
            .then((response) => {
                commit('CLEAR_ERRORS_BILL')
                commit('EMPTY_ERROR_IMPORTS')
                commit('UPDATE_BILL', response.data)
            })
            .catch((error) => {
                commit('SET_ERRORS_BILL', error.response.data)
            })
    },

    async upload_new_pdf({commit},data){

        var formData = new FormData();
        formData.append('bill', data.pdf, data.pdf_name)

        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }
        await axios.patch(process.env.VUE_APP_API_URL + "bills/ " +data.id+ "/", formData, {headers})
        .then((response) => {
            commit('EMPTY_PDF_ERROR_IMAGE')
            commit('UPDATE_BILL', response.data)
        })
        .catch(() => {
            commit('SET_ERROR_PDF_IMAGE')
        })
    },

    async change_status_bill({commit}, data) {
        await axios.post(process.env.VUE_APP_API_URL + "bills/change_validation/" + data.status + "/" + data.id + "/",{
            admin_comment:data.admin_comment
        })
            .then((response) => {
                commit('CLEAR_ADMIN_COMMENT_ERRORS_BILL')
                commit('CHANGE_BILL_VALIDATION', response.data)
            })
            .catch((error) => {
                commit('SET_ERRORS_BILL_VALIDATION', error.response.data)
            })
    },
    async change_validation_status_payment_proposal({commit}, data){
        await axios.post(process.env.VUE_APP_API_URL + "bill_groups/change_validation/" + data.message + "/" + data.id + "/")
            .then((response) => {
                commit('CHANGE_PAYMENT_PROPOSAL_VALIDATION', response.data)
            })
    },
    async delete_bill({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "bills/" + id + "/")
            .then(() => {
                commit('DELETE_BILL', id)
            })
    },
    async delete_payment_proposal({commit}, id){
        await axios.delete(process.env.VUE_APP_API_URL + "bill_groups/" + id + "/")
            .then(() => {
                commit('DELETE_BILL_GROUP', id)
            })
    },
    async clear_errors_bill({commit}) {
        commit('CLEAR_ERRORS_BILL')
    },
    add_bill_to_target({commit}, bill) {
        commit('ADD_BILL_TO_TARGET', bill)
    },
    add_bill_to_source({commit}, bill) {
        commit('ADD_BILL_TO_SOURCE', bill)
    },
    async add_import({commit}, bill) {
        await axios.post(process.env.VUE_APP_API_URL + "imports/", {
            description: bill.description,
            quantity: bill.quantity,
            base_import: bill.base,
            iva: bill.iva,
            id_bill: bill.id_bill,
            total_import: 0,
        })
            .then((response) => {
                commit('EMPTY_ERROR_IMPORTS')
                commit('ADD_IMPORT', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_IMPORTS', error.response.data)
            })
    },
    async delete_import_bill({commit}, id){
        await axios.delete(process.env.VUE_APP_API_URL + 'imports/' + id + '/')
            .then(() => {
                commit('DELETE_IMPORT_CALL', id)
            })
    },
    async get_import_by_bill_id({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'imports/get_by_bill/' + id + '/')
            .then((response) => {
                commit('SET_IMPORTS', response.data)
            })
    },
    async empty_imports_bills({commit}) {
        commit('EMPTY_CURRENT_BILL')
        commit('EMPTY_IMPORT_BILLS')
    },
    async edit_mode({commit}, edit) {
        commit('EDIT_MODE', edit)
    },
    async set_id_bill_edit({commit}, id) {
        commit('SET_ID', id)
    }
}

const getters = {
    get_error_image: (state) => state.error_image,
    get_all_bills: (state) => state.bills,
    get_validated_bills: (state) => state.validated_bills,
    //Rango de fechas bills
    get_bills: (state) => state.bills,
    get_accounted_bills: (state) => state.accounted_bills,
    get_error_bills: (state) => state.error_bills,
    get_admin_comment_errors:(state) => state.admin_comment_errors,
    get_pending_bills: (state) => state.pending_bills,
    get_number_of_bills_month: (state) => {
        var monthly_bills = state.bills.filter(bill => moment(bill.creation_date).isAfter(moment().startOf('month')) && moment(bill.creation_date).isBefore(moment().endOf('month')))
        return monthly_bills.length
    },
    get_monthly_total_import: (state) => {
        var total_import = 0
        var monthly_bills = state.bills.filter(bill => moment(bill.creation_date).isAfter(moment().startOf('month')) && moment(bill.creation_date).isBefore(moment().endOf('month')))
        for(var i in monthly_bills){
            total_import = total_import + parseFloat(monthly_bills[i].total_import)
        }
        return parseFloat(total_import).toFixed(2)
    },
    get_bills_group: (state) => state.bills_group,
    get_bill_group: (state) => state.bill_group,
    get_available_bills: (state) => state.available_bills,
    get_empty_bills: (state) => state.empty_bills,
    get_monthly_bills: (state) => state.monthly_bills,
    get_imports_from_bills: (state) => state.imports_from_bills,
    id_bill: (state) => state.id_bill,
    error_imports: (state) => state.error_imports,
    current_bill: (state) => state.current_bill,
    edit_mode: (state) => state.edit_mode,
    id_bill_edit: (state) => state.id_bill_edit
}

export default {
    state,
    mutations,
    actions,
    getters
};