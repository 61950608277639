<template>
    <div class="container-fluid mt-2">
        <div class="row">
            <div class="col">
                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Change Terminal </h5>
            </div>
        </div>
        <div class="row justify-content-center">
            <span v-for="terminal in all_terminals" :key="terminal" class="col-6 md:col-3 xl:col">
                <a href="#task_calendar">
                    <div class="card" style="cursor:pointer;" @click="change_view_calendar(terminal.name)" :class="{'active_terminal': terminal.name === selected_terminal}">
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                    <span> TERMINAL </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h4> {{terminal.name}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </span>
        </div>
        <div class="row"> 
            <div v-if="check_user_task_responsible" class="custom_col">
                <div class="mb-4">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Scheduling Tasks</h5>
                </div>
                <ScrollPanel style="width: 100%;" class="height_scroll_panel">
                    <div class="container p-fluid">
                        <div v-if="get_scheduling_tasks_by_terminal(selected_terminal).length > 0" id="list-group-item" class="row justify-content-center">
                            <div class="list-group-item me-3 col-12 md:col-5 xl:col-12" style="border-radius: 6px;" v-for="item in get_scheduling_tasks_by_terminal(selected_terminal)" :key="item" @mouseover="set_draggable">
                                <div class="container p-0 ms-2 pr-3 pl-1"> 
                                    <div class="row" >
                                        <div class="col-8 my-auto" style="padding-top: 0px; padding-bottom: 0px;">
                                            <small :data-id="item.id" v-tooltip.top="item.title"> {{format_title(item.title)}}</small>
                                        </div>
                                        <div class="col-4 text-end" style="padding-bottom: 0px;">
                                            <div class="circle-image">
                                                <img :src="item.id_user_assigned.picture" class="profile-pic"/>
                                            </div>    
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col" style="color: gray;">
                                            <i class="fa fa-clock" ></i> <small>{{format_time(item.creation_datetime)}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <div class="row justify-content-center">
                                <div class="col-8 md:col-6 xl:col-10">
                                    <small class="mt-2 mb-2"> No tasks pending. </small>
                                    <Button label="Add Task" icon="fa fa-plus" class="p-button-rounded mt-3" @click="$router.push('Tasks_board')"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollPanel>
            </div>
            <div v-if="check_user_task_responsible" class="custom_col_calendar">
                <ScrollPanel style="height: 700px">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Calendar</h5>
                    <FullCalendar :options="calendarOptions" ref="fullCalendar" id="task_calendar"/>
                </ScrollPanel>
            </div>
            <div v-else class="col">
                <ScrollPanel style="height: 700px">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Calendar</h5>
                    <FullCalendar :options="calendarOptions" ref="fullCalendar" id="task_calendar"/>
                </ScrollPanel>
            </div>
        </div>
    </div>

    <Dialog header="Confirmation" v-model:visible="display_confirmation_date" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '60vw'}" :modal="true">
        <div class="container p-fluid">
            <div class="row">
                <div class="col">
                    Is this the correct date?
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <span class="p-float-label">
                        <Calendar id="start_date" v-model="event.start" dateFormat="yy-mm-dd"/>
                        <label for="start_date">Date</label>
                    </span>
                </div>
                <div class="col">
                    <span class="p-float-label">
                        <Calendar id="start_date" v-model="event.start_hour" :timeOnly="true" hourFormat="24" @change="event.start_hour = num2timeedit($event)"/>
                        <label for="start_date">Hour</label>
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <span class="p-float-label">
                        <Calendar id="start_date" v-model="event.end" dateFormat="yy-mm-dd"/>
                        <label for="start_date">Date</label>
                    </span>
                </div>
                <div class="col">
                    <span class="p-float-label">
                        <Calendar id="start_date" v-model="event.end_hour" :timeOnly="true" hourFormat="24" @change="event.end_hour = num2timeedit($event)"/>
                        <label for="start_date">Hour</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="close_modal_confirmation_add"/>
            <Button label="Confirm" @click="add_task_to_calendar"/>
        </template>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="display_task_from_calendar" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '60vw'}"  :modal="true">
        <div class="container">
            <div class="row">
                <div class="col">
                    Are you sure you want to delete the current task from the calendar? This action it's just to reschedule the task, it doesn't delete it.
                </div>
            </div>
        </div>
        <template #footer>
            <Button  class="p-button-danger" label="Delete" @click="delete_task_from_calendar"/>
        </template>
    </Dialog> 

</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';

import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import ScrollPanel from 'primevue/scrollpanel'

import moment from 'moment'
import ability from '../../services/ability'

import { num2time, num2timeedit } from '@/modules/utilities.js'

export default {
    name: 'Task Calendar',
    components: {
        FullCalendar,
        Dialog,
        Button,
        Calendar,
        ScrollPanel,
    },
    props: {
        terminal_optional: {
            type: String,
            required: false
        }
    },
    data() {
        return{
            loading: false,
            display_task_from_calendar: false,
            color: localStorage.getItem('primary_color'),
            prev_start: '',
            last_month: null,
            prev_end: '',
            selected_terminal: '',
            event_revert: '',
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin ],
                initialView: "timeGridWeek",
                headerToolbar: {
                    left: "",
                    center: "title",
                    right: "prev,next",
                },
                events: [],
                /*
                eventTimeFormat: {
                    // like '14:30:00'
                    hour: "2-digit",
                    minute: "2-digit",
                },
                */
                height: '80%',
                displayEventEnd: true,
                displayEventTime: true,
                eventDisplay: 'block',
                firstDay: 1,
                allDaySlot: false,
                selectable: false,
                selectMirror: true,
                eventColor: "#002D72",
                rerenderDelay: 1,
                draggable: true,
                drop: this.drop,
                editable: true,
                slotEventOverlap: true,
                expandRows: true,
                nowIndicator: true,
                aspectRatio: 2.5,
                locale: 'en-gb',
                eventTimeFormat: { 
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                },
                slotLabelFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                },
                windowResize: function() {
                    if(window.innerWidth < 1141) {
                        this.$refs.fullCalendar.getApi().changeView('timeGridDay')
                    } else {
                        this.$refs.fullCalendar.getApi().changeView('timeGridWeek')
                    }
                }.bind(this),
                eventClick: function(event) {
                    if(event.event._def.extendedProps.internal && ability.can('delete','task')) {
                        this.event.id = event.event._def.publicId
                        this.display_task_from_calendar = true
                    } else {
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000});   
                    }
                    
                }.bind(this),
                customButtons: {
                    next: {
                        click: async () => {
                            this.calendarOptions.events = []
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();

                            this.$refs.fullCalendar.getApi().el.setAttribute('disabled', '')
                            
                            var final_events = []
                            await this.$store.dispatch('load_weekly_tasks', moment(calendarApi.currentData.currentDate).format('yy-MM-DD'))
                            await this.$store.dispatch('load_weekly_calls', moment(calendarApi.currentData.currentDate).format('yy-MM-DD'))
                            this.get_scheduled_tasks_by_terminal(this.selected_terminal).forEach(async(element) => {
                                if(!element.id_ship){
                                    var event = {
                                        id: element.id,
                                        title: element.title,
                                        start: element.start_datetime,
                                        end: element.end_datetime,
                                        internal: element.internal,
                                        color: 'lightgray'
                                    }

                                    if(event.internal) {
                                        event.color = '#002D72'
                                    }
                                    //await this.calendarOptions.events.push(event)
                                    final_events.push(event)
                                } else {
                                    var dict_items = {}

                                    if(element.terminal) {
                                        dict_items['title'] = element.terminal + ' - ' + element.id_ship.name
                                    } else {
                                        dict_items['title'] = element.id_ship.name
                                    }
                                    dict_items['start'] = element.arrival_datetime
                                    dict_items['end'] = element.departure_datetime
                                    dict_items['cruise_line'] = element.id_ship.id_cruise_line
                                    dict_items['id'] = element.id
                                    dict_items['ship_id'] = element.id_ship.id
                                    dict_items['foresight'] = element.foresight
                                    dict_items['terminal'] = element.terminal
                                    dict_items['color'] = '#c7c7c7'

                                    if(element.is_new === true) {
                                        dict_items['title'] = dict_items['title'] + ' **'
                                    }
                                    final_events.push(dict_items)
                                    //await this.calendarOptions.events.push(dict_items)
                                }
                            })
                            this.calendarOptions.events = []
                            this.calendarOptions.events = final_events
                        } 
                    },
                    prev: {
                        click: async () => {
                            this.calendarOptions.events = []
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();
                            await this.$store.dispatch('load_weekly_tasks', moment(calendarApi.currentData.currentDate).format('yy-MM-DD'))
                            await this.$store.dispatch('load_weekly_calls', moment(calendarApi.currentData.currentDate).format('yy-MM-DD'))
                            var final_events = []
                            this.get_scheduled_tasks_by_terminal(this.selected_terminal).forEach(async (element) => {
                                if(!element.id_ship){
                                    var event = {
                                        id: element.id,
                                        title: element.title,
                                        start: element.start_datetime,
                                        end: element.end_datetime,
                                        internal: element.internal,
                                        color: 'lightgray'
                                    }

                                    if(event.internal) {
                                        event.color = '#002D72'
                                    }
                                    final_events.push(event)
                                } else {
                                    var dict_items = {}

                                    if(element.terminal) {
                                        dict_items['title'] = element.terminal + ' - ' + element.id_ship.name
                                    } else {
                                        dict_items['title'] = element.id_ship.name
                                    }
                                    dict_items['start'] = element.arrival_datetime
                                    dict_items['end'] = element.departure_datetime
                                    dict_items['cruise_line'] = element.id_ship.id_cruise_line
                                    dict_items['id'] = element.id
                                    dict_items['ship_id'] = element.id_ship.id
                                    dict_items['foresight'] = element.foresight
                                    dict_items['terminal'] = element.terminal
                                    dict_items['color'] = '#c7c7c7'

                                    if(element.is_new === true) {
                                        dict_items['title'] = dict_items['title'] + ' **'
                                    }
                                    final_events.push(dict_items)
                                }
                                
                            })   
                            this.calendarOptions.events = []
                            this.calendarOptions.events = final_events
                            
                        },
                       
                    }
                },
                eventResize: function(event) {
                    if(ability.can('change','task')){
                        if(event.event._def.extendedProps.internal == false || event.event._def.extendedProps.ship_id) {
                            this.$toast.add({severity:'error', summary: 'Error', detail:'Cannot modify.', life: 3000});
                            event.revert()
                        } else {
                            this.event.id = event.event._def.publicId
                            this.event.title = event.event._def.title
                            this.event.start = moment(event.event._instance.range.start).utc(false).format('yy-MM-DD')
                            this.event.start_hour = moment(event.event._instance.range.start).utc(false).format('HH:mm')
                            this.event.end = moment(event.event._instance.range.end).utc(false).format('yy-MM-DD')
                            this.event.end_hour = moment(event.event._instance.range.end).utc(false).format('HH:mm')
                            this.display_confirmation_date = true
                            // event.event.remove()
                        }
                    } else {
                        event.revert()
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000});   
                    }
                }.bind(this),
                eventDrop: function(event) {
                    if(ability.can('change','task')){
                        this.event_revert = event
                        this.prev_start = event.oldEvent._instance.range.start
                        this.prev_end = event.oldEvent._instance.range.end
                        if(event.event._def.extendedProps.internal == false || event.event._def.extendedProps.ship_id) {
                            this.$toast.add({severity:'error', summary: 'Error', detail:'Cannot modify.', life: 3000});
                            event.revert()
                        } else {
                            this.event.id = event.event._def.publicId
                            this.event.title = event.event._def.title
                            this.event.start = moment(event.event._instance.range.start).utc(false).format('yy-MM-DD')
                            this.event.start_hour = moment(event.event._instance.range.start).utc(false).format('HH:mm')
                            this.event.end = moment(event.event._instance.range.end).utc(false).format('yy-MM-DD')
                            this.event.end_hour = moment(event.event._instance.range.end).utc(false).format('HH:mm')
                            this.display_confirmation_date = true

                            // event.revert()
                        }
                    } else {
                        event.revert()
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000});  
                    }
                    
                }.bind(this)
            },
            display_confirmation_date: false,
            event: [],
        }
    },
    computed: {
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        get_scheduling_tasks: function() {
            return this.$store.getters.get_scheduling_tasks
        },
        get_scheduled_tasks: function() {
            return this.$store.getters.get_scheduled_tasks
        },
        get_internal_scheduling_tasks: function() {
            return this.$store.getters.get_internal_scheduling_tasks
        },
        all_port_calls_calendar_filtered: function() {
            return this.$store.getters.all_port_calls_calendar_filtered
        },
        all_terminals: function() {
            var terminals_list = this.$store.getters.all_terminals
            var new_terminal = {
                name: 'All'
            }
            terminals_list.push(new_terminal)
            return terminals_list 
        },
        check_user_task_responsible: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Tasks responsibles') {
                    return true
                }
            }
            return false
        }
    },
    async mounted() {
        this.loading = true

        let calendarApi = this.$refs.fullCalendar.getApi();

        if(window.innerWidth < 1141) {
            this.$refs.fullCalendar.getApi().changeView('timeGridDay')
        } else {
            this.$refs.fullCalendar.getApi().changeView('timeGridWeek')
        }

        await this.$store.dispatch('load_all_terminals')
        await this.$store.dispatch('load_all_tasks')

        if(this.terminal_optional) {
            this.selected_terminal = this.terminal_optional
        } else {
            this.selected_terminal = this.all_terminals[0].name
        }
        

        await this.$store.dispatch('load_weekly_calls', moment(calendarApi.currentData.dateProfile.currentRange.start).format('yy-MM-DD'))
        await this.$store.dispatch('load_weekly_tasks', moment(calendarApi.currentData.dateProfile.currentRange.start).format('yy-MM-DD'))
        var final_events = []
        this.get_scheduled_tasks_by_terminal(this.all_terminals[0].name).forEach((element) => {
            
            if(!element.id_ship){
                var event = {
                    id: element.id,
                    title: element.title,
                    start: element.start_datetime,
                    end: element.end_datetime,
                    internal: element.internal,
                    color: 'lightgray'
                }

                if(event.internal) {
                    event.color = '#002D72'
                }
                final_events.push(event)
            } else {
                var dict_items = {}

                if(element.terminal) {
                    dict_items['title'] = element.terminal + ' - ' + element.id_ship.name
                } else {
                    dict_items['title'] = element.id_ship.name
                }
                dict_items['start'] = element.arrival_datetime
                dict_items['end'] = element.departure_datetime
                dict_items['cruise_line'] = element.id_ship.id_cruise_line
                dict_items['id'] = element.id
                dict_items['ship_id'] = element.id_ship.id
                dict_items['foresight'] = element.foresight
                dict_items['terminal'] = element.terminal
                dict_items['color'] = '#c7c7c7'

                if(element.is_new === true) {
                    dict_items['title'] = dict_items['title'] + ' **'
                }
                final_events.push(dict_items)
            }

        })

        this.calendarOptions.events = []
        this.calendarOptions.events = final_events

        

        let date = {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear()
        }

        await this.$store.dispatch('load_filtered_calls', date)
        if(document.getElementById('list-group-item') != null) {
            var containerEl = document.getElementById('list-group-item');
            new Draggable(containerEl, {
                itemSelector: '.list-group-item',
            })
        }
        this.loading = false
              
    },
    methods: {
        all_port_calls_calendar_filtered_by_terminal(name) {
            return this.$store.getters.all_port_calls_calendar_filtered_by_terminal(name) ?? {}
        },
        get_scheduled_tasks_by_terminal(name) {
            return this.$store.getters.get_scheduled_tasks_by_terminal(name) ?? {}
        },
        get_scheduling_tasks_by_terminal(name) {
            return this.$store.getters.get_scheduling_tasks_by_terminal(name) ?? {}
        },
        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
        change_view_calendar(data) {
            this.selected_terminal = data
            this.calendarOptions.events = []
            var final_events = []
            this.get_scheduled_tasks_by_terminal(data).forEach((element) => {
                if(!element.id_ship){
                    var event = {
                        id: element.id,
                        title: element.title,
                        start: element.start_datetime,
                        end: element.end_datetime,
                        internal: element.internal,
                        color: 'lightgray'
                    }

                    if(event.internal) {
                        event.color = '#002D72'
                    }
                    final_events.push(event)
                } else {
                    var dict_items = {}

                    if(element.terminal) {
                        dict_items['title'] = element.terminal + ' - ' + element.id_ship.name
                    } else {
                        dict_items['title'] = element.id_ship.name
                    }
                    dict_items['start'] = element.arrival_datetime
                    dict_items['end'] = element.departure_datetime
                    dict_items['cruise_line'] = element.id_ship.id_cruise_line
                    dict_items['id'] = element.id
                    dict_items['ship_id'] = element.id_ship.id
                    dict_items['foresight'] = element.foresight
                    dict_items['terminal'] = element.terminal
                    dict_items['color'] = '#c7c7c7'

                    if(element.is_new === true) {
                        dict_items['title'] = dict_items['title'] + ' **'
                    }
                    final_events.push(dict_items)
                }

               

            })
            this.calendarOptions.events = []
            this.calendarOptions.events = final_events

            // This concat serves the purpose of merging the weekly calls and tasks for the calendar view
            // this.calendarOptions.events = this.calendarOptions.events.concat(this.all_port_calls_calendar_filtered_by_terminal(data))
        },
        async refill_calendar(month, year){
            let date = {
                month: month,
                year: year
            }

            await this.$store.dispatch('load_filtered_calls', date)

            this.calendarOptions.events = this.all_port_calls_calendar_filtered;

        },
        drop(e){

            this.event = {
                id: Number(e.draggedEl.firstChild.firstChild.firstChild.firstChild.getAttribute('data-id')),
                title: e.draggedEl.firstChild.firstChild.firstChild.firstChild.innerHTML,
                start: e.date,
                end: e.date,
                start_hour: moment(e.date).format('HH:mm'),
                end_hour: moment(e.date).add(moment.duration(1, 'hours')).format('HH:mm')
            }
            
            // this.calendarOptions.events.push(this.event)
            // this.$store.dispatch('update_scheduling_task_from_calendar', event)

            this.display_confirmation_date = true
        },
        set_draggable() {
            var containerEl = document.getElementById('list-group-item');
            new Draggable(containerEl, {
                itemSelector: '.list-group-item',
            })
        },
        close_modal_confirmation_add() {

            // this.event_revert.revert()

            this.display_confirmation_date = false
        },
        async close_modal_confirmation() {
            
            let edit_event = {
                id: this.event.id,
                title: this.event.title,
                start: moment(this.prev_start).utc(false),
                end: moment(this.prev_end).utc(false)
            }

            await this.$store.dispatch('update_scheduling_task_from_calendar', edit_event)

            this.calendarOptions.events = []

            this.get_scheduled_tasks.forEach((element) => {
                var event = {
                    id: element.id,
                    title: element.title,
                    start: element.start_datetime,
                    end: element.end_datetime,
                    internal: element.internal,
                    color: 'lightgray'
                }

                if(event.internal) {
                    event.color = '#002D72'
                }

                this.calendarOptions.events.push(event)
            
            })

            this.calendarOptions.events = this.calendarOptions.events.concat(this.all_port_calls_calendar_filtered_by_terminal('A'))
            this.display_confirmation_date = false
            
        },
        format_time(value){
            return moment(value).fromNow()
        },
        format_title(value){
            if(value.length > 10){
                return value.substring(0, 10) + '...'
            } else {
                return value
            }
        },
        async add_task_to_calendar() {

            if(typeof(this.event.start_hour) === 'string') {
                var start_datetime = moment(this.event.start).format('yy-MM-DD') + 'T' + this.event.start_hour
            } else {
                start_datetime = new Date(new Date(this.event.start).getFullYear(), new Date(this.event.start).getMonth(), new Date(this.event.start).getDate(),
                                    new Date(this.event.start_hour).getHours(), new Date(this.event.start_hour).getMinutes(), new Date(this.event.start_hour).getSeconds())
            }
            if(typeof(this.event.end_hour) === 'string') {
                var end_datetime =  moment(this.event.end).format('yy-MM-DD') + 'T' + this.event.end_hour
            } else {
                end_datetime = new Date(new Date(this.event.end).getFullYear(), new Date(this.event.end).getMonth(), new Date(this.event.end).getDate(),
                                    new Date(this.event.end_hour).getHours(), new Date(this.event.end_hour).getMinutes(), new Date(this.event.end_hour).getSeconds())    
            }
            
            let edit_event = {
                id: this.event.id,
                title: this.event.title,
                start: start_datetime,
                end: end_datetime
            }

            for(var item in this.calendarOptions.events) {
                if(this.calendarOptions.events[item].id == edit_event.id) {
                    this.calendarOptions.events.splice(item, 1)
                }
            }
            this.calendarOptions.events.push(edit_event)
            await this.$store.dispatch('update_scheduling_task_from_calendar', edit_event)
            this.display_confirmation_date = false
        },
        delete_task_from_calendar() {
            let index = this.calendarOptions.events.findIndex(event => event.id == this.event.id)
            this.calendarOptions.events.splice(index, 1)
            this.$store.dispatch('delete_task_from_calendar', Number(this.event.id))
            this.display_task_from_calendar = false
        }
    }
}
</script>

<style scoped>

:deep(a) {
    color: black !important;
}

.fc:deep(.fc-col-header-cell-cushion) {
    text-decoration: none !important;
    color: white !important;
}

.fc:deep(.fc-daygrid-day-number) {
    text-decoration: none !important;
}

.fc:deep(.fc-daygrid-event) {
    cursor: pointer;
}

.fc:deep(.fc-daygrid-day.fc-day-today) {
    background: #dce2f7;
}

.fc:deep(.fc-col-header-cell) {
    background: v-bind(color) !important;
    border: v-bind(color) !important;
}

:deep(.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl
        .fc-daygrid-event.fc-event-start) {
    margin-bottom: 2px !important;
}

:deep(.fc.fc-theme-standard .fc-toolbar .fc-button) {
    background: #002d72 !important;
    border: #002d72 !important;
}

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.circle-image{
  cursor:pointer;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 35px;
  height: 35px;
  padding: 0;
}

.circle-image img{
  width:100%;
  height:100%;
  object-fit: cover;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.active_terminal {
    background-color: v-bind(color) !important;
    color: white;
}


:deep(.p-scrollpanel-wrapper) {
    border-right: 9px solid #f8f9fa;
}

:deep(.p-scrollpanel-bar) {
    background-color: lightgray !important;
    opacity: 1;
    transition: background-color .2s;
}

.height_scroll_panel{
    height: 20vh;
}


@media (min-width: 1200px) {
    .height_scroll_panel{
        height: 60vh;
    }
}

.custom_col{
    width: 100% !important;
}

.custom_col_calendar{
    width: 100% !important;

}

@media (min-width: 1200px) {
    .custom_col{
        width: 20% !important;
    }
    .custom_col_calendar{
        width: 80% !important;
    }
}

a{
    text-decoration: none;
}

#task_calendar{
    padding-top: 10px;
}

</style>