<template>
    <ModalContracts/>
    <TableContracts/>
</template>

<script>
import TableContracts from '@/components/AdminDashboard/Contracts/TableContracts.vue/'
import ModalContracts from '@/components/AdminDashboard/Contracts/ModalContracts.vue/'
export default {
    name: 'Contracts',
    components: {
        TableContracts,
        ModalContracts
    }
}
</script>

<style scoped>

</style>