import axios from 'axios'
import moment from 'moment'

const state = {
    messages: [],
    operation_notifications: [],
    messages_between: []
}

const mutations = {
    SET_MESSAGES(state, messages) {
        state.messages = messages
    },
    OPERATION_NOTIFICATIONS(state, notifications){
        state.operation_notifications = notifications
    },
    ADD_MESSAGES(state, messages) {
        state.messages.push(messages)
    },
    EDIT_MESSAGES(state, messages){
        state.messages = state.messages.map(updated_messages => {
            if(updated_messages.id === messages.id) {
                updated_messages.text = messages.text
                updated_messages.text_datetime = messages.text_datetime
            }
            return updated_messages
        })
    },
    DELETE_MESSAGES(state, id) {
        let index = state.messages.findIndex(messages => messages.id == id)
        state.messages.splice(index, 1)
    },
    MARK_AS_READ(state, data) {
        for(var i in data){
            state.messages = state.messages.map(updated_messages => {
                if(updated_messages.id === data[i].id) {
                    updated_messages.is_read = true
                }
                return updated_messages
            })
        }
    },
    MARK_MANUALLY_AS_READ(state, messages) {
        state.messages = state.messages.map(updated_messages => {
            if(updated_messages.id === messages.id) {
                updated_messages.is_read = true
            }
            return updated_messages
        })
    },
    MARK_MANUALLY_AS_UNREAD(state, messages) {
        state.messages = state.messages.map(updated_messages => {
            if(updated_messages.id === messages.id) {
                updated_messages.is_read = false
            }
            return updated_messages
        })
    },
    SET_MESSAGES_BETWEEN_DATES(state, payload) {
        state.messages_between = payload.map(item => {
            item.hour = moment(item.text_datetime).format('HH:mm')
            item.day = new Date(item.text_datetime)

            return item
        })
    }
}

const actions = {
    async load_daily_messages({commit}, date) {
        await axios.get(process.env.VUE_APP_API_URL + "messages/get_by_date_last_days/" + date + "/")
            .then((response) => {
                commit('SET_MESSAGES', response.data)
            })
    },
    async load_operation_notifications({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "messages/get_count_unread_messages/")
            .then((response) => {
                commit('OPERATION_NOTIFICATIONS', response.data)
            })
    },
    async create_message({commit}, data) {
        await axios.post(process.env.VUE_APP_API_URL + "messages/", data)
            .then((response) => {
                commit('ADD_MESSAGES', response.data)
            })
    },
    edit_message({commit}, data) {
        axios.patch(process.env.VUE_APP_API_URL + "messages/" + data.id + "/", data)
            .then((response) => {
                commit('EDIT_MESSAGES', response.data)
            })
    },
    async delete_message({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "messages/" + id + "/")
            .then(() => {
                commit('DELETE_MESSAGES', id)
            })
    },
    async mark_as_read({commit, rootState}, data) {
        await axios.post(process.env.VUE_APP_API_URL + "messages/mark_as_read/" + data.date + "/" + rootState.users.current_user.id + "/")
            .then((response) => {
                commit('MARK_AS_READ', response.data)
            })
    },
    async mark_manually_as_read({commit}, id){
        await axios.patch(process.env.VUE_APP_API_URL + "messages/" + id + "/", {
            is_read: true,
        })
            .then((response) => {
                commit('MARK_MANUALLY_AS_READ', response.data)
            })
    },
    async mark_manually_as_unread({commit}, id){
        await axios.post(process.env.VUE_APP_API_URL + "messages/mark_as_unread/" + id + "/")
            .then((response) => {
                commit('MARK_MANUALLY_AS_UNREAD', response.data)
            })
    },
    async load_messages_between_dates({commit}, data) {
        await axios.get(process.env.VUE_APP_API_URL + 'messages/get_by_date_range/' + moment(data[0]).format('YYYY-MM-DD') + '/' + moment(data[1]).format('YYYY-MM-DD') + '/')
            .then((response) => {
                commit('SET_MESSAGES_BETWEEN_DATES', response.data)
            })
    }
}

const getters = {
    get_daily_messages: (state) => state.messages,
    get_operation_notifications: (state) => state.operation_notifications,
    get_messages_between: (state) => state.messages_between
}

export default {
    state,
    actions,
    mutations,
    getters
}