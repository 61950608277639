<template>
    <Toast/> 
    <div v-if="loading == true" class="p-3">
        <div class="grid justify-content-center">
            <div class="col-1">
                <Skeleton style="height: 3rem;"></Skeleton>
            </div>
        </div>
        <div class="grid">
            <div class="col">
                <Skeleton style="height: 10rem;"></Skeleton>
            </div>
            <div class="col">
                <Skeleton style="height: 10rem;"></Skeleton>
            </div>
            <div class="col">
                <Skeleton style="height: 10rem;"></Skeleton>
            </div>
            <div class="col">
                <Skeleton style="height: 10rem;"></Skeleton>
            </div>
            <div class="col">
                <Skeleton style="height: 10rem;"></Skeleton>
            </div>
        </div>
        <div class="grid justify-content-center">
            <div class="col-1">
                <Skeleton style="height: 3rem;"></Skeleton>
            </div>
            <div class="col-1">
                <Skeleton style="height: 3rem;"></Skeleton>
            </div>
        </div>
        <div class="grid">
            <div class="col">
                <Skeleton style="height: calc(100vh - 37rem);"></Skeleton>
            </div>
        </div>
    </div>
    <div v-else class="grid p-3">
        <div class="sticky_button">
            <Button icon="fa fa-angle-down" class="p-button-sm" @click="show_sticky_dates"/>
        </div>
        <div class="col-12 text-center mt-2">
            <Button label="Print Service Sheet" icon="fa fa-print" class="p-button-text text-center" @click="display_modal_print = true"/> 
        </div>
        <div class="col-12  sticky card" :style="{display: sticky_dates}">
            <StickyDates :service_sheet="service_sheet" :ship="get_ship" @click="hide_sticky_dates" style="cursor: pointer;"/>
        </div>
        <div v-if="get_service_sheet.signed" class="container-fluid row">
            <Message severity="info" :closable="false"> This service sheet has been signed. Any change done will change the status.</Message>
        </div>
        <div v-else-if="get_service_sheet.confirmed" class="container-fluid row">
            <Message severity="info" :closable="false"> This service sheet has been validated. Any change done will change the status.</Message>
        </div>
        
        <div class="col-12" >
            <Dates  :service_sheet="service_sheet" :user_port="user_port"/>
            <div class="row justify-content-center">
                <TabView @tab-change="render_services($event)">
                    <TabPanel header="Service Sheet">     
                        <div class="row">
                            <div class="col-12 xl:col-12 pt-4">
                                <Panel v-model:collapsed="general_collapsed" :toggleable="true" :collapsed="false">
                                    <template #header>
                                        <div @click="general_collapsed = !general_collapsed" style="width: 100%; height: 100%; padding: 0.7rem;">
                                            <div class="row">
                                                <div class="col">
                                                    <span class="title_panels"> General </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <General :service_sheet="service_sheet" :ship="get_ship" :ship_agents="get_ship_agents" :ship_agent="ship_agent" :ships="all_ships" :terminals="get_all_terminals" :ship_responsible="get_ship_responsible"/>
                                </Panel>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 xl:col-6 pt-4"> 
                                <Panel header="Estimation" v-model:collapsed="estimation_collapsed" :toggleable="true" :collapsed="true" data-testid="panel_estimation">
                                    <template #header>
                                        <div @click="estimation_collapsed = !estimation_collapsed" style="width: 100%; height: 100%; padding: 0.7rem;">
                                            <div class="row"  >
                                                <div class="col">
                                                    <span class="title_panels"> Estimation </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>                                     
                                    <Estimation  :service_sheet="service_sheet" :user_port="user_port" :get_placeholders="get_placeholders"/>
                                </Panel>
                            </div>
                            <div class="col-12 xl:col-6 pt-4">
                                <Panel header="Final" v-model:collapsed="final_collapsed" :toggleable="true" :collapsed="true">
                                    <template #header>
                                        <div @click="final_collapsed = !final_collapsed" style="width: 100%; height: 100%; padding: 0.7rem;">
                                            <div class="row"  >
                                                <div class="col">
                                                    <span class="title_panels"> Final </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <Final  :service_sheet="service_sheet" :user_port="user_port" :get_placeholders="get_placeholders"/>
                                </Panel>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div v-if="user_port=='Port de Barcelona'" class="col-12 xl:col-3 pt-4" >
                                <Panel header="Mooring" v-model:collapsed="mooring_collapsed" :toggleable="true" :collapsed="true">
                                    <template #header>
                                        <div @click="mooring_collapsed = !mooring_collapsed" style="width: 100%; height: 100%; padding: 0.7rem;">
                                            <div class="row"  >
                                                <div class="col">
                                                    <span class="title_panels"> Mooring </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="container-fluid p-fluid">
                                        <div class="row">
                                            <div class="col-12 xl:col-12">
                                                <Divider align="center">
                                                    <div class="inline-flex align-items-center">
                                                        <b> Position </b>
                                                    </div>
                                                </Divider>
                                                <div v-for="position of positions" :key="position" class="field-checkbox mt-4">
                                                    <RadioButton name="position" :value="position" v-model="service_sheet.position" @change="button_confirm_mooring = true" :disabled="!this.service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/>
                                                    <label :for="position.key" class="ms-2"> {{position}} </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 xl:col-12">
                                                <Divider align="center">
                                                    <div class="inline-flex align-items-center">
                                                        <b> Modules </b>
                                                    </div>
                                                </Divider>
                                                <div class="col">
                                                    <div class="col-sm-2 col-12">
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <InputNumber id="modules" v-model="service_sheet.module_start" @input="button_confirm_mooring = true"  mode="decimal" :min="0" :disabled="!this.service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/>
                                                                <label for="finger_name"> Start </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 col-12">
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <InputNumber id="modules" v-model="service_sheet.module_end" @input="button_confirm_mooring = true" mode="decimal" :max="1000" :disabled="!this.service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/>
                                                                <label for="finger_name"> End </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div> 
                                        </div>
                                        <div v-if="button_confirm_mooring == true" class="row justify-content-center">
                                            <div class="col-12 xl:col-6">
                                                <Button @click="save_changes_mooring" icon="fa-solid fa-check" label="Save" class="p-button-raised p-button-text p-button-success" />
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                            
                            <div v-if="user_port=='Port de Barcelona' && $ability.can('view', 'finger') && $ability.can('view', 'finger_service') && $ability.can('view', 'ship_finger') && !get_call.is_unconventional" class="col-12 xl:col-9 pt-4">
                                <Panel header="Fingers" v-model:collapsed="fingers_collapsed" :toggleable="true" :collapsed="true">
                                    <template #header>
                                        <div @click="fingers_collapsed = !fingers_collapsed" class="col" style="padding: 0.7rem;">
                                            <div class="row"  >
                                                <div class="col">
                                                    <span class="title_panels"> Fingers </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #icons>
                                        <i v-if="fingers_incidences.length > 0" class="p-panel-header-icon mr-2">
                                            <i v-tooltip.left="'Some fingers have had some kind of incidence.'" class="pi pi-exclamation-triangle fa-xl"  style="color: orange; font-size: 1.5rem" @click="open_modal_show_incidence"></i>
                                        </i>
                                    </template>
                                    <div class="container-fluid p-fluid">
                                        <div class="row">
                                            <div class="col-12 xl:col-12">
                                                <Divider align="center">
                                                    <div class="inline-flex align-items-center">
                                                        <b> Fingers </b>
                                                    </div>
                                                </Divider>
                                                <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                                                    <Datatable :paginator="true" :rows="4" :value="get_finger_service" editMode="row" dataKey="id" v-model:editingRows="editingRows" @row-edit-save="onRowEditSave"
                                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink" scrollable responsiveLayout="scroll" tableStyle="min-width: 70rem">
                                                        <Column field="finger" header="Finger"  style="width:30rem">
                                                            <template #editor="{ data, field }">
                                                                <Dropdown v-model="data[field].id" autofocus :options="all_fingers" optionLabel="name" optionValue="id"/>
                                                            </template>
                                                            <template #body="slotProps">
                                                                {{slotProps.data.finger.name}}
                                                            </template>
                                                        </Column> 
                                                        <Column field="position" header="Position" style="width:20rem">
                                                            <template #editor="{ data, field }">
                                                                <Dropdown v-model="data[field]" autofocus :options="position_values"/>
                                                            </template>
                                                            <template #body="slotProps">
                                                                {{slotProps.data.position}}
                                                            </template>
                                                        </Column>
                                                        <Column field="start_datetime" header="Start" style="width:30rem">
                                                            <template #editor="{ data, field }">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" v-model="data[field]" dateFormat="yy-mm-dd" :minDate="get_min_date" :maxDate="get_max_date"/>
                                                            </template>
                                                        </Column>
                                                        <Column field="start_datetime_hour" header="Hour" style="width:30rem">
                                                            <template #editor="{ data, field }">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" v-model="data[field]" :timeOnly="true" @change="data[field] = num2timeedit($event)"/>
                                                            </template>
                                                        </Column>
                                                        <Column field="end_datetime" header="End" style="width:30rem">
                                                            <template #editor="{ data, field }">
                                                                <Calendar autocomplete="off" v-model="data[field]" dateFormat="yy-mm-dd" :minDate="get_min_date" :maxDate="get_max_date"/>
                                                            </template>
                                                        </Column>
                                                        <Column field="end_datetime_hour" header="Hour" style="width:30rem">
                                                            <template #editor="{ data, field }">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" showButtonBar v-model="data[field]" :timeOnly="true" @change="data[field] = num2timeedit($event)"/>
                                                            </template> 
                                                        </Column>
                                                        <Column v-if="$ability.can('change','finger_service') && !is_disabled" header="Edit" :rowEditor="true" bodyStyle="text-align:center"></Column>
                                                        <Column v-if="$ability.can('delete','finger_service') && !is_disabled" header="Delete" bodyStyle="text-align:center">
                                                            <template #body="slotProps">
                                                                <Button class="p-button-rounded p-button-text p-button-plain p-button-sm" icon="fa fa-trash" @click="delete_finger_service(slotProps)"/>
                                                            </template>
                                                        </Column>
                                                        <template #empty>
                                                            <div class="row">
                                                                <div class="col text-center">
                                                                    No fingers added yet.
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </Datatable>
                                                </div>
                                                
                                                <div v-if="edit_finger_errors_calls" class="text-center">
                                                    <small v-if="Object.keys(edit_finger_errors_calls).length !== 0 && edit_finger_errors_calls.finger" class="p-error"><span v-for="error in edit_finger_errors_calls.finger" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(edit_finger_errors_calls).length !== 0 && edit_finger_errors_calls.start_datetime" class="p-error"><span v-for="error in edit_finger_errors_calls.start_datetime" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(edit_finger_errors_calls).length !== 0 && edit_finger_errors_calls.end_datetime" class="p-error"><span v-for="error in edit_finger_errors_calls.end_datetime" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(edit_finger_errors_calls).length !== 0 && edit_finger_errors_calls.finger_service" class="p-error"><span v-for="error in edit_finger_errors_calls.finger_service" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(edit_finger_errors_calls).length !== 0 && edit_finger_errors_calls.arrival_datetime" class="p-error"><span v-for="error in edit_finger_errors_calls.arrival_datetime" :key="error">{{error}}<br></span></small>
                                                </div>
                                                <div v-if="$ability.can('add','finger_service') && !is_disabled" class="row mt-5">
                                                    <div class="col-12 lg:col-2">
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <Dropdown id="finger_name" v-model="new_finger_service.id_finger" :options="all_fingers" optionLabel="name" optionValue="id" />
                                                                <label for="finger_name"> Finger </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 lg:col-2">
                                                            <div class="col-12">
                                                                <span class="p-float-label">
                                                                    <Dropdown id="finger_name" v-model="new_finger_service.position" :options="position_values"/>
                                                                    <label for="finger_name"> Position </label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    <div class="col-12 lg:col"> 
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" v-model="new_finger_service.start_datetime" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                                                <label for="start_datetime_finger"> Start </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 lg:col"> 
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" v-model="new_finger_service.start_datetime_hour" :timeOnly="true" @change="new_finger_service.start_datetime_hour = num2time($event)"/>
                                                                <label for="start_datetime_finger"> Hour </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 lg:col"> 
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" v-model="new_finger_service.end_datetime" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                                                <label for="end_datetime_finger"> End </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 lg:col"> 
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" v-model="new_finger_service.end_datetime_hour" :timeOnly="true" @change="new_finger_service.end_datetime_hour = num2time($event)"/>
                                                                <label for="start_datetime_finger"> Hour </label>
                                                            </span>
                                                        </div>
                                                    </div>    
                                                    <div class="col-12 lg:col-1 text-center mt-2">  
                                                        <Button icon="fa fa-plus" class="p-button-raised p-button-text p-button-rounded" @click="add_finger_service" style="height: 2.5rem; width: 2.5rem;"/>
                                                    </div>
                                                </div>
                                                <div class="row text-center">
                                                    <small v-if="Object.keys(finger_errors_calls).length !== 0 && finger_errors_calls.finger" class="p-error"><span v-for="error in finger_errors_calls.finger" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(finger_errors_calls).length !== 0 && finger_errors_calls.start_datetime" class="p-error"><span v-for="error in finger_errors_calls.start_datetime" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(finger_errors_calls).length !== 0 && finger_errors_calls.end_datetime" class="p-error"><span v-for="error in finger_errors_calls.end_datetime" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(finger_errors_calls).length !== 0 && finger_errors_calls.finger_service" class="p-error"><span v-for="error in finger_errors_calls.finger_service" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(finger_errors_calls).length !== 0 && finger_errors_calls.arrival_datetime" class="p-error"><span v-for="error in finger_errors_calls.arrival_datetime" :key="error">{{error}}<br></span></small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <span class="p-float-label">
                                                    <TextArea id="finger_description" rows="5" v-model="get_service_sheet.finger_description" @input="save_finger_button = true" :disabled="is_disabled"></TextArea>
                                                    <label for="finger_description"> Observations </label>
                                                </span>
                                                <span v-if="Object.keys(get_errors_service_sheet_fingers_error).length > 0">
                                                    <small class="p-error"> {{ get_errors_service_sheet_fingers_error.finger_description[0] }} </small>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="save_finger_button" class="row justify-content-end mt-2">
                                            <Button class="p-button-raised p-button-rounded p-button-success p-button-sm" icon="pi pi-check" @click="save_finger_description()"> </Button>
                                        </div>
                                        <div class="row justify-content-between mt-2">
                                            <div v-if="$ability.can('add','incidence_report')" class="col-12 md:col-3">
                                                <Button label="New Incidence" icon="fa fa-plus" class="p-button-raised p-button-text p-button-warning" @click="open_modal_incidence"/>
                                            </div>
                                            <div v-if="get_ship_finger_images.length > 0" class="col-12 md:col-3">
                                                <Button label="Finger Positions" icon="fa fa-images" class="p-button-raised p-button-text p-button" @click="display_basic = true"/>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        
                            <div v-if="user_port=='Puerto de Málaga' && service_sheet.main && $ability.can('view', 'finger') && $ability.can('view', 'finger_service') && $ability.can('view', 'ship_finger')" class="col pt-4">
                                <Panel header="Fingers" v-model:collapsed="fingers_collapsed" :toggleable="true" :collapsed="true">
                                    <template #header>
                                        <div @click="fingers_collapsed = !fingers_collapsed" class="col" style="padding: 0.7rem;">
                                            <div class="row">
                                                <div class="col">
                                                    <span class="title_panels"> Fingers </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #icons>
                                        <i v-if="fingers_incidences.length > 0" class="p-panel-header-icon mr-2">
                                            <i v-tooltip.left="'Some fingers have had some kind of incidence.'" class="pi pi-exclamation-triangle fa-xl"  style="color: orange; font-size: 1.5rem" @click="open_modal_show_incidence"></i>
                                        </i>
                                    </template>
                                    <div class="container-fluid p-fluid">
                                        <div class="row">
                                            <div class="col-12 xl:col-12">
                                                <Divider align="center">
                                                    <div class="inline-flex align-items-center">
                                                        <b> Fingers </b>
                                                    </div>
                                                </Divider>
                                                <Datatable  :paginator="true" :rows="4" :value="get_finger_service" editMode="row" dataKey="id" v-model:editingRows="editingRows" @row-edit-save="onRowEditSave"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink">
                                                    <Column field="finger" header="Finger" style="width:20%">
                                                        <template #editor="{ data, field }">
                                                            <Dropdown v-model="data[field].id" autofocus :options="all_fingers" optionLabel="name" optionValue="id"/>
                                                        </template>
                                                        <template #body="slotProps">
                                                            {{slotProps.data.finger.name}}
                                                        </template>
                                                    </Column>
                                                    <Column field="start_datetime" header="Start" style="width:15%">
                                                        <template #editor="{ data, field }">
                                                            <Calendar autocomplete="off" id_="start_datetime_finger" v-model="data[field]" dateFormat="yy-mm-dd" :minDate="get_min_date" :maxDate="get_max_date"/>
                                                        </template>
                                                    </Column>
                                                    <Column field="start_datetime_hour" header="Hour">
                                                        <template #editor="{ data, field }">
                                                            <Calendar autocomplete="off" id_="start_datetime_finger" v-model="data[field]" :timeOnly="true" @change="data[field] = num2timeedit($event)"/>
                                                        </template>
                                                    </Column>
                                                    <Column field="end_datetime" header="End" style="width:15%">
                                                        <template #editor="{ data, field }">
                                                            <Calendar autocomplete="off" v-model="data[field]" dateFormat="yy-mm-dd" :minDate="get_min_date" :maxDate="get_max_date"/>
                                                        </template>
                                                    </Column>
                                                    <Column field="end_datetime_hour" header="Hour">
                                                        <template #editor="{ data, field }">
                                                            <Calendar autocomplete="off" id_="start_datetime_finger" v-model="data[field]" :timeOnly="true" @change="data[field] = num2timeedit($event)"/>
                                                        </template> 
                                                    </Column>
                                                    <Column v-if="$ability.can('change','finger_service')" header="Edit" :rowEditor="true" bodyStyle="text-align:center"></Column>
                                                    <Column v-if="$ability.can('delete','finger_service')" header="Delete" bodyStyle="text-align:center">
                                                        <template #body="slotProps">
                                                            <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_finger_service(slotProps)"/>
                                                        </template>
                                                    </Column>
                                                    <template #empty>
                                                        <div class="row">
                                                            <div class="col text-center">
                                                                No fingers added yet.
                                                            </div>
                                                        </div>
                                                    </template>
                                                </Datatable>
                                                <!--
                                                <div v-if="finger_service.length > 0" class="row justify-content-between">
                                                    <div class="col-12 md:col-3">
                                                        <Button @click="delete_finger_service" icon="pi pi-trash" label="Delete" class="p-button-raised p-button-text p-button-danger"/> 
                                                    </div>
                                                    <div class="col-12 md:col-3">
                                                        <Button @click="save_changes_fingers" icon="fa-solid fa-check" label="Save" class="p-button-raised p-button-text p-button-success" /> 
                                                    </div>
                                                </div>
                                                -->
                                                <div v-if="edit_finger_errors_calls" class="text-center">
                                                    <small v-if="Object.keys(edit_finger_errors_calls).length !== 0 && edit_finger_errors_calls.finger" class="p-error"><span v-for="error in edit_finger_errors_calls.finger" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(edit_finger_errors_calls).length !== 0 && edit_finger_errors_calls.start_datetime" class="p-error"><span v-for="error in edit_finger_errors_calls.start_datetime" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(edit_finger_errors_calls).length !== 0 && edit_finger_errors_calls.end_datetime" class="p-error"><span v-for="error in edit_finger_errors_calls.end_datetime" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(edit_finger_errors_calls).length !== 0 && edit_finger_errors_calls.finger_service" class="p-error"><span v-for="error in edit_finger_errors_calls.finger_service" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(edit_finger_errors_calls).length !== 0 && edit_finger_errors_calls.arrival_datetime" class="p-error"><span v-for="error in edit_finger_errors_calls.arrival_datetime" :key="error">{{error}}<br></span></small>
                                                </div>
                                                <div v-if="$ability.can('add','finger_service')" class="row mt-5">
                                                    <div class="col-12 lg:col-2">
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <Dropdown id="finger_name" v-model="new_finger_service.id_finger" :options="all_fingers" optionLabel="name" optionValue="id" />
                                                                <label for="finger_name"> Finger </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 lg:col"> 
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" v-model="new_finger_service.start_datetime" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                                                <label for="start_datetime_finger"> Start </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 lg:col"> 
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" v-model="new_finger_service.start_datetime_hour" :timeOnly="true" @change="new_finger_service.start_datetime_hour = num2time($event)"/>
                                                                <label for="start_datetime_finger"> Hour </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 lg:col"> 
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" v-model="new_finger_service.end_datetime" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                                                <label for="end_datetime_finger"> End </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 lg:col"> 
                                                        <div class="col-12">
                                                            <span class="p-float-label">
                                                                <Calendar autocomplete="off" id_="start_datetime_finger" v-model="new_finger_service.end_datetime_hour" :timeOnly="true" @change="new_finger_service.end_datetime_hour = num2time($event)"/>
                                                                <label for="start_datetime_finger"> Hour </label>
                                                            </span>
                                                        </div>
                                                    </div>    
                                                    <div class="col-12 lg:col-1 text-center mt-2">  
                                                        <Button icon="fa fa-plus" class="p-button-raised p-button-text p-button-rounded" @click="add_finger_service" style="height: 2.5rem; width: 2.5rem;"/>
                                                    </div>
                                                </div>
                                                <div class="row text-center">
                                                    <small v-if="Object.keys(finger_errors_calls).length !== 0 && finger_errors_calls.finger" class="p-error"><span v-for="error in finger_errors_calls.finger" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(finger_errors_calls).length !== 0 && finger_errors_calls.start_datetime" class="p-error"><span v-for="error in finger_errors_calls.start_datetime" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(finger_errors_calls).length !== 0 && finger_errors_calls.end_datetime" class="p-error"><span v-for="error in finger_errors_calls.end_datetime" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(finger_errors_calls).length !== 0 && finger_errors_calls.finger_service" class="p-error"><span v-for="error in finger_errors_calls.finger_service" :key="error">{{error}}<br></span></small>
                                                    <small v-if="Object.keys(finger_errors_calls).length !== 0 && finger_errors_calls.arrival_datetime" class="p-error"><span v-for="error in finger_errors_calls.arrival_datetime" :key="error">{{error}}<br></span></small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between mt-4">
                                            <div v-if="$ability.can('add','incidence_report')" class="col-12 md:col-3">
                                                <Button label="New Incidence" icon="fa fa-plus" class="p-button-raised p-button-text p-button-warning" @click="open_modal_incidence"/>
                                            </div>
                                            <div v-if="get_ship_finger_images.length > 0 && $ability.can('view','ship_finger')" class="col-12 md:col-3">
                                                <Button label="Finger Positions" icon="fa fa-images" class="p-button-raised p-button-text p-button" @click="display_basic = true"/>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        </div>
                        <!--
                        <div class="row">
                            <div class="col pt-4">
                                <Panel header="Staff" :collapsed="true" :toggleable="true">
                                    <Datatable>
                                        <Column header="Operador"></Column>
                                        <Column v-for="day of column_days" :key="day" :field="day" :header="day"></Column>
                                    </Datatable>
                                </Panel>
                            </div>
                        </div>
                        -->
                    </TabPanel>
                    <TabPanel v-if="check_user_service_operator && (!get_call.is_unconventional || user_port=='Port de Barcelona')" header="Services">
                        <span v-if="loading_services == false">
                            <div class="row text-center">
                                <div class="col">
                                    <Skeleton style="height: calc(100vh - 40rem)"></Skeleton>
                                </div>
                            </div>
                        </span>
                        <div v-else class="row">
                            <div v-if="user_port=='Port de Barcelona' && $ability.can('view','cleaning') && $ability.can('view','cleaning_service') && $ability.can('view','supplier_service') && $ability.can('view','supplier_responsible') && !get_call.is_unconventional" class="col-12 xl:col-12 mt-4"> 
                                <CleaningService  :service_sheet="service_sheet" :days="days" :all_suppliers="all_suppliers"/>
                            </div>
                            <div v-if="$ability.can('view','porter') && $ability.can('view','porter_service') && $ability.can('view','supplier_service') && $ability.can('view','supplier_responsible') && !get_call.is_unconventional" class="col-12 xl:col-12 mt-4">
                                <PortersService  :service_sheet="service_sheet" :all_suppliers="all_suppliers"/>
                            </div>
                            <div v-if="$ability.can('view','terminal_security') && $ability.can('view','terminal_security_service_type_option') && $ability.can('view','terminal_security_service') && $ability.can('view','supplier_service') && $ability.can('view','supplier_responsible') && !get_call.is_unconventional" class="col-12 xl:col-12 mt-4">
                                <TerminalService  :service_sheet="service_sheet" :all_suppliers="all_suppliers"/>
                            </div>
                            <div v-if="user_port=='Port de Barcelona' && service_sheet.main  && $ability.can('view','k9') && $ability.can('view','k9_service') && $ability.can('view','supplier_service') && $ability.can('view','supplier_responsible') && !get_call.is_unconventional" class="col-12 xl:col-12 mt-4">
                                <K9Service  :service_sheet="service_sheet" :all_suppliers="all_suppliers"/>
                            </div>
                            <div v-if="user_port=='Port de Barcelona' && $ability.can('view','fence_security') && $ability.can('view','fence_security_service') && $ability.can('view','supplier_service') && $ability.can('view','supplier_responsible') && !get_call.is_unconventional" class="col-12 mt-4">
                                <FenceService  :service_sheet="service_sheet" :all_suppliers="all_suppliers"/>
                            </div>
                            <div v-if="user_port=='Port de Barcelona' && service_sheet.main && $ability.can('view','custodian') && $ability.can('view','supplier_service') && $ability.can('view','supplier_responsible') && !get_call.is_unconventional" class="col-12 xl:col-6 mt-4">
                                <CustodianService :all_suppliers="all_suppliers"  :service_sheet="service_sheet"/>
                            </div>
                            <div v-if="user_port=='Port de Barcelona' && $ability.can('view','wifi') && !get_call.is_unconventional" class="col-12 xl:col mt-4">
                                <Panel header="Wifi" v-model:collapsed="wifi_collapsed" :collapsed="true" :toggleable="true" >
                                    <template #header>
                                        <div @click="wifi_collapsed = !wifi_collapsed" class="col" style="padding: 0.7rem;">
                                            <div class="row"  >
                                                <div class="col">
                                                    <span class="title_panels"> Wifi </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #icons>
                                        <i v-if="wifi_required.value" class="p-panel-header-icon mr-2">
                                            <span v-if="wifi_required.value == true">
                                                <span v-tooltip.top="'Service required.'" class="fa-solid fa-circle fa-xl" style="color: #22C55E;"></span>
                                            </span>
                                        </i>
                                    </template>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col text-center">
                                                <div class="field">
                                                    <SelectButton id="wifi" optionLabel="name" v-model="wifi_required" :options="options" unselectable @change="change_wifi_service()" :disabled="!$ability.can('add','wifi') || !$ability.can('delete','wifi')"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                            <div v-if="user_port=='Port de Barcelona' && $ability.can('view','water') && $ability.can('view','water_service')" class="col-12 xl:col-12 mt-4">
                                <Panel header="Water" v-model:collapsed="water_collapsed" :collapsed="true" :toggleable="true">
                                    <template #header>
                                        <div @click="water_collapsed = !water_collapsed" class="col" style="padding: 0.7rem;">
                                            <div class="row"  >
                                                <div class="col">
                                                    <span class="title_panels"> Water </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #icons>
                                        <i class="p-panel-header-icon mr-2">
                                            <span v-if="water_required == true">
                                                <i v-tooltip.left="'Service required.'" class="fa-solid fa-circle fa-xl" style="color: #22C55E;"></i>
                                            </span>
                                        </i>
                                    </template>
                                    <div class="container-fluid p-fluid">
                                        <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                                            <Datatable :paginator="true" :rows="4" :value="get_water_readings" tableStyle="min-width: 70rem" editMode="row" dataKey="id" v-model:editingRows="editing_rows_water" @row-edit-save="onRowEditSaveWater"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink">
                                                <Column header="Delivery Note" field="delivery_note" style="width:30rem">
                                                    <template #editor>
                                                        <FileUpload ref="fileUploadWater" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUploadWater"/>
                                                    </template>
                                                    <template #body="slotProps">
                                                        <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-eye" @click="open_modal_image_water(slotProps.data.delivery_note)"/>
                                                    </template>
                                                </Column>
                                                <Column header="Date" field="date" style="width:30rem">
                                                    <template #editor="{ data, field }">
                                                        <Calendar autocomplete="off" v-model="data[field]" dateFormat="yy-mm-dd" :minDate="get_min_date" :maxDate="get_max_date"/>
                                                    </template>
                                                    <template #body="slotProps">
                                                        {{slotProps.data.date}}
                                                    </template>
                                                </Column>
                                                <Column header="Water Meter" field="id_water_meter" style="width:30rem">
                                                    <template #editor="{ data, field }">
                                                        <Dropdown v-model="data[field].id" :options="all_water_meters" optionLabel="name" optionValue="id"/>
                                                    </template>
                                                    <template #body="slotProps">
                                                        {{slotProps.data.id_water_meter.name}}
                                                    </template>
                                                </Column>
                                                <Column header="Initial Reading" field="initial_reading" style="width:30rem">
                                                    <template #editor="{ data, field }">
                                                        <InputNumber autocomplete="off" v-model="data[field]"/>
                                                    </template>
                                                    <template #body="slotProps">
                                                        {{slotProps.data.initial_reading}} m³
                                                    </template>
                                                </Column>
                                                <Column header="Final Reading" field="final_reading" style="width:30rem">
                                                    <template #editor="{ data, field }">
                                                        <InputNumber autocomplete="off" v-model="data[field]"/>
                                                    </template>
                                                    <template #body="slotProps">
                                                        {{slotProps.data.final_reading}} m³
                                                    </template>
                                                </Column>
                                                <Column header="Total" field="difference" style="width:30rem">
                                                    <!--
                                                    <template #editor="{ data, field }">
                                                        <InputNumber autocomplete="off" v-model="data[field]"/>
                                                    </template>
                                                    -->
                                                    <template #body="slotProps">
                                                        {{slotProps.data.difference}} m³
                                                    </template>
                                                </Column>
                                                <Column header="Edit" v-if="$ability.can('change','water_service')" :rowEditor="true" bodyStyle="min-width: 7rem;"></Column>
                                                <Column header="Delete" v-if="$ability.can('delete','water_service') && $ability.can('delete','water')" bodyStyle="text-align:center">
                                                    <template #body="slotProps">
                                                        <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_water_service(slotProps)"/>
                                                    </template>
                                                </Column>
                                                <template #empty>
                                                    <div class="row">
                                                        <div class="col text-center">
                                                            No records.
                                                        </div>
                                                    </div>
                                                </template>
                                            </Datatable>
                                        </div>
                                        <div v-if="$ability.can('add','water_service') && $ability.can('add','water')" class="row">
                                            <div class="col text-center">
                                                <b> Add Delivery Note </b>
                                            </div>
                                        </div>
                                        <div v-if="$ability.can('add','water_service') && $ability.can('add','water')" class="row">
                                            <div class="col-12 md:col-12 text-center">
                                                <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUpload"/>
                                            </div>
                                        </div>
                                        <div v-if="url" class="row">
                                            <div class="col-12 md:col-7 text-center">
                                                <div v-if="url_type == 'pdf'" class="ratio ratio-16x9">
                                                    <iframe
                                                        :src="url"
                                                        frameBorder="0"
                                                        scrolling="auto"
                                                    ></iframe>
                                                </div>
                                                <Image v-if="url_type == 'image'" :src="url" imageStyle="max-width: 40vw;"/>
                                            </div>
                                            <div class="col-12 md:col-5">
                                                <div class="row justify-content-center">
                                                    <div class="col-12 md:col-6">
                                                        <div class="p-float-label">
                                                            <Calendar autocomplete="off" id="calendar_delivery_note" v-model="new_water_reading.date" dateFormat="yy-mm-dd"/>   
                                                            <label for="calendar_delivery_note"> Date </label> 
                                                        </div>
                                                        <small v-if="Object.keys(get_error_create_water_service).length !== 0 && get_error_create_water_service.date" class="p-error"><span v-for="error in get_error_create_water_service.date" :key="error">{{error}}<br></span></small>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center mt-3">
                                                    <div class="col-12 md:col-6">
                                                        <div class="p-float-label">
                                                            <Dropdown autocomplete="off" id="water_meter_dropdown" :options="all_water_meters" v-model="new_water_reading.id_water_meter" optionValue="id" optionLabel="name" @change="load_last_reading($event)"/>   
                                                            <label for="calendar_delivery_note"> Water Meter </label> 
                                                        </div>
                                                        <small v-if="Object.keys(get_error_create_water_service).length !== 0 && get_error_create_water_service.id_water_meter" class="p-error"><span v-for="error in get_error_create_water_service.id_water_meter" :key="error">{{error}}<br></span></small>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center mt-3">
                                                    <div class="col-12 md:col-6">
                                                        <div class="p-float-label">
                                                            <InputNumber id="initial_reading_delivery_note" v-model="new_water_reading.initial_reading"/>   
                                                            <label for="initial_reading_delivery_note"> Initial Reading </label> 
                                                        </div>
                                                        <p class="mb-0"><small class="text-500"> The last reading of this water meter was <strong>{{get_last_reading['last_reading'] ? get_last_reading['last_reading'] : '-'}}</strong></small></p>
                                                        <small v-if="Object.keys(get_error_create_water_service).length !== 0 && get_error_create_water_service.initial_reading" class="p-error"><span v-for="error in get_error_create_water_service.initial_reading" :key="error">{{error}}<br></span></small>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center mt-2">
                                                    <div class="col-12 md:col-6">
                                                        <div class="p-float-label">
                                                            <InputNumber id="final_reading_delivery_note" v-model="new_water_reading.final_reading"/>   
                                                            <label for="final_reading_delivery_note"> Final Reading </label> 
                                                        </div>
                                                        <small v-if="Object.keys(get_error_create_water_service).length !== 0 && get_error_create_water_service.final_reading" class="p-error"><span v-for="error in get_error_create_water_service.final_reading" :key="error">{{error}}<br></span></small>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center mt-3">
                                                    <div class="col-12 md:col-4">
                                                        <Button label="Add" icon="fa fa-plus" class="p-button-text p-button-raised p-button-success" @click="add_water_reading"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--
                                            <div class="col-12 md:col-6">
                                                <div class="row justify-content-center">
                                                    <div v-if="url">
                                                        <div v-if="url_type == 'pdf'" class="ratio ratio-16x9">
                                                            <iframe
                                                                :src="url"
                                                                frameBorder="0"
                                                                scrolling="auto"
                                                            ></iframe>
                                                        </div>
                                                        <img v-if="url_type == 'image'" :src="url" />
                                                    </div>
                                                    <div v-if="water_service.delivery_note.split('?')[0].split('.').pop() == 'pdf'" class="col-12 md:col-10">
                                                        <div class="ratio ratio-16x9" v-if="water_service.delivery_note">
                                                            <iframe
                                                                :src="water_service.delivery_note"
                                                                frameBorder="0"
                                                                scrolling="auto"
                                                            ></iframe>
                                                        </div>
                                                    </div>
                                                    <div v-else class="col-12 md:col-10">
                                                        <Image :src="water_service.delivery_note" imageClass="img-fluid" preview/>
                                                    </div>
                                                </div>
                                            </div>
                                        -->
                                        </div>
                                        <!--
                                        <div class="row pt-2 justify-content-between">
                                            <div class="col-12 md:col-3 lg:col-2">
                                                <Button icon="fa fa-trash" label="Delete" class="p-button-raised p-button-text p-button-danger" @click="delete_water_service()"/>
                                            </div>
                                            <div class="col-12 md:col-3 lg:col-2">
                                                <Button icon="fa fa-check" label="Save" class="p-button-raised p-button-text p-button-success" @click="save_water_service()"/>
                                            </div>
                                        </div>
                                        -->
                                    </div>
                                </Panel>
                            </div>
                            <!--
                            <div v-if="user_port=='Puerto de Málaga'" class="col-12 xl:col-6 mt-4">
                                <Panel header="Shuttle Service" v-model:collapsed="shuttle_collapsed" :collapsed="true" :toggleable="true">
                                    <template #header>
                                        <div @click="shuttle_collapsed = !shuttle_collapsed" style="width: 88%; height: 100%; padding: 0.7rem;">
                                            <div class="row"  >
                                                <div class="col">
                                                    <span class="title_panels"> Shuttle Service </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #icons>
                                        <i class="p-panel-header-icon mr-2">
                                            <span v-if="shuttle_service.status === 'None'">
                                                <span v-tooltip.left="'Service email not sent.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: #002d7d;"></span>
                                            </span>
                                            <span v-if="shuttle_service.status === 'Modified'">
                                                <span v-tooltip.left="'Service modified after email.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: orange;"></span>
                                            </span>
                                            <span v-if="shuttle_service.status === 'On hold'">
                                                <span v-tooltip.left="'Service email sent and waiting for confirmation.'" class="fa-solid fa-circle fa-xl" style="color: orange;"></span>
                                            </span>
                                            <span v-if="shuttle_service.status === 'Confirmed'">
                                                <span v-tooltip.left="'Service email confirmed.'" class="fa-solid fa-circle fa-xl" style="color: #22C55E;"></span>
                                            </span>
                                            <span v-if="shuttle_service.status === 'Refused'">
                                                <span v-tooltip.left="'Service email refused.'" class="fa-solid fa-circle fa-xl" style="color: #EF4444;"></span>
                                            </span>
                                        </i>
                                    </template>
                                    <div class="container p-fluid">
                                        <Divider align="center">
                                            <div class="inline-flex align-items-center">
                                                <b> Responsible </b>
                                            </div>
                                        </Divider>
                                        <div class="row pt-3 pb-3">
                                            <div class="col">
                                                <span class="p-float-label">
                                                    <Dropdown :options="get_users_by_port" :filter="true" optionLabel="username" v-model="shuttle_service.manager" placeholder="Select a user" @change="save_changes_shuttle_service()">
                                                        <template #value="slotProps">
                                                            <div v-if="slotProps.value">
                                                                <span>{{slotProps.value.username}}</span>
                                                            </div>
                                                            <div v-else>
                                                                <span> {{slotProps.placeholder}} </span>
                                                            </div>
                                                        </template>
                                                    </Dropdown>
                                                </span>
                                            </div>
                                        </div>
                                        <Divider align="center" class="mt-2 mb-3">
                                            <div class="inline-flex align-items-center">
                                                <b> Shifts </b>
                                            </div>
                                        </Divider>
                                        <div v-for="(shuttle, index) in shuttle_service_shifts" :key="shuttle">
                                            <div class="row pb-2">
                                                <div class="col-12 md:col-1 mt-2 text-center">
                                                    <Checkbox v-model="selected_shuttle_shifts" :value="index"/>
                                                </div>
                                                <div class="col-12 md:col-3">
                                                    <span class="p-float-label">
                                                        <InputNumber id="quantity" v-model="shuttle.quantity"/>
                                                        <label for="quantity"> Quantity </label>
                                                    </span>
                                                </div>  
                                                <div class="col-12 md:col-4">
                                                    <span class="p-float-label">
                                                        <Calendar autocomplete="off" id="contact" :showTime="true" v-model="shuttle.start_datetime" dateFormat="yy-mm-dd"/>
                                                        <label for="contact"> Turn Start </label>
                                                    </span>
                                                </div>
                                                <div class="col-12 md:col-4">
                                                    <span class="p-float-label">
                                                        <Calendar autocomplete="off" id="contact" :showTime="true" v-model="shuttle.end_datetime" dateFormat="yy-mm-dd"/>
                                                        <label for="contact"> Turn End </label>
                                                    </span>
                                                </div>  
                                            </div>
                                        </div>
                                        <div v-if="get_error_shuttle_service_shifts" class="col text-center" style="color: red;">
                                            <div v-for="error in get_error_shuttle_service_shifts" :key="error">
                                                <ul>    
                                                    <li v-if="error.quantity">{{error.quantity[0]}}</li>
                                                    <li v-if="error.service_type">{{error.service_type[0]}}</li>
                                                    <li v-if="error.start_datetime">{{error.start_datetime[0]}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-if="get_shuttle_service.length == 1" class="row pt-3 pb-2 justify-content-between">
                                            <div class="col-12 md:col-3">
                                                <Button label="New" icon="pi pi-plus" class="p-button-rounded p-button-success" @click="add_shuttle_shift()"/>
                                            </div>
                                            <div class="col-12 md:col-3">
                                                <Button label="Delete" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="delete_shuttle_shift()"/>
                                            </div>
                                        </div>
                                        <Divider align="center" class="mt-2 mb-3">
                                            <div class="inline-flex align-items-center">
                                                <b> Notes </b>
                                            </div>
                                        </Divider>
                                        <div class="row pt-2 pb-2">
                                            <div class="col">
                                                <TextArea :autoResize="true" v-model="shuttle_service.note"/>
                                            </div>
                                        </div>
                                        <div v-if="shuttle_service.status === 'Modified' || shuttle_service.status === 'Refused' || shuttle_service.status === 'Confirmed' || shuttle_service.status === 'On hold'" class="row pt-3 justify-content-between">
                                            <div class="col-12 md:col-4">
                                                <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-rounded" @click="send_shuttle_service_mail()"/>
                                            </div>
                                            <div class="col-12 md:col-4">
                                                <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-rounded p-button-danger" @click="delete_shuttle_service()"/>
                                            </div>
                                        </div>
                                        <div v-else class="row pt-3 justify-content-between">
                                            <div class="col-12 md:col-4">
                                                <Button label="Save" icon="fa-solid fa-check" class="p-button-rounded p-button-success" @click="save_changes_shuttle_service()"/>
                                            </div>
                                            <div class="col-12 md:col-4">
                                                <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-rounded" @click="send_shuttle_service_mail()"/>
                                            </div>
                                            <div class="col-12 md:col-4">
                                                <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-rounded p-button-danger" @click="delete_shuttle_service()"/>
                                            </div>
                                        </div>
                                        <div v-if="mail_shuttle_error.length > 0" class="row pt-3">
                                            <div class="col text-center" style="color: red;">
                                                {{mail_shuttle_error}}
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                            -->
                            <div v-if="user_port=='Puerto de Málaga' && $ability.can('view','ticket_stand') && !get_call.is_unconventional" class="col-12 xl:col-6 mt-4">
                                <Panel header="Ticket Stand" v-model:collapsed="ticket_collapsed" :collapsed="true" :toggleable="true">
                                    <template #header>
                                        <div @click="ticket_collapsed = !ticket_collapsed" class="col" style="padding: 0.7rem;">
                                            <div class="row"  >
                                                <div class="col">
                                                    <span class="title_panels"> Ticket Stand </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #icons>
                                        <i class="p-panel-header-icon mr-2">
                                            <span v-if="ticket_stand_required == true">
                                                <span v-tooltip.top="'Service required.'" class="fa-solid fa-circle fa-xl" style="color: #22C55E;"></span>
                                            </span>
                                        </i>
                                    </template>
                                    <div class="container p-fluid">
                                        <div class="row pt-2 justify-content-start">
                                            <div class="col md:col-6 text-center">
                                                <div class="p-float-label">
                                                    <InputNumber id="ticket_stand" v-model="ticket_stand.quantity" :disabled="!$ability.can('change','ticket_stand')"/>
                                                    <label for="contact"> Quantity </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row pt-3 justify-content-between">
                                            <div v-if="$ability.can('change','ticket_stand')" class="col-12 md:col-3">
                                                <Button icon="fa fa-check" label="Save" class="p-button-raised p-button-text p-button-success" @click="save_ticket_stand()"/>
                                            </div>
                                            <div v-if="$ability.can('delete','ticket_stand')" class="col-12 md:col-3">
                                                <Button icon="fa fa-trash" label="Delete" class="p-button-raised p-button-text p-button-danger" @click="delete_ticket_stand()"/>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                            <div v-if="user_port=='Puerto de Málaga' && $ability.can('view','medical_room') && !get_call.is_unconventional" class="col-12 xl:col-6 mt-4">
                                <Panel header="Medical Room" v-model:collapsed="medical_collapsed" :collapsed="true" :toggleable="true">
                                    <template #header>
                                        <div @click="medical_collapsed = !medical_collapsed" class="col" style="padding: 0.7rem;">
                                            <div class="row"  >
                                                <div class="col">
                                                    <span class="title_panels"> Medical Room </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #icons>
                                        <i class="p-panel-header-icon mr-2">
                                            <span v-if="medical_room_required.value === true">
                                                <span v-tooltip.top="'Service required.'" class="fa-solid fa-circle fa-xl" style="color: #22C55E;"></span>
                                            </span>
                                        </i>
                                    </template>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-12 md:col-12 text-center">
                                                <SelectButton :disabled="!$ability.can('change','medical_room') || !$ability.can('delete','medical_room') || !$ability.can('add','medical_room')" id="medical_room" optionLabel="name" v-model="medical_room_required" :options="options" @change="change_medical_room_service()"/>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                            <div v-if="$ability.can('view','custom_service') && !get_call.is_unconventional" class="col-12 mt-4 mb-4">
                               <Extra  :all_suppliers="all_suppliers" :service_sheet="service_sheet"/>
                            </div>
                        </div>
                    </TabPanel>   
                </TabView>
            </div>
        </div>
        
    </div>
    
    <Dialog header="New Incidence" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '60vw'}">
        <ModalIncidences  :service_sheet="service_sheet"/>
        <template #footer>
            <Button label="Close" icon="pi pi-times" @click="close_modal" class="p-button-text" />
        </template>
    </Dialog>

    <Dialog header="Finger Incidences" v-model:visible="displayModal2" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '80vw'}">
        <Datatable :value="fingers_incidences" class="p-datatable-customers"
            dataKey="id"  responsiveLayout="scroll">
            <Column header="Title" field="title" style="min-width:12rem">
                <template #body="{data}">
                    {{data.title}}
                </template>
            </Column>
            <Column header="Description" field="text" style="min-width:12rem">
                <template #body="{data}">
                    {{data.text}}
                </template>
            </Column>
            <Column header="Date" filterField="date" dataType="date" style="min-width:12rem">
                <template #body="{data}">
                    {{format_date(data.date)}}
                </template>
            </Column>
            <Column header="Subcategory" field="id_incidence_subcategory.title" style="min-width:12rem">
                <template #body="slotProps">
                    {{slotProps.data.id_incidence_subcategory.title}} <br>
                </template>
            </Column>
            <Column header="User" field="id_user_creator.username" style="min-width:12rem"/>
        </Datatable>    
        <template #footer>
            <Button label="Close" icon="pi pi-times" @click="close_modal_show" class="p-button-text"/>
        </template>
    </Dialog>

    <Galleria :value="get_ship_finger_images" :numVisible="7" containerStyle="max-width: 850px"
    :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false" v-model:visible="display_basic">
        <template #item="slotProps">
            <img :src="slotProps.item.picture" style="width: 100%; display: block;" />
        </template>
        <template #caption="{item}">
            <p> {{item.id_ship.name}} </p>
            <p> {{item.id_finger.name}} - {{item.position}} </p>
        </template>
    </Galleria>

    <Galleria v-model:visible="display_modal_water" :value="modal_image" :numVisible="1" containerStyle="max-width: 50%" :circular="true" :fullScreen="true" :showItemNavigators="false"  :showThumbnails="false">
        <template #item="slotProps">
            <span v-if="slotProps.item.split('?')[0].split('.').pop().toLowerCase() == 'pdf'">
                <iframe :src="slotProps.item" frameborder="0" style="width: 50vw; display: block; height: 60vh;"></iframe>
            </span>
            <span v-else>
                <img :src="slotProps.item"  style="height: 90vh; display: block; object-fit: scale-down;" />
            </span>
        </template>
    </Galleria>

    <Dialog v-model:visible="delete_service" :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span> Are you sure you want to delete this service? </span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_service = false"/>
            <Button label="Yes" @click="handle_function_delete(service_selected)" />
        </template>
    </Dialog>

    <Dialog v-model:visible="modal_confirmation_mail"  :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="row">
            <div class="col">
                <span> Are you sure you want to send this mail? </span>
            </div>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="modal_confirmation_mail = false"/>
            <Button label="Yes" @click="handle_function_confirmation_mail(mail_selected)" />
        </template>
    </Dialog>

    <Dialog header="Details Service Sheet" v-model:visible="display_modal_print" :style="{width: '70rem'}" :modal="true">
        <ServiceSheetPrint :print="true"/>
    </Dialog>

</template>

<script>
import Dates from '../../components/ServiceSheet/Dates.vue'
import StickyDates from '../../components/ServiceSheet/Sticky_dates.vue'
import General from '../../components/ServiceSheet/General.vue'
import Estimation from '../../components/ServiceSheet/Estimation.vue'
import Final from '../../components/ServiceSheet/Final.vue'

import CleaningService from '../../components/ServiceSheet/Services/Cleaning.vue'
import PortersService from '../../components/ServiceSheet/Services/Porters.vue'
import TerminalService from '../../components/ServiceSheet/Services/Terminal_security.vue'
import K9Service from '../../components/ServiceSheet/Services/K9.vue'
import FenceService from '../../components/ServiceSheet/Services/Fence_security.vue'
import CustodianService from '../../components/ServiceSheet/Services/Custodian.vue'
import Extra from '../../components/ServiceSheet/Services/Extra.vue'

import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Panel from 'primevue/panel'
import Divider from 'primevue/divider'
import Calendar from 'primevue/calendar'
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import Skeleton from 'primevue/skeleton'
import RadioButton from 'primevue/radiobutton'
import moment from 'moment'
import SelectButton from 'primevue/selectbutton'
import FileUpload from 'primevue/fileupload'
import Image from 'primevue/image'
import TabView from 'primevue/tabview' 
import TabPanel from 'primevue/tabpanel'
import Datatable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import Galleria from 'primevue/galleria'
import Message from 'primevue/message' 
import TextArea from 'primevue/textarea'

import ModalIncidences from '@/components/Incidences/ModalIncidences.vue'
import ServiceSheetPrint from '@/views/Signatures/Signatures_template.vue'

import { num2time, num2timeedit } from '@/modules/utilities.js'

import ability from '../../services/ability'

export default {
    name: 'ServiceSheet', 
    components: {
        Dates,
        StickyDates,
        General,
        Estimation,
        Final,
        InputNumber,
        Dropdown,
        Panel,
        RadioButton,
        Divider,
        Calendar,
        Button,
        Toast,
        Skeleton,
        SelectButton,
        FileUpload,
        Image,
        TabView,
        TabPanel,
        ModalIncidences,
        Datatable,
        Column,
        Dialog,
        Galleria,
        Message,
        ServiceSheetPrint,
        FenceService,
        CleaningService,
        PortersService,
        TerminalService,
        K9Service,
        CustodianService,
        Extra,
        TextArea
    },
	data() {
        return {
            sticky_dates: 'none',
            user_port: '',
            color: localStorage.getItem('primary_color'),
            selected_terminals: [],
            selected_fingers: [],
            all_ships: [],
            selected_ship: null,
            positions : [
                'Port Side',
                'Starboard Side'
            ],
            lower_module: 0,
            max_module: 1000,
            oneDay: 24 * 60 * 60 * 1000,
            days: null,
            column_days: [],
            displayModal: false,
            displayModal2: false,
            displayModal3: false,
            display_modal_water: false,
            editingRows: [],
            fingers_incidences: [],
            display_basic: false,
            position_values: [
                'Mid',
                'Forward',
                'After'
            ],
			placeholder: 'No',
            service_sheet: {},
            ship_agent: {},
            changed: false,
            options: [
                {name:"Required", value: true},
                {name:"Not required", value: false}
            ],
            min_date: '',
            max_date: '',
            ship_responsible_list: [],
            save_finger_button: false,
            // Toggle pods
            general_collapsed: false,
            estimation_collapsed: true,
            final_collapsed: true,
            fingers_collapsed: true,
            mooring_collapsed: true,
            wifi_collapsed: true,
            water_collapsed: true,
            shuttle_collapsed: true,
            ticket_collapsed: true,
            medical_collapsed: true,
            // Errors
            errors_service_sheet_date: {},
            errors_service_sheet_general: {},
            errors_service_sheet_estimation: {},
            errors_service_sheet_final: {},
            finger_errors_calls: {},
            edit_finger_errors_calls: {},
            // Confirm Buttons
            button_confirm_date: false,
            button_confirm_general: false,
            button_confirm_final: false,
            button_confirm_ship: false,
            button_confirm_mooring: false,
            // Style
            arrival_datetime: 'arrival',
            departure_datetime: 'departure',
            is_on_hold_arrival: false,
            is_on_hold_departure: false,
            loading: true,
            // Start
            ph_estimated_arrival_datetime: '',
            ph_estimated_departure_datetime: '',
            finger_service: [],
            new_finger_service: {'service_sheet':  (Number(this.$route.params.sid)), 'id_finger': '', 'position': '', 'start_datetime': '', 'start_datetime_hour': '', 'end_datetime': '', 'end_datetime_hour': ''},
            selected_fingers_services: [],
            // SERVICES
            start_service_date: '',
            end_service_date: '',
            // wifi
            wifi_service: {},
            wifi_required: {name:"Not required", value: false},
            // water
            water_service: {},
            new_water_reading: {'date': '', 'initial_reading': null, 'final_reading': null, 'water': '', 'delivery_note': '', 'id_water_meter': '' },
            water_readings: [],
            water_required: false,
            modal_delivery_note_water: false,
            url: null,
            url_type: '',
            modal_image: [],
            editing_rows_water: [],
            water_new_picture_file: '',
            // medical room
            medical_room: {},
            medical_room_required: {name:"Not required", value: false},
            // ticket stand
            ticket_stand: {
                'quantity': null,
                'id_service_sheet': (Number(this.$route.params.sid))
            },
            ticket_stand_required: false,
            // shuttle
            shuttle_service: {'id_supplier_responsible': null, 'note':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))},
            shuttle_service_shifts: [],
            selected_shuttle_shifts: [],   // Schedules from porters services selected with the checkbox,
            mail_shuttle_error: null,
            // Dialog service delete
            delete_service: false,
            service_selected: '',
            // Mail confirmation petititon
            modal_confirmation_mail: false,
            mail_selected: '',
            display_modal_print: false,
            loading_services: false,
        }
    },
    computed: {
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Services') {
                    return true
                }
            }
            return false
        },
        check_user_admins: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Admins') {
                    return true
                }
            }
            return false
        },
        // Para editar una hoja de servicio despues de ser validada / confirmada, se tiene que ser Admin.
        is_disabled: function() {
            if(this.service_sheet.confirmed && !this.check_user_admins) {
                return true
            } else {
                return false
            }
        },
        get_user_port: function() {
            return this.$store.getters.get_user_port
        },
		get_service_sheet: function() {
			return this.$store.getters.get_service_sheet
        },
        get_placeholders: function() {
            return this.$store.getters.get_placeholders
        },
        get_call: function() {
            return this.$store.getters.get_call
        },
        get_main_service_sheet: function() {
            return this.$store.getters.get_main_service_sheet
        },
        get_all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        get_all_ships: function() {
            return this.$store.getters.all_ships
        },
        get_ship: function() {
            return this.$store.getters.get_ship
        },
        all_fingers: function() {
            return this.$store.getters.all_fingers
        },
        get_finger_service: function() {
            return this.$store.getters.get_finger_service
        },
        get_ship_finger_images: function() {
            return this.$store.getters.get_ship_finger_images
        },
        service_sheet_incidences: function() {
            return this.$store.getters.service_sheet_incidences
        },
        get_users_by_port: function() {
            return this.$store.getters.users_by_port
        },
        get_ship_agents: function() {
            return this.$store.getters.get_ship_agents
        },
        get_ship_responsible() {
            return this.$store.getters.get_ship_responsible
        },
        // Validation errors
        get_errors_service_sheet_date: function() {
            return this.$store.getters.get_errors_service_sheet_date
        },
        get_errors_service_sheet_estimation: function() {
            return this.$store.getters.get_errors_service_sheet_estimation
        },
        get_errors_service_sheet_final: function() {
            return this.$store.getters.get_errors_service_sheet_final
        },
        get_finger_service_errors: function() {
            return this.$store.getters.get_finger_service_errors
        },
        // SERVICES
        all_suppliers: function() {
            return this.$store.getters.all_suppliers
        },
        get_supplier_service: function() {
            return this.$store.getters.get_supplier_service
        },
        get_supplier_service_responsible: function() {
            return this.$store.getters.get_supplier_service_responsible
        },
        // Wifi
        get_wifi_service: function() {
            return this.$store.getters.get_wifi_service
        },
        // Water
        get_water_service: function() {
            return this.$store.getters.get_water_service
        },
        get_water_readings: function() {
            return this.$store.getters.get_water_readings
        },
        get_error_update_water_service: function() {
            return this.$store.getters.get_error_update_water_service
        },
        get_error_create_water_service: function() {
            return this.$store.getters.get_error_create_water_service
        },
        all_water_meters: function() {
            return this.$store.getters.all_water_meters
        },
        get_last_reading: function() {
            return this.$store.getters.get_last_reading
        },
        // Medical room
        get_medical_room_service: function() {
            return this.$store.getters.get_medical_room_service
        },
        // Ticket stand
        get_ticket_stand_service: function() {
            return this.$store.getters.get_ticket_stand_service
        },
        // Shuttle
        get_shuttle_service: function() {
            return this.$store.getters.get_shuttle_service
        },
        get_shuttle_service_shift: function() {
            return this.$store.getters.get_shuttle_service_shift
        },
        get_send_shuttle_mail: function() {
            return this.$store.getters.get_send_shuttle_mail
        },
        get_error_shuttle_service_shifts: function() {
            return this.$store.getters.get_error_shuttle_service_shifts
        },
        // Finger 
        get_errors_service_sheet_fingers_error: function() {
            return this.$store.getters.get_errors_service_sheet_fingers_error
        },
        // Dates
        get_min_date: function() {
            return this.$store.getters.min_date
        },
        get_max_date: function() {
            return this.$store.getters.max_date
        }
        
    },
    async mounted () {
        await this.$store.dispatch('load_user_port')
        this.user_port = this.get_user_port.name

        if(ability.can('view', 'call')) {
            await this.$store.dispatch('load_call', (Number(this.$route.params.id)))
        }

        if(ability.can('view', 'supplier')){
            await this.$store.dispatch('load_all_suppliers')
        }   

        // Load all data from the service sheet
        if(ability.can('view', 'service_sheet')) {
            await this.$store.dispatch('load_service_sheet', (Number(this.$route.params.sid)))
            await this.$store.dispatch('load_placeholders_service_sheets', (Number(this.$route.params.sid)))
        }

        if(ability.can('view', 'ship')){
            await this.$store.dispatch('load_all_ships') // Loads ships for the dropdown (no picture)
            this.all_ships = this.get_all_ships
        }
        if(ability.can('view', 'finger')){
            await this.$store.dispatch('load_all_fingers')
        }
        if(ability.can('view', 'finger_service')){
            await this.$store.dispatch('load_finger_service', (Number(this.$route.params.sid)))
        }
        if(ability.can('view', 'incidence_report')){
            await this.$store.dispatch('load_incidence_by_service_sheet', (Number(this.$route.params.sid)))
        }
        if(ability.can('view', 'user')){
            await this.$store.dispatch('load_users_by_port') // Loads users by current user port for dropdown
        }

        // Finger Service 
        this.service_sheet_incidences.map(ss_incidence => {
            if(ss_incidence.id_incidence_category.title.toLowerCase().includes('finger')) {
                this.fingers_incidences.push(ss_incidence)
            }
        })

        
        
        if(this.get_finger_service.length > 0) {
            this.finger_service = this.get_finger_service
            for(var f in this.finger_service) {
                if(this.finger_service[f].start_datetime.search('T') != -1) {
                    this.finger_service[f].start_datetime_hour =  this.finger_service[f].start_datetime.split('T')[1].split(':')[0] + ':' + this.finger_service[f].start_datetime.split('T')[1].split(':')[1]
                    this.finger_service[f].start_datetime =  this.finger_service[f].start_datetime.split('T')[0]
                }
                if(this.finger_service[f].end_datetime) {
                    if(this.finger_service[f].end_datetime.search('T') != -1) {
                        this.finger_service[f].end_datetime_hour =  this.finger_service[f].end_datetime.split('T')[1].split(':')[0] + ':' + this.finger_service[f].end_datetime.split('T')[1].split(':')[1]
                        this.finger_service[f].end_datetime =  this.finger_service[f].end_datetime.split('T')[0]
                    }
                }
                
            }
        }      

        //this.mail_fence_error = ''
        
        this.mail_shuttle_error = ''

        // Assigns getter data to variable
        this.service_sheet = this.get_service_sheet  
        if(this.service_sheet.id_call){
            if(ability.can('view', 'ship_agent')){
                if(this.service_sheet.id_call.id_ship_responsible){
                    await this.$store.dispatch('load_ship_agent_by_responsible', this.service_sheet.id_call.id_ship_responsible)
                }
            }
            if(this.get_ship_agents.length > 0) {
                if(this.service_sheet.id_call.id_ship_responsible) {
                    this.ship_agent = this.get_ship_agents[0]
                }
                if(ability.can('view', 'ship_responsible')){
                    await this.$store.dispatch('load_ship_responsibles', this.get_ship_agents[0].id)
                }
                this.ship_responsible_list = this.get_ship_responsible
            }
        } else {
            if(ability.can('view', 'ship_agent')){
                await this.$store.dispatch('load_ship_agents', this.service_sheet.id_call.id_ship.id_cruise_line)
            }
        }
        
        // Load singular ship from service sheet (with picture)
        if(ability.can('view', 'ship')){
            await this.$store.dispatch('load_ship', this.service_sheet.id_call.id_ship.id)
        }

        if(ability.can('view', 'finger_service')){
            await this.$store.dispatch('load_ship_fingers_images', (Number(this.$route.params.sid)))
        }
        // Calculate days between call
        
        this.new_finger_service = {'service_sheet':  (Number(this.$route.params.sid)), 'id_finger': '', 'position': '', 'start_datetime': '', 'end_datetime': ''}

        // Formats date yy-mm-dd HH:MM
        if (this.service_sheet.estimated_arrival_datetime) {
            if(this.service_sheet.estimated_arrival_datetime.search('T') != -1) {
                this.service_sheet.estimated_arrival_datetime_hour = (this.service_sheet.estimated_arrival_datetime.split('T')[1]).split(':')[0] + ':' + (this.service_sheet.estimated_arrival_datetime.split('T')[1]).split(':')[1]
                this.service_sheet.estimated_arrival_datetime = this.service_sheet.estimated_arrival_datetime.split('T')[0]
            } 
        } 
        if (this.service_sheet.estimated_departure_datetime) {
            if(this.service_sheet.estimated_departure_datetime.search('T') != -1) {
                this.service_sheet.estimated_departure_datetime_hour = (this.service_sheet.estimated_departure_datetime.split('T')[1]).split(':')[0] + ':' + (this.service_sheet.estimated_departure_datetime.split('T')[1]).split(':')[1]
                this.service_sheet.estimated_departure_datetime = this.service_sheet.estimated_departure_datetime.split('T')[0]
            }
        }
        if (this.service_sheet.arrival_datetime) {
            if(this.service_sheet.arrival_datetime.search('T') != -1) {
                this.service_sheet.arrival_datetime_hour = (this.service_sheet.arrival_datetime.split('T')[1]).split(':')[0] + ':' + (this.service_sheet.arrival_datetime.split('T')[1]).split(':')[1]
                this.service_sheet.arrival_datetime = this.service_sheet.arrival_datetime.split('T')[0]
            }
        }
        if (this.service_sheet.departure_datetime) {
            if(this.service_sheet.departure_datetime.search('T') != -1) {
                this.service_sheet.departure_datetime_hour = (this.service_sheet.departure_datetime.split('T')[1]).split(':')[0] + ':' + (this.service_sheet.departure_datetime.split('T')[1]).split(':')[1]
                this.service_sheet.departure_datetime = this.service_sheet.departure_datetime.split('T')[0]  
            }
        }
            

        
        if(this.service_sheet.arrival_datetime) {
            this.start_service_date = this.service_sheet.arrival_datetime
        } else {
            this.start_service_date = this.service_sheet.estimated_arrival_datetime
        }
        
        if(this.service_sheet.departure_datetime) {
            this.end_service_date = this.service_sheet.departure_datetime
        } else {
            this.end_service_date = this.service_sheet.estimated_departure_datetime
        }

        // this.calculate_days(new Date(this.get_call.arrival_datetime), new Date(this.get_call.departure_datetime))
        

        // Set min and max date (-1, +1)
        
        // add.(1,'d')

        if(this.service_sheet.arrival_datetime) {
            await this.$store.dispatch('set_min_date', {date: new Date(moment(this.service_sheet.arrival_datetime)), mode: 'final'})
        } else if(this.service_sheet.estimated_arrival_datetime) {
            await this.$store.dispatch('set_min_date', {date: new Date(moment(this.service_sheet.estimated_arrival_datetime)), mode: 'estimated'})
        }

        if(this.service_sheet.departure_datetime) {
            await this.$store.dispatch('set_max_date', {date: new Date(moment(this.service_sheet.departure_datetime)), mode: 'final'})
        } else if(this.service_sheet.estimated_departure_datetime) {
            await this.$store.dispatch('set_max_date',  {date: new Date(moment(this.service_sheet.estimated_departure_datetime)), mode: 'estimated'})
        }

        // await this.$store.dispatch('load_fence_security_service')
        // this.fence_security_service = this.get_fence_security_service[0]
        
        if(ability.can('view', 'service_sheet')){
            await this.$store.dispatch('load_main_service_sheet', (Number(this.$route.params.id)))
        }

        if(!this.service_sheet.main) {
            if(this.get_main_service_sheet[0]) {
                if(this.get_main_service_sheet[0].arrival_datetime != null) {
                    if(!this.get_main_service_sheet[0].arrival_datetime.indexOf('T') != -1){
                        this.service_sheet.arrival_datetime = this.get_main_service_sheet[0].arrival_datetime.split('T')[0]
                        this.service_sheet.arrival_datetime_hour = this.get_main_service_sheet[0].arrival_datetime.split('T')[1].split(':')[0] + ':' + this.get_main_service_sheet[0].arrival_datetime.split('T')[1].split(':')[1]
                    }
                }

                if(this.get_main_service_sheet[0].departure_datetime != null) {
                    if(!this.get_main_service_sheet[0].departure_datetime.indexOf('T') != -1) {
                        this.service_sheet.departure_datetime = this.get_main_service_sheet[0].departure_datetime.split('T')[0]
                        this.service_sheet.departure_datetime_hour = this.get_main_service_sheet[0].departure_datetime.split('T')[1].split(':')[0] + ':' + this.get_main_service_sheet[0].departure_datetime.split('T')[1].split(':')[1]
                    }
                }
            }
            
            if(this.get_main_service_sheet[0]) {
                this.service_sheet.p_disembarking = this.get_main_service_sheet[0].p_disembarking
                this.service_sheet.p_embarking = this.get_main_service_sheet[0].p_embarking
                this.service_sheet.p_transit = this.get_main_service_sheet[0].p_transit

                this.service_sheet.disembarking_extra = this.get_main_service_sheet[0].disembarking_extra
                this.service_sheet.embarking_extra = this.get_main_service_sheet[0].embarking_extra
                this.service_sheet.transit_extra = this.get_main_service_sheet[0].transit_extra

                this.service_sheet.c_disembarking = this.get_main_service_sheet[0].c_disembarking
                this.service_sheet.c_embarking = this.get_main_service_sheet[0].c_embarking
                this.service_sheet.c_transit = this.get_main_service_sheet[0].c_transit

                this.service_sheet.position = this.get_main_service_sheet[0].position
                this.service_sheet.module_start = this.get_main_service_sheet[0].module_start
                this.service_sheet.module_end = this.get_main_service_sheet[0].module_end
            }
            
            
        }

        // Stops loading when all data is dispatched
        this.loading = false

    },
    methods: {
        async render_services(event) {

            if(event.index == 1) {
                if(ability.can('view', 'porter')){
                await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
                    if(ability.can('view', 'porter_service_service_option')){
                        await this.$store.dispatch('load_porters_service_service_options')
                    }
                }
                if(ability.can('view', 'terminal_security')){
                    await this.$store.dispatch('load_terminals_security_service', (Number(this.$route.params.sid)))
                    if(ability.can('view', 'terminal_security_service_type_option')){
                        await this.$store.dispatch('load_terminals_security_service_type_options')
                    }
                    if(ability.can('view', 'terminal_security_service_position_option')){
                        await this.$store.dispatch('load_terminals_security_service_position_options')
                    }
                }
                if(this.user_port == "Port de Barcelona") {
                    if(ability.can('view', 'cleaning')){
                        await this.$store.dispatch('load_cleaning_service', (Number(this.$route.params.sid)))
                    }
                    if(ability.can('view', 'custodian')){
                        await this.$store.dispatch('load_custodian_service', (Number(this.$route.params.sid)))
                    }
                    if(ability.can('view', 'k9')){
                        await this.$store.dispatch('load_k9', (Number(this.$route.params.sid)))
                        if(ability.can('view', 'k9_service_type_option')){
                            await this.$store.dispatch('load_k9_service_type_options')
                        }
                    }
                    
                    if(ability.can('view', 'wifi')){
                        await this.$store.dispatch('load_wifi_service', (Number(this.$route.params.sid))) 
                    }
                    if(ability.can('view', 'water')){
                        await this.$store.dispatch('load_water_service', (Number(this.$route.params.sid)))
                    }
                    if(ability.can('view', 'water_meter')){
                        await this.$store.dispatch('load_all_water_meters')
                    }
                }

                if(this.user_port == "Puerto de Málaga") {
                    if(ability.can('view', 'medical_room')){
                        await this.$store.dispatch('load_medical_room_service', (Number(this.$route.params.sid)))
                    }
                    if(ability.can('view', 'ticket_stand')){
                        await this.$store.dispatch('load_ticket_stand_service', (Number(this.$route.params.sid)))
                    }
                    if(ability.can('view', 'shuttle')){
                        await this.$store.dispatch('load_shuttle_service', (Number(this.$route.params.sid)))
                    }
                }

                //if(ability.can('view', 'shuttle')){
                await this.$store.dispatch('load_custom_service', (Number(this.$route.params.sid)))
                //}

                // Wifi service check if exists
                if(this.get_wifi_service.length > 0) {
                    this.wifi_service = this.get_wifi_service
                    this.wifi_required = {name:"Required", value: true}
                }

                // Water service check if exists
                if(this.get_water_service.length > 0) {
                    this.water_service = this.get_water_service[0]
                    this.water_required = true
                    await this.$store.dispatch('load_water_service_readings', this.water_service.id_service_sheet.id)
                    this.new_water_reading.water = this.water_service.id
                } else {
                    await this.$store.dispatch('empty_water_readings')
                }
            
                // Medical room service check if exists
                
                if(this.get_medical_room_service.length > 0) {
                    this.medical_room = this.get_medical_room_service
                    this.medical_room_required = {name:"Required", value: true}
                }   

                // Ticket stand service check if exists
                if(this.get_ticket_stand_service.length > 0) {
                    this.ticket_stand = this.get_ticket_stand_service[0]
                    this.ticket_stand_required = true
                }

                /* Shuttle Service check if exists
                if(this.get_shuttle_service.length > 0) {
                    await this.$store.dispatch('load_shuttle_service_shifts', (Number(this.$route.params.sid)))
                    this.shuttle_service = this.get_shuttle_service[0]

                    if(this.get_shuttle_service_shift.length > 0) {
                        this.shuttle_service_shifts = this.get_shuttle_service_shift
                        for(var g in this.shuttle_service_shifts) {
                            this.shuttle_service_shifts[g].start_datetime = this.shuttle_service_shifts[g].start_datetime.split('T')[0] + ' ' + this.shuttle_service_shifts[g].start_datetime.split('T')[1].split(':')[0] + ':' + this.shuttle_service_shifts[g].start_datetime.split('T')[1].split(':')[1]
                            this.shuttle_service_shifts[g].end_datetime = this.shuttle_service_shifts[g].end_datetime.split('T')[0] + ' ' + this.shuttle_service_shifts[g].end_datetime.split('T')[1].split(':')[0] + ':' + this.shuttle_service_shifts[g].end_datetime.split('T')[1].split(':')[1]
                        }
                    }
                }*/
                
                this.loading_services = true
            }
        },
        show_sticky_dates() { 
            this.sticky_dates = 'block'
        },
        hide_sticky_dates() {
            this.sticky_dates = 'none'
        },
        handle_function_delete(function_name){
            this[function_name]()
            this.delete_service = false
            this.service_selected = ''
        },
        handle_function_confirmation_mail(function_name){
            this[function_name]()
            this.modal_confirmation_mail = false
            this.mail_selected = ''
        },
        open_modal_incidence() {
            this.displayModal = true;
        },
        open_modal_show_incidence() {
            this.displayModal2 = true;
        },
        async close_modal() {
            this.fingers_incidences = []
            this.displayModal = false;
            await this.$store.dispatch('load_incidence_by_service_sheet', (Number(this.$route.params.sid)))
            this.service_sheet_incidences.map(ss_incidence => {
                if(ss_incidence.id_incidence_category.title.toLowerCase().includes('finger')) {
                    this.fingers_incidences.push(ss_incidence)
                }
            })

        },
        
        close_modal_show() {
            this.displayModal2 = false;
        },
        open_modal_image_water(delivery_note) {
            this.modal_image = []
            this.display_modal_water = true;
            if(delivery_note.split('?')[0].split('.').pop() == 'pdf') {
                this.url_type_water = 'pdf'
            } else {
                this.url_type_water = 'image'
            }
            this.modal_image.push(delivery_note)
        },
        close_modal_water() {
            this.display_modal_water = false;
        },
        moment: function(date) {
            if(date == null) {
                return null
            } else if(typeof(date) === "object") {
                return moment(date).format().split('T')[1].split(':')[0] + ':' + moment(date).format().split('T')[1].split(':')[1]
            } else {
                return date
            } 
        },
        format_date(value){
            return moment(value).format().split('T')[0]
        },
        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
        async load_ship_responsibles(id) {
            await this.$store.dispatch('load_ship_responsibles', id.value)
            this.ship_responsible_list = this.get_ship_responsible
        },
        async save_finger_description(){
            await this.$store.dispatch('update_finger_description', this.get_service_sheet)
            if(Object.keys(this.get_errors_service_sheet_fingers_error).length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Success', detail:'Finger description updated', life: 3000});
                this.save_finger_button = false
            }
        },
        // Function that adds new empty finger service so the user can fill
        async add_finger_service() {

            await this.$store.dispatch('create_service_sheet_finger_service', this.new_finger_service)
            this.finger_errors_calls = this.get_finger_service_errors

            if(Object.keys(this.finger_errors_calls).length === 0) {
                this.new_finger_service = {'service_sheet':  (Number(this.$route.params.sid)), 'id_finger': '', 'position': '', 'start_datetime': '', 'end_datetime': ''}
            }
            
            await this.$store.dispatch('load_finger_service', (Number(this.$route.params.sid)))
            this.finger_service = this.get_finger_service
            
            //this.finger_service = this.get_finger_service
            if(this.finger_service) {
                for(var x in this.finger_service) {
                    if(this.finger_service[x].start_datetime.indexOf('T') != -1) {
                        this.finger_service[x].start_datetime_hour = this.finger_service[x].start_datetime.split('T')[1].split(':')[0] + ':' +  this.finger_service[x].start_datetime.split('T')[1].split(':')[1]
                        this.finger_service[x].start_datetime =  this.finger_service[x].start_datetime.split('T')[0] 
                        if(this.finger_service[x].end_datetime) {
                            this.finger_service[x].end_datetime_hour = this.finger_service[x].end_datetime.split('T')[1].split(':')[0] + ':' +  this.finger_service[x].end_datetime.split('T')[1].split(':')[1]
                            this.finger_service[x].end_datetime =  this.finger_service[x].end_datetime.split('T')[0] 
                        }
                        
                    }
                }
            }
        },
        // Function that adds new empty schedule to the shuttle service so the user can fill
        async add_shuttle_shift(){
            this.shuttle_service_shifts.push({
                shuttle: '',
                id: '',
                start_datetime: '',
                end_datetime: '',
                quantity: null,
            })
        },
        // Function to save finger changes
        async onRowEditSave(event) {
            this.edit_finger_errors_calls = {}

            let { newData, index } = event;
            await this.$store.dispatch('patch_service_sheet_fingers_service', newData)

            if(Object.keys(this.get_finger_service_errors).length > 0) {
                this.edit_finger_errors_calls = this.get_finger_service_errors
            } else {
                if(typeof(newData.start_datetime_hour) === 'object') {
                    newData.start_datetime_hour = moment(newData.start_datetime_hour).format().split('T')[1].split(':')[0] + ':' + moment(newData.start_datetime_hour).format().split('T')[1].split(':')[1]
                }
                if(typeof(newData.start_datetime) === 'object') {
                    newData.start_datetime = moment(newData.start_datetime).format().split('T')[0]
                }

                if(newData.end_datetime != '' && newData.end_datetime != null) {
                    if(typeof(newData.end_datetime_hour) === 'object') {
                        newData.end_datetime_hour = moment(newData.end_datetime_hour).format().split('T')[1].split(':')[0] + ':' + moment(newData.end_datetime_hour).format().split('T')[1].split(':')[1]
                    }
                
                    if(typeof(newData.end_datetime) === 'object') {
                        newData.end_datetime = moment(newData.end_datetime).format().split('T')[0]
                    }
                } else {
                    newData.end_datetime_hour = null
                    newData.end_datetime = null
                }
                

                this.finger_service[index] = newData;
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Success', detail:'Data Modified', life: 3000});
            }       
            
            

        },
        async save_changes_mooring(){
            await this.$store.dispatch('patch_service_sheet_fingers', this.service_sheet)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Success', detail:'Data Modified', life: 3000});
            this.button_confirm_mooring = false
        },
        // Function to save fingers changes
        async save_changes_fingers() {
            
            for(var i in this.finger_service) {
                await this.$store.dispatch('patch_service_sheet_fingers_service', this.finger_service[i])
            }   

            this.finger_errors_calls = this.get_finger_service_errors
            
            if(Object.keys(this.finger_errors_calls).length === 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Data Modified', life: 3000});
            }
            
            await this.$store.dispatch('load_finger_service', (Number(this.$route.params.sid)))
            
        },
        // Function called when the button delete from fingers section is clicked
        async delete_finger_service(data) {
            await this.$store.dispatch('delete_service_sheet_finger_services', data.data.id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Data Deleted', life: 3000});
        },
        // WIFI SERVICE
        async change_wifi_service() {
            await this.$store.dispatch('load_wifi_service', (Number(this.$route.params.sid)))

            if (this.wifi_required.value) {
                await this.$store.dispatch('create_wifi_service', (Number(this.$route.params.sid)))
            } else {
                await this.$store.dispatch('delete_wifi_service', this.get_wifi_service[0])
            }
        },
         // WATER SERVICE
        async add_water_reading() {

            if(this.get_water_service.length == 0) {
                await this.$store.dispatch('create_water_service', (Number(this.$route.params.sid)))
                this.water_service = this.get_water_service[0]
                this.new_water_reading.water = this.water_service.id
                await this.$store.dispatch('create_water_reading', this.new_water_reading)

                if(Object.keys(this.get_error_create_water_service).length == 0) {
                    this.$store.dispatch('reset_water_reading')
                    this.water_required = true
                    this.new_water_reading = {'date': '', 'initial_reading': null, 'final_reading': null, 'water': '', 'delivery_note': '', 'id_water_meter': '' }
                    this.new_water_reading.water = this.water_service.id
                    this.url = ''
                }
                
            } else {
                //this.new_water_reading.id_water_meter = this.new_water_reading.id_water_meter.id
                await this.$store.dispatch('create_water_reading', this.new_water_reading)

                if(Object.keys(this.get_error_create_water_service).length == 0) {
                    this.$store.dispatch('reset_water_reading')
                    this.water_required = true
                    this.new_water_reading = {'date': '', 'initial_reading': null, 'final_reading': null, 'water': '', 'delivery_note': '', 'id_water_meter': '' }
                    this.new_water_reading.water = this.water_service.id
                    this.url = ''
                }
            }

            await this.$store.dispatch('load_water_service', (Number(this.$route.params.sid)))
            await this.$store.dispatch('load_water_service_readings', (Number(this.$route.params.sid)))
            
        },
        async save_water_service() {
            let water_delivery_note = {}
            
            water_delivery_note = {
                id: this.water_service.id,
                id_service_sheet: (Number(this.$route.params.sid)),
                quantity: this.water_service.quantity,
                delivery_note: this.water_service.delivery_note_water,
                date: this.water_service.date,
                initial_reading: this.water_service.initial_reading,
                final_reading: this.water_service.final_reading,
                id_water_meter: this.water_service.id_water_meter.id
            }


            if(this.get_water_service.length > 0) {
                await this.$store.dispatch('update_water_service', water_delivery_note)
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Service Updated', life: 3000})
            } else {
                await this.$store.dispatch('create_water_service', water_delivery_note)
                this.water_required = true
                await this.$store.dispatch('load_water_service', (Number(this.$route.params.sid)))
                this.water_service = this.get_water_service[0]
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Service Created', life: 3000})
            }

            await this.$store.dispatch('load_water_service', (Number(this.$route.params.sid)))
            
        },
        async onRowEditSaveWater(event) {
            let { newData } = event;
            if(typeof this.water_new_picture_file === 'object') {
                newData.delivery_note = this.water_new_picture_file
            }
            delete newData.difference
            newData.id_water_meter = newData.id_water_meter.id
            await this.$store.dispatch('update_water_service', newData)
            

            if(this.get_error_update_water_service['initial_reading']) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: this.get_error_update_water_service['initial_reading'][0], life: 3000})
            }
            if(this.get_error_update_water_service['date']) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: this.get_error_update_water_service['date'][0], life: 3000})
            }

        },
        async load_last_reading() {
            if(this.new_water_reading.date != ''){
                await this.$store.dispatch('load_last_reading', this.new_water_reading)
            }    
        },
        onUploadWater(e){
            this.water_new_picture_file = e.files[0]
            this.$refs.fileUploadWater.clear();
            this.$refs.fileUploadWater.uploadedFileCount = 0;
        },
        async delete_water_service(data) {
            /*
            await this.$store.dispatch('delete_water_service', this.get_water_service[0])
            await this.$store.dispatch('load_water_service', (Number(this.$route.params.sid)))
            this.water_service = {
                'quantity': null,
                'id_service_sheet': (Number(this.$route.params.sid))
            },
            this.water_required = false
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Service Deleted', life: 3000})
            */
            await this.$store.dispatch('delete_water_reading', data.data.id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Data Deleted', life: 3000});


            if(this.get_water_readings.length == 0) {
                await this.$store.dispatch('delete_water_service', this.water_service.id)
                this.water_required = false
            }

        },
        // MEDICAL ROOM SERVICE
        async change_medical_room_service() {
            await this.$store.dispatch('load_medical_room_service', (Number(this.$route.params.sid)))
            
            if (this.medical_room_required.value) {
                await this.$store.dispatch('create_medical_room_service', (Number(this.$route.params.sid)))
            } else {
                await this.$store.dispatch('delete_medical_room_service', this.get_medical_room_service[0])
            } 
        },
        // TICKET STAND SERVICE
        async save_ticket_stand() {
            if(this.get_ticket_stand_service.length > 0) {
                await this.$store.dispatch('update_ticket_stand_service', this.ticket_stand)
            } else {
                await this.$store.dispatch('create_ticket_stand_service', this.ticket_stand)
                this.ticket_stand_required = true
                await this.$store.dispatch('load_ticket_stand_service', (Number(this.$route.params.sid)))
                this.ticket_stand = this.get_ticket_stand_service[0]
            }
        },
        async delete_ticket_stand() {
            await this.$store.dispatch('delete_ticket_stand_service', this.get_ticket_stand_service[0])
            await this.$store.dispatch('load_ticket_stand_service', (Number(this.$route.params.sid)))
            this.ticket_stand = {
                'quantity': null,
                'id_service_sheet': (Number(this.$route.params.sid))
            },
            this.ticket_stand_required = false
        },
        // SHUTTLE SERVICE
        change_shuttle_service() {
            this.mail_shuttle_error = null
        }, 
        async save_changes_shuttle_service() {
            this.$store.dispatch('clean_errors_shuttle_shifts')
            this.mail_shuttle_error = ''

            if(!this.shuttle_service.manager && this.shuttle_service_shifts.length == 0){
                this.mail_shuttle_error = 'Cannot save/send empty service. Select a responsible first.'
            } else {
                if(this.get_shuttle_service.length == 0) {
                    if(!this.shuttle_service.manager) {
                        this.mail_shuttle_error = 'Select a responsible first.'
                    } else {
                        await this.$store.dispatch('create_shuttle_service', this.shuttle_service)
                    
                        if(this.mail_shuttle_error == '') {
                            this.$toast.removeAllGroups();
                            this.$toast.add({severity:'success', summary: 'Successful', detail:'Responsible assigned', life: 3000});
                        }

                        await this.$store.dispatch('load_shuttle_service', (Number(this.$route.params.sid)))
                        this.shuttle_service = this.get_shuttle_service[0]

                        for(var i in this.shuttle_service_shifts) {
                            if(this.shuttle_service_shifts[i].id) {
                                await this.$store.dispatch('update_cleaning_service_shifts', this.shuttle_service_shifts[i])
                            } else {
                                this.shuttle_service_shifts[i].shuttle = this.get_shuttle_service[0].id
                                await this.$store.dispatch('create_cleaning_service_shifts', this.shuttle_service_shifts[i])
                            }
                        }

                        if(this.get_error_shuttle_service_shifts.length == 0 && this.get_shuttle_service_shift.length > 0) {
                            this.$toast.removeAllGroups();
                            this.$toast.add({severity:'success', summary: 'Successful', detail:'Shifts updated', life: 3000});
                        }
                    }
                } else {
                    await this.$store.dispatch('update_shuttle_service', this.shuttle_service)

                    await this.$store.dispatch('load_shuttle_service', (Number(this.$route.params.sid)))
                    this.shuttle_service = this.get_shuttle_service[0]
                    
                    for(var j in this.shuttle_service_shifts) {
                        if(this.shuttle_service_shifts[j].id) {
                            await this.$store.dispatch('update_shuttle_service_shifts', this.shuttle_service_shifts[j])
                        } else {
                            this.shuttle_service_shifts[j].shuttle = this.get_shuttle_service[0].id
                            await this.$store.dispatch('create_shuttle_service_shifts', this.shuttle_service_shifts[j])
                        }
                    }

                    if(this.get_error_shuttle_service_shifts.length == 0 && this.shuttle_service_shifts == 0) {
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'error', summary: 'Error', detail:'Insert at least 1 shift.', life: 3000});
                    } else if (this.shuttle_service_shifts.length > 0 && this.get_error_shuttle_service_shifts == 0) {
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'success', summary: 'Successful', detail:'Shifts updated', life: 3000});
                    } else { 
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'error', summary: 'Error', detail:'At least one shift was not successfully edited or created due to an error. Make sure to double check the error in the appropiate service.', life: 15000});
                    }
                }

                await this.$store.dispatch('load_shuttle_service_shifts', (Number(this.$route.params.sid))) 
                this.shuttle_service_shifts = this.get_shuttle_service_shift
                for(var x in this.shuttle_service_shifts) {
                    this.shuttle_service_shifts[x].start_datetime = this.shuttle_service_shifts[x].start_datetime.split('T')[0] + ' ' + this.shuttle_service_shifts[x].start_datetime.split('T')[1].split(':')[0] + ':' + this.shuttle_service_shifts[x].start_datetime.split('T')[1].split(':')[1]
                    this.shuttle_service_shifts[x].end_datetime = this.shuttle_service_shifts[x].end_datetime.split('T')[0] + ' ' + this.shuttle_service_shifts[x].end_datetime.split('T')[1].split(':')[0] + ':' + this.shuttle_service_shifts[x].end_datetime.split('T')[1].split(':')[1]
                }

            }
        },
        async delete_shuttle_shift() {
            this.selected_shuttle_shifts = (this.selected_shuttle_shifts.sort(function(a, b){return a - b})).reverse()

            for(var i in this.selected_shuttle_shifts) {
                if(!this.shuttle_service_shifts[this.selected_shuttle_shifts[i]].id) {
                    this.shuttle_service_shifts.splice(this.selected_shuttle_shifts[i], 1)
                } else {
                    
                    var id = this.shuttle_service_shifts[this.selected_shuttle_shifts[i]].id
                    this.shuttle_service_shifts.splice(this.selected_shuttle_shifts[i], 1)
                    await this.$store.dispatch('delete_shuttle_service', id)
                }
            }
            this.selected_shuttle_shifts = []
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Shift/s deleted', life: 3000});
            
            await this.$store.dispatch('load_shuttle_service', (Number(this.$route.params.sid)))
            this.shuttle_service = this.get_shuttle_service[0]

        },
        async send_shuttle_service_mail() {
             await this.save_changes_shuttle_service()
            
            if(this.mail_shuttle_error == '' && this.get_error_shuttle_service_shifts.length == 0) {
                if(this.shuttle_service_shifts.length > 0) {
                    if(this.shuttle_service.status == 'None' || this.shuttle_service.status == 'Modified' || this.shuttle_service.status == 'Refused') {
                        await this.$store.dispatch('send_shuttle_service_mail', (Number(this.$route.params.sid)), this.shuttle_service)
                        this.mail_shuttle_error = this.get_error_shuttle_service_shifts
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                    } else {
                        if(this.get_send_shuttle_mail == false) {
                            this.mail_shuttle_error = 'Cannot send email with the same data as the previous.' 
                        } else {
                            await this.$store.dispatch('send_shuttle_service_mail', (Number(this.$route.params.sid)), this.shuttle_service)
                            this.$toast.removeAllGroups();
                            this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                        }
                    }
                } 
   
                await this.$store.dispatch('load_shuttle_service', (Number(this.$route.params.sid)))
                this.shuttle_service = this.get_shuttle_service[0]
            }
        },
        async delete_shuttle_service(){
            this.mail_shuttle_error = ''
            
            await this.$store.dispatch('delete_shuttle', this.shuttle_service.id)
            await this.$store.dispatch('load_shuttle_service', (Number(this.$route.params.sid)))
            
            this.shuttle_service = {'manager': null, 'note':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))}
            this.shuttle_service_shifts = []
            
            if(this.get_error_shuttle_service_shifts.detail === 'Not found.') {
                this.mail_shuttle_error = 'Cannot delete empty service.'
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Service deleted', life: 3000})
            }

        },
        onUpload(e){
            if(e.files[0].name.split('.').pop() == 'pdf') {
                this.url_type = 'pdf'
            } else {
                this.url_type = 'image'
            }
            this.new_water_reading.delivery_note = e.files[0]
            const file = e.files[0];
            this.url = URL.createObjectURL(file);
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
    },
}
</script>

<style scoped>

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
    border-radius: 0%;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}

.p-divider.p-divider-horizontal {
  color: v-bind(color);
}

.top_ss{
    background: #002D72;
    color: white;
}

.color_icons{
    color: v-bind(color);
    cursor: pointer;
}

.save_all{
    position: fixed;
    top: 10%;
    left: 1%;
    text-align: center;
    z-index: 3;
    margin-bottom: 1rem;
}

.p-tooltip{
    background: v-bind(color);
}

ul {
    list-style-type: none;
}

iframe {
    display: block;
    border-style:none;
    margin: 0 auto;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

:deep(.p-image-preview-indicator){
    border-radius: 2%;
}

:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}

.sticky {
    position: fixed !important;
    top: 116px;
    right: 0px;
    z-index: 3;
    width: 100%;
    background-color: rgba(0, 45, 114, 1);
    color: white;
    border-radius: 0 0 10px 10px;
}

.sticky_button {
    position: fixed !important;
    top: 116px;
    right: 1rem;
    z-index: 3;
    background-color: rgba(0, 45, 114, 1);
    color: white;
    border-radius: 0 0 10px 10px;
}

:deep(.row-extra) {
    background-color: rgba(0,0,0,.15) !important;
    border-radius: 20px !important;
}

:deep(.p-button-sm){
    padding: .5px .5px;
}

:deep(.p-scrollpanel-content) {
    overflow-y: hidden;
}
</style>  
