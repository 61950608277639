<template>
    <ModalFingers/>
    <TableFingers/>
    
</template>

<script>
import TableFingers from '@/components/AdminDashboard/Fingers/TableFingers.vue/'
import ModalFingers from '@/components/AdminDashboard/Fingers/ModalFingers.vue/'
export default {
    name: 'Fingers',
    components: {
        TableFingers,
        ModalFingers
    }
}
</script>

<style scoped>

</style>