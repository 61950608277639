<template>
    <div class="container">
        <div class="row mt-4 justify-content-center">
            <div class="col text-center">
                <h1> Access Denied </h1>
                <p> You don't have the right permissions to access this page. <span style="cursor: pointer; color: blue;" @click="$router.push('Home')">Click here</span> to return to the home page.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}
</style>