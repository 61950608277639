import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import Tooltip from 'primevue/tooltip';
import ConfirmationService from 'primevue/confirmationservice';

import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';

import './theme/lara-light-purple/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import './assets/css.css'
import '/node_modules/primeflex/primeflex.css'



createApp(App).use(store)
.use(router)
.use(PrimeVue)
.use(ToastService)
.use(ConfirmationService)
.use(abilitiesPlugin, ability, {useGlobalProperties: true})
.use(abilitiesPlugin)
.directive('badge', BadgeDirective)
.directive('tooltip', Tooltip).mount('#app')
