import axios from 'axios'

const state = {
    custom_service: [],
    error_custom_service: []
}

const mutations = {
    SET_CUSTOM_SERVICE(state, custom_service) {
        state.custom_service = custom_service
    },
    ADD_CUSTOM_SERVICE(state, custom_service){
        state.custom_service.push(custom_service)
    },
    SET_ERROR_CUSTOM_SERVICE(state, error_custom_service) {
        state.error_custom_service = []
        state.error_custom_service.push(error_custom_service)
    },
    UPDATE_CUSTOM_SERVICE(state, custom_service) {
        state.custom_service = state.custom_service.map(updated_custom_service => {
            if(updated_custom_service.id === custom_service.id) {
                updated_custom_service.id_supplier = custom_service.id_supplier
                updated_custom_service.id_supplier_responsible = custom_service.id_supplier_responsible
                updated_custom_service.title = custom_service.title
                updated_custom_service.description = custom_service.description
            }
            return updated_custom_service
        })
    },
    DELETE_CUSTOM_SERVICE(state, id){
        let index = state.custom_service.findIndex(custom_service => custom_service.id == id)
        state.custom_service.splice(index, 1)
    },
    CLEAN_ERROR_CUSTOM_SERVICE(state) {
        state.error_custom_service = []
    },
}

const actions = {
	async load_custom_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'custom_services/get_service/' + id + '/') 
			.then((response) => {
				commit('SET_CUSTOM_SERVICE', response.data)
			})
	},
	async create_custom_service({commit}, custom_service) { 
        var responsible = ''
        if(custom_service.id_supplier_responsible == null) {
            responsible = ''
        } else {
            responsible = custom_service.id_supplier_responsible.id
        }
		await axios.post(process.env.VUE_APP_API_URL + 'custom_services/', {
            id_service_sheet: custom_service.id_service_sheet,
            id_supplier: custom_service.id_supplier.id,
            id_supplier_responsible: responsible,
            title: custom_service.title,
            description: custom_service.description,
        })  
			.then((response) => {
                commit('CLEAN_ERROR_CUSTOM_SERVICE')
                commit('ADD_CUSTOM_SERVICE', response.data)
			})
			.catch((error) => {
                commit('SET_ERROR_CUSTOM_SERVICE', error.response.data)
			})
	},
    async update_custom_service({commit}, custom_service) {
        var responsible = ''
        if(custom_service.id_supplier_responsible == null) {
            responsible = ''
        } else {
            responsible = custom_service.id_supplier_responsible.id
        }
        await axios.patch(process.env.VUE_APP_API_URL + 'custom_services/' + custom_service.id + '/', {
            id_supplier_responsible: responsible,
            id_supplier: custom_service.id_supplier.id,
            title: custom_service.title,
            description: custom_service.description,
        })
            .then((response) => {
                commit('CLEAN_ERROR_CUSTOM_SERVICE')
                commit('UPDATE_CUSTOM_SERVICE', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_CUSTOM_SERVICE', error.response.data)
            })
    },
    async delete_custom_service({commit}, id){
        await axios.delete(process.env.VUE_APP_API_URL + 'custom_services/' + id + '/')
            .then((response) => {
                commit('DELETE_CUSTOM_SERVICE', response.data)
            }) 
            .catch((error) => {
                commit('SET_ERROR_CUSTOM_SERVICE', error.response.data)
            })
    },
    async clean_errors_custom_service({commit}) {
        commit('CLEAN_ERROR_CUSTOM_SERVICE')
    }
       
}

const getters = {
    get_custom_service: (state) => state.custom_service,
    get_error_custom_service: (state) => state.error_custom_service
}

export default {
    state,
    mutations,
    actions,
    getters
};