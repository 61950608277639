<template>
    <div class="card">
        <div class="row">
            <Toast />
            <DataTable :value="get_currencies" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" scrollable
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading" removableSort
            :globalFilterFields="['name']"
            v-model:filters="filters">   
                <template #header>
                    <div class="row">
                        <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                            <span class="p-input-icon-left ">
                                <i class="pi pi-search" />
                                <InputText id="filter_currency" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                            </span>
                        </div>
                    </div>
                </template>
                <Column header="Name" field="name" :sortable="true"> 
                </Column>
                <Column header="Abbreviation" field="abbreviation" :sortable="true">
                </Column>
                <Column header="Symbol" field="symbol" :sortable="true">
                </Column>
                <Column v-if="$can('change','currency')" header="Edit" style="width: 7rem;" bodyStyle="overflow: visible">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_currency(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','currency')" header="Delete" style="width: 7rem;" bodyStyle="overflow: visible">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_currency(slotProps)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_currency_modal" v-model:visible="currency_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Currency Details" :modal="true" class="p-fluid">
        <form id="form_edit_currency" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-10 md:col-12">
                        <div class="p-float-field">
                            <label for="name" class="pb-1 ps-1"> <strong> Name </strong> </label>
                            <InputText id="name" v-model.trim="currency.name" autofocus :class="{'p-invalid': submitted && !currency.name || currency_errors.name}" />
                            <small class="p-error" v-if="submitted && !currency.name">Name is required.</small>
                            <small v-if="Object.keys(currency_errors).length !== 0 && currency_errors.name" class="p-error"><span v-for="error in currency_errors.name" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-10 md:col-12">
                        <div class="p-float-field">
                            <label for="abbreviation" class="pb-1 ps-1"> <strong> Abbreviation </strong> </label>
                            <InputText id="abbreviation" v-model.trim="currency.abbreviation"  autofocus/>
                            <small v-if="Object.keys(currency_errors).length !== 0 && currency_errors.abbreviation" class="p-error"><span v-for="error in currency_errors.abbreviation" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-10 md:col-12">
                        <div class="p-float-field">
                            <label for="symbol" class="pb-1 ps-1"> <strong> Symbol </strong> </label>
                            <InputText id="symbol" v-model.trim="currency.symbol" autofocus :class="{'p-invalid': submitted && !currency.symbol || currency_errors.symbol}" />
                            <small class="p-error" v-if="submitted && !currency.symbol">Symbol is required.</small>
                            <small v-if="Object.keys(currency_errors).length !== 0 && currency_errors.symbol" class="p-error"><span v-for="error in currency_errors.symbol" :key="error">{{error}}<br></span></small>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_currency_dialog"/>
            <Button data-cy="save_edit_currency" form="form_edit_currency" type="submit" label="Save" class="p-button-raised"/>
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_currency_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="currency">Are you sure you want to delete <b>{{currency.data.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_currency_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="delete_currency" />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import { FilterMatchMode } from 'primevue/api';
import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'TableCurrency',
    components: {
        DataTable,
        Column,
        Button,
        Dialog,
        InputText,
        Toast,
    },
    data() {
        return {
            id: '',
            currency: {},
            currency_errors:{},
            currency_dialog: false,
            delete_currency_dialog: false,
            submitted: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.get_currencies.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_currencies')
            this.loading = false
        }
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        get_currencies: function() {
            return this.$store.getters.get_currencies
        },
        get_currency_errors: function() {
            return this.$store.getters.get_currency_errors
        },
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        confirm_delete_currency(currency) {
            this.currency = currency
            this.delete_currency_dialog = true;
        },
        async delete_currency() {
            await this.$store.dispatch('delete_currency', this.currency.data.id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Currency deleted', life: 3000});
            this.delete_currency_dialog = false;
        },
        edit_currency(currency) {
            this.currency = {...currency};
            this.currency_dialog = true;
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

			if(this.currency.name.trim() && this.currency.symbol.trim()) {
                await this.$store.dispatch('update_currency', this.currency)
                this.currency_errors = this.get_currency_errors
                
                if(Object.keys(this.currency_errors).length === 0){
                    this.currency_dialog = false;
                    this.currency = {};
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Currency modified', life: 3000});
                }
            }
        },
        hide_currency_dialog() {
            this.currency_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            currency: {
                name: { required },
                symbol: { required },
            }
        }
    },

}
</script>

<style>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>