import axios from 'axios'

const state = {
    groups: [],
    group_errors:{},
    delete_status:false
};

const mutations = {
    SET_GROUPS(state, groups) {
        state.groups = groups
    },
    NEW_GROUP(state, groups) {  
        state.groups.push(groups)
        state.group_errors={}
    },
    DELETE_GROUP(state, id) {
        let index = state.groups.findIndex(groups => groups.id == id)
        state.groups.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_GROUP(state, group) {
        state.groups = state.groups.map(updated_group => {
            if(updated_group.id === group.id) {
                updated_group.name = group.name
            }
            return updated_group
        })
        state.group_errors={}
    },
    ERRORS_GROUP(state, errors){
        state.group_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};
const actions = {
    async load_all_groups({commit}) {        
        await axios.get(process.env.VUE_APP_API_URL  + "cruise_groups/")
            .then((response) => {
                commit('SET_GROUPS', response.data)
            })
    },
    async delete_group({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL  + 'cruise_groups/' + id)
            .then(() => {
                commit('DELETE_GROUP', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async create_group({commit}, group) {
        await axios.post(process.env.VUE_APP_API_URL  + 'cruise_groups/', group)
            .then((response) => {
                commit('NEW_GROUP', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_GROUP', error.response.data)
            })
    },
    async update_group({commit}, group) {
        await axios.put(process.env.VUE_APP_API_URL  + 'cruise_groups/' + group.id + '/',  { name: group.name })
            .then(() => {
                commit('UPDATE_GROUP', group)
            })
            .catch((error) => {
                commit('ERRORS_GROUP', error.response.data)
            })
    },
};

const getters = {
    all_groups: (state) => state.groups,
    get_errors_group: (state) => state.group_errors,
    get_delete_group_status:(state) => state.delete_status,
    get_group: (state) => (id) => {
        return state.groups.find(group => group.id === id)
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};