<template>
    <div class="container mt-4">
        <div class="row">
            <div class="col">
                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Information </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <span class="p-float-label">
                    <InputText v-model="group_name" @change="checkRegex"/>
                    <label> Group Name </label>
                </span>
                <small v-show="validationErrors.group_name && submitted" class="p-error">Group name is required.</small>
                <span v-if="!checkRegex(group_name) && group_name != '' && !this.validationErrors['group_name']"> <small class="p-error"> This group name is not valid </small> </span>
            </div>
        </div>    
    </div>  
    <div class="grid grid-nogutter justify-content-end mt-5">
        <Button label="Next" class="p-button-raised" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
    </div>   
</template>

<script>
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { computed } from 'vue';

export default {
    data() {
        return {
            group_name: '',
            validationErrors: [],
            submitted: false,
        }
    },
    computed: {
        regex: computed(() => /^[a-zA-Z0-9]+$/)
    },
    components: {
        Button,
        InputText
    },
    methods: {
        checkRegex(value) {
            return /^[a-zA-Z0-9]+$/.test(value);
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm() && this.checkRegex(this.group_name)) {
                this.$emit('next-page', { formData: { group_name: this.group_name }, pageIndex: 0 });
            }
        },
        validateForm() {
            if (!this.group_name) { 
                this.validationErrors['group_name'] = true;
            } else {
                delete this.validationErrors['group_name'];
            } 
            return !Object.keys(this.validationErrors).length;
        },
    }
}
</script>

<style scoped>

</style>