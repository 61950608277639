<template>
  <div class="home">
    <div class="container mt-5 ps-4 pe-4">
        <Profile v-if="load" :user="user"/>
    </div>
  </div>
</template>

<script>

import Profile from '../components/Profile/User_profile.vue'

export default {
  name:"User_profile",
  components: {
    Profile
  },
  computed:{
    get_current_user: function() {
      return this.$store.getters.get_current_user
    },
  },
  async mounted(){
    await this.$store.dispatch('get_current_user')
    this.user = this.get_current_user
    this.load=true
  },
  data(){
    return{
      user:[],
      load:false
    }
  }
}
</script>

<style scoped>
</style>