<template>
    <div class="card">
        <div class="row">
            <Toast/>
            <DataTable :value="all_passengers" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" scrollable 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading"
            :globalFilterFields="['first_name', 'last_name', 'dni_pass', 'id_call.id_ship.name', 'id_call.arrival_datetime']"
            v-model:filters="filters" >
                <template #empty>
                    <div class="col text-center">
                        No records found
                    </div>
                </template>
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                        </span>
                    </div>
                </template>
                <Column header="First name" field="first_name" :sortable="true"/>
                <Column header="Last name" field="last_name" :sortable="true"/>
                <Column header="DNI / PASSPORT" field="dni_pass" :sortable="true" style="max-width: 12rem;"/>
                <Column header="Call" field="id_call" :sortable="true">
                    <template #body="slotProps">
                        {{format_date(slotProps.data.id_call.arrival_datetime)}} -> {{slotProps.data.id_call.id_ship.name}}
                    </template>
                </Column>
                <Column v-if="$can('change','passenger')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_passenger(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','passenger')" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_passenger(slotProps)"/>
                    </template>
                </Column> 
            </DataTable>
        </div>
    </div>

    <Dialog v-model:visible="passenger_dialog" :style="{width: '50rem'}" header="Passenger Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="first_name" class="ps-1 pb-1"> <strong>First Name</strong> </label>
                        <InputText id="first_name" v-model.trim="passenger.first_name" required="true" autofocus :class="{'p-invalid': submitted && !passenger.first_name || passenger_errors.first_name}" />
                        <small v-if="Object.keys(passenger_errors).length !== 0 && passenger_errors.first_name" class="p-error"><span v-for="error in passenger_errors.first_name" :key="error">{{error}}<br></span></small>
                        <small class="p-error" v-if="submitted && !passenger.first_name">First name is required.</small>
                    </div>
                </div>
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="last_name" class="ps-1 pb-1"> <strong>Last Name</strong> </label>
                        <InputText id="last_name" v-model.trim="passenger.last_name" required="true" autofocus :class="{'p-invalid': submitted && !passenger.last_name || passenger_errors.last_name}" />
                        <small class="p-error" v-if="submitted && !passenger.last_name">Last name is required.</small>
                        <small v-if="Object.keys(passenger_errors).length !== 0 && passenger_errors.last_name" class="p-error"><span v-for="error in passenger_errors.last_name" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="dni_pass" class="ps-1 pb-1"> <strong>DNI/PASSPORT</strong> </label>
                        <InputText id="dni_pass" v-model.trim="passenger.dni_pass" required="true" autofocus :class="{'p-invalid': submitted && !passenger.dni_pass || passenger_errors.dni_pass}" />
                        <small v-if="Object.keys(passenger_errors).length !== 0 && passenger_errors.dni_pass" class="p-error"><span v-for="error in passenger_errors.dni_pass" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="call" class="ps-1 pb-1"> <strong> Call </strong>  </label>  
                        <Dropdown id="id_call" v-model="passenger.id_call" :options="all_calls" optionLabel="ship_agent" :filter="true">
                            <template #value="slotProps">
                                <div v-if="!slotProps.value">
                                    <span> - </span>
                                </div>
                                <div v-if="slotProps.value">
                                    <span>{{format_date(slotProps.value.arrival_datetime)}} - {{slotProps.value.id_ship.name}}</span>
                                </div>
                            </template>
                            <template #option="slotProps">
                                <div> {{format_date(slotProps.option.arrival_datetime)}} - {{slotProps.option.id_ship.name}} </div>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(passenger_errors).length !== 0 && passenger_errors.id_call" class="p-error"><span v-for="error in passenger_errors.id_call" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_passenger_dialog"/>
            <Button label="Save" @click="save_passenger" />
        </template>
        
    </Dialog>

    <Dialog v-model:visible="delete_passenger_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="passenger">Are you sure you want to delete <b>{{passenger.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_passenger_dialog = false"/>
            <Button label="Yes" @click="deletePassenger" />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'
import { FilterMatchMode } from 'primevue/api';
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: "TablePassengers",    
    components: {
        DataTable,
        Column,
        InputText,
        Button,
        Toast,
        Dialog,
        Dropdown
    },
    data() {
        return {
            passenger: {},
            passenger_errors: {},
            passenger_dialog: false,
            delete_passenger_dialog: false,
            submitted: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_passengers.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_passengers')
            this.loading = false
        }
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_passengers: function() {
            return this.$store.getters.all_passengers
        },
        all_calls: function(){
            return this.$store.getters.all_calls
        },
        get_passenger_errors: function() {
            return this.$store.getters.get_passenger_errors
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        format_date(value) {
            if(value instanceof Date){
                return value.toISOString().split('T')[0]
            } else {
                return value.split('T')[0]
            }
        },
        confirm_delete_passenger(passenger) {
            this.passenger = passenger.data;
            this.delete_passenger_dialog = true;
        },
        deletePassenger() {
            this.$store.dispatch('delete_passenger', this.passenger.id)
            this.delete_passenger_dialog = false;
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Passenger deleted', life: 3000});
        },
        edit_passenger(passenger) {
            this.passenger = {...passenger};
            this.passenger_dialog = true;
            
        },
        async save_passenger() {
            this.submitted = true;
			if (this.passenger.first_name.trim()) {

                await this.$store.dispatch('update_passenger', this.passenger)
                this.passenger_errors = this.get_passenger_errors
                if(Object.keys(this.passenger_errors).length === 0){
                    this.hide_passenger_dialog()
                    this.passenger = {};
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Passenger modified', life: 3000});
                }
            }
        },
        hide_passenger_dialog() {
            this.passenger_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            first_name: { required },
            last_name: { required },
            dni_pass: { required },
            id_call: { required },

        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>