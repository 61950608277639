<template>
    <ModalWaterMeters/>
    <TableWaterMeters/>
</template>

<script>
import TableWaterMeters from '@/components/AdminDashboard/WaterMeters/TableWaterMeters.vue'
import ModalWaterMeters from '@/components/AdminDashboard/WaterMeters/ModalWaterMeters.vue'
export default {
  name: 'Water_meters',
  components: {
    TableWaterMeters,
    ModalWaterMeters
  }
}
</script>

<style scoped>
</style> 
