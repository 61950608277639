import axios from 'axios'

const state = {
    incidence_subcategories: [],
    incidence_subcategories_errors:{},
    delete_status:false,
    subcategory_names: []
};

const mutations = {
    SET_INCIDENCE_SUBCATEGORIES(state, incidence_subcategories) {
        state.incidence_subcategories = incidence_subcategories
    },
    SET_INCIDENCE_SUBCATEGORIES_NAMES(state, incidence_subcategories_names) {
        state.subcategory_names = incidence_subcategories_names
    },
    NEW_INCIDENCE_SUBCATEGORY(state, incidence_subcategory) {  
        state.incidence_subcategories.push(incidence_subcategory)
        state.incidence_subcategories_errors={}
    },
    DELETE_INCIDENCE_SUBCATEGORY(state, id) {
        let index = state.incidence_subcategories.findIndex(incidence_subcategories => incidence_subcategories.id == id)
        state.incidence_subcategories.splice(index, 1)
        state.delete_status=false
    },
    UPDATE_INCIDENCE_SUBCATEGORY(state, incidence_subcategory) {
        state.incidence_subcategories = state.incidence_subcategories.map(updated_incidence_subcategories => {
            if(updated_incidence_subcategories.id === incidence_subcategory.id) {
                updated_incidence_subcategories.title = incidence_subcategory.title
                updated_incidence_subcategories.description = incidence_subcategory.description
                updated_incidence_subcategories.incidence_type = incidence_subcategory.incidence_type
            }
            return updated_incidence_subcategories
        })
        state.incidence_subcategories_errors={}
    },
    ERRORS_INCIDENCE_SUBCATEGORY(state, errors){
        state.incidence_subcategories_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};
const actions = {
    async load_all_incidence_subcategories({commit}) {  
        let names = []      
        await axios.get(process.env.VUE_APP_API_URL  + "incidence_subcategories/")
            .then((response) => {
                commit('SET_INCIDENCE_SUBCATEGORIES', response.data)
                response.data.forEach(element => {
                    names.push(element.title)
                });
                commit('SET_INCIDENCE_SUBCATEGORIES_NAMES', names)
            })
    },
    async delete_incidence_subcategory({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL  + 'incidence_subcategories/' + id)
            .then(() => {
                commit('DELETE_INCIDENCE_SUBCATEGORY', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async create_incidence_subcategory({commit}, incidence_subcategories) {
        await axios.post(process.env.VUE_APP_API_URL  + 'incidence_subcategories/', incidence_subcategories)
            .then((response) => {
                
                commit('NEW_INCIDENCE_SUBCATEGORY', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_INCIDENCE_SUBCATEGORY', error.response.data)
            })
    },
    async update_incidence_subcategory({commit}, incidence_subcategory) {
        await axios.put(process.env.VUE_APP_API_URL  + 'incidence_subcategories/' + incidence_subcategory.id + '/',  { title: incidence_subcategory.title, description: incidence_subcategory.description, incidence_type: incidence_subcategory.incidence_type })
            .then(() => {
                commit('UPDATE_INCIDENCE_SUBCATEGORY', incidence_subcategory)
            })
            .catch((error) => {
                
                commit('ERRORS_INCIDENCE_SUBCATEGORY', error.response.data)
            })
    },
};

const getters = {
    all_incidence_subcategories: (state) => state.incidence_subcategories,
    get_errors_incidence_subcategory: (state) => state.incidence_subcategories_errors,
    get_delete_incidence_subcategory_status:(state) => state.delete_status,
    general_incidences_subcategories: (state) => {
        return state.incidence_subcategories.filter(incidence_subcategory => incidence_subcategory.incidence_type === 'General' || incidence_subcategory.incidence_type === 'Both')
    },
    call_incidences_subcategories: (state) => {
        return state.incidence_subcategories.filter(incidence_subcategory => incidence_subcategory.incidence_type === 'Call' || incidence_subcategory.incidence_type === 'Both')
    },
    get_subcategory_names:(state) => state.subcategory_names
};

export default {
    state,
    mutations,
    actions,
    getters
};