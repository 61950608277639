<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div v-if="$can('view','contract') " class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Contracts </h1>
            <div v-if="$can('add','contract')" class="card p-1">
                <Button data-cy="new_contract_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Contract'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_contract_modal" header="New Contract" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '40vw'}">
            <div class="container">
                <form id="form" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid mt-2">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <InputText id="name"  v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted || contract_errors.name}"/>
                                <label for="name" :class="{'p-error':submitted && contract_errors.name}"> Name* </label>
                                <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.name" class="p-error"><span v-for="error in contract_errors.name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 md:col-12 pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_suplier" id="id_supplier" v-model="v$.id_supplier.$model"  :options="all_suppliers" optionLabel="name" :filter="true" :class="{'p-invalid':v$.id_supplier.$invalid && submitted || contract_errors.id_supplier}"/>
                                <label for="id_supplier" :class="{'p-error':v$.id_supplier.$invalid && submitted || contract_errors.id_supplier}">Supplier*</label>
                                <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.id_supplier" class="p-error"><span v-for="error in contract_errors.id_supplier" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.id_supplier.$invalid && submitted) || v$.id_supplier.$pending.$response" class="p-error">{{v$.id_supplier.required.$message.replace('Value', 'Supplier')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 md:col pt-3">
                            <div class="p-float-label">     
                                <Calendar data-cy="start_date" autocomplete="off" id="start_date" dateFormat="yy-mm-dd" v-model="v$.start_date.$model" :class="{'p-invalid':v$.start_date.$invalid && submitted || contract_errors.start_date}"/>
                                <label for="start_date" :class="{'p-error':v$.start_date.$invalid && submitted } "> Start date* </label>
                                <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.start_date" class="p-error"><span v-for="error in contract_errors.start_date" :key="error">{{error}}<br></span></small>                       
                            </div>
                            <small v-if="(v$.start_date.$invalid && submitted) || v$.start_date.$pending.$response" class="p-error">{{v$.start_date.required.$message.replace('Value', 'Start Date')}}</small>
                        </div>
                        <div class="col-12 md:col pt-3">
                            <div class="p-float-label">     
                                <Calendar data-cy="end_date" autocomplete="off" id="end_date" dateFormat="yy-mm-dd" v-model="v$.end_date.$model" :class="{'p-invalid':v$.end_date.$invalid && submitted || contract_errors.end_date}"/>
                                <label for="end_date" :class="{'p-error':v$.end_date.$invalid && submitted } "> End date* </label>
                                <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.end_date" class="p-error"><span v-for="error in contract_errors.end_date" :key="error">{{error}}<br></span></small>
                            </div>                   
                            <small v-if="(v$.end_date.$invalid && submitted) || v$.end_date.$pending.$response" class="p-error">{{v$.end_date.required.$message.replace('Value', 'End Date')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 md:col pt-3">
                            <div class="p-float-label">     
                                <Calendar data-cy="rescission_date" autocomplete="off" id="rescission_date" dateFormat="yy-mm-dd" v-model="rescission_date" :class="{'p-invalid':contract_errors.rescission_date}"/>
                                <label for="rescission_date" :class="{'p-error':contract_errors.rescission_date} "> Rescission date </label>
                                <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.rescission_date" class="p-error"><span v-for="error in contract_errors.rescission_date" :key="error">{{error}}<br></span></small>  
                            </div>                 
                        </div>
                    </div>
                    
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <TextArea id="contract_details" v-model="contract_details" :class="{'p-invalid':submitted && contract_errors.contract_details}" />
                                <label for="contract_details" :class="{'p-error':submitted && contract_errors.contract_details}"> Contract details </label>
                                <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.contract_details" class="p-error"><span v-for="error in contract_errors.contract_details" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 md:col-12 text-center">
                            <div class="p-float-field">
                                <label for="contractUploader" class="pb-1">Contract* </label>
                                <FileUpload ref="fileUpload" id="contractUploader" mode="basic" name="demo[]" :customUpload="true" accept="image/*,application/pdf" :auto="true" :maxFileSize="100000000" @uploader="onUploadContract"/>
                                <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.contract" class="p-error"><span v-for="error in contract_errors.contract" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.contract.$invalid && submitted) || v$.contract.$pending.$response" class="p-error">{{v$.contract.required.$message.replace('Value', 'Contract')}}</small>
                            <div>{{ contract_name }}</div>
                        </div>
                        
                    </div>
                </form> 
            </div>
            <template #footer>
                <Button data-cy="create_contract_button" form="form" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import Toast from 'primevue/toast'
import Calendar from 'primevue/calendar'
import TextArea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"
import FileUpload from "primevue/fileupload"

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        Dropdown,
        Calendar,
        Toast,
        TextArea,
        InputText,
        FileUpload,
    },
    data() {
        return {
            displayModal: false,
            id_supplier: null,
            contract:'',
            name: null,
            start_date:null,
            end_date:null,
            rescission_date:null,
            contract_details:'',
            contract_errors:{},
            contract_name: '',
            color: localStorage.getItem('primary_color'),
            submitted: false,
            showMessage: false,
        }
    },
    created() {
        this.$store.dispatch('load_all_suppliers')
    },
    computed: {
        all_suppliers: function() {
            return this.$store.getters.all_suppliers
        },
        get_errors_contract: function() {
            return this.$store.getters.get_errors_contract
        }
    },
    methods: {
        onUploadContract(e){
            this.contract = e.files[0];
            this.contract_name = e.files[0].name
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_contract() {
            let contracts = { 
                name: this.name,
                contract: this.contract,
                id_supplier: this.id_supplier,
                start_date: this.start_date,
                end_date: this.end_date,
                rescission_date: this.rescission_date,
                contract_details: this.contract_details
            }
            
            await this.$store.dispatch('create_contract', contracts)
            this.contract_errors = this.get_errors_contract
            if(Object.keys(this.contract_errors).length === 0){
                this.toggleDialog();
                this.resetForm()
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }
            
            let res = await this.add_contract()

            if(res){
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Contract Added', life: 3000});
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.name = null;
            this.contract = '';
            this.contract_name = ''
            this.id_supplier = null;
            this.start_date = null;
            this.end_date = null;
            this.rescission_date = null;
            this.contract_details='';
            this.submitted = false;
        }
    },
    validations() {
        return {
            name: { required },
            contract: { required },
            id_supplier: { required },
            start_date:{ required },
            end_date:{ required },
        }
    },
};
</script>

<style scoped>
.required_field{
    color: v-bind(color)
}
</style>