<template>
    <div class="mt-4 p-0">
        <div class="row">
            <div class="col-12 text-right p-0">
                <Dropdown id="ports" v-model="selectedPort" :options="get_all_ports" optionLabel="name" :filter="true" :placeholder="defaultPort" @change="load_page_bills($event)">
                    <template #option="slotProps">
                        <div><img :alt="slotProps.option.name" :src="slotProps.option.logo" class="img img-fluid mr-2" style="width: 40px" /> {{slotProps.option.name}}</div>
                    </template>
                    <template #value="slotProps">
                        <div v-if="!selectedPort">
                            <span> {{slotProps.placeholder}} </span>
                        </div>
                        <div v-else-if="slotProps.value">

                            <div><img :alt="slotProps.value.name" :src="slotProps.value.logo" class="img img-fluid mr-2" style="width: 40px" /> {{slotProps.value.name}}</div>
                        </div>
                    </template>
                </Dropdown>
            </div>
        </div>
    </div>
    <div class="row mt-4 responsive_width">
        <div class="card p-0" >
            <BillingTable :get_all_bills="get_validated_bills" :loading="loading" :accounting="true" :validating_table="true"/>
        </div>
    </div>
</template>

<script>
import BillingTable from '../../components/Billing/Billing_table.vue'
import Dropdown from 'primevue/dropdown'

export default {
    name: 'Billing_dashboard',
    components: {
        BillingTable,
        Dropdown,
    },
    data(){
        return {
            loading: false,
            selectedPort: '',
            defaultPort:localStorage.getItem('user_port')
        }
    },
    computed: {
        get_validated_bills: function() {
            return this.$store.getters.get_validated_bills
        },
        get_all_ports: function() {
            return this.$store.getters.all_ports
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        }
    },
    async mounted() {
        this.loading = true
        await this.$store.dispatch('load_all_ports')
        await this.$store.dispatch('load_validated_bills')
        this.loading = false
    },
    methods: {
        async load_page_bills(e) {
            this.loading = true
            await this.$store.dispatch('load_validated_bills_by_port', e.value.id)
            this.loading = false
        },
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

:deep(.p-tabview-nav){
    justify-content: flex-start;
    border-bottom: 2px solid #dee2e6 !important;
}

@media (max-width: 1301px) {
    .responsive_width{
        width: 95vw !important; 
    }
}

.responsive_width{
    width: calc(100vw - 23rem )
}

</style>