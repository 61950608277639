<template>
    <div class="row pt-1 pb-2 ">
        <div class="col-12 md:col-2 lg:col text-center">
            <span v-if="service_sheet.id_call">
                <span> Nº <strong>{{service_sheet.id_call.number}} </strong></span>
            </span>
            
        </div>
        <div class="col-6 md:col-3 lg:col text-center">                    
            <span v-if="Object.prototype.toString.call(service_sheet.arrival_datetime) === '[object Date]'"> 
                <i class="pi pi-calendar me-1" style="color: #22C55E;"></i> {{format_date(service_sheet.arrival_datetime)}}
            </span>
            <span v-else-if="service_sheet.arrival_datetime">
                <i class="pi pi-calendar me-1" style="color: #22C55E;"></i> {{service_sheet.arrival_datetime}}
            </span>
            <span v-else-if="Object.prototype.toString.call(service_sheet.estimated_arrival_datetime) === '[object Date]'" >
                <i class="pi pi-calendar me-1" style="color: #22C55E;"></i> {{format_date(service_sheet.estimated_arrival_datetime)}} 
            </span>
            <span v-else-if="service_sheet.estimated_arrival_datetime" >
                <i class="pi pi-calendar me-1" style="color: #22C55E;"></i> {{service_sheet.estimated_arrival_datetime}}
            </span>
        </div>
        <div class="col-6 md:col-2 lg:col text-center">
            <span v-if="Object.prototype.toString.call(service_sheet.arrival_datetime) === '[object Date]'"> 
                <i class="pi pi-clock me-1" style="color: #22C55E;"> </i> {{moment(service_sheet.arrival_datetime_hour)}} 
            </span>
            <span v-else-if="service_sheet.arrival_datetime">
                <i class="pi pi-clock me-1" style="color: #22C55E;"> </i> {{moment(service_sheet.arrival_datetime_hour)}} 
            </span>
            <span v-else-if="Object.prototype.toString.call(service_sheet.estimated_arrival_datetime) === '[object Date]'" >
                <i class="pi pi-clock me-1" style="color: #22C55E;"> </i> {{moment(service_sheet.estimated_arrival_datetime_hour)}} 
            </span>
            <span v-else-if="service_sheet.estimated_arrival_datetime" >
                <i class="pi pi-clock me-1" style="color: #22C55E;"> </i> {{moment(service_sheet.estimated_arrival_datetime_hour)}} 
            </span>
        </div>
        <div class="col-6 md:col-3 lg:col text-center">
            <span v-if="Object.prototype.toString.call(service_sheet.departure_datetime) === '[object Date]'"> 
                <i class="pi pi-calendar me-1" style="color: #EF4444;"></i> {{format_date(service_sheet.departure_datetime)}}
            </span>
            <span v-else-if="service_sheet.departure_datetime">
                <i class="pi pi-calendar me-1" style="color: #EF4444;"></i> {{service_sheet.departure_datetime}}
            </span>
            <span v-else-if="Object.prototype.toString.call(service_sheet.estimated_departure_datetime) === '[object Date]'">
                <i class="pi pi-calendar me-1" style="color: #EF4444;"></i> {{format_date(service_sheet.estimated_departure_datetime)}} 
            </span>
            <span v-else-if="service_sheet.estimated_departure_datetime">
                <i class="pi pi-calendar me-1" style="color: #EF4444;"></i> {{service_sheet.estimated_departure_datetime}}
            </span>
            <span v-else>
                -
            </span>
        </div>
        <div class="col-6 md:col-2 lg:col text-center">
            <span v-if="Object.prototype.toString.call(service_sheet.departure_datetime) === '[object Date]'"> 
                <i class="pi pi-clock me-1" style="color: #EF4444;"> </i> {{moment(service_sheet.departure_datetime_hour)}}
            </span>
            <span v-else-if="service_sheet.departure_datetime">
                <i class="pi pi-clock me-1" style="color: #EF4444;"> </i> {{moment(service_sheet.departure_datetime_hour)}}
            </span>
            <span v-else-if="Object.prototype.toString.call(service_sheet.estimated_departure_datetime) === '[object Date]'">
                <i class="pi pi-clock me-1" style="color: #EF4444;"> </i> {{moment(service_sheet.estimated_departure_datetime_hour)}}
            </span>
            <span v-else-if="service_sheet.estimated_departure_datetime">
                <i class="pi pi-clock me-1" style="color: #EF4444;"> </i> {{moment(service_sheet.estimated_departure_datetime_hour)}}
            </span>
            <span v-else>
                -
            </span>
        </div>
        <div class="col-6 md:col-6 lg:col text-center">
            <span v-if="service_sheet.id_terminal">
                <i class="fa fa-warehouse me-1"></i> {{ service_sheet.id_terminal.name }} 
            </span>
        </div>
        <div class="col-6 md:col-6 lg:col text-center">
            <span>
                <i class="fa fa-ship me-1"></i> {{ ship.name }}
            </span>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'Sticky Dates',
    props: {
        service_sheet: {
            type: Object,
            required: true
        },
        ship: {
            type: Object,
            required: true
        },
    },
    methods: {
        format_date(value){
            return moment(value).format().split('T')[0]
        },
        moment: function(date) {
            if(date == null) {
                return null
            } else if(typeof(date) === "object") {
                return moment(date).format().split('T')[1].split(':')[0] + ':' + moment(date).format().split('T')[1].split(':')[1]
            } else {
                return date
            } 
        },
    }
}
</script>

<style scoped>

</style>