<template>
    <ModalSuppliers/>
    <TableSuppliers/>
</template>

<script>
import TableSuppliers from '@/components/AdminDashboard/Suppliers/TableSuppliers.vue/'
import ModalSuppliers from '@/components/AdminDashboard/Suppliers/ModalSuppliers.vue/'
export default {
    name: 'Suppliers',
    components: {
        TableSuppliers,
        ModalSuppliers
    }
}
</script>

<style scoped>

</style>