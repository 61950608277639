<template>
    <ModalUsers/>
    <TableUsers/>
</template>

<script>
import TableUsers from '@/components/AdminDashboard/Users/TableUsers.vue'
import ModalUsers from '@/components/AdminDashboard/Users/ModalUsers.vue'
export default {
  name: 'Users',
  components: {
    TableUsers,
    ModalUsers
  }
}
</script>

<style scoped>
</style> 
