<template>
    <ModalCard/>
    <TableCard/>
</template>

<script>
import TableCard from '@/components/AdminDashboard/Card/TableCard.vue'
import ModalCard from '@/components/AdminDashboard/Card/ModalCard.vue'
export default {
  name: 'Cruise_lines',
  components: {
    TableCard,
    ModalCard
  }
}
</script>

<style scoped>
</style>