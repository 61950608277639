<template>
	<div class="container">
        <div class="row">
            <div class="col-12 md:col-4 text-center">
                <h5>Username</h5>
                <Dropdown v-model="user_dropdown" :options="users_by_port" optionLabel="username" @change="filter_logs()" showClear/>
            </div>
            <div class="col-12 md:col-4 text-center">
                <h5>Field</h5>
                <Dropdown v-model="field_dropdown" :filter="true" :options="alias_list" optionLabel="alias" @change="filter_logs()" showClear/>
            </div>
            <div class="col-12 md:col-4 text-center">
                <h5>Date</h5>
                <Calendar autocomplete="off" v-model="date_filter" selectionMode="range" @date-select="filter_logs()" @clear-click="filter_logs()" showButtonBar/>
            </div>
        </div>
		<div class="row text-center pt-2">
			<div v-if="get_lazy_logs" class="col">
				<Paginator v-model:first="first" :rows="10" :totalRecords="get_filtered_logs.length" :rowsPerPageOptions="[10,20,30]"
					template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
					@page="onPage($event)">
				</Paginator>
				<div v-if="loading">
					<i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
				</div>
				<div v-else>
					<Timeline :value="full_logs" align="alternate" class="pt-2 pb-5 customized-timeline">
						<template #marker="slotProps">
							<span class="custom-marker" :style="{backgroundColor: slotProps.item.color}">
								<i :class="slotProps.item.icon"></i>
							</span>
						</template>
						<template #content="slotProps">
							<Card>
								<template #title>
									<div v-if="slotProps.item.icon == 'fa fa-envelope'">
										<strong style="color: orange;"> Action</strong> Service Mail Update
									</div>
									<div v-if="slotProps.item.icon == 'fa fa-pencil'">
										<strong> User</strong> {{slotProps.item.user}}
										<br>
										<strong style="color: #002D72;"> Action</strong> Updated
									</div>
									<div v-if="slotProps.item.icon == 'fa fa-trash'">
										<strong> User</strong> {{slotProps.item.user}}
										<br>
										<strong style="color: #EF4444;"> Action</strong> Deleted
									</div>
									<div v-if="slotProps.item.icon == 'fa fa-check'">
										<strong> User</strong> {{slotProps.item.user}} 
										<br>
										<strong style="color: #22C55E;"> Action</strong> Created
									</div>
								</template>
								<template #subtitle>
									{{slotProps.item.date}}
								</template>
								<template #content>
									{{test(slotProps.item.status)}}
								</template>
							</Card>
						</template>
					</Timeline>
				</div>
                <Paginator v-model:first="first" :rows="10" :totalRecords="get_filtered_logs.length" :rowsPerPageOptions="[10,20,30]"
					template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
					@page="onPage($event)">
				</Paginator>
			</div>
		</div>
	</div>
</template>

<script>
import Timeline from 'primevue/timeline'
import Paginator from 'primevue/paginator'
import Card from 'primevue/card'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'

export default {
	name:"Service_sheet_log",
	components: {
		Timeline,
		Paginator,
		Card,
        Dropdown,
        Calendar
	},
	data() {
		return {
			color: localStorage.getItem('primary_color'),
			full_logs: [],
			log: {'status': '', 'date': '', 'icon': '', 'color': '', 'user': ''},
			username: '',
			first: 0,
			field_changed_list: [],
			payload_filter_logs: [],
            user_dropdown: null,
            field_dropdown: null,
            date_filter: null,
			loading: true,
			alias_list: [
				{ name: 'over_night', alias:'over night' },
				{ name: 'weekend', alias:'weekend' },
				{ name: 'over_time', alias:'over time' },
				{ name: 'e_p_embarking', alias:'estimated passenger embarking' },
				{ name: 'e_p_disembarking', alias:'estimated passenger disembarking' },
				{ name: 'e_p_transit', alias:'estimated passenger transit' },
				{ name: 'e_c_embarking', alias:'estimated crew embarking' },
				{ name: 'e_c_disembarking', alias:'estimated crew disembarking' },
				{ name: 'e_c_transit', alias:'estimated crew transit' },
				{ name: 'p_embarking', alias:'passenger embarking' },
				{ name: 'p_disembarking', alias:'passenger disembarking' },
				{ name: 'p_transit', alias:'passenger transit' },
				{ name: 'c_embarking', alias:'crew embarking' },
				{ name: 'c_disembarking', alias:'crew disembarking' },
				{ name: 'c_transit', alias:'crew transit' },
				{ name: 'module_start', alias:'module start' },
				{ name: 'module_end', alias:'module end' },
				{ name: 'estimated_arrival_datetime', alias: 'estimated arrival datetime'},
				{ name: 'estimated_departure_datetime', alias: 'estimated departure datetime'},
				{ name: 'arrival_datetime', alias: 'arrival datetime'},
				{ name: 'departure_datetime', alias: 'departure datetime'},
				{ name: 'id_terminal', alias: 'terminal'},
				{ name: 'pier', alias: 'pier'},
				{ name: 'quantity', alias: 'quantity'},
				{ name: 'service_type', alias: 'service type'},
				{ name: 'start_datetime', alias: 'start datetime'},
				{ name: 'end_datetime', alias: 'end datetime'},
				{ name: 'status', alias: 'status'},
				{ name: 'position', alias: 'ship position'},
				{ name: 'ship_responsible', alias: 'ship responsible'},
				{ name: 'fax', alias: 'fax'},
				{ name: 'phone', alias: 'phone'},
				{ name: 'ship_agent', alias: 'ship agent'},
				{ name: 'id_ship', alias: 'ship'},
				{ name: 'delivery_note', alias: 'delivery note'},
				{ name: 'delivery note date', alias: 'delivery note date'},
                { name: 'id_supplier', alias: 'supplier'}
			],
			alias_services_list: [
				{ name: 'Porter', alias: 'porters service'},
				{ name: 'Porter_service', alias: 'shifts in porters service'},
				{ name: 'Service_sheet', alias: 'service sheet'},
				{ name: 'Ticket_stand', alias: 'ticket stand'},
				{ name: 'Medical_room', alias: 'medical room'},
				{ name: 'Cleaning', alias: 'cleaning service'},
				{ name: 'Cleaning_service', alias: 'shifts in cleaning service'},
				{ name: 'Terminal_security', alias: 'terminal security service'},
				{ name: 'Terminal_security_service', alias: 'shifts in terminal security service'},
				{ name: 'Fence_security', alias: 'fence security service'},
				{ name: 'Fence_security_service', alias: 'shifts in fence security service'},
				{ name: 'K9', alias: 'k9 service'},
				{ name: 'K9_service', alias: 'shifts in k9 service'},
				{ name: 'Custodian', alias: 'custodian service'},
				{ name: 'Wifi', alias: 'wifi service'},
				{ name: 'Shuttle', alias: 'shuttle service'},
				{ name: 'Shuttle_service', alias: 'shifts in shuttle service'},
				{ name: 'Service_sheet_pdfs', alias: 'Signed service sheet pdf'},
			],
			alias_list_log: ''
		}
	},
	computed: {
		get_logs: function() {
			return this.$store.getters.get_logs
		},
		get_lazy_logs: function() {
			return this.$store.getters.get_lazy_logs
		},
        users_by_port: function() {
            return this.$store.getters.users_by_port
        },
        get_filtered_logs: function() {
            return this.$store.getters.get_filtered_logs
        }
	},
	async created() {
        await this.$store.dispatch('load_users_by_port')
		await this.$store.dispatch('load_logs', (Number(this.$route.params.sid)))
		await this.$store.dispatch('empty_filter_logs')
		this.full_logs = []
		await this.payload_filter_logs.push(Number(this.$route.params.sid), 0, 10)
		await this.$store.dispatch('lazy_logs', this.payload_filter_logs )
		this.payload_filter_logs = []
		

		for(var i in this.get_lazy_logs) {
			if(this.get_lazy_logs[i].id_user) {
				this.log.date = this.get_lazy_logs[i].modification_date.split('T')[0] + ' ' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[0] + ':' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[1]
				if(this.get_lazy_logs[i].log_type == 'Created') {
					this.log.user =  this.get_lazy_logs[i].id_user.username
					let alias_status = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name) 
					if(alias_status != null){
						alias_status = alias_status.alias	
					}else{
						alias_status = this.get_lazy_logs[i].table_name
					}
					this.log.status = alias_status
					this.log.icon = 'fa fa-check'
					this.log.color = '#22C55E'
				} else if (this.get_lazy_logs[i].log_type == 'Updated') { 
					if(this.get_lazy_logs[i].field_changed) {
						var test = Array.from(this.get_lazy_logs[i].field_changed)
						var cont = 0
						var word = ''
						for(var k in test) {
							if(test[k] != '[' && test[k] != ']') {
								if(test[k] == "'") {
									cont++
								} else {
									if(cont == 2) {
										cont = 0
									} else {
										if(test[k] === ' '){
											let new_alias = this.alias_list.find(element => element.name == word)
											if(new_alias != null){
												new_alias = new_alias.alias	
											}else{
												new_alias = this.get_lazy_logs[i].table_name
											}
											this.alias_list_log  += new_alias
											this.alias_list_log += ', '
											word = ''
										} else {
											word += test[k]
										}
									}
								}
							} 
						
						}
						
						if(word) {
							let find_alias =  this.alias_list.find(element => element.name == word)
							if(find_alias != null){
								find_alias = find_alias.alias	
							}else{
								find_alias = word
							}
							this.alias_list_log += find_alias
							
							//this.alias_list_log += this.alias_list.find(element => element.name == word).alias
							this.log.user = this.get_lazy_logs[i].id_user.username
							let alias_service = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
							if(alias_service != null){
								alias_service = alias_service.alias	
							}else{
								alias_service = this.get_lazy_logs[i].table_name
							}

							this.log.status = this.alias_list_log + ' in ' + alias_service
							

						} else {
							

							this.log.user = this.get_lazy_logs[i].id_user.username
							let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
							if(new_alias != null){
								new_alias = new_alias.alias	
							}else{
								new_alias = this.get_lazy_logs[i].table_name
							}
							this.log.status = new_alias

						}

						this.alias_list_log = ''
						
					} else {
						
						this.log.user = this.get_lazy_logs[i].id_user.username
						let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
						if(new_alias != null){
							new_alias = new_alias.alias	
						}else{
							new_alias = this.get_lazy_logs[i].table_name
						}
						this.log.status = new_alias
					}
					
					
					this.log.icon = 'fa fa-pencil'
					this.log.color = '#002D72'

				} else if (this.get_lazy_logs[i].log_type == 'Deleted') {
					this.log.user = this.get_lazy_logs[i].id_user.username
					let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
					if(new_alias != null){
						new_alias = new_alias.alias	
					}else{
						new_alias = this.get_lazy_logs[i].table_name
					}
					this.log.status = new_alias
					this.log.icon = 'fa fa-trash'
					this.log.color = '#EF4444'
				}
				this.full_logs.push(this.log)
				this.log = {'status': '', 'date': ''}

			} else {
				this.log.date = this.get_lazy_logs[i].modification_date.split('T')[0] + ' ' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[0] + ':' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[1]
				let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
				if(new_alias != null){
					new_alias = new_alias.alias	
				}else{
					new_alias = this.get_lazy_logs[i].table_name
				}
				this.log.status = new_alias + ' status'
				this.log.icon = 'fa fa-envelope'
				this.log.color = 'orange'
				this.full_logs.push(this.log)
				this.log = {'status': '', 'date': ''}
			}
			
		}

		this.loading = false

	},
	methods: {
		async onPage(event){
            
            await this.$store.dispatch('empty_filter_logs')
			this.full_logs = []
			this.loading = true

            var user_logs = null
            if (this.user_dropdown){
                user_logs = this.user_dropdown.username
            }
            var filter = {
                user: user_logs,
                date: this.date_filter,
                field: this.field_dropdown
            }

            await this.$store.dispatch('filter_logs', filter)   

			

			await this.payload_filter_logs.push(Number(this.$route.params.sid), event.first, event.rows)
			await this.$store.dispatch('lazy_logs', this.payload_filter_logs)

			this.payload_filter_logs = []
			for(var i in this.get_lazy_logs) {
                if(this.get_lazy_logs[i]) {
                    if(this.get_lazy_logs[i].id_user) {
                        this.log.date = this.get_lazy_logs[i].modification_date.split('T')[0] + ' ' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[0] + ':' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[1]
                        if(this.get_lazy_logs[i].log_type == 'Created') {
                            this.log.user =  this.get_lazy_logs[i].id_user.username
                            let alias_status = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name) 
                            if(alias_status != null){
                                alias_status = alias_status.alias	
                            }else{
                                alias_status = this.get_lazy_logs[i].table_name
                            }
                            this.log.status = alias_status
                            this.log.icon = 'fa fa-check'
                            this.log.color = '#22C55E'
                        } else if (this.get_lazy_logs[i].log_type == 'Updated') { 
                            if(this.get_lazy_logs[i].field_changed) {
                                var test = Array.from(this.get_lazy_logs[i].field_changed)
                                var cont = 0
                                var word = ''
                                for(var k in test) {
                                    if(test[k] != '[' && test[k] != ']') {
                                        if(test[k] == "'") {
                                            cont++
                                        } else {
                                            if(cont == 2) {
                                                cont = 0
                                            } else {
                                                if(test[k] === ' '){
                                                    let new_alias = this.alias_list.find(element => element.name == word)
                                                    if(new_alias != null){
                                                        new_alias = new_alias.alias	
                                                    }else{
                                                        new_alias = this.get_lazy_logs[i].table_name
                                                    }
                                                    this.alias_list_log  += new_alias
                                                    this.alias_list_log += ', '
                                                    word = ''
                                                } else {
                                                    word += test[k]
                                                }
                                            }
                                        }
                                    } 
                                
                                }
                                
                                if(word) {
                                    let find_alias =  this.alias_list.find(element => element.name == word)
                                    if(find_alias != null){
                                        find_alias = find_alias.alias	
                                    }else{
                                        find_alias = word
                                    }
                                    this.alias_list_log += find_alias
                                    
                                    //this.alias_list_log += this.alias_list.find(element => element.name == word).alias
                                    this.log.user = this.get_lazy_logs[i].id_user.username
                                    let alias_service = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
                                    if(alias_service != null){
                                        alias_service = alias_service.alias	
                                    }else{
                                        alias_service = this.get_lazy_logs[i].table_name
                                    }

                                    this.log.status = this.alias_list_log + ' in ' + alias_service
                                    

                                } else {
                                    

                                    this.log.user = this.get_lazy_logs[i].id_user.username
                                    let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
                                    if(new_alias != null){
                                        new_alias = new_alias.alias	
                                    }else{
                                        new_alias = this.get_lazy_logs[i].table_name
                                    }
                                    this.log.status = new_alias

                                }

                                this.alias_list_log = ''
                                
                            } else {
                                
                                this.log.user = this.get_lazy_logs[i].id_user.username
                                let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
                                if(new_alias != null){
                                    new_alias = new_alias.alias	
                                }else{
                                    new_alias = this.get_lazy_logs[i].table_name
                                }
                                this.log.status = new_alias
                            }
                            
                            
                            this.log.icon = 'fa fa-pencil'
                            this.log.color = '#002D72'

                        } else if (this.get_lazy_logs[i].log_type == 'Deleted') {
                            this.log.user = this.get_lazy_logs[i].id_user.username
                            let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
                            if(new_alias != null){
                                new_alias = new_alias.alias	
                            }else{
                                new_alias = this.get_lazy_logs[i].table_name
                            }
                            this.log.status = new_alias
                            this.log.icon = 'fa fa-trash'
                            this.log.color = '#EF4444'
                        }
                        this.full_logs.push(this.log)
                        this.log = {'status': '', 'date': ''}

                    } else {
                        this.log.date = this.get_lazy_logs[i].modification_date.split('T')[0] + ' ' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[0] + ':' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[1]
                        let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
                        if(new_alias != null){
                            new_alias = new_alias.alias	
                        }else{
                            new_alias = this.get_lazy_logs[i].table_name
                        }
                        this.log.status = new_alias + ' status'
                        this.log.icon = 'fa fa-envelope'
                        this.log.color = 'orange'
                        this.full_logs.push(this.log)
                        this.log = {'status': '', 'date': ''}
                    }
                }
			}

			this.loading = false

			
		},
		test(string) {
			return string.charAt(0).toUpperCase() + string.slice(1) + '.';
		},
        async filter_logs(){
            this.first = 0
            
            await this.$store.dispatch('empty_filter_logs')
			this.full_logs = []
			this.loading = true

            var user_logs = null
            if (this.user_dropdown){
                user_logs = this.user_dropdown.username
            }
            var filter = {
                user: user_logs,
                date: this.date_filter,
                field: this.field_dropdown
            }

            await this.$store.dispatch('filter_logs', filter)

			await this.payload_filter_logs.push(Number(this.$route.params.sid), 0, 10)
			await this.$store.dispatch('lazy_logs', this.payload_filter_logs)

			this.payload_filter_logs = []
			
   
            for(var i in this.get_lazy_logs) {
                if(this.get_lazy_logs[i]) {
                    if(this.get_lazy_logs[i].id_user) {
                        this.log.date = this.get_lazy_logs[i].modification_date.split('T')[0] + ' ' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[0] + ':' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[1]
                        if(this.get_lazy_logs[i].log_type == 'Created') {
                            this.log.user =  this.get_lazy_logs[i].id_user.username
                            let alias_status = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name) 
                            if(alias_status != null){
                                alias_status = alias_status.alias	
                            }else{
                                alias_status = this.get_lazy_logs[i].table_name
                            }
                            this.log.status = alias_status
                            this.log.icon = 'fa fa-check'
                            this.log.color = '#22C55E'
                        } else if (this.get_lazy_logs[i].log_type == 'Updated') { 
                            if(this.get_lazy_logs[i].field_changed) {
                                var test = Array.from(this.get_lazy_logs[i].field_changed)
                                var cont = 0
                                var word = ''
                                for(var k in test) {
                                    if(test[k] != '[' && test[k] != ']') {
                                        if(test[k] == "'") {
                                            cont++
                                        } else {
                                            if(cont == 2) {
                                                cont = 0
                                            } else {
                                                if(test[k] === ' '){
                                                    let new_alias = this.alias_list.find(element => element.name == word)
                                                    if(new_alias != null){
                                                        new_alias = new_alias.alias	
                                                    }else{
                                                        new_alias = this.get_lazy_logs[i].table_name
                                                    }
                                                    this.alias_list_log  += new_alias
                                                    this.alias_list_log += ', '
                                                    word = ''
                                                } else {
                                                    word += test[k]
                                                }
                                            }
                                        }
                                    } 
                                
                                }
                                
                                if(word) {
                                    let find_alias =  this.alias_list.find(element => element.name == word)
                                    if(find_alias != null){
                                        find_alias = find_alias.alias	
                                    }else{
                                        find_alias = word
                                    }
                                    this.alias_list_log += find_alias
                                    
                                    //this.alias_list_log += this.alias_list.find(element => element.name == word).alias
                                    this.log.user = this.get_lazy_logs[i].id_user.username
                                    let alias_service = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
                                    if(alias_service != null){
                                        alias_service = alias_service.alias	
                                    }else{
                                        alias_service = this.get_lazy_logs[i].table_name
                                    }

                                    this.log.status = this.alias_list_log + ' in ' + alias_service
                                    

                                } else {
                                    

                                    this.log.user = this.get_lazy_logs[i].id_user.username
                                    let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
                                    if(new_alias != null){
                                        new_alias = new_alias.alias	
                                    }else{
                                        new_alias = this.get_lazy_logs[i].table_name
                                    }
                                    this.log.status = new_alias

                                }

                                this.alias_list_log = ''
                                
                            } else {
                                
                                this.log.user = this.get_lazy_logs[i].id_user.username
                                let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
                                if(new_alias != null){
                                    new_alias = new_alias.alias	
                                }else{
                                    new_alias = this.get_lazy_logs[i].table_name
                                }
                                this.log.status = new_alias
                            }
                            
                            
                            this.log.icon = 'fa fa-pencil'
                            this.log.color = '#002D72'

                        } else if (this.get_lazy_logs[i].log_type == 'Deleted') {
                            this.log.user = this.get_lazy_logs[i].id_user.username
                            let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
                            if(new_alias != null){
                                new_alias = new_alias.alias	
                            }else{
                                new_alias = this.get_lazy_logs[i].table_name
                            }
                            this.log.status = new_alias
                            this.log.icon = 'fa fa-trash'
                            this.log.color = '#EF4444'
                        }
                        this.full_logs.push(this.log)
                        this.log = {'status': '', 'date': ''}

                    } else {
                        this.log.date = this.get_lazy_logs[i].modification_date.split('T')[0] + ' ' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[0] + ':' + (this.get_lazy_logs[i].modification_date.split('T')[1]).split(':')[1]
                        let new_alias = this.alias_services_list.find(element => element.name == this.get_lazy_logs[i].table_name)
                        if(new_alias != null){
                            new_alias = new_alias.alias	
                        }else{
                            new_alias = this.get_lazy_logs[i].table_name
                        }
                        this.log.status = new_alias + ' status'
                        this.log.icon = 'fa fa-envelope'
                        this.log.color = 'orange'
                        this.full_logs.push(this.log)
                        this.log = {'status': '', 'date': ''}
                    }
                }
            }
            
            
			

			this.loading = false
            
        }
	},
}
</script>

<style scoped>

.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.p-card:deep(.p-card-title){
	font-weight: normal;
}

@media screen and (max-width: 960px) {
    :deep(.customized-timeline .p-timeline-event:nth-child(even)){
        flex-direction: row !important;
    }
    :deep(.customized-timeline .p-timeline-event-content){
        text-align: left !important;
    }
    :deep(.customized-timeline .p-timeline-event-opposite){
        flex: 0;
    }
    :deep(.customized-timeline .p-card){
        margin-top: 1rem;
    }
}

</style>