<template>
    <ModalGroups/>
	<TableGroups/>
	
</template>

<script>
import TableGroups from '@/components/AdminDashboard/Groups/TableGroups.vue/'
import ModalGroups from '@/components/AdminDashboard/Groups/ModalGroups.vue/'

export default {
	name: 'Groups',
	components: {
		TableGroups,
		ModalGroups
	}
	
}
</script>

<style scoped>

</style>