import axios from 'axios'

const state = {
    billing_destinations: [],
    billing_destination_errors:{}
}

const mutations = {
    SET_BILLING_DESTINATIONS(state, billing_destinations) {
        state.billing_destinations = billing_destinations
    },
    NEW_BILLING_DESTINATION(state, billing_destination) {  
        state.billing_destinations.push(billing_destination)
        state.billing_destination_errors = {}
    },
    DELETE_BILLING_DESTINATION(state, id) {
        let index = state.billing_destinations.findIndex(billing_destinations => billing_destinations.id == id)
        state.billing_destinations.splice(index, 1)
    },
    UPDATE_BILLING_DESTINATION(state, billing_destination) {
        state.billing_destinations = state.billing_destinations.map(updated_billing_destination => {
            if(updated_billing_destination.id === billing_destination.id) {
                updated_billing_destination.billing_type = billing_destination.billing_type
            }
            return updated_billing_destination
        })
        state.billing_destination_errors = {}
    },
    ERRORS_BILLING_DESTINATION(state, errors){
        state.billing_destination_errors = errors
    }
}

const actions = {
    async load_all_billing_destinations({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "billing_destinations/")
            .then((response) => {
                commit('SET_BILLING_DESTINATIONS', response.data)
            })
    },
    async create_billing_destination({commit}, billing_destination) {
        await axios.post(process.env.VUE_APP_API_URL  + 'billing_destinations/', { billing_type: billing_destination.billing_type })
            .then((response) => {
                commit('NEW_BILLING_DESTINATION', response.data)
            })
            .catch((error) => {    
                commit('ERRORS_BILLING_DESTINATION', error.response.data)
            })
    },
    delete_billing_destination({commit}, id) {
        axios.delete(process.env.VUE_APP_API_URL + 'billing_destinations/' + id)
            .then(() => {
                commit('DELETE_BILLING_DESTINATION', id);
            })
            
    }, 
    async update_billing_destination({commit}, billing_destination){
        await axios.put(process.env.VUE_APP_API_URL  + 'billing_destinations/' + billing_destination.id + '/',  { billing_type: billing_destination.billing_type})
            .then(() => {
                
                commit('UPDATE_BILLING_DESTINATION', billing_destination)
            })
            .catch((error) => {
                
                commit('ERRORS_BILLING_DESTINATION', error.response.data)
            })
    },
}

const getters = {
    all_billing_destinations: (state) => state.billing_destinations,
    get_billing_destination_errors: (state) => state.billing_destination_errors
}

export default {
    state,
    mutations,
    actions,
    getters
};