<template>
    <ModalCalls/>
    <TableCalls/>
</template>

<script>
import TableCalls from '@/components/AdminDashboard/Calls/TableCalls.vue/'
import ModalCalls from '@/components/AdminDashboard/Calls/ModalCalls.vue/'
export default {
    name: 'Calls',
    components: {
        TableCalls,
        ModalCalls
    }
}
</script>

<style scoped>

</style>