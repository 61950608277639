import axios from 'axios'
import moment from 'moment'

const state = {
    porters_service: {},
    porters_service_extra_services: [],
    porters_shift_services: [],
    porters_service_service_options: [],
    send_porters_mail: false,
    error_porters_service_extra_services: [],
    error_porters_shift_services:[],
    error_porters_service_service_options: [],
    data_report_porters: [],
}

const mutations = {
    SET_PORTERS_SERVICE(state, porters_service) {
        state.porters_service = porters_service
    },
    ADD_PORTERS_SERVICE(state, porters_service) {
        state.porters_service.push(porters_service)
    },
    SET_PORTERS_SERVICE_EXTRA_SERVICES(state, porters_service_extra_services) {
        state.porters_service_extra_services = porters_service_extra_services
    },
    SET_PORTERS_SHIFT_SERVICES(state, porters_shift_services) {
        state.porters_shift_services = porters_shift_services
    },
    ADD_PORTERS_SERVICE_EXTRA_SERVICES(state, porters_service) {
        porters_service.start_datetime_hour =  porters_service.start_datetime.split('T')[1].split(':')[0] + ':' +  porters_service.start_datetime.split('T')[1].split(':')[1]
        porters_service.start_datetime =  porters_service.start_datetime.split('T')[0]
        porters_service.end_datetime_hour =  porters_service.end_datetime.split('T')[1].split(':')[0] + ':' +  porters_service.end_datetime.split('T')[1].split(':')[1]
        porters_service.end_datetime =  porters_service.end_datetime.split('T')[0]
        state.porters_service_extra_services.push(porters_service)
    },
    ADD_PORTERS_SHIFT_SERVICES(state, porters_shift_service) {
        porters_shift_service.start_datetime_hour =  porters_shift_service.start_datetime.split('T')[1].split(':')[0] + ':' +  porters_shift_service.start_datetime.split('T')[1].split(':')[1]
        porters_shift_service.start_datetime =  porters_shift_service.start_datetime.split('T')[0]
        porters_shift_service.end_datetime_hour =  porters_shift_service.end_datetime.split('T')[1].split(':')[0] + ':' +  porters_shift_service.end_datetime.split('T')[1].split(':')[1]
        porters_shift_service.end_datetime =  porters_shift_service.end_datetime.split('T')[0]
        state.porters_shift_services.push(porters_shift_service)
    },
    SET_PORTERS_SERVICE_SERVICE_OPTIONS(state, options){
        state.porters_service_service_options = options
    },
    ADD_PORTERS_SERVICE_SERVICE_OPTIONS(state, option) {
        state.porters_service_service_options.push(option)
    },
    SET_ERROR_PORTERS_SERVICE_EXTRA_SERVICES(state, error_porters_service_extra_services) {
        state.error_porters_service_extra_services = []
        state.error_porters_service_extra_services.push(error_porters_service_extra_services)
    },
    SET_ERROR_PORTERS_SHIFT_SERVICES(state, error_porters_shift_service) {
        state.error_porters_shift_services = []
        state.error_porters_shift_services.push(error_porters_shift_service)
    },


    UPDATE_PORTERS_SERVICE(state, porters_service) {
        state.porters_service = state.porters_service.map(updated_porters_service => {
            if(updated_porters_service.id === porters_service.id) {
                updated_porters_service.id_supplier = porters_service.id_supplier
                updated_porters_service.id_supplier_responsible = porters_service.id_supplier_responsible
                updated_porters_service.mail_text = porters_service.mail_text
                updated_porters_service.note_info = porters_service.note_info
            }
        })
    },
    UPDATE_PORTERS_SHIFT_SERVICES(state, porters_shift_services) {
        state.porters_shift_services = state.porters_shift_services.map(updated_porters_shift_services => {
            if(updated_porters_shift_services.id === porters_shift_services.id) {
                updated_porters_shift_services.start_datetime_hour = porters_shift_services.start_datetime.split('T')[1].split(':')[0]+ ':' +  porters_shift_services.start_datetime.split('T')[1].split(':')[1]
                updated_porters_shift_services.start_datetime = porters_shift_services.start_datetime.split('T')[0]
                updated_porters_shift_services.end_datetime_hour = porters_shift_services.end_datetime.split('T')[1].split(':')[0]+ ':' + porters_shift_services.end_datetime.split('T')[1].split(':')[1]
                updated_porters_shift_services.end_datetime = porters_shift_services.end_datetime.split('T')[0]
                updated_porters_shift_services.shift_type = porters_shift_services.shift_type
                updated_porters_shift_services.passengers_quantity = porters_shift_services.passengers_quantity
            }
            return updated_porters_shift_services
        })
    },

    UPDATE_PORTERS_SERVICE_EXTRA_SERVICES(state, porters_service_extra_services) {
        state.porters_service_extra_services = state.porters_service_extra_services.map(updated_porters_service_extra_services => {
            if(updated_porters_service_extra_services.id === porters_service_extra_services.id) {
                updated_porters_service_extra_services.start_datetime_hour = porters_service_extra_services.start_datetime.split('T')[1].split(':')[0]+ ':' +  porters_service_extra_services.start_datetime.split('T')[1].split(':')[1]
                updated_porters_service_extra_services.start_datetime = porters_service_extra_services.start_datetime.split('T')[0]
                updated_porters_service_extra_services.end_datetime_hour = porters_service_extra_services.end_datetime.split('T')[1].split(':')[0]+ ':' + porters_service_extra_services.end_datetime.split('T')[1].split(':')[1]
                updated_porters_service_extra_services.end_datetime = porters_service_extra_services.end_datetime.split('T')[0]
                updated_porters_service_extra_services.quantity = porters_service_extra_services.quantity
                updated_porters_service_extra_services.id_service_option = porters_service_extra_services.id_service_option
            }
            return updated_porters_service_extra_services
        })
    },
    DELETE_PORTERS_SERVICE(state, id) {
        let index = state.porters_service_extra_services.findIndex(porters_service_extra_services => porters_service_extra_services.id == id)
        state.porters_service_extra_services.splice(index, 1)
    },
    DELETE_PORTERS_SHIFT_SERVICE(state, id) {
        let index = state.porters_shift_services.findIndex(porters_shift_service => porters_shift_service.id == id)
        state.porters_shift_services.splice(index, 1)
    },
    DELETE_PORTERS(state, id) {
        let index = state.porters_service.findIndex(porters_service => porters_service.id == id)
        state.porters_service.splice(index, 1)
    },
    DELETE_PORTERS_SERVICE_SERVICE_OPTION(state, id) {
        let index = state.porters_service_service_options.findIndex(porters_service_service_options => porters_service_service_options.id == id)
        state.porters_service_service_options.splice(index, 1)
    },
    UPDATE_MAIL_STATUS_PORTERS(state, cond) {
        if(cond == true) {
            state.send_porters_mail = true    
        }
    },
    UPDATE_STATUS_MAIL_PORTERS(state) {
        state.send_porters_mail = false
    },
    SET_ERROR_PORTERS(state, error) {
        state.error_porters_service_extra_services = error
    },
    CLEAN_ERROR_PORTERS(state) {
        state.error_porters_service_extra_services = []
    },

    SET_ERROR_PORTERS_SHIFT(state, error) {
        state.error_porters_shift_services = error
    },
    CLEAN_ERROR_PORTERS_SHIFT(state) {
        state.error_porters_shift_services = []
    },
    DATA_REPORT_PORTERS(state, data) {
        state.data_report_porters = data
    },
    EMPTY_PORTERS_EXTRA_SERVICES(state) {
        state.porters_service_extra_services = []
    },
    EMPTY_PORTERS_SHIFT_SERVICES(state) {
        state.porters_shift_services = []
    },
    SET_ERRORS_PORTERS_SERVICE_SERVICE_OPTIONS(state, payload) {
        state.error_porters_service_service_options = payload
    },
    EMPTY_ERRORS_PORTERS_SERVICE_SERVICE_OPTIONS(state) {
        state.error_porters_service_service_options = []
    }
}

const actions = {
	async load_porters_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'porter/get_service/' + id) 
			.then((response) => {
				commit('SET_PORTERS_SERVICE', response.data)
			})
	},
    async load_porters_service_extra_services({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'porter_service/service_sheet/' + id + '/')
            .then((response)=> {
                commit('SET_PORTERS_SERVICE_EXTRA_SERVICES', response.data)
            })
    },
    async load_porters_shift_services({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'porter_shift_service/service_sheet/' + id + '/')
            .then((response)=> {
                commit('SET_PORTERS_SHIFT_SERVICES', response.data)
            })
    },
    async create_past_porters({commit}, id_ss){
        await axios.post(process.env.VUE_APP_API_URL + 'porter/create_past_porter/' + id_ss + '/')
            .then((response) => {
                commit('SET_PORTERS_SERVICE', response.data)
            })
            .catch(() => {})
    },
    async load_porters_service_service_options({commit}){
        await axios.get(process.env.VUE_APP_API_URL + 'porter_service_service_option/') 
            .then((response) => {
                commit('SET_PORTERS_SERVICE_SERVICE_OPTIONS', response.data)
            })
    },
    async create_porters_service({commit}, porters_service) {
        var id_responsible = ''
        if(porters_service.id_supplier_responsible) {
            id_responsible = porters_service.id_supplier_responsible.id
        }
        await axios.post(process.env.VUE_APP_API_URL + 'porter/', {
            id_service_sheet: porters_service.id_service_sheet,
            id_supplier_responsible: id_responsible,
            id_supplier: porters_service.id_supplier.id,
            mail_text: porters_service.mail_text,
            note_info: porters_service.note_info
        })
            .then((response) => {
                commit('ADD_PORTERS_SERVICE', response.data)
            })
    },
    // Create porter_shift
    async create_porters_shift_services({commit}, porter_shift_service){
        if(typeof porter_shift_service.start_shift === 'string') {
            porter_shift_service.start_shift = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + porter_shift_service.start_shift))
        }
        var start_datetime = new Date(new Date(porter_shift_service.start_day).getFullYear(), new Date(porter_shift_service.start_day).getMonth(), new Date(porter_shift_service.start_day).getDate(),
                            new Date(porter_shift_service.start_shift).getHours(), new Date(porter_shift_service.start_shift).getMinutes(), new Date(porter_shift_service.start_shift).getSeconds())
        if(typeof porter_shift_service.end_shift === 'string') {
            porter_shift_service.end_shift = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + porter_shift_service.end_shift))
        }
        var end_datetime = new Date(new Date(porter_shift_service.end_day).getFullYear(), new Date(porter_shift_service.end_day).getMonth(), new Date(porter_shift_service.end_day).getDate(),
                            new Date(porter_shift_service.end_shift).getHours(), new Date(porter_shift_service.end_shift).getMinutes(), new Date(porter_shift_service.end_shift).getSeconds()) 
        await axios.post(process.env.VUE_APP_API_URL + 'porter_shift_service/', {
            start_datetime: moment(start_datetime).format().split('+')[0],
            end_datetime: moment(end_datetime).format().split('+')[0],
            porter: porter_shift_service.porter,
            shift_type: porter_shift_service.shift_type,
            passengers_quantity: porter_shift_service.passengers_quantity,
            id: porter_shift_service.id,
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_PORTERS', true)
                commit('ADD_PORTERS_SHIFT_SERVICES', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_PORTERS_SHIFT_SERVICES', error.response.data)
            })
    },


    async create_porters_service_extra_services({commit}, porter_service){
        if(typeof porter_service.start_shift === 'string') {
            porter_service.start_shift = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + porter_service.start_shift))
        }
        var start_datetime = new Date(new Date(porter_service.start_day).getFullYear(), new Date(porter_service.start_day).getMonth(), new Date(porter_service.start_day).getDate(),
                            new Date(porter_service.start_shift).getHours(), new Date(porter_service.start_shift).getMinutes(), new Date(porter_service.start_shift).getSeconds())
        if(typeof porter_service.end_shift === 'string') {
            porter_service.end_shift = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + porter_service.end_shift))
        }
        var end_datetime = new Date(new Date(porter_service.end_day).getFullYear(), new Date(porter_service.end_day).getMonth(), new Date(porter_service.end_day).getDate(),
                            new Date(porter_service.end_shift).getHours(), new Date(porter_service.end_shift).getMinutes(), new Date(porter_service.end_shift).getSeconds()) 
        await axios.post(process.env.VUE_APP_API_URL + 'porter_service/', {
            start_datetime: moment(start_datetime).format().split('+')[0],
            end_datetime: moment(end_datetime).format().split('+')[0],
            quantity: porter_service.quantity,
            porter: porter_service.porter,
            id_service_option: porter_service.service_type,
            id: porter_service.id,
            is_estimated: porter_service.is_estimated
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_PORTERS', true)
                commit('ADD_PORTERS_SERVICE_EXTRA_SERVICES', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_PORTERS_SERVICE_EXTRA_SERVICES', error.response.data)
            })
    },
    async create_porters_service_service_option({commit}, name) {
        await axios.post(process.env.VUE_APP_API_URL + 'porter_service_service_option/', {
            name: name 
        })
            .then((response) => {
                commit('EMPTY_ERRORS_PORTERS_SERVICE_SERVICE_OPTIONS')
                commit('ADD_PORTERS_SERVICE_SERVICE_OPTIONS', response.data)
            })
            .catch((error) => {
                commit('SET_ERRORS_PORTERS_SERVICE_SERVICE_OPTIONS', error.response.data)
            })
    },
    async update_porters_service({commit}, porters_service) {
        var id_responsible = ''
        if(porters_service.id_supplier_responsible) {
            id_responsible = porters_service.id_supplier_responsible.id
        }
        await axios.patch(process.env.VUE_APP_API_URL + 'porter/' + porters_service.id + '/', {mail_text: porters_service.mail_text, note_info: porters_service.note_info, id_supplier_responsible: id_responsible, id_supplier: porters_service.id_supplier.id})
            .then((response) => {

                commit('UPDATE_MAIL_STATUS_PORTERS', response.data.edited)
                commit('UPDATE_PORTERS_SERVICE', response.data)
            })
    },

    async update_porters_shift_services ({commit}, porter_shift_service) {
        
        if(typeof(porter_shift_service.start_datetime) === 'object' || typeof(porter_shift_service.start_datetime_hour) === 'object') {
            if(typeof porter_shift_service.start_datetime_hour === 'string') {
                porter_shift_service.start_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + porter_shift_service.start_datetime_hour))
            }
            var start_datetime = new Date(new Date(porter_shift_service.start_datetime).getFullYear(), new Date(porter_shift_service.start_datetime).getMonth(), new Date(porter_shift_service.start_datetime).getDate(),
                new Date(porter_shift_service.start_datetime_hour).getHours(), new Date(porter_shift_service.start_datetime_hour).getMinutes(), new Date(porter_shift_service.start_datetime_hour).getSeconds())
            start_datetime = moment(start_datetime).format().split('+')[0]
        } else {
            start_datetime = porter_shift_service.start_datetime + 'T' + porter_shift_service.start_datetime_hour
        }
        if(typeof(porter_shift_service.end_datetime) === 'object' || typeof(porter_shift_service.end_datetime_hour) === 'object') {
            if(typeof porter_shift_service.end_datetime_hour === 'string') {
                porter_shift_service.end_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + porter_shift_service.end_datetime_hour))
            }
            var end_datetime = new Date(new Date(porter_shift_service.end_datetime).getFullYear(), new Date(porter_shift_service.end_datetime).getMonth(), new Date(porter_shift_service.end_datetime).getDate(),
                new Date(porter_shift_service.end_datetime_hour).getHours(), new Date(porter_shift_service.end_datetime_hour).getMinutes(), new Date(porter_shift_service.end_datetime_hour).getSeconds())
            end_datetime = moment(end_datetime).format().split('+')[0]
        } else {
            end_datetime = porter_shift_service.end_datetime + 'T' + porter_shift_service.end_datetime_hour
        }

        await axios.patch(process.env.VUE_APP_API_URL + 'porter_shift_service/' + porter_shift_service.id + '/', {
            start_datetime: start_datetime,
            end_datetime: end_datetime,
            shift_type: porter_shift_service.shift_type,
            passengers_quantity: porter_shift_service.passengers_quantity
            
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_PORTERS', response.data.edited)
                commit('UPDATE_PORTERS_SHIFT_SERVICES', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_PORTERS_SHIFT_SERVICES', error.response.data)
            })
    },

    async update_porters_service_extra_services ({commit}, porter_service) {
        
        if(typeof(porter_service.start_datetime) === 'object' || typeof(porter_service.start_datetime_hour) === 'object') {
            if(typeof porter_service.start_datetime_hour === 'string') {
                porter_service.start_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + porter_service.start_datetime_hour))
            }
            var start_datetime = new Date(new Date(porter_service.start_datetime).getFullYear(), new Date(porter_service.start_datetime).getMonth(), new Date(porter_service.start_datetime).getDate(),
                new Date(porter_service.start_datetime_hour).getHours(), new Date(porter_service.start_datetime_hour).getMinutes(), new Date(porter_service.start_datetime_hour).getSeconds())
            start_datetime = moment(start_datetime).format().split('+')[0]
        } else {
            start_datetime = porter_service.start_datetime + 'T' + porter_service.start_datetime_hour
        }
        if(typeof(porter_service.end_datetime) === 'object' || typeof(porter_service.end_datetime_hour) === 'object') {
            if(typeof porter_service.end_datetime_hour === 'string') {
                porter_service.end_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + porter_service.end_datetime_hour))
            }
            var end_datetime = new Date(new Date(porter_service.end_datetime).getFullYear(), new Date(porter_service.end_datetime).getMonth(), new Date(porter_service.end_datetime).getDate(),
                new Date(porter_service.end_datetime_hour).getHours(), new Date(porter_service.end_datetime_hour).getMinutes(), new Date(porter_service.end_datetime_hour).getSeconds())
            end_datetime = moment(end_datetime).format().split('+')[0]
        } else {
            end_datetime = porter_service.end_datetime + 'T' + porter_service.end_datetime_hour
        }
        await axios.patch(process.env.VUE_APP_API_URL + 'porter_service/' + porter_service.id + '/', {
            quantity: porter_service.quantity,
            start_datetime: start_datetime,
            end_datetime: end_datetime,
            id_service_option: porter_service.id_service_option.id
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_PORTERS', response.data.edited)
                commit('UPDATE_PORTERS_SERVICE_EXTRA_SERVICES', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_PORTERS_SERVICE_EXTRA_SERVICES', error.response.data)
            })
    },
    async delete_porters({commit}, id){
        await axios.delete(process.env.VUE_APP_API_URL + 'porter/' + id + '/')
            .then(() => {
                commit('DELETE_PORTERS', id)
            })
            .catch((error) => {
                commit('SET_ERROR_PORTERS', error.response.data)
            })
    },
    async delete_porters_service({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'porter_service/' + id + '/')
            .then(() => {
                commit('DELETE_PORTERS_SERVICE', id)
                
            })
            .catch((error) => {
                commit('SET_ERROR_PORTERS', error.response.data)
            })
    },
    async delete_porters_shift_service({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'porter_shift_service/' + id + '/')
            .then(() => {
                commit('DELETE_PORTERS_SHIFT_SERVICE', id)
                
            })
            .catch((error) => {
                commit('SET_ERROR_PORTERS_SHIFT', error.response.data)
            })
    },
    async delete_porters_service_service_option({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "porter_service_service_option/" + id + '/')
            .then(() => {
                commit('DELETE_PORTERS_SERVICE_SERVICE_OPTION', id)
            })
    },
    async send_porters_service_mail({commit}, id){
        await axios.post(process.env.VUE_APP_API_URL + 'porter/send_mail/' + id + '/')
            .then(() => {
                commit('UPDATE_STATUS_MAIL_PORTERS')
                commit('CLEAN_ERROR_PORTERS')
            })
            .catch((error) => {
                commit('SET_ERROR_PORTERS', error.response.data)
            })
    },
    async clean_errors_porters_extra_services({commit}) {
        commit('CLEAN_ERROR_PORTERS')
    },
    async clean_errors_porters_shift_services({commit}) {
        commit('CLEAN_ERROR_PORTERS_SHIFT')
    },
    async get_data_porters({commit}, data) {
        await axios.post(process.env.VUE_APP_API_URL + 'porter/mail_monthly_porters/get_data/' + data.month + '/' + data.year + '/' + data.supplier + '/')
            .then((response) => {
                commit('DATA_REPORT_PORTERS', response.data)
            })
    },
    async empty_porters_extra_services({commit}) {
        commit('EMPTY_PORTERS_EXTRA_SERVICES')
    },
    async empty_porters_shift_services({commit}) {
        commit('EMPTY_PORTERS_SHIFT_SERVICES')
    }
}

const getters = {
    get_porters_service: (state) => state.porters_service,
    get_porters_shift_services: (state) => state.porters_shift_services,
    get_porters_service_service_options: (state) => state.porters_service_service_options,
    get_porters_service_extra_services_estimated: (state) =>  {
        return state.porters_service_extra_services.filter(item => item.is_estimated === true)
    },
    get_porters_service_extra_services_final: (state) => {
        return state.porters_service_extra_services.filter(item => item.is_estimated === false)
    },
    get_send_porters_mail: (state) => state.send_porters_mail,
    get_error_porters_service_extra_services: (state) => state.error_porters_service_extra_services,
    get_error_porters_shift_services: (state) => state.error_porters_shift_services,
    get_data_report_porters: (state) => state.data_report_porters,
    error_porters_service_service_options: (state) => state.error_porters_service_service_options
}

export default {
    state,
    mutations,
    actions,
    getters
};