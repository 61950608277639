import axios from 'axios'

const state = {
    notifications: [],
    last_notifications: []
}

const mutations = {
    SET_NOTIFICATIONS(state, notifications) {
        state.notifications = notifications
    },
    SET_LAST_NOTIFICATIONS(state, payload) {
        state.last_notifications = payload
    },
    SET_VIEWED(state, notification) {
        state.notifications = state.notifications.map(updated_notifications => {
            if(updated_notifications.id === notification.id) {
                updated_notifications.is_viewed = notification.is_viewed
            }
            return updated_notifications
        })  
    
        state.last_notifications.notifications = state.last_notifications.notifications.map(updated_notifications => {
            if(updated_notifications.id === notification.id) {
                updated_notifications.is_viewed = notification.is_viewed
            }
            return updated_notifications
        }) 
    },
    SET_NOT_VIEWED(state, notification) {
        state.notifications = state.notifications.map(updated_notifications => {
            if(updated_notifications.id === notification.id) {
                updated_notifications.is_viewed = notification.is_viewed
            }
            return updated_notifications
        })

        state.last_notifications.notifications = state.last_notifications.notifications.map(updated_notifications => {
            if(updated_notifications.id === notification.id) {
                updated_notifications.is_viewed = notification.is_viewed
            }
            return updated_notifications
        }) 
    },
    SET_ALL_AS_VIEWED(state) {
        state.notifications = state.notifications.map(updated_notifications => {
            updated_notifications.is_viewed = true
            return updated_notifications
        })
    }
}

const actions = {
    async load_notifications({commit}, id){
        await axios.get(process.env.VUE_APP_API_URL  + "notifications/get_by_user/" + id)
            .then((response) => {
                commit('SET_NOTIFICATIONS', response.data)
            })
    },
    async load_last_notifications({commit}){
        await axios.get(process.env.VUE_APP_API_URL  + "notifications/get_last_notifications/")
            .then((response) => {
                commit('SET_LAST_NOTIFICATIONS', response.data)
            })
    },
    async mark_as_viewed({commit}, id) {
        await axios.post(process.env.VUE_APP_API_URL  + "notifications/viewed/" + id +'/')
            .then((response) => {
                commit('SET_VIEWED', response.data)
            }) 
    },
    async mark_as_not_viewed({commit}, id) {
        await axios.patch(process.env.VUE_APP_API_URL  + "notifications/" + id +'/', {
            is_viewed: false
        })
            .then((response) => {
                commit('SET_NOT_VIEWED', response.data)
            }) 
    },
    async mark_all_as_read({commit}, id) {
        await axios.post(process.env.VUE_APP_API_URL  + "notifications/view_all/" + id +'/')
            .then(() => {
                commit('SET_ALL_AS_VIEWED')
            })
    }
}

const getters = {
    notifications: (state) => state.notifications,
    last_notifications: (state) => state.last_notifications,
    unread_notifications (state) {
        return state.notifications.filter(notification => notification.is_viewed == false)
    }
}

export default {
    state,
    mutations,
    actions,
    getters
};