<template>
    <div class="card responsive_width" >
        <div class="row">
            <Toast/>
            <DataTable data-cy="table_scroll" :value="all_suppliers" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading" removableSort
            :globalFilterFields="['name']"
            v-model:filters="filters" >
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText id="filter_suppliers" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                        </span>
                    </div>
                </template>
                <Column header="Name" field="name" :sortable="true" style="min-width: 15rem;"/>
                <Column header="Email/s" field="email" :sortable="true" >
                    <template #body="slotProps">
                        <span v-for="item in slotProps.data.email" :key="item"> 
                            <Chip :label="item" class="me-2 mb-1 mt-1"/> 
                        </span>
                    </template>
                </Column>
                <Column header="Phone" field="phone" :sortable="true"/>
                <Column header="Fiscal adress" field="fiscal_address" :sortable="true" style="min-width: 15rem;"/>
                <Column header="Country Code" field="country_code" :sortable="true"/>
                <Column header="Postal Code" field="cp" :sortable="true"/>
                <Column header="CIF" field="cif" :sortable="true"/>
                <Column header="CCC" field="ccc" :sortable="true"/>
                <Column header="BIC" field="bic_entity" :sortable="true"/>
                <Column header="IBAN" field="iban" :sortable="true"/>
                <Column v-if="$can('change','supplier')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_supplier(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','supplier')" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_supplier(slotProps)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_suppliers_modal" v-model:visible="supplier_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '60vw'}" header="Supplier Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="name" class="ps-1 pb-1"> <strong> Name </strong> </label>
                        <InputText id="name" v-model.trim="supplier.name" required="true" autofocus :class="{'p-invalid': submitted && !supplier.name || supplier_errors.name}" />
                        <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.name" class="p-error"><span v-for="error in supplier_errors.name" :key="error">{{error}}<br></span></small>
                        <small class="p-error" v-if="submitted && !supplier.name">Name is required.</small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="email" class="ps-1 pb-1"> <strong> Email </strong> </label>
                        <Chips id="email" separator="," v-model="supplier.email" required="true" autofocus :class="{'p-invalid': submitted && !supplier.email || supplier_errors.email}" />
                        <small class="p-error" v-if="submitted && !supplier.email">Email is required.</small>
                        <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.email" class="p-error"><span v-for="error in supplier_errors.email" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="phone" class="ps-1 pb-1"> <strong> Phone </strong> </label>
                        <InputText id="phone" v-model.trim="supplier.phone" required="true" autofocus :class="{'p-invalid': submitted && !supplier.phone || supplier_errors.phone}" />
                        <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.phone" class="p-error"><span v-for="error in supplier_errors.phone" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col">
                    <div class="p-float-field">
                        <label for="cif" class="ps-1 pb-1"> <strong> CIF </strong> </label>  
                        <InputText id="cif" v-model="supplier.cif" required="true" autofocus :class="{'p-invalid': submitted && supplier_errors.cif}" />
                        <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.cif" class="p-error"><span v-for="error in supplier_errors.cif" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col">
                    <div class="p-float-field">
                        <label for="ccc" class="ps-1 pb-1"> <strong> CCC </strong> </label>  
                        <InputText id="ccc" v-model="supplier.ccc" required="true" autofocus :class="{'p-invalid': submitted && supplier_errors.ccc}" />
                        <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.ccc" class="p-error"><span v-for="error in supplier_errors.ccc" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="fiscal_address" class="ps-1 pb-1"> <strong> Fiscal adress </strong> </label>  
                        <InputText id="fiscal_address" v-model="supplier.fiscal_address" required="true" autofocus :class="{'p-invalid': submitted && supplier_errors.fiscal_address}" />
                        <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.fiscal_address" class="p-error"><span v-for="error in supplier_errors.fiscal_address" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col">
                    <div class="p-float-field">
                        <label for="country_code" class="ps-1 pb-1"> <strong> Country Code </strong> </label>  
                        <InputText id="country_code" v-model="supplier.country_code" required="true" autofocus :class="{'p-invalid': submitted && supplier_errors.country_code}" />
                        <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.country_code" class="p-error"><span v-for="error in supplier_errors.country_code" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col">
                    <div class="p-float-field">
                        <label for="cp" class="ps-1 pb-1"> <strong> Postal Code </strong> </label>  
                        <InputText id="cp" v-model="supplier.cp" required="true" autofocus :class="{'p-invalid': submitted && supplier_errors.cp}" />
                        <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.cp" class="p-error"><span v-for="error in supplier_errors.cp" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col">
                    <div class="p-float-field">
                        <label for="bic_entity" class="ps-1 pb-1"> <strong> BIC </strong> </label>  
                        <InputText id="bic_entity" v-model="supplier.bic_entity" required="true" autofocus :class="{'p-invalid': submitted && supplier_errors.bic_entity}" />
                        <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.bic_entity" class="p-error"><span v-for="error in supplier_errors.bic_entity" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col">
                    <div class="p-float-field">
                        <label for="iban" class="ps-1 pb-1"> <strong> IBAN </strong> </label>  
                        <InputText id="iban" v-model="supplier.iban" required="true" autofocus :class="{'p-invalid': submitted && supplier_errors.iban}" />
                        <small v-if="Object.keys(supplier_errors).length !== 0 && supplier_errors.iban" class="p-error"><span v-for="error in supplier_errors.iban" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_supplier_dialog"/>
            <Button data-cy="save_edit_supplier" label="Save" @click="save_supplier" />
        </template>
        
    </Dialog>

    <Dialog v-model:visible="delete_supplier_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="supplier">Are you sure you want to delete <b>{{supplier.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_supplier_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deleteSupplier" />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import { FilterMatchMode } from 'primevue/api';
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: "TableSuppliers",    
    components: {
        DataTable,
        Column,
        InputText,
        Button,
        Toast,
        Dialog,
        Chip,
        Chips
    },
    data() {
        return {
            supplier: {},
            supplier_errors: {},
            supplier_dialog: false,
            delete_supplier_dialog: false,
            submitted: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_suppliers.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_suppliers')
            this.loading = false
        }
        
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_suppliers: function() {
            return this.$store.getters.all_suppliers
        },
        get_supplier_errors: function() {
            return this.$store.getters.get_supplier_errors
        },
        get_delete_supplier_status: function() {
            return this.$store.getters.get_delete_supplier_status
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        confirm_delete_supplier(supplier) {
            this.supplier = supplier.data;
            this.delete_supplier_dialog = true;
        },
        async deleteSupplier() {
            await this.$store.dispatch('delete_supplier', this.supplier.id)
            if (!this.get_delete_supplier_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Supplier can't be deleted", life: 3000});
            }
            this.delete_supplier_dialog = false;
            
        },
        edit_supplier(supplier) {
            this.supplier = {...supplier};
            this.supplier_dialog = true;
            
        },
        async save_supplier() {
            this.submitted = true;
			if (this.supplier.name.trim()) {

                await this.$store.dispatch('update_supplier', this.supplier)
                this.supplier_errors = this.get_supplier_errors
                if(Object.keys(this.supplier_errors).length === 0){
                    this.hide_supplier_dialog()
                    this.supplier = {};
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier modified', life: 3000});
                }
            }
        },
        hide_supplier_dialog() {
            this.supplier_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            name: { required },
            email: { required },
            phone: { required },
        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

@media (max-width: 1301px) {
    .responsive_width{
        width: 95vw !important; 
    }
}

.responsive_width{
    width: calc(100vw - 24.5rem )
}


</style>