<template>
    <Panel header="Custodies Service" v-model:collapsed="custodian_collapsed" :collapsed="true" :toggleable="true" @toggle="fill_custodian_service($event)">
        <template #header>
            <div @click="custodian_collapsed = !custodian_collapsed; fill_custodian_service($event)" class="col" style="padding: 0.7rem;">
                <div class="row">
                    <div class="col">
                        <span class="title_panels"> Custodian </span>
                    </div>
                </div>
            </div>
        </template>
        <template #icons>
            <i v-if="created_automatically" v-tooltip.left="'This service was created automatically with previous data.'" class="fa-solid fa-circle-info fa-xl" style="color: #fcc203;"></i>    
            <i v-if="custodian_service.status" class="p-panel-header-icon mr-2">
                <span v-if="custodian_service.status === 'None'">
                    <span v-tooltip.left="'Service email not sent.'" class="fa fa-circle-half-stroke fa-xl" style="color: #002d7d;"></span>
                </span>
                <span v-else-if="custodian_service.status === 'On hold'">
                    <span v-tooltip.left="'Service email sent and waiting for confirmation.'" class="fa fa-circle fa-xl" style="color: orange;"></span>
                </span>
                <span v-else-if="custodian_service.status === 'Confirmed'">
                    <span v-tooltip.left="'Service email confirmed.'" class="fa fa-circle fa-xl" style="color: #22C55E;"></span>
                </span>
                <span v-else-if="custodian_service.status === 'Refused'">
                    <span v-tooltip.left="'Service email refused.'" class="fa fa-circle fa-xl" style="color: #EF4444;"></span>
                </span>
            </i>
        </template>
        <div class="container p-fluid">
            <div class="row">
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Supplier </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="all_suppliers" :disabled="!$ability.can('change','custodian') || check_user_service_operator" :filter="true" optionLabel="name" v-model="custodian_service.id_supplier" @change="load_custodian_service_responsibles()">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span> {{slotProps.value.name}} </span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Responsible </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="custodian_service_responsibles_list" placeholder="Select a responsible..." :disabled="!$ability.can('change','custodian') || check_user_service_operator" :filter="true" optionLabel="name" v-model="custodian_service.id_supplier_responsible" @click="load_custodian_service_responsibles()">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span>{{slotProps.value.name}}</span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$ability.can('add','supplier_responsible')" class="row justify-content-end">
                <div class="col-12 md:col-3 text-center">
                    <ModalSupplierResponsibles :service_sheet="true"/>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <Divider align="center" class="mt-3">
                        <div class="inline-flex align-items-center">
                            <b> Shift </b>
                        </div>
                    </Divider>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 md:col-3 mt-2">
                    <span class="p-float-label">
                        <InputNumber id="quantity_custodian" :disabled="!$ability.can('change','custodian') || check_user_service_operator"  v-model="custodian_service.quantity" @input="change_custodian()"/>
                        <label for="quantity_custodian"> Quantity </label>
                    </span>
                </div>
                <div class="col-12 md:col-5 mt-2">
                    <span class="p-float-label">
                        <Calendar autocomplete="off" :disabled="!$ability.can('change','custodian') || check_user_service_operator"  id="start_datetime_custodian" v-model="custodian_service.start_datetime" dateFormat="yy-mm-dd" :minDate="get_min_date" :maxDate="get_max_date"/>
                        <label for="start_datetime_custodian"> Day </label>
                    </span>
                </div>
                <div class="col-12 md:col-4 mt-2">
                    <span class="p-float-label">
                        <Calendar autocomplete="off" :disabled="!$ability.can('change','custodian') || check_user_service_operator"  id="start_datetime_custodian" v-model="custodian_service.start_datetime_hour" :timeOnly="true" hourFormat="24" @change="custodian_service.start_datetime_hour = num2time($event)"/>
                        <label for="start_datetime_custodian"> Hour </label>
                    </span>
                </div>
            </div>
            <div v-if="get_error_custodian_services.length > 0" class="col text-center" style="color: red;">
                <div v-for="error in get_error_custodian_services" :key="error">
                    <ul>    
                        <li v-if="error.quantity">{{error.quantity[0]}}</li>
                        <li v-if="error.start_datetime">{{error.start_datetime[0]}}</li>
                    </ul>
                </div>
            </div> 
            <div class="row">
                <div class="col-12 md:col-6">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Mail Note </b>
                            <i class="fa fa-circle-info pl-2" v-tooltip="'Please remember that this section is meant for adding a note to the email content, it\'s not the main body of the email.'" type="text" placeholder="Right" style="cursor:pointer;"></i>
                        </div>
                    </Divider>
                    <div class="row pt-3">
                        <div class="col">
                            <TextArea placeholder="This text will be attached to the email sent." rows="5" v-model="custodian_service.mail_text" :disabled="!$ability.can('change','custodian') || check_user_service_operator" />
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Additional Notes </b>
                        </div>
                    </Divider>
                    <div class="row pt-3">
                        <div class="col">
                            <TextArea rows="5" v-model="custodian_service.note_info" :disabled="!$ability.can('change','custodian') || check_user_service_operator" />
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="custodian_service.status === 'Modified' || custodian_service.status === 'Refused' || custodian_service.status === 'Confirmed' || custodian_service.status === 'On hold'" class="row pt-3 justify-content-between">
                <div v-if="$ability.can('delete','custodian') && custodian_service.status && !check_user_service_operator" class="col-12 md:col-3 lg:col-3">
                    <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_custodian()"/>
                </div>
                <div v-if="$ability.can('send','mail_custodian') && !check_user_service_operator" class="col-12 md:col-3 lg:col-3">
                    <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_custodian_mail()"/>
                </div>
            </div>
            <div v-else class="row pt-3 justify-content-between">
                <div v-if="$ability.can('delete','custodian') && custodian_service.status && !check_user_service_operator" class="col-12 md:col-4 lg:col-4">
                    <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_custodian()"/>
                </div>
                <div v-if="$ability.can('send','mail_custodian') && !check_user_service_operator" class="col-12 md:col-3 lg:col-3">
                    <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_custodian_mail()"/>
                </div>
                <div v-if="$ability.can('change','custodian') || $ability.can('add','custodian') && !check_user_service_operator" class="col-12 md:col-3 lg:col-3">
                    <span v-if="custodian_service.status && $ability.can('change','custodian') && !check_user_service_operator">
                        <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_custodian_service()"/>
                    </span>
                    <span v-else-if="!custodian_service.status && $ability.can('add','custodian') && $ability.can('change','custodian') && !check_user_service_operator">
                        <Button label="Create" icon="fa-solid fa-plus" class="p-button-raised p-button-text p-button-success" @click="save_changes_custodian_service()"/>
                    </span>
                </div>
            </div>
            <!--
            <div v-if="mail_custodian_error" class="row pt-3 text-center">
                <div class="col" style="color: red;">
                    {{mail_custodian_error}}
                </div>
            </div>-->
        </div>
    </Panel>

    <Dialog v-model:visible="delete_service" :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span> Are you sure you want to delete this service? </span>
        </div>
        <template #footer>
            <Button label="No"  class="p-button-text" @click="delete_service = false"/>
            <Button label="Yes" @click="handle_function_delete(service_selected)" />
        </template>
    </Dialog>

    <Dialog v-model:visible="modal_confirmation_mail"  :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="row">
            <div class="col">
                <span> Are you sure you want to send this mail? </span>
            </div>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="modal_confirmation_mail = false"/>
            <Button label="Yes" @click="handle_function_confirmation_mail(mail_selected)" />
        </template>
    </Dialog>
</template>

<script>
import Panel from 'primevue/panel'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import TextArea from 'primevue/textarea'
import Dialog from 'primevue/dialog'

import ModalSupplierResponsibles from '@/components/AdminDashboard/Supplier_responsibles/ModalSupplier_responsibles.vue/'

import { num2time, num2timeedit } from '@/modules/utilities.js'


export default {
    name: 'Custodian',
    components: {
        ModalSupplierResponsibles,
        TextArea,
        Panel,
        Button,
        Divider,
        Calendar,
        InputNumber,
        Dropdown,
        Dialog,
    },
    props: {
        service_sheet: {
            type: Object
        },
        all_suppliers: {
            type: Object
        },
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            custodian_service: {'id_supplier_responsible':null,'start_datetime':'', 'start_datetime_hour':'','id_service_sheet': (Number(this.$route.params.sid)),'id_supplier': {"id": 73, "name": "supplier"},'quantity': null, 'note_info':'', 'mail_text': ''},
            mail_custodian_error: null,
            custodian_service_responsibles_list: [], 
            custodian_collapsed: true,
            delete_service: false,
            modal_confirmation_mail: false,
            created_automatically:false
        }
    },
    computed: {
        get_custodian_services: function() {
            return this.$store.getters.get_custodian_services
        },
        get_send_custodian_mail: function() {
            return this.$store.getters.get_send_custodian_mail
        },
        get_error_custodian_services: function() {
            return this.$store.getters.get_error_custodian_services
        },
        get_supplier_service: function() {
            return this.$store.getters.get_supplier_service
        },
        get_supplier_service_responsible: function() {
            return this.$store.getters.get_supplier_service_responsible
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Ops') {
                    return true
                }
            }
            return false
        },
        get_min_date: function() {
            return this.$store.getters.min_date
        },
        get_max_date: function() {
            return this.$store.getters.max_date
        }
    },
    async mounted() {
         // Custodian Service check if exists 
        if(this.get_custodian_services.length) {
            this.custodian_service = this.get_custodian_services[0]
            this.custodian_service.start_datetime_hour = this.custodian_service.start_datetime.split('T')[1].split(':')[0] + ':' + this.custodian_service.start_datetime.split('T')[1].split(':')[1]
            this.custodian_service.start_datetime = this.custodian_service.start_datetime.split('T')[0]
        }

        this.mail_custodian = ''
    },
    methods: {
        num2time(data) {
            return num2time(data)
        },
        async fill_shifts(){
            this.custodian_service = this.get_custodian_services[0]
            this.custodian_service.start_datetime_hour = this.custodian_service.start_datetime.split('T')[1].split(':')[0] + ':' + this.custodian_service.start_datetime.split('T')[1].split(':')[1]
            this.custodian_service.start_datetime = this.custodian_service.start_datetime.split('T')[0]
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
        handle_function_confirmation_mail(function_name){
            this[function_name]()
            this.modal_confirmation_mail = false
            this.mail_selected = ''
        },
        //  CUSTODIAN SERVICE
        async fill_custodian_service(e) {
            if(e.value === false || this.custodian_collapsed === false){
                if (this.custodian_service.status === undefined){
                    await this.$store.dispatch('create_past_custodian', (Number(this.$route.params.sid)))
                    if(this.get_custodian_services[0] !== undefined){
                        this.custodian_service  = this.get_custodian_services[0]
                        this.fill_shifts()
                        this.created_automatically=true
                    }
                }
            }

            await this.$store.dispatch('load_port_supplier_service')

            for(var item in this.get_supplier_service) {
                if(this.get_supplier_service[item].service == 'Custodian security') {
                    this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[item].id_supplier.id)
                }
            }

            this.custodian_service_responsibles_list = this.get_supplier_service_responsible
            if(this.get_custodian_services.length == 0) {
                for(var index in this.get_supplier_service){
                    if(this.get_supplier_service[index].service == 'Custodian security') {
                        this.custodian_service.id_supplier = this.get_supplier_service[index].id_supplier
                        this.custodian_service.id_supplier_responsible = this.get_supplier_service[index].id_supplier_responsible
                    }
                }
            }

            this.load_custodian_service_responsibles()
        },
        async load_custodian_service_responsibles() {
            this.custodian_service.id_supplier_responsible = ''
            await this.$store.dispatch('load_supplier_service_responsible', this.custodian_service.id_supplier.id)
            this.custodian_service_responsibles_list = this.get_supplier_service_responsible

            if(this.custodian_service_responsibles_list.length != 0) {
                this.get_custodian_services.id_supplier_responsible = this.custodian_service_responsibles_list[0]
            }
            
           
        },
        async change_custodian() {
            this.mail_custodian_error = null
        },
        async save_changes_custodian_service() {
            this.$store.dispatch('clean_errors_custodian')
            this.mail_custodian_error = ''
            if(this.get_custodian_services.length == 0) {
                
                await this.$store.dispatch('create_custodian_services', this.custodian_service)
                if(this.get_error_custodian_services.length == 0) {
                    await this.$store.dispatch('load_custodian_service', (Number(this.$route.params.sid)))
                    this.custodian_service = this.get_custodian_services[0]
                    this.custodian_service.start_datetime_hour =  this.custodian_service.start_datetime.split('T')[1].split(':')[0] + ':' + this.custodian_service.start_datetime.split('T')[1].split(':')[1]
                    this.custodian_service.start_datetime = this.custodian_service.start_datetime.split('T')[0]
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail:'Data saved', life: 3000})
                }
                
            } else {
                await this.$store.dispatch('update_custodian_services', this.custodian_service)
                if(this.get_error_custodian_services.length == 0) {
                    await this.$store.dispatch('load_custodian_service', (Number(this.$route.params.sid)))
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail:'Data saved', life: 3000})
                    this.custodian_service = this.get_custodian_services[0]
                    this.custodian_service.start_datetime_hour = this.custodian_service.start_datetime.split('T')[1].split(':')[0] + ':' + this.custodian_service.start_datetime.split('T')[1].split(':')[1]
                    this.custodian_service.start_datetime = this.custodian_service.start_datetime.split('T')[0]
                }
            }


            
        },
        async dialog_confirmation_custodian_mail() {
            this.modal_confirmation_mail = true
            this.mail_selected = 'send_custodian_service_mail'
        },
        async send_custodian_service_mail() {
            this.mail_custodian_error = null
            await this.save_changes_custodian_service()
    
            if(this.custodian_service.status == 'None' || this.custodian_service.status == 'Refused' || this.custodian_service.status == 'Modified') {
                await this.$store.dispatch('send_custodian_service_mail', (Number(this.$route.params.sid)), this.custodian_service)
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
            } else {
                if(!this.custodian_service.status == 'None'){
                    if(this.get_send_custodian_mail == false) {
                        this.mail_custodian_error = 'Cannot send mail with the same data as the previous.' 
                    } else {
                        await this.$store.dispatch('send_custodian_service_mail', (Number(this.$route.params.sid)), this.custodian_service)
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                    }
                }       
            }
                
            await this.$store.dispatch('load_custodian_service', (Number(this.$route.params.sid)))
            if(this.get_custodian_services.length > 0) {
                this.custodian_service = this.get_custodian_services[0]
                this.custodian_service.start_datetime_hour = this.custodian_service.start_datetime.split('T')[1].split(':')[0] + ':' + this.custodian_service.start_datetime.split('T')[1].split(':')[1]
                this.custodian_service.start_datetime = this.custodian_service.start_datetime.split('T')[0]
            }
        },
        async delete_custodian() {
            this.service_selected = 'delete_custodian_confirmation'
            this.delete_service = true
        },
        async delete_custodian_confirmation() {
            this.mail_custodian_error = ''
            
            await this.$store.dispatch('delete_custodian', this.custodian_service.id)
            await this.$store.dispatch('load_custodian_service', (Number(this.$route.params.sid)))
            
            this.custodian_service = {'id_supplier_responsible': null, 'note_info':'', 'mail_text': '', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))}
            
            if(this.get_error_custodian_services.detail === 'Not found.') {
                this.mail_custodian_error = 'Cannot delete empty service.'
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Service deleted', life: 3000})
            }

            //this.fill_custodian_service()
        },
        handle_function_delete(function_name){
            this[function_name]()
            this.delete_service = false
            this.service_selected = ''
        },
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
    border-radius: 0%;
}

.on_hold{
    border: 4px solid #002D72;
    border-radius: 10px;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}

.p-divider.p-divider-horizontal {
  color: v-bind(color);
}

.p-tooltip{
    background: v-bind(color);
}

ul {
    list-style-type: none;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}


:deep(.p-button-sm){
    padding: .5px .5px;
}

:deep(.p-scrollpanel-content) {
    overflow-y: hidden;
}
</style>