<template>
    <Toast />
    <div class="container-fluid">
        <div class="row" style="height: 100%">
            <div v-if="active_index == 0 && $ability.can('add','incidence_report')" class="side_bar_custom">
                <Menu :model="items"/>
            </div>  
            <div v-else>
                <div class="side_bar_custom_small">
                    <Menu :model="items"/>
                </div>
                <div class="side_bar_custom_bottom">
                    <Menu :model="filters_menu_date"/>
                    <div class="p-fluid ps-3 pe-3 ">
                        <Calendar v-model="filter_date" dateFormat="yy-mm-dd" selectionMode="range" :manualInput="false" @date-select="load_new_incidences()"/>
                    </div>
                    <Menu :model="filters_menu_title"/>
                    <div class="flex p-fluid ps-3 pe-3">
                        <InputText v-model="filter_title"/>
                    </div>
                    <Menu :model="filters_menu_description"/>
                    <div class="p-fluid ps-3 pe-3">
                        <InputText id="filter_description" v-model="filter_description"/>
                    </div>
                    <Menu :model="filters_menu_terminal"/>
                    <div class="p-fluid ps-3 pe-3 pb-5">
                        <Dropdown :options="all_terminals" v-model="filter_terminal" optionLabel="name" showClear> </Dropdown>
                    </div>
                </div>
            </div>
            <div class="col main_table">
                <div class="container-fluid" >
                    <TabView v-model:activeIndex="active_index">
                        <TabPanel v-if="$ability.can('add','incidence_report')">
                            <template #header>
                                <i class="fa-solid fa-plus me-2 create_incidence"></i>
                                <span class="create_incidence"> Create Incidence </span>
                            </template>
                            <ModalIncidences :new_incidence="new_incidence"/>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span> General Incidences </span>
                            </template>
                            <!--
                            <div class="container">
                                <div class="row justify-content-between mt-3">
                                    <div class="col-12 md:col-3">
                                        <div class="p-inputgroup">
                                            <Button label="Title" class="p-button-sm"></Button>
                                            <InputText v-model="filter_title"/>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-3">
                                        <div class="p-inputgroup">
                                            <Button label="Terminal" class="p-button-sm "></Button>
                                            <Dropdown :options="all_terminals" v-model="filter_terminal" optionLabel="name" showClear>
                                                
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-3">
                                        <div class="p-inputgroup">
                                            <Button label="Date" class="p-button-sm"></Button>
                                            <Calendar v-model="filter_date" dateFormat="yy-mm-dd" selectionMode="range" :manualInput="false" @date-select="load_new_incidences()"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            -->
                            <div v-if="load_dataview" class="row mt-5 pt-5">
                                <div class="col text-center ">
                                    <i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
                                </div>
                            </div>
                            <div v-else>
                                <DataView :value="get_general_incidences" :layout="layout" paginator :rows="12" class="mt-3">
                                    <template #empty>
                                        <div class="row">
                                            <div class="col text-center">
                                                No records founds.
                                            </div>
                                        </div>
                                    </template>
                                    <template #grid="slotProps">
                                        <div class="col-12 sm:col-6 lg:col-6 xl:col-3 p-2" >
                                            <div class="p-4 border-1 surface-border surface-card border-round" style="cursor:pointer" @click="open_dialog_info(slotProps.data)">
                                                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                                    <div class="flex align-items-center gap-2">
                                                        <span class="font-semibold"> Terminal {{slotProps.data.id_terminal.name}} </span>
                                                    </div>
                                                    <div class="flex align-items-center gap-2">
                                                        <i class="pi pi-calendar"></i>
                                                        <span class="font-semibold"> {{format_date(slotProps.data.date)}} </span>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="flex flex-column align-items-center gap-3 py-5">
                                                    <img class="w-9 border-round card-img-top img-fluid w-100 my-auto" :src="slotProps.data.photo" :alt="slotProps.data.title"/>
                                                    <div v-if="slotProps.data.title.length > 30" class="text-2xl font-bold">{{ slotProps.data.title.substring(0, 25) + '...' }}</div>
                                                    <div data-cy="short_title_incidence" v-else class="text-2xl font-bold">{{ slotProps.data.title }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </DataView>
                            </div> 
                        </TabPanel>
                        <TabPanel>
                            <!--
                            <div class="container">
                                <div class="row justify-content-between mt-3">
                                    <div class="col-12 md:col-3">
                                        <div class="p-inputgroup ">
                                            <Button label="Title" class="p-button-sm"></Button>
                                            <InputText v-model="filter_title"/>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-3">
                                        <div class="p-inputgroup">
                                            <Button label="Terminal" class="p-button-sm"></Button>
                                            <Dropdown :options="all_terminals" v-model="filter_terminal" optionLabel="name" showClear>

                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-3">
                                        <div class="p-inputgroup">
                                            <Button label="Date" class="p-button-sm"></Button>
                                            <Calendar v-model="filter_date" dateFormat="yy-mm-dd" selectionMode="range" :manualInput="false" @date-select="load_new_incidences()"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            -->
                            <template #header>
                                <span> Service&nbsp;Sheet Incidences </span>
                            </template>
                            <div v-if="load_dataview" class="row mt-5 pt-5">
                                <div class="col text-center ">
                                    <i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
                                </div>
                            </div>
                            <div v-else>
                                <DataView :value="get_call_incidences" :layout="layout"  paginator :rows="12" class="mt-3">
                                    <template #empty>
                                        <div class="row">
                                            <div class="col text-center">
                                                No records founds.
                                            </div>
                                        </div>
                                    </template>
                                    <template #grid="slotProps">
                                        <div class="col-12 sm:col-6 lg:col-6 xl:col-3 p-2">
                                            <div class="p-4 border-1 surface-border surface-card border-round" style="cursor:pointer" @click="open_dialog_info(slotProps.data)">
                                                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                                    <div class="flex align-items-center gap-2">
                                                        <span class="font-semibold"> Terminal {{slotProps.data.id_terminal.name}} </span>
                                                    </div>
                                                    <div class="flex align-items-center gap-2">
                                                        <i class="pi pi-calendar"></i>
                                                        <span class="font-semibold"> {{format_date(slotProps.data.date)}} </span>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="flex flex-column align-items-center gap-3 py-5">
                                                    <img class="w-9 border-round card-img-top img-fluid w-100 my-auto" :src="slotProps.data.photo" :alt="slotProps.data.title"/>
                                                    <div v-if="slotProps.data.title.length > 30" class="text-2xl font-bold">{{ slotProps.data.title.substring(0, 25) + '...' }}</div>
                                                    <div v-else class="text-2xl font-bold">{{ slotProps.data.title }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </DataView>
                            </div>
                            <!--
                            <DataTable :value="get_call_incidences" :paginator="true" class="p-datatable-customers" :rows="10"
                            dataKey="id" v-model:filters="filters2" filterDisplay="menu" responsiveLayout="scroll"
                            :globalFilterFields="['type','title']">
                                <Column header="Service Sheet" field="id_service_sheet.id_call.id_ship.name" style="min-width:17rem">
                                    <template #filter="{ filterModel }">
                                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Title"/>
                                    </template>
                                    <template #body="slotProps">
                                        <div v-if="slotProps.data.incidence_type == 'Service Sheet'">
                                            <span style="cursor: pointer;" @click="$router.push({name: 'Service_sheet_template', params: { id: slotProps.data.id_service_sheet.id_call.id, sid: slotProps.data.id_service_sheet.id }})"> <Tag :value="slotProps.data.id_service_sheet.id_call.id_ship.name" rounded ></Tag> </span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Title" field="title" style="min-width:12rem">
                                    <template #body="{data}">
                                        {{data.title}}
                                    </template>
                                    <template #filter="{filterModel}">
                                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Title"/>
                                    </template>
                                </Column>
                                <Column header="Description" field="text" style="min-width:25rem">
                                    <template #body="{data}">
                                        {{data.text}}
                                    </template>
                                    <template #filter="{ filterModel }">
                                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Text"/>
                                    </template>
                                </Column>
                                <Column header="Terminal" field="id_terminal.name" style="min-width:8rem">
                                    <template #body="{data}">
                                        {{data.id_terminal.name}}
                                    </template>
                                    <template #filter="{filterModel}">
                                        <Dropdown v-model="filterModel.value" :options="get_terminal_names" placeholder="Zone" class="p-column-filter" :showClear="true">
                                            <template #value="slotProps">  
                                                <span v-if="slotProps.value">{{slotProps.value}}</span>
                                                <span v-else>{{slotProps.placeholder}}</span>
                                            </template>
                                            <template #option="slotProps">
                                                <span >{{slotProps.option}}</span>
                                            </template>
                                        </Dropdown>
                                    </template>
                                </Column>
                                <Column header="Date" filterField="date" dataType="date" style="min-width:12rem">
                                    <template #body="{data}">
                                        {{format_date(data.date)}}
                                    </template>
                                    <template #filter="{ filterModel }">
                                        <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="yy-mm-dd" placeholder="Date" />
                                    </template>
                                </Column>
                                <Column header="Zone" field="id_incidence_zone.title" style="min-width:12rem">
                                    <template #body="slotProps">
                                        {{slotProps.data.id_incidence_zone.title}} <br>
                                    </template>
                                    <template #filter="{filterModel}">
                                        <Dropdown v-model="filterModel.value" :options="incidence_zones" placeholder="Zone" class="p-column-filter" :showClear="true">
                                            <template #value="slotProps">
                                                <span v-if="slotProps.value">{{slotProps.value}}</span>
                                                <span v-else>{{slotProps.placeholder}}</span>
                                            </template>
                                            <template #option="slotProps">
                                                <span >{{slotProps.option}}</span>
                                            </template>
                                        </Dropdown>
                                    </template>
                                </Column>
                                <Column header="Category" field="id_incidence_category.title" style="min-width:12rem">
                                    <template #body="slotProps">
                                        {{slotProps.data.id_incidence_category.title}}
                                    </template>
                                    <template #filter="{filterModel}">
                                        <Dropdown v-model="filterModel.value" :options="incidence_categories" placeholder="Category" class="p-column-filter" :showClear="true">
                                            <template #value="slotProps">
                                                <span v-if="slotProps.value">{{slotProps.value}}</span>
                                                <span v-else>{{slotProps.placeholder}}</span>
                                            </template>
                                            <template #option="slotProps">
                                                <span >{{slotProps.option}}</span>
                                            </template>
                                        </Dropdown>
                                    </template>
                                </Column>
                                <Column header="Subcategory" field="id_incidence_subcategory.title" style="min-width:12rem">
                                    <template #body="slotProps">
                                        {{slotProps.data.id_incidence_subcategory.title}} <br>
                                    </template>
                                    <template #filter="{filterModel}">
                                        <Dropdown v-model="filterModel.value" :options="incidence_subcategories" placeholder="Category" class="p-column-filter" :showClear="true">
                                            <template #value="slotProps">
                                                <span v-if="slotProps.value">{{slotProps.value}}</span>
                                                <span v-else>{{slotProps.placeholder}}</span>
                                            </template>
                                            <template #option="slotProps">
                                                <span >{{slotProps.option}}</span>
                                            </template>
                                        </Dropdown>
                                    </template>
                                </Column>
                                <Column header="Responsible" field="id_user_responsible.username" style="min-width:12rem">
                                    <template #filter="{ filterModel }">
                                        <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Description"/>
                                    </template>
                                </Column>
                                <Column header="Creator" field="id_user_creator.username" style="min-width:12rem">
                                    <template #filter="{ filterModel }">
                                        <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Description"/>
                                    </template>
                                </Column>
                                <Column header="Picture">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-eye" class="p-button-rounded p-button-text p-button-info" @click="open_modal_picture(slotProps.data)"/>
                                    </template>
                                </Column>
                                <Column v-if="$ability.can('change','incidence_report')" header="Edit">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-text" @click="edit_incidence_report(slotProps.data)"/>
                                    </template>
                                </Column>
                                <Column v-if="$ability.can('delete','incidence_report')" header="Delete">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text" @click="delete_incidence_report(slotProps.data)"/>
                                    </template>
                                </Column>
                                <template #empty>
                                    <div class="row">
                                        <div class="col text-center">
                                            No records.
                                        </div>
                                    </div>
                                </template>
                            </DataTable>
                            -->
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
        <div class="menu-mobile justify-content-end">
            <div class="col">
                <Button icon="fa fa-bars" class="sidebar-button p-button-raised p-button-rounded color-button-menu" style="color: white;" @click="visibleRight = true"/>
            </div>
        </div>
    </div>

    <Sidebar v-model:visible="visibleRight" position="left">
        <Menu :model="items"/>    
        <div v-if="active_index != 0" class="mt-5">
            <Menu :model="filters_menu"></Menu>
            <Menu :model="filters_menu_title"/>
            <div class="flex p-fluid ps-3 pe-3">
                <InputText v-model="filter_title"/>
            </div>
            <Menu :model="filters_menu_terminal"/>
            <div class="p-fluid ps-3 pe-3">
                <Dropdown :options="all_terminals" v-model="filter_terminal" optionLabel="name" showClear> </Dropdown>
            </div>
            <Menu :model="filters_menu_description"/>
            <div class="p-fluid ps-3 pe-3">
                <InputText v-model="filter_description"/>
            </div>
            <Menu :model="filters_menu_date"/>
            <div class="p-fluid ps-3 pe-3">
                <Calendar v-model="filter_date" dateFormat="yy-mm-dd" selectionMode="range" :manualInput="false" @date-select="load_new_incidences()"/>
            </div>
            <div class="flex justify-content-end mt-3 me-3 mb-2">
                <Button class="p-button-raised p-button-text" icon="pi pi-chevron-right" @click="visibleRight = false"></Button>
            </div>
        </div>
    </Sidebar>



    <!-- Dialog Info-->

    <Dialog data-cy="info_incidence_modal" v-model:visible="info_incidence_report_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '80vw'}" header="Info Incidence Report" :modal="true" class="p-fluid" @hide="hide_incidence_report_dialog()">
        <div v-if="edit_mode == false" class="container-fluid">
            <div class="row justify-content-between">
                <div class="col-12 lg:col-5">
                    <div class="row">
                        <div class="col">
                            <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Picture </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <img class="w-9 border-round card-img-top-modal img-fluid w-100 my-auto" :src="incidence_report.photo" />
                        </div>
                    </div>
                    <div v-if="$ability.can('delete','incidence_report')" class="row justify-content-center">
                        <div class="col-12 md:col-4">
                            <Button data-cy="delete_incidence_button" label="Delete" class="p-button-danger" @click="delete_incidence_report()"></Button>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col">
                    <div class="row">
                        <div class="col">
                            <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Information </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> TITLE </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong>  {{ incidence_report.title }} </strong> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> INCIDENCE TYPE </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong>  {{ incidence_report.incidence_type }} </strong> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> TERMINAL </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong>  {{ incidence_report.id_terminal.name }} </strong> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> AUTHOR </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong>  {{ incidence_report.id_user_creator.username }} </strong> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> USER RESPONSIBLE </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong v-if="incidence_report.id_user_responsible">  {{ incidence_report.id_user_responsible.username }} </strong> 
                            <strong v-else> - </strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Schedule </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> DATE </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong>  {{ format_time(incidence_report.date) }} </strong> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> HOUR </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong>  {{ incidence_report.hour }} </strong> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Details </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> ZONE </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong>  {{ incidence_report.id_incidence_zone.title }} </strong> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> CATEGORY </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong>  {{ incidence_report.id_incidence_category.title }} </strong> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> SUBCATEGORY </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong>  {{ incidence_report.id_incidence_subcategory.title }} </strong> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> DESCRIPTION </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong>  {{ incidence_report.text }} </strong> 
                        </div>
                    </div>
                    <div v-if="incidence_report.incidence_type=='Service Sheet'" class="row">
                        <div class="col-12 md:col">
                            <small style="color: gray"> SERVICE SHEET </small> 
                        </div>
                        <div class="col-12 md:col-9">
                            <strong> <Tag  style="cursor: pointer;" @click="$router.push({name: 'Service_sheet_template', params: { id: incidence_report.id_service_sheet.id_call.id, sid: incidence_report.id_service_sheet.id }})"> {{format_time(incidence_report.estimated_arrival_datetime)}} - {{incidence_report.id_service_sheet.id_call.id_ship.name}} </Tag> </strong> 
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div v-else class="container">
            <div class="row pt-4">
                <div class="col-12 md:col mt-3">
                    <div class="p-float-label">
                        <InputText id="edit_title" v-model="incidence_report.title" required="true" autofocus :class="{'p-invalid': submitted && !incidence_report.title || incidence_report_errors.title}"/>
                        <label for="edit_title"> Title </label>
                    </div>
                    <small class="p-error" v-if="submitted && !incidence_report.title">Title is required.</small>
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.title" class="p-error"><span v-for="error in incidence_report_errors.title" :key="error">{{error}}<br></span></small>
                </div>
                <div class="col-12 md:col mt-3">
                    <div class="p-float-label">
                        <Dropdown id="incidence_type" v-model="incidence_report.incidence_type" :options="incidence_type_choices" :filter="true">
                            <template #value="slotProps">
                                <div v-if="slotProps.value && slotProps.value.name">
                                    <span>{{slotProps.value.name}}</span>
                                </div>
                                <div v-else-if="slotProps.value && !slotProps.value.name">
                                    <span >{{slotProps.value}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <label for="incidence_type" :class="{'p-invalid': submitted && !incidence_report.incidence_type || incidence_report_errors.incidence_type}"> Incidence type </label>
                    </div>
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.incidence_type" class="p-error"><span v-for="error in incidence_report_errors.incidence_type" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="row">
                <div class="col-12 md:col mt-3">
                    <div class="p-float-label">
                        <Calendar autocomplete="off" id="date" v-model="incidence_report.date" required="true" dateFormat="yy-mm-dd" autofocus :class="{'p-invalid': submitted && !incidence_report.date || incidence_report_errors.date}"/>
                        <label for="date"> Date </label>
                    </div>
                    <small class="p-error" v-if="submitted && !incidence_report.date">Date is required.</small>
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.date" class="p-error"><span v-for="error in incidence_report_errors.date" :key="error">{{error}}<br></span></small>
                </div>
                <div class="col-12 md:col mt-3">
                    <div class="p-float-label">
                        <Calendar autocomplete="off" id="hour" :timeOnly="true" v-model="incidence_report.hour" required="true" autofocus :class="{'p-invalid': submitted && !incidence_report.hour || incidence_report_errors.hour}" @change="incidence_report.hour = num2timeedit($event)"/>
                        <label for="hour"> Hour </label>
                    </div>
                    <small class="p-error" v-if="submitted && !incidence_report.hour">Date is required.</small>
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.hour" class="p-error"><span v-for="error in incidence_report_errors.hour" :key="error">{{error}}<br></span></small>
                </div>
                <div class="col-12 md:col mt-3">
                    <div class="p-float-label">
                        <Dropdown id="id_terminal" v-model="incidence_report.id_terminal" :options="all_terminals" optionLabel="name" :filter="true"> 
                        <template #value="slotProps">
                            <div v-if="slotProps.value && slotProps.value.value">
                                <span>{{slotProps.value.label}}</span>
                            </div>
                            <div v-else-if="slotProps.label && !slotProps.value.value">
                                <span >{{slotProps.name}}</span>
                            </div>
                        </template>
                        </Dropdown>
                        <label for="terminal" :class="{'p-invalid': submitted && !incidence_report.id_terminal || incidence_report_errors.id_terminal}"> Terminal </label>  
                    </div>
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_terminal" class="p-error"><span v-for="error in incidence_report_errors.id_terminal" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="row">
                <div class="col-12 md:col mt-3">
                    <div class="p-float-label">
                        <Dropdown id="id_incidence_zone" v-model="incidence_report.id_incidence_zone.id" :options="all_incidence_zones" optionLabel="title" optionValue="id" :filter="true" placeholder="Select zone"> 
                        <template #value="slotProps">
                            <div v-if="slotProps.value && slotProps.value.value">
                                <span>{{slotProps.value.label}}</span>
                            </div>
                            <div v-else-if="slotProps.label && !slotProps.value.value">
                                <span >{{slotProps.value.title}}</span>
                            </div>
                        </template>
                        </Dropdown>
                        <label for="incidence_zone" :class="{'p-invalid': submitted && !incidence_report.id_incidence_zone || incidence_report_errors.id_incidence_zone}"> Incidence zone </label>  
                    </div>
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_zone" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_zone" :key="error">{{error}}<br></span></small>
                </div>
                <div class="col-12 md:col mt-3">
                    <div class="p-float-label">
                        <Dropdown id="id_incidence_category" v-model="incidence_report.id_incidence_category.id" :options="all_incidence_categories" optionLabel="title"  optionValue="id" :filter="true" placeholder="Select category"> 
                        <template #value="slotProps">
                            <div v-if="slotProps.value && slotProps.value.value">
                                <span>{{slotProps.value.label}}</span>
                            </div>
                            <div v-else-if="slotProps.label && !slotProps.value.value">
                                <span >{{slotProps.value.title}}</span>
                            </div>
                        </template>
                        </Dropdown>
                        <label for="incidence_category" :class="{'p-invalid': submitted && !incidence_report.id_incidence_category || incidence_report_errors.id_incidence_category}"> Incidece category </label>  
                    </div>
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_category" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_category" :key="error">{{error}}<br></span></small>
                </div>
                <div class="col-12 md:col mt-3">
                    <div class="p-float-label">
                        <Dropdown id="id_incidence_subcategory" v-model="incidence_report.id_incidence_subcategory.id" :options="all_incidence_subcategories" optionLabel="title" optionValue="id" :filter="true" placeholder="Select subcategory"> 
                        <template #value="slotProps">
                            <div v-if="slotProps.value && slotProps.value.value">
                                <span>{{slotProps.value.label}}</span>
                            </div>
                            <div v-else-if="slotProps.label && !slotProps.value.value">
                                <span >{{slotProps.value.title}}</span>
                            </div>
                        </template>
                        </Dropdown>
                        <label for="incidence_subcategory" :class="{'p-invalid': submitted && !incidence_report.id_incidence_subcategory || incidence_report_errors.id_incidence_subcategory}"> Incidence subcategory </label>  
                    </div>    
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_incidence_subcategory" class="p-error"><span v-for="error in incidence_report_errors.id_incidence_subcategory" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="row">
                <div class="col-12 md:col mt-3">
                    <div class="p-float-label" >
                        <TextArea id="text" v-model="incidence_report.text" required="true" rows="4" autofocus :class="{'p-invalid': submitted && !incidence_report.text || incidence_report_errors.text}"/>
                        <label for="text"> Text </label>
                    </div>
                    <small class="p-error" v-if="submitted && !incidence_report.text">Text is required.</small>
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.text" class="p-error"><span v-for="error in incidence_report_errors.text" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="row">
                <div v-if="incidence_report.id_user_responsible" class="col-12 md:col mt-3">
                    <div class="p-float-label">
                        <Dropdown id="id_user" v-model="incidence_report.id_user_responsible.id" :options="get_users" optionLabel="username" optionValue="id" :filter="true"/> 
                        <label for="id_user" :class="{'p-invalid': submitted && !incidence_report.id_user_responsible || incidence_report_errors.id_user_responsible}"> User </label>  
                    </div>    
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_user_responsible" class="p-error"><span v-for="error in incidence_report_errors.id_user_responsible" :key="error">{{error}}<br></span></small>
                </div>
                <div v-else class="col-12 md:col mt-3">
                    <div class="p-float-label">
                        <Dropdown id="id_user" v-model="incidence_report.id_user_responsible" :options="get_users" optionLabel="username" optionValue="id" :filter="true"/> 
                        <label for="id_user" :class="{'p-invalid': submitted && !incidence_report.id_user_responsible || incidence_report_errors.id_user_responsible}"> User </label>  
                    </div>    
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_user_responsible" class="p-error"><span v-for="error in incidence_report_errors.id_user_responsible" :key="error">{{error}}<br></span></small>
                </div>
                <div class="col-12 md:col mt-3">
                    <div v-if="incidence_report.incidence_type=='Service Sheet'" class="p-float-label">
                        <Dropdown id="service_sheet" v-model="incidence_report.id_service_sheet" :options="all_service_sheets" optionLabel="id" :filter="true"> 
                            <template #value="slotProps">
                                <div v-if="!slotProps.value">
                                    <span> - </span>
                                </div>
                                <div v-if="slotProps.value">
                                    <span>{{format_date(slotProps.value.estimated_arrival_datetime)}} - {{slotProps.value.id_call.id_ship.name}}</span>
                                </div>
                            </template>
                            <template #option="slotProps">
                                <div> {{format_date(slotProps.option.estimated_arrival_datetime)}} - {{slotProps.option.id_call.id_ship.name}} </div>
                            </template>
                        </Dropdown>
                        <label for="service_sheet" :class="{'p-invalid': submitted && !incidence_report.id_service_sheet || incidence_report_errors.id_service_sheet}"> Service Sheet </label>  
                    </div>
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.id_service_sheet" class="p-error"><span v-for="error in incidence_report_errors.id_service_sheet" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="row">
                <div class="col text-center mt-3">
                    <div class="p-float-label">
                        <FileUpload mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUpload" />
                    </div>
                    <small v-if="Object.keys(incidence_report_errors).length !== 0 && incidence_report_errors.photo" class="p-error"><span v-for="error in port_errors.photo" :key="error">{{error}}<br></span></small>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Close" class="p-button-text" @click="hide_incidence_report_dialog"/>
            <span v-if="edit_mode == false">
                <Button data-cy="edit_incidence_button" label="Edit" @click="edit_mode = true" />
            </span>
            <span v-else>
                <Button data-cy="save_incidence_button" label="Save" @click="save_incidence_report" />
            </span>
            
        </template>
    </Dialog>


    <!-- Dialog delete incidence -->

    <Dialog v-model:visible="delete_incidence_report_dialog" :style="{width: '50rem'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="incidence_report">Are you sure you want to delete <b>{{incidence_report.title}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_incidence_report_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deleteIncidence_reports" />
        </template>
    </Dialog>

    <Galleria :value="pictures" :numVisible="1" containerStyle="max-width: 850px"
    :circular="true" :fullScreen="true" :showThumbnails="false" v-model:visible="display_basic">
        <template #item="slotProps">
            <img :src="slotProps.item" style="width: 100%; display: block;" />
        </template>
    </Galleria>

</template>

<script> 
import ModalIncidences from '@/components/Incidences/ModalIncidences.vue'

import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
//import DataTable from 'primevue/datatable'
//import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import Dialog from 'primevue/dialog'
import FileUpload from 'primevue/fileupload'
import TextArea from 'primevue/textarea'
import Tag from 'primevue/tag'
import Galleria from 'primevue/galleria'
import DataView from 'primevue/dataview'
import Menu from 'primevue/menu'
import Sidebar from 'primevue/sidebar'

import ability from '../../services/ability'

import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'
import moment from 'moment'

import { num2timeedit } from '@/modules/utilities.js'

export default {
    setup: () => ({ v$: useVuelidate() }),
    title: 'TableIncidence_reports',
    components: {
        ModalIncidences,
        TabView,
        TabPanel,
        //DataTable,
        //Column,
        Button,
        InputText,
        Toast,
        Dropdown,
        Calendar,
        Dialog,
        FileUpload,
        TextArea,
        Tag,
        Galleria,
        DataView,
        Menu,
        Sidebar
    },
    data() {
        return {
            items: [
                {
                    label: 'INCIDENCES',
                    items: [
                    {
                            label: 'Create Incidence',
                            visible: () => {
                                if(ability.can('add', 'incidence_report')){
                                    return true
                                }
                            },
                            command: () => {
                                this.active_index = 0
                            }
                        },
                        {
                            label: 'General Incidences',
                            command: () => {
                                if(ability.can('add', 'incidence_report')){
                                    this.active_index = 1
                                } else {
                                    this.active_index = 0
                                }
                                
                            }
                        },
                        {
                            label: 'Service Sheet Incidences',
                            command: () => {
                                if(ability.can('add', 'incidence_report')){
                                    this.active_index = 2
                                } else {
                                    this.active_index = 1
                                }
                            }
                        },
                    ]
                }
            ],
            filters_menu_title: [
                {
                    label: 'TITLE',
                    items: []
                }
            ],
            filters_menu_terminal: [
                {
                    label: 'TERMINAL',
                    items: []
                }
            ],
            filters_menu_date: [
                {
                    label: 'DATE',
                    items: []
                }
            ],
            filters_menu_description: [
                {
                    label: 'DESCRIPTION',
                    items: []
                }
            ],
            active_index: 0,
            visibleRight: false,
            load_dataview: false,
            layout: 'grid',
            edit_mode: false,
            pictures: [],
            new_incidence: [],
            incidence_report: {},
            incidence_report_errors:{},
            photo: '',
            incidence_type_choices:[
                "General", "Service Sheet"
            ],
            filter_terminal: null,
            filter_date: [new Date(moment().isoWeekday(1).format('YYYY-MM-DD')), new Date(moment().isoWeekday(7).format('YYYY-MM-DD'))],
            filter_title: '',
            filter_description: '',
            filters: {
                'id_terminal.name' : {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'title': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'text': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'id_incidence_zone.title': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'id_incidence_category.title': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'id_incidence_subcategory.title': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'id_user_creator.username': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'id_user_responsible.username': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]}
            },
            filters2: {
                'id_terminal.name' : {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'title': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'text': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'id_incidence_zone.title': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'id_incidence_category.title': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'id_incidence_subcategory.title': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'id_user_creator.username': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'id_user_responsible.username': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'id_service_sheet.id_call.id_ship.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]}
            },
            delete_incidence_report_dialog: false,
            info_incidence_report_dialog: false,
            submitted: false,
            color: localStorage.getItem('primary_color'),
            display_basic: false
        }
    },
    async mounted() {
        this.load_dataview = true

        await this.$store.dispatch('load_incidences_between_dates', this.filter_date)
        
        if(Object.keys(this.$route.params).length != 0) {
            this.new_incidence = {
                date: this.$route.params.date,
                hour: this.$route.params.hour,
                text: this.$route.params.text,
                terminal: JSON.parse(this.$route.params.terminal),
            }
        }

        this.load_dataview = false
    },
    computed: {
        all_incidence_reports: function() {
            return this.$store.getters.all_incidence_reports
        },
        all_incidence_zones: function() {
            return this.$store.getters.all_incidence_zones
        },
        all_incidence_categories: function() {
            return this.$store.getters.all_incidence_categories
        },
        all_incidence_subcategories: function() {
            return this.$store.getters.all_incidence_subcategories
        },
        all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        get_errors_incidence_report: function() {
            return this.$store.getters.get_errors_incidence_report
        },
        get_general_incidences: function() {
            return this.$store.getters.get_general_incidences.filter(item => {
                return item.title.toLowerCase().includes(this.filter_title.toLowerCase()) 
                && (!this.filter_terminal || item.id_terminal.id === this.filter_terminal.id)
                && item.text.toLowerCase().includes(this.filter_description.toLowerCase()) 
            })
        },
        get_call_incidences: function() {
            return this.$store.getters.get_call_incidences.filter(item => {
                return item.title.toLowerCase().includes(this.filter_title.toLowerCase()) 
                && (!this.filter_terminal || item.id_terminal.id === this.filter_terminal.id) 
                && item.text.toLowerCase().includes(this.filter_description.toLowerCase()) 
            })
        },
        get_incidences_between_dates: function() {
            return this.$store.getters.get_incidences_between_dates
        },
        get_terminal_names: function() {
            return this.$store.getters.get_terminal_names
        },
        get_zone_names: function() {
            return this.$store.getters.get_zone_names
        },
        get_category_names: function() {
            return this.$store.getters.get_category_names
        },
        get_subcategory_names: function() {
            return this.$store.getters.get_subcategory_names
        },
        all_service_sheets: function() {
            return this.$store.getters.all_service_sheets
        },
        get_users: function() {
            return this.$store.getters.users_by_port
        },
    },
    methods: {
        num2timeedit(data) {
            return num2timeedit(data)
        },
        onUpload(e){
            this.photo = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        format_date(value) {
            if(value instanceof Date){
                return value.toISOString().split('T')[0]
            } else {
                return value.split('T')[0]
            }
        },
        async load_new_incidences () {
            if(this.filter_date[1] != null) {
                this.load_dataview = true
                await this.$store.dispatch('load_incidences_between_dates', this.filter_date)
                this.load_dataview = false
            }
            
        },
        async save_incidence_report() {
            this.submitted = true;

            if(this.photo != ''){
                this.incidence_report.photo = this.photo
            }
            
            if(this.incidence_report.incidence_type == 'General') {
                this.incidence_report.id_service_sheet = null;
            }

            if(this.incidence_report.id_service_sheet == null) {
                if(this.incidence_report.incidence_type == 'Service Sheet') {
                    this.submitted = false;
                } else {
                    await this.$store.dispatch('update_incidence_report', this.incidence_report)
                    this.incidence_report_errors = this.get_errors_incidence_report

                    if(Object.keys(this.incidence_report_errors).length === 0){
                        this.stop_editing()
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence report modified', life: 3000});
                    }
                }   
            } else if (this.incidence_report.id_service_sheet != null) {
                await this.$store.dispatch('update_incidence_report', this.incidence_report)
                this.incidence_report_errors = this.get_errors_incidence_report
                
                if(Object.keys(this.incidence_report_errors).length === 0){
                    this.stop_editing()
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence report modified', life: 3000});
                }
            }

            //this.edit_mode = false
            //this.info_incidence_report_dialog = false;
        },
        stop_editing() {
            this.edit_mode = false
            this.submitted = false;
        },
        hide_incidence_report_dialog() {
            this.info_incidence_report_dialog = false;
            this.edit_mode = false
            this.submitted = false;
            this.incidence_report = {};
        },
        delete_incidence_report() {
            this.delete_incidence_report_dialog = true;
        },
        async deleteIncidence_reports() {
            await this.$store.dispatch('delete_incidence_report', this.incidence_report.id)
            if (!this.get_delete_incidence_report_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence report deleted', life: 3000});
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Incidence report can't be deleted", life: 3000});
            }
            this.delete_incidence_report_dialog = false
            this.info_incidence_report_dialog = false
        },
        async open_modal_picture(incidence_report) {
            this.pictures = []
            this.incidence_report = {...incidence_report};
            if(this.incidence_report.photo == '') {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "This incidence does not have a picture", life: 3000});
            } else {
                this.pictures.push(this.incidence_report.photo)
                this.display_basic = true; 
            }
            
        },
        open_dialog_info(data) {
            this.incidence_report = {...data};
            this.info_incidence_report_dialog = true
        },
        format_time(value){
            return moment(value).format('YYYY-MM-DD')
        }
    },
    validations() {
        return {
            title: { required },
            text: { required },
            incidence_type: { required },
            date: { required },
            hour: { required },
            id_terminal: { required }
        }
    },

}
</script>

<style scoped>

.side_bar_custom{
    position: fixed;
    width: 300px;
    height: calc(100vh - 11rem);
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: 9rem;
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.side_bar_custom_small{
    position: fixed;
    width: 300px;
    height: calc(100vh - 36rem);
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: 9rem;
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.side_bar_custom_bottom{
    position: fixed;
    width: 300px;
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: calc(100vh - 26rem);
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.main_table {
    margin-left: 300px;
    flex-direction: column;
    min-height: calc(100vh - 9rem);
    justify-content: space-between;
    padding-left: 2rem;
    transition: margin-left 0.2s;
}

@media (min-width: 1300px) {
    .menu-mobile {
        display: none;

    }

    :deep(.p-scrollpanel .p-scrollpanel-bar) {
        background-color: #a8a8a8;
        transition: #a8a8a8 .2s;
    }

    :deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
        background-color: #a8a8a8;
    }
}

@media (max-width: 1301px) {
    .side_bar_custom {
        display: none;
    }

    .side_bar_custom_bottom {
        display: none;
    }

    .side_bar_custom_small {
        display: none;
    }

    .main_table{
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
}

.sidebar-button {
    border-radius: 10px;
    left: 82vw !important;
    top: 90% !important;
    position: fixed !important;
    z-index: 1000;
}

:deep(.p-scrollpanel .p-scrollpanel-bar) {
    background-color: #a8a8a8;
    transition: #a8a8a8 .2s;
}    

:deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
    background-color: #a8a8a8;
}

:deep(.p-menu) {
    border-radius: 0px;
    background: rgba(0,0,0,0); 
    padding: 0;
}

:deep(.p-menu .p-menuitem-link) {
    margin-left: 0px;
}


.scroll_menu{
    background-color: v-bind(opacity_color);
}

:deep(.p-scrollpanel-content){
    padding: 0;

}

:deep(.p-menu .p-submenu-header){
    background-color: rgba(0,0,0,0); 
    border-radius: 6px;
}

/*
:deep(.bm-item-list) {
    margin-left: 0px !important;
}

:deep(.bm-menu) {
    background-color: #eaeaea;
    box-shadow: 0 9px 10px -1px rgba(0,0,0,.2), 0 9px 10px 0 rgba(0,0,0,.14), 0 4px 5px 0 rgba(0,0,0,.12);
}
*/

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
}

.color-button-menu{
    color: white;
    background-color: v-bind(color);
    border-color: v-bind(color);
}

.color-button-menu:hover{
    background-color: v-bind(color) !important;
    border-color: v-bind(color) !important; 
    opacity: .8;
}

:deep(.router-link-active .p-menuitem-text){
    color: v-bind(color) !important;
    font-weight: 700;
}

:deep(.router-link-active .p-menuitem-icon){
    color: v-bind(color) !important;
}

:deep(.p-menu .p-menuitem-link:not(.p-disabled):hover){
    border-radius: 10px;
}

:deep(.p-scrollpanel-bar-x){
    height: 0px;
}

.p-datatable:deep(.p-datatable-header){
    border: 0px;
}

.p-datatable:deep(.p-datatable-thead > tr > th) {
    border: 0px;
}

.p-tabview:deep(.p-tabview-nav li.p-highlight .p-tabview-nav-link) {
    color: v-bind(color) !important;
    border-color: v-bind(color) !important;
}

.p-tabview:deep(.p-tabview-nav .p-tabview-ink-bar) {
    background-color: v-bind(color) !important;
}
/*
.p-tabview:deep(.p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus) {
    box-shadow: inset 0 0 0 0.1rem v-bind(color) !important;
}
*/

.create_incidence {
    color: green !important;
}

.p-tabview:deep(.p-column-filter-overlay) {
    border: v-bind(color);
}

.card-img-top {
    max-height: 150px;
    min-height: 150px;
    object-fit: scale-down;
}

.card-img-top-modal {
    max-height: 450px;
    min-height: 450px;
    object-fit: scale-down;
}

:deep(.p-inputgroup .p-dropdown .p-dropdown-label) {
    margin: auto;
}

</style>

