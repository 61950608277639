<template>
    <div class="card">
        <div class="row">
            <Toast/>
            <DataTable :value="all_ship_responsibles" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading" removableSort
            :globalFilterFields="['name']"
            v-model:filters="filters" >
                <template #header>
                    <div class="row">
                        <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                            <span class="p-input-icon-left ">
                                <i class="pi pi-search" />
                                <InputText id="filter_ship_responsibles" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                            </span>
                        </div>
                    </div>
                </template>
                <Column header="Name" field="name" :sortable="true" style="min-width: 20rem;"/>
                <Column header="Phone" field="phone" :sortable="true" style="min-width: 20rem;"/>
                <Column header="Ship Agent" field="id_ship_agent.name" :sortable="true" style="min-width: 20rem;">
                </Column> 
                <Column v-if="$can('change','ship_responsible')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_ship_responsibles(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','ship_responsible')" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_ship_responsibles(slotProps)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_ship_responsible_modal" v-model:visible="ship_responsibles_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Ship responsible Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="name" class="ps-1 pb-1"> <strong> Name </strong> </label>
                        <InputText id="name" v-model.trim="ship_responsibles.name" required="true" autofocus :class="{'p-invalid': submitted && !ship_responsibles.name || ship_responsibles_errors.name}" />
                        <small v-if="Object.keys(ship_responsibles_errors).length !== 0 && ship_responsibles_errors.name" class="p-error"><span v-for="error in ship_responsibles_errors.name" :key="error">{{error}}<br></span></small>
                        <small class="p-error" v-if="submitted && !ship_responsibles.name">Name is required.</small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="phone" class="ps-1 pb-1"> <strong> Phone </strong> </label>
                        <InputText id="phone" v-model.trim="ship_responsibles.phone" required="true" autofocus :class="{'p-invalid': submitted && !ship_responsibles.phone || ship_responsibles_errors.phone}" />
                        <small v-if="Object.keys(ship_responsibles_errors).length !== 0 && ship_responsibles_errors.phone" class="p-error"><span v-for="error in ship_responsibles_errors.phone" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="ship agent" class="ps-1 pb-1"> <strong>Ship agent</strong> </label>  
                        <Dropdown id="id_agent_agent" v-model="ship_responsibles.id_ship_agent" :options="all_ship_agents" optionLabel="ship_agent" :filter="true" :class="{'p-invalid': submitted && !ship_responsibles.phone || ship_responsibles_errors.phone}">
                            <template #value="slotProps">
                                <div v-if="slotProps.value && !slotProps.value.value">
                                    <span>{{slotProps.value.name}}</span>
                                </div>
                                <div v-else-if="slotProps.label && slotProps.value.value">
                                    <span>{{slotProps.option.name}}</span>
                                </div>
                            </template>
                            <template #option="slotProps">
                                <div>{{slotProps.option.name}} </div>
                            </template>
                        </Dropdown>
                        <small v-if="Object.keys(ship_responsibles_errors).length !== 0 && ship_responsibles_errors.id_ship_agent" class="p-error"><span v-for="error in ship_responsibles_errors.id_ship_agent" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_ship_responsibles_dialog"/>
            <Button data-cy="save_edit_ship_responsible" label="Save"  @click="save_ship_responsibles" />
        </template>
        
    </Dialog>

    <Dialog v-model:visible="delete_ship_responsibles_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="ship_responsibles">Are you sure you want to delete <b>{{ship_responsibles.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_ship_responsibles_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deleteship_responsibles" />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import { FilterMatchMode } from 'primevue/api';
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: "Tableship_responsibles",    
    components: {
        DataTable,
        Column,
        Dropdown,
        InputText,
        Button,
        Toast,
        Dialog,
    },
    data() {
        return {
            ship_responsibles: {},
            ship_responsibles_errors: {},
            ship_responsibles_dialog: false,
            delete_ship_responsibles_dialog: false,
            submitted: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_ship_responsibles.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_ship_responsibles')
            this.loading = false
        }
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_ship_responsibles: function() {
            return this.$store.getters.all_ship_responsibles
        },
        all_ship_agents: function(){
            return this.$store.getters.all_ship_agents
        },
        get_ship_responsibles_errors: function() {
            return this.$store.getters.get_ship_responsibles_errors
        },
        get_delete_ship_responsibles_status: function() {
            return this.$store.getters.get_delete_ship_responsibles_status
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        confirm_delete_ship_responsibles(ship_responsibles) {
            this.ship_responsibles = ship_responsibles.data;
            this.delete_ship_responsibles_dialog = true;
        },
        async deleteship_responsibles() {
            await this.$store.dispatch('delete_ship_responsibles', this.ship_responsibles.id)
            if (!this.get_delete_ship_responsibles_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Ship responsibles deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Ship responsible can't be deleted", life: 3000});
            }
            this.delete_ship_responsibles_dialog = false;
            
        },
        edit_ship_responsibles(ship_responsibles) {
            this.ship_responsibles = {...ship_responsibles};
            this.ship_responsibles_dialog = true;
            
        },
        async save_ship_responsibles() {
            this.submitted = true;
			if (this.ship_responsibles.name.trim()) {

                await this.$store.dispatch('update_ship_responsibles', this.ship_responsibles)
                this.ship_responsibles_errors = this.get_ship_responsibles_errors
                if(Object.keys(this.ship_responsibles_errors).length === 0){
                    this.hide_ship_responsibles_dialog()
                    this.ship_responsibles = {};
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Ship responsible modified', life: 3000});
                }
            }
        },
        hide_ship_responsibles_dialog() {
            this.ship_responsibles_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            name: { required },
            phone: { required },
            id_ship_agent: { required },
        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>