<template>
    <ScrollPanel >
        <div class="d-flex mt-5" style="min-width: 1000px;">
            <span class="col">
                <div v-for="(stat,index) in statistics" :key="stat" > 
                    <span v-if="index == 'calls'" >
                        <div class="row mb-3 text-center">
                            <div class="container">
                                <div class="card color_opacity">
                                    <strong class="m-1"> Month </strong>
                                </div>
                            </div>
                        </div>
                        <div v-for="(value, key, index) in stat" :key="key" class="row" :class="{'back_odd': index % 2 == 0 }">
                            <div v-if="key != 'total' && key != 'percentage'" class="col text-center">
                                <span>{{key}}</span>
                            </div>
                        </div>
                    </span>
                </div>
            </span>
            <div v-for="(stat, index, number) in statistics" :key="stat" class="col">
                <span v-if="current_port == 'Port de Barcelona' || current_port == 'Puerto de Málaga' && index != 'water'">
                    <div class="row mb-3 text-center">
                        <div class="container">
                            <div class="card color_opacity">
                                <strong class="m-1 ">{{format_title(index)}}<i :class="icon_items[number].icon" class="ms-2 my-auto"></i> </strong>
                            </div>
                        </div>
                    </div>
                    <div v-for="(value, key, numeric_index) in stat" :key="key" class="row " :class="{'back_odd': numeric_index % 2 == 0 }" >
                        <div v-if="key != 'total' && key != 'percentage'" class="col text-center">
                            <span v-if="index == 'water' && value != 0">
                                <span class="water_meter" @click="open_dialog_water_meter(key)">{{ value }}</span>
                            </span>
                            <span v-else>
                                <span> {{value}} </span>
                            </span>
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <div class="card">
                                <span class="m-1">
                                    <span v-if="stat.percentage.charAt(0) != '-'"> <span style="color: green">{{stat.percentage}}</span> - <strong>{{stat.total}}</strong>  </span>
                                    <span v-else> <span style="color: red">{{stat.percentage}}</span> - <strong>{{stat.total}}</strong>  </span>
                                </span>
                            </div>
                        </div>  
                    </div>
                </span>
                
            </div>
        </div>  
    </ScrollPanel>
    
    <!--
    <div v-for="(stat, index) in statistics" :key="stat" class="col-4 mt-2">
        <div class="container me-2">
            <div class="mt-2 card">
                <div class="row">
                    <div class="col align-self-end text-center">
                        <h3 style="margin-bottom: 0px;"> {{format_title(index)}} </h3>
                    </div>
                    <div class="col align-self-end text-center">
                        <h3 style="margin-bottom: 0px;"> {{stat.total}} </h3>
                    </div>
                    <div class="col align-self-center text-center">
                        <h6 style="color: green;" class="my-auto"> {{stat.percentage}} </h6>
                    </div>
                </div>
            </div>
            <div class="container my-auto card">
                <div class="row mt-1 mb-2 ">
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center ">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Jan </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['January']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Feb </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['February']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Mar </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['March']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Apr </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['April']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> May </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['May']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Jun </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['June']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Jul </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['July']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Aug </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['August']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Sep </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['September']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Oct </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['October']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Nov </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['November']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center ">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="card" style="border-top: 7px solid #1a73e8;">
                                    <small style="margin-top: 4px;"> Dec </small>
                                    <p style="margin-bottom: 4px;"> <strong>{{stat['December']}}</strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    -->

    <Dialog header="Water meter statistic" v-model:visible="display_water_meter_dialog" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '70vw'}" :modal="true">
        <div class="container">
            <div class="row">
                <div class="col">
                    <span>Graphic of the water meters of all terminals for {{ month }} {{ year }}</span>
                </div>
                <div class="flex mt-5 justify-content-center">
                    <div class="col-12 card" style="height: calc(100vh - 21rem)">
                        <Chart type="bar" :data="basicData" :options="basicOptions" style="height: calc(100vh - 21rem)"/>
                    </div>
                </div>
            </div>
        </div>
    </Dialog>

</template>

<script>
import ScrollPanel from 'primevue/scrollpanel'
import Dialog from 'primevue/dialog'
import Chart from 'primevue/chart'
import moment from 'moment'

export default {
    name: 'Total Statistics',
    components: {
        ScrollPanel,
        Dialog,
        Chart
        
    },
    data() {
        return {
            current_port: localStorage.getItem('user_port'),
            color: localStorage.getItem('primary_color'),
            opacity_color: '',
            display_water_meter_dialog:false,
            month:'',
            icon_items: [
                {'icon': 'fa fa-book'},
                {'icon': 'fa-solid fa-person-walking-arrow-right'},
                {'icon': 'fa-solid fa-person-walking-arrow-right fa-flip-horizontal'},
                {'icon': 'fa-solid fa-people-arrows'},
                {'icon': 'fa-solid fa-ship'},
                {'icon': 'fa-solid fa-people-group'},
                {'icon': 'fa-solid fa-droplet'},
            ],
            data:[],
            basicData: {},
            basicOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            }
        }
    },
    computed: {
        get_water_meter_statistics: function() {
            return this.$store.getters.get_water_meter_statistics
        },
    },
    props: {
        statistics: {
            type: Object
        },
        year: {
            type: Number
        }
    },
    mounted() {
        if(this.color == '#6E3FA3') {
            this.opacity_color = 'rgba(108, 67, 154, .3)'
        } else if (this.color == '#0097A9') {
            this.opacity_color = 'rgba(0, 151, 169, .3)'
        }
    },
    methods: {
        format_title(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        
        async open_dialog_water_meter(month){
            var labels = []
            var data = []
            var month_num = moment().month(month).format("M")
            if (month_num.length == 1){
                month_num = '0'+month_num
            }
            await this.$store.dispatch('load_water_meter_statistics', month_num + '-'+this.year)
            for(var item in this.get_water_meter_statistics) {
                labels.push(item)
                data.push(this.get_water_meter_statistics[item])
            }
            this.basicData = {
                labels: labels,
                datasets: [
                    {
                        label: 'm3',
                        data: data,
                        fill: true,
                        backgroundColor: this.color,
                        tension: .4
                    },
                ]
            },
            this.month = month
            this.display_water_meter_dialog = true
        }

    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

small{
    color: gray;
}

.color_opacity{
    background-color: v-bind(opacity_color);
}

.water_meter{
    text-decoration:underline;
    color:v-bind(color);
    cursor:pointer;
}

</style>