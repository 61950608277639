<template>
    <div class="card">
        <div class="row">
            <Toast />
            <DataTable :value="all_ports" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading" removableSort
            :globalFilterFields="['name','primary_color','secondary_color','typography','typography_media']"
            v-model:filters="filters">
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText id="filter_ports" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                        </span>
                    </div>
                </template>
                <Column header="Name" field="name" :sortable="true"/>
                <Column header="Logo">
                     <template #body="slotProps">
                        <img :src="slotProps.data.logo" alt="Incidence image" width="125"/>
                    </template>
                </Column>
                <Column header="BIC" field="bic_entity" :sortable="true"/>
                <Column header="IBAN" field="iban" :sortable="true"/>
                <Column header="Email sender" field="sender_email" :sortable="true"/>
                <Column header="Primary color" field="primary_color" :sortable="true"/>
                <Column header="Secondary color" field="secondary_color" :sortable="true"/>
                <Column header="Typography" field="typography" :sortable="true"/>
                <Column header="Typography on media" field="typography_media" :sortable="true"/>
                <Column header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_port(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="check_user_super_admin" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_port(slotProps)"/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_port_modal" v-model:visible="port_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Port Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="name" class="ps-1 pb-1"> <strong> Name </strong> </label>
                        <InputText id="name" v-model.trim="port.name" required="true" autofocus :class="{'p-invalid': submitted && !port.name || port_errors.name}" />
                        <small class="p-error" v-if="submitted && !port.name">Name is required.</small>
                        <small v-if="Object.keys(port_errors).length !== 0 && port_errors.name" class="p-error"><span v-for="error in port_errors.name" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="bic_entity" class="ps-1 pb-1"> <strong> BIC </strong> </label>
                        <InputText id="bic_entity" v-model.trim="port.bic_entity" autofocus />
                        <!-- <small class="p-error" v-if="submitted && !port.bic_entity">BIC entity is required.</small> -->
                        <small v-if="Object.keys(port_errors).length !== 0 && port_errors.bic_entity" class="p-error"><span v-for="error in port_errors.bic_entity" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="iban" class="ps-1 pb-1"> <strong> IBAN </strong> </label>
                        <InputText id="iban" v-model.trim="port.iban" autofocus/>
                        <!-- <small class="p-error" v-if="submitted && !port.iban">IBAN is required.</small> -->
                        <small v-if="Object.keys(port_errors).length !== 0 && port_errors.iban" class="p-error"><span v-for="error in port_errors.iban" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-12">
                    <div class="p-float-field">
                        <label for="sender_email" class="ps-1 pb-1"> <strong> Email sender </strong> </label>
                        <InputText id="sender_email" v-model.trim="port.sender_email" required="true" autofocus :class="{'p-invalid': submitted && !port.sender_email || port_errors.sender_email}" />
                        <small class="p-error" v-if="submitted && !port.sender_email">Email sender is required.</small>
                        <small v-if="Object.keys(port_errors).length !== 0 && port_errors.sender_email" class="p-error"><span v-for="error in port_errors.sender_email" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="primary_color" class="ps-1 pb-1"> <strong> Primary color </strong> </label>
                        <InputText id="primary_color" v-model.trim="port.primary_color" required="true" autofocus :class="{'p-invalid': submitted && !port.primary_color || port_errors.primary_color}" />   
                        <small class="p-error" v-if="submitted && !port.primary_color">Primary color is required.</small>
                        <small v-if="Object.keys(port_errors).length !== 0 && port_errors.primary_color" class="p-error"><span v-for="error in port_errors.primary_color" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="secondary_color" class="ps-1 pb-1"> <strong> Secondary color </strong> </label>
                        <InputText id="secondary_color" v-model.trim="port.secondary_color" required="true" autofocus :class="{'p-invalid': submitted && !port.secondary_color || port_errors.secondary_color}" />
                        <small class="p-error" v-if="submitted && !port.secondary_color">Secondary color is required.</small>
                        <small v-if="Object.keys(port_errors).length !== 0 && port_errors.secondary_color" class="p-error"><span v-for="error in port_errors.secondary_color" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="typography" class="ps-1 pb-1"> <strong> Typography </strong> </label>
                        <InputText id="typography" v-model.trim="port.typography" required="true" autofocus :class="{'p-invalid': submitted && !port.typography || port_errors.typography }" />
                        <small class="p-error" v-if="submitted && !port.typography">Typography is required.</small>
                        <small v-if="Object.keys(port_errors).length !== 0 && port_errors.typography" class="p-error"><span v-for="error in port_errors.typography" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
                <div class="col-10 md:col-6">
                    <div class="p-float-field">
                        <label for="media_typography" class="ps-1 pb-1"> <strong> Typography media </strong> </label>
                        <InputText id="media_typography" v-model.trim="port.typography_media" required="true" autofocus :class="{'p-invalid': submitted && !port.typography_media || port_errors.typography_media}" />
                        <small class="p-error" v-if="submitted && !port.typography_media">Typography media is required.</small>
                        <small v-if="Object.keys(port_errors).length !== 0 && port_errors.typography_media" class="p-error"><span v-for="error in port_errors.typography_media" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 text-center">
                    <div class="field pt-4">
                        <div class="p-float-field">
                            <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUpload" />
                        </div>
                        <small v-if="Object.keys(port_errors).length !== 0 && port_errors.logo" class="p-error"><span v-for="error in port_errors.logo" :key="error">{{error}}<br></span></small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_port_dialog"/>
            <Button data-cy="save_edit_port" label="Save" @click="save_port" />
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_port_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="port">Are you sure you want to delete <b>{{port.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_port_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deletePort" />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import { FilterMatchMode } from 'primevue/api'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"
import FileUpload from 'primevue/fileupload'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'TablePorts',
    components: {
        DataTable,
        Column,
        Button,
        InputText,
        Toast,
        Dialog,
        FileUpload
    },
    data() {
        return {
            port: {},
            port_errors:{},
            logo:'',
            port_dialog: false,
            delete_port_dialog: false,
            submitted: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_ports.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_ports')
            this.loading = false
        }
        
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_ports: function() {
            return this.$store.getters.all_ports
        },
        get_port_errors: function() {
            return this.$store.getters.get_port_errors
        },
        get_delete_port_status: function() {
            return this.$store.getters.get_delete_port_status
        },
        get_current_user: function () {
            return this.$store.getters.get_current_user
        },
        check_user_super_admin: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Super Admins') {
                    return true
                }
            }
            return false
        },
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        onUpload(e){
            this.logo = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        confirm_delete_port(port) {
            this.port = port.data;
            this.delete_port_dialog = true;
        },
        async deletePort() {
            await this.$store.dispatch('delete_port', this.port.id)
            if (!this.get_delete_port_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Port deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Port can't be deleted", life: 3000});
            }
                
            this.delete_port_dialog = false;
        },
        edit_port(port) {
            this.port = {...port};
            this.port_dialog = true;
        },
        async save_port() {
            this.submitted = true;
			if (this.port.name.trim()) {
                if(this.logo != ''){
                    this.port.logo = this.logo
                }
                await this.$store.dispatch('update_port', this.port)
                this.port_errors = this.get_port_errors
                
                if(Object.keys(this.port_errors).length === 0){
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Port modified', life: 3000});
                    this.hide_port_dialog()
                    this.port = {};
                }
            }
        },
        hide_port_dialog() {
            this.port_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            name: { required },
            primary_color: { required },
            secondary_color: { required },
            typography: { required },
            typography_media: { required },
            sender_email: { required}
        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>