<template>
    <div class="row content-section introduction mt-4">
        <div class="feature-intro">
            <h1>Bills</h1>
        </div>
    </div>
    <span v-if="check_bills_responsibles||!this.get_current_user.profile.id_port.name == 'Puerto de Málaga'">
        <TabView>
            <TabPanel header="Pending">
                <BillingValidations/>
            </TabPanel>
            <TabPanel header="Bills">
                <div class="row mt-4 responsive_width">
                    <div class="col-6 text-left pt-0 pb-4">
                        <Calendar v-model="bills_range_params.dates" selectionMode="range" dateFormat="yy-mm-dd" class="input_small" @date-select="update_clockings()"></Calendar>
                    </div>
                    <div class="card p-0" >
                        <BillingTable :get_all_bills="get_bills" ranged="true" :loading="loading"/>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </span>
    <div v-else class="row mt-4 responsive_width">
        <div class="card p-0" >
            <BillingTable :get_all_bills="get_all_bills" :loading="loading"/>
        </div>
    </div>
</template>

<script>
import BillingTable from '../../components/Billing/Billing_table.vue'
//import BillingStats from '../../components/Billing/Billing_stats.vue'
import BillingValidations from '../Billing/Billing_validations.vue'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import moment from 'moment'
import Calendar from 'primevue/calendar'

export default {
    name: 'Billing_dashboard',
    components: {
        BillingTable,
        //BillingStats,
        BillingValidations,
        TabView,
        TabPanel,
        Calendar,
    },
    data(){
        return {
            loading: false,
            bills_range_params: {
                dates:[new Date(moment().startOf('month').subtract(3, 'months')), new Date(moment())],
            },
        }
    },
    computed: {
        get_bills: function() {
            return this.$store.getters.get_bills
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_bills_responsibles: function() {
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Bills responsibles') {
                    return true
                }
            }
            return false
        },
    },
    async mounted() {
        this.loading = true
        await this.$store.dispatch('load_range_bills', this.bills_range_params)
        this.loading = false
    },
    methods: {
        async update_clockings () {
            if(this.bills_range_params.dates[1] != null) {
                    this.loading = true
                    await this.$store.dispatch('load_range_bills', this.bills_range_params)
                    this.loading = false
            }       
        },
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

:deep(.p-tabview-nav){
    justify-content: flex-start;
    border-bottom: 2px solid #dee2e6 !important;
}

@media (max-width: 1301px) {
    .responsive_width{
        width: 95vw !important; 
    }
}

.responsive_width{
    width: calc(100vw - 23rem )
}

</style>