<template>
    <div class="container mt-4">
        <div class="row">
            <div class="col">
                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> {{ formData.group_name ? formData.group_name : '-' }}  </h5>
            </div>
        </div>
        <div class="row">
            <PickList v-model="data" dataKey="id" listStyle="height:342px" @move-to-target="move_to_target($event)" @move-to-source="move_to_source($event)">
                <template #sourceheader>
                    Available
                </template>
                <template #targetheader>
                    Selected
                </template>
                <template #item="slotProps">
                    <div class="product-item">
                        <div class="image-container me-3">
                            <Avatar :image="slotProps.item.profile.picture" size="large" shape="circle" aria-haspopup="true" aria-controls="overlay_tmenu"/>
                        </div>
                        <div class="product-list-detail">
                            <h6 class="mb-2">{{slotProps.item.username}}</h6>
                        </div>
                    </div>
                </template>
            </PickList>
        </div>
        <div class="row">
            <div class="col text-center">
                <h5 v-show="validationErrors.selected_user && submitted" class="p-error">At least 1 user is required.</h5>
            </div>
        </div>
    </div>   
    <div class="grid grid-nogutter justify-content-between mt-5">
        <Button label="Back" class="p-button-raised" @click="prevPage()" icon="pi pi-angle-left" />
        <Button label="Next" class="p-button-raised" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
    </div>    
</template>

<script>
import Button from 'primevue/button'
import PickList from 'primevue/picklist'


export default {
    data() {
        return {
            selected_user: [],
            validationErrors: [],
            submitted: false,
        }
    },
    components: {
        Button,
        PickList
    },
    props: {
        formData: Object
    },
    computed: {
        data: function() {
            return this.$store.getters.get_all_users, []
        }, 
        get_all_users: function() {
            return this.$store.getters.get_all_users
        }
    },
    async mounted(){
        await this.$store.dispatch('load_all_users')
        this.data = [ this.get_all_users ,[]]
    },
    methods: {
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', { formData: { selected_user: this.data[1] }, pageIndex: 1 });
            }
        },
        prevPage() {
            this.$emit('prev-page', { pageIndex: 1 });
        },
        validateForm() {
            if (this.data[1].length == 0) { 
                this.validationErrors['selected_user'] = true;
            } else {
                delete this.validationErrors['selected_user'];
            } 
            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>

<style scoped>
:deep(.p-picklist-source-controls){
    display: none;
}

:deep(.p-picklist-target-controls){
    display: none;
}

</style>