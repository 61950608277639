<template>
    <Button icon="fa fa-plus" label="New Group" class="p-button-rounded p-button-success p-button-sm"  @click="$router.push({path: '/permissions/group/steps'})"/>
</template>

<script>
import Button from 'primevue/button'
export default {
    components: {
        Button
    },
}
</script>

<style scoped>

</style>