import axios from 'axios'
import moment from 'moment'

const state = {
    terminal_security_service: {},
    terminal_security_service_shifts: [],
    terminal_security_service_type_options: [],
    terminal_security_service_position_options: [],
    send_terminal_security_mail: false,
    error_terminal_security_service_shifts: [],
    error_terminal_security_service_type_option: [],
    error_terminal_security_service_position_option: []
}

const mutations = {
    SET_TERMINAL_SECURITY_SERVICE(state, terminal_security_service) {
        state.terminal_security_service = terminal_security_service
    },
    SET_TERMINAL_SECURITY_SERVICE_SHIFTS(state, terminal_security_service_shifts) {
        state.terminal_security_service_shifts = terminal_security_service_shifts
    },
    SET_TERMINAL_SECURITY_SERVICE_TYPE_OPTIONS(state, options) {
        state.terminal_security_service_type_options = options
    },
    SET_TERMINAL_SECURITY_SERVICE_POSITION_OPTIONS(state, options) {
        state.terminal_security_service_position_options = options
    },
    ADD_TERMINAL_SECURITY_SERVICE_SHIFTS(state, terminal_security_service_shifts) {
        state.error_terminal_security_service_shifts = []
        terminal_security_service_shifts.start_datetime_hour =  terminal_security_service_shifts.start_datetime.split('T')[1].split(':')[0] + ':' +  terminal_security_service_shifts.start_datetime.split('T')[1].split(':')[1]
        terminal_security_service_shifts.start_datetime =  terminal_security_service_shifts.start_datetime.split('T')[0]
        terminal_security_service_shifts.end_datetime_hour =  terminal_security_service_shifts.end_datetime.split('T')[1].split(':')[0] + ':' +  terminal_security_service_shifts.end_datetime.split('T')[1].split(':')[1]
        terminal_security_service_shifts.end_datetime =  terminal_security_service_shifts.end_datetime.split('T')[0]
        state.terminal_security_service_shifts.push(terminal_security_service_shifts)
    },
    CREATE_TERMINAL_SECURITY_SERVICE_TYPE_OPTIONS(state, option){
        state.terminal_security_service_type_options.push(option)
    },
    CREATE_TERMINAL_SECURITY_SERVICE_POSITION_OPTIONS(state, option) {
        state.terminal_security_service_position_options.push(option)
    },
    SET_ERROR_TERMINAL_SECURITY_SERVICE_SHIFTS(state, error_terminal_security_service_shifts) {
        state.error_terminal_security_service_shifts = []
        state.error_terminal_security_service_shifts.push(error_terminal_security_service_shifts)
    },
    UPDATE_TERMINAL_SECURITY_SERVICE(state, terminal_security_service) {
        state.terminal_security_service = state.terminal_security_service.map(updated_terminal_security_service => {
            if(updated_terminal_security_service.id === terminal_security_service.id) {
                updated_terminal_security_service.id_supplier = terminal_security_service.id_supplier
                updated_terminal_security_service.id_supplier_responsible = terminal_security_service.id_supplier_responsible
                updated_terminal_security_service.note_info = terminal_security_service.note_info
                updated_terminal_security_service.mail_text = terminal_security_service.mail_text
            }
            return updated_terminal_security_service
        })
    },
    UPDATE_TERMINAL_SECURITY_SERVICE_SHIFTS(state, terminal_security_service_shifts) {
        state.terminal_security_service_shifts = state.terminal_security_service_shifts.map(updated_terminal_security_service_shifts => {
            if(updated_terminal_security_service_shifts.id === terminal_security_service_shifts.id) {
                updated_terminal_security_service_shifts.start_datetime_hour = terminal_security_service_shifts.start_datetime.split('T')[1].split(':')[0]+ ':' +  terminal_security_service_shifts.start_datetime.split('T')[1].split(':')[1]
                updated_terminal_security_service_shifts.start_datetime = terminal_security_service_shifts.start_datetime.split('T')[0]
                updated_terminal_security_service_shifts.end_datetime_hour = terminal_security_service_shifts.end_datetime.split('T')[1].split(':')[0]+ ':' +terminal_security_service_shifts.end_datetime.split('T')[1].split(':')[1]
                updated_terminal_security_service_shifts.end_datetime = terminal_security_service_shifts.end_datetime.split('T')[0]
                updated_terminal_security_service_shifts.quantity = terminal_security_service_shifts.quantity
                updated_terminal_security_service_shifts.id_position_option = terminal_security_service_shifts.id_position_option
                updated_terminal_security_service_shifts.id_security_type = terminal_security_service_shifts.id_security_type
                updated_terminal_security_service_shifts.is_extra = terminal_security_service_shifts.is_extra
            }
            return updated_terminal_security_service_shifts
        })
    },
    DELETE_TERMINAL_SECURITY(state, id) {
        let index = state.terminal_security_service.findIndex(terminal_security_service => terminal_security_service.id == id)
        state.terminal_security_service.splice(index, 1)
    },
    DELETE_TERMINAL_SECURITY_SERVICE(state, id) {
        let index = state.terminal_security_service_shifts.findIndex(terminal_security_service_shifts => terminal_security_service_shifts.id == id)
        state.terminal_security_service_shifts.splice(index, 1)
    },
    DELETE_TERMINAL_SECURITY_SERVICE_TYPE_OPTION(state, id) {
        let index = state.terminal_security_service_type_options.findIndex(terminal_security_service_type_option => terminal_security_service_type_option.id == id)
        state.terminal_security_service_type_options.splice(index, 1)
    },
    DELETE_TERMINAL_SECURITY_SERVICE_POSITION_OPTION(state, id) {
        let index = state.terminal_security_service_position_options.findIndex(terminal_security_service_position_option => terminal_security_service_position_option.id == id)
        state.terminal_security_service_position_options.splice(index, 1)
    },
    UPDATE_MAIL_STATUS_TERMINAL_SECURITY(state, cond) {
        if(cond == true) {
            state.send_terminal_security_mail = true    
        }
    },
    UPDATE_STATUS_MAIL_TERMINAL_SECURITY_SERVICE(state) {
        state.send_terminal_security_mail = false
    },
    SET_ERROR_TERMINAL_SECURITY_SERVICE(state, error) {
        state.error_terminal_security_service_shifts = error
    },
    CLEAN_ERROR_TERMINAL_SECURITY_SERVICE(state) {
        state.error_terminal_security_service_shifts =  []
    },
    EMPTY_TERMINALS_SHIFTS(state) {
        state.terminal_security_service_shifts = []
    },
    SET_ERROR_TERMINAL_SECURITY_SERVICE_TYPE_OPTION(state, payload) {
        state.error_terminal_security_service_type_option = payload
    },
    EMPTY_ERROR_TERMINAL_SECURITY_SERVICE_TYPE_OPTION(state) {
        state.error_terminal_security_service_type_option = []
    },
    SET_ERROR_TERMINAL_SECURITY_SERVICE_POSITION_OPTION(state, payload) {
        state.error_terminal_security_service_position_option = payload
    },
    EMPTY_ERROR_TERMINAL_SECURITY_SERVICE_POSITION_OPTION(state) {
        state.error_terminal_security_service_position_option = []
    }
}

const actions = {
	async load_terminals_security_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'terminal_security/get_service/' + id) 
			.then((response) => {
				commit('SET_TERMINAL_SECURITY_SERVICE', response.data)
			})
	},
    async load_terminals_security_service_shifts({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'terminal_security_service/service_sheet/' + id + '/')
            .then((response)=> {
                commit('SET_TERMINAL_SECURITY_SERVICE_SHIFTS', response.data)
            })
    },
    async create_past_terminal_security({commit}, id_ss){
        await axios.post(process.env.VUE_APP_API_URL + 'terminal_security/create_past_terminal_security/' + id_ss + '/')
            .then((response) => {
                commit('SET_TERMINAL_SECURITY_SERVICE', response.data)
            }).catch(() => {})
    },
    async load_terminals_security_service_type_options({commit}){
        await axios.get(process.env.VUE_APP_API_URL + 'terminal_security_service_type_option/') 
            .then((response) => {
                commit('SET_TERMINAL_SECURITY_SERVICE_TYPE_OPTIONS', response.data)
            })
    },
    async load_terminals_security_service_position_options({commit}){
        await axios.get(process.env.VUE_APP_API_URL + 'terminal_security_service_position_option/') 
            .then((response) => {
                commit('SET_TERMINAL_SECURITY_SERVICE_POSITION_OPTIONS', response.data)
            })
    },
    async create_terminals_security_service_type_option({commit}, name) {
        await axios.post(process.env.VUE_APP_API_URL + 'terminal_security_service_type_option/', {
            name: name 
        })
            .then((response) => {
                commit('EMPTY_ERROR_TERMINAL_SECURITY_SERVICE_TYPE_OPTION')
                commit('CREATE_TERMINAL_SECURITY_SERVICE_TYPE_OPTIONS', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_TERMINAL_SECURITY_SERVICE_TYPE_OPTION', error.response.data)
            })
    },
    async create_terminals_security_service_position_option({commit}, name) {
        await axios.post(process.env.VUE_APP_API_URL + 'terminal_security_service_position_option/', {
            name: name 
        })
            .then((response) => {
                commit('EMPTY_ERROR_TERMINAL_SECURITY_SERVICE_POSITION_OPTION')
                commit('CREATE_TERMINAL_SECURITY_SERVICE_POSITION_OPTIONS', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_TERMINAL_SECURITY_SERVICE_POSITION_OPTION', error.response.data)
            })
    },
    async create_terminals_security_service({commit}, terminal_security) {
        await axios.post(process.env.VUE_APP_API_URL + 'terminal_security/', {
            id_service_sheet: terminal_security.id_service_sheet,
            id_supplier_responsible: terminal_security.id_supplier_responsible.id,
            id_supplier: terminal_security.id_supplier.id,
            note_info: terminal_security.note_info,
            mail_text: terminal_security.mail_text,
        })
            .then((response) => {
                commit('SET_TERMINAL_SECURITY_SERVICE', response.data)
            })
    },
    async create_terminals_security_service_shifts({commit}, terminal_security) {
        if(typeof terminal_security.start_datetime_hour === 'string') {
            terminal_security.start_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + terminal_security.start_datetime_hour))
        }
        var start_datetime = new Date(new Date(terminal_security.start_datetime).getFullYear(), new Date(terminal_security.start_datetime).getMonth(), new Date(terminal_security.start_datetime).getDate(),
                            new Date(terminal_security.start_datetime_hour).getHours(), new Date(terminal_security.start_datetime_hour).getMinutes(), new Date(terminal_security.start_datetime_hour).getSeconds())
        if(typeof terminal_security.end_datetime_hour === 'string') {
            terminal_security.end_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + terminal_security.end_datetime_hour))
        }
        var end_datetime = new Date(new Date(terminal_security.end_datetime).getFullYear(), new Date(terminal_security.end_datetime).getMonth(), new Date(terminal_security.end_datetime).getDate(),
                            new Date(terminal_security.end_datetime_hour).getHours(), new Date(terminal_security.end_datetime_hour).getMinutes(), new Date(terminal_security.end_datetime_hour).getSeconds()) 
        
        start_datetime = moment(start_datetime).format().split('+')[0]
        end_datetime = moment(end_datetime).format().split('+')[0]
        await axios.post(process.env.VUE_APP_API_URL + 'terminal_security_service/', {
            start_datetime: start_datetime,
            end_datetime: end_datetime,
            id_position_option: terminal_security.position,
            is_extra: terminal_security.extra,
            quantity: terminal_security.quantity,
            id_security_type: terminal_security.service_type,
            id: terminal_security.id,
            terminal_security: terminal_security.terminal_security,
            is_estimated: terminal_security.is_estimated
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_TERMINAL_SECURITY', true)
                commit('ADD_TERMINAL_SECURITY_SERVICE_SHIFTS', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_TERMINAL_SECURITY_SERVICE_SHIFTS', error.response.data)
            })
    },
    async update_terminals_security_service({commit}, terminal_security) {
        var id_responsible = ''
        if(terminal_security.id_supplier_responsible) {
            id_responsible = terminal_security.id_supplier_responsible.id
        }
        await axios.patch(process.env.VUE_APP_API_URL + 'terminal_security/' + terminal_security.id + '/', {mail_text: terminal_security.mail_text, note_info: terminal_security.note_info, id_supplier_responsible: id_responsible, id_supplier: terminal_security.id_supplier.id})
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_TERMINAL_SECURITY', response.data.edited)
                commit('UPDATE_TERMINAL_SECURITY_SERVICE', response.data)
            })
    },
    async update_terminals_security_service_shifts ({commit}, terminal_security) {
        if(typeof(terminal_security.start_datetime) === 'object' || typeof(terminal_security.start_datetime_hour) === 'object') {
            if(typeof terminal_security.start_datetime_hour === 'string') {
                terminal_security.start_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + terminal_security.start_datetime_hour))
            }
            var start_datetime = new Date(new Date(terminal_security.start_datetime).getFullYear(), new Date(terminal_security.start_datetime).getMonth(), new Date(terminal_security.start_datetime).getDate(),
                new Date(terminal_security.start_datetime_hour).getHours(), new Date(terminal_security.start_datetime_hour).getMinutes(), new Date(terminal_security.start_datetime_hour).getSeconds())
            start_datetime = moment(start_datetime).format().split('+')[0]
        } else {
            start_datetime = terminal_security.start_datetime + 'T' + terminal_security.start_datetime_hour
        }
        if(typeof(terminal_security.end_datetime) === 'object' || typeof(terminal_security.end_datetime_hour) === 'object') {
            if(typeof terminal_security.end_datetime_hour === 'string') {
                terminal_security.end_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + terminal_security.end_datetime_hour))
            }
            var end_datetime = new Date(new Date(terminal_security.end_datetime).getFullYear(), new Date(terminal_security.end_datetime).getMonth(), new Date(terminal_security.end_datetime).getDate(),
                new Date(terminal_security.end_datetime_hour).getHours(), new Date(terminal_security.end_datetime_hour).getMinutes(), new Date(terminal_security.end_datetime_hour).getSeconds())
            end_datetime = moment(end_datetime).format().split('+')[0]
        } else {
            end_datetime = terminal_security.end_datetime + 'T' + terminal_security.end_datetime_hour
        }
        await axios.patch(process.env.VUE_APP_API_URL + 'terminal_security_service/' + terminal_security.id + '/', {
            quantity: terminal_security.quantity,
            start_datetime: start_datetime,
            end_datetime: end_datetime,
            id_security_type: terminal_security.id_security_type.id,
            is_extra: terminal_security.is_extra,
            id_position_option: terminal_security.id_position_option.id
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_TERMINAL_SECURITY', response.data.edited)
                commit('UPDATE_TERMINAL_SECURITY_SERVICE_SHIFTS', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_TERMINAL_SECURITY_SERVICE_SHIFTS', error.response.data)
            })
    },
    async delete_terminal_security({commit}, id){
        await axios.delete(process.env.VUE_APP_API_URL + 'terminal_security/' + id + '/')
            .then(() => {
                commit('DELETE_TERMINAL_SECURITY', id)
            })

    },
    async delete_terminal_security_service({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'terminal_security_service/' + id + '/')
            .then(() => {
                commit('DELETE_TERMINAL_SECURITY_SERVICE', id)
            })
    },
    async delete_terminal_security_service_type_option({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "terminal_security_service_type_option/" + id + '/')
            .then(() => {
                commit('DELETE_TERMINAL_SECURITY_SERVICE_TYPE_OPTION', id)
            })
    },
    async delete_terminal_security_service_position_option({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + "terminal_security_service_position_option/" + id + '/')
            .then(() => {
                commit('DELETE_TERMINAL_SECURITY_SERVICE_POSITION_OPTION', id)
            })
    },
    async send_terminal_security_service_mail({commit}, id){
        await axios.post(process.env.VUE_APP_API_URL + 'terminal_security/send_mail/' + id + '/')
            .then(() => {
                commit('UPDATE_STATUS_MAIL_TERMINAL_SECURITY_SERVICE')
                commit('CLEAN_ERROR_TERMINAL_SECURITY_SERVICE')
            })
            .catch((error) => {
                commit('SET_ERROR_TERMINAL_SECURITY_SERVICE', error.response.data)
            })
    },
    async clean_errors_terminals_security_shifts({commit}) {
        commit('CLEAN_ERROR_TERMINAL_SECURITY_SERVICE')
    },
    async empty_terminals_shifts({commit}) {
        commit('EMPTY_TERMINALS_SHIFTS')
    }
}

const getters = {
    get_terminals_security_service: (state) => state.terminal_security_service,
    get_terminals_security_service_shifts: (state) => state.terminal_security_service_shifts,
    get_send_terminal_security_mail: (state) => state.send_terminal_security_mail,
    get_error_terminal_security_service_shifts: (state) => state.error_terminal_security_service_shifts,
    get_terminal_security_service_type_options: (state) => state.terminal_security_service_type_options,
    get_terminal_security_service_position_options: (state) => state.terminal_security_service_position_options,
    get_terminals_security_service_shifts_estimated: (state) =>  {
        return state.terminal_security_service_shifts.filter(item => item.is_estimated === true)
    },
    get_terminals_security_service_shifts_final: (state) => {
        return state.terminal_security_service_shifts.filter(item => item.is_estimated === false)
    },
    error_terminal_security_service_type_option: (state) => state.error_terminal_security_service_type_option,
    error_terminal_security_service_position_option: (state) => state.error_terminal_security_service_position_option
}

export default {
    state,
    mutations,
    actions,
    getters
};