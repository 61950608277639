<template>
    <ModalTerminals/>
    <TableTerminals/>
</template>

<script>
import TableTerminals from '@/components/AdminDashboard/Terminals/TableTerminals.vue'
import ModalTerminals from '@/components/AdminDashboard/Terminals/ModalTerminals.vue'
export default {
  name: 'Cruise_lines',
  components: {
    TableTerminals,
    ModalTerminals
  }
}
</script>

<style scoped>
</style> 
