<template>
    <ModalIncidence_zones />
    <TableIncidence_zones />
</template>

<script>
import TableIncidence_zones from '@/components/AdminDashboard/Incidence_zones/TableIncidence_zones.vue'
import ModalIncidence_zones from '@/components/AdminDashboard/Incidence_zones/ModalIncidence_zones.vue'
export default {
    name: 'Incidence_zones',
    components: {
        TableIncidence_zones,
        ModalIncidence_zones
    }
}
</script>

<style scoped>
</style>