import axios from 'axios'
import moment from 'moment'

const state = {
    call: {},
    calls: [],
    calls_by_range: [],
    call_errors:{},
    update_response:{},
    delete_status:false,
}

const mutations = {
    SET_CALLS(state, calls) {
        state.calls = calls
    },
    SET_CALLS_BY_RANGE(state, payload) {
        state.calls_by_range = payload
    },
    SET_CALL(state, call) {
        state.call = call
    },
    NEW_SCALE(state, call) {
        state.calls_by_range.push(call)
        state.calls.push(call)
        state.call_errors = {}
    },
    DELETE_SCALE(state, id) {
        let index = state.calls.findIndex(calls => calls.id == id)
        let index_by_range = state.calls_by_range.findIndex(calls_by_range => calls_by_range.id == id)
        state.calls.splice(index, 1)
        state.calls_by_range.splice(index_by_range, 1)
        state.delete_status=false
    },
    UPDATE_SCALE(state, call) {

        if(state.calls.length != 0){
            state.calls = state.calls.map(updated_call => {
                if(updated_call.id === call.id) {
                    updated_call.name = call.name
                    updated_call.ship_agent = call.ship_agent
                    updated_call.arrival_datetime = call.arrival_datetime
                    updated_call.departure_datetime = call.departure_datetime
                    updated_call.id_ship = call.id_ship
                    updated_call.id_ship_responsible = call.id_ship_responsible
                    updated_call.id_port = call.id_port
                    updated_call.is_unconventional = call.is_unconventional
                    updated_call.is_new = call.is_new
                }
                
                return updated_call
            })
        }

        if(state.calls_by_range.length != 0){
            state.calls_by_range = state.calls_by_range.map(updated_call => {
                if(updated_call.id === call.id) {
                    updated_call.name = call.name
                    updated_call.ship_agent = call.ship_agent
                    updated_call.arrival_datetime = call.arrival_datetime
                    updated_call.departure_datetime = call.departure_datetime
                    updated_call.id_ship = call.id_ship
                    updated_call.id_ship_responsible = call.id_ship_responsible
                    updated_call.id_port = call.id_port
                    updated_call.is_unconventional = call.is_unconventional
                    updated_call.is_new = call.is_new
                }
                
                return updated_call
            })
        }
        
        
        state.call_errors = {}
    },
    CHANGE_TYPE_SHIP_CALL(state, call) {
        state.call.is_unconventional = call.is_unconventional
    },
    ERRORS_SCALE(state, errors){
        state.call_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
}

const actions = {

    async download_calls_between_dates({commit}, data){
        const startDate = moment(data.filter_date[0]).format('YYYY-MM-DD');
        const endDate = moment(data.filter_date[1]).format('YYYY-MM-DD');
        const terminalName = data.filter_terminal.name;
        const filename = `calls_${startDate}_to_${endDate}_Terminal_${terminalName}.xlsx`;
        await axios.get(process.env.VUE_APP_API_URL + 'calls/export_data/' + data.filter_terminal.id + '/' + moment(data.filter_date[0]).format('YYYY-MM-DD') + '/' + moment(data.filter_date[1]).format('YYYY-MM-DD') + '/' + data.filter_status + '/',  {responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                console.log(commit)
            })
    },
    async load_all_calls({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "calls/")
            .then((response) => {
                commit('SET_CALLS', response.data)
            })
    },
    async load_all_calls_by_range({commit}, data) {
        await axios.get(process.env.VUE_APP_API_URL + 'calls/get_by_date_range/' + moment(data[0]).format('YYYY-MM-DD') + '/' + moment(data[1]).format('YYYY-MM-DD') + '/' ) 
            .then((response) => {  
                commit('SET_CALLS_BY_RANGE', response.data)
            })
    },
    async load_call({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "calls/" + id + '/')
            .then((response) => {
                commit('SET_CALL', response.data)
            })
    },
    async create_call({commit}, call) {
        if(typeof call.arrival_datetime_hour === 'string') {
            call.arrival_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + call.arrival_datetime_hour))
        }
        var start_datetime = new Date(new Date(call.arrival_datetime).getFullYear(), new Date(call.arrival_datetime).getMonth(), new Date(call.arrival_datetime).getDate(),
                            new Date(call.arrival_datetime_hour).getHours(), new Date(call.arrival_datetime_hour).getMinutes(), new Date(call.arrival_datetime_hour).getSeconds())
        if(typeof call.departure_datetime_hour === 'string') {
            call.departure_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + call.departure_datetime_hour))
        }
        var end_datetime = new Date(new Date(call.departure_datetime).getFullYear(), new Date(call.departure_datetime).getMonth(), new Date(call.departure_datetime).getDate(),
                            new Date(call.departure_datetime_hour).getHours(), new Date(call.departure_datetime_hour).getMinutes(), new Date(call.departure_datetime_hour).getSeconds())
        
        start_datetime = moment(start_datetime).format().split('+')[0]  
        end_datetime = moment(end_datetime).format().split('+')[0]  
        await axios.post(process.env.VUE_APP_API_URL  + 'calls/', { ship_agent:call.ship_agent, arrival_datetime: start_datetime, departure_datetime:end_datetime, id_ship:call.id_ship.id, id_ship_responsible:call.id_ship_responsible, phone: call.phone, fax: call.fax, is_unconventional: call.is_unconventional, is_new: call.is_new })
            .then((response) => {
                commit('NEW_SCALE', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SCALE', error.response.data)
            })
    },
    /*
    async create_calls_csv({commit}, call) {
        await axios.post(process.env.VUE_APP_API_URL  + 'calls/', { })
            .then((response) => {
                commit('NEW_SCALE', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SCALE', error.response.data)
            })
    },
    */
    async delete_call({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'calls/' + id)
            .then(() => {
                commit('DELETE_SCALE', id)
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
    }, 
    async patch_call_from_general({commit}, call) {
        await axios.patch(process.env.VUE_APP_API_URL  + 'calls/' + call.id + '/', {
            is_unconventional: call.is_unconventional,
            is_new: call.is_new
        })
            .then((response) => {
                commit('CHANGE_TYPE_SHIP_CALL', response.data)
            })
    },
    async update_call({commit}, call){
        if(typeof(call.arrival_hour) === 'string') {
            var start_datetime = (new Date(moment(call.arrival_datetime).format().split('T')[0] + 'T' + call.arrival_hour))
        } else {
            start_datetime = new Date(new Date(call.arrival_datetime).getFullYear(), new Date(call.arrival_datetime).getMonth(), new Date(call.arrival_datetime).getDate(),
                                new Date(call.arrival_hour).getHours(), new Date(call.arrival_hour).getMinutes(), new Date(call.arrival_hour).getSeconds())
        }

        if(typeof(call.departure_hour) === 'string') {
            var end_datetime = (new Date(moment(call.departure_datetime).format().split('T')[0] + 'T' + call.departure_hour))
        } else {
            end_datetime = new Date(new Date(call.departure_datetime).getFullYear(), new Date(call.departure_datetime).getMonth(), new Date(call.departure_datetime).getDate(),
                                new Date(call.departure_hour).getHours(), new Date(call.departure_hour).getMinutes(), new Date(call.departure_hour).getSeconds())    
        }
        start_datetime = moment(start_datetime).format().split('+')[0]
        end_datetime = moment(end_datetime).format().split('+')[0]
        if(!call.id_ship_responsible){
            await axios.patch(process.env.VUE_APP_API_URL  + 'calls/' + call.id + '/',  { 
                ship_agent:call.ship_agent, 
                arrival_datetime:start_datetime, 
                departure_datetime:end_datetime, 
                id_ship:call.id_ship.id, id_ship_responsible: '',
                is_unconventional: call.is_unconventional,
                is_new: call.is_new,
            })
            .then((response) => {
                commit('UPDATE_SCALE', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SCALE', error.response.data)
            })
        } else if (typeof(call.id_ship_responsible) === 'object'){
            await axios.patch(process.env.VUE_APP_API_URL  + 'calls/' + call.id + '/',  { ship_agent:call.ship_agent, arrival_datetime:start_datetime, departure_datetime:end_datetime, id_ship:call.id_ship.id, id_ship_responsible:call.id_ship_responsible.id, is_unconventional: call.is_unconventional, is_new: call.is_new })
            .then((response) => {
                commit('UPDATE_SCALE', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SCALE', error.response.data)
            })
        } else {
            await axios.patch(process.env.VUE_APP_API_URL  + 'calls/' + call.id + '/',  { ship_agent:call.ship_agent, arrival_datetime:start_datetime, departure_datetime:end_datetime, id_ship:call.id_ship.id, id_ship_responsible:call.id_ship_responsible, is_unconventional: call.is_unconventional, is_new: call.is_new })
            .then((response) => {
                commit('UPDATE_SCALE', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SCALE', error.response.data)
            })
        }
        
    },
}

const getters = {
    get_call: (state) => state.call,
    all_calls: (state) => state.calls,
    get_errors_call: (state) => state.call_errors,
    get_delete_call_status:(state) => state.delete_status,
    calls_by_range: (state) => state.calls_by_range,
    calls_between_dates: (state) => state.calls_between_dates
}

export default {
    state,
    mutations,
    actions,
    getters
};