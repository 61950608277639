<template>
    <div class="row mt-5">
        <div class="col">
            <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> {{ formData.group_name ? formData.group_name : '-' }}  </h5>
        </div>
    </div>
    <div class="row">
        <div class="col card me-2 align-self-start">
            <span v-for="role in get_menu_items" :key="role"> 
                <div class="row container">
                    <div class="col my-auto">
                        {{role.name}}
                    </div>
                    <div class="col-2 text-end my-auto">
                        <Checkbox v-model="selected" :value="role.id" @input="select_group($event, role.name)"/>
                    </div>
                </div>
            </span>
        </div>
        <div class="col-9 card ms-2">
            <div class="container">
                <div class="row">
                    <span v-for="(item, index) in perms" :key="item" class="col-4">
                        <h1>{{index}}</h1>
                        <span v-for="item1 in item['perms']" :key="item1">
                            <div class="field-checkbox" >
                                <span v-if="item1['required'] == true">
                                    <Checkbox v-model="item1['checked']" :value="item1['id']" :binary="true" :disabled="true"/>    
                                </span>
                                <span v-else>
                                    <Checkbox v-model="item1['checked']" :value="item1['id']" :binary="true" />
                                </span>
                                <label>{{item1["perm"]}}</label>
                            </div>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col text-center">
            <h5 v-show="validationErrors.perms && submitted" class="p-error">At least 1 permission is required.</h5>
        </div>
    </div>
    <div class="grid grid-nogutter justify-content-between mt-5 mb-3">
        <Button label="Back" class="p-button-raised" @click="prevPage()" icon="pi pi-angle-left" />
        <Button label="Next" class="p-button-raised" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
    </div>       
</template>

<script>
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'

export default {
    data() {
        return {
            items: [
                'Management',
                'Calls',
                'Records',
                'Incidences',
                'Maintenance',
                'Billing',
                'Statistics',
            ],
            selected: [],
            perms: [],
            selected_perms: [],
            prev_roles: [],
            validationErrors: []
        }
    },
    props: {
        formData: Object
    },
    components: {
        Checkbox,
        Button,
    },
    computed: {
        get_custom_permissions_group: function() {
            return this.$store.getters.get_custom_permissions_group
        },
        get_menu_items: function() {
            return this.$store.getters.get_menu_items
        }
    },
    async mounted() {
        await this.$store.dispatch('load_menu_items')
    },
    methods: {
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', { formData: { perms: this.perms, id_menu_bar_items: this.selected }, pageIndex: 2 });
            }
        },
        prevPage() {
            this.$emit('prev-page', { pageIndex: 2 });
        },
        format_text(text) {
            return (text.charAt(0).toUpperCase() + text.slice(1)).replaceAll('_', ' ') 
        },
        validateForm(){
            if (this.perms.length == 0) { 
                this.validationErrors['perms'] = true;
            } else {
                delete this.validationErrors['perms'];
            } 
            return !Object.keys(this.validationErrors).length;
        },
        async select_group(event, role) {
            
            await this.$store.dispatch('load_custom_permissions_group', role.toLowerCase())
            
            if(Object.keys(event).length > Object.keys(this.prev_roles).length) {
                // Permisos base
                for(var item in this.perms) {
                    // Nuevos permisos introducidos
                    for(var data in this.get_custom_permissions_group){
                        if(data == item) {
                            // Añadir en grupos, el nuevo rol introducido (true = obligatorio, false = no obligatorio)
                            this.perms[item].groups[Object.keys(this.get_custom_permissions_group[data].groups)] = this.get_custom_permissions_group[data].groups[role.toLowerCase()]

                            for(var attribute in this.get_custom_permissions_group[data]['perms']) {
                                
                                if(this.perms[data]['perms'][attribute].name.includes('view')) {
                                    this.perms[data]['perms'][attribute].required = true
                                    this.perms[data]['perms'][attribute].checked = true
                                }

                            }

                            delete this.get_custom_permissions_group[data]
                        } 
                    }
                }

                this.perms = {...this.perms,...this.get_custom_permissions_group}

            } else {

                for(var i in this.perms) {
                    if(Object.keys(this.perms[i]['groups']).length == 1) {
                        if(Object.keys(this.perms[i]['groups']) == role.toLowerCase()) {
                            // SOLO TIENE 1 GRUPO Y SE HA DE ELIMINAR EL PERMISO
                            delete this.perms[i]
                        }
                    } else {
                        if(Object.keys(this.perms[i]['groups']).includes(role.toLowerCase())){
                            // TIENE MAS DE 1 GRUPO Y SE HA DE MODIFICAR EL PERMISO
                            delete this.perms[i].groups[role.toLowerCase()]

                            // Poner como obligatorios/no obligatorios todos los permisos relacionados con los nuevos roles
                            for(var check in this.perms[i].groups) {
                                for(var index in this.perms[i].perms) {
                                    if(this.perms[i].groups[check] == true) {
                                        if(this.perms[i].perms[index].perm.includes('view')){
                                            this.perms[i].perms[index].required = true
                                            this.perms[i].perms[index].checked = true
                                        } 
                                    } else {
                                        this.perms[i].perms[index].required = false
                                    }
                                    
                                } 
                            }
                        }
                    }
                }
            }

            // this.perms = {...this.perms,...this.get_custom_permissions_group}
            this.prev_roles = [...event]
        },
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}
.is_active{
    background-color: lightblue;
}

</style>