<template>
    <Toast/>
    <div v-if="$can('view','ship_responsible')" class="pt-4 pb-2">
        <div class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Ship Responsibles </h1>
            <div v-if="$can('add','ship_responsible')" class="card p-1">
                <Button data-cy="new_ship_responsible_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Ship Responsible'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_ship_responsible_modal" header="New Ship Responsible" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <div class="container">
                <form id="from_new_ship_responsibles" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted || ship_responsibles_errors.name}" />
                                <label for="name" :class="{'p-error':v$.name.$invalid && submitted}"> Name* </label>
                                <small v-if="Object.keys(ship_responsibles_errors).length !== 0 && ship_responsibles_errors.name" class="p-error"><span v-for="error in ship_responsibles_errors.name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="phone" v-model="v$.phone.$model" :class="{'p-invalid':v$.phone.$invalid && submitted || ship_responsibles_errors.phone}" />
                                <label for="phone" :class="{'p-error':v$.phone.$invalid && submitted}"> Phone* </label>
                                <small v-if="Object.keys(ship_responsibles_errors).length !== 0 && ship_responsibles_errors.phone" class="p-error"><span v-for="error in ship_responsibles_errors.phone" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.phone.$invalid && submitted) || v$.phone.$pending.$response" class="p-error">{{v$.phone.required.$message.replace('Value', 'Phone')}}</small>
                        </div> 
                    </div> 
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_ship_agent" id="id_ship_agent" v-model="id_ship_agent" :options="all_ship_agents" optionLabel="name" :filter="true" placeholder="Ship agent*" :class="{'p-invalid':v$.id_ship_agent.$invalid && submitted || ship_responsibles_errors.id_ship_agent}">
                                    <template #value="slotProps">
                                        <div v-if="!id_ship_agent">
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                        <div v-else-if="slotProps.value">
                                            <span>{{slotProps.value.name}}</span>
                                        </div>
                                    </template>
                                    <template #option="slotProps">
                                        <div> {{slotProps.option.name}}</div>
                                    </template>
                                </Dropdown>
                            </div>
                            <small v-if="Object.keys(ship_responsibles_errors).length !== 0 && ship_responsibles_errors.id_ship_agent" class="p-error"><span v-for="error in ship_responsibles_errors.id_ship_agent" :key="error">{{error}}<br></span></small>
                        </div>
                        <small v-if="(v$.id_ship_agent.$invalid && submitted) || v$.id_ship_agent.$pending.$response" class="p-error">{{v$.id_ship_agent.required.$message.replace('Value', 'Ship agent')}}</small>
                    </div> 
                </form>
            </div>
            <template #footer>
                <Button data-cy="create_ship_responsible_button" form="from_new_ship_responsibles" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Dropdown from 'primevue/dropdown'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        Dropdown,
        InputText,
        Toast,
    },
    data() {
        return {
            displayModal: false,
            name: '',
            phone: '',
            id_ship_agent:'',
            ship_responsibles_errors: {},
            submitted: false,
            showMessage: false,
        }
    },
    created() {
        this.$store.dispatch('load_all_ship_agents')
    },
    computed: {
        all_ship_agents: function() {
            return this.$store.getters.all_ship_agents
        },
        get_ship_responsibles_errors: function() {
            return this.$store.getters.get_ship_responsibles_errors
        }
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_ship_responsibles() {
            let ship_responsibles = { 
                name: this.name,
                phone: this.phone,
                id_ship_agent:this.id_ship_agent,
            }
            await this.$store.dispatch('create_ship_responsibles', ship_responsibles)
            this.ship_responsibles_errors = this.get_ship_responsibles_errors
            if(Object.keys(this.ship_responsibles_errors).length === 0){
                this.toggleDialog();
                this.resetForm();
                return true
            }
            return false

        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

            let res = await this.add_ship_responsibles()

            if (res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Ship responsible added', life: 3000});
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.name = '';
            this.phone = '';
            this.id_ship_agent = null;
            this.submitted = false;
        }
    },
    validations() {
        return {
            name: { required },
            phone: { required },
            id_ship_agent: { required },

        }
    },
};
</script>

<style scoped>
</style>