<template>
    <Toast/>
    <div v-if="!loading">
        <div class="row">
            <div class="col text-center">
                <i class="pi pi-spin pi-spinner mt-5" style="font-size: 5rem;" :style="{color: color}"></i>
            </div>
        </div>
    </div>
    <div v-else>
        <ScrollPanel style="min-width: 800px;">
            <div class="row pt-2">
                <div v-if="service_sheet.signed == true && !print" class="col-12 md:col-10">
                    <Message :closable="false"> This service service is already signed. </Message>
                </div>
                <div v-if="print" class="col text-end my-auto">
                    <Button class="p-button-raised" label="Print" icon="fa fa-print" @click="print_pdf"/>
                </div>
                <div v-else class="col-12 md:col text-center my-auto">
                    <Button class="p-button-raised" label="Sign" icon="fa fa-file-signature" @click="modal_password = true"/>
                </div>
            </div>
            <div class="container-fluid" ref="document">
                <div class="row pt-2" >
                    <div class="offset-1 col-2 padding-left">
                        <Image :src="logo" imageClass="img-fluid"/>
                    </div>
                    <div class="col-7 text-center pt-5 mt-2">
                        <h2> <strong> SERVICE SHEET </strong> </h2>
                    </div>
                    <div class="col-2 pt-4">
                        <p>Nº <strong>{{service_sheet.id_call.number ? service_sheet.id_call.number: '-'}}</strong></p>
                    </div>
                </div>
                <div class="row">
                    <div class="row text-center">
                        <div class="col text-end">
                            <span> <strong> SHIP </strong> </span>
                        </div>
                        <div class="col text-start">
                            <span v-if="service_sheet.id_call">
                                {{service_sheet.id_call.id_ship.name }}
                            </span>
                        </div>
                    </div>
                    <div v-if="ship_agent" class="row text-center">
                        <div class="col text-end">
                            <span> <strong> SHIP AGENT </strong> </span>
                        </div>
                        <div class="col text-start">
                                {{ship_agent}}
                        </div> 
                    </div>
                    <div class="row text-center">
                        <div class="col text-end">
                            <span> <strong> DOCKING </strong> </span>
                        </div>
                        <div class="col text-start">
                            <span v-if="service_sheet.id_call">
                                {{service_sheet.pier ? service_sheet.pier: ''}} <span v-if="service_sheet.pier">-</span> Terminal {{service_sheet.id_terminal.name}}
                            </span>
                        </div>
                    </div>
                    <div v-if="service_sheet.id_call.id_ship.shipowner" class="row text-center">
                        <div class="col text-end">
                            <span> <strong> SHIPOWNER </strong> </span>
                        </div>
                        <div class="col text-start">
                            <span v-if="service_sheet.id_call">
                                {{service_sheet.id_call.id_ship.shipowner ? service_sheet.id_call.id_ship.shipowner: 'Not assigned'}} 
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row pt-3 pb-3">

                </div>
                <div class="row">
                    <div class="offset-2 col">

                    </div>
                    <div class="col text-center" style="border-bottom: 3px solid #002d72;">
                        <strong>ESTIMATION</strong>
                    </div>
                    <div class="col text-center" style="border-bottom: 3px solid #002d72;">
                        <strong>FINAL</strong>
                
                    </div>
                    <div class="col text-center" style="border-bottom: 3px solid #002d72;">
                        <span v-if="port == 1">
                            <strong>NIGHT</strong>
                        </span>
                        <span v-else-if="port == 2">
                            <strong>> 15 H</strong>
                        </span>
                    </div>
                    <div class="col text-center" style="border-bottom: 3px solid #002d72;">
                
                        <strong style="color: orange;">CREW</strong>
                    
                    </div>
                    <div class="col">

                    </div>
                </div>
                <div class="row">
                    <div class="offset-1 col">
                        <strong>DIS.</strong>
                    </div>
                    <div class="offset-1 col text-center">
                        <span v-if="service_sheet.e_p_disembarking || service_sheet.e_p_disembarking == 0">
                            <p>{{service_sheet.e_p_disembarking}}</p>
                        </span>
                        <span v-else>
                        <p><i class="fa fa-xmark"></i> </p>
                        </span>
                    </div> 
                    <div class="col text-center" style="border-left: 3px solid #002d72;">
                        <span v-if="service_sheet.p_disembarking || service_sheet.p_disembarking == 0">
                            <p>{{service_sheet.p_disembarking}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col text-center" >
                        <span v-if="service_sheet.disembarking_extra || service_sheet.disembarking_extra == 0">
                            <p>{{service_sheet.disembarking_extra}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col text-center">
                        <span v-if="service_sheet.c_disembarking || service_sheet.c_disembarking == 0">
                            <p>{{service_sheet.c_disembarking}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col">

                    </div>
                </div>
                <div class="row" >
                    <div class="offset-1 col">
                        <strong>EMB.</strong>
                    </div>
                    <div class="offset-1 col text-center" >
                        <span v-if="service_sheet.e_p_embarking || service_sheet.e_p_embarking == 0">
                            <p>{{service_sheet.e_p_embarking}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col text-center" style="border-left: 3px solid #002d72;">
                        <span v-if="service_sheet.p_embarking || service_sheet.p_embarking == 0">
                            <p>{{service_sheet.p_embarking}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col text-center" >
                        <span v-if="service_sheet.embarking_extra || service_sheet.embarking_extra == 0">
                            <p>{{service_sheet.embarking_extra}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col text-center">
                        <span v-if="service_sheet.c_embarking || service_sheet.c_embarking == 0">
                            <p>{{service_sheet.c_embarking}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col">

                    </div>
                </div>
                <div class="row">
                    <div class="offset-1 col">
                        <strong>TRANSIT</strong>
                    </div>
                    <div class="offset-1 col text-center">
                        <span v-if="service_sheet.e_p_transit || service_sheet.e_p_transit == 0">
                            <p>{{service_sheet.e_p_transit}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col text-center" style="border-left: 3px solid #002d72;">
                        <span v-if="service_sheet.p_transit || service_sheet.p_transit == 0">
                            <p>{{service_sheet.p_transit}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col text-center">
                        <span v-if="service_sheet.transit_extra || service_sheet.transit_extra == 0">
                            <p>{{service_sheet.transit_extra}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col text-center">
                        <span v-if="service_sheet.c_transit">
                            <p>{{service_sheet.c_transit}}</p>
                        </span>
                        <span v-else>
                            <p><i class="fa fa-xmark"></i></p>
                        </span>
                    </div>
                    <div class="col">

                    </div>
                </div>
                <div class="row pt-3 pb-3">

                </div>
                <div class="row">
                    <div class="offset-1 col-3">

                    </div>
                    <div class=" col text-center" style="border-bottom: 3px solid #002d72;">
                        <strong>ESTIMATION</strong>
                    </div>
                    <div class="col text-center" style="border-bottom: 3px solid #002d72;">
                        <strong>FINAL</strong>
                    </div>
                    <div class="col">

                    </div>
                </div>
                <div class="row">
                    <div class="offset-1 col-3" >
                        <strong>ARRIVAL DATE</strong>
                    </div>
                    <div class=" col text-center">
                        <span v-if="service_sheet.estimated_arrival_datetime">
                            <p>{{format_date(service_sheet.estimated_arrival_datetime)}} - {{format_hour(service_sheet.estimated_arrival_datetime)}}</p>
                        </span>
                        <span v-else>
                            <h1> <i class="fa fa-xmark"></i> </h1>
                        </span>
                    </div>
                    <div class="col text-center" style="border-left: 3px solid #002d72;">
                        <span v-if="service_sheet.arrival_datetime">
                            <p>{{format_date(service_sheet.arrival_datetime)}} - {{format_hour(service_sheet.arrival_datetime)}}</p>
                        </span>
                        <span v-else>
                            <h1> <i class="fa fa-xmark"></i> </h1>
                        </span>
                    </div>
                    <div class="col">

                    </div>
                </div>
                <div class="row">
                    <div class="offset-1 col-3">
                        <strong>DEPARTURE DATE</strong>
                    </div>
                    <div class=" col text-center">
                        <span v-if="service_sheet.estimated_departure_datetime">
                            <p>{{format_date(service_sheet.estimated_departure_datetime)}} - {{format_hour(service_sheet.estimated_departure_datetime)}}</p>
                        </span>
                        <span v-else>
                            <h1> <i class="fa fa-xmark"></i> </h1>
                        </span>
                    </div>
                    <div class="col text-center" style="border-left: 3px solid #002d72;">
                        <span v-if="service_sheet.departure_datetime">
                            <p>{{format_date(service_sheet.departure_datetime)}} - {{format_hour(service_sheet.departure_datetime)}}</p>
                        </span>
                        <span v-else>
                            <h1> <i class="fa fa-xmark"></i> </h1>
                        </span>
                    </div>
                    <div class="col">

                    </div>
                </div>
                <div class="row pt-5 pb-3">

                </div>
                <div class="row">
                    <div class="col text-center" >
                        <strong style="border-bottom: 3px solid #002d72;">OVERTIME</strong>
                        <span v-if="service_sheet.over_time">
                            <p class="pt-2"> {{service_sheet.over_time}} </p>
                        </span>
                        <span v-else>
                            <p class="pt-2"> <i class="fa fa-xmark"></i> </p>
                        </span>
                    </div>
                    <div class="col text-center">
                        <strong style="border-bottom: 3px solid #002d72;">OVER NIGHT</strong>
                        <span v-if="service_sheet.over_night" >
                            <p class="pt-2"> {{service_sheet.over_night}} </p>
                        </span>
                        <span v-else>
                            <p class="pt-2"> <i class="fa fa-xmark"></i> </p>
                        </span>
                    </div>
                    <div class="col text-center">
                        <strong style="border-bottom: 3px solid #002d72;">WEEKEND</strong>
                        <span v-if="service_sheet.weekend">
                            <p class="pt-2"> <i class="fa fa-check"></i> </p>
                        </span>
                        <span v-else>
                            <p class="pt-2"> <i class="fa fa-xmark"></i> </p>
                        </span>
                    </div>
                </div>
                <div class="row pt-3">
                    <div v-if="port == 1" class="col text-center">
                        <strong style="border-bottom: 3px solid #002d72;">WATER</strong>
                        <span v-if="get_water_service.length > 0">
                            <p class="pt-2"> {{ get_water_service[0].m3 }} m³</p>
                        </span>
                        <span v-else>
                            <p class="pt-2"> <i class="fa fa-xmark"></i> </p>
                        </span>
                    </div>
                    <div class="col text-center">
                        <strong style="border-bottom: 3px solid #002d72;">FINGERS</strong>
                        <span v-if="get_finger_service.length > 0">
                            <span v-for="finger in get_finger_service" :key="finger">
                                <p> {{finger.finger.name}} </p>
                            </span>
                        </span>
                        <span v-else >
                            <p class="pt-2"> <i class="fa fa-xmark"></i> </p>
                        </span>
                    </div>
                    <div class="col">

                    </div>
                </div>
            </div>
        </ScrollPanel>
    </div>

    <Dialog header="Password" v-model:visible="modal_password" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div v-if="block_page == true" style="position: absolute;margin-left: auto;margin-right: auto;left: 0;right: 0;text-align: center;z-index: 1">
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
        </div>
        <div v-if="!get_current_user.profile.signature" class="row container">
            <div class="col">
                <Message :closable="false" severity="info"> If you don't have a signature uploaded to your profile, you won't be able to sign. </Message>
            </div>
        </div>
        <div class="row">  
            <p> Use 'Test1234' as a temporary password.  </p>
        </div>
        <div class="row">
            <div class="flex form-group col-12 md:col-6">
                <div class="d-flex flex-column">
                    <label for="password_signature"> Password </label>
                    <Password id="password_signature" v-model="password_signature" toggleMask/>
                </div>
            </div>
            <div class="flex form-group col-12 md:col-6">
                <div class="d-flex flex-column">
                    <label for="sign_comment"> Sign Comment </label>
                    <Textarea id="sign_comment" v-model="sign_comment"></Textarea>
                </div>
            </div>

        </div>
        <div class="row">
            <small class="p-error"> {{error_password}} </small>
            <small v-if="get_error_password_pdf" class="p-error"> {{get_error_password_pdf.message}} </small>
        </div>
        <template #footer>
            <span v-if="block_page">
                <Button disabled label="Cancel" class="p-button-text" @click="modal_password = false"/>
                <Button disabled label="Sign" @click="handle_submit"/>
            </span>
            <span v-else>
                <Button label="Cancel" class="p-button-text" @click="modal_password = false"/>
                <Button :disabled="!get_current_user.profile.signature" label="Sign" @click="handle_submit"/>
            </span>
        </template>
    </Dialog>

</template>

<script>
import html2pdf from 'html2pdf.js'
import Image from 'primevue/image'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import Password from 'primevue/password'
import Message from 'primevue/message'
import ScrollPanel from 'primevue/scrollpanel'

import ability from '../../services/ability'

export default {
    components: {
        Image,
        Button,
        Toast,
        Dialog,
        Password,
        Message,
        ScrollPanel,
        Textarea
    },
    props: {
        print: {
            type: Boolean
        }
    },
    emits: ['close_modal'],
    data(){
        return {
            logo: {},
            port: '',
            port_name: '',
            service_sheet: {},
            loading: false,
            color: localStorage.getItem('primary_color'),
            service_sheet_pdf: {},
            modal_password: false,
            password_signature: '',
            error_password: '',
            start_date: '',
            block_page: false,
            ship_agent: '',
            sign_comment:''
        }
    },
    computed: {
		get_service_sheet: function ()  {
			return this.$store.getters.get_service_sheet 
		},
        all_port_calls_signatures: function() {
            return this.$store.getters.all_port_calls_signatures
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        get_error_password_pdf: function() {
            return this.$store.getters.get_error_password_pdf
        },
        get_ship_agents: function() {
            return this.$store.getters.get_ship_agents
        },
        get_water_service: function() {
            return this.$store.getters.get_water_service
        },
        get_finger_service: function() {
            return this.$store.getters.get_finger_service
        },
    },
    async mounted() {
        await this.$store.dispatch('load_service_sheet', (Number(this.$route.params.sid)))
        await this.$store.dispatch('load_signature_calls', 'Pending')
        if(ability.can('view','water')) {
            await this.$store.dispatch('load_water_service', (Number(this.$route.params.sid)))
        }
        await this.$store.dispatch('load_finger_service', (Number(this.$route.params.sid)))
        this.logo = this.get_current_user.profile.id_port.logo
        this.port = this.get_current_user.profile.id_port.id
        this.port_name = this.get_current_user.profile.id_port.name
        // this.logo = URL.createObjectURL(await fetch(this.logo).then(res => res.blob()))
        this.service_sheet = this.get_service_sheet
        if(this.service_sheet.id_call.id_ship_responsible != null) {
            await this.$store.dispatch('load_ship_agent_by_responsible', this.service_sheet.id_call.id_ship_responsible)
            if(this.get_ship_agents.length != 0) {
                this.ship_agent = this.get_ship_agents[0].name
            }
        }
        this.loading = true
    },
    methods: {
        format_date(date) {
			return date.split('T')[0]
		},
        format_hour(date){
            return date.split('T')[1].split(':')[0] + ':' + date.split('T')[1].split(':')[1]
        },
        async handle_submit(){
            
            this.error_password  = ''
            await this.$store.dispatch('clean_pdf_errors')

            if(this.password_signature != '') {
                this.block_page = true
                this.exportToPDF()
            } else {
                this.error_password = 'Password is required'
            }

        },
        async exportToPDF(){
            
			await html2pdf().from(this.$refs.document).set({
                margin: 1,
                filename: "unsigned_version.pdf",
                html2canvas: { dpi: 192, letterRendering: true, scale: 4, useCORS: true},
                jsPDF: { unit: "pt", format: "a4", orientation: "p" },
            }).toPdf().get('pdf').then(async (pdfObj) => {
                this.service_sheet_pdf.id_service_sheet = this.service_sheet.id
                this.service_sheet_pdf.number = this.service_sheet.id_call.number
                this.service_sheet_pdf.pdf = pdfObj.output('blob')
                this.service_sheet_pdf.password = this.password_signature
                this.service_sheet_pdf.location = this.port_name.split(' ').pop()
                this.service_sheet_pdf.sign_comment = this.sign_comment
                await this.$store.dispatch('send_pdf', this.service_sheet_pdf)
            });

            if(Object.keys(this.get_error_password_pdf).length == 0) {
               
                await this.$store.dispatch('load_signature_calls', 'Pending')
                this.block_page = false
                this.$emit('close_modal')
                
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'PDF signed successfully.', life: 3000});
                
            }

            
            this.block_page = false
		
        },
        print_pdf(){
            if(this.service_sheet.arrival_datetime) {
                this.start_date = this.service_sheet.arrival_datetime
            } else {
                this.start_date = this.service_sheet.estimated_arrival_datetime
            }
            html2pdf().from(this.$refs.document).set({
                margin: 1,
                filename: this.service_sheet.id_call.id_ship.name + "_" + this.start_date,
                html2canvas: { dpi: 192, letterRendering: true, scale: 4, useCORS: true},
                jsPDF: { unit: "pt", format: "a4", orientation: "p" },
            }).save();
        }
    }
}
</script>


<style scoped>


.padding-left {
    padding: 0.5rem 0.5rem 0.5rem 0 !important;
}

.bg-test{
    background-color: v-bind(color); 
    color: white;
}

:deep(.p-scrollpanel-content) {
    overflow-x: hidden;
}

:deep(.p-input-icon-right>svg){
    right: 0.75rem
}

</style>