import axios from 'axios'
import moment from 'moment'

const state = {
    shuttle_service: {},
    shuttle_service_shifts: [],
    send_shuttle_mail: false,
    error_shuttle_service_shifts: [],
}

const mutations = {
    SET_SHUTTLE_SERVICE(state, shuttle_service) {
        state.shuttle_service = shuttle_service
    },
    SET_SHUTTLE_SERVICE_SHIFT(state, shuttle_service_shift) {
        state.shuttle_service_shifts = shuttle_service_shift
    },
    SET_ERROR_SHUTTLE_SERVICE_SHIFTS(state, error_shuttle_service_shifts) {
        state.error_shuttle_service_shifts.push(error_shuttle_service_shifts)
    },
    UPDATE_SHUTTLE_SERVICE(state, shuttle_service) {
        state.shuttle_service = Object.keys(state.shuttle_service).map(updated_shuttle_service => {
            if(updated_shuttle_service.id === shuttle_service.id) {
                updated_shuttle_service.manager = shuttle_service.manager
                updated_shuttle_service.note = shuttle_service.note
            }
        })
    },
    UPDATE_SHUTTLE_SERVICE_SHIFTS(state, shuttle_service_shifts) {
        state.shuttle_service_shifts = Object.keys(state.shuttle_service_shifts).map(updated_shuttle_service_shifts => {
            if(updated_shuttle_service_shifts.id === shuttle_service_shifts.id) {
                updated_shuttle_service_shifts.start_datetime = shuttle_service_shifts.start_datetime
                updated_shuttle_service_shifts.end_datetime = shuttle_service_shifts.end_datetime
                updated_shuttle_service_shifts.quantity = shuttle_service_shifts.quantity
            }
        })
    },
    DELETE_SHUTTLE(state, id) {
        console.log(id)
    },
    DELETE_SHUTTLE_SERVICE(state, id) {
        console.log(id)
    },
    UPDATE_MAIL_STATUS_SHUTTLE(state, cond) {
        if(cond == true) {
            state.send_shuttle_mail = true    
        }
    },
    UPDATE_STATUS_MAIL_SHUTTLE(state) {
        state.send_shuttle_mail = false
    },
    SET_ERROR_SHUTTLE(state, error) {
        state.error_shuttle_service_shifts = error
    },
    CLEAN_ERROR_SHUTTLE(state) {
        state.error_shuttle_service_shifts = []
    }
}

const actions = {
	async load_shuttle_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'shuttle/get_service/' + id) 
			.then((response) => {
				commit('SET_SHUTTLE_SERVICE', response.data)
			})
	},
    async load_shuttle_service_shifts({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'shuttle_service/service_sheet/' + id + '/')
            .then((response)=> {
                commit('SET_SHUTTLE_SERVICE_SHIFT', response.data)
            })
    },
    async create_shuttle_service({commit}, shuttle_service) {
        await axios.post(process.env.VUE_APP_API_URL + 'shuttle/', {
            id_service_sheet: shuttle_service.id_service_sheet,
            manager: shuttle_service.manager.id,
            id_supplier: shuttle_service.id_supplier,
            note: shuttle_service.note
        })
            .then((response) => {
                commit('SET_SHUTTLE_SERVICE', response.data)
            })
    },
    async create_shuttle_service_shifts({commit}, shuttle_service){
        await axios.post(process.env.VUE_APP_API_URL + 'shuttle_service/', {
            start_datetime: moment(shuttle_service.end_datetime).format().split('+')[0],
            end_datetime: moment(shuttle_service.end_datetime).format().split('+')[0],
            quantity: shuttle_service.quantity,
            shuttle: shuttle_service.shuttle,
            id: shuttle_service.id
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_SHUTTLE', true)
                commit('SET_SHUTTLE_SERVICE_SHIFT', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_SHUTTLE_SERVICE_SHIFTS', error.response.data)
            })
    },
    async update_shuttle_service({commit}, shuttle_service) {
        await axios.patch(process.env.VUE_APP_API_URL + 'shuttle/' + shuttle_service.id + '/', {note: shuttle_service.note, manager: shuttle_service.manager.id})
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_SHUTTLE', response.data.edited)
                commit('UPDATE_SHUTTLE_SERVICE', response.data)
            })
    },
    async update_shuttle_service_shifts ({commit}, shuttle_service) {
        await axios.patch(process.env.VUE_APP_API_URL + 'shuttle_service/' + shuttle_service.id + '/', {
            quantity: shuttle_service.quantity,
            start_datetime: moment(shuttle_service.start_datetime).format().split('+')[0],
            end_datetime: moment(shuttle_service.end_datetime).format().split('+')[0],
            service_type: shuttle_service.service_type
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_SHUTTLE', response.data.edited)
                commit('UPDATE_SHUTTLE_SERVICE_SHIFTS', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_SHUTTLE_SERVICE_SHIFTS', error.response.data)
            })
    },
    async delete_shuttle({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'shuttle/' + id + '/')
            .then((response) => {

                commit('DELETE_SHUTTLE', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_SHUTTLE', error.response.data)
            })
    },
    async delete_shuttle_service({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'shuttle_service/' + id + '/')
            .then((response) => {
                commit('DELETE_SHUTTLE_SERVICE', response.data)
            })
    },
    async send_shuttle_service_mail({commit}, id){
        await axios.post(process.env.VUE_APP_API_URL + 'shuttle/send_mail/' + id + '/')
            .then(() => {
                commit('UPDATE_STATUS_MAIL_SHUTTLE')
                commit('CLEAN_ERROR_SHUTTLE')
            })
            .catch((error) => {
                commit('SET_ERROR_SHUTTLE', error.response.data)
            })
    },
    async clean_errors_shuttle_shifts({commit}) {
        commit('CLEAN_ERROR_SHUTTLE')
    }
       
}

const getters = {
    get_shuttle_service: (state) => state.shuttle_service,
    get_shuttle_service_shift: (state) => state.shuttle_service_shifts,
    get_send_shuttle_mail: (state) => state.send_shuttle_mail,
    get_error_shuttle_service_shifts: (state) => state.error_shuttle_service_shifts
}

export default {
    state,
    mutations,
    actions,
    getters
};