<template>
    <div v-if="blockedDocument" style="position: absolute; top:0;left:0;">
        <ProgressBar mode="indeterminate" style="height: 6px; width: 100vw;"></ProgressBar>
    </div>
    <div class="container-fluid mt-2">
        <div class="grid justify-content-between">
            <div class="col-12 md:col my-auto">
                <h1 style="margin-bottom: 0px;"> Graphics</h1>
            </div>
            <div class="col-12 md:col-3">
                <div class="flex">
                    <div class="col text-center my-auto">
                        <Button icon="fa fa-chevron-left" class="p-button-sm p-button-text p-button-rounded" @click="previous_year"/>
                    </div>
                    <div class="col my-auto text-center">
                        <h4 style="margin-bottom: 0px;"> {{ year }} </h4>
                    </div>
                    <div class="col text-center my-auto">
                        <Button icon="fa fa-chevron-right" class="p-button-sm p-button-text p-button-rounded" @click="next_year"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex">
        <div class="col my-auto text-center" >
            <SelectButton :options="title_options" v-model="selected_option" optionLabel="title" @click="click_option()" unselectable/>
        </div>
    </div>
    <div class="flex mt-5 justify-content-center">
        <div class="col-12 card" style="height: calc(100vh - 21rem)">
            <Chart type="bar" :data="basicData" :options="basicOptions" style="height: calc(100vh - 21rem)"/>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Chart from 'primevue/chart'
import SelectButton from 'primevue/selectbutton'
import ProgressBar from 'primevue/progressbar'

import moment from 'moment'

export default {
    name: 'Graphics',
    components: {
        Chart,
        Button,
        SelectButton,
        ProgressBar
    },
    data() {
        return {
            blockedDocument: false,
            color: localStorage.getItem('primary_color'),
            year: moment().format('YYYY'),
            selected_option: {
                    title: 'Calls',
                    value: 'calls',
                    constant: true
                },
            title_options: [
                {
                    title: 'Calls',
                    value: 'calls',
                },
                {
                    title: 'Embarking',
                    value: 'emb'
                },
                {
                    title: 'Disembarking',
                    value: 'dis'
                },
                {
                    title: 'E+D',
                    value: 'E+D'
                },
                {
                    title: 'Transit',
                    value: 'transit'
                },
                {
                    title: 'PAX',
                    value: 'pax'
                },
                {
                    title: 'Water',
                    value: 'water'
                },
            ],
            index_options: 0,
            data: [],
            basicData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',],
                datasets: [
                    {
                        label: 'Calls',
                        data: this.data,
                        fill: false,
                        backgroundColor: this.color,
                        tension: .4
                    },
                ]
            },
            basicOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
        }
    },
    computed: {
        get_terminal_statistics: function() {
            return this.$store.getters.get_terminal_statistics
        },
        get_call_statistics: function() {
            return this.$store.getters.get_call_statistics
        },
    },
    async mounted() {
        this.blockedDocument = true
        await this.$store.dispatch('load_terminals_statistics', this.year) 
        await this.$store.dispatch('load_calls_statistics', this.year) 
        
        await this.push_data_chart()
        this.blockedDocument = false
    },
    methods: {
        async previous_year() {
            this.year = this.year - 1
            this.blockedDocument = true;
            this.reset_data()
            await this.$store.dispatch('load_terminals_statistics', this.year) 
            await this.$store.dispatch('load_calls_statistics', this.year)
            await this.push_data_chart()
        },
        async next_year() {
            this.year = this.year + 1
            this.blockedDocument = true;
            this.reset_data()
            await this.$store.dispatch('load_terminals_statistics', this.year)
            await this.$store.dispatch('load_calls_statistics', this.year) 
            await this.push_data_chart()
        },
        async click_option(){
            await this.reset_data()
            await this.push_data_chart()
        },
        async reset_data() {
            this.basicData = []
            this.data = []
        },
        async push_data_chart(){
            await this.reset_data()

            for(var it in this.title_options){
                if(this.selected_option.value == this.title_options[it].value){
                    this.title_options[it].constant = true
                } else {
                    this.title_options[it].constant = false
                }
            }

            for(var item in this.get_call_statistics[this.selected_option.value]) {
                if(typeof(this.get_call_statistics[this.selected_option.value][item]) != 'string' && item != 'total') {
                    this.data.push(this.get_call_statistics[this.selected_option.value][item])
                }
            }

            this.basicData = {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                datasets: [
                    {
                        label: this.selected_option.title,
                        data: this.data,
                        fill: false,
                        backgroundColor: this.color,
                        tension: .4
                    },
                ]
            }

            this.blockedDocument = false;
        }
    }
}
</script>

<style scoped>

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

</style>