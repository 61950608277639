<template>
    <div class="mt-5 p-fluid container-fluid">
        <div class="row">
            <div class="col">
                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Type </h5>
            </div>
        </div>
        <div class="grid">
            <div v-for="item in types" :key="item" class="col-12 md:col">
                <div class="card p-3" style="cursor: pointer; margin-bottom: 0px;" @click="selected_type= item; card = ''" :class="{'active_type': item === selected_type}">
                    <h5> {{item}} </h5>
                </div>
            </div>
        </div>
        <small v-show="validationErrors.selected_type && submitted" class="p-error"> Payment type is required. </small>
        <div v-if="selected_type == 'Credit card'"  class="row mt-3">
            <div class="col-4">
                <Dropdown v-model="card" :options="get_cards" filter placeholder="Card">
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                            <div> {{ slotProps.value.number }} </div>
                        </div>
                        <span v-else>
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div class="flex align-items-center">
                            <div> {{ slotProps.option.number }}</div>
                        </div>
                    </template>
                </Dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Bill Information </h5>
            </div>
        </div> 
        <div class="row justify-content-between">
            <div class="col-12 md:col-4">
                <div class="p-float-label mt-2">
                    <InputText v-model="serial_number"/>
                    <label> Serial Number </label>
                </div>  
                <small v-show="validationErrors.serial_number && submitted" class="p-error"> Serial number is required. </small>
            </div>
        </div>
        <div class="row">
            <div class="col-12 md:col-4">
                <div class="p-float-label mt-2">
                    <MultiSelect :options="all_terminals" optionLabel="name"  v-model="terminals"/>
                    <label> Terminal </label>
                </div>  
                <small v-show="validationErrors.terminal && submitted" class="p-error"> Terminal is required. </small>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Dates </h5>
            </div>
        </div> 
        <div class="row">
            <div class="col-12 md:col-4">
                <div class="p-float-label mt-2">
                    <Calendar v-model="issue_date" dateFormat="yy-mm-dd"/>
                    <label> Issue Date </label> 
                </div>  
            </div>
            <div class="col my-auto">
                <small style="color:gray;">Date when the invoice was created or issued. It is the date on which the seller generates the invoice and sends it to the buyer.</small>
            </div>
        </div>
        <div class="row">
            <div class="col-12 md:col-4">
                <div class="p-float-label mt-2">
                    <Calendar v-model="payment_date" dateFormat="yy-mm-dd"/>
                    <label> Payment Date </label>
                </div>  
            </div>
            <div class="col my-auto">
                <small style="color:gray;">Date on which the payment for the invoice is actually made by the buyer. It is the date when the seller receives the payment for the goods or services that were provided to the buyer.</small>
            </div>
        </div>
        <div class="row">
            <div class="col-12 md:col-4">
                <div class="p-float-label mt-2">
                    <Calendar v-model="due_date" dateFormat="yy-mm-dd"/>
                    <label> Due Date </label>
                </div>  
            </div>
            <div class="col my-auto">
                <small style="color:gray;">Date by which the payment for the invoice is due. It is the deadline by which the buyer is expected to make the payment to the seller.</small>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Curreny </h5>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12 md:col-3">
                <div class="p-float-label">
                    <Dropdown v-model="currency" :options="get_currencies" filter placeholder="Currency">
                        <template #value="slotProps">
                            <div v-if="slotProps.value" class="flex align-items-center">
                                <div> {{ slotProps.value.name }} - {{ slotProps.value.symbol }}</div>
                            </div>
                            <span v-else>
                                {{ slotProps.placeholder }}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <div class="flex align-items-center">
                                <div> {{ slotProps.option.name }} - {{ slotProps.option.symbol }}</div>
                            </div>
                        </template>
                    </Dropdown>
                </div>  
                <small v-show="validationErrors.currency && submitted" class="p-error"> Currency is required. </small>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Concept </h5>
            </div>
            <div class="row mt-2">
                <div class="col-12 md:col-12">
                    <div class="p-float-label">
                        <InputText v-model="concept"/>
                        <label> Text </label>
                    </div> 
                    <small v-show="validationErrors.concept && submitted" class="p-error"> Concept is required. </small> 
                    <small v-show="validationErrors.concept_length && submitted" class="p-error"> Character limit exceeded. Please reduce your text to 300 characters or less. </small> 
                </div>
            </div>
        </div>
    </div> 
    <div class="grid grid-nogutter justify-content-between mt-5 mb-5">
        <Button label="Back" class="p-button-raised" @click="prevPage()" icon="pi pi-angle-left" />
        <Button label="Create Bill" class="p-button-raised" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
    </div>   
</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import MultiSelect from 'primevue/multiselect'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'

import moment from 'moment'

export default {
    name: 'Bill_information',
    data() {
        return{
            color: localStorage.getItem('primary_color'),
            concept: '',
            terminals: '',
            serial_number: '',
            issue_date: '',
            payment_date: '',
            due_date: '',
            currency: '',
            card: '',
            submitted: false,
            validationErrors: [],
            selected_type: '',
            types: [
                'Cash',
                'Bank transfer',
                'Credit card',
                'Direct debit'
            ],
            
        }
    },
    props: {
        formData: Object
    },
    components: {
        InputText,
        Button,
        MultiSelect,
        Calendar,
        Dropdown,
    },
    activated() {
        if(Object.keys(this.formData).length == 0) {
            this.$router.push({path: '/billing/steps'})
        }     
    },
    computed: {
        all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        get_currencies: function() {
            return this.$store.getters.get_currencies
        },
        get_cards: function() {
            return this.$store.getters.get_cards
        }
    },
    async mounted(){
        await this.$store.dispatch('load_all_terminals')
        await this.$store.dispatch('load_all_currencies')
        await this.$store.dispatch('load_all_cards')
    },
    methods: {
        change_total_base(event) {
            this.total_import = this.roundToTwo(event.value + (event.value * (this.iva / 100)))
        },
        change_total_iva(event) {
            this.total_import = this.roundToTwo(this.base_import + (this.base_import * (event.value / 100)))
        },
        roundToTwo(num) {
            return + (Math.round(num + "e+2")  + "e-2");
        },
        async nextPage() {
            this.submitted = true
            if (this.validateForm()) {
                if(this.issue_date != '') {
                    this.issue_date = moment(this.issue_date).format('YYYY-MM-DD')
                }
                if(this.payment_date != '') {
                    this.payment_date = moment(this.payment_date).format('YYYY-MM-DD')
                }
                if(this.due_date != '') {
                    this.due_date = moment(this.due_date).format('YYYY-MM-DD')
                }

                this.$emit('next-page', { formData: { payment_type: this.selected_type, terminals: this.terminals, issue_date: this.issue_date, payment_date:this.payment_date, due_date: this.due_date, number: this.serial_number, concept: this.concept, id_currency: this.currency, id_card: this.card }, pageIndex: 2 });
            }
        },
        validateForm() {
            if (!this.selected_type) { 
                this.validationErrors['selected_type'] = true;
            } else {
                delete this.validationErrors['selected_type'];
            } 
            if (!this.serial_number) { 
                this.validationErrors['serial_number'] = true;
            } else {
                delete this.validationErrors['serial_number'];
            } 
            if (!this.concept) { 
                this.validationErrors['concept'] = true;
            } else {
                delete this.validationErrors['concept'];
            } 
            if (!this.currency) { 
                this.validationErrors['currency'] = true;
            } else {
                delete this.validationErrors['currency'];
            }
            if(this.concept.length > 300) {
                this.validationErrors['concept_length'] = true;
            } else {
                delete this.validationErrors['concept_length']
            }
        
            return !Object.keys(this.validationErrors).length;
        },
        prevPage() {
            this.$emit('prev-page', { pageIndex: 2 });
        },
        onUpload(e){
            if(e.files[0].name.split('.').pop() == 'pdf') {
                this.url_type = 'pdf'
            } else {
                this.url_type = 'image'
            }
            this.bill = e.files[0]
            const file = e.files[0];
            this.url = URL.createObjectURL(file);
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
    }    
}
</script>

<style scoped>
.active_type {
    background-color: v-bind(color) !important;
    color: white;
}
</style>