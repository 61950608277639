import axios from 'axios'

const state = {
    terminals: [],
    terminal_errors: {},
    delete_status : false,
    terminal_names : []
};

const mutations = {
    SET_TERMINALS(state, terminals) {
        state.terminals = terminals
    },
    SET_TERMINAL_NAMES(state, terminal_names) {
        state.terminal_names = terminal_names
    },
    NEW_TERMINAL(state, terminal) {  
        state.terminals.push(terminal)
        state.terminal_errors = {}
    },
    DELETE_TERMINAL(state, id) {
        let index = state.terminals.findIndex(terminals => terminals.id == id)
        state.terminals.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_TERMINAL(state, terminal) {
        state.terminals = state.terminals.map(updated_terminal => {
            if(updated_terminal.id === terminal.id) {
                updated_terminal.name = terminal.name
            }
            return updated_terminal
        })
        state.terminal_errors = {}
    },
    ERRORS_TERMINAL(state, errors){
        state.terminal_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};

const actions = {
    async load_all_terminals({commit}) {
        let names = []
        await axios.get(process.env.VUE_APP_API_URL  + "terminals/")
            .then((response) => {
                commit('SET_TERMINALS', response.data)
                response.data.forEach(element => {
                    names.push(element.name)
                })
                commit('SET_TERMINAL_NAMES', names)
            })
    },
    async create_terminal({commit}, terminal) {
        await axios.post(process.env.VUE_APP_API_URL  + 'terminals/', terminal)
            .then((response) => {
                commit('NEW_TERMINAL', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_TERMINAL', error.response.data)
            })
    },
    async delete_terminal({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'terminals/' + id)
            .then(() => {
                commit('DELETE_TERMINAL', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async update_terminal({commit}, terminal) {
        await axios.put(process.env.VUE_APP_API_URL  + 'terminals/' + terminal.id + '/',  { name: terminal.name })
            .then(() => { 
                commit('UPDATE_TERMINAL', terminal)
            })
            .catch((error) => {
                commit('ERRORS_TERMINAL', error.response.data)
            })
    },
};

const getters = {
    all_terminals: (state) => state.terminals,
    get_errors_terminal: (state) => state.terminal_errors,
    get_delete_terminal_status:(state) => state.delete_status,
    get_terminal_names:(state) => state.terminal_names,
    get_terminal_by_name:(state) => (name) => {
        return state.terminals.filter(terminal => terminal.name === name)
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};