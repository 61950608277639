<template>
    <Toast/>
    <div class="container-fluid pt-3">
        <div class="row pt-3 pb-3 text-center">
            <h2> Supplier Assignment</h2>
        </div>
        <div v-if="loading" class="flex justify-content-center mt-5">
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
        </div>
        <div v-else class="pt-3">
            <div class="row pt-2">
                <div v-if="get_current_user.profile.id_port.name == 'Port de Barcelona'" class="col-12 md:col-4">
                    <Card>
                        <template #title>
                            Cleaning Service 
                        </template>
                         <template #content>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Supplier </label>
                                        </div>
                                        <div v-if="cleaning_service==true" class="row">
                                            <Dropdown id="cleaning_service" v-model="cleaning_service_supplier.id" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_cleaning($event)"/>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown id="cleaning_service" v-model="cleaning_service_supplier" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_cleaning($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Responsible </label>
                                        </div>
                                        <div v-if="cleaning_service==true" class="row">
                                            <div v-if="cleaning_service_responsible==''" class="row">
                                                <Dropdown v-model="cleaning_service_responsible" :options="cleaning_service_responsible_options" optionLabel="name" optionValue="id" @change="send_cleaning_service"/>
                                            </div>
                                            <div v-else>
                                                <div v-if="typeof cleaning_service_responsible === 'number'" class="row">
                                                    <Dropdown v-model="cleaning_service_responsible" :options="cleaning_service_responsible_options" optionLabel="name" optionValue="id" @change="send_cleaning_service"/>
                                                </div>
                                                <div v-else-if="typeof cleaning_service_responsible === 'object'" class="row">
                                                    <Dropdown v-model="cleaning_service_responsible.id" :options="cleaning_service_responsible_options" optionLabel="name" optionValue="id" @change="send_cleaning_service"/>
                                                 </div>
                                            </div>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown id="cleaning_service" v-model="cleaning_service_responsible" :options="cleaning_service_responsible_options" optionLabel="name" optionValue="id" @change="send_cleaning_service"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Card>
                </div>
                <div class="col-12 md:col-4">
                    <Card>
                        <template #title>
                            Porters Service  
                        </template>
                         <template #content>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Supplier </label>
                                        </div>
                                        <div v-if="porters_service==true" class="row">
                                            <Dropdown v-model="porters_service_supplier.id" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_porters($event)"/>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown v-model="porters_service_supplier" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_porters($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Responsible </label>
                                        </div>
                                        <div v-if="porters_service==true" class="row">
                                            <div v-if="porters_service_responsible==''" class="row">
                                                <Dropdown v-model="porters_service_responsible" :options="porters_service_responsible_options" optionLabel="name" optionValue="id" @change="send_porters_service"/>
                                            </div>
                                            <div v-else>
                                                <div v-if="typeof porters_service_responsible === 'number'" class="row">
                                                    <Dropdown v-model="porters_service_responsible" :options="porters_service_responsible_options" optionLabel="name" optionValue="id" @change="send_porters_service"/>
                                                </div>
                                                <div v-else-if="typeof porters_service_responsible === 'object'" class="row">
                                                    <Dropdown v-model="porters_service_responsible.id" :options="porters_service_responsible_options" optionLabel="name" optionValue="id" @change="send_porters_service"/>
                                                 </div>
                                            </div>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown v-model="porters_service_responsible" :options="porters_service_responsible_options" optionLabel="name" optionValue="id" @change="send_porters_service"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Card>
                </div>
                <div class="col-12 md:col-4">
                    <Card>
                        <template #title>
                            Terminal Security Service
                        </template>
                         <template #content>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Supplier </label>
                                        </div>
                                        <div v-if="terminal_security_service==true" class="row"> 
                                            <Dropdown v-model="terminal_security_service_supplier.id" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_terminal_security($event)"/>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown v-model="terminal_security_service_supplier" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_terminal_security($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Responsible </label>
                                        </div>
                                        <div v-if="terminal_security_service==true">
                                            <div v-if="terminal_security_service_responsible==''" class="row">
                                                <Dropdown v-model="terminal_security_service_responsible" :options="terminal_security_service_responsible_options" optionLabel="name" optionValue="id" @change="send_terminal_security_service"/>
                                            </div>
                                            <div v-else>
                                                <div v-if="typeof terminal_security_service_responsible === 'number'" class="row">
                                                    <Dropdown v-model="terminal_security_service_responsible" :options="terminal_security_service_responsible_options" optionLabel="name" optionValue="id" @change="send_terminal_security_service"/>
                                                </div>
                                                <div v-else-if="typeof terminal_security_service_responsible === 'object'" class="row">
                                                    <Dropdown v-model="terminal_security_service_responsible.id" :options="terminal_security_service_responsible_options" optionLabel="name" optionValue="id" @change="send_terminal_security_service"/>
                                                 </div>
                                            </div>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown v-model="terminal_security_service_responsible" :options="terminal_security_service_responsible_options" optionLabel="name" optionValue="id" @change="send_terminal_security_service"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Card>    
                </div>
            </div>
            <div class="row pt-4 pb-4">
                <div v-if="get_current_user.profile.id_port.name == 'Port de Barcelona'" class="col-12 md:col-4">
                    <Card>
                        <template #title>
                            K9 Service
                        </template>
                         <template #content>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Supplier </label>
                                        </div>
                                        <div v-if="k9_service==true" class="row">
                                            <Dropdown v-model="k9_service_supplier.id" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_k9($event)"/>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown v-model="k9_service_supplier" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_k9($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Responsible </label>
                                        </div>
                                        <div v-if="k9_service==true" class="row">
                                            <div v-if="k9_service_responsible==''" class="row">
                                                <Dropdown v-model="k9_service_responsible" :options="k9_service_responsible_options" optionLabel="name" optionValue="id" @change="send_k9_service"/>
                                            </div>
                                            <div v-else>
                                                <div v-if="typeof k9_service_responsible === 'number'" class="row">
                                                    <Dropdown v-model="k9_service_responsible" :options="k9_service_responsible_options" optionLabel="name" optionValue="id" @change="send_k9_service"/>
                                                </div>
                                                <div v-else-if="typeof k9_service_responsible === 'object'" class="row">
                                                    <Dropdown v-model="k9_service_responsible.id" :options="k9_service_responsible_options" optionLabel="name" optionValue="id" @change="send_k9_service"/>
                                                 </div>
                                            </div>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown v-model="k9_service_responsible" :options="k9_service_responsible_options" optionLabel="name" optionValue="id" @change="send_k9_service"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Card>
                </div>
                <div v-if="get_current_user.profile.id_port.name == 'Port de Barcelona'" class="col-12 md:col-4">
                    <Card>
                        <template #title>
                            Custodian Service
                        </template>
                        <template #content>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Supplier </label>
                                        </div>
                                        <div v-if="custodian_service==true" class="row">
                                            <Dropdown v-model="custodian_service_supplier.id" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_custodian($event)"/>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown v-model="custodian_service_supplier" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_custodian($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Responsible </label>
                                        </div>
                                        <div v-if="custodian_service==true" class="row">
                                            <div v-if="custodian_service_responsible==''" class="row">
                                                <Dropdown v-model="custodian_service_responsible" :options="custodian_service_responsible_options" optionLabel="name" optionValue="id" @change="send_custodian_service"/>
                                            </div>
                                            <div v-else>
                                                <div v-if="typeof custodian_service_responsible === 'number'" class="row">
                                                    <Dropdown v-model="custodian_service_responsible" :options="custodian_service_responsible_options" optionLabel="name" optionValue="id" @change="send_custodian_service"/>
                                                </div>
                                                <div v-else-if="typeof custodian_service_responsible === 'object'" class="row">
                                                    <Dropdown v-model="custodian_service_responsible.id" :options="custodian_service_responsible_options" optionLabel="name" optionValue="id" @change="send_custodian_service"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown v-model="custodian_service_responsible" :options="custodian_service_responsible_options" optionLabel="name" optionValue="id" @change="send_custodian_service"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Card>
                </div>
                <!--
                <div v-if="get_current_user.profile.id_port.name == 'Port de Barcelona'" class="col-12 md:col-4">
                    <Card>
                        <template #title>
                            Fence Security Service
                        </template>
                        <template #content>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Supplier </label>
                                        </div>
                                        <div v-if="fence_security_service==true" class="row">
                                            <Dropdown v-model="fence_security_service_supplier.id" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_fence_security($event)"/>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown v-model="fence_security_service_supplier" :options="all_suppliers" optionLabel="name" optionValue="id" @change="refresh_supplier_responsibles_fence_security($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row pb-2">
                                            <label for="cleaning_service"> Responsible </label>
                                        </div>
                                        <div v-if="fence_security_service==true" class="row">
                                            <div v-if="fence_security_service_responsible==''" class="row">
                                                <Dropdown v-model="fence_security_service_responsible" :options="fence_security_service_responsible_options" optionLabel="name" optionValue="id" @change="send_fence_security_service"/>
                                            </div>
                                            <div v-else>
                                                <div v-if="typeof fence_security_service_responsible === 'number'" class="row">
                                                    <Dropdown v-model="fence_security_service_responsible" :options="fence_security_service_responsible_options" optionLabel="name" optionValue="id" @change="send_fence_security_service"/>
                                                </div>
                                                <div v-else-if="typeof fence_security_service_responsible === 'object'" class="row">
                                                    <Dropdown v-model="fence_security_service_responsible.id" :options="fence_security_service_responsible_options" optionLabel="name" optionValue="id" @change="send_fence_security_service"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="row">
                                            <Dropdown v-model="fence_security_service_responsible" :options="fence_security_service_responsible_options" optionLabel="name" optionValue="id" @change="send_fence_security_service"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Card>
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from 'primevue/dropdown'
import Card from 'primevue/card'
import Toast from 'primevue/toast'

export default {
    name: 'Supplier Assignment',
    components: {
        Dropdown,
        Card,
        Toast,
    },
    data() {
        return {
            loading: true,
            cleaning_service: false,
            cleaning_service_responsible_options: '',
            cleaning_service_id: '',
            cleaning_service_supplier: '',
            cleaning_service_responsible: '',
            porters_service: false,
            porters_service_responsible_options: '',
            porters_service_id: '',
            porters_service_supplier: '',
            porters_service_responsible: '',
            terminal_security_service: false,
            terminal_security_service_responsible_options: '',
            terminal_security_service_id: '',
            terminal_security_service_supplier: '',
            terminal_security_service_responsible: '',
            k9_service: false,
            k9_service_responsible_options: '',
            k9_service_id: '',
            k9_service_supplier: '',
            k9_service_responsible: '',
            custodian_service: false,
            custodian_service_responsible_options: '',
            custodian_service_id: '',
            custodian_service_supplier: '',
            custodian_service_responsible: '',
            fence_security_service: false,
            fence_security_service_responsible_options: '',
            fence_security_service_id: '',
            fence_security_service_supplier: '',
            fence_security_service_responsible: '',
            color: localStorage.getItem('primary_color')
        }
    },
    async mounted() {
        await this.$store.dispatch('load_all_suppliers')
        await this.$store.dispatch('load_port_supplier_service')

        this.cleaning_service_responsible_options = this.get_supplier_service_responsible
        this.porters_service_responsible_options = this.get_supplier_service_responsible
        this.terminal_security_service_responsible_options = this.get_supplier_service_responsible
        this.k9_service_responsible_options = this.get_supplier_service_responsible
        this.custodian_service_responsible_options = this.get_supplier_service_responsible
        this.fence_security_service_responsible_options = this.get_supplier_service_responsible

        
        for(var i in this.get_supplier_service) {
            if(this.get_supplier_service[i].service === 'Cleaning') {
                this.cleaning_service = true
                this.cleaning_service_id = this.get_supplier_service[i].id
                this.cleaning_service_supplier = this.get_supplier_service[i].id_supplier
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.cleaning_service_responsible = this.get_supplier_service[i].id_supplier_responsible
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.cleaning_service_responsible_options = this.get_supplier_service_responsible
            } else if (this.get_supplier_service[i].service === 'Porters') {
                this.porters_service = true
                this.porters_service_id = this.get_supplier_service[i].id
                this.porters_service_supplier = this.get_supplier_service[i].id_supplier
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.porters_service_responsible = this.get_supplier_service[i].id_supplier_responsible
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.porters_service_responsible_options = this.get_supplier_service_responsible
            } else if (this.get_supplier_service[i].service === 'Terminal security') {
                this.terminal_security_service = true
                this.terminal_security_service_id = this.get_supplier_service[i].id
                this.terminal_security_service_supplier = this.get_supplier_service[i].id_supplier
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.terminal_security_service_responsible = this.get_supplier_service[i].id_supplier_responsible
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.terminal_security_service_responsible_options = this.get_supplier_service_responsible
            }  else if (this.get_supplier_service[i].service === 'K9') {
                this.k9_service = true
                this.k9_service_id = this.get_supplier_service[i].id
                this.k9_service_supplier = this.get_supplier_service[i].id_supplier
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.k9_service_responsible = this.get_supplier_service[i].id_supplier_responsible
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.k9_service_responsible_options = this.get_supplier_service_responsible
            }  else if (this.get_supplier_service[i].service === 'Custodian security') {
                this.custodian_service = true
                this.custodian_service_id = this.get_supplier_service[i].id
                this.custodian_service_supplier = this.get_supplier_service[i].id_supplier
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.custodian_service_responsible = this.get_supplier_service[i].id_supplier_responsible
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.custodian_service_responsible_options = this.get_supplier_service_responsible
            }  else if (this.get_supplier_service[i].service === 'Fence security') {
                this.fence_security_service = true
                this.fence_security_service_id = this.get_supplier_service[i].id
                this.fence_security_service_supplier = this.get_supplier_service[i].id_supplier
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.fence_security_service_responsible = this.get_supplier_service[i].id_supplier_responsible
                await this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[i].id_supplier.id)
                this.fence_security_service_responsible_options = this.get_supplier_service_responsible
            }
        }

        this.loading = false
    },
    computed: {
        all_suppliers: function() {
            return this.$store.getters.all_suppliers
        },
        get_supplier_service: function() {
            return this.$store.getters.get_supplier_service
        },
        get_supplier_service_responsible: function() {
            return this.$store.getters.get_supplier_service_responsible
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        }
    },
    methods: {
        async refresh_supplier_responsibles_cleaning(id) {
            this.cleaning_service_responsible = ''
            await this.$store.dispatch('load_supplier_service_responsible', id.value)
            this.cleaning_service_responsible_options = this.get_supplier_service_responsible
        },
        async refresh_supplier_responsibles_porters(id) {
            this.porters_service_responsible = ''
            await this.$store.dispatch('load_supplier_service_responsible', id.value)
            this.porters_service_responsible_options = this.get_supplier_service_responsible
            
        },
        async refresh_supplier_responsibles_terminal_security(id) {
            this.terminal_security_service_responsible = ''
            await this.$store.dispatch('load_supplier_service_responsible', id.value)
            this.terminal_security_service_responsible_options = this.get_supplier_service_responsible
           
        },
        async refresh_supplier_responsibles_k9(id) {
            this.k9_service_responsible = ''
            await this.$store.dispatch('load_supplier_service_responsible', id.value)
            this.k9_service_responsible_options = this.get_supplier_service_responsible
            
        },
        async refresh_supplier_responsibles_custodian(id) {
            this.custodian_service_responsible = ''
            await this.$store.dispatch('load_supplier_service_responsible', id.value)
            this.custodian_service_responsible_options = this.get_supplier_service_responsible
            
        },
        async refresh_supplier_responsibles_fence_security(id) {
            this.fence_security_serviceity_service_responsible = ''
            await this.$store.dispatch('load_supplier_service_responsible', id.value)
            this.fence_security_service_responsible_options = this.get_supplier_service_responsible
            
        },
        async send_cleaning_service() {
            
            if(this.cleaning_service === false) {

                let item = {
                    service: 'Cleaning',
                    id: this.cleaning_service_id,
                    id_supplier: this.cleaning_service_supplier,
                    id_supplier_responsible: this.cleaning_service_responsible
                }

                await this.$store.dispatch('create_supplier_service', item)
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Assigned', life: 3000});

            } else {

                let item = {
                    service: 'Cleaning',
                    id: this.cleaning_service_id,
                    id_supplier: this.cleaning_service_supplier.id,
                    id_supplier_responsible: this.cleaning_service_responsible
                }
                
                if(!item.id_supplier_responsible){
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'Empty responsible', life: 3000});
                } else {
                    await this.$store.dispatch('update_supplier_service', item)
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Updated', life: 3000});
                }
            }

        },
        async send_porters_service() {

            if(this.porters_service === false) {
  
                let item = {
                    service: 'Porters',
                    id: this.porters_service_id,
                    id_supplier: this.porters_service_supplier,
                    id_supplier_responsible: this.porters_service_responsible
                }

                await this.$store.dispatch('create_supplier_service', item)
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Assigned', life: 3000});

            } else {

                let item = {
                    service: 'Porters',
                    id: this.porters_service_id,
                    id_supplier: this.porters_service_supplier.id,
                    id_supplier_responsible: this.porters_service_responsible
                }

                
                if(!item.id_supplier_responsible){
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'Empty responsible', life: 3000});
                } else {
                    await this.$store.dispatch('update_supplier_service', item)
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Updated', life: 3000});
                }
            }

        },
        async send_terminal_security_service() {

            if(this.terminal_security_service === false) {

                let item = {
                    service: 'Terminal security',
                    id: this.terminal_security_service_id,
                    id_supplier: this.terminal_security_service_supplier,
                    id_supplier_responsible: this.terminal_security_service_responsible
                }

                await this.$store.dispatch('create_supplier_service', item)
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Assigned', life: 3000});
            } else {

                let item = {
                    service: 'Terminal security',
                    id: this.terminal_security_service_id,
                    id_supplier: this.terminal_security_service_supplier.id,
                    id_supplier_responsible: this.terminal_security_service_responsible
                }

                if(!item.id_supplier_responsible){
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'Empty responsible', life: 3000});
                } else {
                    await this.$store.dispatch('update_supplier_service', item)
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Updated', life: 3000});
                }
                
            } 
        },
        async send_k9_service() {

            if(this.k9_service === false) {
                let item = {
                    service: 'K9',
                    id: this.k9_service_id,
                    id_supplier: this.k9_service_supplier,
                    id_supplier_responsible: this.k9_service_responsible
                }

                await this.$store.dispatch('create_supplier_service', item)
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Assigned', life: 3000});
            } else {

                let item = {
                    service: 'K9',
                    id: this.k9_service_id,
                    id_supplier: this.k9_service_supplier.id,
                    id_supplier_responsible: this.k9_service_responsible
                }

                if(!item.id_supplier_responsible){
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'Empty responsible', life: 3000});
                } else {
                    await this.$store.dispatch('update_supplier_service', item)
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Updated', life: 3000});
                }
                
            } 

        },
        async send_custodian_service() {

            if(this.custodian_service === false) {
                let item = {
                    service: 'Custodian security',
                    id: this.custodian_service_id,
                    id_supplier: this.custodian_service_supplier,
                    id_supplier_responsible: this.custodian_service_responsible
                }

                await this.$store.dispatch('create_supplier_service', item)
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Assigned', life: 3000});
            } else {

                let item = {
                    service: 'Custodian security',
                    id: this.custodian_service_id,
                    id_supplier: this.custodian_service_supplier.id,
                    id_supplier_responsible: this.custodian_service_responsible
                }

                if(!item.id_supplier_responsible){
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'Empty responsible', life: 3000});
                } else {
                    await this.$store.dispatch('update_supplier_service', item)
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Updated', life: 3000});
                }   
            }
        },
        async send_fence_security_service() {
            if(this.fence_security_service === false) {
                let item = {
                    service: 'Fence security',
                    id: this.fence_security_service_id,
                    id_supplier: this.fence_security_service_supplier,
                    id_supplier_responsible: this.fence_security_service_responsible
                }

                await this.$store.dispatch('create_supplier_service', item)
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Assigned', life: 3000});
            } else {

                let item = {
                    service: 'Fence security',
                    id: this.fence_security_service_id,
                    id_supplier: this.fence_security_service_supplier.id,
                    id_supplier_responsible: this.fence_security_service_responsible
                }

                if(!item.id_supplier_responsible){
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'Empty responsible', life: 3000});
                } else {
                    await this.$store.dispatch('update_supplier_service', item)
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Supplier Updated', life: 3000});
                }   
            }
        }

    }
}
</script>

<style scoped>
/*
:deep(.p-dropdown){
    width: 19rem;
}
*/


.card {
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.p-divider.p-divider-horizontal {
  color: v-bind(color);
}

</style>