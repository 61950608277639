<template>
    <ModalShipResponsibles/>
    <TableShipResponsibles/>
</template>

<script>
import TableShipResponsibles from '@/components/AdminDashboard/Ship_responsibles/TableShip_responsibles.vue/'
import ModalShipResponsibles from '@/components/AdminDashboard/Ship_responsibles/ModalShip_responsibles.vue/'
export default {
    name: 'Ship_responsibles',
    components: {
        TableShipResponsibles,
        ModalShipResponsibles
    }
}
</script>

<style scoped>

</style>