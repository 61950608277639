<template>
    <Dialog header="Edit profile picture" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
        <form id="form_profile_picture" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-10 md:col-12 pt-4 text-center">
                        <div class="circle-image">
                            <span v-if="typeof(u.profile.picture) === 'object'">
                                <img :src="u.profile.picture.objectURL" class="profile-pic"/>
                            </span>
                            <span v-else>
                                <img :src="u.profile.picture" class="profile-pic"/>
                            </span>
                        </div>
                        <div class="field pt-3">
                            <div class="p-float-label">
                                <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUpload" />
                            </div>
                            <!--<small v-if="Object.keys(port_errors).length !== 0 && port_errors.logo" class="p-error"><span v-for="error in port_errors.logo" :key="error">{{error}}<br></span></small>-->
                        </div>
                    </div>
                </div> 
            </div>
        </form>
        <template #footer>
            <Button form="form_profile_picture" type="submit" label="Submit" class="p-button-raised"/>
        </template>
    </Dialog>
    <Dialog header="Edit signature picture" v-model:visible="displayModalSignature" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '70vw'}">
        <div class="p-fluid">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-10 md:col-12 pt-4 text-center">
                        <div v-if="u.profile.signature">
                            <span v-if="typeof(u.profile.signature) === 'object'">
                                <img :src="u.profile.signature.objectURL" style="max-width:600px;" class="profile-pic"/>
                            </span>
                            <span v-else>
                                <img :src="u.profile.signature" style="max-width:600px;" class="profile-pic"/>
                            </span>
                        </div>
                        <div v-else>
                            <span> No signature choosen yet.</span>
                        </div>
                        <div class="field pt-3">
                            <div class="p-float-label pt-3">
                                <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUploadSignature" />
                            </div>
                            <!--<small v-if="Object.keys(port_errors).length !== 0 && port_errors.logo" class="p-error"><span v-for="error in port_errors.logo" :key="error">{{error}}<br></span></small>-->
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <template #footer>
            <Button label="Submit" class="p-button-raised" @click="update_signature_profile()"/>
        </template>
    </Dialog>
    <Dialog header="Change password" v-model:visible="displayModalPassword" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '55vw'}">
        <form id="form_password" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="container">
                <div class="row justify-content-center mt-4">
                    <div class="col-10 pt-1">
                        <span class="p-float-label">
                            <Password v-model="current_password" aria-labelledby="current_password" toggleMask :class="{'p-invalid':v$.current_password.$invalid && submitted}" :feedback="false"/>
                            <label for="current_password">Current Password</label> 
                        </span>
                        <small v-if="Object.keys(errors_passwords).length !== 0 && errors_passwords.errors.current_password" class="p-error"><span v-for="error in errors_passwords.errors" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.current_password.$invalid && submitted) || v$.current_password.$pending.$response" class="p-error">{{v$.current_password.required.$message.replace('Value', 'Current password')}}</small>
                    </div>
                    <div class="col-10 mt-3">
                        <span class="p-float-label">
                            <Password v-model="new_password" aria-labelledby="new_password" toggleMask :class="{'p-invalid':v$.new_password.$invalid && submitted}">
                                <template #header>
                                    <h6>Pick a password</h6>
                                </template>
                                <template #footer>
                                    <Divider />
                                    <p class="mt-2">Requirements</p>
                                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                        <li>At least one lowercase</li>
                                        <li>At least one uppercase</li>
                                        <li>At least one numeric</li>
                                        <li>Minimum 8 characters</li>
                                    </ul>
                                </template>
                            </Password>
                            <label for="new_password">New Password</label>
                        </span>
                        <small v-if="Object.keys(errors_passwords).length !== 0 && errors_passwords.errors.new_password" class="p-error"><span v-for="error in errors_passwords.errors" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.new_password.$invalid && submitted) || v$.new_password.$pending.$response" class="p-error">{{v$.new_password.required.$message.replace('Value', 'New password')}}</small>
                    </div>
                    <div class="col-10 mt-3">
                        <span class="p-float-label">
                            <Password v-model="confirm_new_password" aria-labelledby="confirm_new_password" toggleMask :feedback="false" :class="{'p-invalid':v$.confirm_new_password.$invalid && submitted}"/>
                            <label for="confirm_new_password">Confirm new Password</label> 
                        </span>
                        <small v-if="Object.keys(errors_passwords).length !== 0 && errors_passwords.errors.confirm_new_password" class="p-error"><span v-for="error in errors_passwords.errors" :key="error">{{error}}<br></span></small>
                        <small v-if="(v$.confirm_new_password.$invalid && submitted) || v$.confirm_new_password.$pending.$response" class="p-error">{{v$.confirm_new_password.required.$message.replace('Value', 'Confirm new password')}}</small>
                    </div>
                </div> 
            </div>
        </form>
        <template #footer>
            <Button form="form_password" type="submit" label="Submit" class="p-button-raised"/>
        </template>
    </Dialog>
    <div class="p-fluid"> 
        <div class="row justify-content-around">
            <div class="col-12 md:col-5 lg:col-4 pt-3 text-center card">
                <div class="pb-2">
                    <h4>{{ u.username }}</h4>
                    <h6>{{ u.email }}</h6>
                </div>
                <div class="row justify-content-center">
                    <div class="circle-image">
                        <span v-if="typeof(u.profile.picture) === 'object'">
                            <img :src="u.profile.picture.objectURL" class="profile-pic"/>
                        </span>
                        <span v-else>
                            <img :src="u.profile.picture" class="profile-pic"/>
                        </span>
                        <div class="overlay">
                            <FileUpload chooseIcon="pi pi-pencil" chooseLabel="Edit" ref="fileUpload" class="text fileupload_custom" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000000" @uploader="onUpload" />
                            <!--<div class="text"><i class="fa-solid fa-pen-to-square pe-1"></i>Edit</div>-->
                        </div>
                    </div>
                </div>
                <div v-if="update_picture" class="row justify-content-center pt-2">
                    <div class="col-3">
                        <Button label="Save" class="p-button-raised" @click="save_update_picture()"/>
                    </div>
                </div>
                <div class="col pt-2">
                    <div class="offset-md-2 col-12 md:col-8">
                        <Button label="Change Password" icon="fa-solid fa-lock" class="p-button-outlined p-button-raised" @click="openModalPassword" />
                    </div>
                </div>
                <div class="col">
                    <div class="offset-md-2 col-12 md:col-8">
                        <Button label="Change Signature" icon="fa-solid fa-signature" class="p-button-outlined p-button-raised" @click="openModalSignature" />
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 h-100 pt-2 card">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h2> Profile </h2>
                        </div>
                    </div> 
                    <div class="row pt-3">
                        <div class="col-12">
                            <div class="p-float-label">
                                <InputText type="text" id="first_name" v-model="u.first_name" class="p-column-filter" @input="check()"/>
                                <label for="first_name" > First name </label>
                                <small v-if="Object.keys(get_errors_change_user).length !== 0 && get_errors_change_user.first_name" class="p-error"><span v-for="error in get_errors_change_user.first_name" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                        <div class="col-12 pt-4">
                            <div class="p-float-label">
                                <InputText type="text" id="last_name" v-model="u.last_name" class="p-column-filter" @input="check()"/>
                                <label for="last_name" > Last name </label>
                                <small v-if="Object.keys(get_errors_change_user).length !== 0 && get_errors_change_user.last_name" class="p-error"><span v-for="error in get_errors_change_user.last_name" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-12 pt-4">
                            <div class="p-float-label">
                                <InputText type="text" id="username" v-model="u.username" class="p-column-filter" @input="check()" disabled/>
                                <label for="username" > Username </label>
                                <small v-if="Object.keys(get_errors_change_user).length !== 0 && get_errors_change_user.username" class="p-error"><span v-for="error in get_errors_change_user.username" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 pt-4 ">
                            <div class="p-float-label">
                                <InputText type="text" id="email" v-model="u.email" class="p-column-filter" @input="check()" disabled/>
                                <label for="email" > Email </label>
                                <small v-if="Object.keys(get_errors_change_user).length !== 0 && get_errors_change_user.email" class="p-error"><span v-for="error in get_errors_change_user.email" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div> -->
                    <div class="row pt-2 pb-2">
                        <div class="col">
                            <Button label="Save" class="p-button-raised" :disabled="!update_profile" @click="save_update_profile()"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import Dropdown from "primevue/dropdown";
import Password from 'primevue/password';
import Divider from 'primevue/divider';
import InputText from "primevue/inputtext";
//import InputSwitch from 'primevue/inputswitch'
import FileUpload from 'primevue/fileupload';
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import axios from 'axios'

export default {
    name:"Edit_profile",
    setup: () => ({ v$: useVuelidate() }),
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    components: {
        //Dropdown,
        InputText,
        //InputSwitch,
        FileUpload,
        Dialog,
        Button,
        Password,
        Divider
    },
    data(){
        return {
            displayModal: false,
            displayModalSignature:false,
            displayModalPassword:false,
            current_password:'',
            new_password:'',
            confirm_new_password:'',
            picture:'',
            signature:'',
            u: this.user,
            checked: this.user.profile.mail_notifications,
            submitted: false,
            errors_passwords: [],
            static_name: '',
            update_profile: false,
            previous_first_name: '',
            previous_last_name: '',
            previous_username: '',
            previous_email: '',
            update_picture: false,
        }
    },
    validations() {
        return {
            current_password: { required },
            new_password: { required },
            confirm_new_password: { required },
        };
    },
    computed: {
        get_errors_change_user: function() {
            return this.$store.getters.get_errors_change_user
        }
    },
    mounted() {
        if(this.u.first_name && this.u.last_name) {
            this.static_name = this.u.first_name + ' ' + this.u.last_name
        } else {
            this.static_name = this.u.username
        }

        this.previous_first_name = this.u.first_name
        this.previous_last_name = this.u.last_name
        this.previous_username = this.u.username
        this.previous_email = this.u.email           
    },
    methods:{
        async update_signature_profile() {
            await this.$store.dispatch('change_user_signature', this.u)
            this.$toast.add({severity:'success', summary: 'Success', detail:'Updated Successfully', life: 3000});
            this.displayModalSignature = false
        },
         async save_update_picture(){
            await this.$store.dispatch('change_user_picture', this.u)
            this.$toast.add({severity:'success', summary: 'Success', detail:'Updated Successfully', life: 3000});
            this.update_picture = false
        },
        async save_update_profile() {
            await this.$store.dispatch('change_user_profile', this.u)
            if(this.u.first_name && this.u.last_name) {
                this.static_name = this.u.first_name + ' ' + this.u.last_name
            } else {
                this.static_name = this.u.username
            }
            this.previous_first_name = this.u.first_name
            this.previous_last_name = this.u.last_name
            this.previous_username = this.u.username
            this.previous_email = this.u.email 
            this.update_profile = false  
            this.$toast.add({severity:'success', summary: 'Success', detail:'Updated Successfully', life: 3000});
        },
        check() {
            if (this.u.first_name !=  this.previous_first_name || this.u.last_name !=  this.previous_last_name || this.u.username !=  this.previous_username || this.u.email !=  this.previous_email){
                this.update_profile = true
            } else {
                this.update_profile = false
            }  
        },
        onUpload(e){
            this.u.profile.picture = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
            this.update_picture = true
        },
        onUploadSignature(e){
            this.u.profile.signature = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        openModalSignature() {
            this.displayModalSignature = true;
        },
        closeModalSignature() {
            this.displayModalSignature = false;
        },
        openModalPassword() {
            this.displayModalPassword = true;
            this.reset_password_form()
        },
        closeModalPassword() {
            this.displayModalPassword = false;
        },
        async handleSubmit(isFormValid){
            this.submitted = true;

            this.errors_passwords = [] 

            if (!isFormValid) {
                return;
            }

            let res = await this.change_password();

            if (res) {
                this.$toast.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Password updated.",
                    life: 3000,
                });

                this.displayModalPassword = false
                this.reset_password_form()
            }
        },
        async change_password(){
            let password = {}

            password= {
                current_password: this.current_password,
                new_password: this.new_password,
                confirm_new_password: this.confirm_new_password
            }
            
            await axios.put(process.env.VUE_APP_API_URL + "users/change_password/", password)
                .catch((error) => {
                    this.errors_passwords = error.response.data
                })

            if(this.errors_passwords != '') {
                return false
            } else {
                return true
            }
        },
        async reset_password_form(){
            this.current_password = ''
            this.new_password = ''
            this.confirm_new_password = ''
            this.submitted = false;
        }
    }
}
</script>

<style scoped>
.circle-image{
    cursor:pointer;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: 250px;
    height: 250px;
    padding: 0;
}

.circle-image img{
    width:100%;
    height:100%;
    object-fit: cover;
}

.image {
    display: block;
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 75%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25%;
    width: 100%;
    opacity: 0.1;
    transition: .5s ease;
    background-color: #373737;
}

.circle-image:hover .overlay {
    opacity: 0.6;
}

.text {
    color: white;
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
:deep(.overlay> div > span){
    background: #373737!important;
    border:none!important;
    width: 100% !important;

}
:deep(.overlay> div > span:focus-visible){
    border:none!important;

}
:deep(.overlay> div > span > .p-button-icon){
    display: none !important;
}

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

:deep(.p-input-icon-right>svg){
    right:0.75rem;
}

</style>