import axios from 'axios'
import store from '../../store/index'

import moment from 'moment'

const state = {
    port_calls: [],
    port_calls_calendar: [],
    port_calls_signatures: [],
    port_call_errors: {},
    filtered_calls: [],
    port_calls_calendar_filtered: [],
    delete_status:false,
    service_sheets: [],
    import_errors:[],
    create_service_sheet_error: [],
    ship_agents: [],
    ship_responsible: [],
    pdf_versions:[],
    error_password_pdf: []
}

const mutations = {
    SET_PDF_VERSIONS(state, pdf_versions) {
		state.pdf_versions = pdf_versions
	},
    DELETE_CALLS_PENDING(state, id) {
        let index = state.port_calls_signatures.findIndex(port_calls_signatures => port_calls_signatures.id == id)
        state.port_calls_signatures.splice(index, 1)
    },
    SET_PORT_CALLS(state, calls) {
        state.port_calls = calls
        
        //state.port_calls_calendar.splice(0, this.length)
        while(state.port_calls_calendar.length > 0) {
            state.port_calls_calendar.pop();
        }

        calls.forEach(element => {
            var dict_items = {}
            dict_items['title'] = element.id_ship.name
            dict_items['start'] = element.arrival_datetime
            dict_items['end'] = element.departure_datetime
            dict_items['cruise_line'] = element.id_ship.id_cruise_line
            dict_items['id'] = element.id
            dict_items['ship_id'] = element.id_ship.id
            dict_items['foresight'] = element.foresight
            if(dict_items['foresight'] === true) {
                dict_items['color'] = 'gray'
            }
            if(dict_items['is_new'] === true) {
                dict_items['color'] = 'yellow'
            }
            state.port_calls_calendar.push(dict_items)
        });

        
    },
    SET_FILTERED_CALLS(state, filtered_calls) {
        state.filtered_calls = filtered_calls
        //state.port_calls_calendar.splice(0, this.length)
        while(state.port_calls_calendar_filtered.length > 0) {
            state.port_calls_calendar_filtered.pop();
        }


        filtered_calls.forEach(element => {
            var dict_items = {}   

            if(element.terminal) {
                for(var index in store.state.users.current_user.groups) {
                    if(store.state.users.current_user.groups[index].name == 'Accounting Manager' || store.state.users.current_user.groups[index].name == 'Admins' || store.state.users.current_user.groups[index].name == 'Accounting') {
                        dict_items['title'] = element.terminal + ' - ' + element.number + ' - ' + element.id_ship.name
                        break;
                    }
                }
                if(!('title' in dict_items)){
                    dict_items['title'] = element.terminal + ' - ' + element.id_ship.name;
                }
                //dict_items['title'] = element.terminal + ' - ' + element.id_ship.name
            } else {
                for(var item in store.state.users.current_user.groups) {
                    if(store.state.users.current_user.groups[item].name == 'Accounting Manager' || store.state.users.current_user.groups[item].name == 'Admins' || store.state.users.current_user.groups[item].name == 'Accounting') {
                        dict_items['title'] = element.number + ' - ' + element.id_ship.name
                        break;
                    } 
                }
                if(!('title' in dict_items)){
                    dict_items['title'] = element.id_ship.name;
                }
                //dict_items['title'] = element.id_ship.name
            }
            
            dict_items['start'] = element.arrival_datetime
            dict_items['end'] = element.departure_datetime
            dict_items['cruise_line'] = element.id_ship.id_cruise_line
            dict_items['id'] = element.id
            dict_items['ship_id'] = element.id_ship.id
            dict_items['foresight'] = element.foresight
            dict_items['terminal'] = element.terminal
            dict_items['is_unconventional'] = element.is_unconventional
            dict_items['validated'] = element.validated
            
            if(dict_items['foresight'] === true) {
                dict_items['color'] = '#c7c7c7'
                dict_items['borderColor'] = 'white'
            }

            if(dict_items['is_unconventional'] && dict_items['validated']) {
                dict_items['color'] = '#2181b5'
            } else {
                if(dict_items['is_unconventional'] === true) {
                    dict_items['color'] = '#9dc4f5'
                }
    
                if(dict_items['validated'] === true) {
                    dict_items['color'] = '#0eb583'
                }
            }

            



            if(element.is_new === true) {
                dict_items['title'] = dict_items['title'] + ' **'
            }
            
            state.port_calls_calendar_filtered.push(dict_items)
        });
    },
    SET_SERVICE_SHEET_BY_CALL(state, service_sheet) {
        state.service_sheets = service_sheet
    },
    SET_CALLS_SIGNATURE(state, calls) {
        state.port_calls_signatures = calls
    },
    SET_SHIP_AGENTS(state, ship_agents) {
        state.ship_agents = ship_agents
    },
    EMPTY_SHIP_AGENTS(state) {
        state.ship_agents = []
    },
    SET_SHIP_RESPONSIBLES(state, ship_responsible) {
        state.ship_responsible = ship_responsible
    },
    EMPTY_SHIP_RESPONSIBLES(state) {
        state.ship_responsible = []
    },
    NEW_OPERATIVE(state, operative) {  
        state.port_calls.push(operative)
    },
    NEW_SERVICE_SHEET(state, service_sheet) {  
        state.create_service_sheet_error = []
        state.port_calls.push(service_sheet)
        
    },
    NEW_PORT_CALL(state, port_call) {  
        state.port_calls.push(port_call)
        
        var dict_items = {}
        dict_items['start'] = port_call.arrival_datetime
        dict_items['end'] = port_call.departure_datetime
        dict_items['title'] = port_call.id_ship.name
        dict_items['cruise_line'] = port_call.id_ship.id_cruise_line
        dict_items['id'] = port_call.id
        dict_items['ship_id'] = port_call.id_ship.id
        dict_items['foresight'] = port_call.foresight
        dict_items['is_unconventional'] = port_call.is_unconventional
        
        state.port_calls_calendar.push(dict_items)

        state.port_call_errors = {}
    },
    DELETE_PORT_CALL(state, id) {
        let index = state.port_calls.findIndex(port_calls => port_calls.id == id)
        state.port_calls.splice(index, 1)
        let index_c = state.port_calls_calendar.findIndex(port_calls_calendar => port_calls_calendar.id == id)
        state.port_calls_calendar.splice(index_c, 1)
        state.delete_status=false
    },
    DELETE_SERVICE_SHEET(state, id) {
        let index = state.service_sheets.findIndex(service_sheets => service_sheets.id == id)
        state.service_sheets.splice(index, 1)
    },
    UPDATE_PORT_CALL(state, port_call) {
        let modified_call = state.update_response
        state.port_calls = state.port_calls.map(updated_port_call => {
            if(updated_port_call.id === port_call.id) {
                updated_port_call.name = port_call.name
                updated_port_call.ship_agent = port_call.ship_agent
                updated_port_call.arrival_datetime = modified_call.arrival_datetime
                updated_port_call.departure_datetime = modified_call.departure_datetime
                updated_port_call.id_ship = port_call.id_ship
                updated_port_call.ship_responsible = port_call.ship_responsible
                updated_port_call.phone = port_call.phone
                updated_port_call.fax = port_call.fax
                updated_port_call.id_port = port_call.id_port
            }
            return updated_port_call
        })
        state.call_errors = {}
    },
    ERRORS_PORT_CALL(state, errors) {
        state.port_call_errors = errors
    },
    ERRORS_IMPORT_CALLS(state,error) {
        state.import_errors.push(error)
    },
    SET_CREATE_SERVICE_SHEET_ERROR(state, error) {
        state.create_service_sheet_error = []
        state.create_service_sheet_error.push(error)
    },
    PATCH_NUMBER_CALL(state, data){
        console.log(state, data)
    },
    SET_ERROR_PASSWORD_PDF(state, error_password_pdf) {
        state.error_password_pdf = error_password_pdf
    },
    CLEAN_PDF_ERRORS(state) {
        state.error_password_pdf = []
    }
}

const actions = {
    async load_port_calls({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "calls/")
            .then((response) => {
                commit('SET_PORT_CALLS', response.data)
            })
    },
    async load_filtered_calls({commit}, date) {
        await axios.get(process.env.VUE_APP_API_URL + 'calls/get_calls_by_month/?month=' + date.month+ '&year=' + date.year)
            .then((response) => {
                commit('SET_FILTERED_CALLS', response.data)
            })
    },
    async load_service_sheets_by_call({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "service_sheets/get_by_call/" + id + '/')
            .then((response) => {
                commit('SET_SERVICE_SHEET_BY_CALL', response.data)
            })
    },
    async load_signature_calls({commit}, status) {
        await axios.get(process.env.VUE_APP_API_URL + 'calls/get_calls_by_status/' + status +'/')
            .then((response) => {
                commit('SET_CALLS_SIGNATURE', response.data)
            })
    },
    async load_ship_agents({commit}, id){
        if(typeof(id) === 'object') {
            id = id.id
        }
        await axios.get(process.env.VUE_APP_API_URL + 'ship_agents/get_by_cruise_line/' + id + '/')
            .then((response) => {
                commit('SET_SHIP_AGENTS', response.data)
            })
    },
    async empty_ship_agents({commit}){
        commit('EMPTY_SHIP_AGENTS')
    },
    async load_ship_agent_by_responsible({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'ship_agents/get_by_ship_responsible/' + id + '/')
            .then((response) => {
                commit('SET_SHIP_AGENTS', response.data)
            })
    },
    async load_ship_responsibles({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + 'ship_responsible/get_by_ship_agent/' + id + '/')
            .then((response) => {
                commit('SET_SHIP_RESPONSIBLES', response.data)
            })
    },
    async empty_ship_responsibles({commit}) {
        commit('EMPTY_SHIP_RESPONSIBLES')
    },
    async create_operative({commit}, operatives) {
        state.import_errors = []
        const func_create = async () => {
            var first_call = operatives[0]
            for(const operative of operatives){
                if (operative.arrival_datetime < first_call.arrival_datetime) {
                    first_call = operative;
                }
            }
            var date_operative = first_call.arrival_datetime
            await axios.post(process.env.VUE_APP_API_URL  + "operative/delete_calls/", {date_operative})
            for(const operative of operatives){
                await axios.post(process.env.VUE_APP_API_URL  + "operative/", operative)
                .then((response) => {
                    commit('NEW_OPERATIVE', response.data)
                    if (operative.arrival_datetime < first_call.arrival_datetime) {
                        first_call = operative;
                    }
                    
                }).catch(() => {
                    commit('ERRORS_IMPORT_CALLS', operative)
                })
            }
            if(first_call != null){
                await axios.post(process.env.VUE_APP_API_URL  + "operative/renumber_operative_calls/", first_call)
            }
            
            return true

        }
        return await func_create();
    },
    async create_port_call({commit}, port_call) {
        if(typeof port_call.arrival_datetime_hour === 'string') {
            port_call.arrival_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + port_call.arrival_datetime_hour))
        }
        var start_datetime = new Date(new Date(port_call.arrival_datetime).getFullYear(), new Date(port_call.arrival_datetime).getMonth(), new Date(port_call.arrival_datetime).getDate(),
                            new Date(port_call.arrival_datetime_hour).getHours(), new Date(port_call.arrival_datetime_hour).getMinutes(), new Date(port_call.arrival_datetime_hour).getSeconds())
        if(typeof port_call.departure_datetime_hour === 'string') {
            port_call.departure_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + port_call.departure_datetime_hour))
        }
        var end_datetime = new Date(new Date(port_call.departure_datetime).getFullYear(), new Date(port_call.departure_datetime).getMonth(), new Date(port_call.departure_datetime).getDate(),
                            new Date(port_call.departure_datetime_hour).getHours(), new Date(port_call.departure_datetime_hour).getMinutes(), new Date(port_call.departure_datetime_hour).getSeconds())
        
        start_datetime = moment(start_datetime).format().split('+')[0]  
        end_datetime = moment(end_datetime).format().split('+')[0]  
        
        await axios.post(process.env.VUE_APP_API_URL  + 'calls/', { ship_agent:port_call.ship_agent, arrival_datetime:start_datetime, departure_datetime: end_datetime, id_ship:port_call.id_ship.id, id_ship_responsible:port_call.ship_responsible, phone: port_call.phone, fax: port_call.fax, foresight: port_call.foresight, is_unconventional: port_call.is_unconventional })
            .then((response) => {
                commit('NEW_PORT_CALL', response.data)
            })
            .catch((error) => {
                commit('ERRORS_PORT_CALL', error.response.data)
            })
    },
    async create_service_sheet({commit}, service_sheet) {
        await axios.post(process.env.VUE_APP_API_URL  + 'service_sheets/', {estimated_arrival_datetime: moment(service_sheet.estimated_arrival_datetime).format().split('+')[0], estimated_departure_datetime: moment(service_sheet.estimated_departure_datetime).format().split('+')[0], id_terminal: service_sheet.terminal.id, id_call: service_sheet.id_call })
            .then((response) => {
                commit('NEW_SERVICE_SHEET', response.data)
            })
            .catch((error) => {
                commit('SET_CREATE_SERVICE_SHEET_ERROR', error.response.data)
            })
    },
    async delete_port_call({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'calls/' + id)
            .then(() => {
                commit('DELETE_PORT_CALL', id);
            })
            .catch(() => {
                state.delete_status = true
            })
    }, 
    async delete_service_sheet({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'service_sheets/' + id + '/')
            .then(() => {
                commit('DELETE_SERVICE_SHEET', id)
            })
    },
    async update_port_call({commit}, port_call){
        await axios.patch(process.env.VUE_APP_API_URL  + 'calls/' + port_call.id + '/',  { ship_agent:port_call.ship_agent, arrival_datetime:moment(port_call.arrival_datetime_full).format().split('+')[0], departure_datetime:moment(port_call.departure_datetime_full).format().split('+')[0], id_ship:port_call.id_ship.id, ship_responsible:port_call.ship_responsible, phone:port_call.phone, fax: port_call.fax, id_port:port_call.id_port.id })
            .then((response) => {
                state.update_response = response.data
                commit('UPDATE_PORT_CALL', port_call)
            })
            .catch((error) => {
                commit('ERRORS_PORT_CALL', error.response)
            })
    },
    async send_pdf({commit}, pdf) {
		var formData = new FormData();
        formData.append('location', pdf.location)
        formData.append('password', pdf.password)
		formData.append('id_service_sheet',pdf.id_service_sheet);
		formData.append('pdf', pdf.pdf, 'ss_'+pdf.number+'.pdf');
        if(pdf.sign_comment != ''){
            formData.append('sign_comment', pdf.sign_comment);
        }
		let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }

		
		await axios.post(process.env.VUE_APP_API_URL + 'service_sheet_pdf/', formData, {headers})
			.then((response) => {
				commit('DELETE_CALLS_PENDING', pdf.id)
				window.open(response.data.pdf, 'windowOpenTab', 'scrollbars=1,resizable=1,width=1000,height=580,left=0,top=0')
			})
            .catch((error) => {
                commit('SET_ERROR_PASSWORD_PDF', error.response.data)
            })

	},
    async clean_pdf_errors({commit}) {
        commit('CLEAN_PDF_ERRORS')
    },
	async get_pdf_versions({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'service_sheet_pdf/get_by_ss/' + id)
			.then((response) => {
				commit('SET_PDF_VERSIONS', response.data)
			})
	}
}

const getters = {
    all_port_calls: (state) => state.port_calls,
    all_port_calls_calendar: (state) => state.port_calls_calendar,
    all_port_calls_calendar_filtered: (state) => state.port_calls_calendar_filtered,
    all_port_calls_signatures: (state) => state.port_calls_signatures,
    get_port_calls_by_ship: (state) => (title) => {
        return state.port_calls_calendar.filter(port_call_calendar => port_call_calendar.title === title)
    },
    get_port_calls_by_cruise_line: (state) => (id_cruise_line) => {
        return state.port_calls_calendar.filter(port_call_calendar => port_call_calendar.cruise_line === id_cruise_line)
    },
    get_port_calls_filtered: (state) => (title, id_cruise_line) => {
        return state.port_calls_calendar.filter(port_call_calendar => port_call_calendar.cruise_line === id_cruise_line && port_call_calendar.title === title)
    },
    get_port_calls_semi_filtered: (state) => (title, id_cruise_line) => {
        return state.port_calls_calendar.filter(port_call_calendar => port_call_calendar.cruise_line === id_cruise_line || port_call_calendar.title === title)
    },
    get_service_sheets: (state) => state.service_sheets,
    get_import_errors: (state) => state.import_errors,
    get_create_service_sheet_errors: (state) => state.create_service_sheet_error,
    filtered_calls: (state) => state.filtered_calls,
    get_ship_agents: (state) => state.ship_agents,
    get_ship_responsible: (state) => state.ship_responsible,
    get_pdf_versions: (state) => state.pdf_versions,
    all_port_calls_calendar_filtered_by_terminal: (state) => (name) => {
        if(name == 'All'){
            return state.port_calls_calendar_filtered
        } else {
            return state.port_calls_calendar_filtered.filter(port_call => port_call.terminal === name)
        }
    },
    get_port_call_errors: (state) => state.port_call_errors,
    get_error_password_pdf: (state) => state.error_password_pdf
}

export default {
    state,
    mutations,
    actions,
    getters
};