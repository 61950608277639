import axios from 'axios'
import moment from 'moment';

const state = {
    service_sheets: [],
    service_sheet: [],
    placeholders: [],
    main_service_sheet: [],
    service_sheet_date_error: {},
    service_sheet_general_error: {},
    call_general_error: {},
    service_sheet_estimation_error: {},
    service_sheet_final_error: {},
    service_sheet_fingers_error: {},
    min_date: '',
    max_date: '',
    actual_date: ''
};

const mutations = {
    SET_SERVICE_SHEETS(state, service_sheets) {
        state.service_sheets = service_sheets
    },
    SET_SERVICE_SHEET(state, service_sheet) {
        if( service_sheet.terminal_opening_time != null) {
            service_sheet.terminal_opening_time = service_sheet.terminal_opening_time.split(':')[0] + ':' +  service_sheet.terminal_opening_time.split(':')[1]
        }
        if(service_sheet.terminal_closing_time != null) {
            service_sheet.terminal_closing_time = service_sheet.terminal_closing_time.split(':')[0] + ':' +  service_sheet.terminal_closing_time.split(':')[1]
        }
        
        state.service_sheet =  service_sheet
    },
    SET_PLACEHOLDERS_SERVICE_SHEET(state, placeholders) {
        state.placeholders = placeholders
    },
    SET_MAIN_SERVICE_SHEET(state, service_sheet) {
        state.main_service_sheet = service_sheet
    },
    PATCH_MAIN_SERVICE_SHEET(state, service_sheet) {
        state.main_service_sheet =  Object.keys(state.service_sheet).map(updated_service_sheet => {
            if(updated_service_sheet.id === service_sheet.id) {
                updated_service_sheet.main = service_sheet.main
            }
            return updated_service_sheet
        })
        state.service_sheet_date_error = {}
    },
    PATCH_SERVICE_SHEET_DATE(state, service_sheet) {
        state.service_sheet =  Object.keys(state.service_sheet).map(updated_service_sheet => {
            if(updated_service_sheet.id === service_sheet.id) {
                updated_service_sheet.estimated_arrival_datetime = service_sheet.estimated_arrival_datetime
                updated_service_sheet.estimated_departure_datetime = service_sheet.estimated_departure_datetime
                updated_service_sheet.confirmed = service_sheet.confirmed
            }
            
            return updated_service_sheet
        })
        state.service_sheet_date_error = {}
    },
    ERRORS_SERVICE_SHEET_DATE(state, errors){
        state.service_sheet_date_error = errors
    },
    PATCH_SERVICE_SHEET_GENERAL(state, service_sheet) {
        state.service_sheet.pier = service_sheet.pier
        state.service_sheet.id_terminal = service_sheet.id_terminal
        state.service_sheet.note = service_sheet.note
        state.service_sheet.confirmed = service_sheet.confirmed
        state.service_sheet.terminal_opening_time = service_sheet.terminal_opening_time
        state.service_sheet.terminal_closing_time = service_sheet.terminal_closing_time

        state.service_sheet_general_error = {}
        state.call_general_error = {}
    },
    PATCH_CALL_GENERAL(state, service_sheet){
        state.service_sheet.id_call.id_ship.id = service_sheet.id_ship.id
        if(service_sheet.id_ship_responsible) {
            state.service_sheet.id_call.id_ship_responsible = service_sheet.id_ship_responsible.id
        }
        state.service_sheet.id_call.is_unconventional = service_sheet.is_unconventional
    },
    ERRORS_SERVICE_SHEET_GENERAL(state, errors){
        state.service_sheet_general_error = errors
    },
    ERRORS_CALL_GENERAL(state, errors){
        state.call_general_error = errors
    },
    PATCH_SERVICE_SHEET_ESTIMATION(state, service_sheet) {
        if(service_sheet.estimated_arrival_datetime != null) {
            state.service_sheet.estimated_arrival_datetime = moment(service_sheet.estimated_arrival_datetime).format('yy-MM-DD')
            state.service_sheet.estimated_arrival_datetime_hour = moment(service_sheet.estimated_arrival_datetime).format('HH:mm')
        } else {
            state.service_sheet.estimated_arrival_datetime = ''
            state.service_sheet.estimated_arrival_datetime_hour= ''
        }

        if(service_sheet.estimated_departure_datetime != null){
            state.service_sheet.estimated_departure_datetime = moment(service_sheet.estimated_departure_datetime).format('yy-MM-DD')
            state.service_sheet.estimated_departure_datetime_hour = moment(service_sheet.estimated_departure_datetime).format('HH:mm')
        } else {
            state.service_sheet.estimated_departure_datetime = ''
            state.service_sheet.estimated_departure_datetime_hour = ''
        }

        state.service_sheet.e_p_disembarking = service_sheet.e_p_disembarking
        state.service_sheet.e_p_embarking = service_sheet.e_p_embarking
        state.service_sheet.e_p_transit = service_sheet.e_p_transit
        state.service_sheet.e_c_disembarking = service_sheet.e_c_disembarking
        state.service_sheet.e_c_embarking = service_sheet.e_c_embarking
        state.service_sheet.e_c_transit = service_sheet.e_c_transit
        state.service_sheet.confirmed = service_sheet.confirmed

        state.service_sheet_estimation_error = {}
    },
    ERRORS_SERVICE_SHEET_ESTIMATION(state, errors) {
        state.service_sheet_estimation_error = errors
    },
    PATCH_SERVICE_SHEET_FINAL(state, service_sheet) {
        if(service_sheet.arrival_datetime != null) {
            state.service_sheet.arrival_datetime = moment(service_sheet.arrival_datetime).format('yy-MM-DD')
            state.service_sheet.arrival_datetime_hour = moment(service_sheet.arrival_datetime).format('HH:mm')
        } else {
            state.service_sheet.arrival_datetime = ''
            state.service_sheet.arrival_datetime_hour= ''
        }

        if(service_sheet.departure_datetime != null){
            state.service_sheet.departure_datetime = moment(service_sheet.departure_datetime).format('yy-MM-DD')
            state.service_sheet.departure_datetime_hour = moment(service_sheet.departure_datetime).format('HH:mm')
        } else {
            state.service_sheet.departure_datetime = ''
            state.service_sheet.departure_datetime_hour = ''
        }
        
        state.service_sheet.p_disembarking = service_sheet.p_disembarking
        state.service_sheet.p_embarking = service_sheet.p_embarking
        state.service_sheet.p_transit = service_sheet.p_transit
        state.service_sheet.c_disembarking = service_sheet.c_disembarking
        state.service_sheet.c_embarking = service_sheet.c_embarking
        state.service_sheet.c_transit = service_sheet.c_transit
        state.service_sheet.embarking_extra = service_sheet.embarking_extra
        state.service_sheet.disembarking_extra = service_sheet.disembarking_extra
        state.service_sheet.transit_extra = service_sheet.transit_extra
        state.service_sheet.confirmed = service_sheet.confirmed

        state.service_sheet_final_error = {}
    },
    ERRORS_SERVICE_SHEET_FINAL(state, errors) {
        state.service_sheet_final_error = errors
    },
    PATCH_SERVICE_SHEET_FINGER(state, service_sheet){
        state.service_sheet = Object.keys(state.service_sheet).map(updated_service_sheet => {
            if(updated_service_sheet.id === service_sheet.id) {
                updated_service_sheet.positon = service_sheet.position
                updated_service_sheet.module_start = service_sheet.module_start
                updated_service_sheet.module_end = service_sheet.module_end
                updated_service_sheet.confirmed = service_sheet.confirmed
            }
        })
    },
    PATCH_EXTRA_SERVICE_SHEET(state, service_sheet) {
        state.service_sheet.over_time = service_sheet.over_time
        state.service_sheet.over_night = service_sheet.over_night
        state.service_sheet.weekend = service_sheet.weekend
        state.service_sheet.billable_hours = service_sheet.billable_hours
    },
    PATCH_SERVICE_SHEET_VALIDATION(state, payload) {
        console.log(payload)
        state.service_sheet.confirmed =  true
        state.service_sheet.id_call.number =  payload.id_call.number
        console.log(state.service_sheet)
    },
    PATCH_FINGER_DESCRIPTION(state, payload) {
        state.service_sheet.finger_description = payload.finger_description
    },
    ERROR_FINGER_DESCRIPTION(state, payload) {
        state.service_sheet_fingers_error = payload
    },
    EMPTY_ERROR_FINGER_DESCRIPTION(state) {
        state.service_sheet_fingers_error = []
    },
    SET_MIN_DATE(state, payload) {
        /*if(state.actual_date == '') {
            state.actual_date = payload.mode
            state.min_date = payload.date
        } else if(state.actual_date == 'estimated') {
            state.actual_date = payload.mode
            state.min_date = payload.date
        } else if(state.actual_date == 'final' && payload.mode == 'final') {
            state.actual_date = payload.mode
            state.min_date = payload.date
        } else if(payload.status == 'removed') {
            state.actual_date = payload.mode
            state.min_date = payload.date
        }*/
        state.actual_date = payload.mode
        state.min_date = payload.date
    },
    SET_MAX_DATE(state, payload) {

        /*if(state.actual_date == '') {
            state.actual_date = payload.mode
            state.max_date = payload.date
        } else if(state.actual_date == 'estimated') {
            state.actual_date = payload.mode
            state.max_date = payload.date
        } else if(state.actual_date == 'final' && payload.mode == 'final') {
            state.actual_date = payload.mode
            state.max_date = payload.date
        } else if(payload.status == 'removed') {
            state.actual_date = payload.mode
            state.max_date = payload.date
        }*/
        state.actual_date = payload.mode
        state.max_date = payload.date
    },
};

const actions = {
    async load_all_service_sheets({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "service_sheets")
            .then((response) => {
                commit('SET_SERVICE_SHEETS', response.data)
            })
    },
    async load_service_sheet({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL  + "service_sheets/" + id + '/')
            .then((response) => {
                commit('SET_SERVICE_SHEET', response.data)
            })
    },
    async load_placeholders_service_sheets({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL  + "service_sheets/get_last_service_sheet_data/" + id + '/')
            .then((response) => {
                commit('SET_PLACEHOLDERS_SERVICE_SHEET', response.data)
            })
    },
    async load_main_service_sheet ({commit}, id){
        await axios.get(process.env.VUE_APP_API_URL  + "service_sheets/get_main_by_call/" + id + '/')
            .then((response) => {
                commit('SET_MAIN_SERVICE_SHEET', response.data)
            })
    },
    async set_main_service_sheet({commit}, service_sheet) {
        await axios.patch(process.env.VUE_APP_API_URL + "service_sheets/" + service_sheet.id + '/', {main: true})
            .then((response) => {
                commit('PATCH_MAIN_SERVICE_SHEET', response.data)
            })
    },
    async patch_service_sheet_date({commit}, service_sheet) {
        let arrival = moment(service_sheet.estimated_arrival_datetime).format().split('+')[0]
        let departure = moment(service_sheet.estimated_departure_datetime).format().split('+')[0]
        await axios.patch(process.env.VUE_APP_API_URL + "service_sheets/" + service_sheet.id + '/', {estimated_arrival_datetime: arrival, estimated_departure_datetime: departure})
            .then((response) => {
                commit('PATCH_SERVICE_SHEET_DATE', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SERVICE_SHEET_DATE', error.response.data)
            })
    },
    async patch_service_sheet_general({commit}, service_sheet) {
        await axios.patch(process.env.VUE_APP_API_URL + "calls/" + service_sheet.id_call.id + '/', {id_ship: service_sheet.id_call.id_ship.id, id_ship_responsible: service_sheet.id_call.id_ship_responsible, is_unconventional: service_sheet.id_call.is_unconventional})
            .then((response) => {
                commit('PATCH_CALL_GENERAL', response.data)
            })
        await axios.patch(process.env.VUE_APP_API_URL + 'service_sheets/' + service_sheet.id + '/', {pier: service_sheet.pier, id_terminal: service_sheet.id_terminal.id, note: service_sheet.note, terminal_opening_time: service_sheet.terminal_opening_time, terminal_closing_time: service_sheet.terminal_closing_time})
            .then(() => {
                commit('PATCH_SERVICE_SHEET_GENERAL', service_sheet)
            }) 
            .catch((error) => {
                commit('ERRORS_SERVICE_SHEET_GENERAL', error.response.data)
            })
    },
    async patch_service_sheet_estimation({commit}, service_sheet) {
        if(service_sheet.estimated_arrival_datetime == null || service_sheet.estimated_arrival_datetime_hour == null) {
            start_datetime = null
        } else if (typeof(service_sheet.estimated_arrival_datetime) === 'object' || typeof(service_sheet.estimated_arrival_datetime_hour) === 'object' ) {
            if(typeof service_sheet.estimated_arrival_datetime_hour === 'string') {
                service_sheet.estimated_arrival_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + service_sheet.estimated_arrival_datetime_hour))
            }
            var start_datetime = new Date(new Date(service_sheet.estimated_arrival_datetime).getFullYear(), new Date(service_sheet.estimated_arrival_datetime).getMonth(), new Date(service_sheet.estimated_arrival_datetime).getDate(),
                            new Date(service_sheet.estimated_arrival_datetime_hour).getHours(), new Date(service_sheet.estimated_arrival_datetime_hour).getMinutes(), new Date(service_sheet.estimated_arrival_datetime_hour).getSeconds())
            start_datetime = moment(start_datetime).format().split('+')[0]
        } else if (typeof service_sheet.estimated_arrival_datetime === 'string' && typeof service_sheet.estimated_arrival_datetime_hour === 'string'){
            start_datetime = service_sheet.estimated_arrival_datetime + 'T' + service_sheet.estimated_arrival_datetime_hour
        }
        if (service_sheet.estimated_departure_datetime == null || service_sheet.estimated_departure_datetime_hour == null ) {
            end_datetime = null
        } else if(typeof(service_sheet.estimated_departure_datetime) === 'object' || typeof(service_sheet.estimated_departure_datetime_hour) === 'object') {
            if(typeof service_sheet.estimated_departure_datetime_hour === 'string') {
                service_sheet.estimated_departure_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + service_sheet.estimated_departure_datetime_hour))
            }
            var end_datetime = new Date(new Date(service_sheet.estimated_departure_datetime).getFullYear(), new Date(service_sheet.estimated_departure_datetime).getMonth(), new Date(service_sheet.estimated_departure_datetime).getDate(),
                            new Date(service_sheet.estimated_departure_datetime_hour).getHours(), new Date(service_sheet.estimated_departure_datetime_hour).getMinutes(), new Date(service_sheet.estimated_departure_datetime_hour).getSeconds())
            end_datetime = moment(end_datetime).format().split('+')[0]    
        } else if (typeof service_sheet.estimated_departure_datetime === 'string' && typeof service_sheet.estimated_departure_datetime_hour === 'string'){
            end_datetime = service_sheet.estimated_departure_datetime + 'T' + service_sheet.estimated_departure_datetime_hour
        }

        await axios.patch(process.env.VUE_APP_API_URL + 'service_sheets/' + service_sheet.id + '/', 
        {
            estimated_arrival_datetime: start_datetime,
            estimated_departure_datetime: end_datetime,
            e_p_disembarking: service_sheet.e_p_disembarking,
            e_p_embarking: service_sheet.e_p_embarking,
            e_p_transit: service_sheet.e_p_transit,
            e_c_disembarking: service_sheet.e_c_disembarking,
            e_c_embarking: service_sheet.e_c_embarking,
            e_c_transit: service_sheet.e_c_transit
        })
            .then(async (response) => {
                commit('SET_MIN_DATE', {date: new Date(start_datetime), mode: 'estimated'})
                commit('SET_MAX_DATE', {date: new Date(end_datetime), mode: 'estimated'})
                commit('PATCH_SERVICE_SHEET_ESTIMATION', response.data)
            })
            .catch((error) => {
                commit('ERRORS_SERVICE_SHEET_ESTIMATION', error.response.data)
            })
    },
    async patch_service_sheet_final({commit}, service_sheet) {
        
        if(service_sheet.arrival_datetime == null || service_sheet.arrival_datetime_hour == null || service_sheet.arrival_datetime == '' || service_sheet.arrival_datetime_hour == '' ) {
            start_datetime = null
        } else if (typeof(service_sheet.arrival_datetime) === 'object' || typeof(service_sheet.arrival_datetime_hour) === 'object' ) {
            if(typeof service_sheet.arrival_datetime_hour === 'string') {
                if(service_sheet.arrival_datetime_hour.length == 4) {
                    service_sheet.arrival_datetime_hour = '0' + service_sheet.arrival_datetime_hour
                }
                service_sheet.arrival_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + service_sheet.arrival_datetime_hour))
            }
            var start_datetime = new Date(new Date(service_sheet.arrival_datetime).getFullYear(), new Date(service_sheet.arrival_datetime).getMonth(), new Date(service_sheet.arrival_datetime).getDate(),
                            new Date(service_sheet.arrival_datetime_hour).getHours(), new Date(service_sheet.arrival_datetime_hour).getMinutes(), new Date(service_sheet.arrival_datetime_hour).getSeconds())
            start_datetime = moment(start_datetime).format().split('+')[0]
        } else if (typeof service_sheet.arrival_datetime === 'string' && typeof service_sheet.arrival_datetime_hour === 'string'){
            if(service_sheet.arrival_datetime_hour.length == 4) {
                service_sheet.arrival_datetime_hour = '0' + service_sheet.arrival_datetime_hour
            }
            start_datetime = service_sheet.arrival_datetime + 'T' + service_sheet.arrival_datetime_hour
        }
        if (service_sheet.departure_datetime == null || service_sheet.departure_datetime_hour == null || service_sheet.departure_datetime == '' || service_sheet.departure_datetime_hour == '' ) {
            end_datetime = null
        } else if(typeof(service_sheet.departure_datetime) === 'object' || typeof(service_sheet.departure_datetime_hour) === 'object') {
            if(typeof service_sheet.departure_datetime_hour === 'string') {
                if(service_sheet.departure_datetime_hour.length == 4) {
                    service_sheet.departure_datetime_hour = '0' + service_sheet.departure_datetime_hour
                }
                service_sheet.departure_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + service_sheet.departure_datetime_hour))
            }
            var end_datetime = new Date(new Date(service_sheet.departure_datetime).getFullYear(), new Date(service_sheet.departure_datetime).getMonth(), new Date(service_sheet.departure_datetime).getDate(),
                            new Date(service_sheet.departure_datetime_hour).getHours(), new Date(service_sheet.departure_datetime_hour).getMinutes(), new Date(service_sheet.departure_datetime_hour).getSeconds()) 
            end_datetime = moment(end_datetime).format().split('+')[0]    
        } else if (typeof service_sheet.departure_datetime === 'string' && typeof service_sheet.departure_datetime_hour === 'string'){
            if(service_sheet.departure_datetime_hour.length == 4) {
                service_sheet.departure_datetime_hour = '0' + service_sheet.departure_datetime_hour
            }
            end_datetime = service_sheet.departure_datetime + 'T' + service_sheet.departure_datetime_hour
        }
        const data = {
            p_disembarking: service_sheet.p_disembarking,
            p_embarking: service_sheet.p_embarking,
            p_transit: service_sheet.p_transit,
            c_disembarking: service_sheet.c_disembarking,
            c_embarking: service_sheet.c_embarking,
            c_transit: service_sheet.c_transit,
            disembarking_extra: service_sheet.disembarking_extra,
            embarking_extra: service_sheet.embarking_extra,
            transit_extra: service_sheet.transit_extra,
        }

        if (start_datetime){
            data['arrival_datetime'] = start_datetime
        }
        if (end_datetime){
            data['departure_datetime'] = end_datetime
        }
        
        await axios.patch(process.env.VUE_APP_API_URL + 'service_sheets/' + service_sheet.id + '/', data)
            .then(async (response) => {
                commit('PATCH_SERVICE_SHEET_FINAL', response.data)
                if(start_datetime && end_datetime) {
                    commit('SET_MIN_DATE', {date: new Date(start_datetime), mode: 'final'})
                    commit('SET_MAX_DATE', {date: new Date(end_datetime), mode: 'final'})
                    await axios.patch(process.env.VUE_APP_API_URL + 'calls/' + response.data.id_call.id + '/', {
                        arrival_datetime: start_datetime,
                        departure_datetime: end_datetime
                    })
                } else {
                    commit('SET_MIN_DATE', {date: new Date(response.data.estimated_arrival_datetime), mode: 'estimated', status: 'removed'})
                    commit('SET_MAX_DATE', {date: new Date(response.data.estimated_departure_datetime), mode: 'estimated', status: 'removed'})
                }
                
            })
            .catch((error) => {
                commit('ERRORS_SERVICE_SHEET_FINAL', error.response.data)
            })    
    },
    
    async patch_service_sheet_fingers({commit}, service_sheet) {
        await axios.patch(process.env.VUE_APP_API_URL + 'service_sheets/' + service_sheet.id + '/', 
        {
            position: service_sheet.position,
            module_start: service_sheet.module_start,
            module_end: service_sheet.module_end
        })
            .then((response) => {
                commit('PATCH_SERVICE_SHEET_FINGER', response.data)
            })
    },
    async update_extra_service_sheet({commit}, service_sheet) {
        await axios.patch(process.env.VUE_APP_API_URL + 'service_sheets/' + service_sheet.id + '/', {
            over_time: service_sheet.over_time,
            over_night: service_sheet.over_night,
            weekend: service_sheet.weekend,
            billable_hours: service_sheet.billable_hours
        })
            .then((response) => {
                commit('PATCH_EXTRA_SERVICE_SHEET', response.data)
            })
    },
    async update_finger_description({commit}, data) {
        await axios.patch(process.env.VUE_APP_API_URL + 'service_sheets/' + data.id + '/', {
            finger_description: data.finger_description,
        })
            .then((response) => {
                commit('EMPTY_ERROR_FINGER_DESCRIPTION')
                commit('PATCH_FINGER_DESCRIPTION', response.data)
            })
            .catch((error) => {
                commit('ERROR_FINGER_DESCRIPTION', error.response.data)
            })
    },
    async validate_service_sheet({commit}, id) {
        await axios.post(process.env.VUE_APP_API_URL + 'service_sheets/confirm_service_sheet/' + id + '/')
            .then((response) => {
                commit('PATCH_SERVICE_SHEET_VALIDATION', response.data)
            })
    },
    async set_min_date({commit}, data){
        commit('SET_MIN_DATE', {date: data.date, mode: data.mode})
    },
    async set_max_date({commit}, data){
        commit('SET_MAX_DATE', {date: data.date, mode: data.mode})
    }
};

const getters = {
    all_service_sheets: (state) => state.service_sheets,
    filtered_service_sheets: (state) => (terminal, date) => {
        return state.service_sheets.filter(service_sheet => 
            service_sheet.id_terminal.id === terminal.id &&
            moment(date).isSameOrAfter(moment(service_sheet.estimated_arrival_datetime).format('YYYY-MM-DD')) && 
            moment(date).isSameOrBefore(moment(service_sheet.estimated_departure_datetime).format('YYYY-MM-DD'))
        )
    },
    get_main_service_sheet: (state) => state.main_service_sheet,
    get_service_sheet: (state) => state.service_sheet,
    get_placeholders: (state) => state.placeholders,
    get_errors_service_sheet_date: (state) => state.service_sheet_date_error,
    get_errors_service_sheet_general: (state) => state.service_sheet_general_error,
    get_errors_call_general: (state) => state.call_general_error,
    get_errors_service_sheet_estimation: (state) => state.service_sheet_estimation_error,
    get_errors_service_sheet_final: (state) => state.service_sheet_final_error,
    get_errors_service_sheet_fingers_error: (state) => state.service_sheet_fingers_error,
    min_date: (state) => state.min_date,
    max_date: (state) => state.max_date
};

export default {
    state,
    mutations,
    actions,
    getters
};