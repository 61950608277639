<template>
    <div class="container-fluid p-fluid">
        <div class="row ">
            <div class="col-12 xl:col-6">
                <div class="row justify-content-center">
                    <div class="col-12 md:col-11">
                        <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Ship Information </h5>
                    </div>
                </div>
                <div class="row justify-content-center mt-2">
                    <div class="col-12 md:col-11">
                        <span v-if="new_ship.id_cruise_line" class="p-float-label">
                            <InputText id="cruise_line" v-model="new_ship.id_cruise_line.name" disabled data-testid="cruise_line" />
                            <label for="cruise_line"> Cruise Line </label>
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center mt-3">
                    <div class="col-12 md:col-11">
                        <span v-if="new_service_sheet.id_call" class="p-float-label">
                            <Dropdown :options="ship_agents" id="ship_agent" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator" data-testid="ship_agent" optionLabel="name" optionValue="id" v-model="new_ship_agent.id" @change="load_ship_responsibles($event); change_general()"/>
                            <label for="ship_agent"> Ship Agent </label>
                        </span>
                        <small v-if="Object.keys(get_errors_call_general).length !== 0 && get_errors_call_general.ship_agent" class="p-error"><span v-for="error in get_errors_call_general.ship_agent" :key="error">{{error}}<br></span></small> 
                    </div>
                </div> 
                <div class="row justify-content-center mt-3">
                    <div class="col-12 md:col-11">
                        <span v-if="new_service_sheet.id_call" class="p-float-label">
                            <Dropdown :options="new_ship_responsible" id="ship_responsible" :disabled="!$ability.can('change','call') || is_disabled" data-testid="ship_responsible" optionValue="id" optionLabel="name" v-model="new_service_sheet.id_call.id_ship_responsible" @change="change_general()"/> 
                            <label for="ship_responsible">Ship Responsible</label>
                        </span>
                        <small v-if="Object.keys(get_errors_call_general).length !== 0 && get_errors_call_general.id_ship_responsible" class="p-error"><span v-for="error in get_errors_call_general.id_ship_responsible" :key="error">{{error}}<br></span></small> 
                        <small class="p-error">{{error_null_responsible}}</small>
                    </div>
                </div> 
                <div class="row justify-content-center">
                    <div class="col-12 md:col-11">
                        <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Port Information </h5>
                    </div>
                </div>
                <div class="row justify-content-center mt-2">
                    <div class="col-12 md:col-11">
                        <span class="p-float-label">
                            <InputText id="pier" data-testid="pier" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled" v-model="new_service_sheet.pier" @input="change_general()"/> 
                            <label for="pier">Pier</label>
                        </span>
                        <small v-if="Object.keys(get_errors_service_sheet_general).length !== 0 && get_errors_service_sheet_general.pier" class="p-error"><span v-for="error in get_errors_service_sheet_general.pier" :key="error">{{error}}<br></span></small> 
                    </div>
                </div>
                <div class="row justify-content-center mt-3">
                    <div class="col-12 md:col-11">
                        <span v-if="new_service_sheet.id_terminal" class="p-float-label">
                            <Dropdown id="terminal" :filter="true" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled" data-testid="terminal" v-model="new_service_sheet.id_terminal.id" :options="terminals" optionLabel="name" optionValue="id" @change="change_general(); change_general()"/> 
                            <label for="terminal">Terminal</label>
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center mt-3">
                    <div class="col-12 md:col-5">
                        <span v-if="new_service_sheet.id_terminal" class="p-float-label">
                            <Calendar id="terminal" v-model="new_service_sheet.terminal_opening_time" timeOnly :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled" data-testid="terminal"  @date-select="change_general()" @change="change_general(); new_service_sheet.terminal_opening_time = num2time($event)"/> 
                            <label for="terminal"> Terminal Aperture </label>
                        </span>
                    </div>
                    <div class="col-12 md:col-5">
                        <span v-if="new_service_sheet.id_terminal" class="p-float-label">
                            <Calendar id="terminal" v-model="new_service_sheet.terminal_closing_time" timeOnly :disabled="!$ability.can('change','service_sheet') || check_user_service_operator || is_disabled" data-testid="terminal"  @change="change_general(); new_service_sheet.terminal_closing_time = num2time($event)"/> 
                            <label for="terminal"> Terminal Closure </label>
                        </span>
                    </div>
                    <div class="col-1 my-auto text-center">
                        <i class="pi pi-info-circle" v-tooltip.top="'If these fields are empty, they will be automatically populated when the final data is entered.'"> </i>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 md:col-11">
                        <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Extra Information </h5>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 md:col-11">
                        <span class="p-float-label">
                            <Checkbox :disabled="!$ability.can('change','call') || is_disabled" v-model="new_service_sheet.id_call.is_unconventional" :binary="true" @input="change_general()"/>
                            <label class="ms-3"> Unconventional Ship </label>
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 md:col-11">
                        <span class="p-float-label">
                            <Checkbox :disabled="!$ability.can('change','call') || is_disabled"  v-model="new_service_sheet.id_call.is_new" :binary="true" @input="change_general()"/>
                            <label class="ms-3"> First Call </label>
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center mt-3">
                    <div class="col-12 md:col-11">
                        <span class="p-float-label">
                            <TextArea id="observations" data-testid="observations" rows="5" :disabled="!$ability.can('change','service_sheet') || is_disabled" v-model="new_service_sheet.note" @input="change_general()"/>
                            <label for="observations"> Observations </label>
                        </span>
                        <small v-if="Object.keys(get_errors_service_sheet_general).length !== 0 && get_errors_service_sheet_general.note" class="p-error"><span v-for="error in get_errors_service_sheet_general.note" :key="error">{{error}}<br></span></small> 
                    </div>
                </div>
                <div v-if="service_sheet_incidences.length > 0" class="row justify-content-center mt-3">
                    <div class="col-3">
                        <Button label="Incidences" class="p-button-warning p-button-text p-button-raised" icon="fa fa-exclamation-triangle" @click="open_modal_incidences"/>
                    </div>
                </div>
            </div>
            <div class="col-12 xl:col-6">
                <div class="row justify-content-center">
                    <div class="col-12 md:col-11">
                        <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Ship </h5>
                    </div>
                </div>
                <div class="row pb-4">
                    <div class="row justify-content-center mt-2">
                        <div class="col-12 md:col-11">
                            <span v-if="new_service_sheet.id_call" class="p-float-label">
                                <Dropdown :filter="true" id="ship" data-testid="ship" :disabled="!$ability.can('change','service_sheet') || check_user_service_operator" v-model="new_service_sheet.id_call.id_ship.id" :options="ships" optionLabel="name" optionValue="id" @change="select_ship($event); change_general()"/>
                            </span>
                        </div>
                    </div>
                </div>
                <div clas="row justify-content-center" style="text-align: center;">
                    <div class="col-12 md:col-12">
                        <img class="img-fluid img-general w-100" :src="new_ship.picture" alt="Ship Image" style="border-radius: 1%;"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="button_confirm_general" class="col text-center">
        <Button label="Save" icon="pi pi-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_general()"/>
    </div>

    
     <Dialog header="Incidences" v-model:visible="modal_incidences" :breakpoints="{'960px': '90vw'}" :style="{width: '80vw'}" :modal="true">
        <div class="row">
            <div class="col">
                <Datatable :value="service_sheet_incidences" class="p-datatable-customers"
                    dataKey="id"  responsiveLayout="scroll">
                    <Column header="Title" field="title" style="min-width:12rem">
                        <template #body="{data}">
                            {{data.title}}
                        </template>
                    </Column>
                    <Column header="Description" field="text" style="min-width:12rem">
                        <template #body="{data}">
                            {{data.text}}
                        </template>
                    </Column>
                    <Column header="Date" filterField="date" dataType="date" style="min-width:12rem">
                        <template #body="{data}">
                            {{format_date(data.date)}}
                        </template>
                    </Column>
                    <Column header="Category" field="id_incidence_category.title" style="min-width:12rem">
                        <template #body="slotProps">
                            {{slotProps.data.id_incidence_category.title}} <br>
                        </template>
                    </Column>
                    <Column header="Subcategory" field="id_incidence_subcategory.title" style="min-width:12rem">
                        <template #body="slotProps">
                            {{slotProps.data.id_incidence_subcategory.title}} <br>
                        </template>
                    </Column>
                    <Column header="User" field="id_user_creator.username" style="min-width:12rem"/>
                    <template #empty>
                        <div class="row">
                            <div class="col text-center">
                                No incidences.
                            </div>
                        </div>
                    </template>
                </Datatable>    
            </div>
        </div>
        <template #footer>
            <Button label="Close" @click="modal_incidences = false" class="p-button-text"/>
        </template>
    </Dialog>

</template>

<script>
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import TextArea from 'primevue/textarea'
import Dialog from 'primevue/dialog'
import Datatable from 'primevue/datatable'
import Column from 'primevue/column'
import Checkbox from 'primevue/checkbox'
import Calendar from 'primevue/calendar'

import { num2time, num2timeedit } from '@/modules/utilities.js'

import moment from 'moment'

export default {
    name: 'Estimation',
    components: {
        Button,
        Dropdown,
        InputText,
        TextArea,
        Dialog,
        Datatable,
        Column,
        Checkbox,
        Calendar
    },
    props: {
        service_sheet: {
            type: Object,
            required: true
        },
        ship: {
            type: Object,
            required: true
        },
        ship_agent: {
            type: Object,
            required: true
        },
        ship_agents: {
            type: Object,
            required: true
        },
        ships: {
            type: Object,
            required: true
        },
        terminals: {
            type: Object,
            required: true
        },
        ship_responsible: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            new_service_sheet: this.service_sheet,
            new_ship: this.ship,
            new_ship_responsible: this.ship_responsible,
            new_ship_agent: this.ship_agent,
            button_confirm_general: false,
            is_on_hold_general: false,
            general_collapsed: false,
            modal_incidences: false,
            error_null_responsible: ''
        }
    },
    computed: {
        service_sheet_incidences: function() {
            return this.$store.getters.service_sheet_incidences
        },
        get_errors_service_sheet_general: function() {
            return this.$store.getters.get_errors_service_sheet_general
        },
        get_errors_call_general: function() {
            return this.$store.getters.get_errors_call_general
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Ops') {
                    return true
                }
            }
            return false
        },
        check_user_admins: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Admins') {
                    return true
                }
            }
            return false
        },
        // Para editar una hoja de servicio despues de ser validada / confirmada, se tiene que ser Admin.
        is_disabled: function() {
            if(this.service_sheet.confirmed && !this.check_user_admins) {
                return true
            } else {
                return false
            }
        },
    },
    async mounted() {
        if(this.new_ship.id_cruise_line){
            await this.$store.dispatch('load_ship_agents', this.new_ship.id_cruise_line.id)
        }
       
    },
    methods: {
        async open_modal_incidences(){
            this.$store.dispatch('load_incidence_by_service_sheet', this.service_sheet.id)
            this.modal_incidences = true
        },
        async load_ship_responsibles(id) {
            await this.$store.dispatch('load_ship_responsibles', id.value)
            this.new_ship_responsible = this.ship_responsible
        },
        change_general() {
            this.is_on_hold_general = true
            this.button_confirm_general = true
        },
        async save_changes_general() {
            this.error_null_responsible = ''
            
            if(this.new_service_sheet.id_call.id_ship_responsible == '') {
                this.error_null_responsible = 'Cannot let the responsible field empty.'
            } else {
                await this.$store.dispatch('patch_service_sheet_general', this.new_service_sheet)
                await this.$store.dispatch('patch_call_from_general', this.new_service_sheet.id_call)
                if(Object.keys(this.get_errors_service_sheet_general).length === 0 && Object.keys(this.get_errors_call_general).length === 0){
                    this.$toast.add({severity:'success', summary: 'Successful', detail:'Data Modified', life: 3000});
                    this.button_confirm_general = false
                    this.is_on_hold_general = false
                }
            }
            
        },
        async select_ship(event) {
            await this.$store.dispatch('load_ship', event.value)
            this.new_ship = this.ship
            this.new_service_sheet.id_call.id_ship = this.new_ship
            await this.$store.dispatch('load_ship_agents', this.new_ship.id_cruise_line.id)
            this.new_ship_responsible = ''
            this.new_ship_agent.id = ''
            this.new_service_sheet.id_call.id_ship_responsible = ''
        },
        format_date(value){
            return moment(value).format().split('T')[0]
        },
        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
    },
    
}
</script>

<style scoped>
.on_hold{
    border: 4px solid #002D72;
    border-radius: 10px;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}


:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

.img-fluid{
    margin:0 auto;
}

.img-general{
    max-height: 450px;
    min-height: 200px;
    object-fit: contain;
}

</style>