<template>
    <Toast/>
    <div v-if="loading" class="mt-3">
        <!-- <div class="grid justify-content-end">
            <div class="col-1">
                <Skeleton style="height: 6rem"> </Skeleton>
            </div>
        </div> -->
        <div class="grid">
            <div class="col-6">
                <Skeleton style="height: calc(100vh - 40rem)"> </Skeleton>
            </div>
            <div class="col-6">
                <Skeleton style="height: calc(100vh - 40rem)"> </Skeleton>
            </div> 
        </div>
        
    </div>
    <div v-else-if="get_pending_bills.length > 0" >
        <div class="flex justify-content-end mt-3 ">
            <span class="card p-2 mb-0">
                <Button v-if="$ability.can('add','bill')" icon="fa fa-plus" class="p-button-text"  v-tooltip.bottom="'New Bill'" @click="$router.push({path: '/billing/steps'})">
                    <i class="pi pi-plus" style="font-size: 1.2rem" ></i>
                </Button>
            </span>
        </div>

        <div class="grid" style="overflow-x: hidden;">
            <div class="col-12 lg:col-6 p-fluid ps-3 pe-3 pt-3 text-center">
                <span v-if="get_pending_bills[first].bill.split('?')[0].split('.').pop().toLowerCase() == 'pdf'">
                    <div class="ratio " style="height: 45vh;" v-if="get_pending_bills[first].bill">
                        <iframe
                            :src="get_pending_bills[first].bill"
                            frameBorder="0"
                            scrolling="auto"
                        ></iframe>
                    </div>
                </span>
                <span v-else-if="get_pending_bills[first].bill.split('?')[0].split('.').pop().toLowerCase() == 'eml'">
                    <i class="fa-regular fa-envelope fa-10x"></i>
                    <div class="row justify-content-center">
                        <div class="col-6 md:col-4">
                            <a :href="get_pending_bills[first].bill"> <Button icon="pi pi-download" label="Download" class="p-button-raised p-button-rounded" @click="get_pending_bills[first].bill"></Button></a>
                        </div>
                    </div>
                </span>
                <span v-else>
                    <img class="border-round card-img-top-modal img-fluid" style="cursor: pointer;" :src="get_pending_bills[first].bill" @click="display_image(get_pending_bills[first].bill)"/>
                </span>

                <div class="col-12 text-center">
                    <Paginator v-model:first="first" :rows="1" :totalRecords="get_pending_bills.length" :template="template" />
                </div>

            </div>
            <div class="col-12 lg:col-6" >
                <div class="container">
                    <div class="row ps-2">
                        <div class="col-12 sm:col-4 md:col text-center">
                            <small style="color: gray;"> SERIAL NUMBER </small>
                            <strong><h4>{{ get_pending_bills[first].number }}</h4></strong>
                        </div>
                        <div class="com-12 sm:col-4 md:col text-center">
                            <small style="color: gray;">SUPPLIER</small>
                            <strong><h4>{{ get_pending_bills[first].id_supplier.name }}</h4></strong>
                        </div>
                        <div class="col-12 sm:col-4 md:col text-center">
                            <small style="color: gray;">TERMINALS</small>
                            <div v-if="get_pending_bills[first].terminals.length > 0">
                                <span v-for="(item, index) in get_pending_bills[first].terminals" :key="item">
                                    <h4 v-if="index == get_pending_bills[first].terminals.length - 1" style="display: inline;">{{ item.name }}</h4>
                                    <h4 v-else style="display: inline;">{{ item.name }}, </h4>
                                </span>
                            </div>
                            <div v-else>
                                <strong><h4> - </h4></strong>
                            </div>
                        </div>
                    </div>
                    <div class="row ps-2">
                        <div class="col-12 sm:col-4 md:col-4 text-center">
                            <small style="color: gray;"> CONCEPT </small>
                            <strong><h4 v-if="get_pending_bills[first].concept.length < 20">{{ get_pending_bills[first].concept }}</h4>
                            <h4 v-else style="cursor:pointer" @click="toggle_concept($event, get_pending_bills[first].concept)">
                                {{ get_pending_bills[first].concept.split(" ").slice(0, 3).join(" ") }}<span class="click_concept" style="color: rgb(174, 134, 221)">...</span>
                            </h4></strong>
                        </div>
                        <div class="col-12 sm:col-4 md:col-4 text-center">
                            <small style="color: gray;"> PAYMENT TYPE </small>
                            <strong><h4>{{ get_pending_bills[first].payment_type }}</h4></strong>
                        </div>
                    </div>
                    <div class="row ps-2">
                        <div class="col-12 sm:col-4 md:col text-center">
                            <small style="color: gray;">ISSUE DATE</small>
                            <strong><h4>{{ get_pending_bills[first].issue_date ? get_pending_bills[first].issue_date : '-'  }}</h4></strong>
                        </div>
                        <div class="col-12 sm:col-4 md:col text-center">
                            <small style="color: gray;">PAYMENT DATE</small>
                            <strong><h4>{{ get_pending_bills[first].payment_date ? get_pending_bills[first].payment_date : '-' }}</h4></strong>
                        </div>
                        <div class="col-12 sm:col-4 md:col text-center">
                            <small style="color: gray;">DUE DATE</small>
                            <strong><h4>{{ get_pending_bills[first].due_date ? get_pending_bills[first].due_date : '-' }}</h4></strong>
                        </div>
                    </div>
                    <div class="row ps-2">
                        <div class="col-12 sm:col-4 md:col-4 text-center">
                            <small style="color: gray;">BUDGET</small>
                            <strong>
                                <h4 v-if="get_pending_bills[first].budget"><a :href="get_pending_bills[first].budget" target="_blank"> <i class="pi pi-eye" style="font-size:2rem; color: black"></i></a></h4>
                                <h4 v-else> - </h4>
                            </strong>
                        </div>
                        <div class="col-12 sm:col-4 md:col-4 text-center">
                            <small style="color: gray;"> TOTAL IMPORT </small>
                            <strong><h4>{{ get_pending_bills[first].total_import }} {{ get_pending_bills[first].id_currency.symbol ? get_pending_bills[first].id_currency.symbol : ' '  }} </h4></strong>
                        </div>
                    </div>
                    <div v-if="get_pending_bills[first].total_import == 0" class="flex p-fluid">
                        <Message severity="info" :closable="false"> This bill does not have any import added, until then its not possible to validate it.</Message>
                    </div>
                    <Divider class=""/>
                    <div class="row ps-2 pt-2">
                        <div class="col-12">
                            <span class="p-float-label">
                                <TextArea :disabled="get_pending_bills.length == 0" id="admin_comment" rows="4" style="width: 100%;" v-model="admin_comment" :class="{'p-invalid': admin_comment_errors.error}"></TextArea>
                                <label for="admin_comment"> Optional comment </label>
                                <small v-if="Object.keys(admin_comment_errors).length !== 0 && admin_comment_errors.error" class="p-error"><span class="pb-5" >{{admin_comment_errors.error}}<br></span></small>
                            </span>
                        </div>
                    </div>
                    <div class="row ps-2">
                        <div class="col text-end px-1 sm:px-3">
                            <Button :disabled="get_pending_bills.length == 0 || get_pending_bills[first].total_import == 0" icon="pi pi-check" label="Accept" class="p-button-success" @click="validate_bill(get_pending_bills[first])"/>
                        </div>
                        <div class="col px-1 sm:px-3">
                            <Button :disabled="get_pending_bills.length == 0" icon="pi pi-times" label="Deny" class="p-button-danger" @click="reject_bill(get_pending_bills[first])"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="row justify-content-center mt-3">
        <div class="flex justify-content-between mt-3 ">
            <h1> Pending </h1>
            <span class="card p-2 mb-0">
                <Button v-if="$ability.can('add','bill')" icon="fa fa-plus" class="p-button-text"  v-tooltip.bottom="'New Bill'" @click="$router.push({path: '/billing/steps'})">
                    <i class="pi pi-plus" style="font-size: 1.2rem" ></i>
                </Button>
            </span>
        </div>
        <div>
            <div class="row">
                <div class="col text-center mt-4 mb-4">
                    <h5> No pendings bills available.</h5>
                </div>
            </div>  
        </div>
    </div>
    <div class="grid">
        <div v-if="get_error_image" class="offset-md-6 col-md-6 col-12 p-0">
            <p class="p-error">Error adding the signature to the document, please check your profile to see if you have a signature added.</p>
        </div>
    </div>
   
    <!--
    <div class="mt-4" style="margin-bottom: 1rem;">
        <div class="card">
            <DataTable :value="get_pending_bills" paginator="true" :rows="10" stripedRows>
                <Column header="Number" field="number"/>
                <Column header="Date" field="creation_datetime">
                    <template #body="slotProps">
                        <span> {{format_time(slotProps.creation_datetime)}} </span>
                    </template>
                </Column>
                <Column header="Terminals" field="terminals_name">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.terminals_name == 'Empty'">
                            <span> - </span>
                        </span>
                        <span v-else v-for="(item,index) in slotProps.data.terminals_name" :key="index">
                            {{item}}<span v-if="index < slotProps.data.terminals_name.length - 1">,&nbsp;</span>
                        </span>
                    </template>
                </Column>
                <Column header="Supplier" field="id_supplier.name"/>
                <Column header="Import" field="total_import">
                    <template #body="slotProps">
                        <span style="cursor:pointer;" @click="toggle($event, slotProps.data)"> {{slotProps.data.total_import}} € </span>
                    </template>
                </Column>
                <Column header="Details">
                    <template #body="slotProps">
                        <span style="cursor: pointer; color: blue;" @click="toggle_details($event, slotProps.data)"> View </span>
                    </template>
                </Column>
                <Column header="Action">
                    <template #body="slotProps">
                        <Button icon="fa fa-check" class="p-button-sm ms-1 me-1 p-button-rounded p-button-success p-button-outlined" @click="validate_bill(slotProps.data)"/>
                        <Button icon="fa fa-xmark" class="p-button-sm ms-1 me-1 p-button-rounded p-button-danger p-button-outlined" @click="reject_bill(slotProps.data)"/>
                    </template>
                </Column>
                <template #empty>
                    <div class="col text-center">
                        No records found.
                    </div>
                </template>
            </DataTable>
        </div>
    </div>

    <OverlayPanel ref="op" :style="{width: '250px'}">
        <div class="container p-3">
            <div class="row">
                <div class="col">
                    <small>BASE IMPORT</small> 
                </div>
                <div class="col text-end">
                    <strong>{{temporary_base}}</strong> €
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small>IVA</small> 
                </div>
                <div class="col text-end">
                    <strong>{{temporary_iva}} </strong> %
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small>TOTAL</small> 
                </div>
                <div class="col text-end">
                    <strong>{{temporary_total}} </strong> €
                </div>
            </div>
        </div>
    </OverlayPanel>

    <OverlayPanel ref="op_details" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '450px'}">
        <div class="container ps-4 pe-4 pt-2 pb-2">
            <div class="row">
                <div class="col">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Information </h5>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small style="color:gray"> NUMBER </small> 
                </div>
                <div class="col text-end">
                    <strong> {{ details.number ? details.number : '-' }} </strong> 
                </div>
                
            </div>
            <div class="row">
                <div class="col">
                    <small style="color:gray"> TERMINAL </small> 
                </div>
                <div class="col text-end">
                    <span v-if="details.terminals.length == 0">
                        <strong> - </strong>
                    </span>
                    <span v-else>
                        <span v-for="(item,index) in details.terminals" :key="index">
                            <strong>{{item.name}}<span v-if="index < details.terminals.length - 1">,&nbsp;</span></strong>
                        </span>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small style="color:gray"> SUPPLIER </small> 
                </div>
                <div class="col text-end">
                    <strong> {{ details.id_supplier.name ? details.id_supplier.name : '-' }} </strong> 
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small style="color:gray"> BUDGET </small> 
                </div>
                <div class="col text-end">
                    <span v-if="details.budget == null">
                        <strong> - </strong> 
                    </span>
                    <span v-else>
                        <Image :src="details.budget" height="100" preview/>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small style="color:gray"> BILL </small> 
                </div>
                <div class="col text-end">
                    <Image :src="details.bill" height="100" preview/>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Import </h5>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small style="color: gray"> BASE </small> 
                </div>
                <div class="col text-end">
                    <strong> {{ details.base_import ? details.base_import : '-' }} </strong> €
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small style="color: gray"> IVA </small> 
                </div>
                <div class="col text-end">
                    <strong> {{ details.iva ? details.iva : '-' }} </strong> %
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small style="color: gray"> TOTAL </small> 
                </div>
                <div class="col text-end">
                    <strong> {{ details.total_import ? details.total_import : '-' }} </strong> €
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> </h5>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small style="color: gray"> CREATION </small> 
                </div>
                <div class="col text-end">
                    <strong> {{ creation_format(details.creation_datetime) ? creation_format(details.creation_datetime) : '-' }} </strong>
                </div>
            </div>
        </div>
    </OverlayPanel>
    -->
    <Galleria v-model:visible="display_image_modal" :value="image_zoom" containerStyle="max-width: 50%" :fullScreen="true" :showThumbnails="false">
        <template #item="slotProps">
            <img :src="slotProps.item" :alt="image" style="width: 100%; display: block" />
        </template>
    </Galleria>
    <OverlayPanel ref="op_bill_concept" class="p-3" style="max-width: 20rem; box-shadow: 3px 3px 5px 0px;">
        <p> {{ get_pending_bills[first].concept }} </p>
    </OverlayPanel>

</template>

<script>
//import DataTable from 'primevue/datatable'
//import Column from 'primevue/column'
//import OverlayPanel from 'primevue/overlaypanel'
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import Paginator from 'primevue/paginator'
import Galleria from 'primevue/galleria'
import Message from 'primevue/message'
import Skeleton from 'primevue/skeleton'
import TextArea from 'primevue/textarea'
import { PDFDocument, degrees } from 'pdf-lib';
import moment from 'moment'
import axios from 'axios'
import Divider from 'primevue/divider' 
import OverlayPanel from 'primevue/overlaypanel'

export default {
    name: 'Billing_validations',
    components: {
        //DataTable,
        //Column,
        //OverlayPanel,
        Button,
        Toast,
        Paginator,
        Galleria,
        Message,
        Skeleton,
        TextArea,
        Divider,
        OverlayPanel
    },
    data(){ 
        return {
            temporary_total: '',
            temporary_iva: '',
            temporary_base: '',
            details: '',
            first: 0,
            loading: true,
            display_image_modal: false,
            image_zoom: [],
            admin_comment:'',
            admin_comment_errors:{},
            template: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
            errors_adding_image:true,
            pdfDataUrl: null,
            pdfBytes: null,
            imageBytes: null,
            bill_concept: '',
        }
    },
    computed: {
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        get_error_image: function() {
            return this.$store.getters.get_error_image
        },
        get_pending_bills: function() {
            return this.$store.getters.get_pending_bills
        },
        get_admin_comment_errors:function() {
            return this.$store.getters.get_admin_comment_errors
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        await this.$store.dispatch('load_pending_bills')
        this.loading = false
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {

        async handleResize(){
            if (window.innerWidth <= 861){
                this.template = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.template = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        display_image(data) {
            this.image_zoom = []
            this.image_zoom.push(data)
            this.display_image_modal = true
        },

        toggle_concept(event, data) {
            this.bill_concept = data
            this.$refs.op_bill_concept.toggle(event);
        },
        toggle(event, data) {
            this.temporary_base = data.base_import
            this.temporary_iva = data.iva
            this.temporary_total = data.total_import
            this.$refs.op.toggle(event);
        },
        toggle_details(event, data) {
            this.details = data
            this.$refs.op_details.toggle(event);
        },
        creation_format(data){
            return moment(data).fromNow()
        },
        format_time(data) {
            return moment(data).format('YYYY-MM-DD')
        },

        //Configurar nuevo dominio en el ficher CORS de public
        async addImageToPDF(pdfUrl, imageUrl) {
           const axios_request = axios.create({headers: {'Content-Type': 'application/pdf'}, responseType: 'arraybuffer'});
           const existingPdfBytes = await axios_request.get(pdfUrl).then((res) => res.data);
           const pdfDoc = await PDFDocument.load(existingPdfBytes);
           const pages = pdfDoc.getPages();
           const firstPage = pages[0];
           let rotation = firstPage.getRotation().angle;
           let x_cord = 10;
           let y_cord = 10;
           const axios_request2 = axios.create({responseType: 'arraybuffer'});
           const jpgImageBytes = await axios_request2.get(imageUrl).then((res) => res.data);
           let radians = degrees(0)
           const jpgImage = await pdfDoc.embedPng(jpgImageBytes);
           if (rotation === 90 || rotation === 270) {
               x_cord = firstPage.getWidth()-50;
               radians = degrees(90); // 90 grados en radianes
           }
           // Fetch JPEG image
           

           //const { width, height } = firstPage.getSize();
           const imgWidth = 250;
           const imgHeight = (jpgImage.height * imgWidth) / jpgImage.width;
           firstPage.drawImage(jpgImage, {
               x: x_cord, // Adjust the x-coordinate to move the image to the left
               y: y_cord, // Adjust the y-coordinate to move the image down
               width: imgWidth,
               height: imgHeight,
               rotate: radians
           });
           
           const pdfBytes = await pdfDoc.save();
           const modifiedPdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
           //saveAs(modifiedPdfBlob, 'test_pdf.pdf')
           return modifiedPdfBlob
       },

       async validate_bill(data) {
            if(this.first != 0) {
                this.first = this.first - 1
            }
            
            // Si el puerto es Málaga, hay que añadir la firma de la responsable que valida las facturas a las mismas.
            if(this.get_current_user.profile.id_port.name == 'Puerto de Málaga'){
                const new_name = data.bill_name.split('/').pop()
                const new_pdf = await this.addImageToPDF(data.bill, this.get_current_user.profile.signature)
                let add_signature = {
                    id: data.id,
                    pdf:new_pdf,
                    pdf_name:new_name
                }
                await this.$store.dispatch('upload_new_pdf', add_signature)
            }
            if (!this.get_error_image){
                let validation = {
                    status: 'validate',
                    admin_comment: this.admin_comment,
                    id: data.id
                }
                await this.$store.dispatch('change_status_bill', validation)
                if(Object.keys(this.admin_comment_errors).length === 0){
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Bill validated.', life: 3000});
                    this.admin_comment_errors=''
                    this.admin_comment=''
                }else{
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'Bill validation error.', life: 3000});
                }
            }else{
                this.errors_adding_image = true
            }
            
            this.admin_comment_errors = this.get_admin_comment_errors
            
            
        },

        async reject_bill(data) {
            if(this.first != 0) {
                this.first = this.first - 1
            }

            let validation = {
                status: 'reject',
                admin_comment: this.admin_comment,
                id: data.id
            }
            
            await this.$store.dispatch('change_status_bill', validation) 

            this.admin_comment_errors = this.get_admin_comment_errors
            if(Object.keys(this.admin_comment_errors).length === 0){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Bill rejected.', life: 3000});
                this.admin_comment_errors=''
                this.admin_comment=''
            }else{
                this.$toast.add({severity:'error', summary: 'Error', detail: 'Bill validation error.', life: 3000});
            }
        }
    }
    
}
</script>

<style scoped>

.click_concept:hover {
    background-color: rgb(239, 239, 239);
}

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    background: transparent;
}

.card-img-top {
    height: 42vh;
    width: 100vh;
    object-fit: cover;
}

.card-img-top-modal {
    height: 42vh;
    width: 100vh;
    object-fit: scale-down;
}
</style>