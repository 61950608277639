<template>
    <div class="container-fluid" >
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'Maintenance',
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
    margin: 1rem;
}
</style>