<template>
    <div class="card">
        <div class="row">
            <Toast />
            <DataTable :value="all_contracts" :paginator="true" :rows="10"  
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            :globalFilterFields="['id_supplier.name', 'name', 'contract_details']"
            v-model:filters="filters" removableSort
            stripedRows :loading="loading"
            >   
                <template #header>
                    <div class="row">
                        <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                            <span class="p-input-icon-left ">
                                <i class="pi pi-search" />
                                <InputText id="filter_contracts" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                            </span>
                        </div>
                    </div>
                </template>
                <Column header="Name" field="name" :sortable="true" style="min-width:14rem;">
                </Column>
                <Column header="Supplier" field="id_supplier.name" :sortable="true" style="min-width:14rem;">
                </Column>
                <Column header="Start date" field="start_date" :sortable="true" style="min-width:10rem;"> 
                </Column>
                <Column header="End date" field="end_date" :sortable="true" style="min-width:10rem;"> 
                </Column>
                <Column header="Rescission date" field="rescission_date" :sortable="true" style="min-width:10rem;"> 
                </Column>
                <Column header="Contract Details" field="contract_details" :sortable="true"  style="min-width:14rem;">
                </Column>
                <Column header="Info" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button icon="pi pi-eye" class="p-button-rounded p-button-warning p-button-outlined" @click="show_contract(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('change','contract')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_contract(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','contract')" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_contract(slotProps.data)"/>
                    </template>
                </Column>
                <template #empty>
                    <div class="row">
                        <div class="col text-center">
                            No records.
                        </div>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>

    <Dialog data-cy="edit_contracts_modal" v-model:visible="contract_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Contract Details" :modal="true" class="p-fluid">
        <div class="container">
            <div class="col-12 md:col-12">
                <div class="p-float-field">
                    <label for="name" class="ps-1 pb-1"> <strong> Name <b class="required_field">*</b></strong> </label>  
                    <InputText id="name" v-model="contract.name" autofocus :class="{'p-invalid': submitted && contract_errors.name}" />
                    <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.name" class="p-error"><span v-for="error in contract_errors.name" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 md:col-12">
                <div class="p-float-field">
                    <label for="start_date" class="pb-1 ps-1"> <strong> Start date <b class="required_field">*</b></strong> </label>
                    <Calendar autocomplete="off" id="start_date" v-model="contract.start_date" dateFormat="yy-mm-dd" />
                    <small class="p-error" v-if="submitted && !contract.start_date">Start date is required.</small>
                    <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.start_date" class="p-error"><span v-for="error in contract_errors.start_date" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 md:col-12">
                <div class="p-float-field">
                    <label for="end_date" class="pb-1 ps-1"> <strong> End date <b class="required_field">*</b></strong> </label>
                    <Calendar autocomplete="off" id="end_date" v-model="contract.end_date" dateFormat="yy-mm-dd"/>
                    <small class="p-error" v-if="submitted && !contract.end_date">End date is required.</small>
                    <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.end_date" class="p-error"><span v-for="error in contract_errors.end_date" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 md:col-12">
                <div class="p-float-field">
                    <label for="rescission_date" class="pb-1 ps-1"> <strong> Rescission date </strong> </label>
                    <Calendar autocomplete="off" id="rescission_date" v-model="contract.rescission_date" dateFormat="yy-mm-dd"/>
                    <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.rescission_date" class="p-error"><span v-for="error in contract_errors.rescission_date" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 md:col-12">
                <div class="p-float-field">
                    <label for="id_supplier" :class="{'p-invalid': submitted && !contract.id_supplier || contract_errors.id_supplier}" class="ps-1 pb-1"> <strong> Supplier <b class="required_field">*</b> </strong>  </label>  
                    <Dropdown id="id_supplier" v-model="contract.id_supplier" :options="all_suppliers" optionLabel="name" :filter="true"> 
                    <template #value="slotProps">
                        <div v-if="slotProps.value && slotProps.value.name">
                            <span>{{slotProps.value.name}}</span>
                        </div>
                        <div v-else-if="slotProps.label && !slotProps.value.value">
                            <span >{{slotProps.name}}</span>
                        </div>
                    </template>
                    </Dropdown>
                    <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.id_supplier" class="p-error"><span v-for="error in contract_errors.id_supplier" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 md:col-12">
                <div class="p-float-field">
                    <label for="contract_details" class="ps-1 pb-1"> <strong> Contract details </strong> </label>  
                    <TextArea id="contract_details" v-model="contract.contract_details" autofocus :class="{'p-invalid': submitted && contract_errors.contract_details}" />
                    <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.contract_details" class="p-error"><span v-for="error in contract_errors.contract_details" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 md:col-12 text-center">
                <div class="p-float-field">
                    <label for="contractUploader" class="ps-1 pb-1"> <strong> Contract </strong> </label>
                    <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*,application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUploadContract" />
                    <small v-if="Object.keys(contract_errors).length !== 0 && contract_errors.contract" class="p-error"><span v-for="error in contract_errors.contract" :key="error">{{error}}<br></span></small>
                    {{ contract_name }}
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_contract_dialog"/>
            <Button data-cy="save_edit_contract" label="Save" @click="save_contract" />
        </template>
    </Dialog>

     <Dialog v-model:visible="contract_dialog_info" :style="{width: '50rem'}" header="Contract Information" :modal="true" class="p-fluid">
        <div class="container">
            <div class="row pb-2 text-center">
                <div class="col">
                    <div class="row">
                      <b> Contract </b>
                    </div>
                    <div class="row">
                        <div v-if="contract_with_image.contract.split('?')[0].split('.').pop() == 'pdf'" class="col-12 md:col-12">
                            <div class="ratio ratio-4x3" v-if="contract_with_image.contract">
                                <iframe
                                    :src="contract_with_image.contract"
                                    frameBorder="0"
                                    scrolling="auto"
                                ></iframe>
                            </div>
                        </div>
                        <div v-else>
                            <Image :src="contract_with_image.contract" height="200" preview />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Close" class="p-button-text" @click="hide_contract_info_dialog"/>
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_contract_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="contract">Are you sure you want to delete the contract with the following title <b>{{contract.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_contract_dialog = false"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="deleteContracts" />
        </template>
    </Dialog>

</template>

<script> 
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import TextArea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import Toast from 'primevue/toast';
import FileUpload from 'primevue/fileupload'
import Image from 'primevue/image'
import { FilterMatchMode } from 'primevue/api';
import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'TableContracts',
    components: {
        DataTable,
        Column,
        Button,
        Dialog,
        TextArea,
        InputText,
        Calendar,
        Dropdown,
        Toast,
        FileUpload,
        Image
    },
    data() {
        return {
            contract: {},
            contract_errors:{},
            contract_doc:'',
            delete_contract_dialog: false,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            contract_dialog: false,
            contract_dialog_info: false,
            color: localStorage.getItem('primary_color'),
            submitted: false,
            active_index: 1,
            contract_name: '',
            contract_with_image: {},
            contract_with_images: [
                {
                    "itemImageSrc": "",
                    "thumbnailImageSrc": "",
                    "alt": "Contract Picture",
                    "title": "Contract Picture" 
                },
            ],
            responsiveOptions: [
				{
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_contracts.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_suppliers')
            await this.$store.dispatch('load_all_contracts')
            this.loading = false
        }
        
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_contracts: function() {
            return this.$store.getters.all_contracts
        },
        all_suppliers: function() {
            return this.$store.getters.all_suppliers
        },
        get_errors_contract: function() {
            return this.$store.getters.get_errors_contract
        },
        get_delete_contract_status: function() {
            return this.$store.getters.get_delete_contract_status
        },
        get_contract: function() {
            return this.$store.getters.get_contract
        }
    },
    methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        onUploadContract(e){
            this.contract.contract = e.files[0];
            this.contract_name = e.files[0].name
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        confirm_delete_contract(contract) {
            this.contract = contract;
            this.delete_contract_dialog = true;
        },
        async deleteContracts() {
            await this.$store.dispatch('delete_contract', this.contract.id)
            if (!this.get_delete_incidence_zone_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Contract deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Contract can't be deleted", life: 3000});
            }
            this.delete_contract_dialog = false
        },
        edit_contract(contract) {
            this.contract = {...contract};
            this.contract_dialog = true;
        },
        async show_contract(contract) {
            await this.$store.dispatch('load_contract', contract.id)
            this.contract = {...contract};
            this.contract_with_image = this.get_contract
            this.contract_dialog_info = true;
        },
        async save_contract() {
            this.submitted = true

            if(this.contract_doc != ''){
                this.contract.contract = this.contract_doc
            }
            
            await this.$store.dispatch('update_contract', this.contract)
            this.contract_errors = this.get_errors_contract
            
            if(Object.keys(this.contract_errors).length === 0){
                this.contract = '';
                this.hide_contract_dialog()
                this.contract = {};
                this.contract_name = ''
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Contract modified', life: 3000});
            }
        },
        hide_contract_dialog() {
            this.contract_dialog = false;
            this.submitted = false;
        },
        hide_contract_info_dialog() {
            this.contract_dialog_info = false;
        }
    },
    validations() {
        return {
            contract: {
                contract_doc: { required },
                id_supplier: { required },
                start_date: { required },
                end_date: { required },
            }
            
        }
    },

}
</script>

<style  scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}
.required_field{
    color: v-bind(color)
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>