<template>
    <ModalShipAgents/>
    <TableShipAgents/>
</template>

<script>
import TableShipAgents from '@/components/AdminDashboard/Ship_agents/TableShip_agents.vue/'
import ModalShipAgents from '@/components/AdminDashboard/Ship_agents/ModalShip_agents.vue/'
export default {
    name: 'Ship_agents',
    components: {
        TableShipAgents,
        ModalShipAgents
    }
}
</script>

<style scoped>

</style>