import axios from 'axios'

const state = {
    incidence_zones: [],
    incidence_zone_errors: {},
    delete_status: false,
    zone_names: []
};

const mutations = {
    SET_INCIDENCE_ZONE(state, incidence_zones) {
        state.incidence_zones = incidence_zones
    },
    SET_INCIDENCE_ZONE_NAME(state, incidence_zone_names) {
        state.zone_names = incidence_zone_names
    },
    NEW_INCIDENCE_ZONE(state, incidence_zones) {  
        state.incidence_zones.push(incidence_zones)
        state.incidence_zone_errors={}
    },
    DELETE_INCIDENCE_ZONE(state, id) {
        let index = state.incidence_zones.findIndex(incidence_zones => incidence_zones.id == id)
        state.incidence_zones.splice(index, 1)
        state.delete_status=false
    },
    UPDATE_INCIDENCE_ZONE(state, incidence_zone) {
        state.incidence_zones = state.incidence_zones.map(updated_incidence_zone => {
            if(updated_incidence_zone.id === incidence_zone.id) {
                updated_incidence_zone.title = incidence_zone.title
                updated_incidence_zone.description = incidence_zone.description
                updated_incidence_zone.incidence_type = incidence_zone.incidence_type
            }
            return updated_incidence_zone
        })
        state.incidence_zone_errors={}
    },
    ERRORS_INCIDENCE_ZONE(state, errors){
        state.incidence_zone_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};
const actions = {
    async load_all_incidence_zones({commit}) {        
        let names = []
        await axios.get(process.env.VUE_APP_API_URL  + "incidence_zones/")
            .then((response) => {
                commit('SET_INCIDENCE_ZONE', response.data)
                response.data.forEach(element => {
                    names.push(element.title)
                });
                commit('SET_INCIDENCE_ZONE_NAME', names)
            })
    },
    async delete_incidence_zone({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL  + 'incidence_zones/' + id)
            .then(() => {
                commit('DELETE_INCIDENCE_ZONE', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async create_incidence_zone({commit}, incidence_zone) {
        await axios.post(process.env.VUE_APP_API_URL  + 'incidence_zones/', incidence_zone)
            .then((response) => {
                commit('NEW_INCIDENCE_ZONE', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_INCIDENCE_ZONE', error.response.data)
            })
    },
    async update_incidence_zone({commit}, incidence_zone) {
        await axios.put(process.env.VUE_APP_API_URL  + 'incidence_zones/' + incidence_zone.id + '/',  { title: incidence_zone.title, description: incidence_zone.description, incidence_type: incidence_zone.incidence_type })
            .then(() => {
                commit('UPDATE_INCIDENCE_ZONE', incidence_zone)
            })
            .catch((error) => {
                commit('ERRORS_INCIDENCE_ZONE', error.response.data)
            })
    },
};

const getters = {
    all_incidence_zones: (state) => state.incidence_zones,
    get_errors_incidence_zone: (state) => state.incidence_zone_errors,
    get_delete_incidence_zone_status:(state) => state.delete_status,
    general_incidences_zone: (state) => {
        return state.incidence_zones.filter(incidence_zone => incidence_zone.incidence_type === 'General' || incidence_zone.incidence_type === 'Both')
    },
    call_incidences_zone: (state) => {
        return state.incidence_zones.filter(incidence_zone => incidence_zone.incidence_type === 'Call' || incidence_zone.incidence_type === 'Both')
    },
    get_zone_names:(state) => state.zone_names,
};

export default {
    state,
    mutations,
    actions,
    getters
};
