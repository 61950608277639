<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div v-if="$can('view','water_meter')" class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Water Meters </h1>
            <div v-if="$can('add','water_meter')" class="card p-1">
                <Button data-cy="new_terminal_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Terminal'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_water_meter_modal" header="New Water Meter" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <div class="container">
                <form id="form_new_terminal" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label mt-2">     
                                <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted }" />
                                <label for="name" :class="{'p-error':v$.name.$invalid && submitted}"> Name </label>
                                <small v-if="Object.keys(terminal_errors).length !== 0 && terminal_errors.name" class="p-error"><span v-for="error in terminal_errors.name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
                        </div>
                    </div> 
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="dropdown_fingers" id="id_terminal" v-model="v$.id_terminal.$model"  :options="all_terminals" optionLabel="name" :filter="true" :class="{'p-invalid':v$.id_terminal.$invalid && submitted }"/>
                                <label for="id_terminal"> Terminal  </label>
                            </div>
                            <small v-if="(v$.id_terminal.$invalid && submitted) || v$.id_terminal.$pending.$response" class="p-error">{{v$.id_terminal.required.$message.replace('Value', 'Terminal')}}</small>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <Button data-cy="create_terminal_button" form="form_new_terminal" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Dropdown from 'primevue/dropdown'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        Toast,
        Dropdown
    },
    data() {
        return {
            terminal_errors: {},
            displayModal: false,
            name: '',
            id_terminal: '',
            submitted: false,
            showMessage: false,
        }
    },
    async mounted() {
        await this.$store.dispatch('load_all_terminals')
    },
    computed: {
        get_errors_water_meter: function() {
            return this.$store.getters.get_errors_water_meter
        },
        all_terminals: function() {
            return this.$store.getters.all_terminals
        },
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_terminal() {
            let water_meter = { 
                name: this.name,
                id_terminal: this.id_terminal
            }
            
            await this.$store.dispatch('create_water_meter', water_meter)
            this.water_meter_errors = this.get_errors_water_meter
            if(Object.keys(this.water_meter_errors).length === 0){
                this.toggleDialog();
                this.resetForm();
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

            let res = await this.add_terminal()
            if(res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Terminal added', life: 3000});
            }
            
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }

        },
        resetForm() {
            this.name = '';
            this.id_terminal = '';
            this.submitted = false;
        }
    },
    validations() {
        return {
            name: { required },
            id_terminal: { required }
        }
    },
};
</script>

<style scoped>
</style>