import axios from "axios"
import router from '@/router/index.js'

const uninterceptedAxiosInstance = axios.create();


axios.interceptors.request.use(function (config) {
    const token = 'Bearer '+ localStorage.getItem('token');
    config.headers.Authorization =  token;
    config.headers.common['Content-Type'] = 'multipart/form-data'

    return config;
});

axios.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        if(err.response) {
            if(err.response.data.code == 'user_inactive') {
                localStorage.clear()
                router.push('/')
                return new Promise(() => {});
            }
        }
        const originalConfig = err.config;
        if(originalConfig.url === process.env.VUE_APP_API_URL + 'token/refresh/') {
            localStorage.clear()
            router.push('/')
        }
        if (err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await refreshToken();
                    const { accessToken } = rs.data;
                    axios.defaults.headers.Authorization = 'Bearer ' + accessToken;
                    return axios(originalConfig);
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }
                    return Promise.reject(_error);
                }
            }
            if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }
        return Promise.reject(err);
    }
);


export const userService = {
    login
};

async function login(user) {
  
    let lg =  await uninterceptedAxiosInstance.post(process.env.VUE_APP_API_URL + 'token/', user)
        .then(token => {
            // login successful if there's a jwt token in the response
            if (token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', token.data.access);
                localStorage.setItem('refresh', token.data.refresh);
                localStorage.setItem('refresh_expire_date', token.data.refresh_expire_date);
            }
            return token;
        })

    await axios.get(process.env.VUE_APP_API_URL + 'users/username/' + user.username + '/')
        .then((response) => {
            
            localStorage.setItem('user_port', response.data.profile.id_port.name)
            localStorage.setItem('primary_color', response.data.profile.id_port.primary_color)
        })

    return lg;
}

function refreshToken() {
  const token = localStorage.getItem('refresh') 
  return axios.post(process.env.VUE_APP_API_URL + 'token/refresh/', { refresh : token } )
      .then(token => {
          // login successful if there's a jwt token in the response
          if (token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('token', token.data.access);
              
          }

          return token;
      })
}
