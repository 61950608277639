<template>  
    <Toast/>
    <div v-if="load==true" style="height: 100vh;display: flex;flex-flow: column;">
        <div class="main height_responsive" >
            <div v-if="this.$route.path !== '/'" id="menu" :style="{'visibility': visibility_menu}" style="z-index: 10;width: 100%;">
                <TopMenuHeader/> 
            </div>
        </div>
        <ScrollPanel style="height:200px;flex: 1 1 auto;">
            <router-view v-cloak/>
        </ScrollPanel>
    </div>
</template>

<script>
import TopMenuHeader from '@/components/MenuTop/TopMenuHeader.vue'
import ScrollPanel from 'primevue/scrollpanel'
import Toast from 'primevue/toast'
import moment from 'moment'


export default {
    components: {
        TopMenuHeader,
        ScrollPanel,
        Toast
    },
    data() {
        return {
            user: '',
            color: localStorage.getItem('primary_color'),
            load: false,
            visibility_menu: 'display',
       
        }
    },
    async mounted() {
        // Descomentado para poder cargar los permisos correctamente (a veces se realizan multiples peticiones con este codigo)
        if(this.$route.name != 'Login' && localStorage.getItem('token') != null) {
            await this.$store.dispatch('load_perms')
            this.$ability.update(this.get_perms)
        } 

        this.load = true
        this.$primevue.config.locale.firstDayOfWeek = 1
        window.addEventListener(
            "orientationchange",
            this.handleOrientationChange
        )
        this.iniciarContador();
    },
    methods: {
        handleOrientationChange() {
            const orientation = window.screen.orientation.type
            if (orientation === "portrait-primary") {
                if(window.screen.height < 800) {
                    //this.visibility_menu = 'visible'
                }
            } else if (orientation === "landscape-primary") {
                if(window.screen.height < 800) {
                    //this.visibility_menu = 'hidden'
                }
                
            }
        },
        iniciarContador() {
            setInterval(() => {

                var date = localStorage.getItem('refresh_expire_date')

                if(date) {
                    var exp_date = moment(date)
                
                    if(moment(Date()).isSameOrAfter(exp_date))  {
                        
                        localStorage.removeItem('user')
                        localStorage.removeItem('token')
                        localStorage.removeItem('refresh')
                        localStorage.removeItem('refresh_expire_date')
                        localStorage.removeItem('user_port')
                        this.$ability.update([])
                        this.$router.push('/')

                        this.$toast.add({severity:'warn', summary: 'Warning', detail: 'Session closed. Please log in again to continue.', life: 3000});
                        
                    }
                }

            }, 5000);
        }
    },
    computed: {
        get_perms: function() {
            return this.$store.getters.get_perms
        },
    },
    
    watch:{
        async $route (){
            if(this.$route.name != 'Login') {
                await this.$store.dispatch('load_perms')
                this.$ability.update(this.get_perms)
            } 
        },
    },
    
}
</script>


<style scoped>

@import url(https://fonts.googleapis.com/css?family=Calibri);

body{
   font-family: 'Calibri' !important;
   overflow: hidden;
}

body {
    margin: 0;    
}

#menu {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: graycall;
    text-align: center;
    color: #2c3e50;
}

.main{
    max-height: -webkit-fill-available;
}



:deep(.p-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content){
    padding: 0;
}


@media (max-width: 800px) {
    :deep(.p-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content){
        width: 100%;
    }
}

:deep(.p-scrollpanel .p-scrollpanel-bar) {
    background-color: rgba(0,0,0,0);
    transition: rgba(0,0,0,0) .2s;
}    

:deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
    background-color: rgba(0,0,0,0);
}


</style>
