import axios from 'axios'

const state = {
    fingers: [],
    finger_errors:{},
    delete_status:false
}

const mutations = {
    SET_FINGERS(state, fingers) {
        state.fingers = fingers
    },
    NEW_FINGER(state, finger) {  
        state.fingers.push(finger)
        state.finger_errors = {}
    },
    DELETE_FINGER(state, id) {
        let index = state.fingers.findIndex(fingers => fingers.id == id)
        state.fingers.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_FINGER(state, finger) {
        state.fingers = state.fingers.map(updated_finger => {
            if(updated_finger.id === finger.id) {
                updated_finger.name = finger.name
            }
            return updated_finger
        })
        state.finger_errors = {}
    },
    ERRORS_FINGER(state, errors){
        state.finger_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
}

const actions = {
    async load_all_fingers({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "fingers/")
            .then((response) => {
                commit('SET_FINGERS', response.data)
            })
    },
    async create_finger({commit}, finger) {
        await axios.post(process.env.VUE_APP_API_URL  + 'fingers/', finger)
            .then((response) => {
                commit('NEW_FINGER', response.data)
            })
            .catch((error) => {
                
                commit('ERRORS_FINGER', error.response.data)
            })
    },
    delete_finger({commit}, id) {
        axios.delete(process.env.VUE_APP_API_URL + 'fingers/' + id)
            .then(() => {
                commit('DELETE_FINGER', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async update_finger({commit}, finger){
        await axios.put(process.env.VUE_APP_API_URL  + 'fingers/' + finger.id + '/',  { name: finger.name })
            .then(() => {
                commit('UPDATE_FINGER', finger)
            })
            .catch((error) => {
                commit('ERRORS_FINGER', error.response.data)
            })
    },
}

const getters = {
    all_fingers: (state) => state.fingers,
    get_finger_errors:(state) => state.finger_errors,
    get_delete_finger_status:(state) => state.delete_status
}

export default {
    state,
    mutations,
    actions,
    getters
};