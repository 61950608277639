<template>
    <ModalIncidence_reports />
    <TableIncidence_reports />
</template>

<script>
import TableIncidence_reports from '@/components/AdminDashboard/Incidence_reports/TableIncidence_reports.vue'
import ModalIncidence_reports from '@/components/AdminDashboard/Incidence_reports/ModalIncidence_reports.vue'
export default {
  name: 'Incidence_reports',
  components: {
    TableIncidence_reports,
    ModalIncidence_reports
  }
}
</script>

<style scoped>
</style>