import axios from 'axios'

const state = {
    suppliers: [],
    supplier_name: {},
    supplier_service: [],
    supplier_service_responsible: [],
    supplier_errors:{},
    delete_status:false
}

const mutations = {
    SET_PROVIDERS(state, suppliers) {
        state.suppliers = suppliers
    },
    SET_PROVIDER_NAME(state, supplier) {
        state.supplier_name = supplier
    },
    SET_SUPPLIER_SERVICE_BY_PORT(state, supplier_service) {
        state.supplier_service = supplier_service
    },
    SET_SUPPLIER_SERVICE_RESPONSIBLES(state, supplier_service_responsible) {
        state.supplier_service_responsible = supplier_service_responsible
    },
    NEW_PROVIDER(state, supplier) {  
        state.suppliers.push(supplier)
        state.supplier_errors = {}
    },
    NEW_SUPPLIER_SERVICE(state, supplier_service) {
        state.supplier_service.push(supplier_service)
    },
    DELETE_PROVIDER(state, id) {
        let index = state.suppliers.findIndex(suppliers => suppliers.id == id)
        state.suppliers.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_PROVIDER(state, supplier) {
        state.suppliers = state.suppliers.map(updated_supplier => {
            if(updated_supplier.id === supplier.id) {
                updated_supplier.name = supplier.name
                updated_supplier.email = supplier.email
                updated_supplier.phone = supplier.phone         
                updated_supplier.ccc = supplier.ccc
                updated_supplier.cif = supplier.cif
                updated_supplier.fiscal_address = supplier.fiscal_address
                updated_supplier.cp = supplier.cp
                updated_supplier.country_code = supplier.country_code
                updated_supplier.bic_entity = supplier.bic_entity
                updated_supplier.iban = supplier.iban
            }
            return updated_supplier
        })
        state.supplier_errors = {}
    },
    UPDATE_SUPPLIER_SERVICE(state, supplier_service) {
        state.supplier_service = state.supplier_service.map(updated_supplier_service => {
            if(updated_supplier_service.id === supplier_service.id) {
                updated_supplier_service.id_supplier = supplier_service.id_supplier
                updated_supplier_service.id_supplier_responsible = supplier_service.id_supplier_responsible         
            }
            return updated_supplier_service
        })
    },
    ERRORS_PROVIDER(state, errors){
        state.supplier_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
}

const actions = {
    async load_all_suppliers({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "suppliers/")
            .then((response) => {
                commit('SET_PROVIDERS', response.data)
            })
    },
    async load_suppliers_name({commit}, name) {
        await axios.get(process.env.VUE_APP_API_URL  + "suppliers/get_by_name/" + name + '/')
            .then((response) => {
                commit('SET_PROVIDER_NAME', response.data)
            })
    },
    async load_port_supplier_service({commit}){
        await axios.get(process.env.VUE_APP_API_URL + "supplier_service/")
            .then((response) => {
                commit('SET_SUPPLIER_SERVICE_BY_PORT', response.data)
            })
    },
    async load_supplier_service_responsible({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "supplier_responsible/get_responsibles/" + id + '/')
            .then((response) => {
                commit('SET_SUPPLIER_SERVICE_RESPONSIBLES', response.data)
            })
    },
    async create_supplier({commit}, supplier) {
        await axios.post(process.env.VUE_APP_API_URL  + 'suppliers/', { name: supplier.name, email: supplier.email, phone: supplier.phone, cif:supplier.cif, ccc:supplier.ccc, fiscal_address:supplier.fiscal_address, country_code: supplier.country_code, bic_entity: supplier.bic_entity, cp: supplier.cp, iban: supplier.iban })
            .then((response) => {
                commit('NEW_PROVIDER', response.data)
            })
            .catch((error) => {
                commit('ERRORS_PROVIDER', error.response.data)
            })
    },
    async create_supplier_service({commit}, supplier_service) {
        await axios.post(process.env.VUE_APP_API_URL + 'supplier_service/' , supplier_service)
            .then((response) => {
                commit('NEW_SUPPLIER_SERVICE', response.data)
            })
    },
    async delete_supplier({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'suppliers/' + id)
            .then(() => {
                commit('DELETE_PROVIDER', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async update_supplier({commit}, supplier) {
        await axios.put(process.env.VUE_APP_API_URL  + 'suppliers/' + supplier.id + '/',  { name: supplier.name, email: supplier.email, phone: supplier.phone, cif:supplier.cif, ccc:supplier.ccc, fiscal_address:supplier.fiscal_address, country_code: supplier.country_code, cp: supplier.cp, bic_entity: supplier.bic_entity, iban: supplier.iban })
            .then(() => {
                commit('UPDATE_PROVIDER', supplier)
            })
            .catch((error) => {
                commit('ERRORS_PROVIDER', error.response.data)
            })
    },
    async update_supplier_service({commit}, supplier_service) {
        await axios.patch(process.env.VUE_APP_API_URL + 'supplier_service/' + supplier_service.id + '/', {
            id_supplier: supplier_service.id_supplier,
            id_supplier_responsible: supplier_service.id_supplier_responsible.id
        })
            .then((response) => {
                commit('UPDATE_SUPPLIER_SERVICE', response.data)
            })
    }
}

const getters = {
    all_suppliers: (state) => state.suppliers,
    get_supplier_name: (state) => state.supplier_name,
    get_supplier_service: (state) => state.supplier_service,
    get_supplier_service_responsible: (state) => state.supplier_service_responsible,
    get_supplier_errors: (state) => state.supplier_errors,
    get_delete_supplier_status:(state) => state.delete_status
}

export default {
    state,
    mutations,
    actions,
    getters
};