import axios from 'axios'

const state = {
    task_categories: [],
    task_category_errors: {},
    delete_status : false,
    task_category_names : []
};

const mutations = {
    SET_TASK_CATEGORIES(state, task_categories) {
        state.task_categories = task_categories
    },
    SET_TASK_CATEGORY_NAMES(state, task_category_names) {
        state.task_category_names = task_category_names
    },
    NEW_TASK_CATEGORY(state, task_category) {  
        state.task_categories.push(task_category)
        state.task_category_errors = {}
    },
    DELETE_TASK_CATEGORY(state, id) {
        let index = state.task_categories.findIndex(task_categories => task_categories.id == id)
        state.task_categories.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_TASK_CATEGORY(state, task_category) {
        state.task_categories = state.task_categories.map(updated_task_category => {
            if(updated_task_category.id === task_category.id) {
                updated_task_category.name = task_category.name
            }
            return updated_task_category
        })
        state.task_category_errors = {}
    },
    ERRORS_TASK_CATEGORY(state, errors){
        state.task_category_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    }
};

const actions = {
    async load_all_task_categories({commit}) {
        await axios.get(process.env.VUE_APP_API_URL  + "task_categories/")
            .then((response) => {
                commit('SET_TASK_CATEGORIES', response.data)
            })
    },
    async load_task_category_names({commit}) {
        let names = []
        await axios.get(process.env.VUE_APP_API_URL  + "task_categories/")
            .then((response) => {
                response.data.forEach(element => {
                    names.push(element.name)
                });
                commit('SET_TASK_CATEGORY_NAMES', names)
            })
    },
    async create_task_category({commit}, task_category) {
        await axios.post(process.env.VUE_APP_API_URL  + 'task_categories/', task_category)
            .then((response) => {
                commit('NEW_TASK_CATEGORY', response.data)
            })
            .catch((error) => {
                commit('ERRORS_TASK_CATEGORY', error.response.data)
            })
    },
    async delete_task_category({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'task_categories/' + id)
            .then(() => {
                commit('DELETE_TASK_CATEGORY', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async update_task_category({commit}, task_category) {
        await axios.put(process.env.VUE_APP_API_URL  + 'task_categories/' + task_category.id + '/',  { name: task_category.name })
            .then(() => { 
                commit('UPDATE_TASK_CATEGORY', task_category)
            })
            .catch((error) => {
                commit('ERRORS_TASK_CATEGORY', error.response.data)
            })
    },
};

const getters = {
    all_task_categories: (state) => state.task_categories,
    get_errors_task_category: (state) => state.task_category_errors,
    get_delete_task_category_status:(state) => state.delete_status,
    get_task_category_names:(state) => state.task_category_names,
    get_task_category_by_name:(state) => (name) => {
        return state.task_categories.filter(task_category => task_category.name === name)
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};