<template>
  <div class="container">
    <span class="no-carousel">
      <div v-if="get_current_calls.length > 0" class="row justify-content-center">
        <div data-testid="rendered_calls" v-for="ship in get_current_calls" :key="ship" class="col-12 md:col-6 lg:col-6 xl:col-4" id="current-calls">
          <CurrentCallCard data-testid="call" :call="ship"/>
        </div>
      </div>
    </span>
    <span class="carousel">
      <div v-if="get_current_calls.length > 0" class="row justify-content-center">
        <div v-if="get_current_calls.length == 1" data-testid="rendered_calls"  class="col-5">
          <Carousel :value="get_current_calls" :numVisible="3" :numScroll="3" :responsiveOptions="responsiveOptions">
            <template #item="slotProps">
              <CurrentCallCard data-testid="call" :call="slotProps.data"/>
            </template>
          </Carousel>
        </div>
        <div v-else data-testid="rendered_calls">
          <Carousel :value="get_current_calls" :numVisible="3" :numScroll="3" :responsiveOptions="responsiveOptions">
            <template #item="slotProps">
              <CurrentCallCard data-testid="call" :call="slotProps.data"/>
            </template>
          </Carousel>
        </div>
      </div>
    </span>
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <div class="card">
          <div class="ratio ratio-16x9"> 
            <iframe v-if="get_user_port.name=='Port de Barcelona'" name="marinetraffic" style="border-radius: 10px;" id="marinetraffic" scrolling="no" frameborder="1" src="https://www.marinetraffic.com/en/ais/embed/zoom:14/centery:41.3659/centerx:002.1809/maptype:0/shownames:true/mmsi:0/shipid:0/fleet:/fleet_id:/vtypes:Passenger_Vessels/showmenu:true/remember:false">Browser does not support embedded objects.&lt;br/&gt;Visit directly &lt;a href="http://www.marinetraffic.com/"&gt;www.marinetraffic.com&lt;/a&gt;</iframe>
            <iframe v-if="get_user_port.name=='Puerto de Málaga'" name="marinetraffic" style="border-radius: 10px;" id="marinetraffic" scrolling="no" frameborder="1" src="https://www.marinetraffic.com/en/ais/embed/zoom:14/centery:36.7115/centerx:-004.4150/maptype:0/shownames:true/mmsi:0/shipid:0/fleet:/fleet_id:/vtypes:Passenger_Vessels/showmenu:true/remember:false">Browser does not support embedded objects.&lt;br/&gt;Visit directly &lt;a href="http://www.marinetraffic.com/"&gt;www.marinetraffic.com&lt;/a&gt;</iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from 'primevue/carousel'

import CurrentCallCard from '../components/Home/Current_calls_card.vue'

export default {
    name: 'Home',
    data() {
        return {
            current_calls: [],
            color: localStorage.getItem('primary_color'),
        }
    },
    components: {
        Carousel,
        CurrentCallCard
    },
    computed: {
        get_current_calls: function() {
            return this.$store.getters.current_calls
        },
        get_user_port() {
            return this.$store.getters.get_user_port;
        }
    },
    async mounted() {
        await this.$store.dispatch('load_current_calls')

    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
    margin: 1rem;
}

.card-img-top {
    max-height: 200px;
    min-height: 200px;
    object-fit: cover;
}

@media (min-width: 1200px) {
  .no-carousel {
    display: none;
  }
}

@media (max-width: 1199px){
  .carousel {
    display: none;
  }
}

.pointer{
  cursor: pointer !important;
}

</style>