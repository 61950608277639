<template>
    <div class="container p-fluid">
        <div class="row">
            <Divider align="center">
                <div class="inline-flex align-items-center">
                    <b> Schedule </b>
                </div>
            </Divider>
            <div class="col-12 xl:col-6">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <Calendar showButtonBar autocomplete="off" id="arrival_date" dateFormat="yy-mm-dd" v-model="new_service_sheet.arrival_datetime" @date-select="change_final()" @change="change_final()" :disabled="!this.new_service_sheet.main || (!$ability.can('change','service_sheet') && !check_user_service_operator) || is_disabled"/>
                        <label for="arrival_date"> Arrival Date </label>
                    </span>
                </div>
            </div>
            <div class="col-12 xl:col-6">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <Calendar autocomplete="off" id="departure_date" dateFormat="yy-mm-dd" :timeOnly="true" hourFormat="24" v-model="new_service_sheet.arrival_datetime_hour" @date-select="change_final()" @change="change_final(); new_service_sheet.arrival_datetime_hour = num2time($event)" :disabled="!this.new_service_sheet.main || (!$ability.can('change','service_sheet') && !check_user_service_operator) || is_disabled"/>
                        <label for="departure_date"> Hour </label>
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 xl:col-6">
                <div class="col-12 pt-2">
                    <span class="p-float-label">
                        <Calendar showButtonBar autocomplete="off" id="arrival_date" dateFormat="yy-mm-dd"  v-model="new_service_sheet.departure_datetime" @date-select="change_final()" @change="change_final()" :disabled="!this.new_service_sheet.main || (!$ability.can('change','service_sheet') && !check_user_service_operator) || is_disabled"/>
                        <label for="arrival_date"> Departure Date</label>
                    </span>
                </div>
            </div>
            <div class="col-12 xl:col-6">
                <div class="col-12 pt-2">
                    <span class="p-float-label">
                        <Calendar autocomplete="off" id="departure_date" dateFormat="yy-mm-dd" :timeOnly="true" hourFormat="24" v-model="new_service_sheet.departure_datetime_hour" @date-select="change_final()" @change="change_final(); new_service_sheet.departure_datetime_hour = num2time($event)" :disabled="!this.new_service_sheet.main || (!$ability.can('change','service_sheet') && !check_user_service_operator) || is_disabled"/>
                        <label for="departure_date"> Hour </label>
                    </span>
                </div>
            </div>
        </div>
        <div v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.estimated_arrival_datetime" class="row pt-3 text-center">
            <small v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.estimated_arrival_datetime" class="p-error"><span v-for="error in errors_service_sheet_final.estimated_arrival_datetime" :key="error">{{error}}<br></span></small> 
        </div>
        <div class="row">
            <Divider align="center">
                <div class="inline-flex align-items-center">
                    <b> Passengers </b>
                </div>
            </Divider>
            <div class="col-12 xl:col-4 ">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.p_disembarking" id="p_disembarking" v-model="new_service_sheet.p_disembarking" @input="change_final()" :disabled="!this.new_service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/> 
                        <label for="p_disembarking"> Disembarking </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.p_disembarking" class="p-error"><span v-for="error in errors_service_sheet_final.p_disembarking" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.p_embarking" id="p_embarking" v-model="new_service_sheet.p_embarking" @input="change_final()" :disabled="!this.new_service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/> 
                        <label for="p_embarking"> Embarking </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.p_embarking" class="p-error"><span v-for="error in errors_service_sheet_final.p_embarking" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 xl:col-4 ">
                <div class="col-12 pt-4">
                    <span class="p-float-label"> 
                        <InputNumber :placeholder="get_placeholders.p_transit" id="p_transit" v-model="new_service_sheet.p_transit" @input="change_final()" :disabled="!this.new_service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/> 
                        <label for="p_transit"> Transit </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.p_transit" class="p-error"><span v-for="error in errors_service_sheet_final.p_transit" :key="error">{{error}}<br></span></small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="text-center">
                <b v-if="user_port=='Port de Barcelona'" style="color: black;"> Night </b>
                <b v-else-if="user_port=='Puerto de Málaga'" style="color: black;"> 15h </b>
            </div>
            <div class="col-12 xl:col-4 ">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.disembarking_extra" id="p_disembarking" v-model="new_service_sheet.disembarking_extra" @input="change_final()" :disabled="!this.new_service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/> 
                        <label for="p_disembarking"> Disembarking </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.disembarking_extra" class="p-error"><span v-for="error in errors_service_sheet_final.disembarking_extra" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.embarking_extra" id="p_embarking" v-model="new_service_sheet.embarking_extra" @input="change_final()" :disabled="!this.new_service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/> 
                        <label for="p_embarking"> Embarking </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.embarking_extra" class="p-error"><span v-for="error in errors_service_sheet_final.embarking_extra" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 xl:col-4 ">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.transit_extra" id="p_transit" v-model="new_service_sheet.transit_extra" @input="change_final()" :disabled="!this.new_service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/> 
                        <label for="p_transit"> Transit </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.transit_extra" class="p-error"><span v-for="error in errors_service_sheet_final.transit_extra" :key="error">{{error}}<br></span></small>
                </div>
            </div>
        </div>
        <div v-if="user_port=='Port de Barcelona'" class="row">
            <Divider align="center">
                <div class="inline-flex align-items-center">
                    <b> Crew </b>
                </div>
            </Divider>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.c_disembarking" id="c_disembarking" v-model="new_service_sheet.c_disembarking" @input="change_final()" :disabled="!this.new_service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/> 
                        <label for="c_disembarking"> Disembarking </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.c_disembarking" class="p-error"><span v-for="error in errors_service_sheet_final.c_disembarking" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.c_embarking" id="c_embarking" v-model="new_service_sheet.c_embarking" @input="change_final()" :disabled="!this.new_service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/> 
                        <label for="c_embarking"> Embarking </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.c_embarking" class="p-error"><span v-for="error in errors_service_sheet_final.c_embarking" :key="error">{{error}}<br></span></small>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <InputNumber :placeholder="get_placeholders.c_transit" id="c_transit" v-model="new_service_sheet.c_transit" @input="change_final()" :disabled="!this.new_service_sheet.main || !$ability.can('change','service_sheet') || is_disabled"/>
                        <label for="c_transit"> Transit </label>
                    </span>
                    <small v-if="Object.keys(errors_service_sheet_final).length !== 0 && errors_service_sheet_final.c_transit" class="p-error"><span v-for="error in errors_service_sheet_final.c_transit" :key="error">{{error}}<br></span></small>
                </div>
            </div>
        </div> 
    </div>
    <div v-if="button_confirm_final && this.new_service_sheet.main" class="col text-center pt-3">
        <Button label="Save" icon="pi pi-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_final()"/>
    </div>
</template>

<script>
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Divider from 'primevue/divider'
import Button from 'primevue/button'
import moment from 'moment'

import { num2time, num2timeedit } from '@/modules/utilities.js'

export default {
    name: 'Final',
    components: {
        Calendar,
        InputNumber,
        Divider,
        Button
    },
    props: {
        service_sheet: {
            type: Object,
            required: true
        },
        user_port: {
            type: String,
            required: true
        },
        get_placeholders: {
            type: Object
        }
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            new_service_sheet: JSON.parse(JSON.stringify(this.service_sheet)),
            warn_fields: ['arrival_datetime', 'arrival_datetime_hour', 'departure_datetime', 'departure_datetime_hour'],
            date_fields: ['arrival_datetime','departure_datetime'],
            hour_fields: ['arrival_datetime_hour', 'departure_datetime_hour'],
            errors_service_sheet_final: '',
            is_on_hold_final: false,
            button_confirm_final: false,
            final_collapsed: true,
        }
    },
    computed: {
        get_errors_service_sheet_final: function() {
            return this.$store.getters.get_errors_service_sheet_final
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Ops') {
                    return true
                }
            }
            return false
        },
        check_user_admins: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Admins') {
                    return true
                }
            }
            return false
        },
        // Para editar una hoja de servicio despues de ser validada / confirmada, se tiene que ser Admin.
        is_disabled: function() {
            if(this.service_sheet.confirmed && !this.check_user_admins) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        change_final() {
            this.is_on_hold_final = true
            this.button_confirm_final = true
        }, 
        modifyTimeFields(form) {

            const dateHourPairs = [
                { date: 'arrival_datetime', hour: 'arrival_datetime_hour' },
                { date: 'departure_datetime', hour: 'departure_datetime_hour' }
            ];

            let departureChanged = false;
            let arrivalChanged = false;
            
            // Itera sobre el formulario para ajustar los campos de fecha y hora
            for (const key in form) {
                if (this.date_fields.includes(key)) {
                    const formattedDate = moment(form[key]).format().split('T')[0]
                    if (this.service_sheet[key] !== formattedDate) {
                        form[key] = formattedDate;
                        if (key === 'departure_datetime') {
                            departureChanged = true
                        }
                        if (key === 'arrival_datetime') {
                            arrivalChanged = true
                        }
                    }
                } else if (this.hour_fields.includes(key)) {
                    if (form[key]) {
                        if (form[key].length > 7) {
                            const formattedHour = moment(form[key]).format().split('T')[1].split(':')[0] + ':' + form[key].split('T')[1].split(':')[1];
                            if (this.service_sheet[key] !== formattedHour) {
                                form[key] = formattedHour;
                                if (key === 'departure_datetime_hour') {
                                    departureChanged = true
                                } 
                                if (key === 'arrival_datetime_hour') {
                                    arrivalChanged = true
                                }
                            }
                        } else {
                            if (this.service_sheet[key] !== form[key]) {
                                if (key === 'departure_datetime_hour') {
                                    departureChanged = true
                                } 
                                if (key === 'arrival_datetime_hour') {
                                    arrivalChanged = true
                                }
                            }
                        }
                    }
                }
            }

            // Se asegura de que ambos campos se mantengan juntos
            dateHourPairs.forEach(pair => {
                const dateChanged = this.service_sheet[pair.date] !== form[pair.date];
                const hourChanged = this.service_sheet[pair.hour] !== form[pair.hour];

                if (dateChanged || hourChanged) {
                    if (!Object.prototype.hasOwnProperty.call(form, pair.date) && Object.prototype.hasOwnProperty.call(form, pair.hour)) {
                        form[pair.date] = moment(this.service_sheet[pair.date]).format().split('T')[0];
                    } else if (Object.prototype.hasOwnProperty.call(form, pair.date) && !Object.prototype.hasOwnProperty.call(form, pair.hour)) {
                        form[pair.hour] = this.service_sheet[pair.hour];
                    }
                }
            });

            // Determina si hubo cambios en arrival o departure
            if (departureChanged || arrivalChanged) {
                if (departureChanged) {
                    if (!Object.prototype.hasOwnProperty.call(form, 'arrival_datetime')) {
                        form['arrival_datetime'] = moment(this.service_sheet['arrival_datetime']).format().split('T')[0];
                    }
                    if (!Object.prototype.hasOwnProperty.call(form, 'arrival_datetime_hour')) {
                        form['arrival_datetime_hour'] = this.service_sheet['arrival_datetime_hour'];
                    }
                } else if (arrivalChanged) {
                    if (!Object.prototype.hasOwnProperty.call(form, 'departure_datetime')) {
                        form['departure_datetime'] = moment(this.service_sheet['departure_datetime']).format().split('T')[0];
                    }
                    if (!Object.prototype.hasOwnProperty.call(form, 'departure_datetime_hour')) {
                        form['departure_datetime_hour'] = this.service_sheet['departure_datetime_hour'];
                    }
                }
            } else {
                // Si no hubo cambios en arrival ni departure, elimina ambos campos del formulario
                dateHourPairs.forEach(pair => {
                    delete form[pair.date];
                    delete form[pair.hour];
                });
            }
                
            return form;
        },
        // Function called when the button save from estimation section is clicked
        async save_changes_final() {
            if((this.new_service_sheet.departure_datetime == null ^ this.new_service_sheet.departure_datetime_hour == null ^ this.new_service_sheet.departure_datetime == '' ^ this.new_service_sheet.departure_datetime_hour == '') || (this.new_service_sheet.arrival_datetime_hour == null ^ this.new_service_sheet.arrival_datetime == null ^ this.new_service_sheet.arrival_datetime_hour == '' ^ this.new_service_sheet.arrival_datetime == '')) { // XOR OPERATOR
                this.new_service_sheet.departure_datetime = null
                this.new_service_sheet.departure_datetime_hour = null
                this.new_service_sheet.arrival_datetime_hour = null
                this.new_service_sheet.arrival_datetime = null
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'warn', summary: 'Warning', detail:'Date not assigned correctly', life: 3000});
            } else {

                // Esta funcion modifica los campos de fecha y hora si han habido modificaciones.
                const final_form = JSON.parse(JSON.stringify(this.new_service_sheet))
                    
                this.final_form = this.modifyTimeFields(final_form)

                await this.$store.dispatch('patch_service_sheet_final', this.final_form)
                this.errors_service_sheet_final = this.get_errors_service_sheet_final

                if(Object.keys(this.errors_service_sheet_final).length === 0) {
                    this.button_confirm_final = false
                    this.is_on_hold_final = false
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity: 'success', summary: 'Successful', detail:'Data Modified', life: 3000});
                }
            }
            
        },
        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
    }
}
</script>

<style scoped>
.on_hold{
    border: 4px solid #002D72;
    border-radius: 10px;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}


:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

.p-divider.p-divider-horizontal {
  color: v-bind(color);
}


:deep(input:focus::placeholder) {
    opacity: 1;
}
</style>