import axios from 'axios'
import moment from 'moment';

const state = {
    cleaning_service: {},
    cleaning_service_shifts: [],
    send_cleaning_mail: false,
    error_cleaning_service_shifts: [],
    past_cleaning:[]
}

const mutations = {
	SET_CLEANING_SERVICE(state, cleaning_service) {
        state.cleaning_service = cleaning_service
    },
    SET_CLEANING_SERVICE_SHIFTS(state, cleaning_service) {
        state.cleaning_service_shifts = cleaning_service
    },
    ADD_CLEANING_SERVICE(state, cleaning_service) {
        state.cleaning_service = cleaning_service
    },
    SET_PAST_CLEANING(state, past_cleaning){
        state.past_cleaning=past_cleaning
    },
    ADD_CLEANING_SERVICE_SHIFTS(state, cleaning_service) {
        cleaning_service.start_datetime_hour =  cleaning_service.start_datetime.split('T')[1].split(':')[0] + ':' +  cleaning_service.start_datetime.split('T')[1].split(':')[1]
        cleaning_service.start_datetime =  cleaning_service.start_datetime.split('T')[0]
        cleaning_service.end_datetime_hour =  cleaning_service.end_datetime.split('T')[1].split(':')[0] + ':' +  cleaning_service.end_datetime.split('T')[1].split(':')[1]
        cleaning_service.end_datetime =  cleaning_service.end_datetime.split('T')[0]
        state.cleaning_service_shifts.push(cleaning_service)
    },
    SET_ERROR_CLEANING_SERVICE_SHIFTS(state, error_cleaning_service_shifts) {
        state.error_cleaning_service_shifts = []
        state.error_cleaning_service_shifts.push(error_cleaning_service_shifts)
    },
    UPDATE_CLEANING_SERVICE(state, cleaning_service) {
        state.cleaning_service = state.cleaning_service.map(updated_cleaning_service => {
            if(updated_cleaning_service.id === cleaning_service.id) {
                updated_cleaning_service.id_supplier = cleaning_service.id_supplier
                updated_cleaning_service.id_supplier_responsible = cleaning_service.id_supplier_responsible
                updated_cleaning_service.mail_text = cleaning_service.mail_text
                updated_cleaning_service.note_info = cleaning_service.note_info
            }
            return updated_cleaning_service
        })
    },
    UPDATE_CLEANING_SERVICE_SHIFTS(state, cleaning_service_shifts) {
        state.cleaning_service_shifts = state.cleaning_service_shifts.map(updated_cleaning_service_shifts => {
            if(updated_cleaning_service_shifts.id === cleaning_service_shifts.id) {
                updated_cleaning_service_shifts.start_datetime_hour = cleaning_service_shifts.start_datetime.split('T')[1].split(':')[0]+ ':' +  cleaning_service_shifts.start_datetime.split('T')[1].split(':')[1]
                updated_cleaning_service_shifts.start_datetime = cleaning_service_shifts.start_datetime.split('T')[0]
                updated_cleaning_service_shifts.end_datetime_hour = cleaning_service_shifts.end_datetime.split('T')[1].split(':')[0]+ ':' +cleaning_service_shifts.end_datetime.split('T')[1].split(':')[1]
                updated_cleaning_service_shifts.end_datetime = cleaning_service_shifts.end_datetime.split('T')[0]
                updated_cleaning_service_shifts.quantity = cleaning_service_shifts.quantity
            }
            return updated_cleaning_service_shifts
        })
    },
    DELETE_CLEANING(state, id){
        let index = state.cleaning_service.findIndex(cleaning_service => cleaning_service.id == id)
        state.cleaning_service.splice(index, 1)
        state.cleaning_service_shifts = []
    },
    DELETE_CLEANING_SERVICE(state, id) {
        let index = state.cleaning_service_shifts.findIndex(cleaning_service_shifts => cleaning_service_shifts.id == id)
        state.cleaning_service_shifts.splice(index, 1)
    },
    UPDATE_MAIL_STATUS_CLEANING(state, cond) {
        if(cond == true) {
            state.send_cleaning_mail = true    
        }
    },
    UPDATE_STATUS_MAIL_CLEANING(state) {
        state.send_cleaning_mail = false
    },
    SET_ERROR_CLEANING(state, error) {
        state.error_cleaning_service_shifts = error
    },
    CLEAN_ERROR_CLEANING(state) {
        state.error_cleaning_service_shifts = []
    },
    EMPTY_CLEANING_SHIFTS(state) {
        state.cleaning_service_shifts = []
    }
}

const actions = {
	async load_cleaning_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'cleaning/get_service/' + id + '/') 
			.then((response) => {
				commit('SET_CLEANING_SERVICE', response.data)
			})
	},
    async load_cleaning_service_shifts({commit}, id){
        await axios.get(process.env.VUE_APP_API_URL + 'cleaning_service/service_sheet/' + id + '/')
            .then((response) => {
                commit('SET_CLEANING_SERVICE_SHIFTS', response.data)
            })
    },
    async create_past_cleaning({commit}, id_ss){
        await axios.post(process.env.VUE_APP_API_URL + 'cleaning/create_past_cleaning/' + id_ss + '/')
            .then((response) => {
                commit('SET_CLEANING_SERVICE', response.data)
            })
            .catch(() => {
                
            })
    },
    async create_cleaning_service({commit}, cleaning_service) {
        var responsible = ''

        if (cleaning_service.id_supplier_responsible) {
            responsible = cleaning_service.id_supplier_responsible.id
        }
        
        await axios.post(process.env.VUE_APP_API_URL + 'cleaning/', {
            id_service_sheet: cleaning_service.id_service_sheet,
            id_supplier_responsible: responsible,
            id_supplier: cleaning_service.id_supplier.id,
            note_info: cleaning_service.note_info,
            mail_text: cleaning_service.mail_text
        })
            .then((response) => {
                commit('ADD_CLEANING_SERVICE', response.data)
            })
    },
    async create_cleaning_service_shifts({commit}, cleaning_service) {

        if(typeof cleaning_service.start_datetime_hour === 'string') {
            cleaning_service.start_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + cleaning_service.start_datetime_hour))
        }
        var start_datetime = new Date(new Date(cleaning_service.start_datetime).getFullYear(), new Date(cleaning_service.start_datetime).getMonth(), new Date(cleaning_service.start_datetime).getDate(),
                            new Date(cleaning_service.start_datetime_hour).getHours(), new Date(cleaning_service.start_datetime_hour).getMinutes(), new Date(cleaning_service.start_datetime_hour).getSeconds())
        if(typeof cleaning_service.end_datetime_hour === 'string') {
            cleaning_service.end_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + cleaning_service.end_datetime_hour))
        }
        var end_datetime = new Date(new Date(cleaning_service.end_datetime).getFullYear(), new Date(cleaning_service.end_datetime).getMonth(), new Date(cleaning_service.end_datetime).getDate(),
                            new Date(cleaning_service.end_datetime_hour).getHours(), new Date(cleaning_service.end_datetime_hour).getMinutes(), new Date(cleaning_service.end_datetime_hour).getSeconds())                  
        
        start_datetime = moment(start_datetime).format().split('+')[0]
        end_datetime = moment(end_datetime).format().split('+')[0]

        await axios.post(process.env.VUE_APP_API_URL + 'cleaning_service/', {
            start_datetime: start_datetime,
            end_datetime: end_datetime,
            quantity: cleaning_service.quantity,
            cleaning: cleaning_service.cleaning,
            id: cleaning_service.id,
            is_estimated: cleaning_service.is_estimated
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_CLEANING', true)
                commit('ADD_CLEANING_SERVICE_SHIFTS', response.data)
                commit('CLEAN_ERROR_CLEANING')
            })
            .catch((error) => {
                commit('SET_ERROR_CLEANING_SERVICE_SHIFTS', error.response.data)
            })
    },
    async update_cleaning_service({commit}, cleaning_service) {
        var id_responsible = ''
        if(cleaning_service.id_supplier_responsible) {
            id_responsible = cleaning_service.id_supplier_responsible.id
        }
        await axios.patch(process.env.VUE_APP_API_URL + 'cleaning/' + cleaning_service.id + '/', {mail_text: cleaning_service.mail_text, note_info: cleaning_service.note_info, id_supplier_responsible: id_responsible, id_supplier: cleaning_service.id_supplier.id})
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_CLEANING', response.data.edited)
                commit('UPDATE_CLEANING_SERVICE', response.data)
            })
    },
    async update_cleaning_service_shifts ({commit}, cleaning_service) {

        if(typeof(cleaning_service.start_datetime) === 'object' || typeof(cleaning_service.start_datetime_hour) === 'object') {
            if(typeof cleaning_service.start_datetime_hour === 'string') {
                cleaning_service.start_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + cleaning_service.start_datetime_hour))
            }
            var start_datetime = new Date(new Date(cleaning_service.start_datetime).getFullYear(), new Date(cleaning_service.start_datetime).getMonth(), new Date(cleaning_service.start_datetime).getDate(),
                new Date(cleaning_service.start_datetime_hour).getHours(), new Date(cleaning_service.start_datetime_hour).getMinutes(), new Date(cleaning_service.start_datetime_hour).getSeconds())
            start_datetime = moment(start_datetime).format().split('+')[0]
        } else {
            start_datetime = cleaning_service.start_datetime + 'T' + cleaning_service.start_datetime_hour
        }
        if(typeof(cleaning_service.end_datetime) === 'object' || typeof(cleaning_service.end_datetime_hour) === 'object') {
            if(typeof cleaning_service.end_datetime_hour === 'string') {
                cleaning_service.end_datetime_hour = (new Date(moment(Date.now()).format().split('T')[0] + 'T' + cleaning_service.end_datetime_hour))
            }
            var end_datetime = new Date(new Date(cleaning_service.end_datetime).getFullYear(), new Date(cleaning_service.end_datetime).getMonth(), new Date(cleaning_service.end_datetime).getDate(),
                new Date(cleaning_service.end_datetime_hour).getHours(), new Date(cleaning_service.end_datetime_hour).getMinutes(), new Date(cleaning_service.end_datetime_hour).getSeconds())
            end_datetime = moment(end_datetime).format().split('+')[0]
        } else {
            end_datetime = cleaning_service.end_datetime + 'T' + cleaning_service.end_datetime_hour
        }

        await axios.patch(process.env.VUE_APP_API_URL + 'cleaning_service/' + cleaning_service.id + '/', {
            quantity: cleaning_service.quantity,
            start_datetime: start_datetime,
            end_datetime: end_datetime,
        })
            .then((response) => {
                commit('UPDATE_MAIL_STATUS_CLEANING', response.data.edited)
                commit('UPDATE_CLEANING_SERVICE_SHIFTS', response.data)
            })
            .catch((error) => {
                commit('SET_ERROR_CLEANING_SERVICE_SHIFTS', error.response.data)
            })
    },
    async delete_cleaning({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'cleaning/' + id + '/')
            .then(() => {
                commit('DELETE_CLEANING', id)
            }) 
            .catch((error) => {
                commit('SET_ERROR_CLEANING', error.response.data)
            })
    },
    async delete_cleaning_service({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'cleaning_service/' + id + '/')
            .then(() => {
                commit('DELETE_CLEANING_SERVICE', id)
            })
    },
    async send_cleaning_service_mail({commit}, id){
        await axios.post(process.env.VUE_APP_API_URL + 'cleaning/send_mail/' + id + '/')
            .then(() => {
                commit('UPDATE_STATUS_MAIL_CLEANING')
                commit('CLEAN_ERROR_CLEANING')
            })
            .catch((error) => {
                commit('SET_ERROR_CLEANING', error.response.data)
            })
    },
    async clean_errors_cleaning_shifts({commit}) {
        commit('CLEAN_ERROR_CLEANING')
    },
    async empty_cleaning_shifts({commit}) {
        commit('EMPTY_CLEANING_SHIFTS')
    }
}

const getters = {
    get_cleaning_service: (state) => state.cleaning_service,
    get_cleaning_service_shifts: (state) => state.cleaning_service_shifts,
    get_past_cleaning:(state) => state.past_cleaning,
    get_cleaning_service_shifts_estimated: (state) =>  {
        return state.cleaning_service_shifts.filter(item => item.is_estimated === true)
    },
    get_cleaning_service_shifts_final: (state) => {
        return state.cleaning_service_shifts.filter(item => item.is_estimated === false)
    },
    get_send_cleaning_mail: (state) => state.send_cleaning_mail,
    get_error_cleaning_service_shifts: (state) => state.error_cleaning_service_shifts
}

export default {
    state,
    mutations,
    actions,
    getters
};