<template>
    <Panel header="Fence Security" v-model:collapsed="fence_collapsed" :collapsed="true" :toggleable="true" @toggle="fill_fence_security_service($event)">
        <template #header>
            <div @click="fence_collapsed = !fence_collapsed; fill_fence_security_service($event)" class="col" style="padding: 0.7rem;">
                <div class="row"  >
                    <div class="col">
                        <span class="title_panels"> Fence Security </span>
                    </div>
                </div>
            </div>
        </template>
        <template #icons>
            <i v-if="created_automatically" v-tooltip.left="'This service was created automatically with previous data.'" class="fa-solid fa-circle-info fa-xl" style="color: #fcc203;"></i>    
            <i v-if="fence_security_service.status" class="p-panel-header-icon mr-2">
                <span v-if="fence_security_service.status === 'None'">
                    <span v-tooltip.left="'Service email not sent.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: #002d7d;"></span>
                </span>
                <span v-if="fence_security_service.status === 'Modified'">
                    <span v-tooltip.left="'Service modified after email.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: orange;"></span>
                </span>
                <span v-if="fence_security_service.status === 'On hold'">
                    <span v-tooltip.left="'Service email sent and waiting for confirmation.'" class="fa-solid fa-circle fa-xl" style="color: orange;"></span>
                </span>
                <span v-if="fence_security_service.status === 'Confirmed'">
                    <span v-tooltip.left="'Service email confirmed.'" class="fa-solid fa-circle fa-xl" style="color: #22C55E;"></span>
                </span>
                <span v-if="fence_security_service.status === 'Refused'">
                    <span v-tooltip.left="'Service email refused.'" class="fa-solid fa-circle fa-xl" style="color: #EF4444;"></span>
                </span>
            </i>
        </template>
        <div class="flex justify-content-end" >
            <div class="card p-0 mb-1">
                <Button icon="pi pi-refresh" class="p-button-text" @click="calculate_days" v-tooltip.left="'Refreshes the service, in case any days has been added or removed.'"></Button>
            </div>
        </div>
        <div class="container-fluid p-fluid">
            <div class="row">
                <TabView @tab-change="change_fence_tab($event)" v-model:activeIndex="active_index">
                    <TabPanel v-for="tab in fence_days" :key="tab" :header="tab.label" >
                        <div class="row justify-content-center mt-3">
                            <div class="col-12">
                                <div class="row d-flex">
                                    <div v-if="get_filtered_fence_by_day.length != 0 " class="col-12 md:col-2">
                                        <Button icon="pi pi-search" label="Assigned calls" @click="toggle_assigned"/>
                                    </div>
                                    <div v-if="$ability.can('add','fence_security') && get_filtered_fence_by_day.length == 0 && !check_user_service_operator" class="col-12 md:col-3 text-center my-auto">
                                        <Button v-tooltip.right="'Add new empty service to the day selected.'" label="New Service" icon="fa fa-plus" class="p-button-text p-button-raised p-button-success" @click="add_fence_service"/>
                                    </div>
                                    <div v-if="$ability.can('change','fence_security') && get_filtered_fence_by_day.length != 0 && !check_user_service_operator" class="col-12 md:col-3 ms-auto my-auto text-end">
                                        <div v-if="check_if_assigned">
                                            <Button v-tooltip.left="'Unassigns this service sheet from the current fence day.'" label="Unassign" icon="fa fa-xmark" class="p-button-text p-button-raised" @click="unassign_service_sheet_to_fence_service()"/>
                                        </div>
                                        <div v-else>
                                            <Button v-tooltip.left="'Assigns this service sheet to the current fence day. (Must be assigned if the service sheet uses this service)'" label="Assign" icon="fa fa-bookmark" class="p-button-text p-button-raised" @click="assign_service_sheet_to_fence_service()"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="get_filtered_fence_by_day.length != 0" class="row">
                            <div class="col">
                                <Divider align="center">
                                    <div class="inline-flex align-items-center">
                                        <b> Supplier </b>
                                    </div>
                                </Divider>
                                <div class="row pt-3 pb-3">
                                    <div class="col">
                                        <Dropdown :disabled="!$ability.can('change','fence_security') || check_user_service_operator" v-model="fence_security_service_option" :options="get_filtered_fence_by_day" optionLabel="id_supplier.name" optionValue="id" @change="change_service($event)"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <Divider align="center">
                                    <div class="inline-flex align-items-center">
                                        <b> Responsible </b>
                                    </div>
                                </Divider>
                                <div class="row pt-3 pb-3">
                                    <div class="col">
                                        <span class="p-float-label">
                                            <Dropdown :options="fence_security_service_responsibles_list" :disabled="!$ability.can('change','fence_security') || check_user_service_operator" :filter="true" optionLabel="name" v-model="fence_security_service.id_supplier_responsible" placeholder="Select a responsible"> 
                                                <template #value="slotProps">
                                                    <div v-if="slotProps.value">
                                                        <span>{{slotProps.value.name}}</span>
                                                    </div>
                                                    <div v-else>
                                                        <span> {{slotProps.placeholder}} </span>
                                                    </div>
                                                </template>
                                            </Dropdown>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="get_filtered_fence_by_day.length != 0">
                            <Divider align="center" class="mt-2 mb-4">
                                <div class="inline-flex align-items-center">
                                    <b> Shifts </b>
                                </div>
                            </Divider>
                            <TabView>
                                <TabPanel header="Estimation">
                                    <div v-if="fence_security_service.status == 'Pending'">
                                        <Message :closable="false" severity="info"> The email of this service has already been sent, if any modifications are made, the previous email will be annulled. </Message>
                                    </div>
                                    <div v-if="fence_security_service.status == 'Confirmed'">
                                        <Message :closable="false" severity="info"> This service has already been confirmed. If any modifications are made, the status will change, and the previous email will be annulled. </Message>
                                    </div>
                                    <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                                        <DataTable :value="get_fence_security_service_shifts_estimated" tableStyle="min-width: 70rem" editMode="row" dataKey="id" class="mt-3" v-model:editingRows="editing_rows_fence" @row-edit-save="onRowEditSaveFence">
                                            <Column header="Quantity" field="quantity" style="width:30rem">
                                                <template #editor="{ data, field }">
                                                    <InputNumber v-model="data[field]" autofocus />
                                                </template>
                                            </Column>
                                            <Column header="Type" field="id_type_option" style="width:30rem">
                                                <template #body="{data,field}">
                                                    <span> {{data[field].name}}</span>
                                                </template>
                                                <template #editor="{ data, field }">
                                                    <Dropdown :options="get_fence_security_service_type_options" optionLabel="name" optionValue="id" v-model="data[field].id" :disabled="!$ability.can('change','fence_security_service')"/>
                                                </template>
                                            </Column>
                                            <Column header="Gate" field="id_gate_option" style="width:30rem">
                                                <template #body="{data,field}">
                                                    <span> {{data[field].name}}</span>
                                                </template>
                                                <template #editor="{ data, field }"> 
                                                    <Dropdown id="contact" :options="get_fence_security_service_gate_options" optionLabel="name" optionValue="id" v-model="data[field].id" :disabled="!$ability.can('change','fence_security_service')"/>
                                                </template>
                                            </Column>
                                            <Column header="Start" field="start_hour" style="width:30rem">
                                                <template #body="{data,field}">
                                                    <span> {{data[field]}}</span>
                                                </template>
                                                <template #editor="{ data, field }">
                                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                                </template>
                                            </Column>
                                            <Column header="End" field="end_hour" style="width:30rem">
                                                <template #body="{data,field}">
                                                    <span> {{data[field]}}</span>
                                                </template>
                                                <template #editor="{ data, field }">
                                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24"  @change="data[field] = num2timeedit($event)"/>
                                                </template>
                                            </Column>
                                            <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','fence_security_service') && !check_user_service_operator"></Column>
                                            <Column header="Delete" v-if="$ability.can('delete','fence_security_service') && !check_user_service_operator" >
                                                <template #body="slotProps">
                                                    <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_fence_shift(slotProps)"/>
                                                </template>
                                            </Column>
                                            <template #empty>
                                                <div class="row">
                                                    <div class="col text-center">
                                                        No records.
                                                    </div>
                                                </div>
                                            </template>
                                        </DataTable>
                                    </div>
                                    <div v-if="$ability.can('add','fence_security_service') && !check_user_service_operator" class="row mt-3">
                                        <div class="col-12 md:col-1 text-center">
                                            <Button class="p-button-raised p-button-rounded p-button-text" icon="fa fa-plus" @click="add_fence_shift(true)"/> 
                                        </div>
                                        <div class="col-12 md:col my-auto">
                                            <span class="p-float-label">
                                                <InputNumber id="contact" v-model="new_fence_security_service_shifts.quantity"/>
                                                <label for="contact"> Quantity </label>
                                            </span>
                                        </div>
                                        <div class="col-12 md:col my-auto">
                                            <span class="p-float-label">
                                                <Dropdown id="contact" :options="get_fence_security_service_type_options" v-model="new_fence_security_service_shifts.security_type" optionValue="id" optionLabel="name">
                                                    <template #option="slotProps">
                                                        <div class="row">
                                                            <div class="col" style="display:flex;align-items:center;">
                                                                <span> {{slotProps.option.name}} </span>
                                                            </div>
                                                            <div v-if="$ability.can('delete','fence_security_service_type_option')" class="col text-end">
                                                                <i class="fa fa-xmark" @click="delete_fence_security_service_type_option(slotProps.option.id)"/>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-if="$ability.can('add','fence_security_service_type_option')" #footer>
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col text-center my-auto">
                                                                    <InputText v-model="new_type_option" class="p-inputtext-sm"/>
                                                                </div>
                                                                <div class="col">
                                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_fence_security_service_type_option"/>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </template>
                                                </Dropdown>
                                                <label for="contact"> Type </label>
                                            </span>
                                        </div>
                                        <div class="col-12 md:col my-auto">
                                            <span class="p-float-label">
                                                <Dropdown id="contact" :options="get_fence_security_service_gate_options" v-model="new_fence_security_service_shifts.service_type" optionValue="id" optionLabel="name">
                                                    <template #option="slotProps">
                                                        <div class="row">
                                                            <div class="col" style="display:flex;align-items:center;">
                                                                <span> {{slotProps.option.name}} </span>
                                                            </div>
                                                            <div v-if="$ability.can('delete','fence_security_service_gate_option')" class="col text-end">
                                                                <i class="fa fa-xmark" @click="delete_fence_security_service_gate_option(slotProps.option.id)"/>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-if="$ability.can('add','fence_security_service_gate_option')" #footer>
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col text-center my-auto">
                                                                    <InputText v-model="new_gate_option" class="p-inputtext-sm"/>
                                                                </div>
                                                                <div class="col">
                                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_fence_security_service_gate_option"/>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </template>
                                                </Dropdown>
                                                <label for="contact"> Gate </label>
                                            </span>
                                        </div>
                                        <div class="col-12 md:col my-auto">
                                            <span class="p-float-label"> 
                                                <Calendar autocomplete="off" id="contact" :timeOnly="true" hourFormat="24" v-model="new_fence_security_service_shifts.start_datetime_hour" @change="new_fence_security_service_shifts.start_datetime_hour = num2time($event)"/>
                                                <label for="contact"> Start </label>
                                            </span>
                                        </div>
                                        <div class="col-12 md:col my-auto">
                                            <span class="p-float-label">
                                                <Calendar autocomplete="off" id="contact" :timeOnly="true" hourFormat="24" v-model="new_fence_security_service_shifts.end_datetime_hour" @change="new_fence_security_service_shifts.end_datetime_hour = num2time($event)"/>
                                                <label for="contact"> End </label>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-for="error in get_error_fence_security_service_shifts" :key="error">
                                        <div class="row">
                                            <div class="col p-error">
                                                <span v-if="error['start_hour']"> {{error.start_hour[0]}}</span>
                                                <span v-else-if="error['end_hour']"> {{error.end_hour[0]}}</span>
                                                <span v-else> {{error}} </span>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Final" :disabled="fence_security_service.status != 'Confirmed'">
                                    <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                                        <DataTable :value="get_fence_security_service_shifts_final" tableStyle="min-width: 70rem" editMode="row" dataKey="id" class="mt-3" v-model:editingRows="editing_rows_fence" @row-edit-save="onRowEditSaveFence">
                                            <Column header="Quantity" field="quantity" style="width:30rem">
                                                <template #editor="{ data, field }">
                                                    <InputNumber v-model="data[field]" autofocus />
                                                </template>
                                            </Column>
                                            <Column header="Type" field="id_type_option" style="width:30rem">
                                                <template #body="{data,field}">
                                                    <span> {{ data[field].name }}</span>
                                                </template>
                                                <template #editor="{ data, field }">
                                                    <Dropdown :options="get_fence_security_service_type_options" optionLabel="name" optionValue="id" v-model="data[field].id" :disabled="!$ability.can('change','fence_security_service')"/>
                                                </template>
                                            </Column>
                                            <Column header="Gate" field="id_gate_option" style="width:30rem">
                                                <template #body="{data,field}">
                                                    <span> {{data[field].name }}</span>
                                                </template>
                                                <template #editor="{ data, field }">
                                                    <Dropdown id="contact" :options="get_fence_security_service_gate_options" optionLabel="name" optionValue="id" v-model="data[field].id" :disabled="!$ability.can('change','fence_security_service')"/>
                                                </template>
                                            </Column>
                                            <Column header="Start" field="start_hour" style="width:30rem">
                                                <template #body="{data,field}">
                                                    <span> {{data[field]}}</span>
                                                </template>
                                                <template #editor="{ data, field }">
                                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                                </template>
                                            </Column>
                                            <Column header="End" field="end_hour" style="width:30rem">
                                                <template #body="{data,field}">
                                                    <span> {{data[field]}}</span>
                                                </template>
                                                <template #editor="{ data, field }">
                                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24"  @change="data[field] = num2timeedit($event)"/>
                                                </template>
                                            </Column>
                                            <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','fence_security_service')"></Column>
                                            <Column header="Delete" v-if="$ability.can('delete','fence_security_service')" >
                                                <template #body="slotProps">
                                                    <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_fence_shift(slotProps)"/>
                                                </template>
                                            </Column>
                                            <template #empty>
                                                <div class="row">
                                                    <div class="col text-center">
                                                        No records.
                                                    </div>
                                                </div>
                                            </template>
                                        </DataTable>
                                    </div>
                                    <div v-if="$ability.can('add','fence_security_service')" class="row mt-3">
                                        <div class="col-12 md:col-1 text-center">
                                            <Button class="p-button-raised p-button-rounded p-button-text" icon="fa fa-plus" @click="add_fence_shift(false)"/> 
                                        </div>
                                        <div class="col-12 md:col my-auto">
                                            <span class="p-float-label">
                                                <InputNumber id="contact" v-model="new_fence_security_service_shifts.quantity"/>
                                                <label for="contact"> Quantity </label>
                                            </span>
                                        </div>
                                        <div class="col-12 md:col my-auto">
                                            <span class="p-float-label">
                                                <Dropdown id="contact" :options="get_fence_security_service_type_options" v-model="new_fence_security_service_shifts.security_type" optionValue="id" optionLabel="name">
                                                    <template #option="slotProps">
                                                        <div class="row">
                                                            <div class="col" style="display:flex;align-items:center;">
                                                                <span> {{slotProps.option.name}} </span>
                                                            </div>
                                                            <div v-if="$ability.can('delete','fence_security_service_type_option')" class="col text-end">
                                                                <i class="fa fa-xmark" @click="delete_fence_security_service_type_option(slotProps.option.id)"/>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-if="$ability.can('add','fence_security_service_type_option')" #footer>
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col text-center my-auto">
                                                                    <InputText v-model="new_type_option" class="p-inputtext-sm"/>
                                                                </div>
                                                                <div class="col">
                                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_fence_security_service_type_option"/>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </template>
                                                </Dropdown>
                                                <label for="contact"> Type </label>
                                            </span>
                                        </div>
                                        <div class="col-12 md:col my-auto">
                                            <span class="p-float-label">
                                                <Dropdown id="contact" :options="get_fence_security_service_gate_options" v-model="new_fence_security_service_shifts.service_type" optionValue="id" optionLabel="name">
                                                    <template #option="slotProps">
                                                        <div class="row">
                                                            <div class="col" style="display:flex;align-items:center;">
                                                                <span> {{slotProps.option.name}} </span>
                                                            </div>
                                                            <div v-if="$ability.can('delete','fence_security_service_gate_option')" class="col text-end">
                                                                <i class="fa fa-xmark" @click="delete_fence_security_service_gate_option(slotProps.option.id)"/>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-if="$ability.can('add','fence_security_service_gate_option')" #footer>
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col text-center my-auto">
                                                                    <InputText v-model="new_gate_option" class="p-inputtext-sm"/>
                                                                </div>
                                                                <div class="col">
                                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_fence_security_service_gate_option"/>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </template>
                                                </Dropdown>
                                                <label for="contact"> Gate </label>
                                            </span>
                                        </div>
                                        <div class="col-12 md:col my-auto">
                                            <span class="p-float-label"> 
                                                <Calendar autocomplete="off" id="contact" :timeOnly="true" hourFormat="24" v-model="new_fence_security_service_shifts.start_datetime_hour" @change="new_fence_security_service_shifts.start_datetime_hour = num2time($event)"/>
                                                <label for="contact"> Start </label>
                                            </span>
                                        </div>
                                        <div class="col-12 md:col my-auto">
                                            <span class="p-float-label">
                                                <Calendar autocomplete="off" id="contact" :timeOnly="true" hourFormat="24" v-model="new_fence_security_service_shifts.end_datetime_hour" @change="new_fence_security_service_shifts.end_datetime_hour = num2time($event)"/>
                                                <label for="contact"> End </label>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-for="error in get_error_fence_security_service_shifts" :key="error">
                                        <div class="row">
                                            <div class="col p-error">
                                                <span v-if="error['start_hour']"> {{error.start_hour[0]}}</span>
                                                <span v-else-if="error['end_hour']"> {{error.end_hour[0]}}</span>
                                                <span v-else> {{error}} </span>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                            <div class="row">
                                <div class="col-12 md:col-6">
                                    <Divider align="center" class="mt-5">
                                        <div class="inline-flex align-items-center">
                                            <b> Mail Note </b>
                                            <i class="fa fa-circle-info pl-2" v-tooltip="'Please remember that this section is meant for adding a note to the email content, it\'s not the main body of the email.'" type="text" placeholder="Right" style="cursor:pointer;"></i>
                                        </div>
                                    </Divider>
                                    <div class="row pt-3">
                                        <div class="col">
                                            <TextArea placeholder="This text will be attached to the email sent." rows="5" v-model="fence_security_service.mail_text" :disabled="!$ability.can('change','fence_security') || check_user_service_operator"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <Divider align="center" class="mt-5">
                                        <div class="inline-flex align-items-center">
                                            <b> Additional Notes </b>
                                        </div>
                                    </Divider>
                                    <div class="row pt-3">
                                        <div class="col">
                                            <TextArea rows="5" v-model="fence_security_service.note_info" :disabled="!$ability.can('change','fence_security') || check_user_service_operator"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div v-if="fence_security_service.status === 'Modified' || fence_security_service.status === 'Refused' || fence_security_service.status === 'Confirmed' || fence_security_service.status === 'On hold'" class="row pt-3 justify-content-between">
                                <div v-if="$ability.can('send','mail_fence_security') && !check_user_service_operator &&  check_if_assigned" class="col-12 md:col-3 lg:col-2">
                                    <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_fence_security_mail"/>
                                </div>
                                <span v-if="fence_security_service.status && $ability.can('change','fence_security') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                                    <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_fence_security_service()"/>
                                </span>
                            </div>
                            <div v-else class="row pt-3 justify-content-between">
                                <div v-if="$ability.can('send','mail_fence_security') && !check_user_service_operator && check_if_assigned" class="col-12 md:col-3 lg:col-2">
                                    <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_fence_security_mail"/>
                                </div>
                                <div v-if="$ability.can('change','fence_security') || $ability.can('change','fence_security_service') || $ability.can('add','fence_security')" class="col-12 md:col-3 lg:col-2">
                                    <span v-if="fence_security_service.status && $ability.can('change','fence_security') && !check_user_service_operator">
                                        <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_fence_security_service()"/>
                                    </span>
                                    <span v-else-if="!fence_security_service.status && $ability.can('add','fence_security') && !check_user_service_operator">
                                        <Button label="Create" icon="fa-solid fa-plus" class="p-button-raised p-button-text p-button-success" @click="save_changes_fence_security_service()"/>
                                    </span>
                                </div>
                            </div>
                            <div v-if="mail_fence_error" class="row pt-3 text-center">
                                <div v-if="mail_fence_error.length > 0" class="col" style="color: red;">
                                    {{mail_fence_error}}
                                </div>
                            </div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </Panel>    

    <OverlayPanel ref="op" style="width: 700px" :breakpoints="{'960px': '75vw'}">
        <DataTable :value="get_fence_security_service_sheets" :paginator="true" :rows="5" stripedRows>
            <Column header="Terminal" field="id_terminal"/>
            <Column header="Ship" field="id_ship"/>
            <Column header="Estimated Arrival Date" field="estimated_arrival_datetime">
                <template #body="slotProps">
                    {{ format_date(slotProps.data.estimated_arrival_datetime) }}
                </template>    
            </Column>
            <Column header="Estimated Departure Date" field="estimated_departure_datetime">
                <template #body="slotProps">
                    {{ format_date(slotProps.data.estimated_departure_datetime) }}
                </template>  
            </Column>
        </DataTable>
    </OverlayPanel>

    <Dialog v-model:visible="modal_confirmation_mail"  :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="row">
            <div class="col">
                <span> Are you sure you want to send this mail? </span>
            </div>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="modal_confirmation_mail = false"/>
            <Button label="Yes" @click="handle_function_confirmation_mail(mail_selected)" />
        </template>
    </Dialog>

</template>

<script>
import Panel from 'primevue/panel'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import TextArea from 'primevue/textarea'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Divider from 'primevue/divider'
import OverlayPanel from 'primevue/overlaypanel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import Message from 'primevue/message'
import InputText from 'primevue/inputtext'

import { num2time, num2timeedit } from '@/modules/utilities.js'
import moment from 'moment'

export default {
    name: 'Fence security',
    components: {
        Panel,
        TabView,
        TabPanel,
        Dropdown,
        Button,
        TextArea,
        Calendar,
        InputNumber,
        Divider,
        OverlayPanel,
        DataTable,
        Column,
        Message,
        Dialog,
        InputText
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            fence_security_service: {},
            new_fence_security_service: {'id_supplier_responsible': null, 'date': '', 'mail_text': '', 'note_info':'', 'id_supplier': 7, 'id_service_sheet': [(Number(this.$route.params.sid))]},
            new_fence_security_service_shifts: {'fence_security':'', 'quantity': null, 'service_type': '', 'security_type': '', 'start_datetime_hour': '', 'end_datetime_hour': ''},
            fence_security_service_shifts: [],
            fence_security_service_option: '',
            fence_security_service_options: [],
            selected_fence_security_services: [],
            security_type_fence: [
                'Security guard',
                'Security assistant'
            ],
            service_type_fence: [
                '1',
                '2',
                '3',
                '4'
            ],
            fence_security_service_responsibles_list: [],
            mail_fence_error: null,
            current_fence_day: '',
            fence_collapsed: true,
            editing_rows_fence: [],
            modal_confirmation_mail: false,
            active_index: 0,
            new_type_option: '',
            new_gate_option: '',
            fence_days: [],
            created_automatically:false
        }
    },
    props: {
        service_sheet: {
            type: Object
        },
        all_suppliers: {
            type: Object
        },
    },
    computed: {
        get_fence_security_service: function() {
            return this.$store.getters.get_fence_security_service
        },
        //get_filtered_fence_by_day: function() {
        //    return this.$store.getters.get_filtered_fence_by_day
        //},
        get_send_fence_security_mail: function() {
            return this.$store.getters.get_send_fence_security_mail
        },
        get_fence_security_service_shifts: function() {
            return this.$store.getters.get_fence_security_service_shifts
        },
        get_supplier_name: function() {
            return this.$store.getters.get_supplier_name
        },
        get_error_fence_security_service: function() {
            return this.$store.getters.get_error_fence_security_service
        },
        get_error_fence_security_service_shifts: function() {
            return this.$store.getters.get_error_fence_security_service_shifts
        },
        check_if_assigned: function() {
            if(this.get_filtered_fence_by_day.length != 0){
                for(var item in this.get_filtered_fence_by_day[0].id_service_sheet) {
                    if(this.get_filtered_fence_by_day[0].id_service_sheet[item] == (Number(this.$route.params.sid))) {
                        return true
                    }
                }
                return false
            } else {
                return false
            }
            
        },
        get_fence_security_service_sheets: function() {
            return this.$store.getters.get_fence_security_service_sheets
        },
        get_filtered_fence_by_day() {
            if(this.service_sheet.id_terminal.name == 'A' || this.service_sheet.id_terminal.name == 'B' || this.service_sheet.id_terminal.name == 'C') {
                return this.$store.getters.get_filtered_fence_by_day('VALLADO Adosado') ?? {}
            } else if (this.service_sheet.id_terminal.name == 'ESTN' || this.service_sheet.id_terminal.name == 'ESTS' || this.service_sheet.id_terminal.name == 'NORD' || this.service_sheet.id_terminal.name == 'SUD') {
                return this.$store.getters.get_filtered_fence_by_day('VALLADO I+K') ?? {}
            }
            return {}
        },
        get_supplier_service: function () {
            return this.$store.getters.get_supplier_service
        },
        get_supplier_service_responsible: function() {
            return this.$store.getters.get_supplier_service_responsible
        },
        get_fence_security_service_shifts_estimated: function () {
            return this.$store.getters.get_fence_security_service_shifts_estimated
        },
        get_fence_security_service_shifts_final: function () {
            return this.$store.getters.get_fence_security_service_shifts_final
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Ops') {
                    return true
                }
            }
            return false
        },
        get_fence_security_service_type_options: function() {
            return this.$store.getters.get_fence_security_service_type_options
        },
        get_fence_security_service_gate_options: function() {
            return this.$store.getters.get_fence_security_service_gate_options
        },
        get_error_fence_security_service_type_options: function() {
            return this.$store.getters.error_fence_security_service_type_options
        },
        get_error_fence_security_service_gate_options: function() {
            return this.$store.getters.error_fence_security_service_gate_options
        },
        get_min_date: function () {
            return this.$store.getters.min_date
        },
        get_max_date: function () {
            return this.$store.getters.max_date
        },
        created_auto: function () {
            return this.$store.getters.created_auto
        }
    },
    async mounted() {
        this.calculate_days()
        await this.$store.dispatch('load_fence_security_service_type_options')
        await this.$store.dispatch('load_fence_security_service_gate_options')
        await this.$store.dispatch('load_fence_security_service_by_day', this.fence_days[0].label)
    },
    methods: {
        calculate_days() {
            
            this.fence_days = []

            var fecha1 = moment(this.get_min_date)
            var fecha2 = moment(this.get_max_date)

            this.days = moment.duration(fecha2.diff(fecha1)).asDays() + 1

            var item = { label: '' }
            var options = { year: 'numeric', month: 'numeric', day: 'numeric' };

            for(var a = new Date(this.get_min_date); a <= new Date(this.get_max_date); a.setDate(a.getDate() + 1)) {
                item.label = new Date(a).toLocaleDateString("fr-CA", options)
                this.fence_days.push(item)
                item = {label: ''}
            }
        },
        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
        toggle_assigned(event) {
            this.$refs.op.toggle(event);
        },
        handle_function_confirmation_mail(function_name){
            this[function_name]()
            this.modal_confirmation_mail = false
            this.mail_selected = ''
        },
        // Function that adds new empty fence service so the user can fill
        async add_fence_shift(data) {

            this.new_fence_security_service_shifts.fence_security = this.fence_security_service.id

            var is_empty = false
            var shift_values = Object.values(this.new_fence_security_service_shifts)

            for(var item in shift_values) {
                if(shift_values[item] == null || shift_values[item] == '') {
                    is_empty = true
                } 
            }

            if(is_empty == true) {
                this.$store.dispatch('set_error_empty_shift')

            } else {
                this.new_fence_security_service_shifts.is_estimated = data
                if(!this.fence_security_service){
                    this.new_fence_security_service.date = this.fence_days[0].label
                    await this.$store.dispatch('create_fence_security_service', this.new_fence_security_service)
                    await this.$store.dispatch('load_fence_security_service_by_day', this.fence_days[0].label)
                    this.fence_security_service_options = this.get_filtered_fence_by_day[0]
                } 
                
                await this.$store.dispatch('create_fence_security_service_shift', this.new_fence_security_service_shifts)

                if(this.get_error_fence_security_service_shifts.length == 0) {
                    this.new_fence_security_service_shifts = {'fence_security':'', 'quantity': null, 'service_type': '', 'security_type': '', 'start_datetime_hour': '', 'end_datetime_hour': ''}
                }
                /*
                await this.$store.dispatch('load_fence_security_service_shifts', this.fence_security_service.id)
                this.fence_security_service_shifts = this.get_fence_security_service_shifts
                
                if(this.fence_security_service_shifts) {
                    for(var x in this.fence_security_service_shifts) {
                        this.fence_security_service_shifts[x].start_hour = this.fence_security_service_shifts[x].start_hour.split(':')[0] + ':' + this.fence_security_service_shifts[x].start_hour.split(':')[1] 
                        this.fence_security_service_shifts[x].end_hour = this.fence_security_service_shifts[x].end_hour.split(':')[0] + ':' + this.fence_security_service_shifts[x].end_hour.split(':')[1] 
                    }
                }
                */
                
                await this.$store.dispatch('load_fence_security_service_by_day', this.fence_days[this.active_index].label)
                this.fence_security_service = this.get_filtered_fence_by_day[0]

            }
        },
        async add_fence_security_service_type_option() {
            await this.$store.dispatch('create_fence_security_service_type_option', this.new_type_option)
            if(this.get_error_fence_security_service_type_options.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Option created successfully', life: 3000});
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: this.get_error_fence_security_service_type_options.name[0], life: 3000});
            }
            this.new_type_option = ''
        },
        async add_fence_security_service_gate_option() {
            await this.$store.dispatch('create_fence_security_service_gate_option', this.new_gate_option)
            if(this.get_error_fence_security_service_gate_options.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Option created successfully', life: 3000});
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: this.get_error_fence_security_service_gate_options.name[0], life: 3000});
            }
            this.new_gate_option = ''
        },
        // FENCE SECURITY SERVICE
        async add_fence_service() { 

            if(this.service_sheet.id_terminal.name == 'A' || this.service_sheet.id_terminal.name == 'B' || this.service_sheet.id_terminal.name == 'C') {
                await this.$store.dispatch('load_suppliers_name', 'VALLADO Adosado')
                this.new_fence_security_service = {'id_supplier_responsible': null, 'date': this.fence_days[this.active_index].label, 'mail_text': '', 'note_info':'', 'id_supplier': this.get_supplier_name[0].id, 'id_service_sheet': [(Number(this.$route.params.sid))]}
                await this.$store.dispatch('create_fence_security_service', this.new_fence_security_service)
            } else {
                await this.$store.dispatch('load_suppliers_name', 'VALLADO I+K')
                this.new_fence_security_service = {'id_supplier_responsible': null, 'date': this.fence_days[this.active_index].label, 'mail_text': '', 'note_info':'', 'id_supplier': this.get_supplier_name[0].id, 'id_service_sheet': [(Number(this.$route.params.sid))]}
                await this.$store.dispatch('create_fence_security_service', this.new_fence_security_service)
            }

            this.fill_fence_security_service()

            await this.$store.dispatch('load_fence_security_service_by_day', this.fence_days[this.active_index].label)
        },
        check_id(){
            for(var item in this.get_filtered_fence_by_day[0].id_service_sheet) {
                if(this.get_filtered_fence_by_day[0].id_service_sheet[item] == (Number(this.$route.params.sid))) {
                    return true
                }
            }
            return false
        },
        async load_fence_security_service_responsibles() {
            await this.$store.dispatch('load_supplier_service_responsible', this.fence_security_service.id_supplier.id)
            this.fence_security_service_responsibles_list = this.get_supplier_service_responsible


        },
        async assign_service_sheet_to_fence_service() {
            
            var id_list = []
            this.new_fence_security_service.id = this.get_filtered_fence_by_day[0].id

            for(var item in this.get_filtered_fence_by_day[0].id_service_sheet) {
                id_list.push(this.get_filtered_fence_by_day[0].id_service_sheet[item])
            }

            id_list.push(Number(this.$route.params.sid))   

            this.new_fence_security_service.id_service_sheet = id_list

            await this.$store.dispatch('add_service_sheet_to_fence_service', this.new_fence_security_service)

            if(this.get_filtered_fence_by_day.length > 0) {
                this.$store.dispatch('get_ss_from_fence_service', this.get_filtered_fence_by_day[0].id)
            }
            
        },
        async unassign_service_sheet_to_fence_service() {
            if(this.get_filtered_fence_by_day[0].id_service_sheet.includes((Number(this.$route.params.sid)))){
                this.get_filtered_fence_by_day[0].id_service_sheet.splice(this.get_filtered_fence_by_day[0].id_service_sheet.indexOf(Number(this.$route.params.sid), 1))
            }

            if(this.get_filtered_fence_by_day[0].id_service_sheet.length == 0) {
                await this.$store.dispatch('delete_fence_security', this.get_filtered_fence_by_day[0].id)
                this.fence_security_service = {}
            } else {
                await this.$store.dispatch('remove_service_sheet_to_fence_service',  this.get_filtered_fence_by_day[0])
            }

            //await this.$store.dispatch('load_fence_security_service_by_day', this.fence_days[0].label) 
            //this.fence_security_service = {}
            //this.fence_security_service_option =  ''

            if(this.get_filtered_fence_by_day.length > 0) {
                this.$store.dispatch('get_ss_from_fence_service', this.get_filtered_fence_by_day[0].id)
            }


            this.created_automatically = false   
            
        },
        async fill_fence_security_service(e) {
            if(this.fence_collapsed == false) {

                /*if (e.index !== undefined) {
                    await this.$store.dispatch('load_fence_security_service_by_day', this.fence_days[e.index].label)
                    this.current_fence_day = this.fence_days[e.index].label
                } else {
                    await this.$store.dispatch('load_fence_security_service_by_day', this.fence_days[this.active_index].label)
                }*/

                await this.$store.dispatch('load_fence_security_service_by_day', this.fence_days[this.active_index].label)

                if (e != null){
                    if(e.value === false || this.fence_collapsed === false){

                        var data = {
                            date: this.fence_days[this.active_index].label,
                            id_ss: (Number(this.$route.params.sid))
                        }

                        await this.$store.dispatch('create_past_fence_security', data)
                        
                        if(this.created_auto) {
                            this.created_automatically = true   
                        }
                        /*
                        console.log(this.get_fence_security_service_shifts_estimated)
                        if(this.get_fence_security_service_shifts_estimated.length > 0) {
                            console.log('aaa')
                            this.created_automatically = true    
                        }  */ 
                    }
                }
                
                if(this.get_fence_security_service.length > 0) {
                    this.fence_security_service_options = this.get_filtered_fence_by_day
                    this.fence_security_service_option = this.get_filtered_fence_by_day[this.active_index].id
                }

                if(this.get_filtered_fence_by_day.length > 0) {
                    this.change_service({'value': this.get_filtered_fence_by_day[0].id})
                    this.fence_security_service_option = this.get_filtered_fence_by_day[0].id
                }

                if(this.get_filtered_fence_by_day.length > 0) {
                    this.$store.dispatch('get_ss_from_fence_service', this.get_filtered_fence_by_day[0].id)
                }
                
            } 
        },
        async change_fence_tab(event) {
            this.current_fence_day = this.fence_days[event.index].label
            await this.$store.dispatch('load_fence_security_service_by_day', this.fence_days[event.index].label) 
            this.fence_security_service = this.get_filtered_fence_by_day
            this.new_fence_security_service = {'id_supplier_responsible': null, 'date': '', 'note':'', 'id_supplier': 7, 'id_service_sheet': [(Number(this.$route.params.sid))]}
            
            if(this.get_filtered_fence_by_day.length > 0) {
                this.fence_security_service_option = this.get_filtered_fence_by_day[0].id
                this.fence_security_service = this.get_filtered_fence_by_day[0]
                await this.$store.dispatch('load_fence_security_service_shifts', this.fence_security_service.id)
            }else{
                event.value = true
                //this.fill_fence_security_service(event)
            }
            
        },
        async change_service(event) {
            await this.$store.dispatch('load_fence_security_service', event.value)
            await this.$store.dispatch('load_fence_security_service_shifts', event.value)
            
            this.fence_security_service_shifts = this.get_fence_security_service_shifts
            this.fence_security_service = this.get_fence_security_service
            this.load_fence_security_service_responsibles()

        },
        async save_changes_fence_security_service() {
            if(this.get_fence_security_service.length == 0) {
                await this.$store.dispatch('create_fence_security_service', this.fence_security_service)

                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Fence service created successfully', life: 3000});
            } else {
                await this.$store.dispatch('update_fence_security_service', this.fence_security_service)
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Fence service updated successfully', life: 3000});
            }
        },
        async onRowEditSaveFence(event) {

            await this.$store.dispatch('clean_errors_fence_security_shifts')

            let { newData } = event;
            await this.$store.dispatch('update_fence_security_services_shifts', newData)

            await this.$store.dispatch('load_fence_security_service_by_day', this.fence_days[this.active_index].label) 
            this.fence_security_service = this.get_filtered_fence_by_day[0]

            if(this.get_error_fence_security_service_shifts.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Fence security service updated successfully', life: 3000});
            }

        },
        async dialog_confirmation_fence_security_mail() {
            this.modal_confirmation_mail = true
            this.mail_selected = 'send_fence_security_service_mail'
        },
        async send_fence_security_service_mail() {
            this.mail_fence_error = ''

            var data_ids = {
                id_ss: Number(this.$route.params.sid),
                id: this.fence_security_service.id
            }

            await this.$store.dispatch('update_fence_security_service', this.fence_security_service)

            if(this.mail_fence_error == '' ){
                if(this.get_fence_security_service_shifts.length > 0) {
                    if(this.fence_security_service.status == 'None' || this.fence_security_service.status == 'Modified' || this.fence_security_service.status == 'Refused') {
                        await this.$store.dispatch('send_fence_security_service_mail', data_ids)
                        this.mail_fence_error = this.get_error_fence_security_service_shifts 
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                    } else {
                        if(this.get_send_fence_security_mail == false) {
                            this.mail_fence_error = 'Cannot send mail with the same data as the previous.' 
                        } else {
                            await this.$store.dispatch('send_fence_security_service_mail', data_ids)
                            this.$toast.removeAllGroups();
                            this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                        }
                    }
                } else {
                    this.mail_fence_error = 'Cannot send mail without any shift. Please make sure to add at least one'
                }
            }

            await this.$store.dispatch('load_fence_security_service', this.fence_security_service.id)
            this.fence_security_service = this.get_fence_security_service
        },
        async delete_fence_shift(event) {

            await this.$store.dispatch('delete_fence_security_service', event.data.id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Shift deleted', life: 3000});

        },
        async delete_fence_security() {
            this.service_selected = 'delete_fence_security_confirmation'
            this.delete_service = true
        },
        async delete_fence_security_service_type_option(id) {
            await this.$store.dispatch('delete_fence_security_service_type_option', id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Option removed', life: 3000});
        },
        async delete_fence_security_service_gate_option(id) {
            await this.$store.dispatch('delete_fence_security_service_gate_option', id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Option removed', life: 3000});
        },
        format_date(data){
            return moment(data).format('YYYY-MM-DD HH:mm')
        },
    }
        
}
</script>

<style scoped>

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
}

.on_hold{
    border: 4px solid #002D72;
    border-radius: 10px;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}

.p-divider.p-divider-horizontal {
    color: v-bind(color);
}

.p-tooltip{
    background: v-bind(color);
}

ul {
    list-style-type: none;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}


:deep(.p-button-sm){
    padding: .5px .5px;
}

:deep(.p-scrollpanel-content) {
    overflow-y: hidden;
}
</style>