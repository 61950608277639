<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div v-if="$can('view','billing_destination')" class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Billing Destination </h1>
            <div v-if="$can('add','billing_destination')" class="card p-1">
                <Button data-cy="new_billing_destination_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Billing Destination'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_billing_destination_modal" header="New Billing Destination" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <form id="form_billing_destination" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <InputText id="billing_type" v-model="v$.billing_type.$model" :class="{'p-invalid':v$.billing_type.$invalid && submitted || billing_destination_errors.billing_type}" />
                                <label for="billing_type" :class="{'p-error':v$.billing_type.$invalid && submitted}"> Billing Type </label>
                                <small v-if="Object.keys(billing_destination_errors).length !== 0 && billing_destination_errors.billing_type" class="p-error"><span v-for="error in billing_destination_errors.billing_type" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.billing_type.$invalid && submitted) || v$.billing_type.$pending.$response" class="p-error">{{v$.billing_type.required.$message.replace('Value', 'Billing type')}}</small>
                        </div>
                    </div> 
                </div>
            </form>
            <template #footer>
                <Button data-cy="create_billing_destination_button" form="form_billing_destination" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        Toast
    },
    data() {
        return {
            displayModal: false,
            billing_type: '',
			get_errors_billing_destination: {},
            billing_destination_errors: {},
            submitted: false,
            showMessage: false,
        }
    },
    computed: {
        get_billing_destination_errors: function() {
            return this.$store.getters.get_billing_destination_errors
        }
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_billing_destination() {
            let billing_destinations = { 
                billing_type: this.billing_type,
            }
            await this.$store.dispatch('create_billing_destination', billing_destinations)
            this.billing_destination_errors = this.get_errors_billing_destination
            if(Object.keys(this.billing_destination_errors).length === 0){
                this.toggleDialog();
                this.billing_type = ''
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {

            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

            let res = await this.add_billing_destination()
            if (res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Cruise Line added', life: 3000});
            } 
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.billing_type = '';
            this.submitted = false;
        }
    },
    validations() {
        return {
            billing_type: { required }
        }
    },
};
</script>

<style scoped>
</style>