import axios from 'axios'

const state = {
    users: [],
    all_users: [],
    users_management: [],
    port: {},
    ports: [],
    user: '',
    current_user: [],
    basic_users: [],
    errors_change_user: [],
    // Movidos del archivo user.js del adminDashboard
    user_errors: {}, 
    profile_errors: {},
    delete_status : false,
}

const mutations = {
    SET_USERS(state, users) {
        state.users = users
    },
    SET_USER_PORT(state, port) {
        state.port = port
    },
    SET_ALL_USERS(state, users) {
        state.all_users = users
    },
    SET_USER(state, user) {
        state.user = user
    },
    SET_USERS_MANAGEMENT(state, payload) {
        state.users_management = payload
    },
    SET_PORTS(state, port) {
        state.ports = port
    },
    SET_CURRENT_USER(state, current_user) {
        state.current_user = current_user
    },
    SET_BASICS_USER(state, payload) {
        state.basic_users = payload
    },
    UPDATE_CURRENT_USER(state, current_user) {
        /*
        state.current_user = Object.keys(state.current_user).map(updated_current_user => {
            if(updated_current_user.id === current_user.id) {
                updated_current_user.username = current_user.username
                updated_current_user.first_name = current_user.first_name
                updated_current_user.last_name = current_user.last_name
                updated_current_user.email = current_user.email
            }
        })
        */
        state.current_user.email = current_user.email
        state.current_user.first_name = current_user.first_name
        state.current_user.last_name = current_user.last_name
        state.current_user.username = current_user.username
    },
    UPDATE_USER_SIGNATURE(state, current_user) {
        state.current_user.profile.signature = current_user.signature
    },
    UPDATE_USER_PICTURE(state, current_user) {
        state.current_user.profile.picture = current_user.picture
    },
    UPDATE_ERRORS_CHANGE_USER_PROFILE(state, errors) {
        state.errors_change_user = errors
    },
    CLEAN_ERRORS_CHANGE_USER_PROFILE(state) {
        state.errors_change_user = []
    },
    ACTIVATE_USER(state, payload) {
        console.log(payload)
    },

    // Movidos del archivo user.js del adminDashboard
    UPDATE_USER_PROFILE(state, profile) {
        state.users = state.users.map(updated_user => {
            if(updated_user.profile.id === profile.id) {
                updated_user.profile = profile
            }
            
            return updated_user
        })
        
    },
    ERRORS_USER(state, errors){
        state.user_errors = errors
    },
    ERRORS_PROFILE(state, errors){
        state.profile_errors = errors
    },
    DELETE_ERROR(state, status){
        state.delete_status = status
    },
    NEW_USER(state, user) {  
        state.users_management.push(user)
        state.user_errors = {}
    },
    DELETE_USER(state, id) {
        let index = state.users.findIndex(users => users.id == id)
        state.users.splice(index, 1)
        state.delete_status = false
    },
    UPDATE_USER(state, user) {
        state.users_management = state.users_management.map(updated_user => {
            if(updated_user.id === user.id) {
                updated_user.username = user.username
                updated_user.first_name = user.first_name
                updated_user.last_name = user.last_name
                updated_user.email = user.email
            }
            return updated_user
        })
        state.user_errors = {}
    }
}

const actions = {
    load_users_by_port({commit}) {
        axios.get(process.env.VUE_APP_API_URL + "users/by_port/")
            .then((response) => {
                commit('SET_USERS', response.data)
            })
    },
    async load_user_port({commit}){
        await axios.get(process.env.VUE_APP_API_URL + "users/user_port/")
            .then((response) => {
                commit('SET_USER_PORT', response.data)
            })
    },
    async load_all_users({commit}){
        await axios.get(process.env.VUE_APP_API_URL + "users/")
            .then((response) => {
                commit('SET_ALL_USERS', response.data)
            })
    },
    async load_users_management({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "users/get_all_users/")
            .then((response) => {
                commit('SET_USERS_MANAGEMENT', response.data)
            })
    },
    async load_user_by_id({commit}, id) {
        await axios.get(process.env.VUE_APP_API_URL + "users/" + id) 
            .then((response) => {
                commit('SET_USER', response.data)
            })
    },
    async load_basic_users({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "get_basic_users/") 
            .then((response) => {
                commit('SET_BASICS_USER', response.data)
            })
    },
    async load_ports({commit}) {
        await axios.get(process.env.VUE_APP_API_URL + "ports")
            .then((response) => {
                commit('SET_PORTS', response.data)
            })
    },
    async get_current_user({commit}){
        await axios.get(process.env.VUE_APP_API_URL + 'users/get_current_user/')
            .then((response) => {
                commit('SET_CURRENT_USER', response.data)
            })
    },
    async change_user_port({commit}, change_user_port) {
        console.log(commit)
        await axios.patch(process.env.VUE_APP_API_URL + 'users/' + change_user_port.id_user + '/', {
            profile: { id_port: change_user_port.id_port } 
        })
            .then((response) => {
                localStorage.setItem('user_port', response.data.profile.id_port.name)
                localStorage.setItem('primary_color', response.data.profile.id_port.primary_color)
            })
    },
    async change_user_profile({commit}, user) {
        await axios.patch(process.env.VUE_APP_API_URL + 'users/' + user.id + '/', {
            username: user.username,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email
        })
            .then((response) => {
                commit('CLEAN_ERRORS_CHANGE_USER_PROFILE')
                commit('UPDATE_CURRENT_USER', response.data)
            }) 
            .catch((error) => {
                commit('UPDATE_ERRORS_CHANGE_USER_PROFILE', error.response.data)
            })
    },
    async change_user_signature({commit}, user) {
        var formData = new FormData();

        formData.append('signature', user.profile.signature) 
        

        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }

        await axios.patch(process.env.VUE_APP_API_URL + 'profile/' + user.profile.id + '/', formData, headers)
            .then((response) => {
                commit('UPDATE_USER_SIGNATURE', response.data)
            })
    },
    async change_user_picture({commit}, user) {
        var formData = new FormData();

        formData.append('picture', user.profile.picture) 
        

        let token = localStorage.getItem('token')
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer' + token
        }

        await axios.patch(process.env.VUE_APP_API_URL + 'profile/' + user.profile.id + '/', formData, headers)
            .then((response) => {
                commit('UPDATE_USER_PICTURE', response.data)
            })
    },
    async activate_user({commit}, data) {
        const bool = data.is_active ? 'True' : 'False';
        await axios.post(process.env.VUE_APP_API_URL + 'users/activate_user/' + bool + '/' + data.id + '/')
            .then((response) => {
                commit('ACTIVATE_USER', response.data)
            })
    },

    // Movidos del archivo user.js del adminDashboard

    async create_user({commit, rootState}, user) {

        user.profile = {'id_port':rootState.users.port.id}

        await axios.post(process.env.VUE_APP_API_URL  + 'users/', user)
            .then((response) => {
                commit('NEW_USER', response.data)
            })
            .catch((error) => {
                commit('ERRORS_USER', error.response.data)
            }) 

        
    },
    async delete_user({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL + 'users/' + id)
            .then(() => {
                commit('DELETE_USER', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
            
    }, 
    async update_user({commit}, user) {
        await axios.patch(process.env.VUE_APP_API_URL  + 'users/' + user.id + '/',  { 
            username: user.username,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email
        })
            .then((response) => { 
                commit('UPDATE_USER', response.data)
            })
            .catch((error) => {
                commit('ERRORS_USER', error.response.data)
            })
        var formData = new FormData();

        if(typeof(user.profile.picture) != 'string' && user.profile.picture != null) {
            formData.append('picture', user.profile.picture)    
        }
        
        if(typeof(user.profile.signature) != 'string' && user.profile.signature != null) {
            formData.append('signature', user.profile.signature) 
        }
        
        formData.append('mail_notifications', user.profile.mail_notifications)

        if(formData.has('signature') || formData.has('picture')){

            let token = localStorage.getItem('token')
            let headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer' + token
            }
    
            await axios.patch(process.env.VUE_APP_API_URL  + 'profile/' + user.profile.id + '/', formData, {headers})
            
                .then((response) => { 
                    commit('UPDATE_USER_PROFILE', response.data)
                })
                .catch((error) => {
                    commit('ERRORS_PROFILE', error.response.data)
                })
        }
    },

}

const getters = {
    users_by_port: (state) => state.users,
    load_user_port: (state) => state.port,
    users_management: (state) => state.users_management,
    get_user_port:(state) =>{
        return state.port
    },
    get_user_by_id: (state) => state.user,
    get_ports: (state) => state.ports,
    get_current_user: (state) => state.current_user,
    get_all_users: (state) => state.all_users,
    get_errors_change_user: (state) => state.errors_change_user,
    get_basic_users: (state) => state.basic_users,
    // Movidos del archivo user.js del adminDashboard
    all_users: (state) => state.all_users,
    get_errors_user: (state) => state.user_errors,
    get_errors_profile: (state) => state.profile_errors,
    get_delete_user_status:(state) => state.delete_status,
}

export default {
    state,
    mutations,
    actions,
    getters
};