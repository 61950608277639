<template>
	<Toast/>
    <div class="grid pt-4 justify-content-center" style="width: 100%; margin-left: 0;">
        <div v-if="$ability.can('add','service_sheet')" class="col-12 md:col-6 lg:col-3 xl:col text-center pt-2"> 
            <Button data-cy="create_service_sheet" icon="fa-solid fa-plus" label="Create Service Sheet" class="p-button-raised p-button-success p-button-text" @click="open_modal_new"/>
        </div>
        <div class="col-12 md:col-6 lg:col-3 xl:col text-center pt-2" v-if="(this.$route.name == 'Service_sheet_template' || this.$route.name == 'Service_sheet_log') && $ability.can('delete','service_sheet')" >
            <Button data-cy="delete_service_sheet" icon="fa-solid fa-trash" label="Delete Service Sheet" class="p-button-raised p-button-danger p-button-text" @click="open_modal_delete"/>
        </div>
        <div class="col-12 md:col lg:col xl:col text-center pt-2" v-if="this.$route.name == 'Service_sheet_template' || this.$route.name == 'Service_sheet_log'" >
            <div v-if="!swap">
                <Button icon="fa-solid fa-list-ul" label="Log" class="p-button-raised p-button-text" @click="log_push"/>
            </div>
            <div v-if="swap">
                <Button icon="fa-solid fa-clipboard" label="Service Sheet" class="p-button-raised p-button-text" @click="service_sheet_push"/>
            </div>
        </div>
        <div class="col-12 md:col lg:col xl:col text-center" v-if="(this.$route.name == 'Service_sheet_template' || this.$route.name == 'Service_sheet_log') && $ability.can('validate','service_sheet')" >
            <span v-if="get_service_sheet.main && !get_service_sheet.signed">
                <Button label="Validation" class="p-button-raised p-button-text" @click="toggle"/>
            </span>
            <span v-else-if="!get_service_sheet.main && !get_service_sheet.signed">
                <Button label="Validation" @click="toggle" class="p-button-raised p-button-text" disabled/>
            </span>
			<span v-else>
				<Button label="Signed Comment" icon="pi pi-info-circle" @click="toggle_sign_comment" class="p-button-raised p-button-text"/>
			</span>
        </div>
        <div v-if="$ability.can('change','service_sheet')" class="col-12 md:col lg:col xl:col text-center">
            <span v-if="service_sheet.main === false">
                <Button v-tooltip.bottom="'Set the current Service Sheet as the principal.'" icon="fa fa-star" class="p-button-raised p-button-text p-button-rounded" @click="principal_service_sheet"/>
            </span>
            <span v-if="service_sheet.main === true">
                <Button v-tooltip.bottom="'Set the current Service Sheet as the principal.'" icon="fa fa-star" class="p-button-raised p-button-text p-button-rounded" @click="principal_service_sheet" disabled="disabled"/>
            </span>
        </div>
    </div>

    <div v-if="items" class="flex mt-3">
        <div v-bind="$attrs" class="col">
            <TabMenu :model="items" @tab-change="change_tab($event)"/>
            <router-view :key="$route.fullPath"/>
        </div>
    </div>
   

	<Dialog header="New Service Sheet" v-model:visible="modal_new_service_sheet" :modal="true" :style="{width: '50rem'}">
		<form id="form" @submit.prevent="handle_submit()" class="p-fluid">
            <div class="container">
				<div class="row">
					<div class="col text-center">
						<div class="card">
							<h5> Call Arrival Datetime </h5>
							<div class="row">
								<div class="col-12 md:col-6">
									<i class="pi pi-calendar"></i> {{format_date(get_call.arrival_datetime)}}
								</div>
								<div class="col-12 md:col-6">
									<i class="pi pi-clock"></i> {{format_hour(get_call.arrival_datetime)}} 
								</div>
							</div>
						</div>
					</div>
					<div class="col text-center">
						<div class="card">
							<h5> Call Departure Datetime </h5>
							<div class="row">
								<div class="col-12 md:col-6">
									<i class="pi pi-calendar"></i> {{format_date(get_call.departure_datetime)}}
								</div>
								<div class="col-12 md:col-6">
									<i class="pi pi-clock"></i> {{format_hour(get_call.departure_datetime)}} 
								</div>
							</div>
						</div>
					</div>
				</div>
                <div class="row">
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <div class="p-float-label">     
                                <Calendar autocomplete="off" id="estimated_arrival_datetime" v-model="estimated_arrival_datetime" dateFormat="yy-mm-dd" />
                                <label for="estimated_arrival_datetime"> Estimated Arrival Date <b class="required_field">*</b></label>
                            </div>
                        </div>
                    </div>
					<div class="col-12 md:col-6">
						<div class="field">
                            <div class="p-float-label">     
                                <Calendar autocomplete="off" id="estimated_arrival_datetime" v-model="estimated_arrival_datetime_hour" :timeOnly="true" hourFormat="24"/>
                                <label for="estimated_arrival_datetime"> Hour <b class="required_field">*</b></label>
                            </div>
                        </div>
					</div>
                </div>
				<div class="row">
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <div class="p-float-label">     
                                <Calendar autocomplete="off" id="estimated_arrival_datetime" v-model="estimated_departure_datetime" dateFormat="yy-mm-dd"/>
                                <label for="estimated_arrival_datetime"> Estimated Departure Date <b class="required_field">*</b></label>
                            </div>
                        </div>
                    </div>
					<div class="col-12 md:col-6">
						<div class="field">
                            <div class="p-float-label">     
                                <Calendar autocomplete="off" id="estimated_arrival_datetime" v-model="estimated_departure_datetime_hour" :timeOnly="true" hourFormat="24"/>
                                <label for="estimated_arrival_datetime"> Hour <b class="required_field">*</b></label>
                            </div>
                        </div>
					</div>
                </div>
				<div v-if="get_create_service_sheet_errors.length > 0" style="color: red;">
					<div v-if="get_create_service_sheet_errors[0].estimated_arrival_datetime" class="row pb-5">
						<div class="col-12 md:col-6">
							<span> {{get_create_service_sheet_errors[0].estimated_arrival_datetime[0]}} </span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 md:col-6">
						<div class="field ">
							<div class="p-float-label">
								<Dropdown data-cy="dropdown_terminals" id="terminal" v-model="terminal" :options="get_all_terminals" optionLabel="name" :filter="true" />
								<label for="terminal"> Terminal <b class="required_field">*</b></label>
							</div>
						</div>
                    </div>
					<div v-if="terminal" class="col-12 md:col-6">
						<div v-tooltip.bottom="'Optional field in case an extra service sheet is necessary.'" class="field">
							<div class="p-float-label">
								<Dropdown id="terminal" v-model="terminal_extra" :options="get_all_terminals" optionLabel="name" :filter="true" :showClear="true" @change="check_duplicate()"/>
								<label for="terminal"> Extra Terminal <i class="fa fa-circle-info"> </i></label>
							</div>
						</div>
					</div>
				</div>
				<div v-if="get_create_service_sheet_errors.length > 0" class="row" style="color: red;">
					<span  v-if="get_create_service_sheet_errors[0].id_terminal">
						{{get_create_service_sheet_errors[0].id_terminal[0]}}
					</span>
					<span  v-if="get_create_service_sheet_errors[0].service_sheet">
						{{get_create_service_sheet_errors[0].service_sheet[0]}}
					</span>
				</div>
				<div v-if="error_empty">
					<span style="color: red;"> {{error_empty}} </span>
				</div>
            </div>    
        </form>
		<template #footer>
			<Button data-cy="create_new_service_sheet" label="Create" form="form" type="submit"/>
		</template>
	</Dialog>

	<Dialog header="Confirm" v-model:visible="modal_delete_service_sheet" :modal="true" :style="{width: '50rem'}">
		<div class="confirmation-content">
            <span> Are you sure you want to delete the current Service Sheet?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="close_modal_delete"/>
            <Button data-cy="confirm_delete_button" label="Yes" @click="delete_service_sheet" />
        </template>
	</Dialog>

    <Dialog header="Confirm" v-model:visible="modal_validate_service_sheet" :modal="true" :style="{width: '50rem'}">
		<div class="confirmation-content">
            <span> Are you sure you want to validate the current Service Sheet?</span>
        </div>
        <div class="text-center mt-4">
            <i v-if="load_validation == true" class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="modal_validate_service_sheet = false"/>
            <Button label="Yes" @click="validate_ss" :disabled="load_validation"/>
        </template>
	</Dialog>

	<div style="border-radius: 10px; box-shadow: 5px 5px 10px rgba(0,0,0,.101961)">
		<OverlayPanel ref="op_sign_comment" style="width: 300px" :breakpoints="{'960px': '75vw'}">
			<div class="row container">
				<p class="pt-3">{{ get_service_sheet.sign_comment }}</p>
			</div>
		</OverlayPanel>
	</div>

    <OverlayPanel ref="op" style="width: 450px" :breakpoints="{'960px': '75vw'}">
        <div class="row container">
            <p class="mt-2"> Required information: </p>
            <div v-if="!get_call.is_unconventional" class="col">
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li> Final Arrival Datetime </li>
                    <li> Final Departure Datetime </li>
                    <li> Final Passengers Disembarking </li>
                    <li> Final Passengers Embarking </li>
                    <li> Final Passengers Transit </li>
                </ul>
            </div>
            <div v-else class="col">
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
					<li> Final Arrival Datetime </li>
                    <li> Final Departure Datetime </li>
                    <li> Water services </li>
                </ul>
            </div>
            <div v-if="!get_call.is_unconventional" class="col-2 text-center">
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5;list-style-type: none;">
                    <li> 
                        <span v-if="get_service_sheet.arrival_datetime">
                            <i style="color: green;" class="fa fa-check done"></i> 
                        </span>
                        <span v-else>
                             <i style="color: red;" class="fa fa-xmark done"></i> 
                        </span> 
                    </li>
                    <li>
                        <span v-if="get_service_sheet.departure_datetime">
                            <i style="color: green;" class="fa fa-check done"></i> 
                        </span>
                        <span v-else>
                             <i style="color: red;" class="fa fa-xmark done"></i> 
                        </span>  
                    </li>
                    <li>
                        <span v-if="typeof(get_service_sheet.p_disembarking) == 'number'">
                            <i style="color: green;" class="fa fa-check done"></i> 
                        </span>
                        <span v-else>
                             <i style="color: red;" class="fa fa-xmark done"></i> 
                        </span>  
                    </li>
                    <li>
                        <span v-if="typeof(get_service_sheet.p_embarking) == 'number'">
                            <i style="color: green;" class="fa fa-check done"></i> 
                        </span>
                        <span v-else>
                             <i style="color: red;" class="fa fa-xmark done"></i> 
                        </span>  
                    </li>
                    <li>
                        <span v-if="typeof(get_service_sheet.p_transit) == 'number'">
                            <i style="color: green;" class="fa fa-check done"></i> 
                        </span>
                        <span v-else>
                             <i style="color: red;" class="fa fa-xmark done"></i> 
                        </span>  
                    </li>
                </ul>
            </div>
            <div v-else class="col-2 text-center">
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5;list-style-type: none;">
					<li> 
                        <span v-if="get_service_sheet.arrival_datetime">
                            <i style="color: green;" class="fa fa-check done"></i> 
                        </span>
                        <span v-else>
                             <i style="color: red;" class="fa fa-xmark done"></i> 
                        </span> 
                    </li>
                    <li>
                        <span v-if="get_service_sheet.departure_datetime">
                            <i style="color: green;" class="fa fa-check done"></i> 
                        </span>
                        <span v-else>
                             <i style="color: red;" class="fa fa-xmark done"></i> 
                        </span>  
                    </li>
                    <li> 
                        <span v-if="get_water_service.length > 0">
                            <i style="color: green;" class="fa fa-check done"></i> 
                        </span>
                        <span v-else>
                             <i style="color: red;" class="fa fa-xmark done"></i> 
                        </span> 
                    </li>
                </ul>
            </div>
        </div>
        <div class="row container mb-2">
            <div class="col text-end">
                <span v-if="!get_call.is_unconventional">
                    <span v-if="get_service_sheet.arrival_datetime && get_service_sheet.departure_datetime && typeof(get_service_sheet.p_disembarking) == 'number' && typeof(get_service_sheet.p_embarking) == 'number' && typeof(get_service_sheet.p_transit) == 'number' && !get_service_sheet.confirmed">
                        <Button icon="fa fa-check" label="Validate" @click="open_validation_modal"/>
                    </span>
                    <span v-else>
                        <Button icon="fa fa-check" label="Validate" @click="open_validation_modal" disabled/>
                    </span>
                </span>
                <span v-else>
                    <span v-if="get_service_sheet.arrival_datetime && get_service_sheet.departure_datetime && get_water_service.length > 0">
                        <Button icon="fa fa-check" label="Validate" @click="open_validation_modal"/>
                    </span>
                    <span v-else>
                        <Button icon="fa fa-check" label="Validate" @click="open_validation_modal" disabled/>
                    </span>
                </span>
                
            </div>
        </div>
    </OverlayPanel>
</template>

<script>
import TabMenu from 'primevue/tabmenu'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import OverlayPanel from 'primevue/overlaypanel'

// import ability from '../../services/ability'

export default {
    name: 'Service_sheet_menu',
    components: {
        TabMenu,
		Dialog,
		Button,
		Calendar,
		Dropdown,
		Toast,
        OverlayPanel,
    },
	data() {
		return {
			main_check: false,
			service_sheets: '',
			items: [],
			color: localStorage.getItem('primary_color'),
			modal_new_service_sheet: false,
			modal_delete_service_sheet: false,
			estimated_arrival_datetime: '',
			estimated_arrival_datetime_hour: '',
			format_estimated_arrival_datetime_hour: '',
			estimated_departure_datetime: '',
			estimated_departure_datetime_hour: '',
			terminal: '',
			terminal_extra: '',
			id_call: Number(this.$route.params.id),
			sid: Number(this.$route.params.sid),
			service_sheet: '',
			error_empty: '',
            modal_validate_service_sheet: false,
            load_validation: false
		}
	},
    computed: {
        get_service_sheets: function() {
            return this.$store.getters.get_service_sheets
        },
		get_all_terminals: function() {
			return this.$store.getters.all_terminals
		},
		get_service_sheet: function ()  {
			return this.$store.getters.get_service_sheet
		},
		swap() {
			{ return this.$route.meta.swap }
		},
		get_create_service_sheet_errors: function() {
			return this.$store.getters.get_create_service_sheet_errors
		},
		get_call: function(){
			return this.$store.getters.get_call
		},
        get_water_service: function() {
            return this.$store.getters.get_water_service
        }
    },
    async mounted() {
		this.$store.dispatch('load_call', (Number(this.$route.params.id)))
		if(Number(this.$route.params.sid)) {
			await this.$store.dispatch('load_service_sheet', (Number(this.$route.params.sid)))
			this.service_sheet = this.get_service_sheet
		}	
		
        await this.$store.dispatch('load_all_terminals')

        await this.$store.dispatch('load_service_sheets_by_call', (Number(this.$route.params.id)))

		let route_main_ss = false
		this.get_service_sheets.forEach((elements) => {
			let item = {}
			if(elements.id_terminal !== null) {
				if(elements.main === true) {
					item['icon'] = 'fa fa-star'
					this.main_check = true
					route_main_ss = elements.id
				}
				item['label'] = 'Terminal ' + elements.id_terminal.name
			} else {
				item['label'] = elements.number 
			}
            item['to'] = '/dashboard/call/' + Number(this.$route.params.id) + '/servicesheet/' + elements.id
			this.items.push(item)
		})

		// Si hay una main SS se redirecciona la url para cargar esa SS, si se carga el elemento con otra SS, que no se redireccione
		if (route_main_ss && isNaN(Number(this.$route.params.sid))) {
			this.$router.push({
				name: 'Service_sheet_template',
				params: { id: Number(this.$route.params.id), sid: route_main_ss },
			})
		}
        
		if(this.main_check == false && this.get_service_sheets.length > 0) {
			await this.$store.dispatch('set_main_service_sheet', this.get_service_sheets[0])
		}

        if(this.get_call.is_unconventional) {
            if(this.$route.params.sid) {
                await this.$store.dispatch('load_water_service', Number(this.$route.params.sid))
            }
        }
        
    },
	methods: {
		async principal_service_sheet() {
			await this.$store.dispatch('set_main_service_sheet', this.service_sheet)

			this.items = []

			await this.$store.dispatch('load_service_sheets_by_call', (Number(this.$route.params.id)))

			this.get_service_sheets.forEach((elements) => {
				let item = {}
				if(elements.id_terminal!==null) {
					if(elements.main === true) {
						item['icon'] = 'fa fa-star'
					}
					item['label'] = 'Terminal ' + elements.id_terminal.name
				} else {
					item['label'] = elements.number 
				}
				item['to'] = '/dashboard/call/' + Number(this.$route.params.id) + '/servicesheet/' + elements.id
				this.items.push(item)
			})
		},
		check_duplicate() {
			if(this.terminal_extra == this.terminal) {
				this.terminal_extra = ''
				this.$toast.add({severity:'warn', summary: 'Warning', detail: 'Cannot select the same terminal.', life: 3000});
			}
		},
		format_date(date) {
			return date.split('T')[0]
		},
		format_hour(date) {
			return date.split('T')[1].split(':')[0] + ':' + date.split('T')[1].split(':')[1]
		},
		async change_tab(event) {
			await this.$store.dispatch('load_service_sheet', this.items[event.index].to.split('/').pop())
			this.service_sheet = this.get_service_sheet
		},
		async validate_ss() {
            this.load_validation = true
			await this.$store.dispatch('validate_service_sheet', (Number(this.$route.params.sid)))
            this.load_validation = false
            this.modal_validate_service_sheet = false

		},
		async log_push() {
			this.id_call = Number(this.$route.params.id),
			this.sid = Number(this.$route.params.sid),
			await this.$router.push({name: 'Service_sheet_log', params: { id: this.id_call, sid: this.sid }})
		},
		async service_sheet_push() {
			this.id_call = Number(this.$route.params.id),
			this.sid = Number(this.$route.params.sid),
			await this.$router.push({name: 'Service_sheet_template', params: { id: this.id_call, sid: this.sid }})
		},
        open_validation_modal(){
            this.modal_validate_service_sheet = true
        },
		open_modal_new() {
			this.modal_new_service_sheet = true;
			this.estimated_arrival_datetime = new Date(this.get_call.arrival_datetime)
			this.estimated_departure_datetime = new Date(this.get_call.departure_datetime)
			this.estimated_arrival_datetime_hour = new Date(this.get_call.arrival_datetime)
			this.estimated_departure_datetime_hour = new Date(this.get_call.departure_datetime)
		},
		close_modal_new() {
			this.modal_new_service_sheet = false;
		},
		open_modal_delete() {
			this.modal_delete_service_sheet = true;
		},
		close_modal_delete() {
			this.modal_delete_service_sheet = false;
		},
		async handle_submit() {
			this.submitted = true;

			let res = await this.add_service_sheet()
            
            if(res){
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Incidence report Added', life: 3000});
            }

        },
		async add_service_sheet() {
			let service_sheet = {}
			let service_sheet_extra = {}
			this.error_empty == ''

			if(this.estimated_arrival_datetime == '' || this.estimated_arrival_datetime_hour == '' || this.estimated_departure_datetime == '' || this.estimated_departure_datetime_hour == '') {
				this.error_empty = 'Cannot create service sheet without dates.'
			} else  {
				
				service_sheet = {
					estimated_arrival_datetime: new Date(this.estimated_arrival_datetime.getFullYear(), this.estimated_arrival_datetime.getMonth(), this.estimated_arrival_datetime.getDate(), 
					this.estimated_arrival_datetime_hour.getHours(), this.estimated_arrival_datetime_hour.getMinutes()),
					estimated_departure_datetime: new Date(this.estimated_departure_datetime.getFullYear(), this.estimated_departure_datetime.getMonth(), this.estimated_departure_datetime.getDate(), 
					this.estimated_departure_datetime_hour.getHours(), this.estimated_departure_datetime_hour.getMinutes()),
					terminal: this.terminal,
					id_call: this.id_call
				}
				await this.$store.dispatch('create_service_sheet', service_sheet)

				if(this.terminal_extra == this.terminal){
					this.terminal_extra == ''
					this.$toast.add({severity:'warn', summary: 'Warn Message', detail: 'One service sheet was not created due to terminal duplication.', life: 3000});
				} else {
					if(this.terminal_extra) {
						service_sheet_extra = {
							estimated_arrival_datetime: new Date(this.estimated_arrival_datetime.getFullYear(), this.estimated_arrival_datetime.getMonth(), this.estimated_arrival_datetime.getDate(), 
							this.estimated_arrival_datetime_hour.getHours(), this.estimated_arrival_datetime_hour.getMinutes()),
							estimated_departure_datetime: new Date(this.estimated_departure_datetime.getFullYear(), this.estimated_departure_datetime.getMonth(), this.estimated_departure_datetime.getDate(), 
							this.estimated_departure_datetime_hour.getHours(), this.estimated_departure_datetime_hour.getMinutes()),
							terminal: this.terminal_extra,
							id_call: this.id_call
						}
						await this.$store.dispatch('create_service_sheet', service_sheet_extra)
					}

					await this.$store.dispatch('load_call', (Number(this.$route.params.id)))
				}
			}

			await this.$store.dispatch('load_service_sheets_by_call', (Number(this.$route.params.id)))
			this.items = []
			this.get_service_sheets.forEach((elements) => {
				let item = {}
				if(elements.id_terminal!==null) {
					if(elements.main === true) {
						item['icon'] = 'fa fa-star'
					}
					item['label'] = 'Terminal ' + elements.id_terminal.name
				} else {
					item['label'] = elements.number 
				}
				item['to'] = '/dashboard/call/' + Number(this.$route.params.id) + '/servicesheet/' + elements.id
				this.items.push(item)
			})


			if(this.get_create_service_sheet_errors.length == 0 && this.error_empty == ''){
				this.close_modal_new();
				this.reset_form()
				this.$toast.add({severity:'success', summary: 'Success', detail:'Service sheet added', life: 3000});
			}
            
		},
		reset_form() {
			this.estimated_arrival_datetime = ''
			this.estimated_arrival_datetime_hour = ''
			this.estimated_departure_datetime = ''
			this.estimated_departure_datetime_hour = ''
			this.terminal = ''
			this.terminal_extra = ''
		},
		async delete_service_sheet() {
			
			await this.$store.dispatch('load_service_sheets_by_call', (Number(this.$route.params.id)))
            await this.$store.dispatch('delete_service_sheet', Number(this.$route.params.sid))
			this.service_sheets = this.get_service_sheets;
			if(this.service_sheets.length > 0){
				await this.$router.push({name: 'Service_sheet_template', params: { id: Number(this.$route.params.id), sid: this.service_sheets[0].id }})
			} else {
				await this.$router.push({name: 'Service_sheet_menu', params: { id: Number(this.$route.params.id)}})
			}
			this.items = []
			this.get_service_sheets.forEach((elements) => {
				let item = {}
				
				if(elements.id_terminal!==null) {
					if(elements.main === true) {
						item['icon'] = 'fa fa-star'
					}
					item['label'] = 'Terminal ' + elements.id_terminal.name
				} else {
					item['label'] = elements.number 
				}
				
				item['to'] = '/dashboard/call/' + Number(this.$route.params.id) + '/servicesheet/' + elements.id
				this.items.push(item)
			})

			this.close_modal_delete()
			this.$toast.add({severity:'success', summary: 'Success', detail:'Service Sheet Deleted', life: 3000});

        },
        toggle(event) {
            this.$refs.op.toggle(event);
        },
		toggle_sign_comment(event) {
            this.$refs.op_sign_comment.toggle(event);
        }
	}
}
</script>

<style>

.done{
    font-weight: bold;
}

.p-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content {
    width: 100%;
}


</style>

