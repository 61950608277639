<template>
    <Toast/>
    <div class="row p-fluid">
        <div class="col-12 md:col-6 lg:col-3">
            <Panel header="Arrival Date">
                <div class="row pt-1 pb-2 ">
                    <div class="col-12 xl:col-7 text-center" style="font-size: 1.5em;">        
                        <span v-if="Object.prototype.toString.call(service_sheet.arrival_datetime) === '[object Date]'"> 
                            <i class="pi pi-calendar me-1" style="font-size: 1.3rem"></i> <span data-testid="e_arrival_date">{{format_date(service_sheet.arrival_datetime)}}</span>
                        </span>
                        <span v-else-if="service_sheet.arrival_datetime">
                            <i class="pi pi-calendar me-1" style="font-size: 1.3rem"></i> <span data-testid="e_arrival_date">{{service_sheet.arrival_datetime}}</span>
                        </span>
                        <span v-else-if="Object.prototype.toString.call(service_sheet.estimated_arrival_datetime) === '[object Date]'" >
                            <i class="pi pi-calendar me-1" style="font-size: 1.3rem"></i> <span data-testid="e_arrival_date">{{format_date(service_sheet.estimated_arrival_datetime)}}</span>
                        </span>
                        <span v-else-if="service_sheet.estimated_arrival_datetime" >
                            <i class="pi pi-calendar me-1" style="font-size: 1.3rem"></i> <span data-testid="e_arrival_date">{{service_sheet.estimated_arrival_datetime}}</span>
                        </span>
                    </div>
                    <div class="col-12 xl:col-5 text-center" style="font-size: 1.5em;">
                        <span v-if="Object.prototype.toString.call(service_sheet.arrival_datetime) === '[object Date]'"> 
                            <i class="pi pi-clock me-1" style="font-size: 1.3rem"> </i> <span data-testid="e_arrival_hour">{{moment(service_sheet.arrival_datetime_hour)}} </span>
                        </span>
                        <span v-else-if="service_sheet.arrival_datetime">
                            <i class="pi pi-clock me-1" style="font-size: 1.3rem"> </i> <span data-testid="e_arrival_hour">{{service_sheet.arrival_datetime_hour}} </span>
                        </span>
                        <span v-else-if="Object.prototype.toString.call(service_sheet.estimated_arrival_datetime) === '[object Date]'" >
                            <i class="pi pi-clock me-1" style="font-size: 1.3rem"> </i> <span data-testid="e_arrival_hour">{{moment(service_sheet.estimated_arrival_datetime_hour)}} </span>
                        </span>
                        <span v-else-if="service_sheet.estimated_arrival_datetime" >
                            <i class="pi pi-clock me-1" style="font-size: 1.3rem"> </i> <span data-testid="e_arrival_hour">{{service_sheet.estimated_arrival_datetime_hour}} </span>
                        </span>
                    </div>
                </div>
            </Panel>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
            <Panel header="Departure Date">
                <div class="row pt-1 pb-2">
                    <div class="col-12 xl:col-7 text-center" style="font-size: 1.5em;">
                        <span v-if="Object.prototype.toString.call(service_sheet.departure_datetime) === '[object Date]'"> 
                            <i class="pi pi-calendar me-1" style="font-size: 1.3rem"></i> <span data-testid="e_departure_date">{{format_date(service_sheet.departure_datetime)}}</span>
                        </span>
                        <span v-else-if="service_sheet.departure_datetime">
                            <i class="pi pi-calendar me-1" style="font-size: 1.3rem"></i> <span data-testid="e_departure_date">{{service_sheet.departure_datetime}}</span>
                        </span>
                        <span v-else-if="Object.prototype.toString.call(service_sheet.estimated_departure_datetime) === '[object Date]'">
                            <i class="pi pi-calendar me-1" style="font-size: 1.3rem"></i> <span data-testid="e_departure_date">{{format_date(service_sheet.estimated_departure_datetime)}}</span>
                        </span>
                        <span v-else-if="service_sheet.estimated_departure_datetime">
                            <i class="pi pi-calendar me-1" style="font-size: 1.3rem"></i> <span data-testid="e_departure_date">{{service_sheet.estimated_departure_datetime}}</span>
                        </span>
                        <span v-else>
                            -
                        </span>
                    </div>
                    <div class="col-12 xl:col-5 text-center" style="font-size: 1.5em;">
                        <span v-if="Object.prototype.toString.call(service_sheet.departure_datetime) === '[object Date]'"> 
                            <i class="pi pi-clock me-1" style="font-size: 1.3rem"> </i> <span data-testid="e_departure_hour">{{moment(service_sheet.departure_datetime_hour)}}</span>
                        </span>
                        <span v-else-if="service_sheet.departure_datetime">
                            <i class="pi pi-clock me-1" style="font-size: 1.3rem"> </i> <span data-testid="e_departure_hour">{{service_sheet.departure_datetime_hour}}</span>
                        </span>
                        <span v-else-if="Object.prototype.toString.call(service_sheet.estimated_departure_datetime) === '[object Date]'">
                            <i class="pi pi-clock me-1" style="font-size: 1.3rem"> </i> <span data-testid="e_departure_hour">{{moment(service_sheet.estimated_departure_datetime_hour)}}</span>
                        </span>
                        <span v-else-if="service_sheet.estimated_departure_datetime">
                            <i class="pi pi-clock me-1" style="font-size: 1.3rem"> </i> <span data-testid="e_departure_hour">{{service_sheet.estimated_departure_datetime_hour}}</span>
                        </span>
                        <span v-else>
                            -
                        </span>
                    </div>
                </div>
            </Panel>
        </div>
        <div v-if="user_port=='Port de Barcelona'" class="col-6 md:col">
            <Panel header="Over Time">
                <template #icons>
                    <button v-if="check_user_edit" class="p-link mr-0" style="color: white" @click="toggle">
                        <span v-if="input_overtime==false" class="fa fa-pen" @click="input_overtime = true"></span>
                        <span v-else class="fa fa-check" @click="save_extra"> </span>
                    </button>
                </template>
                <div class="row pt-1 pb-2">
                    <div class="col text-center" style="font-size: 1.5em;">
                        <span v-if="input_overtime == false">
                            <div v-if="service_sheet.over_time" data-testid="overtime">
                                {{ service_sheet.over_time }}
                            </div>
                            <div v-else>
                                -
                            </div>
                        </span>
                        <span v-else>
                            <InputNumber v-model="overtime" :min="0"/>
                        </span>
                    </div>
                </div>
            </Panel>
        </div>
        <div v-if="user_port=='Port de Barcelona'" class="col-6 md:col">
            <Panel header="Over Night">
                <template #icons>
                    <button v-if="check_user_edit" class="p-link mr-0" style="color: white" @click="toggle">
                        <span v-if="input_overnight==false" class="fa fa-pen" @click="input_overnight = true"></span>
                        <span v-else class="fa fa-check" @click="save_extra"> </span>
                    </button>
                </template>
                <div class="row pt-1 pb-2">
                    <div class="col text-center" style="font-size: 1.5em;">
                        <span v-if="input_overnight == false">
                                <div v-if="service_sheet.over_night" data-testid="overnight">
                                {{service_sheet.over_night}}
                            </div>
                            <div v-else>
                                -
                            </div>
                        </span>
                        <span v-else>
                            <InputNumber v-model="overnight" :min="0"/>
                        </span>
                    </div>
                </div>
            </Panel>
        </div>
        <div v-if="user_port=='Port de Barcelona'" class="col-12 md:col" data-testid="weekend">
            <Panel header="Weekend">
                <template #icons>
                    <button v-if="check_user_edit" class="p-link mr-0" style="color: white" @click="toggle">
                        <span v-if="weekend==false" class="fa fa-rotate" @click="weekend = true; save_extra()"></span>
                        <span v-else class="fa fa-rotate" @click="weekend = false; save_extra()"> </span>
                    </button>
                </template>
                <div class="row pt-1 pb-2">
                    <div v-if="service_sheet.weekend === true" class="col text-center" style="font-size: 1.5em;" > 
                        <i class="fa fa-check"></i>
                    </div>
                    <div v-else class="col text-center" style="font-size: 1.5em;">
                        <i class="fa fa-xmark"></i>
                    </div>
                </div>
            </Panel>
        </div>
        <div v-if="user_port=='Puerto de Málaga'" class="col-6 md:col" data-testid="billable_hours">
            <Panel header="Billable Hours">
                <template #icons>
                    <button v-if="check_user_edit" class="p-link mr-0" style="color: white" @click="toggle">
                        <span v-if="input_billable_hours==false" class="fa fa-pen" @click="input_billable_hours = true"></span>
                        <span v-else class="fa fa-check" @click="save_extra()"> </span>
                    </button>
                </template>
                <div class="row pt-1 pb-2">
                    <span v-if="input_billable_hours == false">
                        <div v-if="service_sheet.billable_hours" class="col text-center" style="font-size: 1.5em;">
                            {{ service_sheet.billable_hours }}
                        </div>
                        <div v-else class="col text-center" style="font-size: 1.5em;">   
                            <span style="font-size: .5em; color: orange;"> Fill the final schedule to get the billable hours. </span>
                        </div>
                    </span>
                    <span v-else>
                        <InputNumber v-model="billable_hours" :minFractionDigits="2" />
                    </span>
                </div>
            </Panel>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import Panel from 'primevue/panel'
import Toast from 'primevue/toast'
import InputNumber from 'primevue/inputnumber'

export default {
    name: "Dates",
    components: {
        Panel,
        InputNumber,
        Toast
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            input_overtime: false,
            input_overnight: false,
            input_weekend: false,
            input_billable_hours: false,
            overtime: this.service_sheet.over_time,
            overnight: this.service_sheet.over_night,
            weekend: this.service_sheet.weekend,
            billable_hours: this.service_sheet.billable_hours
        }
    },
    props: {
        service_sheet: {
            type: Object,
            required: true
        },
        user_port: {
            type: String,
            required: true
        },
    },
    computed: {
        get_current_user: function () {
            return this.$store.getters.get_current_user
        },
        check_user_edit: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Admins' ) {
                    return true
                }
            }
            return false
        },
    },
    methods: {
        format_date(value) {
            return moment(value).format().split('T')[0]
        },
        moment: function(date) {
            if(date == null) {
                return null
            } else if(typeof(date) === "object") {
                return moment(date).format().split('T')[1].split(':')[0] + ':' + moment(date).format().split('T')[1].split(':')[1]
            } else {
                return date
            } 
        },
        async save_extra() {

            let item = {    
                id: this.service_sheet.id,
                over_time: this.overtime,
                over_night: this.overnight,
                weekend: this.weekend,
                billable_hours: this.billable_hours
            }

            await this.$store.dispatch('update_extra_service_sheet', item)
            this.$toast.removeAllGroups()
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Data updated', life: 3000});
            this.input_overtime = false
            this.input_overnight = false
            this.input_weekend = false
            this.input_billable_hours = false
        }
    }
}
</script>

<style scoped>
.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}


:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}

.sticky {
    position: fixed;
    top: 0;
    z-index: 2;
    display: none;
    background-color: #002D72;
    color: white;
}

.change_visibility {
    display: block;
}

</style>