import axios from 'axios'

const state = {
    wifi: {},
}

const mutations = {
    SET_WIFI_SERVICE(state, wifi) {
        state.wifi = wifi
    },
    CREATE_WIFI_SERVICE(state, wifi) {
        state.wifi = wifi
    },
    DELETE_WIFI_SERVICE(state) {
        state.wifi = {}
    }
}

const actions = {
	async load_wifi_service({commit}, id) {
		await axios.get(process.env.VUE_APP_API_URL + 'wifi/get_service/' + id) 
			.then((response) => {
				commit('SET_WIFI_SERVICE', response.data)
			})
	},
    async create_wifi_service({commit}, id) {
        await axios.post(process.env.VUE_APP_API_URL + 'wifi/', {
            id_service_sheet: id
        })
            .then((response) => {
                commit('CREATE_WIFI_SERVICE', response.data)
            })
    },
    async delete_wifi_service({commit}, wifi_service) {
        await axios.delete(process.env.VUE_APP_API_URL + 'wifi/' + wifi_service.id + '/')
            .then((response) => {
                commit('DELETE_WIFI_SERVICE', response.data)
            })
    }
}

const getters = {
    get_wifi_service: (state) => state.wifi,
}

export default {
    state,
    mutations,
    actions,
    getters
};