<template>
    <Calendarcalls/>
</template>

<script>
import Calendarcalls from '@/components/Calendar/Calendar_calls.vue'
export default {
    name: 'Calendar',
    components: {
        Calendarcalls
    }
}
</script>

<style scoped>

</style>