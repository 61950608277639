<template>
    <Toast/>
    <div class="container">
        <div class="mt-4">
            <div class="row justify-content-between">
                <div class="col-6 my-auto">
                    <h4> Groups and users </h4>
                </div>
                <div class="col-6 text-end my-auto">
                    <GroupCreation/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col my-auto ">
                <Dropdown :filter="true" :options="get_group_permissions" optionLabel="name"  v-model="current_group" @change="group_select($event)" placeholder="Select a group..."/>
            </div>
        </div>
        <div v-if="current_group != ''" class="row mt-2">
            <div class="col my-auto">
                <h1> {{current_group.name}} </h1>
            </div>
            <div class="col text-end my-auto">
                <InputText v-model="filter_name" placeholder="Search by name..."/>
            </div>  
        </div>
    </div>
    <div class="container">
        <div class="row mt-3" >
            <PickList v-model="data" dataKey="id" listStyle="height:342px" @move-to-target="move_to_target($event)" @move-to-source="move_to_source($event)">
                <template #sourceheader>
                    Available
                </template>
                <template #targetheader>
                    Selected
                </template>
                <template #item="slotProps">
                    <div class="product-item">
                        <div class="circle-image me-3">
                            <img :src="slotProps.item.profile.picture" size="large" shape="circle" aria-haspopup="true" aria-controls="overlay_tmenu"/>
                        </div>
                        <div class="product-list-detail">
                            <h6 class="mb-2">{{slotProps.item.username}}</h6>
                        </div>
                    </div>
                </template>
            </PickList>
        </div>
    </div>
    <div v-if="current_group != ''" class="container mt-4 mb-5" >
        <div class="row " >
            <div class="col text-end">
                <Button class="p-button-sm p-button-danger" label="Delete Group" @click="modal_delete_group"/> 
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <h3> Permissions </h3>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col card align-self-start">
                <div class="row container" v-for="role in get_menu_items" :key="role">
                    <div class="col-6 my-auto">
                        {{role.name}}
                    </div>
                    <div v-if="current_group.manageable == false" class="col-6 text-end my-auto">
                        <Checkbox v-model="current_group.id_menu_bar_items" :value="role.id" @input="select_group($event, role.name)" :disabled="true"/>
                    </div>
                    <div v-else class="col-6 text-end my-auto">
                        <Checkbox v-model="current_group.id_menu_bar_items" :value="role.id" @input="select_group($event, role.name)"/>
                    </div>
                </div>
                <div class="row container">
                    <div v-if="current_group.manageable == true" class="col">
                        <Button label="Restore" icon="fa fa-arrow-rotate-right" @click="open_modal_restore"/>
                    </div>
                    <div v-if="current_group.manageable == true" class="col text-end">
                        <Button label="Save" class="p-button-success" @click="save_group_permissions"/>
                    </div>
                </div>
            </div>
            <span class="card col-9 ms-2 p-0">
                <ScrollPanel style="height: 700px" class="custombar1">
                    <div  class="row justify-content-between">
                        <div class="col-6" v-for="(item, index) in perms" :key="item">
                            <h1 style="border-bottom: 1px solid lightgray; padding-bottom: 7px;" class="text-center">{{format_title(index)}}</h1>
                            <div v-for="item1 in item['perms']" :key="item1" class="col">
                                <div class="row">
                                    <div class="col-2">
                                        <span v-if="current_group.manageable == false" class="my-auto">
                                            <InputSwitch v-model="item1['checked']" :value="item1['id']" :binary="true" :disabled="true"/> 
                                        </span>
                                        <span v-else-if="item1['required'] == true" class="my-auto">
                                            <InputSwitch v-model="item1['checked']" :value="item1['id']" :binary="true" :disabled="true"/>    
                                        </span>
                                        <span v-else class="my-auto">
                                            <InputSwitch v-model="item1['checked']" :value="item1['id']" :binary="true" @input="check_view(item1)"/>
                                        </span>
                                    </div>
                                    <div class="col-10">
                                        <label class="my-auto">{{item1["perm"].replace(/_/g, ' ')}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollPanel >
            </span>
        </div>
    </div>
    
    <Dialog header="Confirmation" v-model:visible="display_modal_confirmation" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div class="container">
            <div class="row">
                <div class="col">
                    Do you really want to delete this group ?
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="display_modal_confirmation=false"/>
            <Button label="Confirm" @click="delete_group"/>
        </template>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="display_modal_restore_perms" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div class="container">
            <div class="row">
                <div class="col">
                    Do you really want to restore the previous permissions ?
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="display_modal_restore_perms=false"/>
            <Button label="Confirm" @click="restore_perms"/>
        </template>
    </Dialog>
    
</template>

<script>
import PickList from 'primevue/picklist'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import ScrollPanel  from 'primevue/scrollpanel'
import Checkbox from 'primevue/checkbox' 

import GroupCreation from '@/components/Permissions/Group_creation'

export default {
    name: 'Permissions',
    components: {
        PickList,
        Dropdown,
        GroupCreation,
        Button,
        Dialog,
        InputSwitch,
        InputText,
        Toast,
        ScrollPanel,
        Checkbox
    },
    data() {
        return{
            items: [
                'Management',
                'Calls',
                'Records',
                'Incidences',
                'Maintenance',
                'Billing',
                'Statistics',
            ],
            perms: [],
            format_perms: [],
            prev_roles: [],
            option: '',
            current_group: '',
            display_modal_confirmation: false,
            display_modal_restore_perms: false,
            filter_name: '',
            prev_menu_items: []
        }
    },
    async mounted() {
        var button1 =  document.querySelectorAll(".pi-angle-double-right")
        button1.forEach(btn1 => {
            btn1.parentNode.style.display = 'none';
        });
        var button2 =  document.querySelectorAll(".pi-angle-double-left")
        button2.forEach(btn2 => {
            btn2.parentNode.style.display = 'none'
        });
        var button3 =  document.querySelectorAll(".pi-angle-right")
        button3.forEach(btn3 => {
            btn3.parentNode.setAttribute('disabled', 'disabled');
        });
        var button4 =  document.querySelectorAll(".pi-angle-left")
        button4.forEach(btn4 => {
            btn4.parentNode.setAttribute('disabled', 'disabled');
        });

        await this.$store.dispatch('load_menu_items')
        await this.$store.dispatch('load_all_users')
        await this.$store.dispatch('load_group_permissions')
    },
    computed:{
        data() {
            return [[...this.get_group_users_not], [...this.get_group_users]]
        },
        get_all_users: function() {
            return this.$store.getters.get_all_users.filter(item => item.username.includes(this.filter_name))
        },
        get_group_permissions: function() {
            return this.$store.getters.get_group_permissions
        },
        get_group_users: function() {
            return this.$store.getters.get_group_users.filter(item => item.username.includes(this.filter_name))
        },
        get_group_users_not: function() {
            return this.$store.getters.get_group_users_not.filter(item => item.username.includes(this.filter_name))
        },
        get_editable_group_permissions: function() {
            return this.$store.getters.get_editable_group_permissions
        },
        get_menu_items: function() {
            return this.$store.getters.get_menu_items
        },
        get_custom_permissions_group: function() {
            return this.$store.getters.get_custom_permissions_group
        }
    },
    methods: {
        format_title(string){
            return (string.charAt(0).toUpperCase() + string.slice(1)).replace(/_/g, " ")
        },
        modal_delete_group() {
            this.display_modal_confirmation = true
        },
        delete_group() {
            this.$store.dispatch('delete_group_user', this.current_group.id)
            this.display_modal_confirmation = false
            this.current_group = ''
            this.data = [
                this.get_all_users, []
            ]
        },
        open_modal_restore() {
            this.display_modal_restore_perms = true
        },
        async restore_perms() {
            this.current_group.id_menu_bar_items = this.prev_menu_items
            await this.$store.dispatch('load_editable_permissions_group', this.current_group.id)
            this.perms = this.get_editable_group_permissions
            this.prev_roles = this.perms
            this.display_modal_restore_perms = false
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'info', summary: 'Permissions', detail:'Previous permissions restored.', life: 3000});

        },
        async group_select(data) {
            
            await this.$store.dispatch('load_group_users', data.value.id)
            await this.$store.dispatch('load_group_users_not', data.value.id)
            await this.$store.dispatch('load_editable_permissions_group', data.value.id)

            this.perms = this.get_editable_group_permissions
            this.prev_roles = this.current_group.id_menu_bar_items

            var button3 =  document.querySelectorAll(".pi-angle-right")
            button3.forEach(btn3 => {
                btn3.parentNode.removeAttribute('disabled');
            });
            var button4 =  document.querySelectorAll(".pi-angle-left")
            button4.forEach(btn4 => {
                btn4.parentNode.removeAttribute('disabled');
            });

            this.prev_menu_items = this.current_group.id_menu_bar_items
        },
        async select_group(event, role) {
            
            await this.$store.dispatch('load_custom_permissions_group', role.toLowerCase())

            if(Object.keys(event).length > Object.keys(this.prev_roles).length) {
                // Permisos base
                for(var item in this.perms) {
                    // Nuevos permisos introducidos
                    for(var data in this.get_custom_permissions_group){
                        if(data == item) {

                            

                            // Añadir en grupos, el nuevo rol introducido (true = obligatorio, false = no obligatorio)
                            this.perms[item].groups[Object.keys(this.get_custom_permissions_group[data].groups)] = this.get_custom_permissions_group[data].groups[role]
                            
                            for(var attribute in this.get_custom_permissions_group[data]['perms']) {
                                
                                if(this.perms[data]['perms'][attribute].name.includes('view')) {
                                    this.perms[data]['perms'][attribute].required = true
                                    this.perms[data]['perms'][attribute].checked = true
                                }
                            }

                            delete this.get_custom_permissions_group[data]
                        } 
                    }
                }

                this.perms = {...this.perms,...this.get_custom_permissions_group}

            } else {
                for(var i in this.perms) {
                    if(Object.keys(this.perms[i]['groups']).length == 1) {
                        if(Object.keys(this.perms[i]['groups']) == role) {
                            // SOLO TIENE 1 GRUPO Y SE HA DE ELIMINAR EL PERMISO
                            delete this.perms[i]
                        }
                    } else {
                        if(Object.keys(this.perms[i]['groups']).includes(role)){
                            // TIENE MAS DE 1 GRUPO Y SE HA DE MODIFICAR EL PERMISO
                            delete this.perms[i].groups[role]

                            // Poner como obligatorios/no obligatorios todos los permisos relacionados con los nuevos roles
                            for(var check in this.perms[i].groups) {
                                for(var index in this.perms[i].perms) {
                                    if(this.perms[i].groups[check] == true) {
                                        if(this.perms[i].perms[index].perm.includes('view')){
                                            this.perms[i].perms[index].required = true
                                            this.perms[i].perms[index].checked = true
                                        } 
                                    } else {
                                        this.perms[i].perms[index].required = false
                                    }
                                    
                                } 
                            }
                        }
                    }
                }
            }

            // this.perms = {...this.perms,...this.get_custom_permissions_group}
            this.prev_roles = [...event]

        },
        save_group_permissions() {
            this.format_perms = []
            for(var item in this.perms) {
                for(var data in this.perms[item]['perms']) {
                    if(this.perms[item]['perms'][data].checked == true) {
                        this.format_perms.push(this.perms[item]['perms'][data].id)
                    }
                }
            }
            
            let group = {
                id: this.current_group.id,
                permissions: this.format_perms,
                id_menu_bar_items: this.current_group.id_menu_bar_items
            }

            this.$store.dispatch('update_group_permissions', group)
            this.$toast.add({severity:'success', summary: 'Success', detail:'Permissions updated successfully.', life: 3000});
            
        },
        async move_to_target(event) {
            for(var i in event.items) {
                event.items[i].groups.push(this.current_group.id)
                this.$store.dispatch('update_user_group', event.items[i])
            }
        },
        async move_to_source(event) {
            for(var i in event.items) {
                for(var j in event.items[i].groups){
                    if(event.items[i].groups[j] == this.current_group.id) {
                        event.items[i].groups.splice(j,1)
                        this.$store.dispatch('update_user_group', event.items[i])
                    }
                }
            }
        },
        async check_view(data) {
            if(data.checked == true) {
                var check = data.perm.split(' ')[2]
                check = 'view_' + check
                for(var item in this.perms) {
                    for(var value in this.perms[item]) {
                        for(var key in this.perms[item][value]) {
                            if(this.perms[item][value][key].name == check) {
                                this.perms[item][value][key].checked = true
                            }
                        }
                        
                    }
                }
            } 

            if(data.checked == false) {
                if(data.name.split('_')[0] == 'view') {
                    var permission_check = data.perm.split(' ')[2]
                    for(var index in this.perms[permission_check].perms) {
                        this.perms[permission_check].perms[index]['checked'] = false
                    }
                }
            }
        }
    } 
}

</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 0px;
}

.product-item {
	display: flex;
	align-items: center;
	padding: .5rem;
	width: 100%;
}

.product-list-detail {
    flex: 1 1 0;
}

.product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
    font-size: .875rem;
}

.product-category {
    vertical-align: middle;
    line-height: 1;
    font-size: .875rem;
}

.card-img-top {
    max-height: 100px;
    min-height: 100px;
    object-fit: cover;
    border-radius: 100%;
}

:deep(.p-picklist-source-controls){
    display: none;
}

:deep(.p-picklist-target-controls){
    display: none;
}

.row{
    --bs-gutter-x: 0;
}

:deep(.p-scrollpanel-wrapper) {
    border-right: 9px solid #f8f9fa;
}

:deep(.p-scrollpanel-bar) {
    background-color: lightgray !important;
    opacity: 1;
    transition: background-color .2s;
}

.circle-image{
  cursor:pointer;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px;
  padding: 0;
}

.circle-image img{
  width:100%;
  height:100%;
  object-fit: cover;
}

</style>