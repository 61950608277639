<template>

    <div class="row pt-4 pb-3">
        <div class="col-12 md:col-6">
            <Panel data-cy="panel_new_call" header="New Call">
                <div class="container">
                    <form id="form_new_call" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                        <div class="row ">
                            <div class="col-12 xl:col-6">
                                <div class="p-float-label bottom_margin mt-2">
                                    <Calendar data-cy="calendar_arrival_date" autocomplete="off" manualInput id="arrival_datetime" dateFormat="yy-mm-dd" v-model="v$.arrival_datetime.$model"/>
                                    <label for="arrival_datetime"> Arrival date* </label>
                                </div>
                                <small v-if="Object.keys(get_port_call_errors).length !== 0 && get_port_call_errors.arrival_datetime" class="p-error">
                                    <span v-for="error in get_port_call_errors.arrival_datetime" :key="error"> {{ error }} <br /></span>
                                </small>
                                <small v-if="(v$.arrival_datetime.$invalid && submitted) || v$.arrival_datetime.$pending.$response" class="p-error">
                                    {{ v$.arrival_datetime.required.$message.replace("Value","Arrival Date")}}
                                </small>
                            </div>
                            <div class="col-12 xl:col-6">
                                <div class="p-float-label bottom_margin mt-2">
                                    <Calendar data-cy="calendar_arrival_hour" autocomplete="off" id="arrival_datetime_hour" v-model="arrival_datetime_hour" :timeOnly="true" hourFormat="24"  @change="arrival_datetime_hour = num2time($event)"/>
                                    <label for="arrival_datetime_hour"> Hour* </label>
                                </div>
                                <small v-if="Object.keys(get_port_call_errors).length !== 0 && get_port_call_errors.arrival_datetime_hour" class="p-error">
                                    <span v-for="error in get_port_call_errors.arrival_datetime_hour" :key="error">{{error}}<br/></span>
                                </small>
                                <small v-if="(v$.arrival_datetime_hour.$invalid && submitted) || v$.arrival_datetime_hour.$pending.$response" class="p-error">
                                    {{v$.arrival_datetime_hour.required.$message.replace("Value", "Arrival Hour")}}
                                </small>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-12 xl:col-6 mt-2">
                                <div class="p-float-label bottom_margin">
                                    <Calendar data-cy="calendar_departure_date" autocomplete="off" id="departure_datetime" dateFormat="yy-mm-dd" v-model="departure_datetime" :class="{'p-error': (v$.arrival_datetime.$invalid && submitted) || port_call_errors.departure_datetime}"/>
                                    <label for="departure_datetime"> Departure date* </label>
                                </div>
                                <small
                                    v-if="
                                        Object.keys(
                                            get_port_call_errors
                                        ).length !== 0 &&
                                        get_port_call_errors.departure_datetime
                                    "
                                    class="p-error"
                                    ><span
                                        v-for="error in get_port_call_errors.departure_datetime"
                                        :key="error"
                                        >{{ error }}<br /></span
                                >
                                </small>
                                <small
                                    v-if="
                                        (v$.departure_datetime
                                            .$invalid &&
                                            submitted) ||
                                        v$.departure_datetime
                                            .$pending.$response
                                    "
                                    class="p-error"
                                    >{{
                                        v$.departure_datetime.required.$message.replace(
                                            "Value",
                                            "Departure Date"
                                        )
                                    }}
                                </small>
                            </div>
                            <div class="col-12 xl:col-6 mt-2">
                                <div class="p-float-label bottom_margin">
                                    <Calendar data-cy="calendar_departure_hour" autocomplete="off" id="departure_datetime_hour" v-model="departure_datetime_hour" :timeOnly="true" hourFormat="24" @change="departure_datetime_hour = num2time($event)"/>
                                    <label for="departure_datetime_hour">
                                        Hour* 
                                    </label>
                                </div>
                                <small
                                    v-if="
                                        Object.keys(
                                            get_port_call_errors
                                        ).length !== 0 &&
                                        get_port_call_errors.departure_datetime_hour
                                    "
                                    class="p-error"
                                    ><span
                                        v-for="error in get_port_call_errors.departure_datetime_hour"
                                        :key="error"
                                        >{{ error }}<br /></span
                                >
                                </small>
                                <small
                                    v-if="
                                        (v$.departure_datetime_hour
                                            .$invalid &&
                                            submitted) ||
                                        v$.departure_datetime_hour
                                            .$pending.$response
                                    "
                                    class="p-error"
                                    >{{
                                        v$.departure_datetime_hour.required.$message.replace(
                                            "Value",
                                            "Departure Hour"
                                        )
                                    }}
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="p-float-label bottom_margin mt-2">
                                    <Dropdown data-cy="dropdown_ships" id="id_ship" v-model="id_ship" :options="all_ships" optionLabel="name" filter @change="load_ship_agent($event)"
                                        :class="{
                                            'p-error':
                                                (v$
                                                    .arrival_datetime
                                                    .$invalid &&
                                                    submitted) ||
                                                port_call_errors.id_ship,
                                        }"
                                    />
                                    <label for="id_ship"> Ship* </label>
                                </div>
                                <small
                                    v-if="
                                        Object.keys(
                                            port_call_errors
                                        ).length !== 0 &&
                                        port_call_errors.id_ship
                                    "
                                    class="p-error"
                                    ><span
                                        v-for="error in port_call_errors.id_ship"
                                        :key="error"
                                        >{{ error }}<br /></span
                                ></small>
                                <small
                                    v-if="
                                        (v$.id_ship.$invalid &&
                                            submitted) ||
                                        v$.id_ship.$pending
                                            .$response
                                    "
                                    class="p-error"
                                    >{{
                                        v$.id_ship.required.$message.replace(
                                            "Value",
                                            "Ship"
                                        )
                                    }}</small
                                >
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-12">
                                <div class="p-float-label bottom_margin mt-2">
                                    <Dropdown
                                        id="ship_agent"
                                        v-model="ship_agent"
                                        :options="get_ship_agents"
                                        optionLabel="name"
                                        optionValue="id"
                                        @change="load_ship_responsibles($event)"
                                    />
                                    <label for="ship_agent">
                                        Ship Agent
                                    </label>
                                </div>
                                <small
                                    v-if="
                                        Object.keys(
                                            port_call_errors
                                        ).length !== 0 &&
                                        port_call_errors.ship_agent
                                    "
                                    class="p-error"
                                    ><span
                                        v-for="error in port_call_errors.ship_agent"
                                        :key="error"
                                        >{{ error }}<br /></span
                                ></small>
                            </div> 
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="field ">
                                    <div class="p-float-label mt-2">
                                        <Dropdown
                                            id="ship_responsible"
                                            v-model="
                                                ship_responsible
                                            "
                                            :options="get_ship_responsible"
                                            optionLabel="name"
                                            optionValue="id"
                                        />
                                        <label
                                            for="ship_responsible"
                                        >
                                            Ship Responsible
                                        </label>
                                    </div>
                                    <small
                                        v-if="
                                            Object.keys(
                                                port_call_errors
                                            ).length !== 0 &&
                                            port_call_errors.ship_responsible
                                        "
                                        class="p-error"
                                        ><span
                                            v-for="error in port_call_errors.ship_responsible"
                                            :key="error"
                                            >{{ error }}<br /></span
                                    ></small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="p-float-label">
                                    <Checkbox v-model="is_unconventional" :binary="true"></Checkbox>
                                    <label class="ms-3"> Unconventional Ship </label>
                                </div>
                            </div>
                        </div>  
                    </form>
                </div>
                <div class="container mt-3">
                    <div class="row">
                        <div class="col-2">
                            <Button data-cy="button_create_call" form="form_new_call" type="submit" label="Submit" />
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
        <div v-if="$ability.can('import','call')" class="col-12 md:col-6">
            <div class="row">
                <Panel header="Import calls">
                    <div v-if="loadding == true" class="text-center">
                        <i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
                    </div>
                    <div v-else-if="loadding == false">
                        <div class="container">
                            <VueCsvImport
                                v-model="csv"
                                :fields="{
                                    arrival_date: {
                                        required: true,
                                        label: 'Arrival Date*',
                                    },
                                    ship: {
                                        required: true,
                                        label: 'Ship*',
                                    },
                                    arrival_hour: {
                                        required: false,
                                        label: 'Arrival Hour',
                                    },
                                    departure_date: {
                                        required: false,
                                        label: 'Departure Date',
                                    },
                                    departure_hour: {
                                        required: false,
                                        label: 'Departure Hour',
                                    },
                                    terminal: {
                                        required: false,
                                        label: 'Terminal',
                                    },
                                    pier: {
                                        required: false,
                                        label: 'Pier',
                                    },
                                    embarking: {
                                        required: false,
                                        label: 'Embarking',
                                    },
                                    disembarking: {
                                        required: false,
                                        label: 'Disembarking',
                                    },
                                    transit: {
                                        required: false,
                                        label: 'Transit',
                                    },
                                    new: {
                                        required: false,
                                        label: 'New',
                                    },
                                    base_port: {
                                        required: false,
                                        label: 'Base port',
                                    },
                                    status: {
                                        required: false,
                                        label: 'Status',
                                    },
                                }"
                            >
                                <div class="row pt-2">
                                    <VueCsvInput>
                                        <template #file>
                                            <Button
                                                @click="submitCsv(data)"
                                            >
                                                Submit
                                            </Button>
                                        </template>
                                    </VueCsvInput>
                                </div>
                                <div class="row pt-4">
                                    <div class="col">
                                        <VueCsvToggleHeaders />
                                    </div>
                                </div>
                                <div class="row pt-4">
                                    <div class="col">
                                        <VueCsvSubmit>
                                            <template #submit="data">
                                                <Button
                                                    @click="
                                                        submitCsv(data)
                                                    "
                                                >
                                                    Submit
                                                </Button>
                                            </template>
                                        </VueCsvSubmit>
                                    </div>
                                </div>
                                <div class="row pt-4 ps-3">
                                    <VueCsvMap> </VueCsvMap>
                                </div>
                            </VueCsvImport>
                        </div>
                    </div>
                </Panel>    
            </div>
            <div class="row pt-4" v-if="errors_import_calls.length !== 0">
                <Panel header="Import Errors">
                    <template #icons>
                        <button class="p-panel-header-icon mr-2">
                            <span
                                class="
                                    fa-solid
                                    fa-circle-exclamation
                                    fa-2xl
                                "
                            ></span>
                        </button>
                    </template>
                    <div class="row">
                        <div class="col-12 text-center">
                            <p>
                                Could not be imported
                                {{ errors_import_calls.length }} of
                                {{ list_insert.length }} calls
                            </p>
                        </div>
                        <div class="col text-center">
                            <Button
                                label="Show Errors"
                                @click="error_show = true"
                            />
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
            
        
        <!--
        <div class="row mt-3">
            <div class="col-md-12 card" style="padding: 0 !important;">
                <TableCalls/>
            </div>
        </div>
        -->
    </div>


    <!-- Show Errors Import Modal -->
    <Dialog
        v-model:visible="error_show"
        :style="{ width: '50rem' }"
        header="Import Error Lines"
        :modal="true"
        class="p-fluid"
    >
        <div class="container">
            <div v-for="(error, index) in errors_import_calls" :key="error">
                <p>{{ index + 1 }} - {{ error }}</p>
            </div>
            <div class="row">
                <div class="offset-9 col-3">
                    <Button
                        @click="clean_errors_import_calls"
                        label="Clear Errors"
                    />
                </div>
            </div>
        </div>
    </Dialog>
</template>

<script>
// import TableCalls from '@/components/AdminDashboard/Calls/TableCalls.vue/'
import Panel from "primevue/panel";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Checkbox from 'primevue/checkbox'

import moment from 'moment'

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { FilterMatchMode } from "primevue/api";
import { num2time, num2timeedit } from '@/modules/utilities.js'

import {
    VueCsvImport,
    VueCsvInput,
    VueCsvMap,
    VueCsvSubmit,
    VueCsvToggleHeaders,
} from "vue-csv-import";

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: "Table_calendar",
    components: {
        Dialog,
        Dropdown,
        Calendar,
        Panel,
        Button,
        Checkbox,
        VueCsvImport,
        VueCsvInput,
        VueCsvMap,
        VueCsvSubmit,
        VueCsvToggleHeaders,
        // TableCalls
    },
    data() {
        return {
            color: localStorage.getItem("primary_color"),
            port: localStorage.getItem("user_port"),
            csv: [],
            ship_name: "",
            ship_agent_name:"",
            loadding: false,
            terminal_name: "",
            errors_import_calls: [],
            list_insert: [],
            calls: {},
            user_port: {},
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            call_dialog: false,
            delete_call_dialog: false,
            call: {},
            port_call_errors: {},
            edit_port_call_errors: {},
            submitted: false,
            ship_agent: "",
            ship_responsible: "",
            phone: "",
            fax: "",
            arrival_datetime: "",
            arrival_datetime_hour: "",
            departure_datetime: "",
            departure_datetime_hour: "",
            is_unconventional: false,
            id_ship: null,
            showMessage: false,
            error_show: false,
            //loadings general
        };
    },
    async created() {
        await this.$store.dispatch("load_all_ships");
        await this.$store.dispatch("load_all_ship_agents");
        await this.$store.dispatch("load_all_terminals");
        await this.$store.dispatch("load_user_port");

        this.loading = false;
    },
    computed: {
        all_ships: function () {
            return this.$store.getters.all_ships;
        },
        get_errors_call: function () {
            return this.$store.getters.get_errors_call;
        },
        get_ship_by_name() {
            return this.$store.getters.get_ship_by_name(this.ship_name) ?? null;
        },
        /*get_ship_agent_by_name() {
            return this.$store.getters.get_ship_agent_by_name(this.ship_agent_name) ?? null;
        },*/
        get_terminal_by_name() {
            return (
                this.$store.getters.get_terminal_by_name(this.terminal_name) ??
                null
            );
        },
        get_user_port() {
            return this.$store.getters.get_user_port;
        },
        get_import_errors() {
            return this.$store.getters.get_import_errors;
        },
        get_ship_agents() {
            return this.$store.getters.get_ship_agents
        },
        get_ship_responsible() {
            return this.$store.getters.get_ship_responsible
        },
        get_port_call_errors() {
            return this.$store.getters.get_port_call_errors
        }
    },
    methods: {
        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data){
            return num2timeedit(data)
        },
        clean_errors_import_calls() {
            this.errors_import_calls = [];
            this.error_show = false;
        },
        format_date(value) {
            if (value != null) {
                return value.split("T")[0];
            }
        },
        format_hour(value) {
            if (value != null) {
                return value.split("T")[1].split("+")[0].split(':')[0] + ':' + value.split("T")[1].split("+")[0].split(':')[1];
            }
        },
        async submitCsv(data) {
            let user_port = this.get_user_port;
            this.errors_import_calls = [];
            this.list_insert = [];
            if (user_port.name == "Puerto de Málaga") {
                data.mappedCsv.forEach(async (element) => {
                    if (element.ship == "" || element.ship == '\n') {
                        return;
                    }
                    var status = true
                    if (element["status"] !== undefined) {
                        if (element["status"].toLowerCase() == 'previsto' )
                            status = true
                        else{
                            status = false
                        }
                    }
                    if (status){
                        let data = {};
                        data["service_sheet"] = [];
                        let service_sheet = {};
                        this.ship_name = element.ship;
                        let ship_data = this.get_ship_by_name;
                        // Creamos el arrival datetime con la hora
                        let e_arrival_datetime = element.arrival_date;
                        e_arrival_datetime =
                            element.arrival_date.split("/")[2] +
                            "-" +
                            element.arrival_date.split("/")[1] +
                            "-" +
                            element.arrival_date.split("/")[0];
                        if (element["arrival_hour"] !== undefined) {
                            if (element["arrival_hour"] == "") {
                                element.arrival_hour = "00:00";
                            }
                            e_arrival_datetime =
                                e_arrival_datetime +
                                "T" +
                                element.arrival_hour +
                                ":00";
                        } else {
                            e_arrival_datetime = element.arrival_date.split("/")[2] +
                            "-" +
                            element.arrival_date.split("/")[1] +
                            "-" +
                            element.arrival_date.split("/")[0] + "T00:00:00";
                        }
                        service_sheet["estimated_arrival_datetime"] =
                            e_arrival_datetime;
                        data["arrival_datetime"] = e_arrival_datetime;
                        // Si esta el deaperture_date lo creamos con la hora
                        let e_departure_datetime = null;
                        if (element["departure_date"] !== undefined) {
                            e_departure_datetime =
                                element.departure_date.split("/")[2] +
                                "-" +
                                element.departure_date.split("/")[1] +
                                "-" +
                                element.departure_date.split("/")[0];
                        }
                        if (element["departure_hour"] !== undefined) {
                            if (element["departure_hour"] == "") {
                                element.departure_hour = "00:00";
                            }
                            e_departure_datetime =
                                e_departure_datetime +
                                "T" +
                                element.departure_hour +
                                ":00";
                        } else {
                            if (e_departure_datetime !== null) {
                                e_departure_datetime =
                                    e_departure_datetime + "T05:00:00";
                            }
                        }
                        if (e_departure_datetime == null) {
                            e_departure_datetime = e_arrival_datetime;
                        }
                        service_sheet["estimated_departure_datetime"] =
                            e_departure_datetime;
                        data["departure_datetime"] = e_departure_datetime;

                        // Creamos el is new
                        if (element["new"] !== undefined) {
                            if (element["new"].toLowerCase() == 'si' ){
                                data["is_new"]=true
                            }
                        }
                        // Creamos el base port
                        if (element["base_port"] !== undefined) {
                            if (element["base_port"].toLowerCase() == 'si' ){
                                data["base_port"]=true
                            }
                        }
                        // Creamos el embarking
                        if (element["embarking"] === undefined) {
                            service_sheet["e_p_embarking"] = null;
                        } else {
                            service_sheet["e_p_embarking"] = parseInt(
                                element.embarking
                            );
                        }
                        // Creamos el disembarking
                        if (element["disembarking"] === undefined) {
                            service_sheet["e_p_disembarking"] = null;
                        } else {
                            service_sheet["e_p_disembarking"] = parseInt(
                                element.disembarking
                            );
                        }
                        // Creamos el transit
                        if (element["transit"] === undefined) {
                            service_sheet["e_p_transit"] = null;
                        } else {
                            service_sheet["e_p_transit"] = parseInt(
                                element.transit
                            );
                        }
                        
                        // Creamos el pier
                        if (element["pier"] === undefined) {
                            element.pier = null;
                        }
                        service_sheet["pier"] = element.pier;
                        // Creamos el terminal
                        let terminal = null;
                        if (element["terminal"] === undefined) {
                            this.terminal_name = null;
                        } else {
                            if(element.terminal != "") {
                                this.terminal_name = element.terminal;
                                terminal = this.get_terminal_by_name;
                            } else {
                                terminal = null
                            }
                        }
                        if (terminal !== null) {
                            service_sheet["id_terminal"] = terminal[0].id;
                        } else {
                            service_sheet["id_terminal"] = null;
                        }
                        if (service_sheet["id_terminal"] != null) {
                            data["service_sheet"].push(service_sheet);
                        }
                        /* No hace falta, ya que teniendo el cruise line ya podemos obtener el ship responsible
                        // Creamos el ship_agent
                        if (element["ship_agent"] === undefined) {
                            element.ship_agent = null;
                        }else{
                            this.ship_agent_name = element.ship_agent;
                            let ship_agent_data = this.get_ship_agent_by_name;
                            if (Object.keys(ship_agent_data).length !== 0) {
                                element.ship_agent = ship_agent_data[0].id
                            } else {
                                element.ship_agent = null
                            }
                        }

                        data["ship_agent"] = element.ship_agent;*/
                        if (Object.keys(ship_data).length !== 0) {
                            data["id_ship"] = ship_data[0].id;
                            let new_call = JSON.parse(JSON.stringify(data));
                            this.list_insert.push(new_call);
                        } else {
                            let call_ship_error = "The call scheduled for "+ element.arrival_date +" cannot be created because the ship named "+element.ship+" does not exist."
                            this.errors_import_calls.push(call_ship_error);
                        }
                    }
                });
            } else if (user_port.name == "Port de Barcelona") {
                data.mappedCsv.forEach((element) => {
                    if (element.ship == "" || element.ship == '\n') {
                        return;
                    }
                    let data = {};
                    data["service_sheet"] = [];
                    let service_sheet = {};
                    this.ship_name = element.ship.trim();
                    let ship_data = this.get_ship_by_name;
                    // Creamos el arrival datetime con la hora
                    let e_arrival_datetime = element.arrival_date;
                    if (element["arrival_hour"] !== undefined) {
                        if (element["arrival_hour"] == "") {
                            element.arrival_hour = "00:00";
                        }
                        e_arrival_datetime =
                            element.arrival_date.split("/")[2] +
                            "-" +
                            element.arrival_date.split("/")[1] +
                            "-" +
                            element.arrival_date.split("/")[0];
                        e_arrival_datetime =
                            e_arrival_datetime +
                            "T" +
                            element.arrival_hour +
                            ":00";
                    } else {
                        e_arrival_datetime = element.arrival_date.split("/")[2] +
                            "-" +
                            element.arrival_date.split("/")[1] +
                            "-" +
                            element.arrival_date.split("/")[0] + "T00:00:00";
                    }
                    service_sheet["estimated_arrival_datetime"] =
                        e_arrival_datetime;
                    data["arrival_datetime"] = e_arrival_datetime;
                    // Si esta el deaperture_date lo creamos con la hora
                    let e_departure_datetime = null;
                    if (element["departure_date"] !== undefined) {
                        e_departure_datetime =
                            element.departure_date.split("/")[2] +
                            "-" +
                            element.departure_date.split("/")[1] +
                            "-" +
                            element.departure_date.split("/")[0];
                    }
                    if (element["departure_hour"] !== undefined) {
                        if (element["departure_hour"] == "") {
                            element.departure_hour = "00:00";
                        }
                        e_departure_datetime =
                            e_departure_datetime +
                            "T" +
                            element.departure_hour +
                            ":00";
                    } else {
                        if (e_departure_datetime !== null) {
                            e_departure_datetime =
                                e_departure_datetime + "T05:00:00";
                        }
                    }
                    if (e_departure_datetime == null) {
                        e_departure_datetime = e_arrival_datetime;
                    }
                    service_sheet["estimated_departure_datetime"] =
                        e_departure_datetime;
                    data["departure_datetime"] = e_departure_datetime;
                    // Creamos el embarking
                    if (element["embarking"] === undefined) {
                        service_sheet["e_p_embarking"] = null;
                    } else {
                        service_sheet["e_p_embarking"] = parseInt(
                            element.embarking
                        );
                    }
                    // Creamos el is new
                    if (element["new"] !== undefined) {
                        if (element["new"].toLowerCase() == 'si' ){
                            data["is_new"]=true    
                        }
                    }
                    // Creamos el base port
                    if (element["base_port"] !== undefined) {
                        if (element["base_port"].toLowerCase() == 'si' ){
                            data["base_port"]=true
                        }
                    }
                    // Creamos el disembarking
                    if (element["disembarking"] === undefined) {
                        service_sheet["e_p_disembarking"] = null;
                    } else {
                        service_sheet["e_p_disembarking"] = parseInt(
                            element.disembarking
                        );
                    }
                    // Creamos el transit
                    if (element["transit"] === undefined) {
                        service_sheet["e_p_transit"] = null;
                    } else {
                        service_sheet["e_p_transit"] = parseInt(
                            element.transit
                        );
                    }
                    // Creamos el pier
                    if (element["pier"] === undefined) {
                        element.pier = null;
                    }
                    service_sheet["pier"] = element.pier;
                    // Creamos el terminal
                    if (element["terminal"] === undefined) {
                        this.terminal_name = "";
                    } else {
                        this.terminal_name = element.terminal;
                    }
                    if (this.terminal_name.includes("-")) {
                        let terminals = this.terminal_name.split("-");
                        terminals.forEach((element) => {
                            this.terminal_name = element;
                            let terminal = this.get_terminal_by_name;
                            if (Object.keys(terminal).length !== 0) {
                                service_sheet["id_terminal"] = terminal[0].id;
                            } else {
                                service_sheet["id_terminal"] = null;
                            }
                            let new_service_sheet = JSON.parse(
                                JSON.stringify(service_sheet)
                            );
                            //Hacemos el push del service sheet completo por cada terminal, este es el paso final del service sheet
                            if (service_sheet["id_terminal"] != null) {
                                data["service_sheet"].push(new_service_sheet);
                            }
                        });
                    } else {
                        let terminal = this.get_terminal_by_name;
                        if (Object.keys(terminal).length !== 0) {
                            service_sheet["id_terminal"] = terminal[0].id;
                        } else {
                            service_sheet["id_terminal"] = null;
                        }
                        if (service_sheet["id_terminal"] != null) {
                            service_sheet['main'] = true
                            data["service_sheet"].push(service_sheet);
                        }
                    }

                    /* No hace falta, ya que teniendo el cruise line ya podemos obtener el ship responsible
                    // Creamos el ship_agent
                    if (element["ship_agent"] === undefined) {
                        element.ship_agent = null;
                    }else{
                        this.ship_agent_name = element.ship_agent;
                        let ship_agent_data = this.get_ship_agent_by_name;
                        if (Object.keys(ship_agent_data).length !== 0) {
                            element.ship_agent = ship_agent_data[0].id
                        } else {
                            element.ship_agent = null
                        }
                    }

                    data["ship_agent"] = element.ship_agent;*/

                    if (Object.keys(ship_data).length !== 0) {
                        data["id_ship"] = ship_data[0].id;
                        let new_call = JSON.parse(JSON.stringify(data));
                        this.list_insert.push(new_call);
                    } else {
                        let call_ship_error = "The call scheduled for "+ element.arrival_date+" cannot be created because the ship named "+element.ship+" does not exist."
                        this.errors_import_calls.push(call_ship_error);
                    }
                });
            }
            this.loadding = true;
            let charged = await this.$store.dispatch(
                "create_operative",
                this.list_insert
            );
            this.loadding = false;
            this.errors_import_calls = this.errors_import_calls.concat(
                this.get_import_errors
            );
            if (charged) {
                if (this.errors_import_calls.length === 0) {
                    this.$toast.add({
                        severity: "success",
                        summary: "Successful",
                        detail: "Calls imported",
                        life: 3000,
                    });
                } else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Import calls error",
                        life: 3000,
                    });
                }
                this.$store.dispatch("load_port_calls", this.port);
            }
        },
        async save_call() {
            this.submitted = true;

            if(Object.prototype.toString.call(this.call.arrival_hour) === '[object Date]') {
                this.call.arrival_datetime_full = new Date(new Date(this.call.arrival_datetime).getFullYear(), new Date(this.call.arrival_datetime).getMonth(), new Date(this.call.arrival_datetime).getDate(),
                                new Date(this.call.arrival_hour).getHours(), new Date(this.call.arrival_hour).getMinutes(), new Date(this.call.arrival_hour).getSeconds())
            } else {
                this.call.arrival_datetime_full = moment(new Date(this.call.arrival_datetime)).add(this.call.arrival_hour.split(':')[0], 'h').add(this.call.arrival_hour.split(':')[1], 'm').add(-2, 'h').toDate()
            }

            if(Object.prototype.toString.call(this.call.departure_hour) === '[object Date]') {
                this.call.departure_datetime_full = new Date(new Date(this.call.departure_datetime).getFullYear(), new Date(this.call.departure_datetime).getMonth(), new Date(this.call.departure_datetime).getDate(),
                                new Date(this.call.departure_hour).getHours(), new Date(this.call.departure_hour).getMinutes(), new Date(this.call.departure_hour).getSeconds())
            } else {
                this.call.departure_datetime_full = moment(new Date(this.call.departure_datetime)).add(this.call.departure_hour.split(':')[0], 'h').add(this.call.departure_hour.split(':')[1], 'm').add(-2, 'h').toDate()
            }

            await this.$store.dispatch("update_port_call", this.call);
            this.edit_port_call_errors = this.get_errors_call;
            if (Object.keys(this.port_call_errors).length === 0) {
                this.hide_call_dialog();
                this.call = {};
                this.$toast.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Call Modified",
                    life: 3000,
                });
            }
        },
        async load_ship_agent(id) {
            await this.$store.dispatch('load_ship_agents', id.value.id_cruise_line.id)
        },
        async load_ship_responsibles(id) {
            await this.$store.dispatch('load_ship_responsibles', id.value)
        },
        async add_call() {
            
            let call = {
                ship_agent: this.ship_agent,
                arrival_datetime: this.arrival_datetime,
                arrival_datetime_hour: this.arrival_datetime_hour,
                departure_datetime: this.departure_datetime,
                departure_datetime_hour: this.departure_datetime_hour,
                is_unconventional: this.is_unconventional,
                id_ship: this.id_ship,
                ship_responsible: this.ship_responsible,
                phone: this.phone,
                fax: this.fax,
            };
            await this.$store.dispatch("create_port_call", call);
            this.port_call_errors = this.get_errors_call;
            if (Object.keys(this.port_call_errors).length === 0 && Object.keys(this.get_port_call_errors).length === 0) {
                this.resetForm();
                return true;
            }
            return false;
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
               return;
            }
            
            let res = await this.add_call();
            if (res) {
                this.$toast.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Call Added",
                    life: 3000,
                });
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;

            if (!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.ship_agent = "";
            this.arrival_datetime = "";
            this.arrival_datetime_hour = "",
            this.departure_datetime = "";
            this.departure_datetime_hour = "";
            this.id_ship = null;
            this.ship_responsible = "";
            this.is_unconventional = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            arrival_datetime: { required },
            departure_datetime: { required },
            arrival_datetime_hour: { required },
            departure_datetime_hour: { required },
            id_ship: { required }
        };
    },
};
</script>

<style scoped>
.p-panel:deep(.p-panel-header) {
    background: v-bind(color) !important;
}
.p-panel:deep(.p-panel-header .p-panel-title) {
    color: white;
}
.p-panel:deep(.p-panel-header .p-panel-header-icon) {
    color: white !important;
}
.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover) {
    background: v-bind(color) !important;
}
.p-checkbox:deep(.p-checkbox-box.p-highlight) {
    background: v-bind(color) !important;
    border-color: v-bind(color) !important;
}

.bottom_margin{
    margin-bottom: 0 !important;
}

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}
</style>