<template>
    <Toast/>
    <div v-if="$can('view','ship')" class="pt-4 pb-2">
        <div class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Ships </h1>
            <div v-if="$can('add','ship')" class="card p-1">
                <Button data-cy="new_ship_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Ship'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_ship_modal" header="New Ship" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <div class="container mt-2">
                <form id="form" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">   
                                <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted || ship_errors.name}" />
                                <label for="name" :class="{'p-error':v$.name.$invalid && submitted}"> Name* </label>
                                <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.name" class="p-error"><span v-for="error in ship_errors.name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
                        </div>
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">
                                <Dropdown data-cy="cruise_line_dropdown" id="id_cruise_line" v-model="v$.id_cruise_line.$model"  :options="all_cruise_lines" optionLabel="name" :filter="true" :class="{'p-invalid':v$.id_cruise_line.$invalid && submitted || ship_errors.id_cruise_line}"/>
                                <label for="id_cruise_line" :class="{'p-error':v$.id_cruise_line.$invalid && submitted || ship_errors.id_cruise_line}">Cruise Line*</label>
                                <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.id_cruise_line" class="p-error"><span v-for="error in ship_errors.id_cruise_line" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.id_cruise_line.$invalid && submitted) || v$.id_cruise_line.$pending.$response" class="p-error">{{v$.id_cruise_line.required.$message.replace('Value', 'Cruise line')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">
                                <InputText id="shipowner" v-model="shipowner" :class="{'p-invalid':submitted && ship_errors.shipowner}" />
                                <label for="shipowner" :class="{'p-error':submitted && ship_errors.shipowner}"> Shipowner </label>
                                <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.shipowner" class="p-error"><span v-for="error in ship_errors.shipowner" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">
                                <InputNumber id="lowerbeds" v-model="lowerbeds" :class="{'p-invalid':submitted && ship_errors.lowerbeds}" />
                                <label for="lowerbeds" :class="{'p-error':submitted && ship_errors.lowerbeds}"> Lowerbeds </label>
                                <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.lowerbeds" class="p-error"><span v-for="error in ship_errors.lowerbeds" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-6 text-center">
                            <div class="p-float-field">
                                <label for="pictureUploader" class="pb-1">Picture</label>
                                <FileUpload ref="fileUpload" id="pictureUploader" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUploadPicture" />
                                <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.picture" class="p-error"><span v-for="error in ship_errors.picture" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                        <div class="col-10 md:col-6 text-center">
                            <div class="p-float-field">
                                <label for="blueprintUploader" class="pb-1">Blueprint</label>
                                <FileUpload ref="fileUpload2" id="blueprintUploader" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUploadBlueprint" />
                                <small v-if="Object.keys(ship_errors).length !== 0 && ship_errors.blueprint" class="p-error"><span v-for="error in ship_errors.blueprint" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>
                </form> 
            </div>
            <template #footer>
                <Button data-cy="create_ship_button" form="form" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Toast from 'primevue/toast'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"
import FileUpload from "primevue/fileupload"

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        InputNumber,
        Dropdown,
        Toast,
        FileUpload
    },
    data() {
        return {
            displayModal: false,
            name: '',
            id_cruise_line: null,
            lowerbeds: 0,
            shipowner: '',
            picture:'',
            blueprint:'',
            ship_errors:{},
            submitted: false,
            showMessage: false,
        }
    },
    created() {
        this.$store.dispatch('load_all_cruise_lines')
    },
    computed: {
        all_cruise_lines: function() {
            return this.$store.getters.all_cruise_lines
        },
        get_errors_ship: function() {
            return this.$store.getters.get_errors_ship
        }
    },
    methods: {
        onUploadBlueprint(e){
            this.blueprint = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        onUploadPicture(e){
            this.picture = e.files[0];
            this.$refs.fileUpload2.clear();
            this.$refs.fileUpload2.uploadedFileCount = 0;
        },
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_ship() {
            let ships = { 
                name: this.name,
                lowerbeds: this.lowerbeds,
                shipowner: this.shipowner,
                picture:this.picture,
                blueprint:this.blueprint,
                id_cruise_line: this.id_cruise_line
            }
            await this.$store.dispatch('create_ship', ships)
            this.ship_errors = this.get_errors_ship
            if(Object.keys(this.ship_errors).length === 0){
                this.toggleDialog();
                this.resetForm()
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }
            
            let res = await this.add_ship()

            if(res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Ship added', life: 3000});
            }
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.name = '';
            this.lowerbeds = 0;
            this.shipowner = '';
            this.picture = '';
            this.blueprint = '';
            this.id_cruise_line = null;
            this.submitted = false;
        }
    },
    validations() {
        return {
            name: { required },
            id_cruise_line: { required }
        }
    },
};
</script>

<style scoped>
</style>