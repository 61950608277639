<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div v-if="$can('add','user') " class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Users </h1>
            <div class="card p-1">
                <Button data-cy="new_user_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New User'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_user_modal" header="New user" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <div class="container">
                <form id="form_new_user" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid mt-2">
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="username" v-model="v$.username.$model" :class="{'p-invalid':v$.username.$invalid && submitted || user_errors.username}" />
                                <label for="username" :class="{'p-error':v$.username.$invalid && submitted}"> Username* </label>
                                <small v-if="Object.keys(user_errors).length !== 0 && user_errors.username" class="p-error"><span v-for="error in user_errors.username" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.username.$invalid && submitted) || v$.username.$pending.$response" class="p-error">{{v$.username.required.$message.replace('Value', 'Username')}}</small>
                        </div>
                    </div> 
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="first_name" v-model="first_name" :class="{'p-invalid':submitted && user_errors.first_name}" />
                                <label for="first_name" :class="{'p-error':submitted && user_errors.first_name}"> First name </label>
                                <small v-if="Object.keys(user_errors).length !== 0 && user_errors.first_name" class="p-error"><span v-for="error in user_errors.first_name" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div> 
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="last_name" v-model="last_name" :class="{'p-invalid': submitted && user_errors.last_name}" />
                                <label for="last_name" :class="{'p-error': user_errors.last_name && submitted}"> Last name </label>
                                <small v-if="Object.keys(user_errors).length !== 0 && user_errors.last_name" class="p-error"><span v-for="error in user_errors.last_name" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div> 
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted || user_errors.email}" />
                                <label for="email" :class="{'p-error':v$.email.$invalid && submitted}"> Email* </label>
                                <small v-if="Object.keys(user_errors).length !== 0 && user_errors.email" class="p-error"><span v-for="error in user_errors.email" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 mt-3">
                            <span class="p-float-label">
                                <Password v-model="v$.password.$model" id="password" aria-labelledby="password" toggleMask :class="{'p-invalid':v$.password.$invalid && submitted }">
                                    <template #header>
                                        <h6>Pick a password</h6>
                                    </template>
                                    <template #footer>
                                        <Divider />
                                        <p class="mt-2">Requirements</p>
                                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                            <li>At least one lowercase</li>
                                            <li>At least one uppercase</li>
                                            <li>At least one numeric</li>
                                            <li>Minimum 8 characters</li>
                                        </ul>
                                    </template>
                                </Password>
                                <label for="password"> New Password* </label>
                            </span>
                            <small v-if="Object.keys(user_errors).length !== 0 && user_errors.password" class="p-error"><span v-for="error in user_errors.password" :key="error">{{error}}<br></span></small>
                            <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{v$.password.required.$message.replace('Value', 'New password')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 mt-3">
                            <span class="p-float-label">
                                <Password v-model="confirm_password" id="confirm_password" aria-labelledby="confirm_password" toggleMask :feedback="false" :class="{'p-invalid':v$.confirm_password.$invalid && submitted}"/>
                                <label for="confirm_password">Confirm password*</label> 
                            </span>
                            <small v-if="Object.keys(user_errors).length !== 0 && user_errors.confirm_password" class="p-error"><span v-for="error in user_errors.confirm_password" :key="error">{{error}}<br></span></small>
                            <small v-if="(v$.confirm_password.$invalid && submitted) || v$.confirm_password.$pending.$response" class="p-error">{{v$.confirm_password.required.$message.replace('Value', 'Confirm new password')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 mt-3">
                            <Checkbox v-model="mail_notifications" :binary="true" />
                            <label class="ms-3" style="color: gray;"> Mail notifications </label> 
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <Button data-cy="create_user_button" form="form_new_user" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Password from 'primevue/password'
import Checkbox from 'primevue/checkbox'
import Divider from 'primevue/divider'

import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        Toast,
        Password,
        Checkbox,
        Divider,
    },
    data() {
        return {
            user_errors: {},
            profile_errors: {},
            displayModal: false,
            username: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            confirm_password: '',
            mail_notifications: false,
            submitted: false,
            showMessage: false,
        }
    },
    computed: {
        get_errors_user: function() {
            return this.$store.getters.get_errors_user
        },
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_user() {
            let user = { 
                username: this.username,
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                password:this.password,
                confirm_password:this.confirm_password,
                profile: {
                    mail_notifications: this.mail_notifications
                }
            }
            if (this.password != this.confirm_password){
                this.user_errors.password=["The passwords don't match, try again."]
                return false
            }
            await this.$store.dispatch('create_user', user)
            this.user_errors = this.get_errors_user
            if(Object.keys(this.user_errors).length === 0){
                this.toggleDialog();
                this.resetForm();
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

            let res = await this.add_user()
            if(res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'User added', life: 3000});
            }
            
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }

        },
        resetForm() {
            this.username = '';
            this.first_name = '';
            this.last_name = '';
            this.email = '';
            this.password = '';
            this.confirm_password = '';
            this.submitted = false;
        }
    },
    validations() {
        return {
            username: { required },
            email: { required },
            password: { required },
            confirm_password: { required }
        }
    },
};
</script>

<style scoped>

:deep(.p-input-icon-right>svg){
    right: 0.75rem
}

</style>