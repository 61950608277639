<template>
    <div class="card">
        <div class="row">
			<Toast />
			<Datatable :value="all_groups" :paginator="true" :rows="10"
            :paginatorTemplate="paginatorTemplate"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" scrollable 
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            stripedRows :loading="loading" removableSort
            :globalFilterFields="['name']"
            v-model:filters="filters">
				<template #header>
                    <div class="table-header flex flex-column md:flex-row md:justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText id="filter_cruise_groups" v-model="filters['global'].value" placeholder="Keyword Search" class="input_small"/>
                        </span>
                    </div>
                </template>
				<Column header="Name" field="name" :sortable="true"> 
                </Column>
                <Column v-if="$can('change','cruise_group')" header="Edit" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="edit_button" icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined" @click="edit_group(slotProps.data)"/>
                    </template>
                </Column>
                <Column v-if="$can('delete','cruise_group')" header="Delete" style="width: 7rem;">
                    <template #body="slotProps">
                        <Button data-cy="delete_button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirm_delete_group(slotProps)"/>
                    </template>
                </Column>
			</Datatable>
		</div>
	</div>

	<Dialog data-cy="edit_group_modal" v-model:visible="group_dialog" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}" header="Group Details" :modal="true" class="p-fluid">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-10 md:col-12">
					<div class="p-float-field">
						<label for="name" class="pb-1 ps-1"> <strong> Name </strong> </label>
						<InputText id="name" v-model.trim="group.name" required="true" autofocus :class="{'p-invalid': submitted && !group.name || group_errors.name}" />
						<small class="p-error" v-if="submitted && !group.name">Name is required.</small>
						<small v-if="Object.keys(group_errors).length !== 0 && group_errors.name" class="p-error"><span v-for="error in group_errors.name" :key="error">{{error}}<br></span></small>
					</div>
				</div>
			</div>
		</div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="hide_group_dialog"/>
            <Button data-cy="save_edit_group" label="Save" @click="save_group" />
        </template>
    </Dialog>

    <Dialog v-model:visible="delete_group_dialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span v-if="group">Are you sure you want to delete <b>{{group.data.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="delete_group_dialog = false"/>
            <Button label="Yes" data-cy="confirm_delete_button" @click="delete_group" />
        </template>
    </Dialog>
</template>

<script>
import Datatable from 'primevue/datatable'
import Column from 'primevue/column'
import Toast from 'primevue/toast'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Dialog from 'primevue/dialog'

import { FilterMatchMode } from 'primevue/api';
import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'

export default {
	setup: () => ({ v$: useVuelidate() }),
    name: 'TableGroups',
	components: {
		Datatable,
		Column,
		Toast,
		InputText,
		Button,
		Dialog
	},
	data() {
		return {
            groups: {},
            group_errors:{},
            get_errors_group: {},
            group_dialog: false,
            delete_group_dialog: false,
            submitted: false,
			filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            loading: false,
            paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		}
	},
    async mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        if(this.all_groups.length == 0) {
            this.loading = true
            await this.$store.dispatch('load_all_groups')
            this.loading = false
        }
    },

    async unmounted(){
        window.removeEventListener('resize', this.handleResize);
    },

    computed: {
        all_groups: function() {
            return this.$store.getters.all_groups
        },
        get_delete_group_status: function(){
            return this.$store.getters.get_delete_group_status
        }
	},
	methods: {

        async handleResize(){
            if (window.innerWidth <= 767){
                this.paginatorTemplate = "CurrentPageReport PrevPageLink NextPageLink"
            } else {
                this.paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            }
        },

        confirm_delete_group(group) {
            this.group = group;
            this.delete_group_dialog = true;
        },
        async delete_group() {
            await this.$store.dispatch('delete_group', this.group.data.id)
            if (!this.get_delete_group_status){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Cruise group deleted', life: 3000});
            }else{
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: "Cruise group can't be deleted", life: 3000});
            }
            this.delete_group_dialog = false;
        },
        edit_group(group) {
            this.group = {...group};
            this.group_dialog = true;
        },
        async save_group() {
            this.submitted = true;
			if (this.group.name.trim()) {
                await this.$store.dispatch('update_group', this.group)
                this.group_errors = this.get_errors_group
                
                if(Object.keys(this.group_errors).length === 0){
                    this.group_dialog = false;
                    this.group = {};
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Cruise group modified', life: 3000});
                }
                
            }
        },
        hide_group_dialog() {
            this.group_dialog = false;
            this.submitted = false;
        },
    },
    validations() {
        return {
            name: { required },
        }
    },

}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

:deep(.p-inputtext){
    font-size: 1rem;
    margin: auto;
}

.input_small{
    font-size: .8rem !important; 
}

</style>