<template>
    <div class="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden" style="background-color: white; height: 100vh">
        <div class="flex flex-column align-items-center justify-content-center ">
            <img src="../assets/global_port_logo.png" style="margin-bottom: 2rem; width: 20rem;"/>
            <div class="w-full" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)">
                <div class="w-full surface-card py-8 px-3 sm:px-8" style="border-radius: 53px; background-color: #f8f9fa !important;">
                    <div v-if="reset_password == false">
                        <div>
                            <form id="form_login" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                                <div>
                                    <label for="username" class="block text-900 text-xl font-medium mb-2" :class="{ 'p-error': v$.username.$invalid && submitted }"> Username </label>
                                    <InputText data-testid="name-input" data-cy="username" id="username" placeholder="Username" v-model.trim="v$.username.$model" :class="{ 'p-invalid': v$.username.$invalid && submitted }" />
                                </div>
                                <small v-if="(v$.username.$invalid && submitted) || v$.username.$pending.$response" class="p-error">{{v$.username.required.$message.replace("Value", "Name")}}</small>
                                <div class="pt-3">
                                    <label for="password" class="block text-900 text-xl font-medium mb-2" :class="{'p-error': v$.password.$invalid && submitted}"> Password </label>
                                    <Password data-testid="password-input" id="password" placeholder="Password" class="w-full md:w-30rem" v-model.trim="v$.password.$model" :class="{'p-invalid': v$.password.$invalid && submitted }" :feedback="false" toggleMask/>
                                </div>
                                <small v-if=" (v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{v$.password.required.$message.replace("Value", "Password")}}</small>
                                <div class="mt-3 text-end">
                                    <p> <span @click="reset_password = true, mail_sent = false" style="cursor: pointer; color: blue;">Forgot password?</span> </p>
                                </div>
                                <div class="pt-2 align-items-center d-flex justify-content-center ">
                                    <Button label="Log In" type="submit" :disabled="get_loading" class="w-full p-2"/>
                                    <span v-if="get_loading" class="col">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #002D72"></i>
                                    </span>
                                </div>
                                <span class="mb-3 mt-1 p-error" v-if="get_error_message_login != ''"> {{ get_error_message_login }} </span>
                            </form>
                        </div>
                    </div>
                    <div v-else-if="get_loading == false && reset_password == true" class="p-fluid">
                        <div class="col-12 pb-0 m-0">
                            <InlineMessage v-if="mail_sent" severity="success" class="mt-3 mb-1"> Mail sended succesfully. </InlineMessage>
                            <div class="field pt-3">
                                <div class="p-float-label">
                                    <InputText data-testid="name-input" id="username" v-model="request_email" />
                                    <label> Email or username </label>
                                </div>
                            </div>
                            <div class="pt-2 pb-1 align-items-center d-flex justify-content-center ">
                                <Button label="Request email" @click="send_request_mail"/>
                            </div>
                        </div>
                        <div class="col-12 pb-0 text-end">
                            <p> <span @click="reset_password = false, mail_sent = false" style="cursor: pointer; color: blue;">Cancel</span> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import InlineMessage from 'primevue/inlinemessage';

import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import axios from 'axios'

const uninterceptedAxiosInstance = axios.create();

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: "Login",
    components: {
            InputText,
            Password,
            Button,
            InlineMessage,
    },
    data() {
        return {
        username: "",
        password: "",
        request_email: "",
        reset_password: false,
        submitted: false,
        hasLoggedIn: false,
        showMessage: false,
        mail_sent: false,
        };
    },
    created () {
    this.check()
    },
    computed: {
        get_error_message_login: function() {
            return this.$store.getters.get_error_message_login
        },
        get_loading: function() {
            return this.$store.getters.get_loading
        },
        get_perms: function() {
            return this.$store.getters.get_perms
        },
    },
    methods: {
        async handleSubmit(isFormValid) {
            this.error_message = null
            
            this.submitted = true;

            let user = {
                username: this.username,
                password: this.password,
            };

            if (!isFormValid) {
                return;
            } 

            await this.$store.dispatch('login', user)
        },
        async send_request_mail() {
            await uninterceptedAxiosInstance.post(process.env.VUE_APP_API_URL + 'users/send_password_recovery_mail/' + this.request_email + '/')
            this.mail_sent = true
            this.request_email = ''
        },
        async check() {
            // If the user is logged in (get token from local storage) redirect to main page.
            if (localStorage.getItem('token')) {
                this.$router.push('/home')
            }
        },
    }, 
    validations() {
        return {
            username: { required },
            password: { required },
        };
    },
};
</script>

<style scoped>

/*
#formContent {
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
}
.vertical-center {
    min-height: 90vh; 
    display: flex;
    align-items: center;
}
*/

.p-button{
    background: #002D72 !important;
}

:deep(.p-input-icon-right>svg){
    right: 0.75rem
}

</style>