<template>
    <div class="container-fluid">
        <div class="row" style="height: 100%">
            <div class="side_bar_custom">
                <Menu :model="items"/>
            </div>  
            <div class="col main_table">
                <div class="container-fluid" >
                    <router-view />
                </div>
            </div>
        </div>
        <div class="menu-mobile justify-content-end">
            <div class="col">
                <Button icon="fa fa-bars" class="sidebar-button p-button-raised p-button-rounded color-button-menu" style="color: white;" @click="visibleRight = true"/>
            </div>
        </div>
    </div>

    <Sidebar v-model:visible="visibleRight" position="left">
        <Menu :model="items"/>    
    </Sidebar>
    
</template>

<script>
import Menu from 'primevue/menu'
import Button from 'primevue/button'
import ability from '../services/ability'
import Sidebar from 'primevue/sidebar'

export default {
    name: 'Admin',
    components: {
        Menu,
        Sidebar,
        Button,
    },
    methods: {
        openMenu() {
            this.open = true;
        }
    },
    data() {
        return {
            visibleRight: false,
            color: localStorage.getItem('primary_color'),
            opacity_color: '',
            items: [
                {
                    label: 'GENERAL',
                    icon: 'fa-solid fa-scroll',
                    visible: () =>{
                        if(this.check_user_managment_general == true) {
                            return true
                        } else {
                            return false
                        }
                    },
                    items: [
                        {
                            label: 'Calls',
                            icon: "fa-solid fa-book",
                            visible: () =>  ability.can('view', 'call'),
                            to: '/admin/calls',
                            command: () => {
                                this.visibleRight = false
                            },
                        },
                    ]
                },
                {
                    label: 'SHIP INFORMATION',
                    icon: 'fa-solid fa-scroll',
                    visible: () =>{
                        if(this.check_user_managment_ship_info == true) {
                            return true
                        } else {
                            return false
                        }
                    },
                    items: [
                        {
                            label: 'Cruise groups',
                            icon: 'fa-solid fa-briefcase',
                            visible: () =>  ability.can('view', 'cruise_group'),
                            to: '/admin/groups',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Cruise lines',
                            icon:"fa-solid fa-city",
                            visible: () =>  ability.can('view', 'cruise_line'),
                            to: '/admin/cruise_lines',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Ships',
                            icon:'fas fa-ship',
                            visible: () =>  ability.can('view', 'ship'),
                            to: '/admin/ships',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Ship agent',
                            icon:'fa-solid fa-users-between-lines',
                            visible: () =>  ability.can('view', 'ship_agent'),
                            to: '/admin/ship_agents',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Ship responsibles',
                            icon:'fa-solid fa-clipboard-user',
                            visible: () =>  ability.can('view', 'ship_responsible'),
                            to: '/admin/ship_responsibles',
                            command: () => {
                                this.visibleRight = false
                            }
                        }
                        
                    ]
                },
                {
                    label: 'INFRASTRUCTURE',
                    icon: 'fas fa-industry',
                    visible: () =>{
                        if(this.check_user_managment_infrastructure == true) {
                            return true
                        } else {
                            return false
                        }
                    },
                    items: [
                        {
                            label: 'Ports',
                            icon:"fas fa-anchor",
                            visible: () =>  ability.can('view', 'port'),
                            to: '/admin/ports',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Terminals',
                            icon: "fa-solid fa-warehouse",
                            visible: () =>  ability.can('view', 'terminal'),
                            to: '/admin/terminals',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Water Meters',
                            icon: 'fa-solid fa-water',
                            visible: () =>  ability.can('view', 'water_meter'),
                            to: '/admin/water_meters',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Fingers',
                            icon:"fa-solid fa-bacon",
                            visible: () =>  ability.can('view', 'finger'),
                            to: '/admin/fingers',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Ship fingers',
                            icon:"fa-solid fa-image",
                            visible: () =>  ability.can('view', 'ship_finger'),
                            to: '/admin/ship_fingers',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        
                    ]
                },
                {
                    label: 'SERVICES',
                    icon: 'fa-solid fa-dolly',
                    visible: () =>{
                        if(this.check_user_managment_services == true) {
                            return true
                        } else {
                            return false
                        }
                    },
                    items: [
                        {
                            label: 'Suppliers',
                            icon: 'fa-solid fa-building-user',
                            visible: () =>  ability.can('view', 'supplier'),
                            to: '/admin/suppliers',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Contracts',
                            icon: 'fa-solid fa-file-contract',
                            visible: () =>  ability.can('view', 'contract'),
                            to: '/admin/contracts',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Supplier responsibles',
                            icon: 'fa-solid fa-user-tie',
                            visible: () =>  ability.can('view', 'supplier_responsible'),
                            to: '/admin/supplier_responsibles',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Supplier Assignment',
                            icon: 'fa-solid fa-share-nodes',
                            visible: () =>  ability.can('view', 'supplier') && ability.can('view', 'supplier_responsible') && ability.can('view', 'supplier_service'),
                            to: '/admin/supplier_assignment',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Billing Destination',
                            icon: 'fa-solid fa-wallet',
                            visible: () =>  ability.can('view', 'billing_destination'),
                            to: '/admin/billing_destination',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Currency',
                            icon:"fa-solid fa-dollar-sign",
                            visible: () =>  ability.can('view', 'currency'),
                            to: '/admin/currency',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Card',
                            icon:"fa-solid fa-credit-card",
                            visible: () =>  ability.can('view', 'card'),
                            to: '/admin/card',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                    ]
                },
                {
                    label: 'PEOPLE',
                    icon: 'fa-solid fa-users',
                    visible: () =>{
                        if(this.check_user_managment_people == true) {
                            return true
                        } else {
                            return false
                        }
                    },
                    items: [
                        {
                            label: 'Passengers',
                            icon:'fa-solid fa-person-walking',
                            visible: () =>  ability.can('view', 'passenger'),
                            to: '/admin/passengers',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Users',
                            icon:'fa-solid fa-user',
                            visible: () =>  ability.can('view', 'user'),
                            to: '/admin/users',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Crew',
                            icon:"fa-solid fa-user-group",
                            visible: () =>  ability.can('view', 'crew'),
                            to: '/admin/crew',
                            command: () => {
                                this.visibleRight = false
                            }
                        }
                    ]
                },
                {
                    label: 'INCIDENCES / TASKS',
                    icon: 'fa-solid fa-flag',
                    visible: () => {
                        if(this.check_user_managment_incidences == true) {
                            return true
                        }
                        else {
                            return false
                        }
                    },
                    items: [
                        {
                            label: 'Task Categories',
                            icon:'fa-solid fa-chart-simple',
                            visible: () =>  ability.can('view', 'task_category'),
                            to: '/admin/task_categories',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Incidence Reports',
                            icon:'fa-solid fa-book-atlas',
                            visible: () =>  ability.can('view', 'incidence_report'),
                            to: '/admin/incidence_reports',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Incidence Zones',
                            icon:'fa-solid fa-indent',
                            visible: () =>  ability.can('view', 'incidence_zone'),
                            to: '/admin/incidence_zones',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Incidence Categories',
                            icon:'fa-solid fa-indent',
                            visible: () =>  ability.can('view', 'incidence_category'),
                            to: '/admin/incidence_categories',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                        {
                            label: 'Incidence Subcategories',
                            icon:'fa-solid fa-indent',
                            visible: () =>  ability.can('view', 'incidence_subcategory'),
                            to: '/admin/incidence_subcategories',
                            command: () => {
                                this.visibleRight = false
                            }
                        },
                    ]
                },
            ]
        }
    },
    computed: {
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_managment_general: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Managment General') {
                    return true
                }
            }
            return false
        },
        check_user_managment_ship_info: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Managment Ship Info') {
                    return true
                }
            }
            return false
        },
        check_user_managment_infrastructure: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Managment Infrastructure') {
                    return true
                }
            }
            return false
        },
        check_user_managment_people: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Managment People') {
                    return true
                }
            }
            return false
        },
        check_user_managment_incidences: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Managment Incidences') {
                    return true
                }
            }
            return false
        },
        check_user_managment_services: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Managment Services') {
                    return true
                }
            }
            return false
        },
    },
    mounted() {
        if(this.$router.currentRoute.value.fullPath == '/admin/'){
            if(this.check_user_managment_general) {
                this.$router.push('/admin/calls')
            } else if(this.check_user_managment_ship_info) {
                this.$router.push('/admin/groups')
            } else if(this.check_user_managment_infrastructure) {
                this.$router.push('/admin/terminals')
            } else if(this.check_user_managment_services) {
                this.$router.push('/admin/suppliers')
            } else if(this.check_user_managment_people) {
                this.$router.push('/admin/passengers')
            } else if(this.check_user_managment_incidences) {
                this.$router.push('/admin/task_categories')
            }
        }
    },
    watch:{
        async $route (){
            if(this.$router.currentRoute.value.fullPath == '/admin/') {
                if(this.check_user_managment_general) {
                    this.$router.push('/admin/calls')
                } else if(this.check_user_managment_ship_info) {
                    this.$router.push('/admin/groups')
                } else if(this.check_user_managment_infrastructure) {
                    this.$router.push('/admin/terminals')
                } else if(this.check_user_managment_services) {
                    this.$router.push('/admin/suppliers')
                } else if(this.check_user_managment_people) {
                    this.$router.push('/admin/passengers')
                } else if(this.check_user_managment_incidences) {
                    this.$router.push('/admin/task_categories')
                }
            } 
        }
    },
}
</script>

<style scoped>

.side_bar_custom{
    position: fixed;
    width: 300px;
    height: calc(100vh - 11rem);
    z-index: 3;
    overflow-y: auto;
    user-select: none;
    top: 9rem;
    left: 2rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 0.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.main_table {
    margin-left: 300px;
    flex-direction: column;
    min-height: calc(100vh - 9rem);
    justify-content: space-between;
    padding-left: 2rem;
    transition: margin-left 0.2s;
    width: 50vw;
}

@media (min-width: 1300px) {
    .menu-mobile {
        display: none;

    }

    :deep(.p-scrollpanel .p-scrollpanel-bar) {
        background-color: #a8a8a8;
        transition: #a8a8a8 .2s;
    }

    :deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
        background-color: #a8a8a8;
    }
}

@media (max-width: 1301px) {
    .side_bar_custom {
        display: none;
    }
    .main_table{
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
}

:deep(.p-scrollpanel .p-scrollpanel-bar) {
    background-color: #a8a8a8;
    transition: #a8a8a8 .2s;
}    

:deep(.p-scrollpanel .p-scrollpanel-bar:hover) {
    background-color: #a8a8a8;
}

.sidebar-button {
    border-radius: 10px;
    left: 82vw !important;
    top: 90% !important;
    position: fixed !important;
    z-index: 1000;
}

:deep(.p-menu) {
    border-radius: 0px;
    background: rgba(0,0,0,0); 
    padding: 0;
}

:deep(.p-menu .p-menuitem-link) {
    margin-left: 0px;
}


.scroll_menu{
    background-color: v-bind(opacity_color);
}

:deep(.p-scrollpanel-content){
    padding: 0;

}

:deep(.p-menu .p-submenu-header){
    background-color: rgba(0,0,0,0); 
    border-radius: 6px;
}

/*
:deep(.bm-item-list) {
    margin-left: 0px !important;
}

:deep(.bm-menu) {
    background-color: #eaeaea;
    box-shadow: 0 9px 10px -1px rgba(0,0,0,.2), 0 9px 10px 0 rgba(0,0,0,.14), 0 4px 5px 0 rgba(0,0,0,.12);
}
*/

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
}

.color-button-menu{
    color: white;
    background-color: v-bind(color);
    border-color: v-bind(color);
}

.color-button-menu:hover{
    background-color: v-bind(color) !important;
    border-color: v-bind(color) !important; 
    opacity: .8;
}

:deep(.router-link-active .p-menuitem-text){
    color: v-bind(color) !important;
    font-weight: 700;
}

:deep(.router-link-active .p-menuitem-icon){
    color: v-bind(color) !important;
}

:deep(.p-menu .p-menuitem-link:not(.p-disabled):hover){
    border-radius: 10px;
}

:deep(.p-scrollpanel-bar-x){
    height: 0px;
}

</style>