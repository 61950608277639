import axios from 'axios'

const state = {
    currencies: [],
    currency_errors: []
}

const mutations = {
    SET_CURRENCIES(state, currencies) {
        state.currencies = currencies
    },
    NEW_CURRENCY(state, currency) {  
        state.currencies.push(currency)
        state.currency_errors={}
    },
    DELETE_CURRENCY(state, id) {
        let index = state.currencies.findIndex(currencies => currencies.id == id)
        state.currencies.splice(index, 1)
    },
    UPDATE_CURRENCY(state, currency) {
        state.currencies = state.currencies.map(updated_currency => {
            if(updated_currency.id === currency.id) {
                updated_currency.name = currency.name
                updated_currency.abbreviation = currency.abbreviation 
                updated_currency.symbol = currency.symbol 
            }
            return updated_currency
        })
        state.currency_errors={}
    },
    ERRORS_CURRENCY(state, errors){
        state.currency_errors = errors
    },
    DELETE_ERROR(state){
        state.delete_status = []
    }
}

const actions = {
    async load_all_currencies({commit}) {        
        axios.get(process.env.VUE_APP_API_URL  + "currency/")
            .then((response) => {
                commit('SET_CURRENCIES', response.data)
            })
    },
    async delete_currency({commit}, id) {
        await axios.delete(process.env.VUE_APP_API_URL  + 'currency/' + id)
            .then(() => {
                commit('DELETE_CURRENCY', id);
            })
            .catch(() => {
                commit('DELETE_ERROR', true)
            })
    }, 
    async create_currency({commit}, currency) {
        await axios.post(process.env.VUE_APP_API_URL  + 'currency/', { name: currency.name, abbreviation: currency.abbreviation, symbol: currency.symbol })
            .then((response) => {
                commit('NEW_CURRENCY', response.data)
            })
            .catch((error) => {
                commit('ERRORS_CURRENCY', error.response.data)
            })
    },
    async update_currency({commit}, currency) {
        await axios.put(process.env.VUE_APP_API_URL  + 'currency/' + currency.id + '/',  { name: currency.name, abbreviation: currency.abbreviation, symbol: currency.symbol })
            .then(() => {
                commit('UPDATE_CURRENCY', currency)
            })
            .catch((error) => {
                commit('ERRORS_CURRENCY', error.response.data)
            })
    },
}

const getters = {
    get_currencies: (state) => state.currencies,
    get_currency_errors: (state) => state.currency_errors
}

export default {
    state,
    mutations,
    actions,
    getters
};