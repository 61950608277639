<template>
    <BlockUI :blocked="blocked_edit_bill" :fullScreen="true">
    <div v-if="blocked_edit_bill == true" style="position: absolute;margin-left: auto;margin-right: auto;left: 0;right: 0;bottom: 50%;text-align: center;z-index: 9099">
        <i class="pi pi-spin pi-spinner" style="font-size: 5rem;"></i>
    </div>
    <div v-if="!edit_mode" class="row">
        <div class="col">
            <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> New Payment Proposal </h5>
        </div>
    </div>
    <div v-else class="row">
        <div class="col">
            <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Edit Payment Proposal </h5>
        </div>
    </div>
    <div v-if="!edit_mode">
        <div class="row justify-content-center" >
            <div class="col-3 p-fluid ">
                <Calendar view="month" v-model="date_payment_proposal" dateFormat="MM, yy" placeholder="Select the month"/>
            </div>
        </div>
        <div v-if="error_date" class="row">
            <div class="col text-center">
                <small style="color:red;"> {{error_date}} </small>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="row">
            <div class="col text-center">
                <h2>{{format_date(date)}}</h2>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h5  style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Filtering </h5>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <InputText v-model="filter_number" placeholder="Search by number..." class="me-2"/>
            <InputText v-model="filter_supplier" placeholder="Search by supplier..." class="me-2"/>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col" style="height: 100%;">
            <PickList v-model="data" dataKey="id" stripedRows  @move-to-target="move_to_target($event)" @move-to-source="move_to_source($event)">
                <template #sourceheader>
                    Available
                </template>
                <template #targetheader>
                    Selected
                </template>
                <template #item="slotProp">
                    <div class="row">
                        <div class="col">
                            <small style="color:gray"> NUMBER </small><strong> {{slotProp.item.number }} </strong>
                        </div>
                        <div class="col">
                            <small style="color:gray"> SUPPLIER </small><strong> {{slotProp.item.id_supplier.name}} </strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <small style="color:gray"> DUE DATE </small> <strong> {{format_datetime(slotProp.item.due_date)}} </strong>
                        </div>
                        <div class="col">
                            <small style="color:gray"> IMPORT </small> <strong> {{slotProp.item.total_import}} €</strong>
                        </div>
                    </div>
                </template>
            </PickList>
        </div>
    </div>
    <div v-if="error_bills" class="row">
        <div class="col-6">

        </div>
        <div class="col-6 text-center">
            <small style="color:red;"> {{error_bills}} </small>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-6">

        </div>
        <div class="col-6 text-center">
            <small> TOTAL IMPORT</small> <h3>{{get_total_import_new}} €</h3> 
        </div>
    </div>
   
    <div class="container">
        <div v-if="edit_mode" class="row">
            <div class="col text-end">
                <Button label="Save" @click="edit_proposal"/>
            </div>
        </div>
        <div v-else class="row">
            <div class="col text-end">
                <Button label="Create" @click="create_new_proposal"/>
            </div>
        </div> 
    </div>

    </BlockUI>
</template>

<script>
import PickList from 'primevue/picklist'
import Calendar from 'primevue/calendar'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import BlockUI from 'primevue/blockui'

import moment from 'moment'

export default {
    name: 'Payment_porposal_creation',
    components: {
        PickList,
        Calendar,
        Button,
        InputText,
        BlockUI,
    },
    props: {
        date: {
            type: Date,
        },
        id: {
            type: Number
        }
    },
    data() {
        return {
            home: {icon: 'fa fa-home', to: '/billing/payment_proposal'},
            items: [
                {label: 'Creation'},
            ],
            date_payment_proposal: '',
            error_date: '',
            error_bills: '',
            filter_number: '',
            filter_supplier: '',
            blocked_edit_bill: false
        }
    },
    computed: {
        data() {
            return [[...this.get_available_bills], [...this.get_empty_bills]]
        },
        get_available_bills: function() {
            return this.$store.getters.get_available_bills.filter(item => {
                if(item.id_supplier.name.includes(this.filter_supplier) == false) {
                    return false
                }

                if(item.number.includes(this.filter_number) == false) {
                    return false
                }
                return true
            });
        },
        get_empty_bills: function() {
            return this.$store.getters.get_empty_bills.filter(item =>{
                if(item.id_supplier.name.includes(this.filter_supplier) == false) {
                    return false
                }

                if(item.number.includes(this.filter_number) == false) {
                    return false
                }
                return true
            })
        },
        get_total_import_new: function() {
            var total_import = 0

            for(var item in this.data[1]){
                total_import = total_import + parseFloat(this.data[1][item].total_import)
            }

            return total_import.toFixed(2)
        },
        edit_mode: function () {
            return this.$store.getters.edit_mode
        },
        id_bill_edit: function() {
            return this.$store.getters.id_bill_edit
        }
    },
    async mounted() {
        this.blocked_edit_bill = true
        await this.$store.dispatch('set_current_bills_empty')
        await this.$store.dispatch('load_available_bills')
        if(this.edit_mode){
            await this.$store.dispatch('load_current_bills', this.id_bill_edit)
        } 
        this.blocked_edit_bill = false
    },
    methods: {
        async move_to_target(event) {
            for(var item in this.get_available_bills) {
                if(this.get_available_bills[item].id == event.items[0].id) {
                    return this.$store.dispatch('add_bill_to_target', this.get_available_bills[item])
                }
            }
        },
        async move_to_source(event) {
            for(var item in this.get_empty_bills) {
                if(this.get_empty_bills[item].id == event.items[0].id) {
                    return this.$store.dispatch('add_bill_to_source', this.get_empty_bills[item])
                }
            }
        },
        format_datetime(value) {
            if(value == null) {
                return '-'
            } else {
                return moment(value).format('YYYY-MM-DD')
            }
            
        },
        format_date(data){
            return moment(data).format('MMMM, yy')
        },
        create_new_proposal(){
            this.error_date = ''
            this.error_bills = ''

            if(this.date_payment_proposal == '' || this.data[1].length == 0) {
                if(this.date_payment_proposal == '') {
                    this.error_date = 'Please select a date.'
                }
                if(this.data[1].length == 0) {
                    this.error_bills = 'Please select at least 1 bill.'
                }

            } else {
                var new_proposal = {
                    date: this.date_payment_proposal,
                    bills: this.data[1]
                }
                this.$store.dispatch('create_proposal_payment', new_proposal)
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Success', detail:'Payment created.', life: 3000});
                this.$router.push('/billing/payment_proposal')
            }
        },
        edit_proposal() {
            this.error_date = ''
            this.error_bills = ''

            if(this.data[1].length == 0) {
                this.error_bills = 'Please select at least 1 bill.'
            } else {
                var new_proposal = {
                    id: this.id_bill_edit,
                    bills: this.data[1]
                }

                this.$store.dispatch('update_proposal_payment', new_proposal)
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Success', detail:'Payment created.', life: 3000});
                this.$router.push('/billing/payment_proposal')
            }
        }
    }
}
</script>

<style scoped>
:deep(.p-picklist-source-controls){
    display: none;
}

:deep(.p-picklist-target-controls){
    display: none;
}

:deep(.p-picklist-list) {
    height: 900px;
}

</style>