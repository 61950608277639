<template>
    <div class="custom_container">
        <ScrollPanel style="width: 90%; margin: auto; " >
            <div style="width: 1650px; margin: auto;">
                <div class="row mt-5" >
                    <Steps :model="items_title"/>
                </div>
                <div class="row" style="height: 67vh;">
                    <div class="col" style="height: 100%;" >
                        <ScrollPanel style="width: 100%; height: 100%;">
                            <Draggable :list="get_pending_tasks"  itemKey="title" group="task" id="pending_list" :move="check_move_pending" @start="onStart" @end="onEnd">
                                <template #header>
                                    <div v-if="$ability.can('add','task')" class="row justify-content-center" style="margin-top: 1rem; margin-left: 0; margin-right:0 ; width: 100%;">
                                        <div class="col-6 text-center">
                                            <Button icon="fa fa-plus" class="p-button-success p-button-rounded p-button-raised" @click="open_modal_new_task"/>
                                        </div>
                                    </div>
                                </template>
                                <template #item="{element}">
                                    <div class="card mb-2 status" draggable="true" style="cursor: pointer; " @click="display_info_task(element)">
                                        <PendingTask :task="element"/>
                                    </div>
                                </template>
                            </Draggable>
                        </ScrollPanel>
                    </div>
                    <div class="col" style="height: 100%;">
                        <ScrollPanel style="width: 100%; height: 100%;">
                            <Draggable style="height: 90%;" :list="get_scheduling_tasks" itemKey="title" group="task" id="scheduling_list" @change="on_change_scheduling($event)" :move="check_move_scheduling" @start="onStart" @end="onEnd">
                                <template #item="{element}">
                                    <div class="card mb-2 status" draggable="true" style="cursor: pointer;" @click="display_info_task(element)">
                                        <SchedulingTask :task="element"/>
                                    </div>
                                </template>
                            </Draggable>
                        </ScrollPanel>
                    </div>
                    <div class="col" style="height: 100%;">
                        <ScrollPanel style="width: 100%; height: 100%;">
                            <Draggable style="height: 90%;" :list="get_scheduled_tasks" itemKey="title" group="task" id="scheduled_list" @change="on_change_scheduled($event)" :move="check_move_scheduled" @start="onStart" @end="onEnd">
                                <template #item="{element}" >
                                    <div class="card mb-2 status" draggable="true" style="cursor: pointer;" @click="display_info_task(element)">
                                        <ScheduledTask :task="element"/>
                                    </div>
                                </template>
                            </Draggable>
                        </ScrollPanel>
                    </div>
                    <div class="col" style="height: 100%;">
                        <ScrollPanel style="width: 100%; height: 100%;">
                            <Draggable :list="get_finished_tasks" itemKey="title" group="task" id="finished_list" @change="on_change_finished($event)" :move="check_move_finished" @start="onStart" @end="onEnd">
                                <template #footer>
                                    <div class="row justify-content-center" style="margin-top: 1rem;">
                                        <div class="col-6 text-center">
                                            <Tag class="hover_button" style="cursor:pointer;" severity="info" rounded @click="$router.push({name: 'Tasks_budgets', params: { filter_board: 'Finished' }, })"> Check every finished task. </Tag>
                                        </div>
                                    </div>
                                </template>
                                <template #item="{element}" >
                                    <div class="card mb-2 status" draggable="true" style="cursor: pointer;" @click="display_info_task(element)">
                                        <FinishedTask :task="element"/>
                                    </div>
                                </template>
                            </Draggable>
                            
                        </ScrollPanel>
                    </div>
                </div>
            </div>
        </ScrollPanel>
    </div>
    <div class="container-fluid">
        <div class="row justify-content-between" style="bottom: 0; width:100%;">
            <div class="col">

            </div>
            <div v-if="$ability.can('change','task')" class="col-12 md:col-3 text-end">
                <Draggable :list="delete_list" class="card" itemKey="title" group="task" id="archived_list"  @change="on_change_archived($event)" style="border: 1px solid #face7d; border-radius: 10px;" >
                    <template #item="{element}" >
                        <div class="card mb-2 status" draggable="true" :class="{'priority_status':element.priority}">
                            <DeletedTask :task="element"/>
                        </div>
                    </template>
                    <template #footer>
                        <div class="p-2 text-center">
                            <Chip label="Drag to archive" icon="fa fa-box-archive" class="mb-2 " style="background-color:#ffffff; color: #face7d;" :class="{'archive_active':dragging}"/>
                        </div>
                    </template>
                </Draggable>
            </div>
            <div v-if="$ability.can('delete','task')" class="col-12 md:col-3 text-end">
                <Draggable :list="delete_list" class="card" itemKey="title" group="task" id="deleted_list"  @change="on_change_deleted($event)" style="border: 1px solid #E98A83; border-radius: 10px;" >
                    <template #item="{element}" >
                        <div class="card mb-2 status" draggable="true" :class="{'priority_status':element.priority}">
                            <DeletedTask :task="element"/>
                        </div>
                    </template>
                    <template #footer>
                        <div class="p-2 text-center">
                            <Chip label="Drag to delete" icon="fa fa-trash" class="mb-2 " style="background-color:#ffffff; color: #E98A83;" :class="{'delete_active':dragging}"/>
                        </div>
                    </template>
                </Draggable>
            </div>
            <div class="col-12 md:col text-end me-4 mt-3">
                
            </div>
        </div>
    </div>

    <Dialog header="New Task" v-model:visible="new_task_modal" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '60vw'}" :modal="true">
        <BlockUI :blocked="blocked_new_task" :fullScreen="true">
        <div v-if="blocked_new_task == true" style="position: absolute;margin-left: auto;margin-right: auto;left: 0;right: 0;bottom: 50%;text-align: center;z-index: 9099">
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
        </div>
        <div class="container">
            <form id="form_new_task" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                <div class="row">
                    <div class="col" style="display: flex; flex-direction: column;">
                        <div class="container">
                            <div class="row mt-3">
                                <div class="col text-center">
                                    <SelectButton :options="options" v-model="task_type" @change="reset_supplier" unselectable :disabled="!$ability.can('external','task')"/>
                                </div>
                            </div>
                            <div class="row mt-3 justify-content-between">
                                <div class="col">
                                    <span class="p-float-label">
                                        <InputText id="title" type="text" v-model="task_title" :class="{'p-invalid':v$.task_title.$invalid && submitted}"/>
                                        <label for="title"> Title <strong style="color: red;">*</strong> </label>
                                    </span>
                                    <small v-if="(v$.task_title.$invalid && submitted) || v$.task_title.$pending.$response" class="p-error">{{v$.task_title.required.$message.replace('value', 'title')}}</small>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <span class="p-float-label">
                                        <Dropdown id="terminal" v-model="task_terminal" :options="get_all_terminals" optionLabel="name" optionValue="id" :class="{'p-invalid':v$.task_terminal.$invalid && submitted}"/>
                                        <label for="terminal"> Terminal <strong style="color: red;">*</strong></label>
                                    </span>
                                    <small v-if="(v$.task_terminal.$invalid && submitted) || v$.task_terminal.$pending.$response" class="p-error">{{v$.task_terminal.required.$message.replace('value', 'terminal')}}</small>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <span class="p-float-label">
                                        <Dropdown id="category" v-model="task_category" :options="get_task_categories" optionLabel="name" optionValue="id" :class="{'p-invalid':v$.task_category.$invalid && submitted}">
                                            <template v-if="$ability.can('add','task_category')" #footer>
                                                <div class="container p-fluid">
                                                    <div class="row">
                                                        <div class="col-10 text-center my-auto">
                                                            <InputText v-model="new_task_category" class="p-inputtext-sm"  placeholder="Create a new task category"/>
                                                        </div>
                                                        <div class="col-2">
                                                            <Button icon="fa fa-plus" class="p-button-text p-button-success p-button-raised p-button-rounded p-button-sm" @click="add_new_task_category"/>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </template>
                                        </Dropdown>
                                        <label for="category"> Category <strong style="color: red;">*</strong></label>
                                    </span>
                                    <small v-if="(v$.task_category.$invalid && submitted) || v$.task_category.$pending.$response" class="p-error">{{v$.task_category.required.$message.replace('value', 'category')}}</small>
                                </div>
                            </div>
                            <div v-if="task_type=='External'" class="row mt-3" >
                                <div class="col">
                                    <span class="p-float-label">
                                        <Dropdown id="supplier" v-model="task_supplier" :options="get_all_suppliers" optionLabel="name" optionValue="id" :class="{'p-invalid':v$.task_supplier.$invalid && submitted}"/>
                                        <label for="supplier"> Supplier <strong style="color: red;">*</strong></label>
                                    </span>
                                    <small v-if="(v$.task_supplier.$invalid && submitted) || v$.task_supplier.$pending.$response" class="p-error">{{v$.task_supplier.required.$message.replace('value', 'supplier')}}</small>
                                </div>
                            </div>
                            <div class="row mt-4" style="margin-top: auto;">
                                <div class="col">
                                    <span class="p-float-label">
                                        <TextArea id="supplier" v-model="task_description" rows="2" style="width: 100%" :class="{'p-invalid':v$.task_description.$invalid && submitted}"/>  
                                        <label for="supplier"> Description <strong style="color: red;">*</strong></label>
                                    </span> 
                                    <small v-if="(v$.task_description.$invalid && submitted) || v$.task_description.$pending.$response" class="p-error">{{v$.task_description.required.$message.replace('value', 'description')}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col" style="border-left: 1px solid lightgray; display: flex; flex-direction: column;">
                        <div class="row justify-content-center">
                            <div class="col-8" style="padding-bottom: 0px;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Priority</h5>
                            </div>
                            <div class="col-2">
                                <InputSwitch id="urgency" v-model="task_urgency"/>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-10" style="padding-top: 0px;">
                                <small> Mark this field if the task requires maximum priority.</small>
                            </div>
                        </div>
                        <div v-if="task_type=='Internal' && check_user_task_responsible" class="row justify-content-center" style="margin-top: auto;">
                            <div class="col-10" style="padding-bottom: 0px;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Responsible</h5>
                            </div>
                        </div>
                        <div v-if="task_type=='Internal' && check_user_task_responsible" class="row justify-content-center">
                            <div class="col-10" style="padding-top: 0px;">
                                <small> Assign a responsible to this task.</small>
                            </div>
                        </div>
                        <div v-if="task_type=='Internal' && check_user_task_responsible" class="row justify-content-center">
                            <div class="col-10">
                                <Dropdown v-model="task_user" :options="users_by_port" :filter="true" optionLabel="username" listStyle="max-height:220px" placeholder="Select a user...">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value" class="row">
                                            <div class="col-2 ms-2">
                                                <div class="circle-image">
                                                    <img :src="slotProps.value.profile.picture" class="profile-pic" @click="toggle"/>
                                                </div> 
                                            </div>
                                            <div class="col my-auto mx-auto">
                                                {{slotProps.value.username}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{slotProps.placeholder}}
                                        </div>
                                    </template>
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col-2">
                                                <div class="circle-image">
                                                    <img :src="slotProps.option.profile.picture" class="profile-pic" @click="toggle"/>
                                                </div> 
                                            </div>
                                            <div class="col my-auto mx-auto">
                                                {{slotProps.option.username}}
                                            </div>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>
                        <div class="row justify-content-center" style="margin-top: auto;">
                            <div class="col-10" style="padding-bottom: 0px;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Picture</h5>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-10">
                                <FileUpload ref="fileUpload_picture" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUpload_picture_new_task"/>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        </BlockUI>
        <template #footer>
            <div class="row">
                <div class="col">
                    <Button label="Cancel" class="p-button-text" @click="hide_new_task_form"/>
                    <Button label="Create" form="form_new_task" type="submit"/>
                </div>
            </div>
        </template>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="display_confirmation_delete" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '70vw'}"  :modal="true">
        <div class="container">
            <div class="row">
                <span> Are you sure you want to delete permanently the task with the title <strong>{{removed_task.title}}</strong> ? </span>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="cancel_delete_task"/>
            <Button label="Confirm" @click="delete_task"/>
        </template>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="display_confirmation_archived" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '70vw'}"  :modal="true">
        <div class="container">
            <div class="row">
                <span> Are you sure you want to archive permanently the task with the title <strong>{{removed_task.title}}</strong> ? </span>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button-text" @click="cancel_delete_task"/>
            <Button label="Confirm" @click="archive_task"/>
        </template>
    </Dialog>

    <Dialog :header="current_task.title" v-model:visible="display_validation_task_dialog" :breakpoints="{'960px': '85vw', '640px': '90vw'}" :style="{width: '40vw'}"  :modal="true">
        <div class="container">
            <div v-if="current_task.periodical">
                <div class="col-12">
                    <p> Do you really want to validate the current task?</p>
                </div>
            </div>
            <div v-if="!current_task.internal" class="row justify-content-center">
                <div class="col-12">
                    <p> Do you really want to validate the current task?</p>
                </div>
            </div>
            <div v-if="current_task.id_user_assigned" class="row justify-content-center">
                <div class="col-12">
                    <p> Do you really want to validate the current task?</p>
                </div>
            </div>
            <div v-if="current_task.status == 'Pending' && (check_user_task_responsible || !current_task.id_user_assigned)" class="row justify-content-start" style="margin-top: auto;">
                <div class="col-4" style="padding-bottom: 0px;">
                    <h5> Responsible </h5>
                </div>
            </div>
            <div v-if="current_task.status == 'Pending' && !current_task.id_user_assigned" class="row justify-content-start">
                <div class="col" style="padding-top: 0px;">
                    <small> You must assign a responsible to this task before procceding to the next step.</small>
                </div>
            </div>
            <div v-if="current_task.status == 'Pending' && (check_user_task_responsible || !current_task.id_user_assigned)" class="row justify-content-start">
                <div class="col-4 p-fluid">
                    <Dropdown v-model="current_task.id_user_assigned" :options="users_by_port" :filter="true" optionLabel="username" listStyle="max-height:220px" placeholder="Select a user...">
                        <template #value="slotProps">
                            <div v-if="slotProps.value" class="row">
                                <div class="col my-auto ms-2">
                                    {{slotProps.value.username}}
                                </div>
                            </div>
                            <div v-else>
                                {{slotProps.placeholder}}
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="row">
                                <div class="col-2 md:col-2">
                                    <div class="circle-image">
                                        <img :src="slotProps.option.profile.picture" class="profile-pic" @click="toggle"/>
                                    </div> 
                                </div>
                                <div class="col my-auto mx-auto">
                                    {{slotProps.option.username}}
                                </div>
                            </div>
                        </template>
                    </Dropdown> 
                </div>
            </div>
        </div>
         <template #footer>
            <div v-if="current_task.status == 'Pending'">
                <Button label="No" icon="pi pi-times" @click="cancel_pending_form" class="p-button-text" />
                <Button label="Yes" icon="pi pi-check" @click="form_pending" autofocus :disabled="!current_task.id_user_assigned"/>
            </div>
            <div v-else-if="current_task.status == 'Scheduled'">
                <Button label="No" icon="pi pi-times" @click="cancel_scheduled_form" class="p-button-text"/>
                <Button label="Yes" icon="pi pi-check" @click="form_scheduled" autofocus />
            </div>
        </template>
    </Dialog>

    <Dialog header="Information" v-model:visible="display_pending_task_info" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: width_modal}" :modal="true">
        <BlockUI :blocked="blocked_new_task" :fullScreen="true">
        <div v-if="blocked_new_task == true" style="position: absolute;margin-left: auto;margin-right: auto;left: 0;right: 0;bottom: 50%;text-align: center;z-index: 9099">
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
        </div>
        <div class="p-fluid container">
            <div class="row mb-4">
                <div class="col">
                    <div class="container">
                        <div class="row ">
                            <div class="col text-end">
                                <div v-if="!info_internal">
                                    <Tag severity="info"> <p style="margin-bottom: 0px;">External</p> </Tag>
                                </div>
                                <div v-else >
                                    <Tag severity="primary"> <p style="margin-bottom: 0px;">Internal</p> </Tag>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="p-float-label">
                                    <InputText id="title_inf" class="input-edit" v-model="info_title"/>
                                    <label for="title_inf">Title</label>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_task_categories" optionLabel="name" optionValue="id" v-model="info_category.id"/>
                                    <label for="title">Category</label>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_all_terminals" optionLabel="name" optionValue="id" v-model="info_terminal.id"/>
                                    <label for="title"><small style="color: gray;">Terminal</small></label>
                                </span>
                            </div>
                        </div>
                        <div v-if="!info_internal" class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_all_suppliers" optionLabel="name" optionValue="id" v-model="info_supplier.id"/>
                                    <label for="title" ><small style="color: gray;">Supplier</small></label>
                                </span>
                            </div>  
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 my-auto" style="padding-bottom: 0px;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Picture </h5>
                            </div>
                        </div>
                        <div class="row justify-content-between">
                            <div v-if="info_picture"  class="col-12 md:col-6">
                                <Button label="Picture" icon="pi pi-eye" style="padding: 0; height: 50px; object-fit: cover;" class="text-button p-button-text p-button-raised">
                                    <div class="row">
                                        <Image :src="info_picture" preview imageStyle="width: 100%; opacity: 0;z-index: 2;"/>
                                        <span class="text-hidden" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"><i class="fa-solid fa-magnifying-glass"></i> Image </span>
                                    </div>
                                    
                                </Button>
                            </div>
                            <div class="col-12 md:col-6 text-end">
                                <FileUpload ref="fileUpload_picture" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUpload_picture" />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <TextArea id="title" v-model="info_description" rows="2"/>
                                    <label for="title"><small style="color: gray;">Description</small></label>
                                </span> 
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12" style="padding-bottom: 0px;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Responsible </h5>
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12" style="padding-top: 0px;">
                                <small> Assign a responsible to this task.</small>
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12 my-auto">
                                <Dropdown  v-model="info_user_assigned" :disabled="!check_user_task_responsible" :options="users_by_port" :filter="true" optionLabel="username" listStyle="max-height:220px" placeholder="Select a user...">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value" class="row">
                                            <div class="col my-auto ms-2">
                                                {{slotProps.value.username}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{slotProps.placeholder}}
                                        </div>
                                    </template>
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col-1">
                                                <div class="circle-image">
                                                    <img :src="slotProps.option.profile.picture" class="profile-pic" @click="toggle"/>
                                                </div> 
                                            </div>
                                            <div class="col my-auto mx-auto">
                                                {{slotProps.option.username}}
                                            </div>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>
                        <div class="row justify-content-end">
                            <div class="col-12 md:col-4">
                                <Button label="Save" class="p-button-raised" @click="save_task"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!info_internal" class="col-12 lg:col-6 border_left_divider" style="display: flex; flex-direction: column;">
                    <div v-if="!info_internal" class="row justify-content-center margin_mobile">
                        <div class="col-6 text-start" >
                            <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Budget Request</h5> 
                        </div>
                        <div class="col-4 text-end ">
                            <span v-if="get_all_budgets.length > 0">
                                <span v-if="info_budget_status == 'On hold'">
                                    <small style="color: gray"> Budget </small> <Tag :value="'On hold'"></Tag>
                                </span>
                                <span v-else-if="info_budget_mail_sent == true && (get_budgets_tasks[0].status == 'Rejected' || get_budgets_tasks[0].status == 'Confirmed')">
                                    <small style="color: gray"> Budget </small> <Tag :value="'Pending'"></Tag>
                                </span>
                                <span v-else>
                                    <small style="color: gray"> Budget </small> <Tag :value="info_budget_status" :class="{'budget_success': info_budget_status == 'Confirmed', 'budget_rejected': info_budget_status == 'Rejected'}"></Tag>
                                </span>
                            </span> 
                            <span v-else-if="info_budget_mail_sent == true">
                                <small style="color: gray"> Budget </small> <Tag :value="'Pending'"></Tag>
                            </span>
                            <span v-else>
                                <small style="color: gray"> Budget </small> <Tag :value="'Not sent'"></Tag>
                            </span>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10">
                            <small>The following text will be inclosed inside the body email.</small>
                        </div>
                    </div>
                    <div v-if="!info_internal" class="row justify-content-center">
                        <div class="col-10">
                            <small style="color:gray;"> Email description </small>
                            <TextArea id="mail_description" :autoResize="true" v-model="info_mail_description" placeholder="Your email"  v-tooltip.right="'In order to send a new email, the description must be different from the last one.'"/>
                        </div>
                    </div>
                    <div v-if="!info_internal && info_budget_status!='Confirmed'" class="row justify-content-center">
                        <div class="col-10 md:col-4">
                            <Button label="Send" icon="fa-regular fa-paper-plane" class="p-button-rounded p-button-raised p-button-text" @click="send_budget_mail" :disabled="!(info_mail_description != '' && info_mail_description != prev_info_mail_description && (info_budget_status == 'Rejected' || info_budget_status == 'Not sent'))"/>
                        </div>
                    </div>
                    <div class="row text-center" style="margin-top: auto;">
                        <span v-if="get_all_budgets.length > 0">
                            <span v-if="info_budget_status == 'On hold'">
                                <Tag severity="warning"> The new budget requires validation.</Tag>
                            </span>
                            <span v-else-if="info_budget_mail_sent == true && (get_budgets_tasks[0].status == 'Rejected' || get_budgets_tasks[0].status == 'Confirmed')">
                                <Tag severity="info"> The email has already been sent.<br>  Sending a new email, will overwrite the previous one. </Tag>
                            </span>
                        </span> 
                        <span v-else-if="info_budget_mail_sent == true">
                            <Tag severity="info"> The email has already been sent. <br> Sending a new email, will overwrite the previous one. </Tag>
                        </span>
                        <span v-else>
                            <Tag severity="info"> No email has been sent.</Tag>
                        </span>
                    </div>
                    <div v-if="!info_internal" class="row justify-content-center">
                        <div class="col-10">
                            <div class="row">
                                <div v-if="get_all_budgets.length > 0" class="col-12 md:col text-start my-auto">
                                    <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Budget</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div v-if="(info_budget_status=='On hold' || info_budget_status=='Rejected') && !info_internal" class="col text-center">
                                    <Button icon="fa fa-xmark" class="p-button-danger p-button-raised p-button-rounded" @click="reject_budget" :disabled="info_budget_status=='Rejected'"/>
                                </div>
                                <div v-if="get_all_budgets.length > 0 && (info_budget_status=='On hold' || info_budget_status=='Confirmed' || info_budget_status=='Rejected')" class="col text-center">
                                    <a :href="get_all_budgets[0].budget" target="_blank" style="text-decoration: none;"> <Button icon="fa fa-magnifying-glass" class="p-button-raised p-button-text p-button-rounded"/> </a>
                                </div>
                                <div v-if="(info_budget_status=='On hold' || info_budget_status=='Rejected') && !info_internal" class="col text-center">
                                    <Button icon="fa fa-check" class="p-button-success p-button-raised p-button-rounded" @click="confirm_budget" :disabled="info_budget_status=='Rejected'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </BlockUI>
    </Dialog>

    <Dialog header="Information" v-model:visible="display_scheduling_task_info" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: width_modal}" :modal="true" @hide="cancel_new_proposal">
        <BlockUI :blocked="blocked_new_task" :fullScreen="true">
        <div v-if="blocked_new_task == true" style="position: absolute;margin-left: auto;margin-right: auto;left: 0;right: 0;bottom: 50%;text-align: center;z-index: 9099">
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
        </div>
        <div class="p-fluid">
            <div class="row">
                <div class="col">
                    <div class="container">
                        <div class="row">
                            <div class="col text-end">
                                <div v-if="!info_internal">
                                    <Tag severity="info"> <p style="margin-bottom: 0px;">External</p> </Tag>
                                </div>
                                <div v-else >
                                    <Tag severity="primary"> <p style="margin-bottom: 0px;">Internal</p> </Tag>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="p-float-label">
                                    <InputText id="title_inf" class="input-edit" v-model="info_title"/>
                                    <label for="title_inf">Title</label>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_task_categories" optionLabel="name" optionValue="id" v-model="info_category.id"/>
                                    <label for="title"> Category </label>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_all_terminals" optionLabel="name" optionValue="id" v-model="info_terminal.id"/>
                                    <label for="title"><small style="color: gray;"> Terminal </small></label>
                                </span>
                            </div>
                        </div>
                        <div v-if="!info_internal" class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_all_suppliers" optionLabel="name" optionValue="id" v-model="info_supplier.id" disabled/>
                                    <label for="title" ><small style="color: gray;"> Supplier </small></label>
                                </span>
                            </div>  
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 my-auto" style="padding-bottom: 0px;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Picture</h5>
                            </div>
                        </div>
                        <div class="row justify-content-between">
                            <div class="col-6 md:col-4">
                                <Button label="Picture" icon="pi pi-eye" style="padding: 0; height: 40px;" class="text-button p-button-text p-button-raised">
                                    <Image :src="info_picture" preview imageStyle="width: 100%; opacity: 0;z-index: 2;"/>
                                    <span class="text-hidden" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"><i class="fa-solid fa-magnifying-glass"></i> Image </span>
                                </Button>
                            </div>
                            <div class="col md:col text-end">
                                <FileUpload ref="fileUpload_picture" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUpload_picture" />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <TextArea id="title" v-model="info_description" rows="3"/>
                                    <label for="title"><small style="color: gray;">Description</small></label>
                                </span> 
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12" style="padding-bottom: 0px;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Responsible</h5>
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12" style="padding-top: 0px;">
                                <small> Assign a responsible to this task.</small>
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12 my-auto">
                                <Dropdown  v-model="info_user_assigned" :disabled="!check_user_task_responsible" :options="users_by_port" :filter="true" optionLabel="username" listStyle="max-height:220px" placeholder="Select a user...">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value" class="row">
                                            <div class="col my-auto ms-2">
                                                {{slotProps.value.username}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{slotProps.placeholder}}
                                        </div>
                                    </template>
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col-1">
                                                <div class="circle-image">
                                                    <img :src="slotProps.option.profile.picture" class="profile-pic" @click="toggle"/>
                                                </div> 
                                            </div>
                                            <div class="col my-auto mx-auto">
                                                {{slotProps.option.username}}
                                            </div>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>
                         <div v-if="!info_internal" class="row justify-content-between">
                            <div class="col-12 md:col-4">
                                <a :href="info_budget" target="_blank" style="text-decoration: none;"> <Button label="Budget" icon="fa fa-coins" class="p-button-raised p-button-text"/> </a>
                            </div>
                            <div class="col-12 md:col-4">
                                <Button label="Save" class="p-button-raised" @click="save_task"/>
                            </div>
                        </div>
                        <div v-else class="row justify-content-between">
                            <div class="col-12 md:col-3">
                                <Button label="Set Date" icon="fa fa-calendar" class="p-button-raised" @click="$router.push({name: 'Tasks_calendar', params:{terminal_optional: info_terminal.name}})"/>
                            </div>
                            <div class="col-12 md:col-2">
                                <Button label="Save" class="p-button-raised" @click="save_task"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!info_internal" class="col-12 xl:col-6 border_left_divider text-center" style="display: flex; flex-direction: column;">
                    <span v-if="info_proposal_supplier == null || info_proposal_supplier == false">
                        <div class="container">
                            <div class="row">
                                <div class="col text-start" style="padding-bottom: 0px;">
                                   <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Date Email</h5>
                                </div>
                            </div>
                            <div class="row" >
                                <div class="col text-start" style="padding-top: 0px;">
                                    <span> <small> In order to send the email, please fill in the following fields </small> </span>
                                </div>
                            </div>
                            <div v-if="proposal_start_date" class="card mb-5">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <h6><strong>Last Proposal</strong></h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 md:col-3">
                                            <small><strong>Start</strong></small>
                                        </div>
                                        <div class="col-12 md:col">
                                            <i class="fa fa-calendar" style="color:gray;"></i> {{proposal_start_date}} 
                                        </div>
                                        <div class="col-12 md:col">
                                            <i class="fa fa-clock" style="color:gray;"></i> {{proposal_start_hour}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-12 md:col-3">
                                            <small><strong>End</strong></small>
                                        </div>
                                        <div class="col-12 md:col">
                                            <i class="fa fa-calendar" style="color:gray;"></i> <span v-if="proposal_end_date">{{proposal_end_date}} </span><span v-else> - </span>
                                        </div>
                                        <div class="col-12 md:col">
                                            <i class="fa fa-clock" style="color:gray;"></i> <span v-if="proposal_end_hour">{{proposal_end_hour}} </span><span v-else> - </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <span class="p-float-label">
                                        <Calendar id="proposal_date" v-model="new_proposal_start_date" dateFormat="yy-mm-dd"/>
                                        <label for="proposal_date"> Day </label>
                                    </span>
                                </div>
                                <div class="col">
                                    <span class="p-float-label">
                                        <Calendar id="proposal_date" v-model="new_proposal_start_hour" :timeOnly="true" hourFormat="24"  @change="new_proposal_start_hour = num2time($event)"/>
                                        <label for="proposal_date"> Hour </label>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <small style="color: red;"> {{error_date_proposal}} </small>
                                </div>
                            </div>
                            <div class="row justify-content-center mt-2">
                                <div class="col-12 md:col-7">   
                                    <Button label="Send proposal" icon="fa-regular fa-calendar" class="p-button-text p-button-raised" @click="send_date_proposal_mail"/>
                                </div>
                            </div>
                            <div class="row mb-5" >
                                <div class="col align-self-center">
                                    <span v-if="info_proposal_supplier == null">
                                        <small>No email has been sent yet.</small>
                                    </span>
                                    <span v-else-if="info_proposal_supplier == false">
                                        <small><i class="fa fa-triangle-exclamation" style="color: orange;"></i> An email has already been sent and only the supplier confirmation is missing. A new email can be sent but it will overwrite the previous one.</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </span>
                    <span v-else>
                        <div class="container">
                            <div v-if="proposal_start_date">
                                <div class="content-section introduction">
                                    <div class="feature-intro text-start">
                                        <small> A proposal date has been sent by the supplier. Another proposal can be sent if it is necessary. </small>
                                    </div>
                                </div>
                            </div>
                            <div v-if="proposal_start_date" class="card">
                                <div class="container">
                                    <div class="row mt-3">
                                        <div class="col">
                                            <h6><strong>Supplier Proposal</strong></h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 md:col-3">
                                            <small><strong>Start</strong></small>
                                        </div>
                                        <div class="col-12 md:col">
                                            <i class="fa fa-calendar" style="color:gray;"></i> {{proposal_start_date}} 
                                        </div>
                                        <div class="col-12 md:col">
                                            <i class="fa fa-clock" style="color:gray;"></i> {{proposal_start_hour}}
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-12 md:col-3">
                                            <small><strong>End</strong></small>
                                        </div>
                                        <div class="col-12 md:col">
                                            <i class="fa fa-calendar" style="color:gray;"></i> <span v-if="proposal_end_date">{{proposal_end_date}} </span><span v-else> - </span>
                                        </div>
                                        <div class="col-12 md:col">
                                            <i class="fa fa-clock" style="color:gray;"></i> <span v-if="proposal_end_hour">{{proposal_end_hour}} </span><span v-else> - </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center mt-4">
                                <div class="col-10">
                                    <div class="row justify-content-between">
                                        <div class="col-12 md:col-5">
                                            <Button label="New proposal" class="p-button-raised p-button-text" @click="send_new_proposal"/>
                                        </div>
                                        <div class="col-12 md:col-5">
                                            <Button label="Confirm" class="p-button-success p-button-raised" @click="accept_proposal"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="new_proposal" class="card">
                                <div class="container">
                                    <div class="row mt-3">
                                        <div class="col">
                                            <h6><strong>New Proposal</strong></h6>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="col">
                                            <span class="p-float-label">
                                                <Calendar id="proposal_date" v-model="new_proposal_start_date" dateFormat="yy-mm-dd"/>
                                                <label for="proposal_date"> Day </label>
                                            </span>
                                        </div>
                                        <div class="col">
                                            <span class="p-float-label">
                                                <Calendar id="proposal_date" v-model="new_proposal_start_hour" :timeOnly="true" hourFormat="24"  @change="new_proposal_start_hour = num2time($event)"/>
                                                <label for="proposal_date"> Hour </label>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center mt-2 mb-3">
                                        <div class="col-12 md:col-6">   
                                            <Button label="Send" icon="fa fa-paper-plane"  @click="send_date_proposal_mail"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="new_proposal" class="row">
                                <div class="col">
                                    <small style="color: red;"> {{error_date_proposal}} </small>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        </BlockUI>
    </Dialog>
    
    <Dialog header="Information" v-model:visible="display_scheduled_task_info" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: width_modal}" :modal="true">
        <BlockUI :blocked="blocked_new_task" :fullScreen="true">
        <div v-if="blocked_new_task == true" style="position: absolute;margin-left: auto;margin-right: auto;left: 0;right: 0;bottom: 50%;text-align: center;z-index: 9099">
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem;" :style="{color: color}"></i>
        </div>
        <div class="p-fluid">
            <div class="row">
                <div class="col">
                    <div class="container">
                        <div v-if="info_internal == true" class="row justify-content-around">
                            <div class="col-4 text-center" style="border-bottom: 1px solid lightgray;">
                                <span style="color: gray;"> Start </span>
                            </div>
                            <div class="col-4 text-center" style="border-bottom: 1px solid lightgray;">
                                <span style="color: gray; "> End </span>
                            </div>
                        </div>
                        <div v-if="info_internal == true" class="row">
                            <div class="col text-center">
                                <i class="fa fa-calendar me-2" style="color: gray;"></i>
                                {{format_datetime(info_start_datetime)}} - {{format_time(info_start_datetime)}}
                            </div>
                            <div class="col text-center ">
                                <i class="fa fa-calendar me-2" style="color: gray;"></i>
                                {{format_datetime(info_end_datetime)}} - {{format_time(info_end_datetime)}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-end">
                                <div v-if="!info_internal">
                                    <Tag severity="info"> <p style="margin-bottom: 0px;">External</p> </Tag>
                                </div>
                                <div v-else >
                                    <Tag severity="primary"> <p style="margin-bottom: 0px;">Internal</p> </Tag>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="p-float-label">
                                    <InputText id="title_inf" class="input-edit" v-model="info_title"/>
                                    <label for="title_inf">Title</label>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_task_categories" optionLabel="name" optionValue="id" v-model="info_category.id"/>
                                    <label for="title">Category</label>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_all_terminals" optionLabel="name" optionValue="id" v-model="info_terminal.id"/>
                                    <label for="title"><small style="color: gray;">Terminal</small></label>
                                </span>
                            </div>
                        </div>
                        <div v-if="!info_internal" class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_all_suppliers" optionLabel="name" optionValue="id" v-model="info_supplier.id" disabled/>
                                    <label for="title" ><small style="color: gray;">Supplier</small></label>
                                </span>
                            </div>  
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 my-auto" style="padding-bottom: 0px;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Picture</h5>
                            </div>
                        </div>
                        <div class="row justify-content-between">
                            <div class="col-6 md:col-5">
                                <Button label="Picture" icon="pi pi-eye" style="padding: 0; height: 40px;" class="text-button p-button-text p-button-raised">
                                    <Image :src="info_picture" preview imageStyle="width: 100%; opacity: 0;z-index: 2;"/>
                                    <span class="text-hidden" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"><i class="fa-solid fa-magnifying-glass"></i> Image </span>
                                </Button>
                            </div>
                            <div class="col-6 text-end">
                                <FileUpload ref="fileUpload_picture" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUpload_picture" />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <TextArea id="title" v-model="info_description" rows="3"/>
                                    <label for="title"><small style="color: gray;">Description</small></label>
                                </span> 
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12" style="padding-bottom: 0px;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Responsible</h5>
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12" style="padding-top: 0px;">
                                <small> Assign a responsible to this task.</small>
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12 my-auto">
                                <Dropdown  v-model="info_user_assigned" :disabled="!check_user_task_responsible" :options="users_by_port" :filter="true" optionLabel="username" listStyle="max-height:220px" placeholder="Select a user...">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value" class="row">
                                            <div class="col my-auto ms-2">
                                                {{slotProps.value.username}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{slotProps.placeholder}}
                                        </div>
                                    </template>
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col-1">
                                                <div class="circle-image">
                                                    <img :src="slotProps.option.profile.picture" class="profile-pic" @click="toggle"/>
                                                </div> 
                                            </div>
                                            <div class="col my-auto mx-auto">
                                                {{slotProps.option.username}}
                                            </div>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>
                        <div class="row">
                            
                        </div>
                        <div v-if="!info_internal" class="row justify-content-between">
                            <div class="col-12 md:col-4">
                                <a :href="info_budget" target="_blank" style="text-decoration: none;"> <Button label="Budget" icon="fa fa-coins" class="p-button-raised p-button-text"/> </a>
                            </div>
                            <div class="col-12 md:col-4">
                                <Button label="Save" class="p-button-raised" @click="save_task"/>
                            </div>
                        </div>
                        <div v-else class="row justify-content-end">
                            <div class="col-12 md:col-2">
                                <Button label="Save" class="p-button-raised" @click="save_task"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!info_internal" class="col-12 xl:col-6 border_left_divider text-center" style="display: flex; flex-direction: column;"> 
                    <div class="container">
                        <div class="row">
                            <div class="col-9 md:col-10 text-start my-auto" style="padding-bottom: 0px;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Workers</h5>
                            </div>
                            <div v-if="get_worker_list.length > 0" class="col text-end">
                                <Button type="button" icon="fa fa-chevron-down" @click="toggle_worker_list" class="p-button-sm p-button-rounded p-button-text p-button-raised"/>
                            </div>
                            <div v-else-if="info_worker_file_mail_sent == false" class="col text-end">
                                <Button v-tooltip="'Send mail to demand worker list.'"  icon="fa-regular fa-envelope" @click="request_worker_list" class="p-button-sm p-button-rounded p-button-text p-button-raised"/>
                            </div>
                            <div v-else class="col text-end">
                                <Button v-tooltip="'An email has already been sent, click if you want to send another email.'" type="button" @click="confirmation_resend_mail" icon="fa fa-arrow-rotate-right"  class="p-button-sm p-button-rounded p-button-text p-button-raised me-2"/>
                            </div>
                        </div>
                        <div class="row" style="margin-top: auto;">
                            <div class="col text-start" style="padding-bottom: 0px; margin-top: auto;">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Schedule</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-start" style="padding-top: 0px;">
                                <span><small> In order to upload the work report, please fill the following fields </small></span>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 md:col-3 mt-2">
                                <Tag> Started at </Tag>
                            </div>
                            <div class="col-6 md:col-6 text-center">
                                <Calendar v-model="start_date" dateFormat="yy-mm-dd" disabled/>
                            </div>
                            <div class="col-6 md:col-3 text-center">
                                <Calendar v-model="start_hour" hourFormat="24" :timeOnly="true" disabled/>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 md:col-3 mt-2">
                                <Tag> Finished at </Tag>
                            </div>
                            <div class="col-6 md:col-6 text-center">
                                <span>
                                    <Calendar v-model="info_end_date" dateFormat="yy-mm-dd" />
                                </span>
                            </div>
                            <div class="col-6 md:col-3 text-center">
                                <span>
                                    <Calendar v-model="info_end_hour" hourFormat="24" :timeOnly="true"  @change="info_end_hour = num2time($event)"/>
                                </span>
                            </div>
                        </div>
                        <div class="row justify-content-center" v-if="get_error_update_schedule_task_end_date">
                            <span class="text-center" v-for="item in get_error_update_schedule_task_end_date.start_datetime" :key="item">
                                <small style="color:red;">{{item}}</small>
                            </span>
                        </div>
                        <div v-if="(info_end_date != '' || info_end_hour != '') && (this.prev_end_date != this.info_end_date || this.prev_end_hour != this.info_end_hour)" class="row justify-content-center">
                            <div class="col-4 text-end">
                                <Button class="p-button-success" label="Save" @click="save_end_date_proposal"/>
                            </div>
                        </div>
                        <div class="row" style="margin-top: auto">
                            <div class="col text-start my-auto">
                                <h5 style="color:gray; border-bottom: 1px solid lightgray; padding-bottom: 7px;"> Work Report</h5>
                            </div>
                        </div>
                        <div v-if="!after_today" class="row">
                            <div class="col text-start">
                                <span ><small> If the task has not finished, the upload option won't be available.  </small> </span>
                            </div>
                        </div>
                        <div v-if="after_today && info_end_datetime" class="row">
                            <div class="col">
                                <small> Upload your own work report.  </small>
                            </div>
                            <div class="col">
                                <small> Demand the work report.  </small>
                            </div>
                        </div>
                        <div v-if="after_today && info_end_datetime" class="row justify-content-between">
                            <div class="col-6 text-center">
                                
                                <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*, application/pdf" :auto="true" :maxFileSize="10000000" @uploader="onUpload"/>
                            </div>
                            <div class="col-6 text-center" style="border-left: 1px solid lightgray;">
                                
                                <Button v-tooltip="'Send mail to demand work report.'"  icon="fa-regular fa-envelope" @click="confirmation_send_mail_work_report" class="p-button-sm p-button-rounded p-button-text p-button-raised ms-2"/>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <a v-if="info_work_report" :href="info_work_report" target="_blank" style="text-decoration: none;"> <Button v-tooltip="'View work report.'" label="Open Work Report" icon="fa fa-magnifying-glass" class="p-button-raised p-button-text p-button-rounded"/> </a>
                            </div>
                        </div>
                        <div v-if="info_work_report_mail_sent" class="row">
                            <span> An email requesting the work report has already been sent.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </BlockUI>
    </Dialog>
    
    <Dialog header="Information" v-model:visible="display_finished_task_info" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: width_modal}" :modal="true">
        <div class="p-fluid">
            <div class="row">
                <div class="col">
                    <div class="container">
                        <div class="row">
                            <div class="col text-end">
                                <div v-if="!info_internal">
                                    <Tag severity="info"> <p style="margin-bottom: 0px;">External</p> </Tag>
                                </div>
                                <div v-else>
                                    <Tag severity="primary"> <p style="margin-bottom: 0px;">Internal</p> </Tag>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="p-float-label">
                                    <InputText id="title_inf" class="input-edit" v-model="info_title" disabled/>
                                    <label for="title_inf">Title</label>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_task_categories" optionLabel="name" optionValue="id" v-model="info_category.id" disabled/>
                                    <label for="title">Category</label>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_all_terminals" optionLabel="name" optionValue="id" v-model="info_terminal.id" disabled/>
                                    <label for="title"><small style="color: gray;">Terminal</small></label>
                                </span>
                            </div>
                        </div>
                        <div v-if="!info_internal" class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <Dropdown id="title" :options="get_all_suppliers" optionLabel="name" optionValue="id" v-model="info_supplier.id" disabled/>
                                    <label for="title" ><small style="color: gray;">Supplier</small></label>
                                </span>
                            </div>  
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 my-auto" style="padding-bottom: 0px;">
                                <h5> Picture </h5>
                            </div>
                        </div>
                        <div class="row justify-content-between">
                            <div class="col-12 md:col-4">
                                <Button label="Picture" icon="pi pi-eye" style="padding: 0; height: 40px;" class="text-button p-button-text p-button-raised">
                                    <Image :src="info_picture" preview imageStyle="width: 100%; opacity: 0;z-index: 2;"/>
                                    <span class="text-hidden" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"><i class="fa-solid fa-magnifying-glass"></i> Image </span>
                                </Button>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <span class="p-float-label">
                                    <TextArea id="title" v-model="info_description" disabled rows="3"/>
                                    <label for="title"><small style="color: gray;">Description</small></label>
                                </span> 
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12" style="padding-bottom: 0px;">
                                <h5> Responsible </h5>
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12" style="padding-top: 0px;">
                                <small> Assign a responsible to this task.</small>
                            </div>
                        </div>
                        <div v-if="info_internal" class="row justify-content-center">
                            <div class="col-12 my-auto">
                                <Dropdown  v-model="info_user_assigned" :options="users_by_port" :filter="true" optionLabel="username" listStyle="max-height:220px" placeholder="Select a user..." disabled>
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value" class="row">
                                            <div class="col my-auto ms-2">
                                                {{slotProps.value.username}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{slotProps.placeholder}}
                                        </div>
                                    </template>
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col-1">
                                                <div class="circle-image">
                                                    <img :src="slotProps.option.picture" class="profile-pic" @click="toggle"/>
                                                </div> 
                                            </div>
                                            <div class="col my-auto mx-auto">
                                                {{slotProps.option.username}}
                                            </div>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>  
                    </div>
                </div>
                <div v-if="!info_internal" class="col-12 xl:col-6 border_left_divider text-center">
                    <div class="container">
                        <div class="row">
                            <div class="col text-start" style="padding-bottom: 0px;">
                                <h5> Schedule </h5>
                            </div>
                        </div>
                        <div class="row justify-content-center" >
                            <div class="col-3">
                                <Tag> Started at </Tag>
                            </div>
                            <div class="col-7 text-center">
                                <h5> {{format_datetime(info_start_datetime)}}</h5> 
                            </div>
                            <div class="col-2 text-center">
                                <h5> {{format_time(info_start_datetime)}}</h5>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-3">
                                <Tag> Finished at </Tag>
                            </div>
                            <div class="col-7 text-center">
                                <h5> {{format_datetime(info_end_datetime)}}</h5>
                            </div>
                            <div class="col-2 text-center">
                                <h5> {{format_time(info_end_datetime)}}</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-start" style="padding-bottom: 0px;">
                                <h5> Documents </h5>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 md:col-6">
                                <a :href="get_all_budgets[0].budget" target="_blank" style="text-decoration: none;"> <Button label="Budget" icon="fa fa-coins" class="p-button-raised p-button-text"/> </a>
                            </div>
                            <div class="col-12 md:col-6">
                                <a :href="info_work_report" target="_blank" style="text-decoration: none;"> <Button label="Work Report" icon="fa fa-file" class="p-button-raised p-button-text"/> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dialog>

    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 450px" :breakpoints="{'960px': '75vw'}">
        <div class="container p-fluid mt-4">
            <div class="row">
                <div class="col">
                    <span class="p-float-label">
                        <Calendar id="end_date" v-model="end_date" dateFormat="yy-mm-dd"/>
                        <label for="end_date"> Date </label>
                    </span>
                </div>
                <div class="col">
                    <span class="p-float-label">
                        <Calendar id="end_hour" v-model="end_hour" :timeOnly="true" hourFormat="24" @change="end_hour = num2time($event)"/>
                        <label for="end_hour"> Hour </label>
                    </span>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-3">
                    <Button label="Assign" @click="assign_end_date"/>
                </div>
            </div>
        </div>
    </OverlayPanel>

    <OverlayPanel ref="worker_list" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 50vw" :breakpoints="{'960px': '75vw'}">
        <DataTable :value="get_worker_list" selectionMode="single" :paginator="true" :rows="5"  responsiveLayout="scroll" >
            <Column field="dni" header="DNI" sortable ></Column>
            <Column field="name" header="Name" sortable ></Column>
            <Column field="email" header="Emial" sortable ></Column>
            <Column field="phone" header="Phone" sortable ></Column>
            <Column field="date_of_birth" header="Birth date" sortable ></Column>
            <Column field="nationality" header="Nationality" sortable ></Column>
        </DataTable>
    </OverlayPanel>

    <Dialog header="Confirmation"  v-model:visible="display_modal_confirmation" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div class="container">
            <div class="row">
                <div class="col">
                    Are you sure you want to send another email? It will overwrite the previous one.
                </div>
            </div>
        </div>
        <template #footer>
            <div class="row">
                <div class="col">
                    <Button label="Send" @click="request_worker_list"/>
                </div>
            </div>
        </template>
    </Dialog>

    <Dialog header="Confirmation"  v-model:visible="display_modal_confirmation_mail_work_report" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div class="container">
            <div class="row">
                <div class="col">
                    Are you sure you want to send an email? If you have already sent one, it will overwrite it.
                </div>
            </div>
        </div>
        <template #footer>
            <div class="row">
                <div class="col">
                    <Button label="Send" @click="demand_work_report"/>
                </div>
            </div>
        </template>
    </Dialog>

</template>

<script>
import PendingTask from '../../components/Maintenance/Pending_task.vue'
import SchedulingTask from '../../components/Maintenance/Scheduling_task.vue'
import ScheduledTask from '../../components/Maintenance/Scheduled_task.vue'
import FinishedTask from '../../components/Maintenance/Finished_task.vue'
import DeletedTask from '../../components/Maintenance/Deleted_task.vue'

import Draggable from 'vuedraggable'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import ScrollPanel from 'primevue/scrollpanel'
import Chip from 'primevue/chip'
import InputText from 'primevue/inputtext'
import TextArea from 'primevue/textarea'
import SelectButton from 'primevue/selectbutton'
import InputSwitch from 'primevue/inputswitch'
import Tag from 'primevue/tag'
import OverlayPanel from 'primevue/overlaypanel'
import Steps from 'primevue/steps'
import FileUpload from 'primevue/fileupload'
import Image from 'primevue/image'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import BlockUI from 'primevue/blockui'

import { required, requiredIf } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"

import { num2time } from '@/modules/utilities.js'

import moment from "moment"
import ability from '../../services/ability'

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'Task Board',
    components: {
        PendingTask,
        SchedulingTask,
        ScheduledTask,
        FinishedTask,
        DeletedTask,
        Draggable,
        Dialog,
        Button,
        Calendar,
        Dropdown,
        ScrollPanel,
        Chip,
        TextArea,
        InputText,
        SelectButton,
        InputSwitch,
        Tag,
        OverlayPanel,
        Steps,
        FileUpload,
        Image,
        DataTable,
        Column,
        BlockUI,
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            items_title: [
                {
                    label: 'Pending'
                },
                {
                    label: 'Scheduling'
                },
                {
                    label: 'Scheduled'
                },
                {
                    label: 'Finished'
                }
            ],
            new_task_category: '',
            width_modal: '',
            display_validation_task_dialog: false,
            display_scheduling_task_info: false,
            display_confirmation_delete: false,
            current_task: [],
            options: ['Internal', 'External'],
            delete_list: [],
            dragging: false,
            remove_event: false,
            removed_task:[],
            new_task_modal: false,
            task_title: '',
            task_urgency: false,
            task_type: 'Internal',
            task_category: '',
            task_terminal: '',
            task_start_date: '',
            task_start_hour: '',
            task_end_date: '',
            task_end_hour: '',
            task_supplier: '',
            task_description: '',
            task_user: '',
            task_picture: '',
            submitted: false,
            display_pending_task_info: false,
            display_scheduled_task_info: false,
            display_finished_task_info: false,
            display_confirmation_archived: false,
            info_task: [],
            info_id: '',
            info_internal: false,
            info_title: '',
            info_category: '',
            info_terminal: '',
            info_supplier: '',
            info_description: '',
            info_user_assigned: '',
            info_picture: '',
            info_budget: '',
            info_budget_status: '',
            info_budget_mail_sent: false,
            info_mail_description: '',
            info_proposal_supplier: null,
            info_work_report: null,
            info_work_report_mail_sent: false,
            info_worker_file_mail_sent: false,
            proposal_start_date: '',
            proposal_start_hour: '',
            new_proposal_start_date: '',
            new_proposal_start_hour: '',
            proposal_end_date: '',
            proposal_end_hour: '',
            info_start_datetime: '',
            prev_info_mail_description: '',
            new_proposal: false,
            error_date_proposal: '',
            after_today: false,
            new_end_hour: '',
            new_end_date: '',
            info_end_date: '',
            info_end_hour: '',
            isHovering: false,
            display_modal_confirmation: false,
            display_modal_confirmation_mail_work_report: false,
            prev_end_date: '',
            prev_end_hour: '',
            blocked_new_task: false,
        }
    },
    computed: {
        get_pending_tasks() {
            return this.$store.getters.get_pending_tasks;
        },
        get_scheduling_tasks() {
            return this.$store.getters.get_scheduling_tasks;
        },
        get_scheduled_tasks() {
            return this.$store.getters.get_scheduled_tasks;
        },
        get_finished_tasks() {
            return this.$store.getters.get_finished_tasks;
        },
        get_board() {
            return this.$store.getters.get_board
        },
        get_task_categories: function() {
            return this.$store.getters.get_task_categories
        },
        get_all_terminals: function() {
            return this.$store.getters.all_terminals
        },
        get_all_suppliers: function() {
            return this.$store.getters.all_suppliers
        },
        get_end_datetime: function () {
            return this.$store.getters.get_end_datetime
        },
        get_budgets_tasks: function () {
            return this.$store.getters.get_budgets_tasks ?? null
        },
        get_all_budgets: function() {
            return this.$store.getters.get_all_budgets
        },
        start_date: function() {
            return this.format_datetime(this.info_start_datetime)
        },
        start_hour: function() {
            return this.format_time(this.info_start_datetime)
        },
        users_by_port: function() {
            return this.$store.getters.users_by_port
        },
        get_worker_list: function() {
            return this.$store.getters.get_worker_list
        },
        get_error_update_schedule_task_end_date: function() {
            return this.$store.getters.get_error_update_schedule_task_end_date
        },
        get_current_user: function(){
            return this.$store.getters.get_current_user
        },
        check_user_task_responsible: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Tasks responsibles') {
                    return true
                }
            }
            return false
        }
    },
    async mounted() {
        await this.$store.dispatch('load_all_tasks')
        await this.$store.dispatch('load_task_categories')
        await this.$store.dispatch('load_all_terminals')
        await this.$store.dispatch('load_all_suppliers')
        await this.$store.dispatch('load_users_by_port')
        if(this.$route.params.task_type) {
            this.new_task_modal = this.$route.params.new_task_modal
            this.task_type = this.$route.params.task_type
            this.task_description = this.$route.params.text
            this.task_terminal = JSON.parse(this.$route.params.terminal)
            this.task_terminal = this.task_terminal.id
        }
    },
    methods: {
        num2time(data) {
            return num2time(data)
        },
        async display_info_task(data) {
            if(data.id_user_assigned){
                if(data.id_user_assigned.id == this.get_current_user.id) {
                    var user_check = data.id_user_assigned.id
                }
            } 

            if(this.check_user_task_responsible || user_check ) {
                this.info_id = data.id
                this.info_internal = data.internal
                this.info_title = data.title
                this.info_category = data.id_task_category
                this.info_terminal = data.id_terminal
                if(data.id_supplier == null) {
                    this.info_supplier = { id: null, name: null}
                } else {
                    this.info_supplier = data.id_supplier
                }
                this.info_description = data.description
                this.info_user_assigned = data.id_user_assigned
                this.info_budget = data.budget
                this.info_picture = data.picture
                this.info_proposal_supplier = data.proposal_supplier
                this.info_mail_description = data.message_sent
                this.prev_info_mail_description = data.message_sent
                this.proposal_supplier = this.new_proposal
                this.info_work_report = data.work_report
                this.info_work_report_mail_sent = data.work_report_mail_send
                this.info_start_datetime = data.start_datetime
                this.info_end_datetime = data.end_datetime
                this.info_worker_file_mail_sent = data.worker_file_mail_sent
                if(data.end_datetime != null) {
                    this.info_end_date = moment(data.end_datetime).format().split('T')[0]
                    this.info_end_hour = moment(data.end_datetime).format().split('T')[1].split('+')[0].split(':')[0] + ':' + moment(data.end_datetime).format().split('T')[1].split('+')[0].split(':')[1]
                    this.prev_end_date = this.info_end_date
                    this.prev_end_hour = this.info_end_hour
                } else {
                    this.info_end_date = ''
                    this.info_end_hour= ''
                }
                this.info_budget_mail_sent = data.budget_mail_sent
                if(data.proposed_start_datetime != null){
                    this.proposal_start_date = moment(data.proposed_start_datetime).format().split('T')[0]
                    this.proposal_start_hour = moment(data.proposed_start_datetime).format().split('T')[1].split('+')[0].split(':')[0] + ':' + moment(data.proposed_start_datetime).format().split('T')[1].split('+')[0].split(':')[1]
                }
                if(data.proposed_end_datetime != null){
                    this.proposal_end_date = moment(data.proposed_end_datetime).format().split('T')[0]
                    this.proposal_end_hour = moment(data.proposed_end_datetime).format().split('T')[1].split('+')[0].split(':')[0] + ':' + moment(data.proposed_end_datetime).format().split('T')[1].split('+')[0].split(':')[1]
                }
                if(ability.can('view','budget')) {
                    await this.$store.dispatch('load_budgets_from_tasks', data.id)
                    if(this.get_all_budgets.length > 0) {
                        this.info_budget_status = this.get_all_budgets[0].status
                    } else {
                        this.info_budget_status = 'Not sent'
                    }
                }
                
                
                if(moment(this.info_start_datetime).isAfter(moment())) {
                    this.after_today = false
                } else {
                    this.after_today = true
                }

                if(ability.can('view','worker_file')) {
                    await this.$store.dispatch('load_workers_list', data.id)
                }
                switch(data.status) {
                    case 'Pending':
                        if(this.info_internal == true) {
                            this.width_modal = '50vw' 
                        } else {
                            this.width_modal = '70vw'
                        }
                        this.display_pending_task_info = true
                        break;
                    case 'Scheduling':
                        if(this.info_internal == true) {
                            this.width_modal = '50vw' 
                        } else {
                            this.width_modal = '70vw'
                        }
                        this.display_scheduling_task_info = true
                        break; 
                    case 'Scheduled':
                        if(this.info_internal == true) {
                            this.width_modal = '50vw' 
                        } else {
                            this.width_modal = '70vw'
                        }
                        this.display_scheduled_task_info = true
                        break;
                    case 'Finished':
                        if(this.info_internal == true) {
                            this.width_modal = '50vw' 
                        } else {
                            this.width_modal = '70vw'
                        }
                        this.display_finished_task_info = true
                        break;
                }
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000});   
            }
        },
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        toggle_worker_list(event) {
            this.$refs.worker_list.toggle(event)
        },
        open_modal_new_task() {
            this.new_task_modal = true
        },
        hide_new_task_form(){
            this.new_task_modal = false
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }

            this.blocked_new_task = true

            let res = await this.add_task();
            if (res) {
                this.$toast.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Task Added",
                    life: 3000,
                });
            }
            this.new_task_modal = false
            
        },
        async add_task() {
            
            let task = {}

            var internal = ''

            if(this.task_type == 'Internal'){
                internal = true
            } else {
                internal = false
            }

            if(this.task_user != '') {
                task = {
                    title: this.task_title,
                    priority: this.task_urgency,
                    id_task_category: this.task_category,
                    id_terminal: this.task_terminal,
                    internal: internal,
                    description: this.task_description,
                    id_supplier: this.task_supplier,
                    id_user_assigned: this.task_user.id,
                    picture: this.task_picture,
                    status: 'Pending'
                }
            } else {
                task = {
                    title: this.task_title,
                    priority: this.task_urgency,
                    id_task_category: this.task_category,
                    id_terminal: this.task_terminal,
                    internal: internal,
                    description: this.task_description,
                    id_supplier: this.task_supplier,
                    picture: this.task_picture,
                    status: 'Pending'
                }
            }

            await this.$store.dispatch('create_task', task)
            await this.hide_block()
            
            this.$toast.add({severity:'success', summary: 'Success', detail:'Task created successfully.', life: 3000});
            this.reset_form()
            this.submitted = false
        },
        async reset_form() {
            this.task_title = ''
            this.task_terminal = ''
            this.task_category = ''
            this.task_description = ''
            this.task_supplier = ''
            this.task_user = ''  
        },
        async save_task() {
            
            let task = {}

            if(this.info_user_assigned == null) {
                task = {
                    id: this.info_id,
                    title: this.info_title,
                    internal: this.info_internal,
                    id_task_category: this.info_category,
                    id_terminal: this.info_terminal,
                    id_supplier: this.info_supplier,
                    description: this.info_description,
                    budget_status: this.info_budget_status,
                    picture: this.task_picture
                }
            } else {
                task = {
                    id: this.info_id,
                    title: this.info_title,
                    internal: this.info_internal,
                    id_task_category: this.info_category,
                    id_terminal: this.info_terminal,
                    id_supplier: this.info_supplier,
                    description: this.info_description,
                    budget_status: this.info_budget_status,
                    id_user_assigned: this.info_user_assigned.id,
                    picture: this.task_picture
                }
            }

            await this.$store.dispatch('save_task', task)
            this.display_pending_task_info = false
            this.display_scheduling_task_info = false
            this.display_scheduled_task_info = false
            this.display_finished_task_info = false
            this.$toast.add({severity:'success', summary: 'Success', detail:'Data modified', life: 3000});
        },
        async send_budget_mail() {
            let item = {
                id: this.info_id,
                message: this.info_mail_description
            }
            this.$store.dispatch('send_budget_mail', item)
            this.info_budget_mail_sent = true
            this.prev_info_mail_description = this.info_mail_description
            await this.$store.dispatch('load_budgets_from_task', this.info_id)
            await this.$store.dispatch('load_budgets_from_tasks', this.info_id)
            this.$toast.add({severity:'success', summary: 'Success', detail:'Mail sent', life: 3000});
            this.display_pending_task_info = false
        },
        async confirm_budget() {
            this.blocked_new_task = true
            let item = {
                id: this.info_id,
                status: 'Confirmed'
            }
            await this.$store.dispatch('confirm_budget', item)
            this.info_budget_status = 'Confirmed'
            this.info_budget_mail_sent = false
            this.$toast.add({severity:'success', summary: 'Success', detail:'Budget confirmed', life: 3000});
            
            await this.hide_block()
            this.display_pending_task_info = false
        },
        async reject_budget() {
            this.blocked_new_task = true
            let item = {
                id: this.info_id,
                status: 'Rejected'
            }
            let budget = {
                id: this.get_budgets_tasks[0].id,
                status: 'Rejected'
            }
            await this.$store.dispatch('confirm_budget', item)
            await this.$store.dispatch('change_status_budget', budget)
            this.info_budget_status = 'Rejected'
            this.info_budget_mail_sent = false
            this.$toast.add({severity:'success', summary: 'Success', detail:'Budget rejected', life: 3000});
            await this.hide_block()
        },
        async send_date_proposal_mail() {
            
            if(this.new_proposal_start_date == '' || this.new_proposal_start_date == null || this.new_proposal_start_hour == '' || this.new_proposal_start_hour == null) {
                this.error_date_proposal = 'Cannot send proposal date with empty fields.'
            } else {

                this.error_date_proposal = ''

                let item = {}

                if(typeof(this.new_proposal_start_hour) === 'string') {
                    item = {
                        id: this.info_id,
                        start_datetime: moment(this.new_proposal_start_date).format().split('T')[0] + 'T' + this.new_proposal_start_hour
                    }
                } else {
                    item = {
                        id: this.info_id,
                        start_datetime: moment(this.new_proposal_start_date).format().split('T')[0] + 'T' + moment(this.new_proposal_start_hour).format().split('T')[1].split(':')[0] + ':' + moment(this.new_proposal_start_hour).format().split('T')[1].split(':')[1]
                    }
                }

                

                if(moment(item.start_datetime).isAfter(moment().format())) {
                    this.proposal_end_date = ''
                    this.proposal_end_hour = ''
                    await this.$store.dispatch('send_date_proposal_mail', item)
                    this.$toast.add({severity:'success', summary: 'Success', detail:'Mail sent', life: 3000});
                    this.info_proposal_supplier = false
                    this.display_scheduling_task_info = false
                    this.new_proposal = false
                    this.proposal_start_date = moment(this.new_proposal_start_date).format().split('T')[0]
                    if(typeof(this.new_proposal_start_hour) === 'string') {
                        this.proposal_start_hour = this.new_proposal_start_hour
                    } else {
                        this.proposal_start_hour = moment(this.new_proposal_start_hour).format().split('T')[1].split(':')[0] + ':' + moment(this.new_proposal_start_hour).format().split('T')[1].split(':')[1]
                    }  
                    this.proposal_end_date = '-'
                    this.proposal_end_hour = '-'
                } else {
                    this.error_date_proposal = 'Date cannot be less than the current date'
                }
            }
            
            
        },
        async send_new_proposal() {
            this.new_proposal = true
            
        },
        async cancel_new_proposal() {
            this.new_proposal = false
        },
        async accept_proposal() {
            this.blocked_new_task = true
            let item = {
                id: this.info_id,
                start_datetime: moment(this.proposal_start_date).format().split('T')[0] + 'T' + this.proposal_start_hour,
                end_datetime: moment(this.proposal_end_date).format().split('T')[0] + 'T' + this.proposal_end_hour
            }
            await this.$store.dispatch('accept_supplier_date_proposal', item)
            this.$toast.add({severity:'success', summary: 'Success', detail:'Proposal accepted', life: 3000});
            await this.hide_block()
            this.display_scheduling_task_info = false
            
        },
        async hide_block(){
            this.blocked_new_task = false
        },
        async save_end_date_proposal() {

            if(!this.info_end_date || !this.info_end_hour) {
                
                this.$toast.add({severity:'error', summary: 'Error', detail:'Missing date/hour field.', life: 3000});

            } else {

                if(typeof(this.info_end_hour) == 'string') {
                    var end_datetime = moment(this.info_end_date).format('yy-MM-D') + 'T' + this.info_end_hour 
                } else {
                    end_datetime = moment(this.info_end_date).format('yy-MM-D') + 'T' + moment(this.info_end_hour).format('H:mm')
                }
                
                let item = {
                    id: this.info_id,
                    end_datetime: end_datetime
                }

                

                await this.$store.dispatch('update_scheduled_task_end_date', item)
                // this.info_end_datetime = end_datetime
                // this.$toast.add({severity:'success', summary: 'Success', detail:'Date assigned correctly.', life: 3000});
            }
            
            if(this.get_error_update_schedule_task_end_date.length == 0) {
                this.$toast.add({severity:'success', summary: 'Success', detail:'Date assigned correctly.', life: 3000})
                
            }
            
            
        },
        async onUpload(e) {
            this.info_work_report = e.files[0]
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
            let item = {
                id: this.info_id,
                work_report: this.info_work_report
            }
            await this.$store.dispatch('upload_work_report_image', item)
            this.info_work_report = this.get_work_report_from_task(this.info_id)
            this.$toast.add({severity:'success', summary: 'Success', detail:'Work report uploaded.', life: 3000});
        },
        get_work_report_from_task(id) {
            return this.$store.getters.get_work_report_from_task(id) ?? {}
        },
        confirmation_resend_mail() {
            this.display_modal_confirmation = true
        },
        async onUpload_picture_new_task(e){
            this.task_picture = e.files[0]
            this.$refs.fileUpload_picture.clear();
            this.$refs.fileUpload_picture.uploadedFileCount = 0;
            this.$toast.add({severity:'success', summary: 'Success', detail:'Picture selected.', life: 3000});
        },
        async onUpload_picture(e) {
            this.blocked_new_task = true
            this.task_picture = e.files[0]
            this.$refs.fileUpload_picture.clear();
            this.$refs.fileUpload_picture.uploadedFileCount = 0;
            let item = {
                id: this.info_id,
                picture: this.task_picture
            }
            await this.$store.dispatch('upload_task_image', item)
            this.info_picture = this.get_picture_from_task(this.info_id)
            this.$toast.add({severity:'success', summary: 'Success', detail:'Picture selected.', life: 3000});
            await this.hide_block()
        },
        get_picture_from_task(id) {
            return this.$store.getters.get_picture_from_task(id) ?? {}
        },
        async confirmation_send_mail_work_report() {
            this.display_modal_confirmation_mail_work_report = true
        },
        async demand_work_report() {
            this.display_modal_confirmation_mail_work_report = false
            await this.$store.dispatch('send_work_report_mail', this.info_id)
            
            this.$toast.add({severity:'success', summary: 'Success', detail:'Mail sent successfully', life: 3000});
        },
        async assign_end_date(event) {
            
            let item = {
                id: this.info_id,
                end_date: this.end_date,
                end_hour: this.end_hour
            }
            await this.$store.dispatch('assign_end_date', item)
            this.$toast.add({severity:'success', summary: 'Success', detail:'End date set successfully', life: 3000});
            this.$refs.op.toggle(event);
        },
        async onEnd() {
            this.dragging = false
        },
        async onStart() {
            this.dragging = true
        },
        async on_change_scheduling(event) {
            if(ability.can('change','task')) {
                if(event.added && event.added.element.budget_status != 'Confirmed' && event.added.element.internal == false) {
                    this.$toast.add({severity:'error', summary: 'Error', detail:'Missing/Rejected Budget', life: 3000});
                } else if (event.added && event.added.element.status == 'Pending') {
                    if(event.added.element.id_user_assigned) {
                        if(this.get_current_user.id == event.added.element.id_user_assigned.id) {
                            this.current_task = event.added.element
                            return this.display_validation_task_dialog = true
                        } else if (this.check_user_task_responsible) {
                            this.current_task = event.added.element
                            return this.display_validation_task_dialog = true 
                        } else {
                            this.$toast.removeAllGroups();
                            this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000}); 
                        }
                    } else if (this.check_user_task_responsible) {
                        this.current_task = event.added.element
                        return this.display_validation_task_dialog = true
                    } else {
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000}); 
                    }
                } 

            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000}); 
            }
            
        },
        async on_change_scheduled(event) {
            if(event.added) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'info', summary: 'Info', detail:'The task is missing the date.', life: 3000}); 
            } 
        },
        async on_change_finished(event) {
            if(event.added && event.added.element.internal == false) {
                if(event.added.element.work_report != '') {
                    if (event.added && moment(event.added.element.end_datetime).isAfter(moment())) {
                        this.$toast.add({severity:'error', summary: 'Error', detail:'The task is still on course.', life: 3000});
                    } else {    
                        if(event.added.element.id_user_assigned) {
                            if(this.get_current_user.id == event.added.element.id_user_assigned.id) {
                                this.current_task = event.added.element
                                return this.display_validation_task_dialog = true
                            } else if (this.check_user_task_responsible) {
                                this.current_task = event.added.element
                                return this.display_validation_task_dialog = true 
                            } else {
                                this.$toast.removeAllGroups();
                                this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000}); 
                            }           
                        } else if (this.check_user_task_responsible) {
                            this.current_task = event.added.element
                            return this.display_validation_task_dialog = true 
                        } else {
                            this.$toast.removeAllGroups();
                            this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000});
                        }
                    }
                } else {
                    this.$toast.add({severity:'error', summary: 'Error', detail:'Missing work report', life: 3000});
                }
            } else {
                if (event.added && moment(event.added.element.end_datetime).isAfter(moment())) {
                        this.$toast.add({severity:'error', summary: 'Error', detail:'The task is still on course.', life: 3000});
                } else {
                    if(event.added.element.id_user_assigned) {
                        if(this.get_current_user.id == event.added.element.id_user_assigned.id) {
                            this.current_task = event.added.element
                            return this.display_validation_task_dialog = true
                        } else if (this.check_user_task_responsible) {
                            this.current_task = event.added.element
                            return this.display_validation_task_dialog = true 
                        } else {
                            this.$toast.removeAllGroups();
                            this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000}); 
                        }           
                    } else if (this.check_user_task_responsible) {
                        this.current_task = event.added.element
                        return this.display_validation_task_dialog = true 
                    } else {
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000});
                    }
                }
            }
        },
        async on_change_deleted(event) {
            this.removed_task.id = event.added.element.id
            this.removed_task.title = event.added.element.title
            this.display_confirmation_delete = true  
            this.delete_list = []
        },
        async on_change_archived(event) {
            if(this.check_user_task_responsible == true ) {
                this.removed_task.id = event.added.element.id
                this.removed_task.title = event.added.element.title
                this.display_confirmation_archived = true  
                this.delete_list = []
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'warn', summary: 'Forbidden', detail:'You do not have permissions to execute this action.', life: 3000});
            }
        },
        async delete_task(){
            this.$store.dispatch('delete_task', this.removed_task.id)
            
            this.delete_list = []
            this.display_confirmation_delete = false
        },
        async archive_task(){
            this.$store.dispatch('archive_task', this.removed_task.id)

            this.delete_list = []
            this.display_confirmation_archived = false
        },
        async cancel_delete_task() {
            this.display_confirmation_delete = false
        },
        async form_pending() {
            await this.$store.dispatch('update_pending_task', this.current_task)
            this.display_validation_task_dialog = false
        },
        async form_scheduled() {
            await this.$store.dispatch('update_scheduled_task', this.current_task)
            this.display_validation_task_dialog = false
        },
        async cancel_pending_form() {
            this.current_task.id_user_assigned = ''
            this.display_validation_task_dialog = false
        },
        async cancel_scheduled_form() {
            this.display_validation_task_dialog = false
        },
        check_move_pending(e) {
            if(e.to.id == 'deleted_list'){
                this.remove_event = true
            } else {
                this.remove_event = false
            }
            /*if(e.to.id == 'scheduled_list' || e.to.id == 'finished_list') {
                return false
            }*/
        },
        check_move_scheduling(e) {
            if(e.to.id == 'deleted_list'){
                this.remove_event = true
            } else {
                this.remove_event = false
            }
            if(e.to.id == 'pending_list' || e.to.id == 'finished_list') {
                return false
            }
        },
        check_move_scheduled(e) {
            if(e.to.id == 'deleted_list'){
                this.remove_event = true
            } else {
                this.remove_event = false
            }
            if(e.to.id == 'pending_list' || e.to.id == 'scheduling_list') {
                return false
            }
        },
        check_move_finished(e) {
            if(e.to.id == 'deleted_list'){
                this.remove_event = true
            } else {
                this.remove_event = false
            }
            return false
        },
        request_worker_list(){
            this.$store.dispatch('request_worker_list', this.info_id)
            if(this.display_modal_confirmation == true) {
                this.display_modal_confirmation = false
            }
            this.$toast.add({severity:'success', summary: 'Success', detail:'Email sent', life: 3000});
        },
        add_new_task_category() {
            let task_category = { 
                name: this.new_task_category
            }
            this.$store.dispatch('create_task_category', task_category)
        },
        format_day(value) {
            return moment(value).format('ddd D')
        },
        format_date(value) {
            return value.split("T")[1].split(':')[0] + ':' + value.split("T")[1].split(':')[1];
        },
        format_time(value) {
            return moment(value).format('HH:mm')
        },
        format_datetime(value) {
            return moment(value).format('yy-MM-DD')
        },
        reset_supplier() {
            this.task_supplier = ''
        }
    },
    validations() {
        return {
            task_title: { required },
            task_category: { required },
            task_terminal: { required },
            task_description: { required },
            task_supplier: { 
                required: requiredIf(function() {
                    return this.task_type == 'External'
                })  
            }
        };
    },

}
</script>

<style scoped>

:deep(.p-scrollpanel-wrapper) {
    border-right: 9px solid #f8f9fa;
}

:deep(.p-scrollpanel-bar) {
    background-color: lightgray !important;
    opacity: 1;
    transition: background-color .2s;
}

.delete_active{
    color: red !important;
}

.archive_active{
    color: orange !important;
}

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
    margin: 1rem;
}

.budget_success {
    background-color: #22C55E;
}

.budget_rejected {
    background-color: #EF4444;
}

:deep(.p-steps-item) {
    opacity: 1 !important;
}

.email_format{
    border: 1px solid lightgray;
    border-radius: 3%;
    box-shadow: 1px 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.circle-image{
  position: relative;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 35px;
  height: 35px;
  padding: 0;
}

.circle-image img{
  width:100%;
  height:100%;
  object-fit: cover;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.text-button:hover .text-hidden{
    display: none;
}

:deep(.p-card-content) {
    padding-top: 0px;
}

@media (min-width: 2100px) {
    .custom_container{
        width: 2500px;
        margin: 0 auto;
    }
}

.border_left_divider{
    border-top: 1px solid lightgray;
    margin-top: 1em;
    border-left: 0px;
}

.margin_mobile{
    margin-top: 1em;
}

@media (min-width: 1025px) {
    .border_left_divider{
        border-left: 1px solid lightgray; 
        border-top: 0px;
        margin-top: 0px;
    }

    .margin_mobile{
        margin-top: 0em;
    }
}


:deep(.p-scrollpanel-bar-x){
    height: 0px;
}

:deep(.p-scrollpanel-wrapper){
    border-right: 0px;
}

@media (min-width: 1800px) {
    :deep(.p-scrollpanel-content){
        overflow-x: hidden;
    }
}
.hover_button{
    color: gray !important;
    background: white !important;
}

.hover_button:hover{
    background-color: lightgray !important;
}


</style>