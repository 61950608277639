<template>
    <Toast/>
    <div class="pt-4 pb-2">
        <div v-if="check_user_super_admin" class="flex justify-content-between align-items-end">
            <h1 class="my-auto"> Ports </h1>
            <div class="card p-1">
                <Button data-cy="new_port_button" class="p-button-text p-button-success" @click="openModal" v-tooltip.bottom="'New Port'">
                    <i class="pi pi-plus" style="font-size: 1.5rem" ></i>
                </Button>
            </div>
        </div>

        <Dialog data-cy="new_port_modal" header="New port" v-model:visible="displayModal" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw'}">
            <div class="container">
                <form id="from_new_port" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid" enctype=“multipart/form-data”>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted}" />
                                <label for="name" :class="{'p-error':v$.name.$invalid && submitted || port_errors.name}"> Name </label>
                                <small v-if="Object.keys(port_errors).length !== 0 && port_errors.name" class="p-error"><span v-for="error in port_errors.name" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <InputText id="iban" v-model="iban" :class="{'p-invalid':submitted && port_errors.iban}" />
                                <label for="iban" :class="{'p-error':submitted && port_errors.iban}"> IBAN </label>
                                <small v-if="Object.keys(port_errors).length !== 0 && port_errors.iban" class="p-error"><span v-for="error in port_errors.iban" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">
                                <InputText id="bic_entity" v-model="bic_entity" :class="{'p-invalid':submitted && port_errors.bic_entity}" />
                                <label for="bic_entity" :class="{'p-error':submitted && port_errors.bic_entity}"> BIC </label>
                                <small v-if="Object.keys(port_errors).length !== 0 && port_errors.bic_entity" class="p-error"><span v-for="error in port_errors.bic_entity" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-10 md:col-12 pt-3">
                            <div class="p-float-label">     
                                <InputText id="sender_email" v-model="v$.sender_email.$model" :class="{'p-invalid':v$.sender_email.$invalid && submitted}" />
                                <label for="sender_email" :class="{'p-error':v$.sender_email.$invalid && submitted || port_errors.sender_email}"> Email sender </label>
                                <small v-if="Object.keys(port_errors).length !== 0 && port_errors.sender_email" class="p-error"><span v-for="error in port_errors.sender_email" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.sender_email.$invalid && submitted) || v$.sender_email.$pending.$response" class="p-error">{{v$.sender_email.required.$message.replace('Value', 'Email sender')}}</small>
                        </div>
                    </div>
 
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">
                                <InputText id="primary_color" v-model="v$.primary_color.$model" :class="{'p-invalid':v$.primary_color.$invalid && submitted}" />
                                <label for="primary_color" :class="{'p-error':v$.primary_color.$invalid && submitted || port_errors.primary_color}"> Primary color </label>
                                <small v-if="Object.keys(port_errors).length !== 0 && port_errors.primary_color" class="p-error"><span v-for="error in port_errors.primary_color" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.primary_color.$invalid && submitted) || v$.primary_color.$pending.$response" class="p-error">{{v$.primary_color.required.$message.replace('Value', 'Primary color')}}</small>
                        </div>
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">     
                                <InputText id="secondary_color" v-model="v$.secondary_color.$model" :class="{'p-invalid':v$.secondary_color.$invalid && submitted}" />
                                <label for="secondary_color" :class="{'p-error':v$.secondary_color.$invalid && submitted || port_errors.secondary_color } "> Secondary color </label>
                                <small v-if="Object.keys(port_errors).length !== 0 && port_errors.secondary_color" class="p-error"><span v-for="error in port_errors.secondary_color" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.secondary_color.$invalid && submitted) || v$.secondary_color.$pending.$response" class="p-error">{{v$.secondary_color.required.$message.replace('Value', 'Secondary color')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">     
                                <InputText id="typography" v-model="v$.typography.$model" :class="{'p-invalid':v$.typography.$invalid && submitted}" />
                                <label for="typography" :class="{'p-error':v$.typography.$invalid && submitted || port_errors.typography}"> Typography </label>
                                <small v-if="Object.keys(port_errors).length !== 0 && port_errors.typography" class="p-error"><span v-for="error in port_errors.typography" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.typography.$invalid && submitted) || v$.typography.$pending.$response" class="p-error">{{v$.typography.required.$message.replace('Value', 'Typography')}}</small>
                        </div>
                        <div class="col-10 md:col-6 pt-3">
                            <div class="p-float-label">     
                                <InputText id="typography_media" v-model="v$.typography_media.$model" :class="{'p-invalid':v$.typography_media.$invalid && submitted}" />
                                <label for="typography_media" :class="{'p-error':v$.typography_media.$invalid && submitted || port_errors.typography_media}"> Typography media </label>
                                <small v-if="Object.keys(port_errors).length !== 0 && port_errors.typography_media" class="p-error"><span v-for="error in port_errors.typography_media" :key="error">{{error}}<br></span></small>
                            </div>
                            <small v-if="(v$.typography_media.$invalid && submitted) || v$.typography_media.$pending.$response" class="p-error">{{v$.typography_media.required.$message.replace('Value', 'Typography media')}}</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10 text-center">
                            <div class="field pt-3">
                                <div class="p-float-label">
                                    <FileUpload ref="fileUpload" mode="basic" name="demo[]" :customUpload="true" accept="image/*" :auto="true" :maxFileSize="10000000" @uploader="onUpload" />
                                </div>
                                <small v-if="Object.keys(port_errors).length !== 0 && port_errors.logo" class="p-error"><span v-for="error in port_errors.logo" :key="error">{{error}}<br></span></small>
                            </div>
                        </div>
                    </div> 
                </form>
            </div>
            <template #footer>
                <Button data-cy="create_port_button" form="from_new_port" type="submit" label="Submit" class="p-button-raised"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import { required } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";
import FileUpload from 'primevue/fileupload';

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog,
        Button,
        InputText,
        Toast,
        FileUpload
    },
    data() {
        return {
            displayModal: false,
            name: '',
            bic_entity: '',
            iban: '',
            sender_email: '',
            logo: '',
            primary_color: '',
            secondary_color: '',
            typography: '',
            typography_media: '',
            port_errors: {},
            submitted: false,
            showMessage: false,
        }
    },
    computed: {
        get_port_errors: function() {
            return this.$store.getters.get_port_errors
        },
        get_current_user: function () {
            return this.$store.getters.get_current_user
        },
        check_user_super_admin: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Super Admins') {
                    return true
                }
            }
            return false
        },
    },
    methods: {
        onUpload(e){
            this.logo = e.files[0];
            this.$refs.fileUpload.clear();
            this.$refs.fileUpload.uploadedFileCount = 0;
        },
        openModal() {
            this.displayModal = true;
            this.resetForm()
        },
        closeModal() {
            this.displayModal = false;
        },
        async add_port() {
            let port = {
                name: this.name,
                sender_email:this.sender_email,
                bic_entity:this.bic_entity,
                iban:this.iban,
                logo: this.logo,
                primary_color:this.primary_color,
                secondary_color: this.secondary_color,
                typography: this.typography,
                typography_media:this.typography_media
            }
            await this.$store.dispatch('create_port', port)
            this.port_errors = this.get_port_errors
            if(Object.keys(this.port_errors).length === 0){
                this.toggleDialog();
                this.resetForm();
                return true
            }
            return false
        },
        async handleSubmit(isFormValid) {
            this.submitted = true;
            
            if (!isFormValid) {
                return;
            }

            let res = await this.add_port()
            if(res){
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Port added', life: 3000});
            }
            
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;
            this.closeModal()

            if(!this.showMessage) {
                this.resetForm();
            }

        },
        resetForm() {
            this.name = ''
            this.sender_email = ''
            this.bic_entity = ''
            this.iban = ''
            this.logo = ''
            this.primary_color = ''
            this.secondary_color = ''
            this.typography = ''
            this.typography_media = ''
            this.submitted = false;
        }
    },
    validations() {
        return {
            name: { required },
            sender_email: { required },
            primary_color: { required },
            secondary_color: { required },
            typography: { required },
            typography_media: { required },
        }
    },
};
</script>

<style scoped>
</style>