<template>
    <div class="card mt-4">
        <div class="container mb-3">
            <div class="row justify-content-center">
                <div class="col-11">
                     <div class="row mt-3">
                        <div class="col">
                            <span style="color: gray"> GROUP NAME </span> 
                        </div>
                        <div class="col">
                            <strong>{{formData.group_name}}</strong>
                        </div>
                    </div>    
                    <div class="row">
                        <div class="col">
                            <span style="color: gray"> USER/S </span>  
                        </div>
                        <div class="col">
                            <div class="row">
                                <div v-for="user in formData.selected_user" :key="user" class="col-6">
                                    <div class="product-item">
                                        <div class="image-container text-center">
                                            <Avatar :image="user.profile.picture" size="large" shape="circle" aria-haspopup="true" aria-controls="overlay_tmenu"/>
                                        </div>
                                        <div class="product-list-detail">
                                            <h5 class="mb-2">{{user.username}}</h5>
                                            {{user.email}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid grid-nogutter justify-content-between mt-5">
        <Button label="Back" class="p-button-raised" @click="prevPage()" icon="pi pi-angle-left" />
        <Button label="Complete" class="p-button-raised p-button-success" @click="complete()" icon="pi pi-check" iconPos="right"/>
    </div>   
</template>

<script>
import Button from 'primevue/button'
import Avatar from 'primevue/avatar'

export default {
    data() {
        return{
            format_perms: [],
            format_users: [],
        }
    },
    props: {
        formData: Object
    },
    components: {
        Button,
        Avatar
    },
    computed: {
        get_user_port: function() {
            return this.$store.getters.get_user_port
        }
    },
    methods: {
        prevPage() {
            this.$emit('prev-page', { pageIndex: 3 });
        },
        complete() {

            for(var item in this.formData['perms']) {
                for(var data in this.formData['perms'][item]['perms']) {
                    if(this.formData['perms'][item]['perms'][data].checked == true) {
                        this.format_perms.push(this.formData['perms'][item]['perms'][data].id)
                    }
                }
            }

            for(var user in this.formData['selected_user']) {
                this.format_users.push(this.formData['selected_user'][user].id)
            }

            this.$emit('complete', { formData : { format_perms: this.format_perms, id_port: this.get_user_port.id, format_users: this.format_users, id_menu_bar_items: this.formData.id_menu_bar_items}});
        },
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    background-color: #f8f9fa;
    padding: 1rem;
}
</style>