<template>
    <div v-if="loading" style="position: absolute; top:0;left:0;">
        <ProgressBar mode="indeterminate" style="height: 6px; width: 100vw;"></ProgressBar>
    </div>
    <div class="row">
        <div class="col content-section introduction mt-4 ms-3">
            <div class="feature-intro">
                <h1> Pending calls</h1>
                <p> Calls which are not validated.</p>
            </div>
        </div>
    </div>

    <span v-if="!loading">
        <div class="row">
            <div v-for="call in get_unconfirmed_calls" :key="call" class="col:12 md:col-6 lg:col-4 mb-3">
                <Cards data-testid="call" :call="call" :validation_type="true"/>
            </div>
        </div>
    </span>
</template>

<script>
import Cards from '../../components/Home/Current_calls_card.vue'
import ProgressBar from 'primevue/progressbar'


export default {
    name: "Validation Panel",
    components: {
        Cards,
        ProgressBar
    },
    data() {
        return{
            loading: false
        }
    },
    computed: {
        get_unconfirmed_calls: function() {
            return this.$store.getters.get_unconfirmed_calls
        }
    },
    async mounted() {
        this.loading = true
        await this.$store.dispatch('load_unconfirmed_calls')
        this.loading = false
    }
}
</script>

<style scoped>

.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
}

.height_skeleton {
    height: calc(100vh - 20rem) !important;
}



</style>